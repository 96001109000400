import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { translateFormatter } from "_utils";
import { genericReducer } from "_reducers/general.reducer";

import ContainedButton from "_components/Button/ContainedButton";

import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

import BasicAnchor from "_components/Anchor/Basic";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Form/termsAndConditionsStyle.js";

const useStyles = makeStyles()(styles);

const TermsAndConditions = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["auth", "default"]);
  const { termsAccepted, setTermsAccepted, onAgreement } = props;
  const privacyLink = t("default:disclaimer.privacyPolicy");
  const formatter = translateFormatter()
    .setLink("sera4privacy", privacyLink, "https://sera4.com/privacy-policy");

  const [ state, setState ] = useReducer(genericReducer,
    {
      touChecked: termsAccepted,
      privacyChecked: termsAccepted
    }
  );

  const handleCheckboxChange = name => event => {
    setState({ [name]: event.target.checked });

    if (name === "privacyChecked" && state.touChecked) {
      setTermsAccepted(event.target.checked);
    } else if (name === "touChecked" && state.privacyChecked) {
      setTermsAccepted(event.target.checked);
    }
  };

  const renderAdditionalDisclaimers = () => {
    const formatter = translateFormatter()
      .setLink("sera4web", "www.sera4.com", "https://sera4.com")
      .setLink("sera4mailPrivacy", "privacy@sera4.com", "mailto:privacy@sera4.com");

    return (
      <div style={{ marginTop: "10px" }}>
        <Typography variant="body2">
          {t("auth:login.activateDisclaimer", formatter.getInterpolatedKeys())}
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox checked={state.touChecked} onChange={handleCheckboxChange("touChecked")} />
            }
            label={(
              <label htmlFor="privacyPolicy">
                <BasicAnchor title={t("login.tou")} href="https://www.sera4.com/terms-of-use/">{t("login.tou")}</BasicAnchor>
              </label>
            )}
            color="primary"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox checked={state.privacyChecked} onChange={handleCheckboxChange("privacyChecked")} />
            }
            label={(
              <label htmlFor="privacyPolicy">
                <BasicAnchor title={t("login.privacyPolicy")} href="https://www.sera4.com/privacy-policy/">{t("login.privacyPolicy")}</BasicAnchor>
              </label>
            )}
            color="primary"
          />
        </FormGroup>
      </div>
    );
  };

  return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
    <Grid item>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <Typography className={classes.centerText}><b>{t("default:label.agreementStep")}</b></Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="body2">
                {t("default:disclaimer.generalCommit", formatter.getInterpolatedKeys())}
              </Typography>
              {renderAdditionalDisclaimers()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <ContainedButton
                fullWidth
                variant="contained"
                disabled={!state.touChecked || !state.privacyChecked}
                onClick={onAgreement}
                color="primary"
              >
                {t("default:button.accept")}
              </ContainedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
};

TermsAndConditions.propTypes = {
  setTermsAccepted: PropTypes.func.isRequired,
  termsAccepted: PropTypes.bool,
  onAgreement: PropTypes.func.isRequired
};

export default TermsAndConditions;