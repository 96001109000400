import PropTypes from "prop-types";

import Switch from "react-switch";

export default function ToggleSwitch({ checked, height, width, handleChange, disabled , onColor, uncheckedIcon, checkedIcon, offColor, className }) {
  return (
    <Switch
      checked={checked}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
      onColor={onColor || "#ED1A39"}
      offColor={offColor || "#888"}
      borderRadius={5}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon || false}
      activeBoxShadow={`0 0 2px 3px ${onColor || "#ED1A39"}`}
      height={height || 23}
      width={width || 50}
      id="custom-toggle-switch"
      className={className}
    />
  );
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  uncheckedIcon: PropTypes.element,
  checkedIcon: PropTypes.element,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string
};
