import { useReducer, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { alertActions } from "_actions";
import {
  formatDateAsMMMMDDYYYYhhmmss,
  userDeviceHelper
} from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { userDevicesService } from "_services/lockstasy/userDevices.service";
import { userService } from "_services";

import {
  Button,
  Grid,
  Divider,
  Typography,
  TablePagination,
  Card,
  CardContent,
  CardActions,
  Skeleton
} from "@mui/material";

import LsyRouter from "_components/Navigation/LsyRouter";
import CustomModal from "_components/Modal/CustomModal";

import {
  KeyboardBackspaceRounded,
  MobileFriendly
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/UserDevicesStyle.js";

const useStyles = makeStyles()(styles);
const MIN_VALUE_ENABLE_PAGINATION = 5;

function UserDevices(props) {
  const { history } = props;

  const { classes } = useStyles();
  const { t } = useTranslation("default");

  const membershipId = useParams().id;
  const dispatch = useDispatch();

  const [state, setState] = useReducer(genericReducer,
    {
      deviceList: [],
      latestDevice: {},
      user: {},
      totalSize: 0,
      loading: false,
      currentPage: 1,
      rowsPerPage: 10,
      totalData: 0,
      isRestricted: false,
      restrictionConfirmationOpen: false
    });

  const setLoading = (loading) => setState({ loading });
  const setRestrictionConfirmationOpen = (restrictionConfirmationOpen) => setState({ restrictionConfirmationOpen });

  const fetchUser = useCallback(async () => {
    try {
      const result = await userService.fetchUser({ id: membershipId });
      setState({
        user: result.data, 
        isRestricted: result.data.device_restricted
      });
    } catch (e) {
      console.warn("Warning, failed to fetch user", e);
      return [];
    }
    setLoading(false);
  }, [membershipId]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const options = {
        page: state.currentPage,
        page_size: state.rowsPerPage,
        sort_by: "last_visited_at,desc"
      };
      const result = await userDevicesService.fetchUserDevices({id: membershipId, options: options});
      const [recentDevice, ...list] = result.data;

      setState({ 
        ...state.currentPage === 1 ? { latestDevice: recentDevice } : {}, 
        deviceList: [recentDevice, ...list], 
        totalData: result.meta.pagination.total
      });
    } catch (e) {
      console.warn(e);
    }
    setLoading(false);
  }, [membershipId, state.currentPage, state.rowsPerPage]);

  useEffect(() => {
    setLoading(true);
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const routeToDeviceHistory = (v) => {
    history.push(`/users/${membershipId}/devices/${v.teleporte_id}/device_history`);
  };

  const getRemoveRestrictionConfirmation = () => {
    return <Typography>{t("confirmation.removeDeviceRestriction")}</Typography>;
  };

  const handleRemoveRestrictionClick = () => {
    setState({
      restrictedDeviceId: state.user.restricted_device.teleporte_id,
      restrictionConfirmationOpen: true
    });
  };

  const removeRestrictedDevice = async () => {
    try {
      await userDevicesService.removeRestrictedDevice({id: membershipId, deviceId: state.restrictedDeviceId});
      dispatch(alertActions.send(t("success.deviceRestriction")));
      fetchData();
      fetchUser();
    } catch (e) {
      console.warn(e);
      dispatch(alertActions.send(t("error.deviceRestriction"), "error"));
    }
  };

  const handleChangePage = (event, newPage) => {
    setState({currentPage: newPage + 1});
  };

  const handleRowChange = (event) => {
    setState({
      rowsPerPage: parseInt(event.target.value, 10),
      currentPage: 1
    });
  };

  const renderField = (label, value) => {
    return <div className={classes.sentence}>
      <div className={classes.label}>{`${label}:`}</div> 
      <div className={classes.value}>{value}</div>
    </div>;
  };

  const renderLoadingCard = (quantity) => {
    return Array.from({length: quantity}).map((_, i) => {
      return <div key={`loadingCard-${i}`} className={classes.item}> 
        <div className={classes.title}>
          {Array.from({length: 2}).map((_, i) => {
            return <Skeleton variant="rectangular" key={`loadingHeader-${i}`} className={classes.skeleton}/>;
          })}
        </div>
        <div className={classes.sentenceContainer}>
          {Array.from({length: 4}).map((_, i) => {
            return <Skeleton variant="rectangular" key={`loadingLine-${i}`} className={classes.marginBottom}/>;
          })}
        </div>
      </div>;
    });
  };

  const placeholderNoDevice = () => {
    return <Card sx={{ minWidth: 375, maxWidth: 375 }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="flex-start" className={classes.marginBottom}>
          <Grid item>
            <div className={classes.center}>
              <MobileFriendly fontSize="small"/>
              <Typography variant="body2">
                {state.isRestricted ? t("label.unregistered") : t("label.latestDevice")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="body1" component="div" className={classes.textAlign}>
              {state.isRestricted ? t("widget.explanations.deviceWillBeRegistered") : t("fallbacks.noDevice")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>;
  };

  const renderLatestDevice = () => {
    if (isEmpty(state.latestDevice) || (state.isRestricted && !state.user.restricted_device)) {
      return placeholderNoDevice();
    }

    const device = state.isRestricted ? state.user.restricted_device : state.latestDevice;
    const platform = device.platform || {};
    const handleOpenDeviceHistory = () => routeToDeviceHistory(state.latestDevice);

    return <Card data-testid="latestDeviceCard" sx={{ minWidth: 375, maxWidth: 375 }}>
      <CardContent onClick={handleOpenDeviceHistory} className={classes.clickable}>
        <Grid container alignItems="center" justifyContent="space-between" className={classes.marginBottom}>
          <Grid item>
            <div className={classes.center}>
              <MobileFriendly fontSize="small"/>
              <Typography variant="body2">
                {device.restricted ? t("label.registered") : t("label.latestDevice")}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.center}>
              {userDeviceHelper.getDeviceIconType(`${platform.name} ${platform.model}`, classes)}
              <Typography variant="body2">
                {`${platform.name} (${platform.version})`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.textAlign}>
              <b>{platform.model}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {`${t("label.lastLogin")}: ${formatDateAsMMMMDDYYYYhhmmss(device.last_visited_at)}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            {device.restricted && 
              <Button 
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleRemoveRestrictionClick}>
                {t("button.unregisterDevice")}
              </Button>
            }
          </Grid>
        </Grid>
      </CardActions>
    </Card>;
  };

  return (
    <div className={classes.body}>
      <Grid item xs={false} md={1}></Grid>
      <Grid item xs={12} md={10}>
        <LsyRouter className={classes.backContainer} page="user" id={membershipId} testId={"backRoute"}>
          <KeyboardBackspaceRounded className={classes.icon}/>
          <span>{t("button.toUserProfile")}</span>
        </LsyRouter>
        <Typography variant="subtitle1"><b>{t("label.mobileDevices", { quantity: state.totalData })}</b></Typography>
        <Typography className={classes.titleDescription} variant="caption">
          {`${state.user.first_name} ${state.user.last_name} (${state.user.email})`}
        </Typography>
        <Divider className={classes.divider} />
        {state.isRestricted &&
          <Typography variant="body2">
            {t("description.deviceEnabled")}
          </Typography>
        }
        <div className={classes.container}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              {state.loading ? renderLoadingCard(1) : renderLatestDevice()}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.deviceContainer} data-testid="deviceContainer">
            {state.loading ? renderLoadingCard(3) :
              state.deviceList.map((v, i) => {
                if (isEmpty(v)) {
                  return null;
                }

                const handleOpenHistory = () => routeToDeviceHistory(v);
                const platform = v?.platform || {};

                return <div className={classes.item} key={v.teleporte_id} data-testid={`device${v.teleporte_id}`} onClick={handleOpenHistory}> 
                  <div className={classes.title}>
                    <div className={classes.flex}>
                      <Typography className={classes.deviceTitleFull}>
                        <b>{platform.model}</b>
                      </Typography>
                      {userDeviceHelper.getDeviceIconType(`${platform.name} ${platform.model}`, classes)}
                    </div>
                    { state.currentPage === 1 && i === 0 ? 
                      <div className={classes.center}>
                        {state.latestDevice.restricted && <MobileFriendly fontSize="small"/>}
                        <Typography variant="body2">
                          {state.latestDevice.restricted ? t("label.registered") : t("label.latestDevice")}
                        </Typography>
                      </div> :
                      v.restricted && <div className={classes.center}>
                        <MobileFriendly fontSize="small"/>
                        <Typography variant="body2">
                          {t("label.registered")}
                        </Typography>
                      </div>
                    }
                  </div>
                  <div className={classes.sentenceContainer}>
                    {renderField(t("label.lastLogin"), formatDateAsMMMMDDYYYYhhmmss(v.last_visited_at))}
                    {renderField(t("label.firstLogin"), formatDateAsMMMMDDYYYYhhmmss(v.created_at))}
                    {renderField(t("label.platform"), `${platform.name} (${platform.version})`)}
                    {renderField(t("label.teleporteVersion"), v.teleporte_version)}
                  </div>
                </div>;
              })
            }
          </div>
        </div>
        <div data-testid="footer">
          {state.totalData > MIN_VALUE_ENABLE_PAGINATION ? 
            <TablePagination
              className={classes.pagination}
              data-testid="pagination"
              component="div"
              count={state.totalData || 0}
              rowsPerPage={state.rowsPerPage}
              page={state.currentPage - 1}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 15, 25, 50]}
              onRowsPerPageChange={handleRowChange}
            /> : null}
        </div>
      </Grid>
      <Grid item xs={false} md={1}></Grid>
      <CustomModal
        open={state.restrictionConfirmationOpen}
        setOpen={setRestrictionConfirmationOpen}
        handleSubmit={removeRestrictedDevice}
        type="confirm"
        confirm={t("button.confirm")}
        cancel={t("button.cancel")}
        cancelButtonStyle={classes.button}
        confirmButtonStyle={classes.button}
        title={t("confirmation.removeTitle")}
        description={getRemoveRestrictionConfirmation()}
        disableBackdropClick={true}
        modalStyle={classes.modal}
      />
    </div>
  );
}

UserDevices.propTypes = {
  history: PropTypes.object
};

export default UserDevices;