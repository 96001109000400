import PropTypes from "prop-types";
import moment from "moment";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import GridItem from "components/Grid/GridItem.js";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/notificationCommentStyle.js";

const useStyles = makeStyles()(styles);

function NotificationComment(props) {
  const { classes } = useStyles();
  const { data } = props;

  return (
    <GridItem xs={12} className={classes.commentItem}>
      <Card variant="outlined" className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <p className={classes.name}>
              {data.user.name}
              <span className={classes.date}>{moment(data.created_at).format("lll")}</span>
            </p>
            <p className={classes.comment}>{data.comment}</p>
          </CardContent>
        </div>
      </Card>
    </GridItem>
  );
}

NotificationComment.propTypes = {
  data: PropTypes.object
};

export default NotificationComment;
