const sitesStyle = (theme) => ({
  card: {
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  siteCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto"
  },
  cardContent: {
    flexGrow: "1"
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  locksWidget: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  lockIcon:{
    color: theme.lsyPalette.primary.main,
    marginLeft: "15px"
  }
});
export default sitesStyle;
