const siteCard2Style = (theme) => ({
  card: {
    marginTop: "15px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  cardContent: {
    "flexGrow": "1",
    "& p": {
      margin: "0px"
    },
    "&:last-child": {
      paddingBottom: "8px"
    }
  },
  siteName: {
    textAlign: "center",
    marginBottom: "8px"
  },
  name: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title1,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  description: {
    textAlign: "center",
    marginTop: "8px",
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.subtitle3,
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  defaultIcon: {
    height: "40px",
    width: "40px",
    marginLeft: "10px"
  },
  divBar: {
    width: "100%",
    height: "15px",
    backgroundColor: theme.lsyPalette.secondary.background
  },
  iconDiv: {
    display: "flex",
    alignItems: "center",
    minHeight: "20px"
  },
  icon: {
    marginLeft: "5px"
  },
  vpnIcon: {
    marginLeft: "5px",
    marginRight: "2px",
    width: "20px",
    height: "20px",
    color: "#88828b"
  },
  count: {
    fontSize: theme.lsyPalette.body1
  },
  siteDelete: {
    transform: "scale(0.8)",
    flexGrow: 1,
    color: theme.lsyPalette.primary.main
  },
  invisDiv: {
    visibility: "none",
    flexGrow: 1
  },
  deleteButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  lockStatusIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(0.5),
    width: "18px",
    height: "18px"
  },
  placeholderDiv: {
    height: "34px"
  }
});

export default siteCard2Style;
