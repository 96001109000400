import { modalConstants } from "_constants";

const showModal = ({ modalProps, modalType }) => dispatch => {
  dispatch({
    type: modalConstants.SHOW_MODAL,
    modalProps,
    modalType
  });
};

const hideModal = () => dispatch => {
  dispatch({
    type: modalConstants.HIDE_MODAL
  });
};

export const modalActions = {
  showModal,
  hideModal
};