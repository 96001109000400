import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { convertToHex } from "_helpers";
import { getFormattedLicense } from "_helpers/lock";

import {
  Grid,
  Skeleton,
  Typography
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";

import ForwardIcon from "@mui/icons-material/Forward";
import TagIcon from "@mui/icons-material/LocalOfferOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import SiteIcon from "assets/teleporte/SiteIcon";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/lockReplacementDetailsStyle";

const useStyles = makeStyles()(styles);

function LockReplacementDetails(props) {
  const { t } = useTranslation("default");
  const { classes, cx } = useStyles();
  const { lock, replaceBy, type, isLoading, sites, groups } = props;
  const isNoteType = type === "note";

  const getSiteName = siteId => sites.find(site => site?.id == siteId)?.name;
  const getLockGroupName = lockGroupId => groups.find(group => group?.id == lockGroupId)?.name;

  const basicLockFields = [
    {
      label: t("label.hardwareId"),
      field: "hardware_id",
      func: lock => convertToHex(lock.id, 4)
    },
    {
      label: t("label.lockName"),
      field: "name"
    },
    {
      label: t("label.lockDescription"),
      field: "description"
    },
    {
      label: t("widgetField.license"),
      field: "license",
      func: lock => getFormattedLicense(lock, t)
    }
  ];

  const advancedLockFields = [
    {
      icon: <VpnKeyIcon className={classes.icon}/>,
      field: "keys",
      func: lock => lock.keys ? `${lock.keys.single} ${t("label.singleKeys")}` : null
    },
    {
      icon: <SiteIcon className={classes.icon}/>,
      field: "site",
      func: lock => !lock?.collection_id ? null :
        `${getSiteName(lock?.collection_id)} (${lock?.keys?.collection} ${t("label.keys")})`
    },
    {
      icon: <LockGroupIcon className={classes.icon}/>,
      field: "lock_group_name",
      func: lock => !lock?.lock_group_id ? null :
        `${getLockGroupName(lock?.lock_group_id)} (${lock?.keys?.group} ${t("label.keys")})`
    },
    {
      icon: <TagIcon data-testid="tagIcon" className={classes.icon}/>,
      field: "tags",
      func: lock => isNoteType ?
        isEmpty(lock.tag_ids) ? null : `${lock.tag_ids.length} ${t("features.tags")}` :
        lock.tag_ids && lock.tag_ids > 0 ? `${lock.tag_ids} ${t("features.tags")}` : null
    }
  ];

  const renderSkeleton = () => {
    return <Skeleton variant="rectangular" className={classes.skeleton} data-testid="skeleton"/>;
  };

  const renderLockInfo = (lock, beforeAfter) => {
    if (isLoading) {
      return renderSkeleton();
    }

    if (!lock || !lock[beforeAfter]) {
      return null;
    }

    const rows = [
      ...basicLockFields,
      ...advancedLockFields
    ];

    return <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start" className={classes.spaceAround}>
      {rows.map((row, i) => {
        const value = row.func ? row.func({...lock[beforeAfter], id: lock.id}) : lock[beforeAfter][row.field];
        const space = i === basicLockFields.length - 1 ? classes.marginBottom : i > basicLockFields.length - 1 ? classes.spaceSidesBottom : "";

        return value && <Grid item key={`${lock.id}${row.field}`} className={cx(classes.infoLine, space)} data-testid={`lockFieldSection-${beforeAfter}`}>
          {row.icon ? row.icon :
            <Typography variant="body2" className={classes.description}>{`${row.label}:`}</Typography>
          }
          <Typography variant="body2" className={classes.value}>{value}</Typography>
        </Grid>;
      })}
    </Grid>;
  };

  const renderLocks = beforeAfter => {
    return <Grid container direction="column" alignItems="strech" justifyContent="space-around">
      <Grid item className={classes.spaceTopBottom} data-testid="lockSection">
        <div className={cx(classes.border, isNoteType ? classes.maxWidth : "")}>
          {renderLockInfo(lock, beforeAfter)}
        </div>
      </Grid>
      <Grid item className={classes.spaceTopBottom} data-testid="replacementLockSection">
        <div className={cx(classes.border, isNoteType ? classes.maxWidth : "")}>
          {renderLockInfo(replaceBy, beforeAfter)}
        </div>
      </Grid>
    </Grid>;
  };

  return <ErrorBoundary>
    <Grid container alignItems="strech" justifyContent="space-evenly" className={classes.spaceTopBottom}>
      <Grid item data-testid="beforeReplacementSection">
        <Typography variant={isNoteType ? "body2" : "body1"}>
          <b>{t("label.lockReplacement.beforeReplacement")}</b>
        </Typography>
        {renderLocks("before")}
      </Grid>
      <Grid item className={classes.displayFlex}>
        <Grid container direction="column" alignItems="center" justifyContent="space-around">
          <Grid item>
            <ForwardIcon fontSize="large"/>
          </Grid>
          <Grid item>
            <ForwardIcon fontSize="large"/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item data-testid="afterReplacementSection">
        <Typography variant={isNoteType ? "body2" : "body1"}>
          <b>{t("label.lockReplacement.afterReplacement")}</b>
        </Typography>
        {renderLocks("after")}
      </Grid>
    </Grid>
  </ErrorBoundary>;
}

LockReplacementDetails.defaultProps = {
  lock: {},
  replaceBy: {},
  sites: [],
  groups: []
};

LockReplacementDetails.propTypes = {
  lock: PropTypes.object.isRequired,
  replaceBy: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  sites: PropTypes.array,
  groups: PropTypes.array
};

export default LockReplacementDetails;