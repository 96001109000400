/* Use this file to send/retrieve updated from the license service */
import { get, post, put } from "_helpers/axios/gw.client";
import { pick } from "lodash";

import {
  lockdepotUrl
} from "_services/helper";


// this will create NEW licenses for the lock
const bulkCreateLicenses = async (payload = { }) => {
  return post(`${lockdepotUrl}/licenses/bulk`, payload);
};

// this will update exisitng locks licenses
const bulkUpdateLicenses = async (payload = { }) => {
  return put(`${lockdepotUrl}/licenses/bulk`, payload);
};

const fetchLockLicense = async (lockId) => {
  return get(`${lockdepotUrl}/locks/${lockId}/license`);
};

// includes expired/old licenses as well
const fetchLockLicenses = async (lockId) => {
  return get(`${lockdepotUrl}/locks/${lockId}/licenses`);
};

// this will update exisitng locks licenses
const updateLockLicense = async (lockId, payload = {}) => {
  return post(`${lockdepotUrl}/locks/${lockId}/license`, pick(payload, ["license_definition_id", "valid_from", "valid_till","invoice"]));
};

export const licenseService = {
  bulkCreateLicenses,
  bulkUpdateLicenses,
  updateLockLicense,
  fetchLockLicense,
  fetchLockLicenses
};