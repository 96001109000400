import { useState, useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { userNotificationService } from "_services/lockstasy/userNotification.service";
import { isBlank } from "_helpers";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";
import Placeholder from "_components/Helper/Placeholder";
import CustomModal from "_components/Modal/CustomModal";
import LsyRouter from "_components/Navigation/LsyRouter";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";

import { Clear, NotificationsActive, NotificationsOff, OpenInNew, Person } from "@mui/icons-material";
import LockIcons from "assets/teleporte/LockIcons";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/userNotificationStyle.js";

const useStyles = makeStyles()(styles);

function UserNotificationsWidget(props) {
  const { title, isCollapsed, fetchWithInput, fetchType, type, apiType, apiLabel, data, setData, currNotification, isTypeAhead, hasSelectAll, selectAll, setSelectAll, statusIcon } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const theme = useTheme();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rowsPerPage: 10,
      page: 1
    }
  );

  const [selectAllOpen, setSelectAllOpen] = useState(false);
  const setWidgetData = useCallback((data) => setData({ [type]: data }), [setData, type]);
  const isSelectAll = selectAll[type];
  const setIsSelectAll = (data) => setSelectAll({ [type]: data });

  const handleSelectorSubmit = useCallback(async (e) => { 
    try {
      let idLabel;
      if (type === "work_session_without_close_confirmation" || type === "work_session_time_exceeded") {
        idLabel = "membership_id";
      } else if (type === "lock_group") {
        idLabel = "lock_group_id";
      } else {
        idLabel = "lock_id";
      }

      const opt = { [idLabel]: e[e.length - 1].id };

      if (Array.isArray(apiType)) {
        opt.type = apiType[0];

        apiType.slice(1).forEach(async (type) => {
          await userNotificationService.updateUserNotification({...opt, type: type });
        });
      }

      const resp = await userNotificationService.updateUserNotification({ ...opt, type: opt.type || apiType });

      if (resp.data) {
        let newItem = resp.data;
        newItem[apiLabel].id = newItem[apiLabel][Object.keys(newItem[apiLabel]).filter(e => e.indexOf("id") >= 0)[0]];
        const newArr = [...data, resp.data];

        setWidgetData(newArr);
      }

      dispatch(alertActions.send("Successfully Added"));
    } catch (e) {
      console.warn("Failed to add subscription", e, e?.response);
      dispatch(alertActions.send(t("error.addUserNotification"), "error"));
    }
  }, [apiLabel, apiType, data, dispatch, setWidgetData, type, t]);

  const formattedSelectorValues = useCallback(() => {
    const formattedData = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i][apiLabel];
      obj.id = obj[Object.keys(obj).filter(e => e.indexOf("id") >= 0)[0]];
      formattedData.push(obj);
    }
    return formattedData;
  }, [data, apiLabel]);

  const getPlaceholderTransitionStyle = (state, theme) => {
    if (state.hasValue || state.selectProps.inputValue) {
      return {
        top: -13,
        fontSize: 11,
        backgroundColor: theme.lsyPalette.secondary.main
      };
    }
  
    return {
      fontSize: 12
    };
  };

  const selector = useCallback(() => { 
    return (
      <div data-testid="typeAhead">
        <CustomAsyncSelect
          isMulti={true}
          isClearable={false}
          autoFocus={true}
          backspaceRemovesValue={false}
          placeholder={`Search for ${fetchType}:`}
          loadOptions={fetchWithInput}
          isOptionDisabled={(option) => option.disabled}
          value={formattedSelectorValues()}
          autoBlur={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          onChange={handleSelectorSubmit}
          noOptionsMessage={({ inputValue }) => {
            if (isBlank(inputValue)) {
              return t("instructions.typeSomething");
            } else {
              return t("fallbacks.noOptions");
            }
          }}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              padding: `0 ${theme.spacing(0.75)}`,
              paddingTop: "0px",
              overflow: "visible",
              position: "relative"
            }),
            multiValue: () => ({
              display: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              ...getPlaceholderTransitionStyle(state, theme),
              position: "absolute",
              transition: "top 0.5s, font-size 0.5s",
              color: theme.lsyPalette.primary.mainDark,
              paddingLeft: "4px",
              paddingRight: "4px",
              fontWeight: 400
            })
          }}
        />
      </div>
    );
  }, [
    fetchWithInput,
    t,
    fetchType, 
    theme,
    formattedSelectorValues, 
    handleSelectorSubmit
  ]);

  const handleSelectAllSubmit = async () => {
    try { 
      if (isSelectAll) {
        if (Array.isArray(apiType)) {
          apiType.forEach(async (type) => {
            await userNotificationService.deleteUserNotification({type: type});
          });
        } else {
          await userNotificationService.deleteUserNotification({type: apiType});
        }

        dispatch(alertActions.send(t("success.removeAllUserNotifications")));
        setWidgetData([]);
      } else {
        if (Array.isArray(apiType)) {
          apiType.forEach(async (type) => {
            await userNotificationService.updateUserNotification({type: type});
          });
        } else {
          await userNotificationService.updateUserNotification({type: apiType});
        }
        
        dispatch(alertActions.send(t("success.addAllUserNotifications")));
        setWidgetData([{}]);
      }
      setIsSelectAll(!isSelectAll);
    } catch (e) {
      console.warn("Failed to update subscriptions", e);
      if (isSelectAll) {
        dispatch(alertActions.send(t("error.removeUserNotificationAll"), "error"));
      } else {
        dispatch(alertActions.send(t("error.addUserNotificationAll"), "error"));
      }
    }
  };

  const handleCheckChange = async () => {
    if (isSelectAll) { 
      handleSelectAllSubmit();
    } else {
      setSelectAllOpen(true);
    }
  };  

  const noNotificationsScreen = () => {
    return (
      <div className={classes.placeholder} data-testid="noDataPlaceholder">
        <Placeholder message={t("label.notificationsDisabled")} classNameMessage={classes.placeholderText} icon={<NotificationsOff/>} classNameIcon={classes.placeholderIcon}/>
      </div>
    );
  };

  const yesNotificationsScreen = () => {
    return (
      <div className={classes.placeholder} data-testid="allDataPlaceholder">
        <Placeholder message={t("label.allNotificationsEnabled")} classNameMessage={classes.placeholderText} icon={<NotificationsActive/>} classNameIcon={classes.placeholderIcon}/>
      </div>
    );
  };

  const handleRemoveItem = async(v) => {
    if (!isSelectAll) {
      try {
        let idLabel;
        if (type === "work_session_without_close_confirmation" || type === "work_session_time_exceeded") {
          idLabel = "membership_id"; 
        } else if (type === "lock_group") {
          idLabel = "lock_group_id";
        } else {
          idLabel = "lock_id";
        }

        if (Array.isArray(apiType)) {
          apiType.forEach(async (type) => {
            const options = {
              type: type,
              [idLabel]: v[apiLabel].id
            };

            await userNotificationService.deleteUserNotification(options);
          });
        } else {
          const options = {
            type: apiType,
            [idLabel]: v[apiLabel].id
          };
          await userNotificationService.deleteUserNotification(options);
        }

        dispatch(alertActions.send(t("success.deleteNotification")));

        setWidgetData(
          data.filter((item) => {
            return item !== v;
          })
        );
      } catch(e) {
        dispatch(alertActions.send(t("error.removeUserNotification"), "error"));
      }
    }
  };

  const getSelectAllConfirmation = () => {
    return (
      <div>
        <div>{t("confirmation.subAllUserNotifications")}</div>
      </div>
    );
  };

  const formattedData = () => {
    return data.slice(state.rowsPerPage * (state.page - 1), state.rowsPerPage * state.page);
  };

  const getItemIcon = () => {
    switch (apiLabel) {
      case "user":
        return <Person height="25px" width="25px"/>;
      case "lock":
        return <LockIcons type={"AP3"} defaultStyle={classes.lockIcons} height="25px" width="25px"/>;
      case "lock_group":
        return <LockGroupIcon height="25px" width="25px"/>;
      default:
        return;
    }
  };

  const handleChangePage = (event, newPage) => { 
    setState({page: newPage + 1});
  };
  const handleRowChange = (event) => { 
    setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

  return (
    <div className={classes.notificationSettings}>
      <div className={classes.subTitle} data-testid="title"><b>{title}</b></div>
      <div className={cx(classes.notificationWindow, {[classes.content]: isCollapsed})}>
        <div className={classes.header}>
          <div className={classes.descTitle}>
            <Typography variant="body2"><b className={classes.rightSpacing}>{t(currNotification.title)}</b>{statusIcon}</Typography>
          </div>
        </div>
        <div data-testid="description" className={classes.description}>{t(currNotification.description)}</div>
        <div className={classes.inputs}>
          <Grid className={classes.description} item xs={12} md={12}>
            {isTypeAhead && !isSelectAll ? selector() : null}
          </Grid>
        </div>
        <div data-testid="table" className={classes.table}>
          {!isSelectAll && data.length > 0 ? 
            <div className={classes.itemContainer}>
              <div className={classes.items} data-testid="dataContainer">
                {formattedData().map((v, i) => {
                  return (
                    <div key={i} className={classes.item} data-testid="dataItem">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-testid="dataItemButton"
                        onClick={() => handleRemoveItem(v)}
                        className={classes.iconButton}
                      >
                        <Clear className={isSelectAll ? classes.disabledText : classes.removeItem}/>
                      </IconButton>
                      <div className={classes.itemIcon}>
                        { getItemIcon() }
                      </div>
                      <div className={classes.itemText}>{`${v[apiLabel]?.name}`}</div>
                      <div className={classes.routeIcon}>
                        <LsyRouter page={apiLabel} id={v[apiLabel]?.id}>
                          <OpenInNew className={classes.removeItem}/>
                        </LsyRouter>
                      </div>
                    </div>
                  );
                })}
              </div> 
            </div> : null}
          {!isSelectAll ? data.length === 0 ? noNotificationsScreen() : null : yesNotificationsScreen()}
        </div>
        <div className={classes.titleWorkSessions}>
          {hasSelectAll ? 
            <FormControlLabel
              className={classes.checkbox}
              data-testid="checkboxContainer"
              control={
                <Checkbox checked={isSelectAll} data-testid="checkbox" onChange={handleCheckChange} name="select" size="small" />
              }
              label={t("label.allNotificationsLabel", {notification: t(currNotification.name), type: fetchType.toLowerCase()})}
            /> : null}
          {!isSelectAll ? 
            <TablePagination
              className={classes.pagination}
              data-testid="pagination"
              component="div"
              count={data.length || 0}
              rowsPerPage={state.rowsPerPage}
              page={state.page - 1}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 15, 20, 25]}
              onRowsPerPageChange={handleRowChange}
            /> : null}
        </div>
      </div>
      <CustomModal
        open={selectAllOpen}
        setOpen={setSelectAllOpen}
        handleSubmit={handleSelectAllSubmit}
        type="confirm"
        confirm={t("button.confirm")}
        cancel={t("button.cancel")}
        cancelButtonStyle={classes.button}
        confirmButtonStyle={classes.button}
        title={"Confirmation"}
        description={getSelectAllConfirmation()}
        disableBackdropClick={true}
        modalStyle={classes.modal}
      />
    </div>
  );
}
UserNotificationsWidget.propTypes = {
  title: PropTypes.object,
  isCollapsed: PropTypes.bool,
  fetchWithInput: PropTypes.func.isRequired,
  fetchType: PropTypes.string,
  type: PropTypes.string.isRequired,
  apiType: PropTypes.any.isRequired,
  apiLabel: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  currNotification: PropTypes.object.isRequired,
  isTypeAhead: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  selectAll: PropTypes.object,
  setSelectAll: PropTypes.func,
  statusIcon: PropTypes.element
};
  
export default UserNotificationsWidget;