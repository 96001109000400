import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/lockBatteryIconStyle";
const useStyles = makeStyles()(styles);

function LockBatteryIcon(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={props.width} height={props.height} viewBox="0 0 11.53 22.04" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve">  
      <path className={cx(classes.row, props.className, {[classes.lowBattery]: props.level !== 1})} d="M10.25,1.7h-.9V.2c0-.11-.09-.2-.2-.2H2.39c-.11,0-.2,.09-.2,.2V1.7h-.9c-.71,0-1.28,.58-1.28,1.28V20.75c0,.71,.58,1.28,1.28,1.28H10.25c.71,0,1.28-.58,1.28-1.28V2.99c0-.71-.58-1.28-1.28-1.28Zm.72,19.05c0,.39-.32,.72-.72,.72H1.28c-.39,0-.72-.32-.72-.72V2.99c0-.39,.32-.72,.72-.72H10.25c.39,0,.72,.32,.72,.72V20.75Z"/>
      <rect className={cx(classes.row, props.className, {[classes.lowBattery]: props.level !== 1})} x="1.35" y="17.71" width="8.91" height="2.92" rx=".27" ry=".27"/>
      { props.level === 1 &&
        <>
          <rect className={cx(classes.row, props.className)} x="1.35" y="14.06" width="8.91" height="2.92" rx=".27" ry=".27"/>
          <rect className={cx(classes.row, props.className)} x="1.35" y="10.41" width="8.91" height="2.92" rx=".27" ry=".27"/>
          <rect className={cx(classes.row, props.className)} x="1.35" y="6.76" width="8.91" height="2.92" rx=".27" ry=".27"/>
          <rect className={cx(classes.row, props.className)} x="1.35" y="3.1" width="8.91" height="2.92" rx=".27" ry=".27"/>
        </>
      }
    </svg>
  );
}

LockBatteryIcon.defaultProps = {
  level: 1
};

LockBatteryIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  level: PropTypes.oneOf([0,1])
};

export default LockBatteryIcon;


