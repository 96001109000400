const customModalStyle = (theme) => ({
  chip: {
    "height": "fit-content",
    "& .MuiChip-label": {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    }
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px"
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "300px",
      width: "100%"
    },
    background: theme.lsyPalette.secondary.main
  },
  title: {
    fontSize: theme.lsyPalette.title1,
    color: theme.lsyPalette.text,
    fontWeight: "500",
    display: "flex"
  },
  content: {
    fontSize: theme.lsyPalette.body2,
    color: theme.lsyPalette.text
  },
  cancelButton: {
    color: theme.lsyPalette.text
  },
  confirmButton: {
    color: theme.lsyPalette.text
  },
  noData: {
    minHeight: "400px",
    flexGrow: 1
  },
  noDataBody: {
    fontSize: theme.lsyPalette.title3,
    color: theme.lsyPalette.text
  },
  selectLabel: {
    fontSize: theme.lsyPalette.body1
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px"
  },
  itemGrid: {
    width: "100%"
  },
  inherit: {
    display: "inherit"
  },
  negMarginTop: {
    marginTop: "-12px"
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  paddedItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  root: {
    color: theme.lsyPalette.text,
    background: theme.lsyPalette.secondary.main,
    margin: "0px"
  },
  clearIcon: {
    color: theme.lsyPalette.text
  },
  helperIcon: {
    alignSelf: "flex-start",
    paddingLeft: "0px !important"
  },
  header: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center"
  },
  calloutTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "275px",
    minWidth: "150px"
  },
  calloutIcon: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  },
  menu: {
    "overflow": "visible",
    "filter": "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    "mt": 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: "50%",
      width: 10,
      height: 10,
      backgroundColor: "white",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0
    }
  },
  lastFiltersTitle: {
    fontSize: theme.lsyPalette.body1
  },
  tooltipTitle: {
    whiteSpace: "pre-line"
  }
});
export default customModalStyle;
