import { useState, useReducer, useContext, useRef } from "react";
import { useSelector } from "react-redux";

//core components
import Card from "@mui/material/Card";

//components
import LsyTable from "_components/Table/LsyTable";
import LsyAppBar from "_components/Lockstasy/LsyAppBar";
import Placeholder from "_components/Helper/Placeholder";

import styles from "assets/jss/widgets/tableWidgetStyle.js";

import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual } from "lodash";

import { makeStyles } from "tss-react/mui";
import { WebSocketDataContext } from "_contexts/WebSocketData/WebSocketData";

const useStyles = makeStyles()(styles);

function TableWidget(props) {
  const { classes } = useStyles();
  const defaultData = [
    [
      {
        id: "Date",
        numeric: false,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type2",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Open",
        numeric: true,
        disablePadding: false,
        label: "-"
      }
    ],
    [
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      }
    ]
  ];

  const stateRef = useRef([[],[],[]]);
  const webSocketContext = useContext(WebSocketDataContext);
  const webSocketActive = webSocketContext.webSocketActive;
  const [data, setData] = useState(defaultData);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { fetchData, org, dataFormatter, stats, sortMap, events } = props;
  const language = useSelector((state) => state.locale.language);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      org: org,
      language: language,
      currentPage: 1, 
      rowsPerPage: props.rowsPerPage,
      orderBy: props.defaultSort,
      direction: props.sortDirection
    }
  );

  const liveUpdates = events && webSocketActive && state.currentPage === 1;

  const setSortParams = ({ orderBy, direction }) => setState({ orderBy, direction });
  const setCurrentPage = (currentPage) => setState({ currentPage });

  const fetchWidgetData = async () => {
    try {
      var options = {
        page: state.currentPage,
        page_size: props.rowsPerPage
      };
      
      if (sortMap) {
        let sortParam = `sort[${sortMap[state.orderBy]}]`;
        options[sortParam] = state.direction || null;
      }

      const result = await fetchData(options);

      if (stats) {
        setTotalPages(result.meta.pagination.total_pages);
        setTotalData(result.meta.pagination.total);
      }

      const formattedData = dataFormatter(result.data);
      setData(formattedData);

      stateRef.current = formattedData;

      setLoading(false);
    } catch (e) {
      console.debug(e);
    }
  };

  const sort = (orderBy, direction) => {
    setSortParams({
      orderBy: orderBy,
      direction: direction
    });
  };

  const handleUpdate = (data) => {
    if (!data) {
      return;
    }

    const id = props.getIdFromWebsocket(data); 
    const existingItemIds = stateRef.current[2];

    // Item already exists
    if(data.open && existingItemIds?.includes(id))
      return;

    // Item exists on another page
    if(!data.open && !existingItemIds?.includes(id)) {
      fetchWidgetData();
      return;
    }

    // Remove item
    if(!data.open && existingItemIds?.includes(id)) {
      const newData = props.removeTableItem(stateRef.current, id);

      if(totalPages > 1 && newData[1].length < props.rowsPerPage) {
        if(newData[1].length === 0 && state.currentPage > 1) {
          setCurrentPage(state.currentPage - 1);
        }
        fetchWidgetData();
      }
      
      setData(newData);
      setTotalData(prev => prev - 1); 
      stateRef.current = newData;
      return;
    }

    const formattedData = dataFormatter([data]);    
    const newData = props.addTableItem(stateRef.current, formattedData, state.orderBy, state.direction, props.rowsPerPage, totalPages, setTotalPages);

    setData(newData);
    setTotalData(prev => prev + 1);
    stateRef.current = newData;
  };

  function handleAlert(e) {
    if(liveUpdates) {
      const { action, object } = e.detail;
      switch (action) {
        case "update": handleUpdate(object);
          break;
      }
    }
  }

  useCustomCompareEffect(() => {
    fetchWidgetData();
  }, [state, language, org], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    if (events) {
      events.map((alertType) => {
        window.addEventListener(alertType, handleAlert);
      });
    }

    return () => {
      if (events) {
        events.map((alertType) => {
          window.removeEventListener(alertType, handleAlert, false);
        });
      }
    };
  }, [events, handleAlert], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const title = props.stats && totalData ? `${totalData} ${props.title}` : `${props.title}`;
  const fallbackData = (<Placeholder icon={props.icon}>{props.fallbackData}</Placeholder>
  );

  return (
    <Card elevation={5} className={classes.widgetCard} data-testid={`tableWidget-${props.title}`}>
      <LsyAppBar
        liveUpdates={liveUpdates}
        events={events}
        paginate={props.paginate}
        refresh={props.refresh}
        icon={props.icon}
        totalData={`${totalData}`}
        title={`${title}`}
        titleAction={props.titleAction}
        currentPage={state.currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        fetchWidgetData={fetchWidgetData}
        size={props.appBarSize}
      />
      {loading === true || data[1].length !== 0 ? (
        <LsyTable
          data={data}
          sort={sort}
          orderBy={state.orderBy}
          sortDirection={state.direction}
          liveUpdates={liveUpdates}
        />
      ) : (
        fallbackData
      )}
      {props.secondPaginate ? (
        <LsyAppBar
          paginate={props.paginate}
          refresh={props.refresh}
          currentPage={state.currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          fetchWidgetData={fetchWidgetData}
          size={props.appBarSize}
        />
      ) : null}
    </Card>
  );
}


TableWidget.propTypes = {
  stats: PropTypes.bool,
  paginate: PropTypes.bool,
  title: PropTypes.string,
  titleAction: PropTypes.func,
  refresh: PropTypes.bool,
  icon: PropTypes.object,
  fetchData: PropTypes.func,
  org: PropTypes.string,
  dataFormatter: PropTypes.func,
  rowsPerPage: PropTypes.number,
  appBarSize: PropTypes.string,
  secondPaginate: PropTypes.bool,
  fallbackData: PropTypes.any,
  defaultSort: PropTypes.string,
  sortMap: PropTypes.object,
  sortDirection: PropTypes.string,
  events: PropTypes.array,
  eventIdQuery: PropTypes.string,
  getIdFromWebsocket: PropTypes.func,
  addTableItem: PropTypes.func,
  removeTableItem: PropTypes.func
};

export default TableWidget;