import { useEffect } from "react";
import PropTypes from "prop-types";

export function getPlatform() {
  const platform = navigator.userAgentData?.platform || navigator.platform;

  if (platform.toLowerCase().indexOf("mac") >= 0) {
    return "mac";
  } else {
    return "windows";
  }
}

export function isMac() {
  return getPlatform() === "mac";
}

/* listen to a specific key combination such as "Meta (Windows or Mac Butterfly key)" + "Shift" + "KeyE"
 * and call the passed function when the key combination is pressed
 *
 * The mainKeyCode is the primary key that needs to be pressed (such as KeyP)
 * The onKeyPress function is called when the key combination is pressed
 * The mainKeyCode is the main key that needs to be pressed
 * The modifiers is an array of strings that specify the required modifiers
 * The onKeyPress is the function that is called when the key combination is pressed
 */
function KeyListenerComponent({ mainKeyCode, modifiers = [], onKeyPress }) {
  useEffect(() => {
    const handleKeyPress = (event) => {
      const isMetaKey = modifiers.includes("meta") ? event.metaKey : true;
      const isShiftKey = modifiers.includes("shift") ? event.shiftKey : true;
      const isCtrlKey = modifiers.includes("ctrl")  ? event.ctrlKey : true;
      const isAltKey = modifiers.includes("alt") ? event.altKey : true ;
      const isCorrectMainKeyCode = event.code === mainKeyCode;

      // Check if all required modifiers and the main key are pressed
      if (isMetaKey && isShiftKey && isCtrlKey && isAltKey && isCorrectMainKeyCode) {
        console.debug(`Key combination pressed: ${modifiers.join(" + ")} + ${mainKeyCode.toUpperCase()}`);
        if (onKeyPress) {
          onKeyPress(); // Call the passed function
        }
      }
    };

    // Add event listener for keydown events
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [mainKeyCode, modifiers, onKeyPress]); // Dependencies include the onKeyPress function
}

KeyListenerComponent.propTypes = {
  mainKeyCode: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  onKeyPress: PropTypes.func.isRequired
};

export default KeyListenerComponent;