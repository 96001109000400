import { config } from "_configs/server-config";

const piratey = config.pirateLangSupport;

let llist = {
  en: { name: "English" },
  es: { name: "Español" },
  pt: { name: "Português" },
  fr: { name: "Français" }
};

if (piratey) {
  llist["pi"] = { name: "Pirate" };
}

export const LOCALES = llist;