/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import PropTypes from "prop-types";

// For documentation on this API use https://react-google-maps-api-docs.netlify.app/
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
  useJsApiLoader
} from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { lockService } from "_services/lockstasy";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import moment from "moment";
import { compactObj, hasAbility } from "_helpers";
import {
  useCustomCompareMemo,
  useCustomCompareEffect
} from "use-custom-compare";
import { isEqual } from "lodash";
import {
  lightTheme,
  darkTheme,
  turnOffPoi,
  defaultLocation
} from "_constants/map.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { siteService } from "_services";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";

// @mui/material components
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RegularButton from "_components/Button/RegularButton";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";

//components
import ErrorBoundary from "_components/ErrorBoundary";
import LoadingPlaceHolder from "_components/Loading";
import MapOptionBox from "_components/Map/MapOptionBox";

//styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import mapWidgetStyle from "assets/jss/widgets/maps/dynamicMapWidgetStyle";

import { config } from "_configs/server-config";
import {
  FilteredHardwareTypeOptionMenu
} from "_constants/lock.constants";
import { lsyRouter } from "_helpers";
import GenericLocationIcon from "assets/teleporte/GenericLocationIcon";

const MAX_LOCK_FETCH_SIZE = 5000;
const useStyles = makeStyles()(mapWidgetStyle);

function Maps({ history, location }) {
  const { classes, cx } = useStyles();

  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector(
    (state) => state.memberships.currentMembership
  );

  const [site, setSite] = useState(-1);
  const [locations, setLocations] = useState([]);
  const [status, setStatus] = useState(0);
  const [type, setType] = useState(" ");
  const [filterStatus, setFilterStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dialogStatus, setDialogStatus] = useState({});
  const [mapTheme, setMapTheme] = useState(true);
  const [details, setDetails] = useState(false);
  const [lockInfo, setLockInfo] = useState({});
  const [map, setMap] = useState(null);
  const [forceRecenter, setForceRecenter] = useState(0);
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: config.googleMapsAPIKey });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const { t } = useTranslation("default");
  const basicLockOptions = Object.freeze([
    { name: t("label.allLocks"), value: " " }
  ]);
  const basicSiteOptions = [
    { name: t("label.allSites"), id: -1 }
  ];
  const [lockTypeOptions, setLockTypeOptions] = useState(basicLockOptions);
  const [siteOptions, setSiteOptions] = useState(basicSiteOptions);

  const canReadSites =
    hasAbility(currentMembership, "view", "lock_collections") &&
    ability.can("read", "lock_collections");

  const handleClick = (event) => {
    setFilterStatus(true);
    setAnchorEl(event.currentTarget);
  };

  const enableLiveMode = false;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusOptions = Object.freeze([
    { name: t("label.all"), value: 0 },
    { name: t("widgetField.open"), value: 1 },
    { name: t("label.closed"), value: 2 }
  ]);

  const fetchLocations = async () => {
    setLoading(true);

    let formattedResult = [];

    try {
      // Fetch lock locations
      const lockResult = await lockService.fetchLockLocations(compactObj({
        page: 1,
        page_size: MAX_LOCK_FETCH_SIZE,
        open_status: status,
        hardware_type: type,
        site_id: site === -1 ? "" : site
      }));

      const tempDialogStatus = {};
      for (let i = 0; i < lockResult.data.length; i++) {
        const lock = lockResult.data[i];
        try {
          let location = lock.location;
          tempDialogStatus[lock.id] = false;
          const lockData = {
            id: lock.id,
            location: {
              lat: parseFloat(location.lat),
              lng: parseFloat(location.lng)
            }
          };

          if(lock?.site_id)
            lockData.siteId = lock.site_id;

          formattedResult.push(lockData);
        } catch (e) {
          continue;
        }
      }
      
      setLocations(formattedResult);
      setDialogStatus({ ...tempDialogStatus });
    } catch (e) {
      console.warn("Failed to fetch locations", e);
    }
    setLoading(false);
  };

  const fetchSites = async () => {
    try {
      const result = await siteService.fetchSites();
      const formattedResult = result.data.map(site => site ? { id: site.id, name: site.name } : null);
      setSiteOptions((basicOptions) => [...basicOptions, ...formattedResult]);
      
      const siteIdQuery = location.search.split("=")[1];
      if(siteIdQuery) {
        if ([...basicSiteOptions, ...formattedResult].some(e => e.id == siteIdQuery)) {
          setSite(siteIdQuery);
        }
      }
    } catch (e) {
      setSiteOptions(basicSiteOptions);
      console.warn("Warning, failed to fetch sites", e);
    }
  };



  const fetchHardwareTypes = async () => {
    try {
      const serverLockTypes = await lockService.fetchHardwareTypes();
      const hardwareOptions = FilteredHardwareTypeOptionMenu(
        serverLockTypes.data["hardware_types"]
      );

      let additionalLockOptions = [...basicLockOptions, ...hardwareOptions];

      /* Commenting out as this one is still in debate, should we have padlocks and controllers */
      // if (PadlockPresentIntList(serverLockTypes.data["hardware_types"])) {
      //   additionalLockOptions.push({ name: t("lock.padlocks"), value: "padlocks" });
      // }
      // if (ControllerPresentInList(serverLockTypes.data["hardware_types"])) {
      //   additionalLockOptions.push({ name: t("lock.controllers"), value: "controllers" });
      // }

      setLockTypeOptions(additionalLockOptions);
    } catch (e) {
      setLockTypeOptions(basicLockOptions);
      console.warn("Could not fetch lock types");
    }
  };

  useEffect(() => {
    fetchHardwareTypes();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if(canReadSites) {
      fetchSites();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);


  useEffect(() => {
    if (filterStatus) {
      fetchLocations();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [status, filterStatus, type, site]);

  const mapStyle = useMemo(() => {
    var theme = mapTheme ? [...lightTheme] : [...darkTheme];
    if (!details) {
      theme.push(turnOffPoi);
    }
    return theme;
  }, [details, mapTheme]);

  const defaultMapOptions = {
    styles: mapStyle,
    fullscreenControl: false,
    controlSize: 20,
    streetViewControl: false
  };

  const containerStyle = {
    width: "100%",
    height: "800px"
  };

  useCustomCompareEffect(
    () => {
      setTimeout(() => {
        setForceRecenter((prev) => prev + 1);
      }, 3000);
    },
    [locations],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const center = useCustomCompareMemo(
    () => {
      if (locations.length > 0) {
        return locations[0].location;
      } else {
        return defaultLocation;
      }
    },
    [locations, forceRecenter],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const onLoad = useCallback(
    function callback(map) {
      // const bounds = new window.google.maps.LatLngBounds();
      // map.fitBounds(bounds);
      map.setCenter(center);
      setMap(map);
    },
    [center]
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const fetchLockStatus = async (id) => {
    try {
      const result = await lockService.fetchLockStatus({ lockId: id });
      return result.data;
    } catch (e) {
      console.warn("Failed to fetch lock status", e);
      dispatch(alertActions.send(t("error.fetchLockStatus"), "error"));
    }
  };

  const showInfoWindow = async (id) => {
    const result = await fetchLockStatus(id);
    setLockInfo({ ...lockInfo, [id]: result });
    setDialogStatus((prev) => {
      return {
        ...prev,
        [id]: true
      };
    });
  };

  const showDetails = (data) => {
    const details = (
      <div>
        <p className={classes.lockName}>{data.name}</p>
        {data?.site?.name &&
        <p className={classes.keyValue}>
          {`${t("widgetField.site")}: `}
          <span>{data.site.name}</span>
        </p>
        }
        <p className={classes.keyValue}>
          {`${t("label.lockState")}: `}
          <span>{data.open ? t("widgetField.open") : t("label.closed")}</span>
        </p>
        {data.user ? (
          <p className={classes.keyValue}>
            {`${t("label.lastOperator")}: `}
            <span
              className={classes.actionLink}
              onClick={() =>
                history.push(lsyRouter("user", data.user.membership_id))
              }
            >
              {`${data.user.first_name} ${data.user.last_name}`}
            </span>
          </p>
        ) : null}
        {data.last_opened_at ? (
          <p className={classes.keyValue}>
            {`${t("label.lastOpened")}: `}
            <span>{moment(data.last_opened_at).format("lll")}</span>
          </p>
        ) : null}
        {data.last_reported_at ? (
          <p className={classes.keyValue}>
            {`${t("label.lastVerified")}*: `}
            <span>{moment(data.last_reported_at).format("llll")}</span>
          </p>
        ) : null}
        <p className={classes.lockDetails}>
          <span onClick={() => history.push(lsyRouter("lock", data.id))}>
            {t("label.lockDetails")}
          </span>
        </p>
        {data?.site?.id &&
        <p className={classes.lockDetails}>
          <span onClick={() => history.push(lsyRouter("site", data.site.id))}>
            {t("label.siteDetails")}
          </span>
        </p>}
      </div>
    );

    return details;
  };

  function showZoom() {
    console.debug("Zoom level: ", this.getZoom());
  }

  const handleKeyDownStatus = (e) => {
    if (e.key === "Tab") {
      setStatus(e.currentTarget.dataset.value);
    }
  };

  const handleKeyDownType = (e) => {
    if (e.key === "Tab") {
      setType(e.currentTarget.dataset.value);
    }
  };

  const handleKeyDownSite = (e) => {
    if (e.key === "Tab") {
      setSite(e.currentTarget.dataset.value);
    }
  };

  const filterForm = useMemo(() => {
    const form = (
      <Grid container className={classes.formGrid}>
        <GridItem xs={12}>
          <FormControl variant="outlined" className={classes.itemGrid}>
            <InputLabel id="status">{t("label.status")}</InputLabel>
            <Select
              className={classes.itemBox}
              value={status}
              role="listbox"
              label={t("label.status")}
              onChange={(e) => {
                setStatus(e.target.value);
              }}>
              {statusOptions.map((option, index) => {
                return (
                  <MenuItem 
                    value={option.value} 
                    key={index}
                    onKeyDown={handleKeyDownStatus}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12}>
          <FormControl variant="outlined" className={classes.itemGrid}>
            <InputLabel id="lock type">{t("form.showLocksOfType")}</InputLabel>
            <Select
              className={classes.itemBox}
              label={t("form.showLocksOfType")}
              role="listbox"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}>
              {lockTypeOptions.map((item, index) => {
                return (
                  <MenuItem 
                    key={`${item.field}-${index}`} 
                    value={item.value}
                    onKeyDown={handleKeyDownType}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        {canReadSites && 
        <GridItem xs={12}>
          <FormControl variant="outlined" className={classes.itemGrid}>
            <InputLabel id="sites">{t("badges.sites")}</InputLabel>
            <Select
              className={classes.itemBox}
              label={t("badges.sites")}
              role="listbox"
              value={site}
              onChange={(e) => setSite(e.target.value)}>
              {siteOptions.map((item, index) => {
                return (
                  <MenuItem 
                    key={`${item.field}-${index}`} 
                    value={item.id}
                    onKeyDown={handleKeyDownSite}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>}
      </Grid>
    );

    return form;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [status, type, lockTypeOptions, siteOptions, site]);
  return (
    <div className={classes.lsyBackground}>
      <Popover
        id="filter options"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        elevation={1}
        style={{ top: "10px" }}
        classes={{ paper: classes.popOver }}
      >
        {filterForm}
      </Popover>
      <Grid
        className={classes.content}
        container
        justifyContent="center"
        direction="column"
        alignContent="center"
      >
        <GridItem className={classes.controls} xs={12} md={10}>
          <div className={classes.controlsItemDiv}>
            <Grid className={classes.content2} container direction="column">
              <GridItem className={classes.controls2} xs={12} sm={6} md={4}>
                <div className={classes.controlDiv}>
                  <RegularButton
                    className={cx(classes.lockStatusButton, {
                      [classes.buttonActive]: filterStatus
                    })}
                    disableRipple={true}
                    onClick={handleClick}
                  >
                    {t("button.filter")} &nbsp;
                    <FilterListIcon />
                  </RegularButton>
                  <div className={classes.borders}>
                    {enableLiveMode ? (
                      <RegularButton
                        className={cx(classes.liveButton, {
                          [classes.buttonActive]: !filterStatus
                        })}
                        onClick={() => {
                          setFilterStatus(false);
                          setLocations([]);
                        }}
                        disableRipple={true}
                      >
                        {t("label.live")}
                      </RegularButton>
                    ) : null}
                  </div>
                  {loading ? (
                    <div className={classes.loader}>
                      <LoadingPlaceHolder
                        color={muiTheme.lsyPalette.primary.mainLight}
                        height={12}
                      />
                    </div>
                  ) : (
                    <p className={classes.data}>
                      {(locations.length === MAX_LOCK_FETCH_SIZE
                        ? `${MAX_LOCK_FETCH_SIZE}+`
                        : locations.length) +
                        " " +
                        t("features.locks")}
                    </p>
                  )}
                </div>
              </GridItem>
            </Grid>
          </div>
        </GridItem>
        <GridItem className={classes.map} xs={12} md={10}>
          <ErrorBoundary>
            { isLoaded ?
              <GoogleMap
                mapContainerStyle={containerStyle}
                options={defaultMapOptions}
                center={center}
                zoom={7}
                onZoomChanged={showZoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                clickableIcons={false}
              >
                <div className={classes.controlsWrap}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <div className={classes.legend}>
                      <p>{t("label.legend.legend")}</p>
                      <hr className={classes.legendUnderline}/>
                      {canReadSites && 
                        <div className={classes.legendItem}>
                          <p>{t("label.lockInSite")}</p>
                          <GenericLocationIcon width="27px" height="27px" className={classes.blackLegendItem}/>
                        </div>}
                      <div className={classes.legendItem}>
                        <p>{t("widgetField.lock")}</p>
                        <GenericLocationIcon width="27px" height="27px" className={classes.redLegendItem}/>
                      </div>
                    </div>
                  </Box>
                  <MapOptionBox
                    mapTheme={mapTheme}
                    setMapTheme={setMapTheme}
                    details={details}
                    setDetails={setDetails}
                  />
                </div>
                {locations ?
                  <MarkerClusterer
                    minimumClusterSize={3}
                    maxZoom={17}
                  >
                    {(clusterer) =>
                      locations.map((lock, index) => (
                        <Marker
                          key={index}
                          position={lock.location}
                          clusterer={clusterer}
                          onLoad={marker => {
                            const customIcon = (opts) => Object.assign({
                              path: "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                              fillOpacity: 1,
                              strokeWeight: 1,
                              scale: 2.5
                            }, opts);
                            
                            if(lock.siteId && canReadSites) {
                              marker.setIcon(customIcon({
                                fillColor: "black",
                                strokeColor: "white"
                              }));
                            }}
                          }
                          onClick={() => {
                            showInfoWindow(lock.id);
                          }}>
                          { dialogStatus[lock.id] && lockInfo[lock.id] ?
                            <InfoWindow
                              key={`infowindow-${index}`}
                              visible={false}
                              position={lock.location}
                              onCloseClick={() => {
                                setDialogStatus(prev => {
                                  return {
                                    ...prev,
                                    [lock.id]: false
                                  };
                                });
                                setLockInfo({ ...lockInfo, [lock.id]: null });
                              }}
                            >
                              {showDetails(lockInfo[lock.id])}
                            </InfoWindow>
                            : null}
                        </Marker>
                      ))
                    }
                  </MarkerClusterer> : <></>}
              </GoogleMap> :
              <LoadingPlaceHolder title={t("actions.loading")} fullHeight={true} />
            }
          </ErrorBoundary>
        </GridItem>
      </Grid>
    </div>
  );
}

Maps.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string
};

export default Maps;
