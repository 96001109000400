import { useContext } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter, history } from "_helpers";
import { isAccountStatusSuspended, isAccountStatusInactive, getUserStatusName } from "_constants";
import appRoutes from "_routes";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import ContainedButton from "_components/Button/ContainedButton";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";

import { alertActions, authActions, memberActions } from "_actions";
import { userService } from "_services/lockstasy";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/usersStyle.js";

const useStyles = makeStyles()(styles);

function LsyUserCard(props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const userGroupMap = lsyAdminDataContext.userGroupMap;
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const memberships = useSelector((state) => state.memberships?.list);

  const { user } = props;

  const isSelf = currentMembership.id === user.membership_id;
  const inactiveOrSuspendedUser = isAccountStatusInactive(user.status) || isAccountStatusSuspended(user.status);

  const hasOtherMembership = () => {
    const filteredMemberships = memberships.filter(membership => !membership.s4_role && membership.id !== user.membership_id);

    return !isEmpty(filteredMemberships);
  };

  const postDeleteUser = () => {
    if (!isSelf) {
      props.fetchWidgetData();
      return;
    }

    if (hasOtherMembership()) {
      history.replace(appRoutes.private.memberships);
      dispatch(memberActions.removeCurrentMembership());
      dispatch(memberActions.removeMembershipFromList(currentMembership, memberships));
    } else {
      dispatch(authActions.logout());
    }
  };

  const deleteUser = async () => {
    try {
      await userService.deleteUser({ membershipId: user.membership_id });
      dispatch(alertActions.send(t("success.deleteUser")));
      postDeleteUser();
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteUser"), "error"));
    }
  };

  const openDeleteUserModal = () => {
    const modalContent = {
      type: "confirm",
      confirm: t("button.confirm"),
      confirmAction: deleteUser,
      title: t("confirmation.deleteTitle"),
      description: t("confirmation.deleteUser"),
      submit: true
    };

    if (isSelf) {
      modalContent.title = t("confirmation.leaveOrgTitle");
      modalContent.description = t("confirmation.leaveOrgDesc");
    }

    props.createModal(modalContent);
  };

  const mfaTagElement = (hasMfa) => {
    if (hasMfa) {
      return (
        <Chip
          size="small"
          className={cx(classes.chip, classes.enabled)}
          label={t("label.mfaEnabled")}
        />
      );
    } else {
      return (null);
    }
  };

  const renderCardTitle = () => {
    return <Typography variant="h5" component="h2" role="cardTitle" aria-label="fullName">
      <span role="nameSpan" aria-label="fullName"
        onClick={() => props.history.push(lsyRouter("user", user.membership_id))}
        className={cx(classes.userName, {[classes.inactiveOrSuspendedUser]: inactiveOrSuspendedUser})}
      >
        {`${user.first_name} ${user.last_name}`} { mfaTagElement(user.mfa) }
      </span>
    </Typography>;
  };

  const renderCardField = (fieldName, field, value) => {
    return <Typography className={classes.pos} role="cardField" aria-label={fieldName}>
      <span className={classes.field}>{`${field}:`}</span>
      <span className={classes.value}>
        {value}
      </span>
    </Typography>;
  };

  const renderEmail = () => {
    return <Typography className={classes.pos} role="cardField" aria-label="email">
      <span className={classes.field}>{`${t("form.email")}:`}</span>
      <a href={`mailto:${user.email}`}>
        <span className={classes.actionLink}>{user.email}</span>
      </a>
    </Typography>;
  };

  const renderUsername = () => {
    return user.username &&
      renderCardField("username", t("login.username", { ns: "auth" }), user.username);
  };

  const renderMembershipType = () => {
    return renderCardField("userGroup", t("label.membershipType"), userGroupMap[user.user_group] || user.user_group);
  };

  const renderStatus = () => {
    return renderCardField("status", t("label.status"), t(getUserStatusName(user.status)) || user.status);
  };

  const renderTags = () => {
    return <Can I="read" on="tags" ability={ability}>
      {user.tags.length > 0 ?
        <div className={classes.chips}>
          {user.tags.map((tag, index) => {
            return (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                key={`${tag.name}-${index}`}
                title={`${t("users.showTags")} ${tag.name}`}
              >
                <Chip
                  className={classes.chip}
                  label={tag.name}
                  onClick={() => {
                    const newState = {
                      ...props.state.filterVariables,
                      tag_ids: [{ id: tag.id, name: tag.name }]
                    };
                    props.setState({
                      filterVariables: newState,
                      currentPage: 1,
                      filterActive: true
                    });
                    props.reset(newState);
                    props.updateUrlQuery(newState);
                  }
                  }
                />
              </Tooltip>
            );
          })}
        </div> : null}
    </Can>;
  };

  const renderDeleteButton = () => {
    return <Can I="delete" on="users" ability={ability}>
      {isSelf ? 
        <ContainedButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={openDeleteUserModal}
        >
          {t("button.leaveOrg")}
        </ContainedButton> : 
        <Tooltip title={t("users.delete")} classes={{ tooltip: classes.tooltip }} >
          <IconButton
            onClick={openDeleteUserModal}
            className={classes.userDelete}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>}
    </Can>;
  };

  return (
    <Card className={classes.userCard}>
      <div className={classes.userCardContent}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <PersonIcon
            fontSize="large"
            className={cx(classes.personIcon, {[classes.inactiveOrSuspendedUser]: inactiveOrSuspendedUser})}
          />
        </Box>
        <CardContent className={classes.cardContent}>
          {renderCardTitle()}
          {renderEmail()}
          {renderUsername()}
          {renderMembershipType()}
          {renderStatus()}
          {renderTags()}
        </CardContent>
        {renderDeleteButton()}
      </div>
    </Card>
  );
}

LsyUserCard.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  user: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  updateUrlQuery: PropTypes.func
};

export default LsyUserCard;
