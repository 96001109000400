const auditStyle = theme => ({
  audit: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1",
    fillRule: "evenodd"
  },
  svg: {
  }
});

export default auditStyle;
