import { genericReducer } from "./general.reducer";

const initialState = {
  currentSort: "created_at",
  currentOrder: "desc"
};

export const sortReducer = {
  reducer: genericReducer,
  initialState: initialState
};