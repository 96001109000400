
export function register() {
  if (process.env.NODE_ENV !== "development" && "serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      console.info("Service workers are supported", process.env.PUBLIC_URL);
      navigator.serviceWorker.register("/sw.js").then(
        registration => {
          console.info("Service worker registration succeeded:", registration);
        },
        error => {
          console.error(`Service worker registration failed: ${error}`);
        }
      );
    });
  }
}