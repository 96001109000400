import { Component } from "react";
import PropTypes from "prop-types";
import InterfaceErrorPage from "_adminViews/Pages/InterfaceErrorPage.js";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    document.body.style.margin = "0px";
  }

  render() {
    return <Sentry.ErrorBoundary fallback={this.props.fullPage ? <InterfaceErrorPage /> : <p style={{ color: "#FF0000" }}>{this.props.t("error.minor")}</p>}>
      {this.props.children}
    </Sentry.ErrorBoundary>;
  }
}

ErrorBoundary.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullPage: PropTypes.bool,
  t: PropTypes.func
};

export default withTranslation()(ErrorBoundary);