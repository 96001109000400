/**
 * This object is not default preferences that shows up on the UI.
 * These are options sent to the backend when the user wants to
 * restore to some default values.
 */
export const defaultPreferences = Object.freeze({
  default_key_time: "14", //2 weeks in days
  default_key_time_unit: "days" // days
});

export const keyTimeUnits = Object.freeze({
  HOURS: "hours",
  DAYS: "days",
  MONTHS: "months"
});

export const maxKeyDurations = Object.freeze({
  MAX_HOURS: 720,
  MAX_DAYS: 365,
  MAX_MONTHS: 60
});