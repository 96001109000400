const loginOptionsStyle = theme => ({
  spaceAround: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  activeIcon: {},
  completedIcon: {},
  centerText: {
    textAlign: "center"
  },
  select: {
    fontSize: theme.typography.normalFont.fontSize,
    height: "34px"
  },
  topMargin: {
    marginTop: theme.spacing(2)
  }
});

export default loginOptionsStyle;