import { useReducer } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { genericReducer } from "_reducers/general.reducer";
import { isEmpty, isEqual } from "lodash";
import { useCustomCompareEffect } from "use-custom-compare";

import SummaryPopoverWidget from "./SummaryPopoverWidget";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import { 
  accessHistoryHelper,
  formatDateAsMMMDDYYYY,
  formatDateAshhmmssA,
  formatDuration
} from "_helpers";
import { getLockEventName } from "_helpers/lock";
import { userService, lockService } from "_services";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";

import {
  DescriptionOutlined,
  Dialpad,
  Lock,
  LockOpen,
  QueryStatsOutlined
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessHistoryStyle";

const useStyles = makeStyles()(styles);

function AccessHistoryTimelineWidget(props) {
  const { type, data, title, startDate, endDate, isShowAuditTable } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const language = useSelector((state) => state.locale.language);
  moment.locale(language);

  const [timelineState, setTimelineState] = useReducer(genericReducer,
    {
      userAnchorEl: null,
      userData: {},
      userSummaryOpen: false,
      formattedData: []
    }
  );

  const setUserSummaryOpen = (userSummaryOpen) => setTimelineState({ userSummaryOpen });
  const setFormattedData = (formattedData) => setTimelineState({ formattedData });
  
  const isUserTimeline = type === "locks";

  const handleUserSummary = async (e, item) => {
    if (item.user && item.user.deleted) {
      setTimelineState({
        userAnchorEl: e.currentTarget,
        userData: item.user,
        userSummaryOpen: true
      });
      return;
    }
    
    try {
      let result = isUserTimeline ? await userService.fetchUser({id: item.id}) : await lockService.fetchLock({lockId: item.id});
      setTimelineState({
        userAnchorEl: e.currentTarget,
        userData: result.data,
        userSummaryOpen: true
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const getEventIcon = (event, openedVia) => {
    switch (event) {
      case 0:
      case 19:
      case 20:
      case 38:
      case 40:
        return <LockOpen/>;
      case 1:
        return <Lock/>;
      case 2:
        return <DescriptionOutlined/>;
      default:
        return openedVia === 10 ? <Dialpad/> : null;
    }
  };

  const getUserName = user => {
    return user ? `${user.first_name} ${user.last_name}` : "";
  };

  const getUserInfo = (openedVia, user) => {
    const responsible = accessHistoryHelper.getUser(openedVia, user, t);

    return {
      name: responsible.name,
      id: user?.membership_id,
      link: responsible.action,
      user: user
    };
  };

  useCustomCompareEffect(() => {
    let arr = [];
    data.map(v => {
      if (isShowAuditTable) {
        arr.push({
          action: getLockEventName(v.event, v.opened_via),
          time: v.timestamp,
          name: isUserTimeline ? getUserName(v.user) : v?.name,
          id: isUserTimeline ? v.user?.membership_id : v?.id,
          icon: <TimelineDot>{getEventIcon(v.event)}</TimelineDot>,
          link: isUserTimeline ? v.user?.membership_id : v?.id,
          user: v.user
        });
      } else {
        let responsibleInfo = {};

        if (isUserTimeline) {
          responsibleInfo = { ...getUserInfo(v.open.opened_via, v.open?.user) };
        } else {
          responsibleInfo = {
            name: v.lock?.name,
            id: v.lock?.id,
            link: v.lock?.id
          };
        }

        if (v.closed.timestamp) {
          arr.push({
            action: "label.closed",
            time: v.closed.timestamp,
            duration: v.duration,
            ...v.unlatch_by_default ? getUserInfo(null, v.closed.user) : responsibleInfo,
            icon: <TimelineDot><Lock/></TimelineDot>,
            ...v.closed.rts_info ? { isClosedByRTS: true } : {}
          });
        }
        arr.push({
          action: "label.opened",
          time: v.open.timestamp,
          ...responsibleInfo,
          icon: <TimelineDot color="primary"><LockOpen/></TimelineDot>
        });
      }
    });

    arr = arr.sort((left, right) => {
      return moment.utc(right.time).diff(moment.utc(left.time));
    });

    setFormattedData(arr);
  }, [data], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return (
    <ErrorBoundary>
      <Typography variant="body1"><b>{title}</b></Typography>
      <div className={classes.datePeriod}>
        {formatDateAsMMMDDYYYY(startDate.locale(language))} - {formatDateAsMMMDDYYYY(endDate.locale(language))}
      </div>
      {!isEmpty(timelineState.formattedData) ? 
        <Timeline position="alternate">
          {timelineState.formattedData.map((v, i) => {
            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                  className={classes.time}
                >
                  <div>
                    {formatDateAsMMMDDYYYY(v.time)}
                  </div>
                  <div>
                    {formatDateAshhmmssA(v.time)}
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  {v.icon}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "16px", px: 2 }}>
                  <Typography className={cx(classes.description, v.isClosedByRTS ? classes.rtsInfo : "")} variant="body2">
                    <b className={classes.timelineHeader}>{t(v.action)}</b>
                    <span>
                      {v.action === "label.closed" ?
                        `(${t("label.duration")} ${formatDuration(v.duration, t)})${v.isClosedByRTS ? "*" : ""}` :
                        null
                      }
                    </span>
                  </Typography>
                  <Typography 
                    variant="body2" 
                    className={cx(v.link ? classes.link : null, classes.action, {[classes.alignRight]: i % 2 === 1})} 
                    onClick={v.link ? (e) => handleUserSummary(e, v) : null}
                  >
                    {v.name}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline> : 
        <div className={classes.placeholder}>
          <Placeholder
            icon={<QueryStatsOutlined/>}
            classNameMessage={classes.placeholderText}
            classNameIcon={classes.placeholderIcon}
          >
            <Typography className={classes.placeholderText}>{t("fallbacks.noData")}</Typography>
          </Placeholder>
        </div>}

      <SummaryPopoverWidget
        type={isUserTimeline ? "user" : "lock"}
        data={timelineState.userData}
        open={timelineState.userSummaryOpen}
        setOpen={setUserSummaryOpen}
        anchorEl={timelineState.userAnchorEl}
      />
    </ErrorBoundary>
  );
}

AccessHistoryTimelineWidget.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  isShowAuditTable: PropTypes.bool
};

export default AccessHistoryTimelineWidget;