const lsyNavStyle = (theme) => ({
  lockstasyNavTabs: {
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  lsyNavTabText: {
    fontSize: theme.lsyPalette.body2,
    color: theme.lsyPalette.text
  },
  indicator: {
    backgroundColor: theme.lsyPalette.indicator
  },
  tabSelected: {
    // color:`${theme.lsyPalette.indicator} !important`,
    "&.Mui-selected": {
      color: theme.lsyPalette.indicator
    }
  },
  tabRoot:{
    textTransform:"none"
  },
  //Bottom 2 classes are only used for old nav tabs design
  lsyNavText: {
    textAlign: "center",
    fontSize: "13px",
    color: "#544D5B"
  },
  lsyNavButton: {
    "height": "80%",
    "textTransform": "none",
    "color": "gray",
    "paddingLeft": "0px",
    "paddingRight": "0px",
    "&:hover": {
      boxShadow: "none"
    },
    "boxShadow": "none"
  }
});
export default lsyNavStyle;
