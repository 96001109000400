import { forEach } from "lodash";

export const TELEPORTE_SUPPORT_USER_EMAIL = "teleporte@sera4.com";

export const maxAPIKeys = 3;

export const accountStatusConstants = Object.freeze({
  DELETED : -1,
  INACTIVE: 0,
  ACTIVE  : 1,
  DISABLED: 2,
  LOCKED  : 3
});

export const membershipStatusNameConstants = Object.freeze({
  "-1": "label.deleted",
  "0": "label.inactive",
  "1": "label.enabled",
  "3": "label.suspended",
  "4": "label.locked"
});

export const mfaConstants = Object.freeze({
  DISABLED : 0,
  PENDING  : 1,
  ENABLED  : 2
});

const mfaNamesConst = Object.freeze({
  0: "label.disabled",
  1: "label.pending",
  2: "label.enabled"
});

export const accountConstantsById = (integer) => {
  let returnVal = null;

  forEach(accountStatusConstants, (value, key) => {
    if (parseInt(integer) === value) {
      returnVal = key;
      return false;
    }
  });

  return returnVal;
};

export const getUserStatusName = (status) => {
  return membershipStatusNameConstants[status];
};

export const isAccountStatusInactive = (status) => {
  return accountStatusConstants.INACTIVE === status;
};

export const isAccountStatusSuspended = (status) => {
  return accountStatusConstants.LOCKED === status;
};

export const mfaConstantsById = (integer) => {
  let returnVal = null;

  forEach(mfaConstants, (value, key) => {
    if (parseInt(integer) === value) {
      returnVal = key;
      return false;
    }
  });

  return returnVal;
};

export const getMfaStatusName = (status) => {
  return mfaNamesConst[status];
};

export const mfaMethods = Object.freeze(["TOTP"]);

export const MFA_CODE_LENGTH = 6;
export const MAX_EMAIL_LENGTH = 254;
export const MAX_USERNAME_LENGTH = 64;