export const notificationTypeConstants = Object.freeze({
  LATCH_SENSOR:                     1,
  DOOR_SENSOR:                      2,
  LOW_BATTERY:                      3,
  ACCESS_REQUEST:                   4,
  WORK_SESSION_CLOSE_NOT_CONFIRMED: 5,
  WORK_SESSION_TIME_EXCEEDED:       6,
  LOCK_NOTE:                        7,
  OPEN_LOCK:                        8,
  CUSTOM_LOCK:                      9,
  CUSTOM_USER:                      10,
  MFA_ENABLED:                      20,
  MFA_DISABLED:                     21
});