import { useContext, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { siteService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { Can } from "@casl/react";
import RecentlyUpdated from "_components/Helper/RecentlyUpdated";
import LsyRouter from "_components/Navigation/LsyRouter";

// @mui/material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/siteCard2Style.js";

//icons
import GenericLock from "assets/teleporte/GenericLock";
import LockIcons from "assets/teleporte/LockIcons";
import { VpnKey } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { controllerTypes } from "_constants/lock.constants";

const useStyles = makeStyles()(styles);

const invalidTypes = Object.freeze(["OTHER"]);

function SiteCard2(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { data } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const deleteSite = async (siteId, fetchWidgetData) => {
    try {
      await siteService.deleteSite({ id: siteId });
      dispatch(alertActions.send(t("success.deleteSite")));
      setTimeout(() => { // FIXME: this is due to a delay to update data in the backend database
        fetchWidgetData();
      }, [1000]);
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteSite"), "error"));
    }
  };

  return (
    <Card className={classes.card}>
      <div className={classes.divBar}/>
      <RecentlyUpdated updatedAt={data.updated_at}/>
      <p className={classes.siteName}>
        <LsyRouter className={classes.name} baseUrl={props.baseUrl} org={props.org} page="site" id={data.id}>
          {data.name}
        </LsyRouter>
      </p>
      {data.description ?
        <p className={classes.description}>
          <span>
            {data.description}
          </span>
        </p> : <div className={classes.placeholderDiv} />}
      <div className={classes.iconDiv}>
        {ability.can("read", "locks") && data.hardware_count ?
          Object.keys(data.hardware_count).map((type) => {
            let count = data.hardware_count[type] || 0;
            
            if (controllerTypes.includes(type)) {
              count = 0;
              for (const t of controllerTypes) {
                if(data.hardware_count[t]) {
                  count += data.hardware_count[t];
                  delete data.hardware_count[t];
                }
              }
            }
            
            if (!invalidTypes.includes(type) && count > 0) {
              return <Fragment key={type}>
                <LockIcons className={classes.icon} type={type} height="20px" width="20px" />
                <span className={classes.count}>{count}</span>
              </Fragment>;
            } else {
              return null;
            }
          }) : null}
        {ability.can("read", "keys") && data.key_count > 0 ?
          <Fragment>
            <VpnKey className={classes.vpnIcon} />
            <span className={classes.count}>{data.key_count}</span>
          </Fragment> : null}
        <GenericLock
          className={classes.lockStatusIcon}
          closed={!data.open}
        />
        <Can I="delete" on="lock_collections" ability={ability}>
          <Tooltip title={t("button.deleteSite")} size="small" classes={{ tooltip: classes.tooltip }} >
            <IconButton
              onClick={() => {
                props.createModal({
                  type: "confirm",
                  confirm: t("button.confirm"),
                  confirmAction: () => deleteSite(data.id, props.fetchWidgetData),
                  title: t("confirmation.deleteTitle"),
                  description: t("confirmation.deleteSite"),
                  submit: true
                });
              }}
              className={classes.deleteButton}
              aria-label="delete"
              size="large">
              <DeleteIcon className={classes.siteDelete} />
            </IconButton>
          </Tooltip>
        </Can>
      </div>
    </Card>
  );
}

SiteCard2.propTypes = {
  history: PropTypes.object,
  index: PropTypes.number,
  org: PropTypes.string,
  data: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  baseUrl: PropTypes.string
};

export default SiteCard2;
