import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  formatDateAsMMMMDDYYYYhhmmLocal
} from "_helpers";

import {
  Grid,
  Tooltip,
  Typography
} from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Table/workSessionTableItemStyle";

const useStyles = makeStyles()(styles);

function WorkSessionTableItem(props) {
  const { workSession, setSelectedWorkSession, isSelected } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");

  const renderStatus = () => {
    return <>
      {workSession.time_exceeded &&
        <Tooltip title={t("label.timeLimitExceeded")}>
          <AccessTimeIcon data-testid="AccessTimeIcon" className={classes.dotIcon} />
        </Tooltip>
      }
      {!workSession.close_confirmed &&
        <Tooltip title={t("label.closeNotConfirmed")}>
          <LockOpenIcon data-testid="LockOpenIcon" className={classes.dotIcon} />
        </Tooltip>
      }
    </>;
  };

  const renderItemTitle = () => {
    return <Grid container direction="row" alignItems="center">
      <Grid item>
        <Typography className={classes.tableItemTitle}>
          <b>{formatDateAsMMMMDDYYYYhhmmLocal(workSession.started_at_time)}</b>
        </Typography>
      </Grid>
      <Grid item>
        {renderStatus()}
      </Grid>
    </Grid>;
  };

  const handleSelectWorkSession = () => {
    if (!setSelectedWorkSession) {
      return;
    }

    setSelectedWorkSession(workSession);
  };

  return <Grid container direction="column" data-testid="WorkSessionTableItem"
    className={cx(classes.tableItem, isSelected ? classes.tableItemSelected : "", setSelectedWorkSession ? classes.clickable : "")}
    onClick={handleSelectWorkSession}
  >
    <Grid item data-testid="WorkSessionTableItemTitle">
      { renderItemTitle() }
    </Grid>
    <Grid item data-testid="WorkSessionTableItemSubtitle">
      <Typography className={classes.tableItemSubtitle}>
        <b>{t("label.duration")}</b> {workSession.duration || t("label.ongoing")}
      </Typography>
    </Grid>
    <Grid item className={classes.width} data-testid="WorkSessionTableItemComment">
      <Typography className={classes.tableItemComment}>
        {workSession.comments}
      </Typography>
    </Grid>
  </Grid>;
}

WorkSessionTableItem.propTypes = {
  workSession: PropTypes.object.isRequired,
  setSelectedWorkSession: PropTypes.func,
  isSelected: PropTypes.bool
};

export default WorkSessionTableItem;