import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// @mui/material components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from "@mui/material";

//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";

//components
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

//styles
import { makeStyles } from "tss-react/mui";
import mapWidgetStyle from "assets/jss/widgets/maps/dynamicMapWidgetStyle";

const useStyles = makeStyles()(mapWidgetStyle);

const MapOptionBox = (props) => {
  const { mapTheme, setMapTheme, details, setDetails } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");

  return (
    <div className={cx(classes.optionBox, props.class)}>
      <Accordion className={classes.accordian} square={true}>
        <AccordionSummary
          classes={{ root: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordianSummary}
        >
          <p className={classes.optionsTitle}>{t("widgetField.options")}</p>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianDetails}>
          <div className={classes.switchOption}>
            <p>{t("label.contrast")}</p>
            <ToggleSwitch
              checked={mapTheme}
              handleChange={(e) => setMapTheme(e)}
              checkedIcon={null}
              height={15}
              width={30}
            />
          </div>
          <div />
          <div className={classes.switchOption}>
            <p>
              {t("label.poi")}
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={t("label.poiExp")}
              >
                <HelpIcon fontSize="small" className={classes.helpIcon} />
              </Tooltip>
            </p>
            <ToggleSwitch
              checked={details}
              handleChange={(e) => setDetails(e)}
              checkedIcon={null}
              height={15}
              width={30}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

MapOptionBox.propTypes = {
  mapTheme: PropTypes.bool,
  setMapTheme: PropTypes.func,
  details: PropTypes.bool,
  setDetails: PropTypes.func,
  class: PropTypes.object
};

MapOptionBox.defaultProps = {
  mapTheme: true,
  details: false
};

export default MapOptionBox;
