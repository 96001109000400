const chipDateTimeRangeStyle = theme => ({
  chip: {
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& input": {
      width: "150px"
    }
  },
  input: {
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": "300",
    "&.MuiInput-root": {
      marginTop: "auto",
      marginBottom: "auto"
    }
  }
});

export default chipDateTimeRangeStyle;