const chipDateStyle = theme => ({
  chip: {
    "padding": "0",
    "height": "27px",
    "width": "150px",
    "& .MuiChip-label": {
      padding: "0"
    }
  },
  input: {
    "fontSize": theme.lsyPalette.body1,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "height": "27px"
  }
});

export default chipDateStyle;