const rolesIconStyle = theme => ({
  rolesIcon: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  svg: {
    enableBackground: "new 0 0 230 234"
  }
});

export default rolesIconStyle;
