const passwordlessStyle = theme => ({
  spaceAround: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  topMargin: {
    marginTop: theme.spacing(2)
  },
  button: {
    textTransform: "none"
  },
  stepIcon: {
    "color": theme.lsyPalette.primary.main,
    "&.Mui-active": {
      color: theme.lsyPalette.indicator
    },
    "&.Mui-completed": {
      color: theme.lsyPalette.indicator
    }
  },
  activeIcon: {},
  completedIcon: {}
});

export default passwordlessStyle;