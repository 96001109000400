import PropTypes from "prop-types";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/notificationCardStyle.js";

const useStyles = makeStyles()(styles);

function NotificationCardSkeleton() {
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <div className={classes.skeletonDetails}>
        <CardContent className={classes.content}>
          <div  className={classes.title}>
            {<Skeleton variant="rectangular" width="27px" height="27px" />}
            <span  className={classes.titleText}><Skeleton variant="rectangular" width="100px" height="27px" /></span>
          </div>
          <div  className={classes.message}>
            <Skeleton variant="rectangular" width="500px" height="18px" />
          </div>
        </CardContent>
        <div className={classes.actions}>
          <div  className={classes.date}><Skeleton variant="rectangular" width="180px" height="12px" /></div>
          <Skeleton className={classes.skeletonButton} variant="rectangular" width="20px" height="20px" />
          <Skeleton variant="rectangular" width="20px" height="20px" />
        </div>
      </div>
    </Card >);
}

NotificationCardSkeleton.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  alert: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  children: PropTypes.element
};

export default NotificationCardSkeleton;
