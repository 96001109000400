import { Fragment } from "react";

import classes from "./Layout.css";
/* Not used as GDPR isn't requiring a footer for a simple cookie usage - keeping around as it's finished/styled */
// import CookieFooter from "../../_containers/CookieFooter/CookieFooter";

/* Core page content */
const layout = ( props ) => (
  <Fragment>
    <main className={classes.Content}>
      {props.children}
    </main>
    {/* <CookieFooter /> */}
  </Fragment>
);

export default layout;
