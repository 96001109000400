const spacing = {
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
};

const collapsePanelStyle = (theme) => ({
  body: {
    marginTop: "20px"
  },
  panel: {
    border: "1px solid",
    borderColor: theme.lsyPalette.border.lightGrey,
    borderRadius: "8px",
    ...spacing
  },
  subPanel: {
    ...spacing
  },
  description: {
    color: theme.lsyPalette.primary.mainLight
  },
  divider: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: theme.lsyPalette.border.lightGrey
  },
  icon: {
    color: theme.lsyPalette.primary.mainDark
  },
  clickableGrid: {
    cursor: "pointer"
  }
});
  
export default collapsePanelStyle;