import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { fetchErrors, fetchStatus } from "_utils";
import appRoutes from "_routes";
import { genericReducer } from "_reducers/general.reducer";
import { localeActions } from "_actions";
import { authService } from "_services";

import LoginFrame from "_components/Auth/LoginFrame";
import PasswordlessForm from "_components/Auth/LoginForm/PasswordlessForm";

const EmailLink = (props) => {
  const { t } = useTranslation(["default", "auth"]);
  const dispatch = useDispatch();
  const [ state, setState ] = useReducer(genericReducer,
    {
      open: false,
      isLoading: false,
      language: "en",
      username: "",
      isUsernameEmpty: false,
      showPasswordlessInfo: false,
      showPasswordlessNotif: false,
      passwordlessNotifSeverity: "info",
      passwordlessNotifMessage: ""
    }
  );

  useEffect(() => {
    if (!state.open) {
      setTimeout(() => setState({ open: true, ...history.state?.state }), 500);
    }

    if (state.showPasswordlessNotif && history.state?.state?.showPasswordlessNotif) {
      window.history.replaceState({}, document.title);
    }
  });

  const setUsername = username => setState({ 
    username: username,
    isUsernameEmpty: isEmpty(username)
  });

  const getErrorMsg = (e) => {
    let errorMsg = t("auth:passwordless.errorNotif");
    const errors = fetchErrors(e);
    const status = fetchStatus(e);

    if (status === 404 && errors.account) {
      if (errors.account.msg === "not_found") {
        errorMsg = t("auth:passwordless.accountNotFound");
      }
    }

    return errorMsg;
  };

  const onClickSignIn = async () => {
    if (isEmpty(state.username)) {
      setState({ isUsernameEmpty: isEmpty(state.username) });
      return;
    }

    setState({ isLoading: true });
    
    try {
      await authService.loginPasswordless({ username: state.username });
      setState({
        showPasswordlessNotif: true,
        passwordlessNotifSeverity: "success",
        passwordlessNotifMessage: t("auth:passwordless.successNotif")
      });
    } catch (e) {
      setState({
        showPasswordlessNotif: true,
        passwordlessNotifSeverity: "error",
        passwordlessNotifMessage: getErrorMsg(e)
      });
    }

    setState({ isLoading: false });
  };

  const onChangeLanguage = (language) => {
    dispatch(localeActions.setLocale({ language: language }));
    setState({ language: language });
  };

  const openPassword = () => props.history.push(appRoutes.public.password);

  const renderContent = () => {
    return <PasswordlessForm
      username={state.username}
      isUsernameEmpty={state.isUsernameEmpty}
      isLoading={state.isLoading}
      setUsername={setUsername}
      onChangeLanguage={onChangeLanguage}
      openPassword={openPassword}
      onClickSignIn={onClickSignIn}
      notification={{
        showPasswordlessNotif: state.showPasswordlessNotif,
        passwordlessNotifSeverity: state.passwordlessNotifSeverity,
        passwordlessNotifMessage: state.passwordlessNotifMessage
      }}
    />;
  };

  return <LoginFrame open={state.open}>{renderContent()}</LoginFrame>;
};

EmailLink.propTypes = {
  history: PropTypes.object.isRequired
};

export default EmailLink;