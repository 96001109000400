/* This file compliments member.actions - if there is a dispatch call, please use that file instead */
import { get, post, put, del } from "_helpers/axios/gw.client";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";
import { getLocalTZ } from "_helpers";

import {
  lockdepotUrl,
  lsyShadowUrl
} from "_services/helper";


/* funny call, as this one requires ONE membership ID to succeed */
const fetchLocks = async (options = { }) => {
  options.page_size = options.page_size || 1500;

  var requestOptions = {
    ...options,
    page_index: options.page_index,
    lock_id: options.lock_id?.toString(),
    include: "license&include=firmware_current&include=firmware_next"
  };

  return get(`${lockdepotUrl}/locks`,requestOptions);
};

const fetchLocksFromTenant = async (options) => {
  const { tenant_id, ...rest } = options;

  return get(`${lsyShadowUrl(tenant_id)}/locks`, rest);
};

/* funny call, as this one requires ONE membership ID to succeed */
const fetchLock = async (lockId) => {
  const options = {
    include: "license&include=firmware_current&include=firmware_next&include=rtm_board&include=battery_type"
  };
  return get(`${lockdepotUrl}/locks/${lockId}`, options);
};

const fetchOTAOptions = async (lockId) => {
  return get(`${lockdepotUrl}/locks/${lockId}/ota_options`);
};

const updateLock = async (lockId, params = {}) => {
  return put(`${lockdepotUrl}/locks/${lockId}`, params);
};

// transfer to a new tenant
const transferLock = async (lockId, params = {}) => {
  return post(`${lockdepotUrl}/locks/${lockId}/transfer`, params);
};

/* calls all the way into LSY */
const fetchDeepLockData = async (lockId) => {
  return get(`${lockdepotUrl}/locks/${lockId}/lockstasy`);
};

const bulkCreateLocks = async (payload = { }) => {
  return post(`${lockdepotUrl}/locks/bulk`, payload);
};

const bulkUpdateLocks = async (payload = { }) => {
  return put(`${lockdepotUrl}/locks/bulk/update`, payload);
};

const bulkCancelOtaLocks = async (payload) => {
  return post(`${lockdepotUrl}/locks/bulk/cancel_ota`, payload);
};

const bulkTransferLocks = async (payload = { }, isTransferAll = false) => {
  const { include_license } = payload;
  delete payload.include_license;
  var url = `${lockdepotUrl}/locks/bulk/transfer?transfer_all=${isTransferAll}`;
  if (include_license) {
    url += "&include_license=true";
  }
  return post(url, payload);
};

const deleteSemaphore = async (semaphoreId) => {
  return del(`${lockdepotUrl}/lock_semaphores/${semaphoreId}`);
};

const fetchSemaphores = async ({ entries, lock_id, accountId, includeDeleted }) => {
  const options = { entries, lock_id, accountId, includeDeleted};

  var additionalArgs = transformToQueryString(sanitizeKeys(options, ["entries"]));
  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
  }

  return get(`${lockdepotUrl}/lock_semaphores?page_size=${entries || 10}${additionalArgs}`);
};

const generateILSUrl = async (lockId) => {
  return get(`${lockdepotUrl}/locks/${lockId}/ils_url`);
};

const generateAccessCode = async (lockId, payload) => {
  return post(`${lockdepotUrl}/locks/${lockId}/access_code`, payload);
};

const resetILS = async (lockId) => {
  return post(`${lockdepotUrl}/locks/${lockId}/reset`, {
    resetLock: true
  });
};

const fetchLockLogs = async (options) => {
  options.page_size = options.page_size || 1500;
  return get(`${lockdepotUrl}/lock_logs`, options);
};

const resyncLockLogs = async (options) => {
  options.page_size = options.page_size || 100;
  return get(`${lockdepotUrl}/lock_logs/resync`, options);
};

const fetchLockBatteryLogs = async (options) => {
  options.page_size = options.page_size || 1500;
  options.timezone = encodeURIComponent(getLocalTZ());

  return get(`${lockdepotUrl}/lock_logs/battery_status`, options);
};

export const lockService = {
  updateLock,
  transferLock,
  fetchLock,
  fetchLocksFromTenant,
  fetchDeepLockData,
  fetchLocks,
  fetchLockBatteryLogs,
  fetchOTAOptions,
  bulkUpdateLocks,
  bulkCancelOtaLocks,
  bulkCreateLocks,
  bulkTransferLocks,
  fetchSemaphores,
  deleteSemaphore,
  generateILSUrl,
  fetchLockLogs,
  resyncLockLogs,
  generateAccessCode,
  resetILS
};
