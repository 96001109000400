const userEditStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 10px 5%"
  },
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    borderRadius: "3px",
    marginBottom: "25px",
    height: "100%"
  },
  margin: {
    marginTop: "1%",
    marginBottom: "1%"
  },
  panelSpacing: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  },
  content: {
    marginTop: "20px"
  },
  description: {
    color: theme.lsyPalette.primary.mainLight,
    display: "block",
    [theme.breakpoints.down("md")]: {width: "165px"},
    [theme.breakpoints.up("sm")]: {width: "300px"},
    [theme.breakpoints.up("md")]: {width: "400px"}
  },
  divider: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: theme.lsyPalette.border.lightGrey
  },
  icon: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": "middle",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  sizeBreak: {
    [theme.breakpoints.down("md")]: {width: "165px"},
    [theme.breakpoints.up("sm")]: {width: "300px"},
    [theme.breakpoints.up("md")]: {width: "400px"}
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  },
  button: {
    color: theme.lsyPalette.primary.mainDark,
    borderColor: theme.lsyPalette.primary.mainDark,
    textTransform: "none"
  },
  internalIcon: {
    padding: "0px"
  },
  iconButton: {
    padding: "4px"
  },
  chip: {
    marginLeft: "10px",
    display: "inline",
    fontSize: "12px",
    backgroundColor: "#F78F1E",
    fontWeight: "bold",
    color: "white"
  },
  modal: {
    maxWidth: "600px"
  }
});
  
export default userEditStyle;
  