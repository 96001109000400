import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setTitle } from "_helpers/page-title";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

//Core Components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lsyNavStyle.js";

import PropTypes from "prop-types";
import { lsyRouter } from "_helpers";


const useStyles = makeStyles()(styles);

export default function LsyNav(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const handleChange = (event) => {
    const newValue = parseInt(event.target.id.split("#")[1]);
    props.setCurrentNavTab(newValue);
    switch (newValue) {
      case 0:
        props.history.push(lsyRouter("sites"));
        setTitle(t("features.lockCollections"));
        break;
      case 1:
        props.history.push(lsyRouter("users"));
        setTitle(t("features.users"));
        break;
      case 2:
        props.history.push(lsyRouter("locks"));
        setTitle(t("features.locks"));
        break;
      case 3:
        props.history.push(lsyRouter("locations"));
        setTitle(t("features.locations"));
        break;
      default:
    }
  };

  function a11yProps(index) {
    return {
      "id": `lsyNavTab#${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  }

  useEffect(() => {
    switch (props.selectedRoute) {
      case "/sites":
        props.setCurrentNavTab(0);
        break;
      case "/users":
        props.setCurrentNavTab(1);
        break;
      case "/locks":
        props.setCurrentNavTab(2);
        break;
      case "/locations":
        props.setCurrentNavTab(3);
        break;
      default:
        break;
    }
  },[props, props.selectedRoute]);

  return (
    <div className={classes.lockstasyNavTabs}>
      {props.features ? (
        <Tabs
          value={props.currentNavTab >= 0 ? props.currentNavTab : false}
          onClick={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="navTabs"
          classes={{ indicator: classes.indicator, root: classes.tabRoot }}
        >
          <Tab
            disableRipple
            label={t("features.lockCollections")}
            {...a11yProps(0)}
            disabled={!props.features.lock_collections || !ability.can("read", "lock_collections")}
            className={classes.lsyNavTabText}
            classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
          />
          <Tab
            disableRipple
            disabled={!ability.can("read", "users")}
            label={t("features.users")}
            {...a11yProps(1)}
            className={classes.lsyNavTabText}
            classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
          />
          <Tab
            disableRipple
            disabled={!ability.can("read", "locks")}
            label={t("features.locks")}
            {...a11yProps(2)}
            className={classes.lsyNavTabText}
            classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
          />
          <Tab
            disableRipple
            label={t("features.locations")}
            disabled={!props.features.maps || !ability.can("read", "locations")}
            {...a11yProps(3)}
            className={classes.lsyNavTabText}
            classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
          />
        </Tabs>
      ) : null}
    </div>
  );
}
LsyNav.propTypes = {
  image: PropTypes.any,
  route: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  enable: PropTypes.bool,
  org: PropTypes.string,
  history: PropTypes.object,
  currentNavTab: PropTypes.number,
  setCurrentNavTab: PropTypes.func,
  features: PropTypes.object,
  baseUrl: PropTypes.string,
  selectedRoute: PropTypes.string,
  ability: PropTypes.object
};
