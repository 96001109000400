export const findNoteErrorString = (e, options) => {
  const status = e?.response?.status;

  if (!status)
    return options?.defaultMsg;

  switch (status) {
    case 402:
      return "error.enterpriseLicenseRequired";
    default:
      return options?.defaultMsg ? options.defaultMsg : "";
  }
};