/* Generic component to be used to show when a recent update happens on the parent component.
  It shows a bell icon and a tooltip.
  time, timeUnit, and diffComparator can be defined outside this component.
  By default time is 24, timeUnit is "hours" and diffComparator checks if updateAt happened in the last 24 hours.*/
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Tooltip from "@mui/material/Tooltip";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/recentlyUpdatedStyle.js";

const useStyles = makeStyles()(styles);

function RecentlyUpdated(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const { updatedAt, time, timeUnit, diffComparator } = props;

  const recentlyModified = () => {
    if (diffComparator) {
      return diffComparator(updatedAt);
    }
    
    return moment().diff(moment(updatedAt), timeUnit) <= time;
  };

  return updatedAt && recentlyModified() ? 
    <div data-testid="recentlyUpdatedId" className={classes.notificationIconDiv}>
      <Tooltip placement="right" title={t("label.recentlyModified")} classes={{ tooltip: classes.tooltip }} >
        <NotificationsActiveIcon data-testid="recentlyUpdatedIconId" className={classes.notificationIcon}/>
      </Tooltip>
    </div>
    : null;
}

RecentlyUpdated.defaultProps = {
  time: 24,
  timeUnit: "hours"
};

RecentlyUpdated.propTypes = {
  updatedAt: PropTypes.any,
  time: PropTypes.number,
  timeUnit: PropTypes.string,
  diffComparator: PropTypes.func
};

export default RecentlyUpdated;