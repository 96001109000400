import { twsWebUrl } from "_services/helper";
import { get, del, put, post } from "_helpers/axios/gw.client";

const fetchSites = async (options = {}) => {
  options.include = "key_count";
  return await get(`${twsWebUrl}/sites`, options);
};

const fetchSiteData = async (options) => {
  return await get(`${twsWebUrl}/sites/${options.siteId}`);
};

const fetchLocks = async (options) => {
  const siteId = options.siteId;
  delete options.siteId;

  return await get(`${twsWebUrl}/sites/${siteId}/locks`, options);
};

const fetchKeys = async (options) => {
  const siteId = options.siteId;
  delete options.siteId;
  return await get(`${twsWebUrl}/sites/${siteId}/keys/access`, options);
};

const addSite = async (options) => {
  const data = {
    site: options.data
  };
  return await post(`${twsWebUrl}/sites`, data);
};

const addLock = async (options) => {
  return await post(`${twsWebUrl}/sites/${options.siteId}/locks`, options.data);
};

const assignKey = async (options) => {
  return await post(`${twsWebUrl}/sites/${options.siteId}/keys`, options.data);
};

const updateSite = async (options) => {
  const data = {
    site: options.data
  };
  return await put(`${twsWebUrl}/sites/${options.siteId}`, data);
};

const removeLock = async (options) => {
  return await del(`${twsWebUrl}/sites/${options.siteId}/locks/${options.lockId}`);
};

const deleteSite = async (options) => {
  return await del(`${twsWebUrl}/sites/${options.id}`);
};

const deleteKey = async (options) => {
  return await del(`${twsWebUrl}/sites/${options.siteId}/keys/access/${options.keyId}`);
};

export const siteService = {
  fetchSites,
  fetchSiteData,
  fetchLocks,
  fetchKeys,
  addLock,
  assignKey,
  removeLock,
  deleteKey,
  addSite,
  updateSite,
  deleteSite
};