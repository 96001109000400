import { keyframes } from "tss-react";

const lsyHeaderStyle = theme => ({
  header:{
    position:"relative",
    boxShadow: "0 4px 6px -6px #222"

  },
  menuIcon:{
    color:theme.lsyPalette.primary.main
  },
  tooltip:{
    backgroundColor:theme.lsyPalette.primary.mainHover,
    color:theme.lsyPalette.textContrast
  },
  arrow:{
    color: theme.lsyPalette.primary.mainHover
  },
  navItem:{
    alignSelf:"flex-end"
  },
  userButton:{
    [theme.breakpoints.down("md")]: {
      "& .MuiButton-startIcon": {
        margin: "0px"
      }
    },
    minWidth: "fit-content",
    fontSize:theme.lsyPalette.body1,
    color:theme.lsyPalette.primary.main
  },
  notificationsButton:{
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2)
    },
    "fontSize":theme.lsyPalette.body1,
    "minWidth": "fit-content",
    "color":theme.lsyPalette.primary.main,
    "& .MuiButton-startIcon": {
      margin: "0px"
    }
  },
  authUserName:{
    [theme.breakpoints.down("md")]: {
      display:"none"
    },
    fontWeight:500,
    width: "75px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  logoImageDiv:{
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    },
    marginTop: "8px",
    overflow: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    cursor: "pointer"
  },
  logo: {
    width: "50px",
    display: "inline"
  },
  tpLogo: {
    height: "50px",
    marginLeft: "-18px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    top: "3px"
  },

  imageDiv:{
    overflow: "hidden",
    cursor:"pointer"
  },
  tpLogoContainer: {
    display: "inline"
  },
  designation: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.only("sm")]: {
      position: "absolute",
      top: "25px"
    },
    [theme.breakpoints.only("md")]: {
      position: "absolute",
      top: "25px",
      left: "187px"
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      top: "25px",
      left: "185px"
    },
    color: theme.palette.text.light,
    cursor: "default",
    fontSize: "12px"
  },
  userItem:{
    [theme.breakpoints.up("md")]: {
      alignSelf:"flex-end"
    },
    alignSelf:"center"
  },
  divider:{
    borderColor:theme.lsyPalette.secondary.background
  },
  announcementContainer: {
    margin: "5px 0 5px 0",
    textAlign: "center"
  },
  announcement: {
    fontWeight: "400",
    color: theme.lsyPalette.text,
    [theme.breakpoints.up("md")]: {
      fontSize:theme.lsyPalette.body2

    },
    [theme.breakpoints.down("md")]: {
      fontSize:theme.lsyPalette.body1

    }
  },
  pulse: {
    "height": theme.spacing(1),
    "width": theme.spacing(1),
    "position": "absolute",
    "right": theme.spacing(1),
    "top": theme.spacing(1),
    "transform": "translate(50%, -50%)",
    "&:before,:after": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      borderRadius: "100%"
    },
    "&:before": {
      animation: `${keyframes` 75%, 100% {
        transform: scale(2);
        opacity: 0;
    } `} 2s 1`,
      background: theme.lsyPalette.stdRed
    },
    "&:after": {
      background: theme.lsyPalette.stdRed
    }
  }
});

export default lsyHeaderStyle;