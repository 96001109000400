const swapStyle = theme => ({
  swap: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1",
    fillRule: "evenodd"
  },
  svg: {
  }
});
  
export default swapStyle;
  