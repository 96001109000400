import { useState, useReducer, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCustomCompareCallback, useCustomCompareEffect } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";

import { alertActions } from "_actions";
import {
  convertToHex,
  formatDateAsLocal,
  formatDateAsYYYYMM,
  formatDateAsMMMMYYYY,
  formatDateAsYYYYMMDD,
  formatDateAsYYYYMMDDhhmmss,
  getLocalTZ
} from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { accessHistoryService } from "_services";

import { Grid, Tooltip, Typography } from "@mui/material/";

import SystemChartsWidget from "./SystemChartsWidget";
import AccessHistoryTimelineWidget from "./AccessHistoryTimelineWidget";
import ErrorBoundary from "_components/ErrorBoundary";
import GridItem from "components/Grid/GridItem";

import IconButton from "@mui/material/IconButton";
import SwapIcon from "assets/teleporte/SwapIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessHistoryStyle";

const useStyles = makeStyles()(styles);

function AccessHistoryWidget(props) {
  const { history, baseUrl, org, type, data, startDate, endDate, 
    id, updateUrlQuery, filterVariables, isShowAuditTable } = props;

  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();

  const [state, setState] = useReducer(genericReducer,
    {
      chartData: [],
      chartGrouping: "month",
      chartLoading: true,
      startDate: startDate,
      endDate: endDate
    }
  );

  const [isShowTimeline, setIsShowTimeline] = useState(true);

  const titles = useMemo(() => {
    return type === "users" ?
      { chartTitle: t("features.accessChart"), timelineTitle: isShowAuditTable ? t("features.eventsTimeline") : t("features.accessTimeline") } :
      { chartTitle: t("features.lockChart"), timelineTitle: isShowAuditTable ? t("features.lockEventsTimeline") : t("features.lockTimeline") };
  }, [isShowAuditTable, type, t]);

  const fetchAccessHistoryStats = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      let groupBy;
      let dateDiff = moment(endDate).diff(moment(startDate), "days");
      if (dateDiff < 21) {
        groupBy = "day";
      } else if (dateDiff < 70) {
        groupBy = "week";
      } else {
        groupBy = "month";
      }
      
      const newStratDate = moment(startDate).startOf(groupBy);
      const newEndDate = moment(endDate).endOf(groupBy);
      const initiator_ids = filterVariables.search.map(v => v.id);
      const options = {
        start_date: formatDateAsYYYYMMDDhhmmss(newStratDate.utc()),
        end_date: formatDateAsYYYYMMDDhhmmss(newEndDate.utc()),
        group_by: groupBy,
        client_timezone: getLocalTZ()
      };

      let result;

      if (type === "users"){
        options["membershipId"] = id;
        options["lock_ids"] = initiator_ids.toString();
        options.lock_status = filterVariables.lock_status;
        result = await accessHistoryService.fetchAccessHistoryStats(options);
      } else if (type === "locks"){
        options["lockId"] = id;
        options["membership_ids"] = initiator_ids.toString();
        result = await accessHistoryService.fetchLockAccessHistoryStats(options);
      }

      let data = result.data;
      Object.keys(data)?.forEach((date) => {
        let newDate = date.split(" ")[0];
        data[newDate] = data[date];
        delete data[date];
      });
      setState({ chartData: data, chartLoading: false, chartGrouping: groupBy, startDate: newStratDate, endDate: newEndDate });
    } catch (e) {
      console.warn("Warning, failed to fetch user's access history data", e);
      dispatch(alertActions.send(t("error.fetchAccessHistory"), "error"));
    }
  }, [endDate, startDate, filterVariables.search, filterVariables.lock_status, type, id, dispatch, t]);

  useCustomCompareEffect(() => {
    setState({chartLoading: true});
    fetchAccessHistoryStats();

    let newState = {
      start_date: startDate,
      end_date: endDate
    };
    updateUrlQuery(newState);
  }, [startDate, endDate, filterVariables, state.sortDirection, state.rowsPerPage], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getFirstWeek = useCallback((startDate, endWeek) => {
    let week = moment(endWeek);

    while (startDate.isBefore(week)) {
      week = week.subtract(1, "week");
    }

    return week;
  }, []);

  const fillWeeks = useCallback((start_date, end_date, endWeek) => {
    let weeks = [];
    let week = getFirstWeek(start_date, endWeek);

    while (end_date.isAfter(week)) {
      week = week.add(1, "week");
      weeks.push(formatDateAsYYYYMMDD(week));
    }
    
    return weeks;
  }, [getFirstWeek]);

  const getXAxis = useCallback(() => {
    let axis = [];
    let count;
    const dates = Object.keys(state.chartData);
    if (state.chartGrouping !== "" && !isEmpty(dates)) {
      const end_date = moment(endDate);
      const start_date = moment(startDate);
      if (state.chartGrouping === "week") {
        return fillWeeks(start_date, end_date, dates[0]);
      }
      count = end_date.diff(start_date, state.chartGrouping + "s") + 2;
      for (let i = 0; i < count; i++) {
        axis.push(formatDateAsYYYYMMDD(moment(end_date).subtract(i, state.chartGrouping + "s").endOf(state.chartGrouping + "s")));
      }
      axis = axis.reverse();
    }

    return axis;
  }, [state.chartGrouping, state.chartData, startDate, endDate, fillWeeks]);

  const getOptions = useCustomCompareCallback(() => {
    const xAxis = getXAxis();
    const dataPoints = xAxis.map((date) => {
      return state.chartData[date] || 0;
    });

    const lockId = type === "locks" ? "_" + convertToHex(id, 4) : "";
    const nameToExport = `${titles.chartTitle.replaceAll(" ", "_")}${lockId}_${moment().format("YYYY-MM-DD_HH-mm-ss")}`;

    const options = {
      toolbox: {
        feature: {
          magicType: { 
            type: ["line", "bar"],
            title: {
              line: t("widget.chart.lineChart"),
              bar: t("widget.chart.barChart")
            }
          },
          saveAsImage: {
            show: true,
            title: t("widget.chart.saveImage"),
            name: nameToExport
          }
        }
      },
      xAxis: {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          formatter: function (value) {
            if (state.chartGrouping === "month") {
              return formatDateAsYYYYMM(value);
            }
            return value;
          }
        }
      },
      yAxis: {
        type: "value",
        minInterval: 1
      },
      series: [
        {
          data: dataPoints,
          type: "line"
        }
      ],
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          const param = params[0];
          let title = param.axisValue;
          if (state.chartGrouping === "week") {
            const startOfWeek = formatDateAsLocal(moment(param.axisValue).subtract(6, "day"));
            const endOfWeek = formatDateAsLocal(moment(param.axisValue));
            title = t("accessHistory.chart.weekRange", { startDate: startOfWeek, endDate: endOfWeek});
          } else if (state.chartGrouping === "month") {
            title = formatDateAsMMMMYYYY(param.axisValue);
          }
          return `${title}<br/>
            ${param.marker} ${t("label.accessesChart")}: ${param.value}<br/>`;
        }
      },
      grid: {
        left: "30", right: "0", top: "5%"
      }
    };

    return options;
  }, [data, state.chartData], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const displayTooltipTitle = () => {
    return isShowTimeline ? titles.chartTitle : titles.timelineTitle;
  };

  const getTableTitle = () => {
    return isShowAuditTable || isShowTimeline ? titles.timelineTitle : titles.chartTitle;
  };

  return (
    <ErrorBoundary>
      <GridItem className={classes.chartContainer} data-testid="chart" xs={12} md={5}>
        <Grid container justifyContent="space-between">
          <Grid item >
            <Typography variant="body1">
              <b>{getTableTitle()}</b>
            </Typography>
          </Grid>
          {!isShowAuditTable && <Grid item >
            <Tooltip title={displayTooltipTitle()}>
              <IconButton size="small" data-testid="swapButton" onClick={() => setIsShowTimeline(!isShowTimeline)}>
                <SwapIcon className={classes.chipIcon} pathClassName={classes.mainLight} sortDirection={isShowTimeline ? "ASC" : "DESC"} width="20" height="20"/>
              </IconButton>
            </Tooltip>
          </Grid>}
        </Grid>
        {isShowAuditTable || isShowTimeline ? 
          <div data-testid="timelineContainer">
            <AccessHistoryTimelineWidget
              history={history}
              baseUrl={baseUrl}
              org={org}
              type={type} 
              data={data} 
              getOptions={getOptions}
              startDate={startDate} 
              endDate={endDate}
              isShowAuditTable={isShowAuditTable}
            /> 
          </div> : 
          <div data-testid="chartContainer">
            <SystemChartsWidget
              description={t("accessHistory.chart.chartDescription")}
              startDate={state.startDate} 
              endDate={state.endDate} 
              getOptions={getOptions} 
              chartLoading={state.chartLoading}
              classNameChart={classes.chart}
            />
          </div>}
      </GridItem>
    </ErrorBoundary>
  );
}

AccessHistoryWidget.propTypes = {
  history: PropTypes.object,
  baseUrl: PropTypes.string,
  org: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  id: PropTypes.string,
  updateUrlQuery: PropTypes.func,
  filterVariables: PropTypes.object,
  isShowAuditTable: PropTypes.bool
};

export default AccessHistoryWidget;