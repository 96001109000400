import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MAX_EMAIL_LENGTH } from "_constants";
import { genericReducer } from "_reducers/general.reducer";
import appRoutes from "_routes";

import ContainedButton from "_components/Button/ContainedButton";
import SelectLanguage from "_components/Select/SelectLanguage";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CheckRounded from "@mui/icons-material/CheckRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import LinkIcon from "assets/teleporte/LinkIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Auth/passwordlessFormStyle";

const useStyles = makeStyles()(styles);

const PasswordlessForm = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const { username, isUsernameEmpty, notification, isLoading, setUsername, onChangeLanguage, onClickSignIn } = props;

  const [ state, setState ] = useReducer(genericReducer, {
    showPasswordlessInfo: false,
    showPasswordlessNotif: notification.showPasswordlessNotif,
    passwordlessNotifSeverity: notification.passwordlessNotifSeverity,
    passwordlessNotifMessage: notification.passwordlessNotifMessage
  });

  const setShowPasswordlessInfo = () => setState({
    showPasswordlessInfo: !state.showPasswordlessInfo,
    showPasswordlessNotif: !state.showPasswordlessInfo,
    passwordlessNotifSeverity: "info",
    passwordlessNotifMessage: t("auth:passwordless.description")
  });

  useEffect(() => {
    setState({
      showPasswordlessNotif: notification.showPasswordlessNotif,
      passwordlessNotifSeverity: notification.passwordlessNotifSeverity,
      passwordlessNotifMessage: notification.passwordlessNotifMessage
    });
  }, [notification]);

  const handleChangeUsername = event => setUsername(event.target.value);

  const handleKeypress = event => {
    if (event.key === "Enter") {
      onClickSignIn();
    }
  };

  const history = useHistory();
  const goToSignInOptions = () => history.push(appRoutes.public.login);

  const renderUsernameField = () => {
    return <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={10}>
        <TextField
          variant="outlined"
          fullWidth required
          error={isUsernameEmpty}
          id="login.name"
          label={`${t("auth:login.email")}/${t("auth:login.username")}`}
          inputProps={{maxLength: MAX_EMAIL_LENGTH}}
          name="username"
          autoComplete="username"
          value={username}
          autoFocus={true}
          onChange={handleChangeUsername}
          helperText={isUsernameEmpty ? t("error.requiredField") : null}
          onKeyDown={handleKeypress}
        />
      </Grid>
    </Grid>;
  };

  const renderSignInButton = () => {
    return <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <ContainedButton
          fullWidth
          variant="contained"
          color="secondary"
          disabled={isLoading}
          startIcon={isLoading ?
            <CircularProgress className={classes.spinner} size={30} /> :
            <LinkIcon/>}
          onClick={onClickSignIn}
        >
          {isLoading ? t("auth:passwordless.loadingButton") : t("auth:login.button")}
        </ContainedButton>
      </Grid>
    </Grid>;
  };

  const renderNotifications = () => {
    return <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="caption" onClick={setShowPasswordlessInfo} className={classes.link} >
              {state.showPasswordlessInfo ? t("button.hideInfo") : t("auth:passwordless.showInfo")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Collapse in={state.showPasswordlessNotif}>
          <Alert 
            iconMapping={{success: <CheckRounded fontSize="inherit" />}}
            severity={state.passwordlessNotifSeverity}
            style={{alignItems: "center"}}
          >
            <Typography>{state.passwordlessNotifMessage}</Typography>
          </Alert>
        </Collapse>
      </Grid>
    </Grid>;
  };

  const renderFooter = () => {
    return <Grid container justifyContent="center" alignItems="center" className={classes.spaceAround}>
      <Grid item xs={10}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <SelectLanguage showLabel={false} handleSubmit={onChangeLanguage} selectClass={classes.select}/>
          </Grid>
          <Grid item>
            <Button 
              variant="text"
              size="small"
              className={classes.button}
              startIcon={<KeyboardBackspaceRoundedIcon/>}
              onClick={goToSignInOptions}
            >
              {t("button.backSignIn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.topMargin}>
    <Grid item>
      {renderUsernameField()}
    </Grid>
    <Grid item>
      {renderSignInButton()}
    </Grid>
    <Grid item>
      {renderNotifications()}
    </Grid>
    <Grid item>
      {renderFooter()}
    </Grid>
  </Grid>;
};

PasswordlessForm.defaultProps = {
  notification: {
    showPasswordlessNotif: false,
    passwordlessNotifSeverity: "info",
    passwordlessNotifMessage: ""
  }
};

PasswordlessForm.propTypes = {
  username: PropTypes.string,
  isUsernameEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  setUsername: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onClickSignIn: PropTypes.func.isRequired,
  notification: PropTypes.object
};

export default PasswordlessForm;