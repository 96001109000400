import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { genericReducer } from "_reducers/general.reducer";
import { getDevice, getFilterVars } from "_helpers";
import { localeActions } from "_actions";
import { translateFormatter } from "_utils";

import {
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";

import Loader from "_components/Loading";
import LoginFrame from "_components/Auth/LoginFrame";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/loginMobStyle";
import appStoreLogo from "assets/img/badges/appStore.svg";

const useStyles = makeStyles()(styles);

const defaultInitialState = {
  language: "en"
};

const LoginMob = () => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default"]);
  const [ state, setState ] = useReducer(genericReducer,
    {
      open: false,
      isLoading: true
    }
  );

  const formatter = translateFormatter();
  
  const history = useHistory();
  const { search } = history.location;
  const params = getFilterVars(defaultInitialState, search);
  const dispatch = useDispatch();

  const redirectToStore = () => {
    switch (getDevice()) {
      case "Android":
        window.location.href =
        "https://play.google.com/store/apps/details?id=ca.lockedup.teleporte&hl=en&gl=US";
        break;
      case "iOS":
        window.location.href = "https://apps.apple.com/ca/app/teleporte/id1068979703";
        break;
    }
  };
  
  useEffect(() => {
    if (!state.open) {
      setTimeout(() => {
        setState({ open: true, isLoading: false });
        dispatch(localeActions.setLocale({ language: params.language }));
        redirectToStore();
      }, 1000);
    }
  });

  const renderTroubleshootingSteps = () => {
    formatter.setBoldText("continueSignIn", t("instructions.signInIssue.continueSignIn"));
    formatter.setBoldText("copyLink", t("instructions.signInIssue.copyLink"));
    formatter.setBoldText("oneTimePassword", t("instructions.signInIssue.oneTimePassword"));
    formatter.setBoldText("signIn", t("instructions.signInIssue.signIn"));
    formatter.setBoldText("troubleshooting", t("instructions.signInIssue.troubleshooting"));

    return <Grid item xs={10}>
      <Typography variant="subtitle2">{`2. ${t("instructions.signInIssue.title")}`}</Typography><br/>
      <Typography variant="caption">
        {t("instructions.signInIssue.description", formatter.getInterpolatedKeys())}
      </Typography><br/>
      <Typography variant="caption">
        {t("instructions.signInIssue.stepsTitle", formatter.getInterpolatedKeys())}
      </Typography>
      <List dense disablePadding sx={{ listStyleType: "disc", pl: 4 }}>
        <ListItem key="signInIssueStep1" disableGutters sx={{ display: "list-item" }}>
          <Typography variant="caption">
            {t("instructions.signInIssue.step1", formatter.getInterpolatedKeys())}
          </Typography>
        </ListItem>
        <ListItem key="signInIssueStep2" disableGutters sx={{ display: "list-item" }}>
          <Typography variant="caption">
            {t("instructions.signInIssue.step2", formatter.getInterpolatedKeys())}
          </Typography>
        </ListItem>
        <ListItem key="signInIssueStep3" disableGutters sx={{ display: "list-item" }}>
          <Typography variant="caption">
            {t("instructions.signInIssue.step3", formatter.getInterpolatedKeys())}
          </Typography>
        </ListItem>
        <ListItem key="signInIssueStep4" disableGutters sx={{ display: "list-item" }}>
          <Typography variant="caption">
            {t("instructions.signInIssue.step4", formatter.getInterpolatedKeys())}
          </Typography>
        </ListItem>
      </List>
    </Grid>;
  };

  return state.isLoading ? <Loader title={t("actions.redirecting")} /> :
    <LoginFrame open={state.open}>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.spaceAround}>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={10}>
              <Typography>{t("description.teleporte")}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2">{`1. ${t("instructions.downloadApp")}`}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item>
                  <a href='https://play.google.com/store/apps/details?id=ca.lockedup.teleporte&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                      width={208} height={80}/>
                  </a>
                </Grid>
                <Grid item>
                  <a href='https://apps.apple.com/ca/app/teleporte/id1068979703'>
                    <img src={appStoreLogo}/>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {renderTroubleshootingSteps()}
          </Grid>
        </Grid>
      </Grid>
    </LoginFrame>;
};

export default LoginMob;