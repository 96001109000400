/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useReducer, Fragment } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";

import { useDispatch } from "react-redux";
import { each } from "lodash";
// @mui/material components
import { makeStyles } from "tss-react/mui";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import RegularButton from "_components/Button/RegularButton";
import DateTimeRange from "_components/Date/DateTimeRange";

import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  Tooltip
} from "@mui/material";

import LoadingPlaceHolder from "_components/Loading";
import { licenseService } from "_services/admin";
import {
  lockLicenseConstants,
  lockLicenseConstantsById,
  licensesNotRequiringInvoices
} from "_constants/lock.constants";

import { genericReducer } from "_reducers/general.reducer";
import { alertActions } from "_actions";
import {
  isBlank,
  findLockErrorString
} from "_helpers";

import moment from "moment";
import baseStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles()(theme => ({
  ...baseStyles,
  tooltip: tooltip,
  expiredWarning: {
    textAlign: "center",
    color: theme.lsyPalette.stdRed,
    fontWeight: "bold"
  },
  labelHorizontal: {
    ...baseStyles.labelHorizontal,
    textTransform: "capitalize"
  },
  invoiceLabelRoot: {
    marginTop: "0.5em"
  },
  optionsLabel: {
    paddingTop: "0px"
  }
}));

export default function EditLockLicense({ lockId, submitHandler, readOnly = false, refreshData }) {
  const { classes } = useStyles();
  // const editFormHandle = useRef(null);

  const { register, handleSubmit, setValue, formState: { errors }, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const [lockLicenseData, setLockLicenseData] = useState({});
  const [editLicense, setEditLicense] = useState(false);
  const [customLicense, setCustomLicense] = useState(false);

  //Default renewal set to 3 months
  const [customDate, setCustomDate] = useReducer(genericReducer,
    {
      startDate: moment().startOf("day"),
      endDate: moment().add(3, "months").endOf("month").endOf("day")
    }
  );
  const setStartDate = (date) => setCustomDate({ startDate: moment(date) });
  const setEndDate = (date) => setCustomDate({ endDate: moment(date) });

  // form data
  const [licenseId, setLicenseId] = useState(null);

  // either move or edit
  const dispatch = useDispatch();

  const getLicense = useCallback(async (id) => {
    try {
      let result = await licenseService.fetchLockLicense(id);
      if (result) {
        // current one
        setLockLicenseData(result);
      }
    } catch (e) {
      console.warn(e.response);
    }
  });

  useEffect(() => {
    getLicense(lockId);

    return () => {
      setEditLicense(false);
      setLockLicenseData({});
    };
  }, [lockId]);

  const onLicenseSubmit = async (data) => {
    submitHandler(true);

    // data.year !== custom indicates the preset buttons were used instead of datepicker
    if (data.year !== "custom"){
      data.valid_from = moment().utc().startOf("day");

      //Licenses are set to end of month as requested in TWS-1187
      data.valid_till = moment().utc().add(data.year, "years").endOf("month").endOf("day");
    } else {
      data.valid_from = moment.utc(customDate.startDate.utc(true)).startOf("day").format();
      data.valid_till = moment.utc(customDate.endDate.utc(true)).endOf("day").format();
    }

    try {
      if (isBlank(data.invoice))
        delete data.invoice;

      const result = await licenseService.updateLockLicense(lockId, data);
      if (result) {
        setLockLicenseData(result);
        setEditLicense(false);
        dispatch(alertActions.send("Lock License Successfully Updated"));
        refreshData();
      }
    } catch (e) {
      let warningMessage = findLockErrorString(e, {
        resourceName: "License"
      });

      if (isBlank(warningMessage)) {
        dispatch(alertActions.send(`Error Updating Lock License: ${e.message}`, "error"));
      } else {
        dispatch(alertActions.send(warningMessage, "error"));
      }
    }

    submitHandler(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      setLicenseId(e.currentTarget.dataset.value);
    }
  };

  const licenseChoices = () => {
    var td = [];
    each(lockLicenseConstants, (val, key) => {
      td.push(
        (
          <MenuItem
            key={lockId + "-" + val}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={val}
            onKeyDown={handleKeyDown}
          >
            {key}
          </MenuItem>
        )
      );
    });

    return td;
  };

  const noLicenseData = () => <h5>Unlicensed Lock</h5>;

  const applyUtcOffset = (date) => {
    return moment(date).subtract(moment(date).utcOffset(), "minutes");
  };

  const showLicenseBody = () => {
    let licenseData = {};
    let showExpired = false;
    let licensed = false;

    if (lockLicenseData && lockLicenseData.valid_from) {
      licenseData = lockLicenseData;
      licensed = true;
      if (licenseData.valid_till && (moment(licenseData.valid_till).endOf("day") < moment().endOf("day")))
      {
        showExpired = true;
      }

    }

    const renderCustomDates = () => {
      return <GridItem xs={12}>
        <GridContainer justifyContent="flex-end" className={classes.customLicenseGrid}>
          <GridItem xs={12}>
            <DateTimeRange 
              startDate={customDate.startDate}
              setStartDate={setStartDate}
              endDate={customDate.endDate}
              setEndDate={setEndDate}
              label={"Valid From ~ Valid Till"}
              format="YYYY/MM/DD"
              showTime={false}
              inputProps={{ variant: "standard" }}
              minDate={moment().startOf("day").toDate()}
              calendarProps={{ calendarPosition: "top-left", fixMainPosition: true }}
            />
          </GridItem>
          <GridItem>
            <RegularButton
              type="submit"
              onClick={() => {
                setValue("year", "custom", {
                  shouldValidate: true,
                  shouldDirty: true
                });
              }}
            >Set License</RegularButton>
          </GridItem>
        </GridContainer>
      </GridItem>;
    };

    const handleDemoDates = () => {
      setValue("year", "custom", {
        shouldValidate: true,
        shouldDirty: true
      });
      setCustomDate({
        startDate: moment().utc().startOf("day"),
        endDate: moment("2099-03-30").utc().endOf("day")
      });
    };

    return (
      <Fragment>
        { !licensed ?
          <GridContainer>
            <GridItem xs={12}>
              <hr />
              <GridContainer justifyContent="center">
                <GridItem style={{ textAlign: "center" }}>
                  {noLicenseData()}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer> :
          <GridContainer>
            <GridItem xs={12}>
              <hr />
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <b>License Class</b>: {lockLicenseConstantsById(licenseData.license_definition_id) || "UNKNOWN"}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <b>Updated At</b>: <Tooltip title={moment(licenseData.updated_at).format()} aria-label="Updated At"><span>{licenseData.updated_at ? applyUtcOffset(licenseData.updated_at).format("L") : "No date"}</span></Tooltip>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <b>Valid From</b>: <Tooltip title={moment(licenseData.valid_from).format()} aria-label="Valid From"><span>{licenseData.valid_from ? applyUtcOffset(licenseData.valid_from).format("L") : "No date"}</span></Tooltip>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <b>Valid Till</b>: <Tooltip title={moment(licenseData.valid_till).format()} aria-label="Valid Till"><span>{licenseData.valid_till ? moment(licenseData.valid_till).format("L") : "No date"}</span></Tooltip>
                </GridItem>
                <GridItem xs={12}>
                  <b>Invoice</b>: {licenseData.user_data ? licenseData.user_data.invoice : "-"}
                </GridItem>
                {showExpired ?
                  <GridItem xs={12} className={classes.expiredWarning}>
                    <h5>EXPIRED</h5>
                  </GridItem> :
                  (null)
                }
              </GridContainer>
            </GridItem>
          </GridContainer>
        }

        { (editLicense && !readOnly) ?
          <form onSubmit={handleSubmit(onLicenseSubmit)}>
            <GridContainer justifyContent="space-around">
              <GridItem xs={12}>
                <FormControl
                  variant="standard"
                  fullWidth
                  style={{ marginTop: "15px" }}
                  className={classes.selectFormControl}
                  error={Boolean(errors.license_definition_id)}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.select}
                  >
                    Choose a License
                  </InputLabel>
                  <Controller
                    render={({ field }) => <Select
                      {...field}
                      variant="standard"
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      inputProps={{
                        name: "license_definition_id",
                        id: "license_definition_id",
                        onChange: (event) => {
                          setLicenseId(event.target.value);
                        }
                      }}
                      disabled={readOnly}>
                      {licenseChoices()}
                    </Select>}
                    control={control}
                    rules={{
                      required: "You must choose a valid license"
                    }}
                    defaultValue=""
                    name="license_definition_id"
                  />
                  <GridContainer justifyContent="center">
                    <GridItem xs={12}>
                      <CustomInput
                        id="invoice_number"
                        labelText="Invoice #"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.invoiceLabelRoot
                        }}
                        inputProps={{
                          defaultValue: "",
                          name: "invoice",
                          ...register("invoice", {
                            validate: (value) => {
                              if (!licensesNotRequiringInvoices.includes(licenseId)) {
                                return ((value && value.length >= 2));
                              }
                              return true;
                            }
                          }),
                          disabled: readOnly,
                          autoComplete: "off"
                        }}
                        error={!!errors.invoice}
                      />
                    </GridItem>
                  </GridContainer>
                  <FormHelperText>
                    {errors.license_definition_id && errors.license_definition_id.message}
                  </FormHelperText>
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <p className={classes.presetInfo}>Renew license with preset amounts below or select a custom license:</p>
                <RegularButton {...register("year")}
                  type="submit"
                  onClick={() => {
                    setValue("year", "1", {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                  }}
                >+ 1 year</RegularButton>
                <RegularButton
                  type="submit"
                  onClick={() => {
                    setValue("year", "3", {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                  }}
                >+ 3 years</RegularButton>
                <RegularButton
                  type="submit"
                  onClick={() => {
                    setValue("year", "5", {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                  }}>
                  + 5 years
                </RegularButton>
                <Tooltip title={"Set license valid until 2099-03-30"}>
                  <RegularButton
                    type="submit"
                    onClick={handleDemoDates}>
                    DEMO/LTOL
                  </RegularButton>
                </Tooltip>
                <RegularButton
                  type="button"
                  onClick={() => {
                    setCustomLicense(customLicense ? false : true);
                  }}
                >
                  Custom
                </RegularButton>
              </GridItem>
              {customLicense && renderCustomDates()}
            </GridContainer>
          </form> :
          <GridContainer justifyContent="center">
            <GridItem>
              {readOnly ? (null) :
                <RegularButton onClick={() => setEditLicense(true)}>{licensed ? "Update" : "Create"} License</RegularButton>
              }
            </GridItem>
          </GridContainer>
        }
      </Fragment>
    );
  };

  return (
    <Fragment>
      { lockLicenseData ? showLicenseBody() : <LoadingPlaceHolder title="Loading License Data" />}
    </Fragment>
  );
}

EditLockLicense.propTypes = {
  lockId: PropTypes.number.isRequired,
  submitHandler: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  refreshData: PropTypes.func
};
