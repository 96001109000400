const openLockTableStyle = theme => ({
  widgetIcon: {
    color: theme.lsyPalette.secondary.main
  },
  overflowCell:{
    maxWidth: "190px",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  whiteLock: {
    color: theme.lsyPalette.textContrast
  }
});

export default openLockTableStyle;