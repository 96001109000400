import { twsWebUrl } from "_services/helper";
import { get, post, put } from "_helpers/axios/gw.client";

const fetchNotifications = async (options) => {
  return await get(`${twsWebUrl}/notifications`, options);
};

const updateNotification = async (options) => {
  return await put(`${twsWebUrl}/notifications/${options.notificationId}`, options.data);
};

const fetchComments = async (options) => {
  return await get(`${twsWebUrl}/notifications/${options.notificationId}/comments`);
};

const createComment = async (options) => {
  return await post(`${twsWebUrl}/notifications/${options.notificationId}/comments`, options.data);
};

export const notificationService = {
  fetchNotifications,
  updateNotification,
  fetchComments,
  createComment
};
