import { useContext } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { siteService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { invalidSearchCharsRegex } from "_helpers";
import { fetchErrors } from "_utils";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

//icons
import AddIcon from "@mui/icons-material/Add";
import SiteIcon from "assets/teleporte/SiteIcon";

import CardListWidget from "_containers/Widgets/CardListWidget";
import SiteCard2 from "_components/Lockstasy/SiteCard2";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Placeholder from "_components/Helper/Placeholder";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/sitesStyle.js";

const useStyles = makeStyles()(styles);

function Sites(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;


  const addSite = async (data, fetchWidgetData) => {
    try {
      await siteService.addSite({ data: data });
      dispatch(alertActions.send(t("success.addSite")));
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors.length > 0) {
        const nameNotUnique = errors.find(o => o.detail === "error.name.not_unique");
        if (nameNotUnique) {
          console.warn(t("error.existingSite", e));
          dispatch(alertActions.send(t("error.existingSite"), "error"));
        } else {
          console.warn("Warning, failed to add site", e);
          dispatch(alertActions.send(t("error.addSite"), "error"));
        }
      } else {
        console.warn("Warning, failed to add site", e);
        dispatch(alertActions.send(t("error.addSite"), "error"));
      }
    }
    fetchWidgetData();

    setTimeout(() => { // FIXME: this is due to a delay to update data in the backend database
      fetchWidgetData();
    }, [1000]);
  };

  const skeletonCard = <Card className={classes.card}>
    <div className={classes.divBar} />
    <GridContainer direction="column" alignItems="center">
      <GridItem xs={8}>
        <Skeleton variant="text" width={120} height={50} />
      </GridItem>
      <GridItem xs={8}>
        <Skeleton variant="text" width={180} height={30} />
      </GridItem>
    </GridContainer>
  </Card>;

  /* temporarily removed while new view is validated */
  // const skeletonCard = <Card className={classes.card}>
  //   <div className={classes.siteCardContent}>
  //     <CardContent className={classes.cardContent}>
  //       <Skeleton variant="text" width={200} height={50} />
  //       <Skeleton variant="text" width={200} />
  //       <Skeleton variant="text" width={200} />
  //       <Skeleton variant="text" width={200} />
  //     </CardContent>
  //     <Skeleton variant="circular" className={classes.siteDelete} width={70} height={70} />
  //   </div>
  // </Card>;

  const siteFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset) => {
    var cardList = data.map((data, index) => {
      return (
        <GridItem key={data.id} xs={12} sm={6} md={4} lg={3}>
          <SiteCard2
            key={data.id}
            org={props.org}
            index={index}
            data={data}
            history={props.history}
            setFilterVariables={setFilterVariables}
            createModal={createModal}
            fetchWidgetData={fetchWidgetData}
            setValue={setValue}
            state={state}
            setState={setState}
            reset={reset}
            baseUrl={props.baseUrl}
          />
        </GridItem>
      );
    });
    return cardList;
  };

  const getFilterOptions = () => {
    return [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        validateEntry: value => !invalidSearchCharsRegex.test(value),
        errorMsg: t("error.invalidSearch"),
        defaultValue: "",
        autoFocus: true
      },
      {
        field: "open",
        type: "select",
        label: t("label.status"),
        defaultValue: " ",
        options: [
          { name: t("label.all"), value: " " },
          { name: t("widgetField.open"), value: "true" },
          { name: t("label.closed"), value: "false" }
        ]
      },
      {
        field: "sort_by",
        type: "select",
        label: t("form.sortSitesBy"),
        defaultValue: "name,asc",
        options: [
          { name: t("form.name"), value: "name,asc" },
          { name: t("label.updatedAt"), value: "updated_at,desc" },
          { name: t("label.openedAt"), value: "opened_at,desc" }
        ]
      }
    ];
  };

  const getAdditionOptions = () => {
    var options = [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      inputProps: { maxLength: 100 },
      placeholder: `${t("label.maxChars", { num: 100 })}`,
      autoFocus: true
    },
    {
      field: "description",
      type: "textField",
      label: t("form.description"),
      inputProps: { maxLength: 100 },
      placeholder: `${t("label.maxChars", { num: 100 })}`
    }
    ];
    return options;
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        <GridItem className={classes.sitesWidget} xs={12} md={10}>
          <CardListWidget
            skeletonCard={skeletonCard}
            fallbackData={<Placeholder message={t("fallbacks.noSitesFound")} icon={<SiteIcon/>}/>}
            org={props.org}
            paginate
            stats
            title={t("features.lockCollections")}
            rowsPerPage={12}
            secondPaginate={true}
            enableFilter
            filterOptions={getFilterOptions()}
            fielterDefaultValues={{ search: "", open: " ", sort_by: "name,asc" }}
            removeEmptyOptions
            additionForm={getAdditionOptions()}
            additionIcon={<AddIcon className={classes.addIcon} />}
            additionLabel={t("button.addSite")}
            addition={ability.can("create", "lock_collections") ? addSite : null}
            fetchData={siteService.fetchSites}
            dataFormatter={siteFormatter}
            appBarSize="md"
            enableQueryParams
            baseUrl={props.baseUrl}
            history={props.history}
            location={props.location}
            display="grid"
          />
        </GridItem>
      </Grid>
    </div>
  );
}

Sites.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string,
  location: PropTypes.object
};

export default Sites;