/*eslint quote-props: ["warn", "as-needed"]*/
const lsyDashboardStyle = (theme) => ({
  "@keyframes lsyBadgeFadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  batteryAlert: {
    display: "flex",
    alignItems: "center"
  },
  batteryIcon: {
    transform: "rotate(90deg)",
    marginRight: "4px"
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100vh",
    height: "fit-content",
    paddingTop: "15px",
    paddingBottom: "25px"
  },
  widgetIcon: {
    color: theme.lsyPalette.secondary.main
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  tableContainer: {
    marginBottom: "25px"
  },
  notificationWidget:{
    marginTop: "25px"
  },
  badgeContainer: {
    minHeight: "110px",
    height: "fit-content",
    width: "100%",
    marginTop: "10px",
    background: theme.lsyPalette.secondary.main,
    display: "flex",
    flexWrap: "wrap",
    animation: `$lsyBadgeFadeIn 1000ms ${theme.transitions.easing.easeInOut}`
  },
  badgeDivider: {
    height: "80px",
    width: "2px",
    margin: "auto 0px",
    background: theme.lsyPalette.secondary.background
  }
});
export default lsyDashboardStyle;
