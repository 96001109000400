import { useEffect, useState, useRef } from "react";

import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import OtpInput from "react18-input-otp";

import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";

import MfaInfoModal from "_components/Modal/MfaInfoModal";

import mfaFormStyle from "assets/jss/components/mfaFormStyle.js";

import { MFA_CODE_LENGTH } from "_constants";

const useStyles = makeStyles()(mfaFormStyle);

const MfaForm = ({ onChange, value, t }) => {
  const { classes, cx } = useStyles();
  const [animate, setAnimate] = useState("");
  const [showModal, setShowModal] = useState(false);

  const otp = useRef(null);
  const timer = useRef(null);

  const onError = () => {
    setAnimate(true);
    timer.current = setTimeout(function () {
      setAnimate(false);
    }, 700);
    otp.current.focusInput(0);
  };

  useEffect(() => {
    if (value === "      ") {
      onError();
    }
  }, [value]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          {t("mfa.promptCodeNotif")}
        </Grid>
        <Grid item>
          <OtpInput
            ref={otp}
            value={value === false ? "" : value}
            inputStyle={cx(classes.input, {[classes.animate]: animate})}
            onChange={onChange}
            numInputs={MFA_CODE_LENGTH}
            focusStyle={cx(classes.focusStyle)}
            containerStyle={cx(classes.containerStyle)}
            shouldAutoFocus
            isInputNum={true}
          />
        </Grid>
        <Grid item>
          <a
            className={classes.mfaInfoLink}
            onClick={() => setShowModal(true)}
            role="link"
          >
            {t("mfa.whatIsThis")}
          </a>
        </Grid>
      </Grid>
      <MfaInfoModal
        showModal={showModal}
        handleClose={setShowModal}
      ></MfaInfoModal>
    </>
  );
};

MfaForm.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func
};

const styledForm = withTranslation(["auth"])(MfaForm);
export { styledForm as MfaForm };