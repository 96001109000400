import { twsWebUrl } from "_services/helper";
import { get, post, put, del } from "_helpers/axios/gw.client";

const fetchLockgroups = async (options) => {
  return await get(`${twsWebUrl}/lock_groups`, options);
};

const fetchLockgroup = async (id) => {
  return await get(`${twsWebUrl}/lock_groups/${id}`);
};

const updateLockgroup = async ({id, options}) => {
  return await put(`${twsWebUrl}/lock_groups/${id}`, options);
};

const createLockgroup = async (options) => {
  return await post(`${twsWebUrl}/lock_groups`, options);
};

const deleteLockgroup = async (options) => {
  return await del(`${twsWebUrl}/lock_groups/${options.id}}`);
};

const fetchKeys = async ({id, options}) => {
  return await get(`${twsWebUrl}/lock_groups/${id}/keys/access`, options);
};

const fetchLocks = async ({id, options}) => {
  return await get(`${twsWebUrl}/lock_groups/${id}/locks`, options);
};

const addLock = async (options) => {
  return await post(`${twsWebUrl}/lock_groups/${options.lockgroupId}/locks`, options.data);
};

const removeLock = async ({id, lockId, options}) => {
  return await del(`${twsWebUrl}/lock_groups/${id}/locks/${lockId}`, options);
};

const assignKey = async (options) => {
  return await post(`${twsWebUrl}/lock_groups/${options.lockgroupId}/keys`, options.data);
};

const deleteKey = async ({id, keyId, options}) => {
  return await del(`${twsWebUrl}/lock_groups/${id}/keys/${keyId}`, options);
};


export const lockgroupService = {
  fetchLockgroups,
  fetchLockgroup,
  updateLockgroup,
  createLockgroup,
  deleteLockgroup,
  assignKey,
  fetchKeys,
  fetchLocks,
  addLock,
  removeLock,
  deleteKey
};
