import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { alertActions } from "_actions";
import {
  formatDateAsdddMMMDDYYYY,
  formatDateAsLTS
} from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { workSessionService } from "_services/lockstasy";

import {
  Divider,
  Grid,
  Skeleton,
  TablePagination,
  Typography
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import SummaryPopoverWidget from "_containers/Widgets/SummaryPopoverWidget";

import {
  DescriptionOutlined,
  List,
  VpnKeyRounded
} from "@mui/icons-material";

import GenericLock from "assets/teleporte/GenericLock";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Table/workSessionEventsTableStyle";

const useStyles = makeStyles()(styles);

function WorkSessionEventsTable(props) {
  const { selectedWorkSession } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();

  const [popoverState, setPopoverState] = useReducer(genericReducer,
    {
      isSummaryOpen: false,
      data: {},
      anchorEl: null,
      type: "lock"
    }
  );

  const setIsSummaryOpen = (isSummaryOpen) => setPopoverState({ isSummaryOpen });

  const [state, setState] = useReducer(genericReducer,
    {
      workSessionEvents: {},
      totalQuantity: 0,
      currentPage: 1,
      pageSize: 5,
      isLoading: true
    }
  );

  const EVENT_TYPE = {
    1: {type: "lock", getLabel: label => `accessHistory.${label}`},
    2: {type: "lockNote", getLabel: label => `accessHistory.${label}`},
    3: {type: "accessRequest", getLabel: () => "label.accessRequest"},
    4: {type: "RTS", getLabel: () => "lock.rts"}
  };

  const formatEvents = events => {
    let formattedEvents = {};

    for (const event of events) {
      const date = formatDateAsdddMMMDDYYYY(event.timestamp);

      if (formattedEvents[date]) {
        formattedEvents[date].push({...event});
      } else {
        formattedEvents[date] = [{...event}];
      }
    }

    return formattedEvents;
  };

  const fetchWorkSessionEvents = useCallback(async () => {
    if (!selectedWorkSession?.id) {
      return setState({ workSessionEvents: {}, isLoading: false });
    }

    setState({ isLoading: true });
    
    try {
      const options = {
        page: state.currentPage,
        page_size: state.pageSize
      };
      const result = await workSessionService.fetchWorkSessionEvents(selectedWorkSession.id, options);
      setState({
        workSessionEvents: formatEvents(result.data),
        totalQuantity: result.meta.pagination.total,
        isLoading: false
      });
    } catch (e) {
      setState({ workSessionEvents: {}, totalQuantity: 0, isLoading: false });
      dispatch(alertActions.send(t("error.fetchWorkSessionEvents"), "error"));
      console.warn("Warning, failed to fetch work session events", e);
    }
  }, [selectedWorkSession?.id, state.currentPage, state.pageSize, dispatch, t]);

  useEffect(() => {
    fetchWorkSessionEvents();
  }, [fetchWorkSessionEvents]);

  const handleChangePage = (event, newPage) => {
    setState({ currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      pageSize: parseInt(event.target.value),
      currentPage: 1
    });
  };

  const getEventIcon = (eventType, label) => {
    switch (EVENT_TYPE[eventType].type) {
      case "lock":
        return <GenericLock className={classes.icon} closed={label.includes("1")} closedColor="black"/>;
      case "lockNote":
        return <DescriptionOutlined className={classes.icon}/>;
      case "accessRequest":
        return <VpnKeyRounded className={classes.icon}/>;
      default:
        return null;
    }
  };

  const renderLoading = () => {
    return <Grid container direction="column">
      {[...Array(4).keys()].map(v => {
        return <Grid item key={v}>
          <Skeleton variant="text" height={40} data-testid="skeleton"/>
        </Grid>;
      })}
    </Grid>;
  };

  const renderPlaceholder = () => {
    return <Placeholder
      message={t("fallbacks.noWorkSessionEvents")}
      icon={<List/>}
      classNameMessage={classes.placeholderText}
      classNameIcon={classes.placeholderIcon}
    />;
  };

  const renderWorkSessionEvents = () => {
    if (state.isLoading) {
      return renderLoading();
    }

    return isEmpty(state.workSessionEvents) ? renderPlaceholder() :
      Object.keys(state.workSessionEvents).map((wsEventKey, i) => {
        return <div key={i}>
          <div className={classes.date} data-testid="eventDate">{wsEventKey}</div>
          <Divider data-testid="divider"/>
          {state.workSessionEvents[wsEventKey].map(wsEvent => {
            const icon = getEventIcon(wsEvent.event_type, wsEvent.data.label);
            const handleLockSummary = e => {
              setPopoverState({
                data: {...wsEvent.lock, full_identifier: wsEvent.lock.name},
                isSummaryOpen: true,
                type: "lock",
                anchorEl: e.target
              });
            };

            return <div className={classes.item} key={wsEvent.id} data-testid="eventRow">
              {icon}
              <div className={classes.textContainer}>
                <div className={classes.description}>
                  <b>{t(EVENT_TYPE[wsEvent.event_type].getLabel(wsEvent.data.label))}</b> --
                  <span className={classes.link} onClick={handleLockSummary}> {wsEvent.lock.name}</span>
                </div>
                <div className={classes.time}>{formatDateAsLTS(wsEvent.timestamp)}</div>
              </div>
            </div>;
          })}
        </div>;
      });
  };

  const renderPagination = () => {
    return isEmpty(state.workSessionEvents) ? null :
      <TablePagination
        className={classes.pagination}
        data-testid="pagination"
        component="div"
        count={state.totalQuantity || 0}
        rowsPerPage={state.pageSize}
        page={state.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15, 20]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />;
  };

  return <ErrorBoundary>
    <Grid container direction="column" spacing={1} className={classes.table}>
      <Grid item data-testid="headerSection">
        <Typography variant="body1"><b>{t("label.workSessionEvents")}</b></Typography>
      </Grid>
      <Grid item data-testid="bodySection">
        {renderWorkSessionEvents()}
      </Grid>
      <Grid item data-testid="footerSection">
        { renderPagination() }
      </Grid>
    </Grid>
    <SummaryPopoverWidget
      type={popoverState.type}
      data={popoverState.data}
      open={popoverState.isSummaryOpen}
      setOpen={setIsSummaryOpen}
      anchorEl={popoverState.anchorEl}
    />
  </ErrorBoundary>;
}

WorkSessionEventsTable.propTypes = {
  selectedWorkSession: PropTypes.object
};

export default WorkSessionEventsTable;