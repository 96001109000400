const cardListWidgetStyle = theme => ({
  widgetCard: {
    backgroundColor: theme.lsyPalette.secondary.main
  },
  noDataSm: {
    minHeight: "50px",
    flexGrow: 1
  },
  noDataMd: {
    minHeight: "150px",
    flexGrow: 1
  },
  noDataLg: {
    minHeight: "500px",
    flexGrow: 1
  },
  dataWrap: {
    paddingBottom: "15px"
  },
  noDataBodySm: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  noDataBodyMd: {
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text
  },
  noDataBodyLg: {
    fontSize: theme.lsyPalette.title1,
    color: theme.lsyPalette.text
  },
  selectLabel: {
    fontSize: theme.lsyPalette.body1
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px"
  },
  itemGrid: {
    width: "100%"
  },
  inherit: {
    display: "inherit"
  },
  paddedItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  modal: {
    width: "400px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%"
    },
    overflowX: "hidden",
    overflowY: "auto"
  },
  gridSkeleton: {
    marginTop: "10px"
  }
});

export default cardListWidgetStyle;