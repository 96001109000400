const passwordStyle = theme => ({
  margins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  button: {
    textTransform: "none"
  },
  spinner: {
    color: theme.lsyPalette.secondary.main
  }
});

export default passwordStyle;