import { alertConstants } from "../_constants";

const initialState = {
  notifications: []
};


export const alert = (state = initialState, action) => {
  switch (action.type) {
    case alertConstants.ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ]
      };

    case alertConstants.REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };

    default:
      return state;
  }
};