import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomModal from "_components/Modal/CustomModal";

// @mui/material components
import { makeStyles } from "tss-react/mui";

const styles = (theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    mfaButton: {
      color: theme.palette.primary.contrastText
    }
  };
};

const useStyles = makeStyles()(styles);

const buttonProps = {
  color: "primary",
  variant: "contained",
  type: "submit"
};

const MfaCodeModal = ({ showModal, handleClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("auth");

  const closeModal = (close = false) => {
    handleClose(close);
  };

  const body = () => {
    return (
      <div className={classes.container}>
        {t("mfa.mfaDesc1")} <br></br>
        {t("mfa.mfaDesc2")} <br></br>
        {t("mfa.mfaDesc3")}
      </div>
    );
  };

  return (
    <CustomModal
      title={t("mfa.whatIsMfa")}
      description={body()}
      open={showModal}
      setOpen={closeModal}
      manualClose={false}
      type="alert"
      confirmButtonStyle={classes.mfaButton}
      confirmButtonProps={buttonProps}
    />
  );
};

MfaCodeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default MfaCodeModal;
