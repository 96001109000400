const lsyRightDrawerStyle = (theme) => ({
  lsyLeftDrawerText: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  divider: {
    borderColor: theme.lsyPalette.secondary.background
  },
  drawer: {
    width: "300px",
    backgroundColor: theme.lsyPalette.secondary.main,
    height: "100%"
  },
  authUserFullName: {
    fontSize: theme.lsyPalette.title1,
    color: theme.lsyPalette.text
  },
  autocompleteDrawer: {
    width: "100%",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  autocompleteLabel: {
    color: theme.lsyPalette.text
  },
  accountInfoButton: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  logoutButton: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  menuContainer: {
    background: theme.lsyPalette.secondary.main
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  toggleSwitch: {
    marginTop: theme.spacing(0.625),
    marginLeft: theme.spacing(1)
  }
});
export default lsyRightDrawerStyle;
