/**
 * This frame should be used only for views components
 * that are entire pages. For example, the LockReplacement.
 * When we finish to update all LSY pages, this frame
 * should be wrapped around all of them instead of
 * each individual component.
 */
import PropTypes from "prop-types";

import { Fade, Grid } from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Lockstasy/lsyFrameStyle";

const useStyles = makeStyles()(styles);

const LsyFrame = (props) => {
  const { classes } = useStyles();
  const { children } = props;

  const renderLsyFrame = () => {
    return <Grid container className={classes.container} justifyContent="center" alignItems="center">
      <Grid item xs={11} lg={10}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </Grid>
    </Grid>;
  };

  return <Fade in={true} timeout={1000}>{renderLsyFrame()}</Fade>;
};

LsyFrame.defaultProps = {
  open: true
};

LsyFrame.propTypes = {
  children: PropTypes.any
};

export default LsyFrame;