import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { alertActions } from "_actions";

import CustomModal from "_components/Modal/CustomModal";

// @mui/material components
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Modal/mfaCodeModalStyle";

const useStyles = makeStyles()(styles);

const MfaCodeModal = ({ showModal, handleClose, codes, onNotification }) => {
  const { classes } = useStyles();

  const { t } = useTranslation("auth");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(codes.join("\r\n"));
      onNotification(t("mfa.clipboardNotif"));
    } catch (e) {
      onNotification(t("mfa.clipboardNotifFail"), "error");
    }
  };

  const closeModal = (close = false) => {
    handleClose(close);
  };

  const renderCodes = useMemo(() => {
    return codes.map(code => {
      return <div key={code} className={classes.codeContainer}>
        <Typography data-testid={code} className={classes.code}>{code}</Typography>
      </div>;
    });
  }, [codes, classes]);

  const body = () => {
    return (
      <Grid container direction="column" justifyContent="center" className={classes.container} spacing={2}>
        <Grid item>
          <Typography>{t("mfa.descriptionBkCodes")}</Typography>
          <Typography>{t("mfa.noticeBkCodes")}</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item>
              <div className={classes.codeTitle}>
                <Typography><strong>{"Backup Codes"}</strong></Typography>
              </div>
              {renderCodes}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Alert severity={"warning"} className={classes.alert}>
            <Typography >{t("mfa.noteBkCodes")}</Typography>
          </Alert>
        </Grid>
        <Grid item>
          <Button onClick={copyToClipboard} color="secondary" className={classes.button} variant="contained">
            {t("mfa.copyCodes")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomModal
      title={t("mfa.authBkCodesTitle")}
      titleStyle={classes.title}
      description={body()}
      open={showModal}
      setOpen={closeModal}
      manualClose={false}
      type="alert"
      contentStyle={classes.content}
      disableBackdropClick={true}
    />
  );
};

MfaCodeModal.defaultProps = {
  codes: []
};

MfaCodeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  codes: PropTypes.array,
  onNotification: PropTypes.func
};

const mapDispatchtoProps = (dispatch) => {
  return {
    onNotification: (message, level = "success") =>
      dispatch(alertActions.send(message, level))
  };
};

export default connect(null, mapDispatchtoProps)(MfaCodeModal);