import { Component } from "react";

import { ScaleLoader } from "react-spinners";
import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";
import "./Loading.css";

import { css } from "@emotion/react";
const override = css`
  display: flex;
`;

export default class LoaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  render() {
    const { fullHeight, height, radius, width, size, color, style, justify } = this.props;
    let gridStyle = Object.assign({}, style);

    if (fullHeight) 
      gridStyle.minHeight = "100vh";

    return (
      <Grid container alignItems="center" justifyContent={justify} style={gridStyle}>
        <div className='sweet-loading'>
          <ScaleLoader
            sx={override}
            sizeunit={"px"}
            color={color || "#ed1a39"}
            height={height || 40}
            radius={radius || 2}
            width={width || 4}
            size={size || 200}
            loading={this.state.loading}
          />
          {this.props.title}
        </div>
      </Grid>
    );
  }
}


LoaderComponent.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  justify: PropTypes.string,
  margin: PropTypes.string,
  title: PropTypes.string,
  radius: PropTypes.number,
  heightUnit: PropTypes.string,
  widthUnit: PropTypes.string,
  radiusUnit: PropTypes.string,
  size: PropTypes.number,
  fullHeight: PropTypes.bool,
  style: PropTypes.object
};


LoaderComponent.defaultProps = {
  loading: true,
  color: "#ed1a39",
  height: 35,
  width: 4,
  justify: "center",
  size: 200,
  margin: "2px",
  radius: 2,
  heightUnit: "px",
  widthUnit: "px",
  radiusUnit: "px",
  style: {}
};