import axios from "axios";
import { handleResponse } from "_services/helper";

const TIMEOUT = 1 * 60 * 1000;

const api = axios.create({
  timeout: TIMEOUT
});

/* requires atleast a URL */
const getBase = async (requestOptions = {}) => {
  if (typeof(requestOptions) === "string" ) {
    return api({ method: "GET", url: requestOptions});
  } else {
    requestOptions.method = "GET";
  }

  return api(requestOptions);
};

const putBase = async (requestOptions, payload = {}) => {
  if (typeof(requestOptions) === "string" ) {
    return api({ method: "PUT", url: requestOptions, data: payload});
  }

  return api(requestOptions);
};

const postBase = async (requestOptions, payload = {}) => {
  if (typeof(requestOptions) === "string" ) {
    return api({ method: "POST", url: requestOptions, data: payload});
  }

  return api(requestOptions);
};

export const getGeneric = async requestOptions => {
  const res = await getBase(requestOptions);
  return handleResponse(res);
};

export const postGeneric = async (requestOptions, payload) => {
  const res = await postBase(requestOptions, payload);
  return handleResponse(res);
};

export const putGeneric = async (requestOptions, payload) => {
  const res = await putBase(requestOptions, payload);
  return handleResponse(res);
};

export default api;