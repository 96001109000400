import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/genericLockStyle";
const useStyles = makeStyles()(styles);

function GenericLock(props) {
  const { classes, cx } = useStyles();

  let stroke = "";
  let fill = "";

  const disabledColor = props.className?.includes("disabledColor") ? "grey" : null;
  const color = props.closed ? props.closedColor : props.openedColor;

  switch (disabledColor || color) {
    case "red":
      stroke = classes.redStroke;
      fill = classes.redFill;
      break;
    case "white":
      stroke = classes.whiteStroke;
      fill = classes.whiteFill;
      break;
    case "grey":
      stroke = classes.greyStroke;
      fill = classes.greyFill;
      break;
    case "black":
      stroke = classes.blackStroke;
      fill = classes.blackFill;
      break;
    default:
      stroke = classes.greyStroke;
      fill = classes.greyFill;
      break;
  }

  return props.closed ?  
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.width} height={props.height} viewBox="0 0 24 24" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve" data-testid="ClosedGenericLock">  
      <rect className={cx(classes.cls2, stroke)} x="4.14" y="9.67" width="15.54" height="13.48" rx="2.07" ry="2.07"/>
      <path className={cx(classes.cls2, props.className, stroke)} d="M6.33 9.8V6.49c0-3.07 2.51-5.58 5.58-5.58 3.07 0 5.58 2.51 5.58 5.58V9.8"/>
      <path className={cx(classes.cls2, props.className, stroke)} d="M8.59 9.67V6.48c0-1.83 1.5-3.32 3.32-3.32 1.83 0 3.32 1.5 3.32 3.32v3.19"/>
      <circle className={cx(classes.circle, props.className, stroke)} cx="11.91" cy="15.57" r="1.08"/>
      <path className={cx(classes.path, props.className, stroke)} d="M12.93 18.32H10.9s-.01 0-.01-.01l.63-2.32s0-.01.01-.01h.77s.01 0 .01.01l.63 2.32s0 .01-.01.01Z" />
    </svg>
    : 
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.width} height={props.height} viewBox="0 0 24 24" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve" data-testid="OpenedGenericLock">  
      <rect className={cx(classes.cls2, stroke)} x="4.14" y="9.67" width="15.54" height="13.48" rx="2.07" ry="2.07" />
      <path className={cx(classes.cls2Open, stroke, fill)} d="M15.23 9.67V6.48c0-1.83-1.5-3.32-3.32-3.32h0c-1.38 0-2.56.85-3.07 2.05l-2.03-1C7.69 2.27 9.64.91 11.9.91h0c3.07 0 5.58 2.51 5.58 5.58v3.07" />
      <circle className={cx(classes.circle, stroke)} cx="11.91" cy="15.57" r="1.08"/>
      <path className={cx(classes.path, stroke)} d="M12.93 18.32H10.9s-.01 0-.01-.01l.63-2.32s0-.01.01-.01h.77s.01 0 .01.01l.63 2.32s0 .01-.01.01Z"/>
    </svg>;  
}

GenericLock.defaultProps = {
  closed: true,
  openedColor: "red",
  closedColor: "grey"
};

GenericLock.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  closed: PropTypes.bool,
  openedColor: PropTypes.oneOf(["red", "white", "grey", "black"]),
  closedColor: PropTypes.oneOf(["red", "white", "grey", "black"])
};

export default GenericLock;
