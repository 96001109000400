const processorStyle = theme => ({
  processor: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  svg: {
    transform: "scale(1.2)"
  }
});

export default processorStyle;
