import { useState, useContext, useMemo, useEffect, forwardRef, Fragment } from "react";
import PropTypes from "prop-types";
import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData.js";
import { each, sortBy } from "lodash";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles()({
  ...styles,
  highlight : {
    background: "#b5e2fc"
  }
});

const AutocompleteFetchSelect =  forwardRef((props, ref) => {
  const appDataContext = useContext(AppAdminDataContext);
  const { classes } = useStyles();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const resources = useMemo(() => { return (props.dataSource || appDataContext[props.resourceName] || []);}, [props.dataSource, props.resourceName, appDataContext]);

  useEffect(() => {
    const fetchData  = () => {
      try {
        if (resources) {
          let data = [];
          each (resources, (val, key) => {
            data.push({ key: key, id: val.id, name: val.name });
          });
          setData(sortBy(data, "name"));
          setIsLoading(false);
        }
      } catch(e) {
        console.warn("Error organizing searchable resource list:" + e);
      }
    };
    fetchData();
  }, [resources]);


  const showAutoComplete = () => {
    return (
      <Autocomplete
        disabled={props.disabled}
        ref={ref}
        id={props.id}
        loading={typeof data === "undefined"}
        getOptionLabel={(option) => {
          return option.name ? option.name : (option.id || "");
        }}
        options={data || []}
        autoHighlight={props.autoHighlight}
        autoSelect={true}
        isOptionEqualToValue={(option, value) => {
          return (option.id === value || option.id === value.id);
        }}
        disableClearable={!props.clearable}
        value={props.selectedValue}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={props.required}
              label={props.title}
              variant={props.outlined ? "outlined" : "standard"}
              className={props.highlighted ? classes.highlight : ""}
            />
          );
        }}
      />
    );
  };

  return (
    <Fragment>
      { isLoading ? null : showAutoComplete() }
    </Fragment>
  );
});

AutocompleteFetchSelect.displayName = "AutocompleteFetchSelect";

AutocompleteFetchSelect.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  prompt: PropTypes.string,
  organizationList: PropTypes.object,
  selectedValue: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  highlighted: PropTypes.bool,
  outlined: PropTypes.bool,
  resourceName: PropTypes.string,
  dataSource: PropTypes.array,
  autoHighlight: PropTypes.bool,
  disabled: PropTypes.bool
};

AutocompleteFetchSelect.defaultProps = {
  id: "autocomplete-select",
  prompt: "Input",
  clearable: false,
  disabled: false,
  onChange: () => {}
};

export default AutocompleteFetchSelect;