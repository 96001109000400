import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
  useContext,
  useReducer
} from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, isEqual } from "lodash";
import { useCustomCompareEffect } from "use-custom-compare";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import { alertActions } from "_actions";
import { MAX_LOCKS_QUANTITY_FILTER } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { isBlank, convertToInt, isBrowserTimeMilitaryFormat } from "_helpers";
import { formatLockHexIDs } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";
import {
  lockService,
  userService,
  keyService,
  siteService,
  lockgroupService,
  accessRequestService
} from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";
import { fetchErrors } from "_utils";

// @mui/material components
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

//components
import RegularButton from "_components/Button/RegularButton";
import CustomModal from "_components/Modal/CustomModal";
import GridItem from "components/Grid/GridItem";
import AsyncSelect from "react-select/async";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";
import DateTimeRange from "_components/Date/DateTimeRange";

//styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/keyLockWizardStyle.js";

//icons
import {
  ArrowRightAlt as ArrowRightAltIcon,
  Clear as ClearIcon
} from "@mui/icons-material";

/**
 * SITE
 *   lock = adding locks to site
 *   siteKey = adding keys to a site
 * 
 * LOCKGROUP
 *  lockgroupLock = adding locks to a lock group
 *  lockgroupAssignKey = assigning keys to a lock group
 * 
 * USER
 *   key = adding a regular lock key to user
 *   userSiteKey = adding a site key to a user
 *   lockgroupKey = adding a lock group key to a user
 *
 * LOCK
 *    userKey = adding a regular lock key to a user (lock is fixed)
 *
 * OTHERS
 * accessRequest = Approving a regular key or site key for a user.
**/
const useStyles = makeStyles()(styles);

const PAGE_SIZE = 10;
const QTY_MAX_LOCK_GROUPS_ASSIGN_KEY = 1;

function KeyLockWizard(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("default");
  const theme = useTheme();
  const { open, setOpen, type, siteName, siteId, lockgroupName, lockgroupId, 
    user, lock, lockName, requestId, fetchWidgetData } = props;
  const {orgPreferences} =  useContext(LsyAdminDataContext);
  const [selectorValues, setSelectorValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment().local().startOf("hour"));
  const [endDate, setEndDate] = useState(moment().local().add(14, "days").startOf("hour"));
  const [referenceNumber, setReferenceNumber] = useState("");
  const [comments, setComments] = useState("");
  const [confirmationEmail, setConfirmationEmail] = useState(`${t("label.accessRequestApproved", {
    startDate: moment(startDate).format("MMM D YYYY, HH:mm"),
    endDate: moment(endDate).format("MMM D YYYY, HH:mm"),
    lock: lockName
  })}`);
  const [activeStep, setActiveStep] = useState(0);
  const [siteScope, setSiteScope] = useState(false);
  const [lockIdsState, setLockIdsState] = useReducer(genericReducer, {
    lockIds: "",
    error: "",
    isLoading: false
  });
  const currentPage = useRef(1);
  const totalPages = useRef(1);

  const timeFormat = () => {
    return isBrowserTimeMilitaryFormat() ? "HH:mm" : "hh:mm a";
  };

  const concatDefaultOptions = (options, concat) => {
    if(concat) {
      setDefaultOptions(prev => [...prev, ...options]);
    } else {
      setDefaultOptions([...options]);
    }
  };

  const lockSteps = Object.freeze([
    t("features.locks"),
    t("button.confirm")
  ]);

  const siteKeySteps = Object.freeze([
    t("features.users"),
    t("label.details"),
    t("button.confirm")
  ]);

  const keySteps = Object.freeze([
    t("features.locks"),
    t("label.details"),
    t("button.confirm")
  ]);

  const lockKeySteps = Object.freeze([
    t("features.users"),
    t("label.details"),
    t("button.confirm")
  ]);

  const userSiteKeySteps = Object.freeze([
    t("features.lockCollections"),
    t("label.details"),
    t("button.confirm")
  ]);

  const lockgroupKeySteps = Object.freeze([
    t("features.lockGroups"),
    t("label.details"),
    t("button.confirm")
  ]);

  const accessRequestSteps = Object.freeze([
    t("label.details"),
    t("button.confirm")
  ]);

  const isValidLockForSite = useCallback(lock => {
    const isLockOnSite = lock?.site?.name === siteName;

    return hasLockLicense("lock_collections", lock) && !isLockOnSite;
  }, [siteName]);

  const isValidLockForGroup = useCallback(lock => {
    const isLockOnGroup = lock?.lock_group_name === lockgroupName;

    return hasLockLicense("lock_groups", lock) && !isLockOnGroup;
  }, [lockgroupName]);

  const isValidLock = useCallback(lock => {
    if (lock.semaphore_locked) {
      return false;
    }

    if (type === "lock") {
      return isValidLockForSite(lock);
    } else if (type === "lockgroupLock") {
      return isValidLockForGroup(lock);
    } else {
      return true;
    }

  }, [type, isValidLockForSite, isValidLockForGroup]);

  const formatLockData = useCallback(locks => {
    return locks.map(lock => {
      let additionalInfo = "";
      if(lock?.site && type === "lock") {
        additionalInfo = ` [${t("widgetField.site")}: ${lock.site.name}]`;
      } else if(lock?.lock_group_name && (type === "key" || type === "lockgroupLock")) {
        if(lock?.site) {
          additionalInfo = ` [${t("widgetField.site")}: ${lock.site.name}] `;
        }
        additionalInfo += `[${t("label.lockGroup")}: ${lock.lock_group_name}]`;
      }

      return lock
        ? {
          id: lock.id,
          name: `${lock.full_identifier}${additionalInfo}`,
          disabled: !isValidLock(lock)
        }
        : null;
    });
  }, [type, isValidLock, t]);

  const fetchLocks = useCallback(async (loadOnScroll = false) => {
    if (currentPage.current <= totalPages.current) {
      try {
        const result = await lockService.fetchLocks({
          page: currentPage.current,
          page_size: PAGE_SIZE
        });
        
        const formattedResult = formatLockData(result.data);

        concatDefaultOptions(formattedResult, loadOnScroll);
        totalPages.current = result.meta.pagination.total_pages;
      } catch (e) {
        console.warn("Warning, failed to fetch locks", e);
      }
    }
  }, [formatLockData]);

  const fetchLocksWithInput = useCallback(
    async (inputValue) => {
      let options = {
        page: 1,
        page_size: PAGE_SIZE
      };

      if (inputValue.includes("0x")) {
        const formattedArray = inputValue.split(",").map((id) => {
          let formattedId = id;
          if (formattedId.includes("0x")) {
            formattedId = convertToInt(formattedId);
          }
          formattedId = `${formattedId}`.trim();
          return formattedId;
        });
        options.lockIds = formattedArray;
      } else {
        options.search = inputValue;
      }

      try {
        const result = await lockService.fetchLocks(options);
        const formattedResult = result.data.map((v) =>
          v
            ? {
              id: v.id,
              name: `${v.full_identifier}${v.site ? ` [${v.site.name}]` : ""}`,
              disabled: !isValidLock(v)
            }
            : null
        );
        setDefaultOptions(formattedResult);
        currentPage.current = 0;
        return formattedResult;
      } catch (e) {
        console.warn("Warning, failed to fetch locks with given input", e);
      }
    },
    [isValidLock]
  );

  const fetchUsers = useCallback(async (loadOnScroll = false) => {
    if (currentPage.current <= totalPages.current) {
      try {
        const result = await userService.fetchUsers({
          page: currentPage.current,
          page_size: PAGE_SIZE
        });
        const formattedResult = result.data.map((v) =>
          v
            ? {
              id: v.membership_id,
              name: `${v.first_name} ${v.last_name} (${v.email})`
            }
            : null
        );

        concatDefaultOptions(formattedResult, loadOnScroll);
        totalPages.current = result.meta.pagination.total_pages;
      } catch (e) {
        console.warn("Warning, failed to fetch locks", e);
      }
    }
  }, []);

  const fetchUsersWithInput = useCallback(
    async (inputValue) => {
      try {
        const result = await userService.fetchUsers({
          page: 1,
          page_index: PAGE_SIZE,
          search: inputValue
        });
        const formattedResult = result.data.map((v) =>
          v
            ? {
              id: v.membership_id,
              name: `${v.first_name} ${v.last_name} (${v.email})`
            }
            : null
        );

        setDefaultOptions([...formattedResult]);
        currentPage.current = 0;
        return formattedResult;
      } catch (e) {
        console.warn("Warning, failed to fetch locks with given input", e);
      }
    }, []);

  const fetchSites = useCallback(async (loadOnScroll = false) => {
    if (currentPage.current <= totalPages.current) {
      try {
        const result = await siteService.fetchSites({
          page: currentPage.current,
          page_size: PAGE_SIZE
        });
        const formattedResult = result.data.map((v) => {
          return v
            ? {
              id: v.id,
              name: `${v.name} [${v?.lock_count || 0} ${t("features.locks")}]`
            }
            : null;
        });
        
        concatDefaultOptions(formattedResult, loadOnScroll);
        totalPages.current = result.meta.pagination.total_pages;
      } catch (e) {
        console.warn("Warning, failed to fetch sites", e);
      }
    }
  }, [t]);

  const fetchSitesWithInput = useCallback(async (inputValue) => {
    const options = {
      page: 1,
      page_size: PAGE_SIZE,
      search: inputValue
    };

    try {
      const result = await siteService.fetchSites(options);
      const formattedResult = result.data.map((v) => v ? {
        id: v.id,
        name: `${v.name} [${v?.lock_count || 0} ${t("features.locks")}]`
      } : null
      );
      
      setDefaultOptions([...formattedResult]);
      currentPage.current = 0;
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch sites with given input", e);
    }
  }, [t]);

  const formatGroupData = useCallback(groups => {
    return groups.map(group => {
      return group ?
        {
          id: group.id,
          name: `${group.name} [${group?.number_of_locks || 0} ${t("features.locks")}]`,
          disabled: group.number_of_locks === 0
        }
        : null;
    });
  }, [t]);
  
  const fetchLockgroups = useCallback(async (loadOnScroll = false) => {
    if (currentPage.current <= totalPages.current) {
      try {
        const result = await lockgroupService.fetchLockgroups({
          page: currentPage.current,
          page_size: PAGE_SIZE
        });

        const formattedResult = formatGroupData(result.data);
        
        concatDefaultOptions(formattedResult, loadOnScroll);
        totalPages.current = result.meta.pagination.total_pages;
      } catch (e) {
        console.warn("Warning, failed to fetch lock groups", e);
      }
    }
  }, [formatGroupData]);

  const fetchLockgroupsWithInput = useCallback(async (inputValue) => {
    const options = {
      page: 1,
      page_size: PAGE_SIZE,
      search: inputValue
    };

    try {
      const result = await lockgroupService.fetchLockgroups(options);
      const formattedResult = formatGroupData(result.data);

      setDefaultOptions([...formattedResult]);
      currentPage.current = 0;
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch lock groups with given input", e);
    }
  }, [formatGroupData]);

  const addLock = async () => {
    let data = {};
    const params = {};

    if (selectorValues.length > (props.maxLocks - props.numLocks)) {
      dispatch(alertActions.send(t("error.maxLocks", { maxLocks: props.maxLocks }), "error"));
      return false;
    }

    data.lock_ids = selectorValues.map((lock) => lock.id);
    params.data = data;

    try {
      if (type === "lock") {
        data.site_id = siteId;
        params.siteId = siteId;

        await siteService.addLock(params);
      } else {
        params.lockgroupId = lockgroupId;

        await lockgroupService.addLock(params);
      }
      dispatch(alertActions.send(t("success.addLock")));
      fetchWidgetData();
      return true;
    } catch (e) {
      console.warn("Warning, failed to add lock", e);

      if (fetchErrors(e)[0]?.detail === "error.site.max_locks_exceeded") {
        dispatch(alertActions.send(t("error.maxLocks", { maxLocks: props.maxLocks }), "error"));
      } else if(!fetchErrors(e)[0]?.detail === "error.site.max_locks_exceeded") {
        dispatch(alertActions.send(t("error.existingLock"), "error"));
      } else {
        dispatch(alertActions.send(t("error.addLock"), "error"));
      }
      return false;
    }
  };

  const assignKey = async () => {
    let data = {};
    const params = {};

    if (comments) {
      data.comments = comments;
    }

    if (!isBlank(referenceNumber)) {
      data.ticket_number = referenceNumber;
    }

    if (siteScope) {
      data.grant_scoping = "site";
    }

    if (type === "accessRequest") {
      data.message = confirmationEmail;
    }

    data.start_date = moment(startDate).utc();
    data.end_date = moment(endDate).utc();

    try {
      if (type === "siteKey") {
        data.membership_ids = selectorValues.map((user) => user.id);
        params.siteId = siteId;
        params.data = { key: data, site_id: siteId };

        await siteService.assignKey(params);
      } else if (type === "lockgroupAssignKey") {
        data.membership_ids = selectorValues.map((user) => user.id);
        params.lockgroupId = lockgroupId;
        params.data = { key: data };

        await lockgroupService.assignKey(params);
      } else if (type === "userSiteKey") {
        params.membershipId = [user.membership_id];
        data.site_ids = selectorValues.map((site) => site.id);
        params.data = { key: data };

        await userService.assignSiteKey(params);
      } else if (type === "key") {
        data.membership_ids = [user.membership_id];
        data.lock_ids = selectorValues.map((lock) => lock.id);
        params.data = { key: data };

        await keyService.addKey(params);
      } else if (type === "accessRequest") {
        params.data = { request: data };
        params.action = "approve";
        params.id = requestId;

        await accessRequestService.updateRequest(params);
      } else if (type === "lockKey") {
        data.membership_ids = selectorValues.map((user) => user.id);
        data.lock_ids = [lock.id];
        params.data = { key: data };

        await keyService.addKey(params);
      } else if (type === "lockgroupKey") {
        data.membership_ids = [user.membership_id];
        params.lockgroupId = selectorValues.map((group) => group.id);
        params.data = { key: data };

        await lockgroupService.assignKey(params);
      }
      dispatch(alertActions.send(t("success.assignKey")));
      fetchWidgetData();
      return true;
    } catch (e) {
      console.warn("Warning, failed to assign key", e);
      dispatch(alertActions.send(t("error.assignKey"), "error"));
      return false;
    }
  };

  const lockParams = Object.freeze({ //locks widget on sites page
    title: t("label.addLockTo", { site: siteName }),
    placeholder: t("instructions.selectLocks"),
    steps: lockSteps,
    fetchData: fetchLocks,
    fetchDataWithInput: fetchLocksWithInput,
    summaryTitle: t("features.locks"),
    finalStep: t("button.addLock"),
    handleFinalStep: addLock
  });

  const siteKeyParams = Object.freeze({ //key widget on sites page
    title: t("label.assignKeyTo", { site: siteName }),
    placeholder: t("instructions.selectUsers"),
    steps: siteKeySteps,
    fetchData: fetchUsers,
    fetchDataWithInput: fetchUsersWithInput,
    summaryTitle: t("features.users"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const lockgroupLockParams = Object.freeze({ //locks widget on lock groups page
    title: t("label.addLockTo", { site: lockgroupName }),
    placeholder: t("instructions.selectLocks"),
    steps: lockSteps,
    fetchData: fetchLocks,
    fetchDataWithInput: fetchLocksWithInput,
    summaryTitle: t("features.locks"),
    finalStep: t("button.addLock"),
    handleFinalStep: addLock
  });

  const lockgroupAssignKeyParams = Object.freeze({ //key widget on lock groups page
    title: t("label.assignKeyTo", { site: lockgroupName }),
    placeholder: t("instructions.selectUsers"),
    steps: siteKeySteps,
    fetchData: fetchUsers,
    fetchDataWithInput: fetchUsersWithInput,
    summaryTitle: t("features.users"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const keyParams = Object.freeze({ //regular key for users
    title: t("label.assignKeyTo", { site: `${user?.first_name} ${user?.last_name} (${user?.email})` }),
    placeholder: t("instructions.selectLocks"),
    steps: keySteps,
    fetchData: fetchLocks,
    fetchDataWithInput: fetchLocksWithInput,
    summaryTitle: t("features.locks"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const userSiteKeyParams = Object.freeze({ //site key for users
    title: t("label.assignKeyTo", { site: `${user?.first_name} ${user?.last_name} (${user?.email})` }),
    placeholder: t("instructions.selectSites"),
    steps: userSiteKeySteps,
    fetchData: fetchSites,
    fetchDataWithInput: fetchSitesWithInput,
    summaryTitle: t("features.lockCollections"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const lockgroupKeyParams = Object.freeze({ //lock group key for users
    title: t("label.assignKeyTo", { site: `${user?.first_name} ${user?.last_name} (${user?.email})` }),
    placeholder: t("instructions.selectLockGroups"),
    steps: lockgroupKeySteps,
    fetchData: fetchLockgroups,
    fetchDataWithInput: fetchLockgroupsWithInput,
    summaryTitle: t("features.lockGroups"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const lockKeyParams = Object.freeze({ //key widget on lock page
    title: t("label.issuekeyTo", { lock: lock?.name }),
    placeholder: t("instructions.selectUsers"),
    steps: lockKeySteps,
    fetchData: fetchUsers,
    fetchDataWithInput: fetchUsersWithInput,
    summaryTitle: t("features.users"),
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const accessRequestParams = Object.freeze({ //site key for users
    title: t("label.assignKeyTo", { site: lockName }),
    steps: accessRequestSteps,
    finalStep: t("button.issueKey"),
    handleFinalStep: assignKey
  });

  const params = useMemo(() => {
    switch (type) {
      case "lock":
        return lockParams;
      case "siteKey":
        return siteKeyParams;
      case "key":
        return keyParams;
      case "userSiteKey":
        return userSiteKeyParams;
      case "lockgroupKey":
        return lockgroupKeyParams;
      case "lockgroupAssignKey":
        return lockgroupAssignKeyParams;
      case "lockgroupLock":
        return lockgroupLockParams;
      case "lockKey":
        return lockKeyParams;
      case "accessRequest":
        return accessRequestParams;
      default:
        return lockParams;
    }
  }, [type, lockParams, siteKeyParams, keyParams, userSiteKeyParams, lockgroupKeyParams, lockgroupAssignKeyParams, lockgroupLockParams,
    accessRequestParams, lockKeyParams]);


  const { steps, title, finalStep, placeholder, fetchData, fetchDataWithInput, summaryTitle, handleFinalStep } = params;

  const fetchMoreData = useCallback((loadOnScroll = false) => {
    currentPage.current += 1;
    if (fetchData) {
      fetchData(loadOnScroll);
    }
  }, [fetchData]);

  useEffect(() => {
    if (fetchData) {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    if(orgPreferences?.default_key_time && orgPreferences?.default_key_time_unit) {
      if (orgPreferences?.default_key_time_unit === "hours") {
        setEndDate(moment().local().add(parseInt(orgPreferences?.default_key_time, 10), orgPreferences?.default_key_time_unit).startOf("hour"));
      } else {
        setEndDate(moment().local().add(parseInt(orgPreferences?.default_key_time, 10), orgPreferences?.default_key_time_unit).endOf("day"));
      }
    }

  },[orgPreferences]);

  const updateConfirmationEmail = useCallback((startDate, endDate, siteScope) => {
    let message;
    let params = {
      startDate: moment(startDate).format(`MMM D YYYY, ${timeFormat()}`),
      endDate: moment(endDate).format(`MMM D YYYY, ${timeFormat()}`)
    };
    
    if (siteScope) {
      message = "label.accessRequestApprovedSite";
      params.site = siteName;
    } else {
      message = "label.accessRequestApproved";
      params.lock = lockName;
    }
    setConfirmationEmail(t(message, params));
  }, [lockName, siteName, t]);
  
  useCustomCompareEffect(() => {
    updateConfirmationEmail(startDate, endDate, siteScope);
  }, [startDate, endDate, siteScope], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      let success = await handleFinalStep();
      if (success) {
        setOpen({ type: type, open: false });
      }
    } else if (activeStep === 1 &&
      type !== "lock" &&
      moment(endDate).isSameOrBefore(startDate)
    ) {
      dispatch(alertActions.send(t("error.invalidDates"), "error"));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderSelectorTextHelper = useCallback(() => {
    if (type === "lockgroupKey") {
      return <Typography variant="caption" className={classes.helperText}>
        {t("instructions.lockGroupsLimit")}
      </Typography>;
    }

    return null;
  }, [classes.helperText, t, type]);

  const selector = useCallback(() => {
    const isDisabled = type === "lockgroupKey" && selectorValues.length >= QTY_MAX_LOCK_GROUPS_ASSIGN_KEY;

    return <div>
      <AsyncSelect
        className={classes.itemBox}
        isClearable={false}
        isMulti
        autoFocus={true}
        backspaceRemovesValue={false}
        captureMenuScroll={true}
        hideSelectedOptions={true}
        value={selectorValues}
        isDisabled={isDisabled}
        onChange={(e) => {
          setSelectorValues(e);
        }}
        placeholder={placeholder}
        loadOptions={fetchDataWithInput}
        defaultOptions={defaultOptions}
        isOptionDisabled={(option) => option.disabled}
        inputValue={inputValue}
        onMenuScrollToBottom={() => fetchMoreData(true)} 
        onInputChange={(query, { action }) => {
          if (
            action === "set-value" ||
            action === "input-blur" ||
            action === "menu-close"
          ) {
            return;
          }

          setInputValue(query);
          if (isBlank(query)) {
            currentPage.current = 0;
            setDefaultOptions([]);
            fetchMoreData();
          }
        }}
        autoBlur={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        noOptionsMessage={() => t("fallbacks.noOptions")}
        loadingMessage={() => t("actions.loading")}
        theme={(base) => ({
          ...base,
          colors: {
            ...base.colors,
            primary25: theme.lsyPalette.rowHover,
            primary50: theme.lsyPalette.rowHover,
            primary: theme.lsyPalette.primary.mainLight
          }
        })}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            minHeight: "60px",
            height: "60px"
          }),
          valueContainer: (provided) => ({
            ...provided,
            height: "60px",
            padding: "0 6px"
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "60px"
          }),
          multiValue: (provided) => ({ ...provided, display: "none" })
        }}
        menuPortalTarget={document.body}
      />
      {renderSelectorTextHelper()}
    </div>;
  }, [
    type,
    classes,
    defaultOptions,
    fetchDataWithInput,
    selectorValues,
    fetchMoreData,
    placeholder,
    t,
    inputValue,
    theme,
    renderSelectorTextHelper
  ]);

  const handleConfirmSelectedLockIds = useCallback(async () => {
    setLockIdsState({ isLoading: true });
    
    const formattedIds = formatLockHexIDs(lockIdsState.lockIds, t);

    if (formattedIds.error) {
      setLockIdsState({ error: formattedIds.error });
    } else if (!formattedIds.ids) {
      return;
    } else {
      try {
        const result = await lockService.fetchLocks({
          page: 1,
          page_size: MAX_LOCKS_QUANTITY_FILTER,
          ...formattedIds
        });
        
        const formattedResult = formatLockData(result.data);
        const invalidLocks = formattedResult.filter(lock => lock.disabled);
        const validLocks = formattedResult.filter(lock => invalidLocks.every(selectedLock => selectedLock.id !== lock.id));
        const newSelectedLocks = [
          ...selectorValues,
          ...validLocks.filter(lock => selectorValues.every(selectedLock => selectedLock.id !== lock.id))
        ];
        const error = isEmpty(invalidLocks) ? "" :
          t("error.lock.listLocked", {locks: invalidLocks.map(lock => lock.name).join(", ")});

        setSelectorValues(newSelectedLocks);
        setLockIdsState({ lockIds: "", error: error });
      } catch (e) {
        console.warn("Warning, failed to fetch locks", e);
      }
    }
    setLockIdsState({ isLoading: false });
  }, [lockIdsState.lockIds, selectorValues, formatLockData, t]);

  const handleListLockIds = useCallback(async event => {
    if (event.key !== "Enter") {
      return;
    }

    handleConfirmSelectedLockIds();
  }, [handleConfirmSelectedLockIds]);

  const handleChangeLockIds = useCallback(event => setLockIdsState({ lockIds: event.target.value, error: "" }), []);

  const selectListLocks = useCallback(() => {
    const searchByLockId = t("label.searchByLockId", { max: MAX_LOCKS_QUANTITY_FILTER, newLine: <br/> });
    const enterButton = <InputAdornment position="end">
      <Divider sx={{ height: 42, m: 0.5 }} orientation="vertical" />
      {lockIdsState.isLoading ?
        <CircularProgress color="inherit" size="24px" className={classes.circularProgress}/> :
        <Tooltip title={t("confirmation.lockSelection")}>
          <IconButton tabIndex={-1} onClick={handleConfirmSelectedLockIds} size="small">
            <ArrowRightAltIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
      }
    </InputAdornment>;

    return type === "key" &&
      <Grid container alignItems="flex-start" justifyContent="space-between">
        <Grid item xs={12}>
          <TextField
            fullWidth
            helperText={lockIdsState.error || searchByLockId}
            label={t("form.listLockIds")}
            value={lockIdsState.lockIds}
            onChange={handleChangeLockIds}
            InputLabelProps={{ shrink: true }}
            onKeyDown={handleListLockIds}
            error={!isEmpty(lockIdsState.error)}
            InputProps={{ endAdornment: enterButton, className: classes.listLockIds }}
          />
        </Grid>
      </Grid>;
  }, [lockIdsState.isLoading, lockIdsState.lockIds, lockIdsState.error, type, classes.circularProgress,
    classes.listLockIds, handleChangeLockIds, handleConfirmSelectedLockIds, handleListLockIds, t]
  );

  const selectionPage = useCallback(() => {
    const page = (
      <Grid container>
        <GridItem xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {selector()}
            </Grid>
            <Grid item>
              {selectListLocks()}
            </Grid>
          </Grid>
        </GridItem>
        <GridItem xs={12} md={6}>
          <div className={classes.confirmSelection}>
            {selectorValues.map((value) => {
              return (
                <GridItem className={classes.selectionItem} key={value.id}>
                  <div className={classes.selectionPageDiv}>
                    {value.name}
                    <IconButton
                      className={classes.removeItem}
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setSelectorValues((values) => {
                          return values.filter((item) => {
                            return item.id !== value.id;
                          });
                        });
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </div>
                </GridItem>
              );
            })}
          </div>
        </GridItem>
      </Grid>
    );
    return page;
  }, [selectorValues, classes, selector, selectListLocks]);

  const confirmPage = useCallback(() => {
    const page = (
      <Grid container direction="column">
        {type === "siteKey" || type === "key" || type === "accessRequest" || type === "lockKey" || type === "lockgroupKey" || type === "userSiteKey" ? (
          <Fragment>
            <GridItem xs={12} className={classes.keyValueItem}>
              <span className={classes.key}>{`${t("label.date")}: `}</span>
              <span className={classes.value}>{`${moment(startDate).format(`MMM D YYYY, ${timeFormat()}`)} - ${moment(endDate).format(`MMM D YYYY, ${timeFormat()}`)}`}</span>
            </GridItem>
            {referenceNumber ? (
              <GridItem xs={12} className={classes.keyValueItem}>
                <span className={classes.key}>{`${t(
                  "label.reference"
                )}: `}</span>
                <span className={classes.value}>{referenceNumber}</span>
              </GridItem>
            ) : null}
            {comments ? (
              <GridItem xs={12} className={classes.keyValueItem}>
                <span className={classes.key}>{`${t("label.comments")}: `}</span>
                <span className={classes.value}>{comments}</span>
              </GridItem>
            ) : null}
          </Fragment>
        ) : null}
        {type !== "accessRequest" ? <Fragment>
          <GridItem xs={12} className={classes.keyValueItem}>
            <span className={classes.key}>
              {`${summaryTitle} (${selectorValues.length}): `}</span>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.confirmSelection}>
              {selectorValues.map((value) => {
                return (
                  <GridItem
                    xs={12}
                    className={classes.summaryItem}
                    key={value.id}
                  >
                    <span className={classes.key}>{value.name}</span>
                  </GridItem>
                );
              })}
            </div>
          </GridItem>
        </Fragment> : <GridItem xs={12}>
          <TextField
            id="confirmationEmail"
            label={t("label.confirmationEmail")}
            value={confirmationEmail}
            onChange={(e) => setConfirmationEmail(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            multiline
            maxRows={5}
            variant="outlined"
          />
        </GridItem>}
      </Grid>
    );
    return page;
  }, [
    selectorValues,
    referenceNumber,
    comments,
    startDate,
    endDate,
    type,
    classes,
    t,
    summaryTitle,
    confirmationEmail
  ]);

  const detailsPage = useCallback(() => {
    const page = (
      <Grid container direction="column">
        <Grid container>
          <GridItem xs={12}>
            <DateTimeRange 
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              label={`${t("label.startDate")} ~ ${t("label.endDate")}`}
              format="YYYY/MM/DD HH:mm"
              minDate={moment().startOf("day").toDate()}
              showTime={true} />
          </GridItem>
        </Grid>
        { type !== "lockgroupKey" && type !== "lockgroupAssignKey" ?
          <Fragment>
            <GridItem xs={12}>
              <TextField
                id="referenceNumber"
                label={t("label.referenceNumber")}
                value={referenceNumber}
                autoFocus={true}
                onChange={(e) => setReferenceNumber(e.target.value)}
                placeholder={`${t("label.maxChars", { num: 50 })} [${t(
                  "label.optional"
                ).toLowerCase()}]`}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 50
                }}
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="comments"
                label={t("label.comments")}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder={`${t("label.maxChars", { num: 100 })} [${t(
                  "label.optional"
                ).toLowerCase()}]`}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                variant="outlined"
              />
            </GridItem>
            {type === "accessRequest" && siteName ?
              <GridItem className={classes.toggleDiv}>
                <span className={classes.toggleSwitchLabel}>{t("label.grantToSite", { site: siteName })}</span>
                <ToggleSwitch
                  checked={siteScope}
                  handleChange={setSiteScope}
                />
              </GridItem> : null}
          </Fragment> : null}
      </Grid>
    );
    return page;
  }, [classes, startDate, endDate, referenceNumber, comments, type, t, siteName, siteScope]);

  const getLockPage = useCallback(() => {
    switch (activeStep) {
      case 0:
        return selectionPage();
      case 1:
        return confirmPage();
      default:
        break;
    }
  }, [activeStep, confirmPage, selectionPage]);

  const getKeyPage = useCallback(() => {
    switch (activeStep) {
      case 0:
        return selectionPage();
      case 1:
        return detailsPage();
      case 2:
        return confirmPage();
      default:
        break;
    }
  }, [activeStep, confirmPage, detailsPage, selectionPage]);

  const getRequestPage = useCallback(() => {
    switch (activeStep) {
      case 0:
        return detailsPage();
      case 1:
        return confirmPage();
      default:
        break;
    }
  }, [activeStep, confirmPage, detailsPage]);

  const getPage = () => {
    switch (type) {
      case "lock":
        return getLockPage();
      case "lockgroupLock":
        return getLockPage();
      case "accessRequest":
        return getRequestPage();
      default:
        return getKeyPage();
    }
  };

  const isNextStepDisabled = useMemo(() => {
    if (type === "accessRequest") {
      return false;
    } else {
      return selectorValues ? !selectorValues.length > 0 : true;
    }
  }, [selectorValues, type]);

  const widget = (
    <div className={classes.instructions}>
      {getPage()}
      <div className={classes.root}>
        <Stepper activeStep={activeStep} style={{ padding: 24 }}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                      active: classes.activeIcon,
                      completed: classes.completedIcon
                    }
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div>
            <div className={classes.stepButtons}>
              <RegularButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                {t("button.back")}
              </RegularButton>
              <RegularButton
                onClick={handleNext}
                className={classes.button}
                disabled={isNextStepDisabled}
              >
                {activeStep === steps.length - 1 ? finalStep : t("button.next")}
              </RegularButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      type="custom"
      description={widget}
      title={title}
      submit
      modalStyle={classes.modal}
      contentStyle={classes.content}
      disableBackdropClick
      clearIcon
    />
  );
}

KeyLockWizard.propTypes = {
  type: PropTypes.string,
  lockName: PropTypes.string,
  requestId: PropTypes.number,
  fetchWidgetData: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  siteName: PropTypes.string,
  siteId: PropTypes.string,
  lockgroupName: PropTypes.string,
  lockgroupId: PropTypes.string,
  user: PropTypes.object,
  lock: PropTypes.object,
  maxLocks: PropTypes.number,
  numLocks: PropTypes.number
};

export default KeyLockWizard;

