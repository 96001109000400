import { red } from "@mui/material/colors";
import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
  infoColor,
  warningColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  membershipLink: {
    color: infoColor[0],
    textDecoration: "none",
    fontWeight: 400
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0]
  },
  activeStatus: {
    float: "right"
  },
  alarmText: {
    color: warningColor[0]
  },
  stats: {
    "color": grayColor[0],
    "fontSize": "12px",
    "lineHeight": "22px",
    "display": "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    "color": "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  input: {
    backgroundColor: red
  },
  button: {
    textTransform: "none"
  },
  tableRow: {
    display: "flex",
    alignItems: "center"
  },
  iconButton: {
    marginLeft: "4px",
    padding: "0px"
  },
  icon: {
    fontSize: "0.95rem"
  },
  disabled: {
    color: dangerColor[0]
  },
  pending: {
    color: warningColor[0]
  },
  enabled: {
    color: successColor[0]
  },
  authentication: {
    display: "flex",
    alignItems: "center"
  },
  pwPwlIconBorder: {
    stroke: "orange"
  },
  pwPwlIcon: {
    fill: "orange"
  },
  pwlIconBorder: {
    stroke: "green"
  },
  pwlIcon: {
    fill: "green"
  },
  membershipMessage: {
    display: "block"
  }
};

export default dashboardStyle;
