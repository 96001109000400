import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { config } from "_configs/server-config";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import ReactPostprocessor from "i18next-react-postprocessor";

let language = localStorage.getItem("language");
if (!language) {
  language = navigator.language || navigator.userLanguage || "en";
  // We don't support country specific variant at the moment
  // so remove the country
  if (language.includes("-")) {
    language = language.split("-")[0];
  }
}

var missingKeys = {};
const debug = process.env.NODE_ENV === "development";

const options = {
  backend: {
    queryStringParams: { v: config.swVersion }
  },
  lng: language,
  fallbackLng: {
    gb: ["en"],
    default: ["en"]
  },
  ns: ["default", "auth"],
  defaultNS: "default",
  interpolation: {
    escapeValue: false
  },
  debug: false,
  load: "currentOnly",
  saveMissing: true,
  missingKeyHandler: debug ? (ng, ns, key) => {
    missingKeys[key] = "";
  } : null,
  omitBoundRerender: true,
  react: {
    withRef: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    useSuspense: true
  },
  postProcess: ["reactPostprocessor"]
};

if (process && !process.release) {
  i18n
    .use(Backend)
    .use(LanguageDetector);
}

i18n.use(initReactI18next);
i18n.use(new ReactPostprocessor());

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
