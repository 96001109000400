const lsyLeftDrawerStyle = (theme) => ({
  drawer: {
    width: "300px",
    backgroundColor: theme.lsyPalette.secondary.main,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    overflow: "-moz-scrollbars-none"  
  },
  listSubheader: {
    marginLeft: "40px",
    borderLeft: "2px solid #c4c4c4",
    maxWidth: "260px !important"
  },
  selectedItem: {
    borderLeft: `2px solid ${theme.lsyPalette.indicator}`
  },
  soloItem: {
    fontSize: 16
  },
  listRoot: {
    "width": "100%",
    "marginTop": 10,
    "maxWidth": 360,
    "backgroundColor": theme.lsyPalette.secondary.main,
    "position": "relative",
    "overflowY": "auto",
    "overflowX": "hidden",
    // [theme.breakpoints.up("lg")]: {
    //   maxHeight: 700
    // },

    "& .MuiButtonBase-root": {
      // marginLeft: "40px",
      fontWeight: "300",
      maxWidth: "100%",
      // borderLeft: "1px solid grey",
      padding: "3px 0px 3px 12px"
    }
    // "& .MuiListItemText-root": {
    //   color: "red"
    // }
  },
  listSection: {
    backgroundColor: "inherit"
  },
  listUl: {
    backgroundColor: "inherit",
    padding: 0
  }
});
export default lsyLeftDrawerStyle;
