import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import {
  DialogContent,
  Modal
} from "@mui/material";
import modalTypes from "./Types";

import { modalActions } from "_actions";

// import { pick } from "lodash";

const MODAL_TYPES = {
  "alert":   modalTypes.alertModal,
  "confirm": modalTypes.confirmModal,
  "custom":  modalTypes.customModal,
  "custom-select": modalTypes.customSelectModal,
  "custom-form": modalTypes.customFormModal,
  "custom-list": modalTypes.customListModal
};

const useStyles = makeStyles()({
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }
});


export default function ModalContainer()  {
  const isOpen = useSelector(state => (typeof(state.modal.modalType) === "string"));
  const modalType = useSelector(state => state.modal.modalType);
  const { modalStyle, ...rest } = useSelector(state => state.modal.modalProps);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const closeModal = () => {
    dispatch(modalActions.hideModal());
  };

  const renderModal = () => {
    const SpecifiedModal = MODAL_TYPES[modalType];
    return  (
      <div>
        <Modal
          aria-labelledby="simple-modal"
          aria-describedby="simple-modal"
          open={isOpen}
          onClose={closeModal}
        >
          <DialogContent className={classes.flexContainer}>
            <SpecifiedModal
              onClose={closeModal}
              modalStyle={ { maxWidth: "600px", ...modalStyle } }
              { ...rest }
            />
          </DialogContent>
        </Modal>
      </div>);
  };

  return (
    <Fragment>
      { isOpen ? renderModal() : null }
    </Fragment>
  );
}