import { isEmpty } from "lodash";
import { get, save, update } from "__indexedDB/db";

const TABLE_NAME = "favourites";

const updateFavourites = async (membershipId, prop, id) => {
  const favourites = await get(TABLE_NAME, membershipId);
  
  if (isEmpty(favourites)) {
    return await save(TABLE_NAME, {
      membership_id: membershipId,
      [prop]: [id]
    });
  } else if (isEmpty(favourites[prop])) {
    return await update(TABLE_NAME, {
      ...favourites,
      [prop]: [id]
    });
  } else {
    return await update(TABLE_NAME, {
      ...favourites,
      [prop]: favourites[prop].includes(id) ?
        favourites[prop].filter(savedId => savedId !== id) :
        [...favourites[prop], id]
    });
  }
};

export const updateFavouriteLocks = async (membershipId, lockId) => {
  return await updateFavourites(membershipId, "lock_ids", lockId);
};

const getAllFavourites = async (membershipId, prop) => {
  const favourites = await get(TABLE_NAME, membershipId) || {};
  return favourites[prop] || [];
};

export const getAllFavouteLocks = async (membershipId) => {
  return await getAllFavourites(membershipId, "lock_ids");
};