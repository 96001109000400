import { useEffect } from "react";import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { lsyRouter } from "_helpers";
import { urlMap } from "_helpers/ReportHelper";

import { Grid, Typography, Tab, Tabs, useMediaQuery } from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";
import ReportsExportWidget from "_containers/Widgets/ReportsExportWidget";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/reportsStyle";

const useStyles = makeStyles()(styles);

function Reports(props) {
  const { location, history } = props;
  const { t } = useTranslation(["default", "auth"]);
  const { classes } = useStyles();
  const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { report } = useParams();

  const reportExports = () => {
    return <ErrorBoundary>
      <ReportsExportWidget
        location={location}
        history={history}
      />
    </ErrorBoundary>;
  };

  const handleChangeTab = (_, newValue) => {
    history.push(lsyRouter("reports", newValue));
  };

  const isValidReport = Object.values(urlMap).some(url => url === report);

  useEffect(() => {
    if (!isValidReport) {
      history.replace(`/reports/${urlMap.accessHistory}`);
    }
  });

  const getReport = () => {
    return isValidReport ? report : urlMap.accessHistory;
  };

  const renderTabs = () => {
    return <Tabs
      data-testid="reportTabs"
      orientation={isDownMd ? "horizontal" : "vertical"}
      variant="scrollable"
      fullwidth="true"
      scrollButtons="auto"
      allowScrollButtonsMobile
      value={getReport()}
      onChange={handleChangeTab}
    >
      <Tab data-testid="accessHistoryTab" className={classes.tab} label={t("label.accessHistory")} value={urlMap.accessHistory} />
      <Tab data-testid="keysTab" className={classes.tab} label={t("label.keys")} value={urlMap.keys} />
      <Tab data-testid="usersTab" className={classes.tab} label={t("features.users")} value={urlMap.users} />
      <Tab data-testid="locksTab" className={classes.tab} label={t("features.locks")} value={urlMap.locks} />
      <Tab data-testid="tagsTab" className={classes.tab} label={t("features.tags")} value={urlMap.tags} />
      <Tab data-testid="systemLogsTab" className={classes.tab} label={t("features.systemLogs")} value={urlMap.systemLogs} />
    </Tabs>;
  };

  return <Grid container alignItems="center" justifyContent="center" className={classes.container}>
    <Grid item xs={11} sm={12} className={classes.item}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography data-testid="reportsTitle" variant="h6">{t("reports.reports")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              {renderTabs()}
            </Grid>
            <Grid item xs={12} md={10}>
              {reportExports()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
}

Reports.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default Reports;