import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useLsyHistory } from "_hooks";

import {
  Divider,
  Grid,
  Typography
} from "@mui/material";

import LsyFrame from "_components/Lockstasy/LsyFrame";
import LsyRouter from "_components/Navigation/LsyRouter";
import WorkSessionDetails from "_containers/Widgets/WorkSessionDetails";
import WorkSessionEventsTable from "_components/Table/WorkSessionEventsTable";
import WorkSessionsTable from "_components/Table/WorkSessionsTable";

import { KeyboardBackspaceRounded } from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/workSessionsStyle";

const useStyles = makeStyles()(styles);

function WorkSessions() {
  const { t } = useTranslation("default");
  const { classes } = useStyles();

  const [selectedWorkSession, setSelectedWorkSession] = useState({});

  const membershipId = useParams().id;
  const history = useLsyHistory();

  const renderWorkSessionTable = () => {
    return <WorkSessionsTable
      membershipId={membershipId}
      selectedWorkSession={selectedWorkSession}
      setSelectedWorkSession={setSelectedWorkSession}
      shouldSelectFirstWorkSession={true}
    />;
  };

  const renderWorkSessionDetails = () => {
    return <WorkSessionDetails
      selectedWorkSession={selectedWorkSession}
    />;
  };

  const renderWorkSessionEvents = () => {
    return <WorkSessionEventsTable
      selectedWorkSession={selectedWorkSession}
    />;
  };

  return <LsyFrame>
    <Grid container justifyContent="center">
      <Grid item xs={12} md={5.6} data-testid="WorkSessionsTableSection">
        <Typography variant="body1"><b>{t("label.workSessions")}</b></Typography>
        <LsyRouter className={classes.backContainer} page="user" id={membershipId} testId={"goBackTo"}>
          <div className={classes.line}>
            <KeyboardBackspaceRounded className={classes.backIcon}/>
            <span className={classes.titleDescription}>
              {history.location?.state.user}
            </span>
          </div>
        </LsyRouter>
        { renderWorkSessionTable() }
      </Grid>
      <Grid item xs={false} md={0.4} className={classes.displayGrid}>
        <Divider orientation="vertical" flexItem className={classes.divider} data-testid="WorkSessionsDivider"/>
      </Grid>
      <Grid item xs={12} md={6} data-testid="WorkSessionsDetailsSection">
        <Grid container direction="column">
          <Grid item>
            { renderWorkSessionDetails() }
          </Grid>
          <Grid item>
            { renderWorkSessionEvents() }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </LsyFrame>;
}

export default WorkSessions;