import { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { genericReducer } from "_reducers/general.reducer";
import { roleService } from "_services/lockstasy";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import RoleWizard2 from "_containers/Lockstasy/RoleWizard2";

import RolesIcon from "assets/teleporte/RolesIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Select/selectRoleStyle.js";

const useStyles = makeStyles()(styles);

const SelectRole = (props) => {
  const { t } = useTranslation("default");
  const { classes, cx } = useStyles();
  const { roleId, handleSubmit, showLabel, selectClass } = props;
  const inputProps = { variant: "outlined", ...props.inputProps};
  const label = showLabel ? {label: t("label.membershipType")} : {};
  const [ state, setState ] = useReducer(genericReducer,
    {
      roleOptions: [],
      openRoles: false
    }
  );
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const userGroupMap = lsyAdminDataContext.userGroupMap;
  const setOpenRole = open => setState({ openRoles: open });
  const openRole = () => setOpenRole(true);

  const fetchRoles = async () => {
    try {
      const result = await roleService.fetchRoles();
      setState({ roleOptions: result.data });
    } catch (e) {
      console.warn("Warning, failed to fetch roles", e);
    }
  };

  useEffect(() => {
    if (isEmpty(state.roleOptions)) {
      fetchRoles();
    }
  });

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      handleSubmit(event.currentTarget.dataset.value);
    }
  };

  const onChange = (event) => {
    handleSubmit(event.target.value);
  };

  return <Grid container spacing={1}>
    <Grid item>
      <FormControl variant={inputProps.variant} fullWidth>
        {showLabel ? <InputLabel>{label.label}</InputLabel> : null}
        <Select
          className={cx(classes.select, selectClass)}
          {...label}
          onChange={onChange}
          value={isEmpty(state.roleOptions) ? "" : roleId || ""}
          fullWidth
          MenuProps={{ MenuListProps: { disablePadding: true } }}
        >
          {state.roleOptions.map((option, index) => {
            return (
              <MenuItem
                key={`role-${index}`}
                onKeyDown={handleKeyDown}
                value={option.id}
              >
                {userGroupMap[option.name] || option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
    <Grid item>
      <Tooltip title={t("label.roles")}>
        <IconButton onClick={openRole} size="large">
          <RolesIcon className={classes.rolesIcon} />
        </IconButton>
      </Tooltip>
    </Grid>
    {!isEmpty(state.roleOptions) && 
      <RoleWizard2
        open={state.openRoles}
        setOpen={setOpenRole}
        roles={state.roleOptions}
      />
    }
  </Grid>;
};

SelectRole.defaultProps = {
  selectClass: "",
  showLabel: true,
  handleSubmit: () => {}
};

SelectRole.propTypes = {
  roleId: PropTypes.number,
  handleSubmit: PropTypes.func,
  inputProps: PropTypes.object,
  showLabel: PropTypes.bool,
  selectClass: PropTypes.string
};

export default SelectRole;