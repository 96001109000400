import {
  primaryColor,
  whiteColor,
  s4ShadowColor
} from "assets/jss/material-dashboard-pro-react.js";

const slideMenuStyle = {
  bmCrossButton: {
    height: "32px",
    width: "32px",
    marginTop: "8px",
    zIndex: 3
  },
  bmCross: {
    background: s4ShadowColor[0]
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%"
  },
  bmMenu: {
    background: whiteColor,
    fontSize: "1.15em",
    maxWidth: "100%",
    overflowX: "hidden"
  },
  bmMorphShape: {
    fill: primaryColor[1]
  },
  bmItemList: {
    color: primaryColor[1]
  },
  bmItem: {
    display: "inline-block"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};

export default slideMenuStyle;
