const itemPanelStyle = (theme) => ({
  spacing: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  },
  button: {
    color: theme.lsyPalette.primary.mainDark,
    borderColor: theme.lsyPalette.primary.mainDark,
    textTransform: "none"
  },
  subButton: {
    textTransform: "none"
  },
  errorMsg: {
    color: theme.lsyPalette.stdRed
  }
});
  
export default itemPanelStyle;
  