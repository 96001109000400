import {
  compact,
  isNull,
  isUndefined,
  map,
  omitBy,
  trim,
  uniq
} from "lodash";
import moment from "moment";

import { config } from "_configs/server-config";

const GOOGLE_MAPS_API_URL = "https://www.google.com/maps/search/?api=1";
const GOOGLE_MAPS_EMBED_API_URL = "https://www.google.com/maps/embed/v1/place?";
const GPS_ACCURACY = 6; // digits

export const TYPING_WAIT_TIME = 700; // time to wait for a user to finish typing in milliseconds

export const sortByKey = (array, key) => {
  if (array.length > 1) {
    return array.sort(function (a, b) {
      const x = a[key].toLowerCase(); const y = b[key].toLowerCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  } else {
    return array;
  }
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const pruneEmptyKeys = (obj, excludeKeys = []) => {
  return omitBy(obj, function(value, key) {
    return (excludeKeys.includes(key) || (value === null) || (value === undefined));
  });
};

/* help clean objects before serializing out as to not cause api errors */
export const sanitizeKeys = (obj, excludeKeys = [])  => {
  if (typeof(obj) === "object") {
    return pruneEmptyKeys(obj, excludeKeys);
  } else {
    return obj;
  }
};

/* when sent an axios error object, can send the lowest level / useful message
  possible, useful for pushing todebug console */
export const getLowLevelMessage = (errorObj) => {
  if (errorObj) {
    if (errorObj.response) {
      return JSON.stringify(errorObj.response.data);
    } else {
      return errorObj;
    }
  } else {
    return "";
  }
};

export const convertToInt = (number) => {
  return parseInt(number, 16);
};

export const convertToHex = (d, padding) => {
  let hex = Number(d).toString(16);
  padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

  while (hex.length < padding) {
    hex = "0" + hex;
  }

  return hex.toUpperCase();
};

export const isNullOrUndefined = value => isNull(value) || isUndefined(value);

export const isBlank = (str) => {
  return isNullOrUndefined(str) || /^\s*$/.test(str);
};

// remove falsey values from objects , arrays
export const compactObj = (obj, includeEmpty = false) => {
  if (Array.isArray(obj)) {
    if (includeEmpty)
      return uniq(map(obj, (obj) => trim(obj)));
    else
      return uniq(compact(map(obj, (obj) => trim(obj))));
  } else {
    return omitBy(obj, (value) => {
      if (!includeEmpty) {
        return isBlank(value);
      } else {
        return ((value === null) ||
          (value === undefined));
      }
    });
  }
};

export const hasWhiteSpace = (s) => {
  return /\s/g.test(s);
};

/* translate from string of strings and hex to formal array */
export const formatIdList = (idsString) => {
  if (!idsString) {
    return [];
  }

  /* strings are split either by space or , not both */
  let splitMarker = " ";
  if (idsString.indexOf(",") >= 0) {
    splitMarker = ",";
  }

  idsString = idsString.replace(/\n/g," ").replace(/\s+/," ").split(splitMarker);

  return compactObj(map(idsString, (el) => {
    let id;
    if (/(^0x)|([a-f])/i.test(el)) {
      id = convertToInt(el);
    } else {
      id = parseInt(el);
    }
    if (isNaN(id))
      id = null;
    return id;
  }));
};

export const isFloat = (value) => {
  return !isNaN(parseFloat(value)) && /^\s*[\d.]+\s*$/.test(value);
};

// transform an object of {a:1, b:2} -> a=1&b=2
export const transformToQueryString = (obj) => {
  return Object.keys(obj).map(key => key + "=" + obj[key]).join("&");
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const _titleizeWord = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const titleize = (sentence) => {
  if(!sentence.split)
    return sentence;

  let result = [];

  sentence.split(" ").forEach(function(w) {
    result.push(_titleizeWord(w));
  });

  return result.join(" ");
};

/* useful for not COMPLETELY wiping preferences/etc on a machine */
export const clearStorageExcept = (exceptions)  => {
  let keys = Object.keys(localStorage);
  exceptions = [].concat(exceptions); // prevent undefined

  // loop through keys
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];

    // check if key excluded
    if (!exceptions.includes(key))
      localStorage.removeItem(key);
  }
};

/**
 * Converst raw bytes value to human readable conversion
 * e.g. 712456123 becomes 71.24MB and 413 becomes 413B
 * @param {String} str Raw bytes to convert
 * @returns
 */
export const convertByteValueToHumanReadable = (str) => {
  if (str) {
    const ranges = [
      {n : 1000000n, d : 1000n, l : "KB"},
      {n : 1000000000n, d : 1000000n, l : "MB"},
      {n : 1000000000000n, d : 1000000000n, l : "GB"}
    ];

    const value = BigInt(str);
    for (let i = 0; i < ranges.length; ++i) {
      const r = ranges[i];
      if (value > 1000n && value < r.n) {
        const wholePart = (value / r.d).toString();
        const length = wholePart.length;
        return `${wholePart}.${value.toString().slice(length, length + 2)}${r.l}`;
      }
    }
    return `${value}B`;
  } else {
    return "0B";
  }
};

// Gets key using value from dictionary
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const weekdayDateTimeFormat = date => moment(date).format("llll");

export const getDevice = () => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "computer";
};

const getLocation = location => {
  let newLat;
  let newLng;

  if (typeof(location) === "string") {
    const [ lat, lng ] = location.split(",");
    newLat = lat.trim();
    newLng = lng.trim();

    return { lat: newLat, lng: newLng };
  } else {
    return location;
  }
};

export const formatLocation = location => {
  if (!location) {
    return "";
  }

  const newLocation = getLocation(location);

  const lat = parseFloat(newLocation.lat).toFixed(GPS_ACCURACY);
  const lng = parseFloat(newLocation.lng).toFixed(GPS_ACCURACY);

  return `${lat}, ${lng}`;
};

export const generateGoolgeMapsUrlPoint = (location) => {
  const { lat, lng } = location;
  return encodeURI(`${GOOGLE_MAPS_API_URL}&query=${lat},${lng}`);
};

export const generateGoolgeMapsEmbedApiUrl = (location, zoom) => {
  const { lat, lng } = location;
  const z = zoom ? `&zoom=${zoom}` : "";

  return encodeURI(`${GOOGLE_MAPS_EMBED_API_URL}key=${config.googleMapsAPIKey}${z}&center=${lat},${lng}&q=${lat},${lng}`);
};

/**
 * The getDiff function compares two objects a and b and
 * returns an object containing the properties that have
 * different values in b compared to a
 */
export const getDiff = (a, b) => {
  return (Object.keys(b).reduce((diff, key) => {
    if (a[key] === b[key]) 
      return diff;
    return {
      ...diff,
      [key]: b[key]
    };
  }, {}));
};