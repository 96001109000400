import { twsWebUrl } from "_services/helper";
import { get, del } from "_helpers/axios/gw.client";
import { userDeviceHelper } from "_helpers";

const fetchUserDevices = async ({id, options}) => {
  const result = await get(`${twsWebUrl}/users/${id}/devices`, options);
  const data = userDeviceHelper.formatDeviceData(result.data);
  return { ...result, data };
};

const removeRestrictedDevice = async ({id, deviceId}) => {
  return await del(`${twsWebUrl}/users/${id}/devices/${deviceId}/restriction`);
};

const fetchDeviceHistory = async ({id, deviceId, options}) => {
  const result = await get(`${twsWebUrl}/users/${id}/devices/${deviceId}/access_history`, options);
  const data = userDeviceHelper.formatDeviceData(result.data);
  return { ...result, data };
};

export const userDevicesService = {
  fetchUserDevices,
  removeRestrictedDevice,
  fetchDeviceHistory
};