import { useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LOCK_EVENT } from "_constants/lock.constants";
import {
  accessHistoryHelper,
  formatDateAsMMMMDDYYYYhhmmssA,
  formatDuration,
  formatLocation,
  generateGoolgeMapsUrlPoint,
  getLocalTZNameFormatted,
  isNullOrUndefined
} from "_helpers";
import { getLockEventName } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";
import { userService, lockService } from "_services";

import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Skeleton } from "@mui/material";

import LsyTable2 from "_components/Table/LsyTable2";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import SummaryPopoverWidget from "./SummaryPopoverWidget";

import SwapIcon from "assets/teleporte/SwapIcon";
import {
  List,
  Launch as LaunchIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessHistoryStyle";

const useStyles = makeStyles()(styles);

function AccessEventsTable(props) {
  const { data, type, handleDirectionChange, loading,
    isShowAuditTable, pagination, sortDirection } = props;

  const { classes } = useStyles();
  const { t } = useTranslation("default");

  const isLockTable = () => type === "users";

  const getHeader = () => {
    if (isShowAuditTable) {
      return [
        ...isLockTable() ?
          [{label: `${t("widgetField.lock")}`, id: "lock", sortable: false}] :
          [{label: `${t("widgetField.user")}`, id: "user", sortable: false}],
        {label: `${t("label.action")}`, id: "event", sortable: false},
        {label: `${t("label.actionTime")}`, id: "timestamp", sortable: false},
        {label: `${t("label.location")}`, id: "location", sortable: false}
      ];
    } else {
      return [
        ...isLockTable() ?
          [{label: `${t("widgetField.lock")}`, id: "lock", sortable: false}] :
          [{label: `${t("widgetField.user")}`, id: "user", sortable: false}],
        {label: `${t("widgetField.open")}`, id: "open", sortable: false},	
        {label: `${t("label.closed")}`, id: "closed", sortable: false}, 
        {label: `${t("label.duration")}`, id: "duration", sortable: false}
      ];
    }
  };
  
  const connectedLatchLabels = [
    {label: `${t("label.openBy")}`, id: "open_by", sortable: false},
    {label: `${t("label.openTime")}`, id: "open_time", sortable: false},
    {label: `${t("label.closedBy")}`, id: "closed_by", sortable: false},
    {label: `${t("label.closedTime")}`, id: "closed_time", sortable: false},
    {label: `${t("label.duration")}`, id: "duration", sortable: false}
  ];

  const [popoverState, setPopoverState] = useReducer(genericReducer,
    {
      isSummaryOpen: false,
      data: {},
      anchorEl: null,
      type: "user"
    }
  );
  const setIsSummaryOpen = (isSummaryOpen) => setPopoverState({ isSummaryOpen });

  const defaultData = [
    [
      {
        id: "Date",
        numeric: false,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type2",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Open",
        numeric: true,
        disablePadding: false,
        label: "-"
      }
    ],
    [
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      }
    ]
  ];

  const handleSummary = async (e, data) => {
    if (data.deleted) {
      setPopoverState({
        data: data,
        isSummaryOpen: true,
        type: isLockTable() ? "lock" : "user",
        anchorEl: e.target
      });
      return;
    }

    try {
      let result = isLockTable() ? await lockService.fetchLock({lockId: data.id}) : await userService.fetchUser({id: data.membership_id});
      setPopoverState({
        data: result.data,
        isSummaryOpen: true,
        type: isLockTable() ? "lock" : "user",
        anchorEl: e.target
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const getUser = (data) => {
    if (!data) {
      return { text: "" };
    }

    const user = accessHistoryHelper.getUser(data.opened_via, data.user, t);

    return {
      text: user.name,
      ...user.action && { action: (e) => handleSummary(e, user) }
    };
  };

  const handleNoteAction = (e, message) => {
    setPopoverState({
      data: { message },
      isSummaryOpen: true,
      type: "lockNote",
      anchorEl: e.target
    });
  };

  const getUserOrLockColumnForEventTable = data => {
    return isLockTable() ?
      { lock: { text: data.name, action: (e) => handleSummary(e, data) } } :
      { user: { text: data.user ? `${data.user.first_name} ${data.user.last_name}` : "", action: (e) => handleSummary(e, data.user) } };
  };

  const getNoteEventAction = data => {
    /*
      The backend won’t send the lock_event_id and message when the lock license is keyless.
      In this case, there is no note information to display, but a note event exists for that lock.
      So, the lock note event is displayed in the table, but there is no action to display the lock note information.
    */
    return data.lock_event_id && data.event === LOCK_EVENT.note ? (e) => handleNoteAction(e, data.message) : null;
  };

  const getLocation = location => {
    return location ? 
      <span>
        <a
          href={generateGoolgeMapsUrlPoint(location)}
          className={classes.redirectLink}
          target="_blank"
          rel="noreferrer"
        >
          {formatLocation(location)}
          <LaunchIcon className={classes.redirectIcon}/>
        </a>
      </span> : null;
  };

  const getFormattedEvents = data => {
    return {
      ...getUserOrLockColumnForEventTable(data),
      event: { text: t(getLockEventName(data.event, data.opened_via)), action: getNoteEventAction(data) },
      timestamp: { text: formatDateAsMMMMDDYYYYhhmmssA(data.timestamp) },
      location: { text: getLocation(data.location) }
    };
  };

  const getUserOrLockColumnForAccessTable = v => {
    return isLockTable() ?
      { lock: { text: v.lock?.name, action: (e) => handleSummary(e, v.lock) }} :
      { user: getUser(v.open) };
  };

  const getFormattedData = () => {
    const header = getHeader();
    const formattedData = data.map(v => {
      if (isShowAuditTable) {
        return getFormattedEvents(v);
      } else {
        const rtsInfo = !isNullOrUndefined(v.closed?.rts_info) ? classes.rtsInfo : "";
        const duration = v.closed?.timestamp ? `${formatDuration(v.duration, t)}${rtsInfo ? "*" : ""}` : "";

        return {
          ...getUserOrLockColumnForAccessTable(v),
          open: { text: formatDateAsMMMMDDYYYYhhmmssA(v.open?.timestamp) },
          closed: {
            text: `${formatDateAsMMMMDDYYYYhhmmssA(v.closed?.timestamp)}${rtsInfo ? "*" : ""}`,
            classNameProps: rtsInfo
          },
          duration: { text: duration, classNameProps: rtsInfo }
        };
      }
    });

    return { header: header, data: formattedData };
  };

  const getFormattedConnectedLatchData = () => {
    const formattedData = data.map(v => {
      return {
        open_by: getUser(v.open),
        open_time: { text: formatDateAsMMMMDDYYYYhhmmssA(v.open.timestamp) },
        closed_by: getUser(v.closed),
        closed_time: { text: formatDateAsMMMMDDYYYYhhmmssA(v.closed.timestamp) },
        duration: { text: v.closed?.timestamp ? formatDuration(v.duration, t) : "" }
      };
    });

    return { header: connectedLatchLabels, data: formattedData };
  };

  const getTableData = () => {
    const formattedData = !isLockTable() && !isShowAuditTable && data[0].unlatch_by_default ? getFormattedConnectedLatchData() : getFormattedData();

    const tableData = [
      formattedData.header,
      [...formattedData.data]
    ];
    return tableData;
  };

  const handleChangePage = (event, newPage) => {
    pagination.setCurrentPage(newPage + 1);
  };

  const handleRowChange = (event) => {
    pagination.setRowsPerPage(parseInt(event.target.value, 10));
  };
  
  const getDirectionText = () => sortDirection === "desc" ? t("label.viewingLatest") : t("label.viewingOldest");

  const renderExtraInfo = () => {
    const rtsInfo = data?.find(d => !isNullOrUndefined(d.closed?.rts_info))?.closed.rts_info;
    
    return rtsInfo &&
      <Typography data-testid="rtsInfo" variant="caption" className={classes.rtsInfo}>{rtsInfo}</Typography>;
  };

  return (
    <ErrorBoundary>
      <div className={classes.subHeader} data-testid="header">
        <div className={classes.eventType} data-testid="timezoneSection">
          <span>
            <b>{`${t("label.timezone")}: `}</b>
            {`${getLocalTZNameFormatted()}`}
          </span>
        </div>
        <TablePagination
          className={classes.pagination}
          data-testid="pagination"
          component="div"
          count={pagination.totalSize || 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 15, 20, 25]}
          onRowsPerPageChange={handleRowChange}
        />
      </div>
      {data.length !== 0 ? 
        <div data-testid="table">
          {renderExtraInfo()}
          <LsyTable2
            className={classes.table}
            data={loading ? defaultData : getTableData()}
            sortDirection={sortDirection}
          />
        </div> : 
        <div className={classes.placeholder} data-testid="placeholder">
          <Placeholder 
            message={t("fallbacks.noData")} 
            classNameMessage={classes.placeholderText} 
            icon={<List/>} 
            classNameIcon={classes.placeholderIcon}
          />
        </div>
      }
      <div className={classes.subHeader} data-testid="footer">
        <IconButton data-testid="sortDirectionButton" className={classes.iconButton} onClick={handleDirectionChange} size="small">
          <SwapIcon className={classes.chipIcon} pathClassName={classes.red} sortDirection={sortDirection.toUpperCase()} width="20" height="20"/>
        </IconButton>
        <div className={classes.description} data-testid="sortDirectionText">{getDirectionText()}</div>
        <TablePagination
          className={classes.pagination}
          data-testid="pagination"
          component="div"
          count={pagination.totalSize || 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 15, 20, 25]}
          onRowsPerPageChange={handleRowChange}
        />
      </div>

      <SummaryPopoverWidget
        type={popoverState.type}
        data={popoverState.data}
        open={popoverState.isSummaryOpen}
        setOpen={setIsSummaryOpen}
        anchorEl={popoverState.anchorEl}
      />
    </ErrorBoundary>
  );
}

AccessEventsTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object.isRequired,
  type: PropTypes.string,
  sortDirection: PropTypes.string,
  handleDirectionChange: PropTypes.func,
  isShowAuditTable: PropTypes.bool,
  loading: PropTypes.bool
};

export default AccessEventsTable;