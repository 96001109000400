import PropTypes from "prop-types";

import { Chip } from "@mui/material";

import DateTimeRange from "_components/Date/DateTimeRange";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Date/chipDateStyle";

const useStyles = makeStyles()(styles);

function ChipDate(props) {
  const { classNameProps, date, onDelete, setDate } = props;
  const { classes, cx } = useStyles();

  return <Chip
    data-testid="dateChip"
    className={cx(classes.chip, classNameProps.chip)}
    onDelete={onDelete}
    label={
      <DateTimeRange
        date={date}
        setDate={setDate}
        isRange={false}
        numberOfMonths={1}
        showTime={false}
        label={" "}
        format="YYYY/MM/DD"
        inputProps={{
          className: cx(classes.input, classNameProps.input)
        }}
        calendarProps={{ onOpenPickNewDate: false}}
      />
    }
  />;
}

ChipDate.defaultProps = {
  classNameProps: {}
};

ChipDate.propTypes = {
  classNameProps: PropTypes.object,
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  setDate: PropTypes.func,
  onDelete: PropTypes.func
};

export default ChipDate;