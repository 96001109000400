import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/accountCardStyle";
const useStyles = makeStyles()(styles);

function AccountCardIcon(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_AccountCardIcon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 122.88 81.38" className={classes.svg + " " + (props.className || "")} xmlSpace="preserve">
      <path className={cx(classes.accountCard, (props.pathClassName || ""))} d="M68.75,62c-1,0-1.79-1.07-1.79-2.38s.8-2.38,1.79-2.38h35.91c1,0,1.78,1.07,1.78,2.38s-.8,2.38-1.78,2.38ZM25.66,0H97.22a3.75,3.75,0,0,1,3.66,3h8.79a13.25,13.25,0,0,1,13.21,13.2v52A13.19,13.19,0,0,1,119,77.49l0,0a13.18,13.18,0,0,1-9.31,3.87H13.21A13.25,13.25,0,0,1,0,68.18v-52A13.13,13.13,0,0,1,3.88,6.89l0,0A13.15,13.15,0,0,1,13.21,3H22a3.74,3.74,0,0,1,3.66-3ZM101,9.17v.49a3.74,3.74,0,0,1-3.73,3.73H25.66a3.74,3.74,0,0,1-3.73-3.73V9.17H13.21a7,7,0,0,0-5,2.06h0a7,7,0,0,0-2.05,5v52a7.05,7.05,0,0,0,7,7h96.46a7,7,0,0,0,5-2.06h0a7,7,0,0,0,2.06-5v-52a7.07,7.07,0,0,0-7-7Zm-56.19,39C51.89,54.37,56.53,45.44,60,60a2.23,2.23,0,0,1-2.34,2.19H19.52a2.25,2.25,0,0,1-2.34-2.19c3.38-14.34,7.45-5.67,15.1-11.74a27.69,27.69,0,0,0,1-2.85c.25-.78-1.3-2.32-1.82-3.15l-1.93-3.08a5.56,5.56,0,0,1-1.1-2.81,2.22,2.22,0,0,1,.19-1,2,2,0,0,1,.67-.77,2.07,2.07,0,0,1,.47-.24,48.09,48.09,0,0,1-.1-5.52A7.78,7.78,0,0,1,30,27.62a8.14,8.14,0,0,1,5.36-5.21c1.21-.41.74-1.41,2-1.34,2.88.15,7.33,2,9,4,2.4,2.76,1.78,6.16,1.7,9.54h0a1.37,1.37,0,0,1,1,1,4.34,4.34,0,0,1-.52,2.64h0l0,.07L46.27,42a18,18,0,0,1-2.86,3.88c-.33.27,1.13,2.06,1.35,2.35Zm24-15c-1,0-1.79-1.07-1.79-2.38s.8-2.38,1.79-2.38H90.21c1,0,1.79,1.07,1.79,2.38s-.8,2.38-1.79,2.38Zm0,14.37c-1,0-1.79-1.07-1.79-2.38s.8-2.38,1.79-2.38h34c1,0,1.79,1.07,1.79,2.38s-.8,2.38-1.79,2.38Z"></path>
    </svg>
  );
}

AccountCardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

export default AccountCardIcon;
