/* This file is used for s4 admin purposes */
import { get, post } from "_helpers/axios/gw.client";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";
import {
  identityUrl
} from "_services/helper";

/* this can be aggressive, so protect it by determining if there is a copy already in the state tree */
const fetchGlobalOrganizations = async (options = { }) => {
  var entries = options["entries"] || 1000;

  var additionalArgs = transformToQueryString(sanitizeKeys(options, ["entries"]));
  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
  }

  return get(`${identityUrl}/tenants?page_size=${entries}${additionalArgs}`);
};

const fetchTotalOrganizations = () => {
  return get({ url: `${identityUrl}/tenants/count`});
};

const fetchGlobalOrganization = async (tenantId, options = {}) => {
  var additionalArgs = transformToQueryString(sanitizeKeys(options, ["entries"]));
  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
  }

  return get(`${identityUrl}/tenants/${tenantId}${additionalArgs ? "?" + additionalArgs : ""}`);
};

const fetchAPITokens = async (tenantId) => {
  return get(`${identityUrl}/tenants/${tenantId}/api_tokens`);
};
const updateOrganization = (params) => {
  return post(`${identityUrl}/tenants/${params.id}`, params.data);
};

export const organizationService = {
  fetchGlobalOrganizations,
  fetchTotalOrganizations,
  fetchGlobalOrganization,
  updateOrganization,
  fetchAPITokens
};