import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import RegularButton from "_components/Button/RegularButton";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Panel/itemPanelStyle.js";

const useStyles = makeStyles()(styles);

function ItemPanel(props) {
  const { classes } = useStyles();
  const { buttons } = props;

  const renderButtons = () => {
    if (buttons.length === 1) {
      return <Button variant="outlined" size="small" className={classes.button} onClick={buttons[0].onClick}>
        {buttons[0].label}
      </Button>;
    }

    return buttons.map((button, idx) => {
      return <RegularButton key={`${button.label}-${idx}`} className={classes.subButton} variant="text" onClick={button.onClick} disabled={button.disabled}>
        {button.label}
      </RegularButton>;
    });
  };

  return <Grid item>
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.spacing}>
      <Grid item>
        { props.children }
      </Grid>
      { buttons.length > 0 &&
        <Grid item>
          { renderButtons() }
        </Grid>
      }
      { props.errorMsg && 
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.errorMsg}>{props.errorMsg}</Typography>
        </Grid>
      }
    </Grid>
  </Grid>;
}

ItemPanel.defaultProps = {
  buttons: []
};

ItemPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  errorMsg: PropTypes.string
};

export default ItemPanel;