import PropTypes from "prop-types";

import Skeleton from "@mui/material/Skeleton";

const CustomSkeleton = (props) => {
  return props.loading ?
    <Skeleton data-testid="skeleton" animation="wave" /> :
    props.children || null;
};

CustomSkeleton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool
};

export default CustomSkeleton;