import { config } from "../_configs/server-config";
import authClient, { get, post, put } from "../_helpers/axios/gw.client";

import { handleResponse, identityUrl } from "_services/helper";
import { tokenExpKey } from "_helpers";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

function handleSuccessLoginResponse(data) {
  if (data.data) {
    return data.data;
  } else {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    const [, subdomain, domain] = window.location.hostname.match(/^([^.]+)\.(.+)$/) || [];

    const {tws_token_data: twsTokenData } = data.tws_token;
    let decodedToken;
    try {
      /* -- example payload --
        aud: ['all']
        context: {key: 'local-gateway', user: {…}, client: {…}, gw: 'solo_gateway', roles: Array(1)}
        exp: 1673445326308
        iat: 1665669326307
        iss: "sera4_production"
        jti: "0e9a65c8-6b44-41bf-a163-d1fbcb6be8cf"
        sub: "Name-e85c891f-fc17-42ce-a83d-c5ab3686885a"
      */
      decodedToken = jwt_decode(twsTokenData);
      data.decodedToken = decodedToken;

      // setting expiry time of the twsToken so auto-timeout knows when to auto-logout
      localStorage.setItem(tokenExpKey, parseInt(decodedToken.exp));
    } catch(e) {
      console.warn("failed to decode JWT payload");
    }

    // used for cross-domain switching/teleporte domains
    cookies.set("twsAuthSD", subdomain, { expires: new Date(decodedToken.exp), secure: config.secureProtocol, path: "/", sameSite: "strict", domain: `.${domain}` });
    localStorage.setItem("domain", domain);
    localStorage.setItem("user", JSON.stringify(data.tws_account));
    localStorage.setItem("accountId", data.tws_account.id);
    localStorage.setItem("sessionId", data.tws_token.tws_token_client_id);
    return data;
  }
}

function login(username, password, mfaCode, tcAgreement) {
  console.debug("Authenticating against:", config.s4SSOGwUrl);

  const requestOptions = {
    method: "post",
    url: "sessions",
    data: { username, password }
  };

  if (mfaCode){
    requestOptions.data.mfa_code = mfaCode;
  }

  if (tcAgreement){
    requestOptions.data.terms_and_conditions = tcAgreement;
  }

  return authClient(requestOptions)
    .then((r) => handleResponse(r, { refresh: false }))
    .then((data) => handleSuccessLoginResponse(data));
}

function loginPasswordless(data) {
  return get(`${config.s4SSOGwUrl}/sessions/passwordless`, data);
}

function verifyPasswordless(data) {
  return post(`${config.s4SSOGwUrl}/sessions/passwordless`, data)
    .then((data) => handleSuccessLoginResponse(data));
}

function updatePersonalInfoAccount(id, data) {
  return post(`${config.s4SSOGwUrl}/identity/accounts/${id}`, data);
}

function extendSession() {
  return post(`${config.s4SSOGwUrl}/sessions/extend`)
    .then((data) => handleSuccessLoginResponse(data));
}

function resetPassword(username) {
  console.debug("Resetting password for:", username);

  const requestOptions = {
    method: "post",
    url: `accounts/${username}/password_reset`
  };

  return authClient(requestOptions);
}

function getAuthenticator(email, password) {
  const payload = { mfa_method: "TOTP", password, email };
  return post(`${identityUrl}/mfa/setup/authenticator`, payload);
}

function confirmAuthenticator(mfa_code) {
  const payload = { mfa_method: "TOTP", mfa_code };
  return post(`${identityUrl}/mfa/confirm-authenticator`, payload);
}

function setMfa(user_id, mfa_status, email, password = "      ") {
  const payload = {
    mfa_method: "TOTP",
    mfa_status,
    password,
    email
  };
  return put(`${identityUrl}/mfa/${user_id}`, payload);
}

export const authService = {
  login,
  loginPasswordless,
  verifyPasswordless,
  updatePersonalInfoAccount,
  extendSession,
  resetPassword,
  getAuthenticator,
  confirmAuthenticator,
  setMfa
};