import { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { invalidTagRegex, invalidSearchCharsRegex } from "_helpers";
import { lockgroupService } from "_services/lockstasy";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import TagIcon from "@mui/icons-material/LocalOfferOutlined";

import CardListWidget from "_containers/Widgets/CardListWidget";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Placeholder from "_components/Helper/Placeholder";
import LockGroupsCard from "_components/Lockstasy/LockGroupsCard";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockGroupsStyle.js";

const useStyles = makeStyles()(styles);

function LockGroups(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const fetchLockGroups = async (options) => {
    try {
      const result = await lockgroupService.fetchLockgroups(options);
      return result;
    } catch (e) {
      console.warn("Warning, failed to fetch lock groups", e);
      return [];
    }
  };

  const createLockGroup = async (data, fetchWidgetData) => {
    try {
      await lockgroupService.createLockgroup({lock_group: data});
      dispatch(alertActions.send(t("success.createLockgroup")));
    } catch (e) {
      console.warn("Warning, failed to create lock group", e);
      dispatch(alertActions.send(t("error.createLockgroup"), "error"));
    }

    setTimeout(() => {
      fetchWidgetData();
    }, [1000]);
  };

  const skeletonCard = <Card className={classes.card}>
    <div className={classes.divBar} />
    <GridContainer direction="column" alignItems="center">
      <GridItem xs={8}>
        <Skeleton variant="text" width={120} height={50} />
      </GridItem>
      <GridItem xs={8}>
        <Skeleton variant="text" width={180} height={30} />
      </GridItem>
    </GridContainer>
  </Card>;

  const lockGroupFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal) => {
    return data.map((data) => {
      return (
        <GridItem data-testid="lockgroupContainer" key={data.id} xs={12} sm={6} md={4} lg={3}>
          <LockGroupsCard
            key={data.id}
            data={data}
            createModal={createModal}
            fetchWidgetData={fetchWidgetData}
          />
        </GridItem>
      );
    });
  };

  const getAdditionOptions = () => {
    return [
      {
        field: "name",
        type: "textField",
        label: t("form.name"),
        required: t("error.requiredField"),
        validateEntry: value => !invalidTagRegex.test(value),
        errorMsg: t("error.invalidCreateChars"),
        autoFocus: true
      },
      {
        field: "description",
        type: "textField",
        label: t("form.description"),
        validateEntry: value => !invalidTagRegex.test(value),
        errorMsg: t("error.invalidCreateChars")
      }
    ];
  };

  const getFilterOptions = () => {
    return [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        validateEntry: value => !invalidSearchCharsRegex.test(value),
        errorMsg: t("error.invalidSearch"),
        autoFocus: true
      }
    ];
  };

  const getDefaultValues = () => {
    return {
      search: ""
    };
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        <GridItem data-testid="cardListWidget" className={classes.lockGroupWidget} xs={12} md={10}>
          <CardListWidget
            skeletonCard={skeletonCard}
            fallbackData={<Placeholder message={t("fallbacks.noLockgroups")} icon={<TagIcon/>}/>}
            org={props.org}
            paginate
            stats
            title={t("features.lockGroups")}
            rowsPerPage={12}
            secondPaginate={true}
            enableFilter
            filterOptions={getFilterOptions()}
            fielterDefaultValues={getDefaultValues()}
            removeEmptyOptions
            additionForm={getAdditionOptions()}
            additionIcon={<AddIcon className={classes.additionIcon} data-testid="addIcon" />}
            additionLabel={t("label.createLockGroup")}
            addition={ability.can("create", "lock_groups") ? createLockGroup : null}
            fetchData={fetchLockGroups}
            dataFormatter={lockGroupFormatter}
            appBarSize="md"
            display="grid"
            enableQueryParams
            location={props.location}
          />
        </GridItem>
      </Grid>
    </div>
  );
}

LockGroups.propTypes = {
  org: PropTypes.string,
  location: PropTypes.object
};

export default LockGroups;
