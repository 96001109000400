import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "tss-react/mui";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles()({
  ...styles,
  highlight : {
    background: "#b5e2fc"
  }
});

export default function CustomInput(props) {
  const { classes, cx } = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    outlined,
    helperText,
    errorMsg
  } = props;
  const labelClasses = cx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
    [props.labelClass]: props.labelClass
  });
  const underlineClasses = cx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = cx({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = cx({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.outlined]: outlined
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = cx(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = cx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  return (
    <FormControl variant="standard" {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
          error={error}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        className={(props.highlighted) ? classes.highlight : ""}
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        onChange={e => props.onChange(e)}
        {...inputProps}
        error={error}
      />
      {error && errorMsg !== undefined ? (
        <FormHelperText id={id + "-errorMessage"} error={true} className={classes.error}>
          {errorMsg}
        </FormHelperText>
      ) : null}
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
  highlighted: PropTypes.bool,
  outlined: PropTypes.bool
};

CustomInput.defaultProps = {
  onChange: () => {}
};