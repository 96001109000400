import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

import Placeholder from "_components/Helper/Placeholder";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { QueryStatsOutlined } from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/systemLogsStyle";

const useStyles = makeStyles()(styles);

function SystemChartsWidget(props) {
  const { title, description, startDate, endDate, getOptions, chartLoading, classNameChart } = props;

  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const language = useSelector((state) => state.locale.language);
  moment.locale(language);

  return (
    <div>
      <Typography variant="body1"><b>{title}</b></Typography>
      { description &&
        <Typography variant="caption" className={classes.chartDescription}>
          {description}
        </Typography>
      }
      <div className={classes.datePeriod}>
        {startDate.locale(language).format("MMM DD, YYYY")} - {endDate.locale(language).format("MMM DD, YYYY")}
      </div>
      {chartLoading ? <Skeleton className={classNameChart} variant="rectangular"/> : 
        !getOptions()?.series[0]?.data.every(item => item === 0) ? 
          <ReactECharts
            option={getOptions()}
            notMerge={true}
            lazyUpdate={true}
            className={classNameChart}
          /> : 
          <div className={classes.placeholder}>
            <Placeholder
              icon={<QueryStatsOutlined/>}
              classNameMessage={classes.placeholderText}
              classNameIcon={classes.placeholderIcon}
            >
              <Typography className={classes.placeholderText}>{t("fallbacks.noChartData")}</Typography>
            </Placeholder>
          </div>
      }
    </div>
  );

}

SystemChartsWidget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  getOptions: PropTypes.func,
  chartLoading: PropTypes.bool,
  classNameChart: PropTypes.any
};

export default SystemChartsWidget;