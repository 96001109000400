import { useTranslation } from "react-i18next";

import { PropTypes } from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const CustomAlert = ({ type, className, style }) => {
  const { t } = useTranslation("auth");

  let title = "";
  let body = "";
  let severity = "";

  switch (type) {
    case "MfaFirstAttemptIncorrect":
      body = t("mfa.firstAttemptIncorrect");
      severity = "warning";
      break;
    case "MfaInfo":
      title = t("mfa.setupTitle");
      body = t("mfa.setupAlert");
      severity = "info";
      break;
    case "MfaSuccess":
      title = t("mfa.enabledTitle");
      body = (
        <>
          {t("mfa.successAlert1")} <br></br> {t("mfa.successAlert2")} <br></br>{" "}
          {t("mfa.successAlert3")}{" "}
        </>
      );
      severity = "success";
      break;
    case "MfaEnabled":
      title = t("mfa.enabledTitle");
      body = t("mfa.enabledAlert");
      severity = "success";
      break;
    case "MfaDisabled":
      title = t("mfa.disabledTitle");
      body = t("mfa.disabledAlert");
      severity = "error";
      break;
    case "MfaError":
      break;
    case "MfaRecovery":
      title = "TWO-FACTOR RECOVERY";
      body = (
        <p>
          If you cannot access your account through your normal two-step login
          method, you can use your two-step login recovery code to disable all
          two-step providers on your account.
        </p>
      );
      severity = "warning";
      break;
    case "CustomConfigurationOrg":
      title = "Custom Configuration Applicable";
      body = (
        <p>
          This organization has custom setup configurations. Please refer to the following <a target="_blank" rel="noreferrer" href="https://lockedup.atlassian.net/wiki/spaces/CI/pages/2505310227/Advanced+Configurations+Lock+Types+Special+Firmware+Flex+Licenses+-+Customer+Set+Up">document</a>.
        </p>
      );
      severity = "warning";
      break;
  }

  return (
    <Alert severity={severity} className={className} style={style}>
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  );
};

CustomAlert.propTypes = {
  type: PropTypes.oneOf([
    "MfaInfo",
    "MfaSuccess",
    "MfaEnabled",
    "MfaDisabled",
    "MfaError",
    "MfaRecovery",
    "CustomConfigurationOrg",
    "MfaFirstAttemptIncorrect"
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default CustomAlert;
