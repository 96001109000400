import { useTranslation } from "react-i18next";

// @mui/material components
import { makeStyles } from "tss-react/mui";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles()(styles);

export default function InterfaceErrorPage() {
  const { classes } = useStyles();
  const { t } = useTranslation("default");

  return (
    <GridContainer
      justifyContent="center"
      alignItems="center"
      spacing={0}
      direction="row"
      className={classes.background}
    >
      <GridItem>
        <h2 className={classes.subTitle}>{t("error.fullPageTitle")}</h2>
        <h4 className={classes.description}>
          {t("error.fullPageDescription")}
        </h4>
      </GridItem>
    </GridContainer>
  );
}
