import { config } from "_configs/server-config";

const getConnectSrc = () => {
  const connectSrc = [
    "'self'",
    `wss://${config.domain}`,
    `https://${config.domain}`,
    config.telescopioUrl,
    "https://maps.googleapis.com",
    "https://www.gravatar.com/avatar/",
    "https://*.ingest.sentry.io",
    "https://*.cloudflare.com",
    "https://cloudflareinsights.com"
  ];

  if (process.env.NODE_ENV === "development") {
    connectSrc.push(
      `ws://${config.domain}`,
      `http://${config.domain}`
    );
  }

  return connectSrc;
};

const cspConfigPolicy = {
  "default-src": "'none'",
  "base-uri": "'self'",
  "connect-src": getConnectSrc(),
  "script-src": [
    "'self'",
    "https://maps.googleapis.com",
    "https://cdnjs.cloudflare.com",
    "https://static.cloudflareinsights.com"
  ],
  "style-src": [
    "'self'",
    "'unsafe-inline'",
    "https://fonts.googleapis.com",
    "https://use.fontawesome.com",
    "https://cdnjs.cloudflare.com"
  ],
  "font-src": [
    "'self'",
    "https://fonts.googleapis.com",
    "https://fonts.gstatic.com",
    "https://use.fontawesome.com",
    "https://cdnjs.cloudflare.com"
  ],
  "img-src": [
    "'self'",
    "data:",
    "https://www.sera4.com/",
    "https://www.gravatar.com/avatar/",
    "https://maps.googleapis.com",
    "https://maps.gstatic.com",
    "https://developers.google.com"
  ],
  "frame-src": [
    "https://www.google.com"
  ],
  "manifest-src": "'self'",
  "worker-src": ["'self'", "blob:"]
};

export const getCSPConfigPolicyAsString = () => {
  const formattedCSPConfigPolicy = Object.keys(cspConfigPolicy).map(key => {
    let value = cspConfigPolicy[key];
    if (Array.isArray(value)) {
      value = value.join(" ");
    }

    return `${key} ${value}`;
  });

  return formattedCSPConfigPolicy.join("; ");
};