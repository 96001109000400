import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "tss-react/mui";
import { capitalize } from "lodash";

const styles = theme => ( {
  root: {
    marginLeft:  theme.spacing(1),
    marginRight: theme.spacing(1),
    textDecoration: "none"
  },
  textDefault: {
    fontSize: theme.typography.fontSize
  },
  textLarge:  theme.typography.largeFont,
  textSmall: theme.typography.smallFont
});

const BasicAnchor = ( props ) => {
  const {
    classes,
    size,
    alt,
    title,
    href,
    className: classNameProp
  } = props;

  const cs = classNames(
    classes.root,
    {
      [classes[`text${capitalize(size)}`]]: size !== "default"
    },
    classNameProp
  );

  return (<a data-testid="basicLinkId" href={href} target="_blank" rel="noreferrer" className={cs} alt={alt || title} title={title || alt}>{props.children}</a>);
};

BasicAnchor.propTypes = {
  /**
   * Set the text-align on the component.
   */
  //align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    "default",
    "error",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary"
  ]),
  size: PropTypes.oneOf([
    "small",
    "default",
    "large"
  ]),
  alt:   PropTypes.string,
  title: PropTypes.string,
  href:  PropTypes.string,
  underline:  PropTypes.bool
};

BasicAnchor.defaultProps = {
  color: "default",
  size: "default",
  underline: true
};

export default withStyles(BasicAnchor, styles);