const aboutStyle = (theme) => ({
  link:{
    color:theme.lsyPalette.link,
    textDecoration:"none",
    cursor: "pointer"
  },
  marginTop: {
    marginTop: theme.spacing(4)
  }
});
  
export default aboutStyle;