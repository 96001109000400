import { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import { organizationService } from "_services";
import { alertActions, memberActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { history } from "_helpers";
import appRoutes from "_routes";
import { encodePreferences, isLicenseExpiryAlertsOn, isPasswordlessRequired } from "_helpers/systemSettings";
import { memberService } from "_services/admin";
import { keyTimeUnits, maxKeyDurations, defaultPreferences } from "_constants/systemSettings.constants";

// Components
import LoadingPlaceHolder from "_components/Loading";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";

// Icons
import ErrorIcon from "@mui/icons-material/Error";

//styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/systemSettingsStyle.js";

const useStyles = makeStyles()(styles);

const SystemSettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("default");
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);
  const { handleSubmit, reset, setValue, clearErrors, control, getValues, formState: { isValid, errors, dirtyFields } } = useForm({ mode: "onTouched" });
  const {fetchPreferences, orgPreferences} = useContext(LsyAdminDataContext);
  const [submitting, setSubmitting] = useState(false);
  const [passwordlessChecked, setPasswordlessChecked] = useState(false);
  const [licenseExpiryChecked, setLicenseExpiryChecked] = useState(false);

  const resetValues = useCallback(() => {
    reset(orgPreferences);
    setPasswordlessChecked(isPasswordlessRequired(orgPreferences));
    setLicenseExpiryChecked(isLicenseExpiryAlertsOn(orgPreferences));
  }, [orgPreferences, reset]);

  /**
   * Restores all organization preferences back to the default preferences
   * @param {array} options the preferences to restore
   */
  const restoreDefault = (options) => {
    resetValues();
    for (const option of options) {
      setValue(option, defaultPreferences[option], { shouldDirty: true });
      clearErrors(option);
    }
  };

  const isAddingAuthRequired = (field, isAdding) => {
    const orgPrefsIncludesField = orgPreferences.authorizations_required?.includes(field.toUpperCase());
    return isAdding && !orgPrefsIncludesField;
  };
  
  const updatePreferences = async (options) => {
    setSubmitting(true);
    const encodedOptions = encodePreferences(options);
    
    try {
      await organizationService.updatePreferences(encodedOptions);  
      await fetchPreferences(true);
      dispatch(alertActions.send(t("success.updatePreferences")));
      if (isAddingAuthRequired("passwordless", passwordlessChecked) && auth.loginMethod !== "passwordless") {
        const result = await memberService.fetchMembershipByAccountId(auth.user.id);
        dispatch(memberActions.setMemberships(result.data));
        dispatch(memberActions.removeCurrentMembership());
        history.replace(appRoutes.private.memberships);
        return;
      }
    } catch (e) {
      dispatch(alertActions.send(t("error.failedPreferences"), "error"));
      console.warn("Warning, failed to update organization preferences", e);
    }
    setSubmitting(false);
  };

  const onSubmit = (values) => {
    if(!submitting)
      updatePreferences({...values});
  };

  useEffect(() => {
    async function getPreferences() {
      await fetchPreferences();
    }
    getPreferences();
  }, [fetchPreferences]);

  useEffect(() => {
    if(!orgPreferences)
      return;

    resetValues();
  }, [orgPreferences, reset, resetValues]);
  
  const failedFetchView = () => {
    return <Grid container justifyContent="space-evenly" className={classes.container} >
      <Grid container className={classes.errorMsgWrap}>
        <ErrorIcon color="primary" className={classes.errorIcon} />
        <Typography className={classes.itemDescription} variant="h5">{t("label.error")}</Typography> 
        <Typography className={classes.itemDescription} variant="body1">{t("error.errorLoadingPreferences")}</Typography> 
      </Grid>
    </Grid>;
  };

  const loadingView = () => {
    return (
      <div data-testid="loading-system-settings-view" className={classes.container}>
        <div>
          <Skeleton animation="wave" width={300} height={60} />
          <Skeleton animation="wave" width={500} />
        </div>
        {[...Array(1).keys()].map((i) => {
          return <div key={i}>
            <Divider className={classes.divider} variant="middle" />
            <Grid container className={classes.settingsItemWrap}>
              <Grid md={4} sm={12} xs={12} item className={classes.itemDescriptionWrap}>
                <Skeleton animation="wave" width={210} height={60} />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" width={125} height={50} />
              </Grid>
              <Grid md={7} sm={12} xs={12} item className={classes.itemDetailsBox}> 
                <Grid item xs={12} className={classes.itemInputWrap}>
                  <Skeleton animation="wave" width={210} height={60} />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Grid>
              </Grid>
            </Grid>
          </div>;
        })}
      </div>);
  };

  const onChangeTimeUnit = e => setValue("default_key_time_unit", e.target.value, { shouldDirty: true });
  const onChangeTime = e => setValue("default_key_time", e.target.value, { shouldDirty: true });

  const onChangeCheckPasswordless = e => {
    setValue("authorizations_required", e.target.checked ? "passwordless" : "", { shouldDirty: true } );
    setPasswordlessChecked(e.target.checked);
  };

  const onChangeCheckLicenseExpiry = value => {
    setValue("license_expiry_alerts", value, { shouldDirty: true } );
    setLicenseExpiryChecked(value);
  };

  return <div className={classes.lsyBackground}>
    {orgPreferences ? 
      Object.keys(orgPreferences).length > 0 ? 
        <Grid container justifyContent="space-evenly" className={classes.container} >
          <FormControl variant="standard">
            <Grid xs={12} item className={classes.header}>
              <Grid xs={12} item>
                <Typography variant="h6">{t("features.systemSettings")}</Typography>
                <Typography className={classes.heading} variant="subtitle2">{t("label.systemSettingsSubtitle")}</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} variant="middle" />
            <Grid container className={classes.settingsItemWrap}>
              <Grid md={5} sm={12} xs={12} item>
                <Grid xs={12} item>
                  <Typography variant="body1"><strong>{t("label.defaultKeyTime")}</strong></Typography>
                  <Typography className={classes.itemDescription} variant="body1">{t("label.defaultKeyTimeSubtitle")}</Typography>
                  <Button data-testid="restore-default-btn" disabled={orgPreferences ? false : true} onClick={() => restoreDefault(["default_key_time", "default_key_time_unit"])} className={classes.restoreBtn}>{t("button.restoreDefault")}</Button>
                </Grid>
              </Grid>
              <Grid md={6} sm={12} xs={12} item className={classes.itemDetailsBox}> 
                <Grid item xs={12} className={classes.itemInputWrap}>
                  <FormLabel className={classes.itemDetailsHeader} id="default_key_time_label">{t("label.units")}</FormLabel>
                  <Controller
                    control={control}
                    name="default_key_time_unit"
                    defaultValue={orgPreferences.default_key_time_unit}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <RadioGroup
                        {...field}
                        data-testid="key-time-unit"
                        row
                        id="default_key_time_unit"
                        aria-labelledby="default_key_time_unit"
                        name="default_key_time_unit"
                        onChange={onChangeTimeUnit}
                      >
                        <FormControlLabel value={keyTimeUnits.HOURS} control={<Radio />} label={t("label.timeUnits.hours")} />
                        <FormControlLabel value={keyTimeUnits.DAYS} control={<Radio />} label={t("label.timeUnits.days")} />
                        <FormControlLabel value={keyTimeUnits.MONTHS} control={<Radio />} label={t("label.timeUnits.months")} />
                      </RadioGroup>
                    }
                  />
                  {errors["default_key_time_unit"] && <FormHelperText error>{errors["default_key_time_unit"] ? t("error.mustSelect") : ""}</FormHelperText>}
                </Grid>
                <Grid item xs={12} className={classes.itemInputWrap}>
                  <FormLabel className={classes.itemDetailsHeader} id="default_key_time_label">{t("label.keyLengthDuration")}</FormLabel>
                  <Controller
                    control={control}
                    name="default_key_time"
                    defaultValue={orgPreferences.default_key_time}
                    rules={{ 
                      required: true, 
                      min: 0,
                      pattern: /^[1-9][0-9]*$/,
                      validate: {
                        validEntry: () => {
                          const unit = getValues("default_key_time_unit");
                          const duration = getValues("default_key_time");
                          if(duration < 1) 
                            return false;
                          if(unit === keyTimeUnits.HOURS && duration > maxKeyDurations.MAX_HOURS) 
                            return false;
                          if(unit === keyTimeUnits.DAYS && duration > maxKeyDurations.MAX_DAYS) 
                            return false;
                          if(unit === keyTimeUnits.MONTHS && duration > maxKeyDurations.MAX_MONTHS) 
                            return false;
                        }
                      }
                    }}
                    render={({ field }) =>
                      <TextField
                        {...field}
                        onChange={onChangeTime}
                        className={classes.search}
                        size="small" 
                        id="default_key_time"
                        type="number"
                        variant="outlined"
                        placeholder={t("label.exampleDuration")}
                        aria-labelledby="default_key_time_label"
                        InputProps={{ inputProps: { "min": 1, "data-testid": "key-time" } }}
                        error={!isEmpty(errors["default_key_time"])}
                      />
                    }
                  />
                  {!isEmpty(errors["default_key_time"]) && <FormHelperText error>{errors["default_key_time"] ? t("error.invalidEntry") : ""}</FormHelperText>}
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Grid container alignItems="center">
                  <Grid item xs={8} md={6}>
                    <Typography variant="body1"><strong>{t("label.authsRequired")}</strong></Typography>
                    <Typography className={classes.itemDescription} variant="body1">{t("label.authsRequiredDesc")}</Typography>
                  </Grid>
                  <Grid item xs={4} md={6}>
                    <Controller
                      control={control}
                      name="authorizations_required"
                      defaultValue={passwordlessChecked}
                      render={({ field }) =>
                        <FormControlLabel
                          {...field}
                          label="Passwordless"
                          control={<Checkbox checked={passwordlessChecked} onChange={onChangeCheckPasswordless}/>}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Grid container alignItems="center" data-testid="licenseExpireAlertSection">
                  <Grid item xs={8} md={6}>
                    <Typography variant="body1"><strong>{t("label.licenseExpireAlert")}</strong></Typography>
                    {licenseExpiryChecked ?
                      <Typography className={classes.itemDescription} variant="body1">{t("description.disableLicenseExpiryAlert")}</Typography> :
                      <Typography className={classes.itemDescription} variant="body1">{t("description.enableLicenseExpiryAlert")}</Typography>
                    }
                  </Grid>
                  <Grid item xs={4} md={6}>
                    <ToggleSwitch
                      onColor={theme.lsyPalette.primary.mainDark}
                      checked={licenseExpiryChecked || false}
                      handleChange={onChangeCheckLicenseExpiry}
                      className={classes.toggleSwitch}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 
          </FormControl>
        </Grid> : loadingView() : failedFetchView()
    }
    {!isEmpty(dirtyFields) && <div data-testid="action-buttons-ss" className={classes.actionBtnsWrap}>
      <Button data-testid="cancel-btn-ss" onClick={resetValues} className={classes.actionBtn} variant="outlined">{t("button.cancelChanges")}</Button>
      <Button data-testid="save-btn-ss" disabled={isEmpty(dirtyFields) || !isValid} onClick={handleSubmit(onSubmit)} color="secondary" className={classes.actionBtn} variant="contained">
        { submitting ? (<LoadingPlaceHolder height={6} />) : t("button.saveChanges")}
      </Button>
    </div>}
  </div>;
};

SystemSettings.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string
};

export default SystemSettings;
