import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { BroadcastChannel } from "broadcast-channel";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { modalActions } from "_actions";

import { Typography } from "@mui/material";

import CustomModal from "_components/Modal/CustomModal";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Modal/sessionTimeoutModalStyle";

const useStyles = makeStyles()(styles);

const COUNTDOWN_INITIAL_STATE = 60;

const SessionTimeoutModal = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const idleSessionTimeoutChannel = useMemo(() => new BroadcastChannel("sessionTimeout"), []);
  const { showModal, setShowModal, logout, continueSession, totalCountdownTime, timeoutOrigin, membership, showOrganization } = props;
  const [ countdown, setCountdown ] = useState(totalCountdownTime || COUNTDOWN_INITIAL_STATE);
  const [ organization, setOrganization ] = useState("");
  let interval = useRef();
  const { t } = useTranslation("auth");
  
  useEffect(() => {
    if (showModal)
      idleSessionTimeoutChannel.postMessage({ type: "IDLE", org: timeoutOrigin });

  }, [showModal, idleSessionTimeoutChannel, timeoutOrigin]);

  const onLogout = useCallback(() => {
    const logoutMessage = <div>
      {t("sessionTimeout.loggedOutMessage")}
      {showOrganization ? 
        <Typography variant="caption" className={classes.description}>
          {isEmpty(organization) ? timeoutOrigin : organization}
        </Typography> :
        null
      }
    </div>;

    clearInterval(interval.current);
    logout();
    idleSessionTimeoutChannel.postMessage({ type: "LOGOUT" });
    idleSessionTimeoutChannel.close();
    dispatch(modalActions.showModal({
      modalType: "alert",
      modalProps: {
        title: t("sessionTimeout.title"),
        message: logoutMessage
      }
    }));
  }, [idleSessionTimeoutChannel, logout, organization, timeoutOrigin, showOrganization, dispatch, t, classes.description]);

  const stayLoggedIn = useCallback(() => {
    clearInterval(interval.current);
    continueSession();
    idleSessionTimeoutChannel.postMessage({ type: "CONTINUE_SESSION" });
  }, [continueSession, idleSessionTimeoutChannel]);

  const broadcastHandler = useCallback(event => {
    switch (event.type)  {
      case "LOGOUT":
        onLogout();
        break;
      case "CONTINUE_SESSION":
        if (showModal) {
          stayLoggedIn();
        }
        break;
      case "IDLE":
        if (!showModal) {
          setShowModal(true);
        }
        if (isEmpty(organization)) {
          setOrganization(isEmpty(event.org) ? membership?.tenant_name || "" : event.org);
        }
        break;
    }
  }, [showModal, setShowModal, membership, organization, stayLoggedIn, onLogout]);

  useEffect(() => {
    idleSessionTimeoutChannel.addEventListener("message", broadcastHandler);

    if (showModal) {
      interval.current = setInterval(() => {
        const count = countdown - 1;
        if (count > 0) {
          setCountdown(count);
        }
        else {
          onLogout();
        }
      }, 1000);
    }
    else {
      clearInterval(interval.current);
      setCountdown(COUNTDOWN_INITIAL_STATE);
    }

    return () => {
      clearInterval(interval.current);
      idleSessionTimeoutChannel.removeEventListener("message", broadcastHandler);
    };
  }, [showModal, onLogout, countdown, idleSessionTimeoutChannel, organization, broadcastHandler, dispatch, t]);

  const getDescription = () => {
    return <p data-testid="session-timeout-description">
      {t("sessionTimeout.sessionInactivityMessage")}<br/>
      {t("sessionTimeout.logoutCountdownMessage", { countdown: countdown })}
    </p>;
  };

  return (
    <CustomModal
      open={showModal}
      setOpen={setShowModal}
      handleSubmit={stayLoggedIn}
      type="confirm"
      confirm={t("sessionTimeout.stayLoggeInButton")}
      cancel={t("sessionTimeout.logoutButton")}
      cancelButtonStyle={classes.button}
      confirmButtonStyle={classes.button}
      handleCancel={onLogout}
      title={t("sessionTimeout.title")}
      description={getDescription()}
      disableBackdropClick={true}
      modalStyle={classes.modal}
    />
  );
};
  
SessionTimeoutModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  continueSession: PropTypes.func.isRequired,
  totalCountdownTime: PropTypes.number,
  membership: PropTypes.any,
  timeoutOrigin: PropTypes.string,
  showOrganization: PropTypes.bool
};

export default SessionTimeoutModal;