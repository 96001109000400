import { useContext, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { tagService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// @mui/material components
import Card from "@mui/material/Card";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/tagsCard2Style.js";
import { SlideMenuContext } from "_components/AnimatedMenu/SlideMenu.js";
import RecentlyUpdated from "_components/Helper/RecentlyUpdated";

//icons
import LockIcons from "assets/teleporte/LockIcons";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const useStyles = makeStyles()(styles);
const invalidTypes = Object.freeze(["MX5", "OTHER"]);

function TagsCard2(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { data } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const ctx = useContext(SlideMenuContext);

  const deleteTag = async (data, fetchWidgetData) => {
    try {
      await tagService.deleteTag({ data: data.id });
      dispatch(alertActions.send(t("success.deleteTag")));
      setTimeout(() => {
        fetchWidgetData();
      }, [1000]);
      ctx.closeMenu();
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteTag"), "error"));
    }
  };

  const createSidebar = () => {
    ctx.toggleMenu({
      menuType: "tagShow",
      data: data,
      history: props.history,
      createModal: props.createModal,
      fetchWidgetData: props.fetchWidgetData,
      deleteTag: deleteTag,
      ability: ability
    });
  };

  return (
    <Card className={classes.card}>
      <div className={classes.divBar} />
      <RecentlyUpdated updatedAt={data.updated_at}/>
      <p className={classes.tagName}>
        <span
          onClick={createSidebar}
          className={classes.name}
        >{data.name}
        </span>
      </p>
      {data.description ?
        <p className={classes.description}>
          <span>
            {data.description}
          </span>
        </p> : <div className={classes.placeholderDiv} />}
      <div className={classes.iconDiv}>
        {ability.can("read", "locks") && data.hardware_count ?
          Object.keys(data.hardware_count).map((type) => {
            let count = data.hardware_count[type] || 0;
            if (type === "AX5") {
              count += data.hardware_count["MX5"] || 0;
            }
            if (!invalidTypes.includes(type) && count > 0) {
              return <Fragment key={type}>
                <LockIcons className={classes.icon} type={type} height="20px" width="20px" />
                <span className={classes.count}>{count}</span>
              </Fragment>;
            } else {
              return null;
            }
          }) : null}
        {ability.can("read", "users") && data.user_count > 0 ?
          <Fragment>
            <AccountCircleOutlinedIcon className={classes.personIcon} />
            <span className={classes.count}>{data.user_count}</span>
          </Fragment> : null}
      </div>
    </Card>
  );
}

TagsCard2.propTypes = {
  history: PropTypes.object,
  index: PropTypes.number,
  org: PropTypes.string,
  data: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func
};

export default TagsCard2;