import { useReducer, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import moment from "moment";
import { capitalize, isEmpty, isEqual } from "lodash";
import queryString from "query-string";
import { genericReducer } from "_reducers/general.reducer";

import { userDevicesService } from "_services/lockstasy/userDevices.service";
import {
  getFilterVars,
  compactObj,
  formatDateAsLTS,
  formatDateAsMMMDDYYYY,
  formatDateAsYYYYMMDD,
  formatDateToAPI,
  userDeviceHelper
} from "_helpers";

import {
  Chip,
  Divider,
  useMediaQuery,
  Skeleton,
  TablePagination,
  Typography
} from "@mui/material";

import DateTimeRange from "_components/Date/DateTimeRange";
import Placeholder from "_components/Helper/Placeholder";
import LsyRouter from "_components/Navigation/LsyRouter";

import { KeyboardBackspaceRounded, List } from "@mui/icons-material";
import SwapIcon from "assets/teleporte/SwapIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/UserDevicesStyle.js";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles()(styles);

const defaultFilterState = {
  start_date: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
  end_date: moment(),
  viewing_latest: "true"
};

function DeviceHistoryWidget(props) {
  const { location, history, deviceId, membershipId } = props;
  const { search } = location;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const isDownSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();

  const getInitialState = () => {
    let filterVars = getFilterVars(defaultFilterState, search);
    if (filterVars.start_date) {
      filterVars.start_date = moment(filterVars.start_date);
    }
    if (filterVars.end_date) {
      filterVars.end_date = moment(filterVars.end_date);
    }
    if (filterVars.viewing_latest) {
      filterVars.viewing_latest = filterVars.viewing_latest === "true";
    }

    if (Object.keys(filterVars).length > 0) {
      const newState = { ...defaultFilterState, ...filterVars };
      return newState;
    } else {
      return defaultFilterState;
    }
  };
  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      data: {},
      userName: "",
      deviceName: "",
      filterVariables: initialState,
      totalData: 0,
      rowsPerPage: 10,
      currentPage: 1,
      loading: false
    });

  const setLoading = loading => setState({ loading });
  const setFilterVariables = filterVariables => setState({ filterVariables: {...state.filterVariables, ...filterVariables}, currentPage: 1 });
  const setDateRange = dateRange => setFilterVariables({ start_date: dateRange[0], end_date: dateRange[1] });
  const setViewingLatest = viewing_latest => setFilterVariables({ viewing_latest });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const options = {
        page: state.currentPage,
        page_size: state.rowsPerPage,
        start_date: formatDateToAPI(moment(state.filterVariables.start_date).startOf("day")),
        end_date: formatDateToAPI(moment(state.filterVariables.end_date).endOf("day")),
        sort_by: state.filterVariables.viewing_latest ? "created_at, desc" : "created_at, asc"
      };
      const result = await userDevicesService.fetchDeviceHistory({
        id: membershipId, 
        deviceId: deviceId,
        options: options
      });

      let obj = {};
      let userName = "";
      let device = "";

      result.data.forEach((item) => {
        const formattedCreatedAt = formatDateAsMMMDDYYYY(item.created_at);
        if (!obj[formattedCreatedAt]) {
          obj[formattedCreatedAt] = [];
        }
        obj[formattedCreatedAt].push(item);

        if (!userName) {
          userName = `${item.user.name} (${item.user.email})`;
        }
        if (!device) {
          const platformName = item.platform_name.startsWith("i") ? item.platform_name : capitalize(item.platform_name);
          device = `${platformName} ${item.platform_model}`;
        }
      });

      setState({ 
        data: obj,
        ...isEmpty(state.userName) ? {userName: userName} : {},
        ...isEmpty(state.deviceName) ? {deviceName: device} : {},
        totalData: result.meta.pagination.total
      });
    } catch (e) {
      console.warn(e);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipId, deviceId, state.rowsPerPage, state.currentPage, state.filterVariables]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateUrlQuery = useCallback((newState) => {
    if (!isEqual(newState, defaultFilterState)) {
      if (newState?.start_date)
        newState.start_date = formatDateAsYYYYMMDD(newState.start_date);
      if (newState?.end_date)
        newState.end_date = formatDateAsYYYYMMDD(newState.end_date);

      const filterVar = compactObj(newState);
      history.replace(`/users/${membershipId}/devices/${deviceId}/device_history?${queryString.stringify(filterVar)}`);
    } else {
      history.replace(`/users/${membershipId}/devices/${deviceId}/device_history`);
    }
  }, [deviceId, history, membershipId]);

  useCustomCompareEffect(() => {
    updateUrlQuery(state.filterVariables);
  }, [state.filterVariables], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleChangePage = (event, newPage) => {
    setState({currentPage: newPage + 1});
  };

  const handleRowChange = (event) => {
    setState({
      rowsPerPage: parseInt(event.target.value, 10),
      currentPage: 1
    });
  };

  const swapSortOrder = () => {
    setViewingLatest(!state.filterVariables.viewing_latest);
  };

  const renderLoading = () => {
    const loadingLine = () => Array.from({length: 3}).map((_, i) => {
      return <div key={`skeleton-${i}`} className={classes.historyContainer}>
        <div className={classes.sentence}>
          <div className={cx([classes.value, classes.label])}>
            <Skeleton variant="rectangular" className={classes.skeleton}/>
          </div>
        </div>
      </div>;
    });

    return <div className={classes.dateContainer}>
      <div className={classes.marginBottom}>
        <Skeleton variant="rectangular" className={classes.skeleton}/>
      </div>
      {Array.from({length: 3}).map((_, i) => {
        return (
          <div key={`loadingLine-${i}`} className={classes.historyContainer}>
            {loadingLine()}
          </div>
        );
      })}
    </div>;
  };

  const renderField = (label, value) => {
    return <div className={classes.sentence}>
      <div className={classes.label}>{`${label}:`}</div>
      <div className={classes.value}>{value}</div>
    </div>;
  };

  const renderHistoryTable = () => {
    return isEmpty(state.data) ?
      <Placeholder message={t("fallbacks.noData")} icon={<List/>} /> :
      <div data-testid="deviceContainer">
        {Object.entries(state.data).map((group) => {
          return (
            <div className={classes.dateContainer} key={group[0]}>
              <div className={classes.marginBottom}><b>{group[0]}</b></div>
              {group[1].map((v) => {
                return (
                  <div key={v.created_at} className={classes.historyContainer}>
                    <div className={classes.sentence}>
                      <div className={cx([classes.value, classes.label])}>{formatDateAsLTS(v.created_at)}</div>
                    </div>
                    {renderField(t("label.platformVersion"), v.platform_version)}
                    {renderField(t("label.teleporteVersion"), v.version)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>;
  };

  return (
    <div>
      <LsyRouter className={classes.backContainer} page="user_devices" id={membershipId}>
        <KeyboardBackspaceRounded className={classes.icon}/>
        <span>{t("button.backToUserDevices")}</span>
      </LsyRouter>
      <Typography variant="subtitle1"><b>{`${t("label.loginHistoryFor")} ${state.deviceName}`}</b></Typography>
      <Typography variant="caption" className={classes.titleDescription}>
        {state.userName}
      </Typography>
      <div className={cx(classes.sentence, classes.flex, classes.chipContainer)}>
        <div className={classes.label}>{t("label.device")}: </div>
        <div>{state.deviceName}</div>
        {userDeviceHelper.getDeviceIconType(state.deviceName, classes)}
      </div>
      <div className={classes.sentence}>
        <div className={classes.label}>{t("label.teleporteId")}: </div>
        <div>{deviceId}</div>
      </div>
      <Divider />
      <div className={classes.container}>
        <div className={cx(classes.chipContainer, classes.flex)}>
          <Chip
            data-testid="dateTimeRangeChip"
            className={classes.chip}
            label={
              <DateTimeRange 
                startDate={moment(state.filterVariables.start_date, "YYYY/MM/DD")}
                endDate={moment(state.filterVariables.end_date, "YYYY/MM/DD")}
                setDateRange={setDateRange}
                numberOfMonths={isDownSm ? 1 : 2}
                showTime={false}
                label={" "}
                format="YYYY/MM/DD"
                inputProps={{variant: "standard", disableUnderline: true, style: {fontSize: theme.lsyPalette.subtitle3, margin: 0, marginTop: "auto", marginBottom: "auto", fontWeight: "300", width: "165px"}}}
              />
            }
          />
          <Chip
            data-testid="chipSortDirection"
            onClick={swapSortOrder}
            className={classes.chip}
            label={
              <div className={classes.chipHeader}>
                <SwapIcon className={classes.chipIcon} pathClassName={classes.red} sortDirection={!state.filterVariables.viewing_latest ? "ASC" : "DESC"} width="20" height="20"/>
                <p className={classes.chipText}>
                  <span>{state.filterVariables.viewing_latest ? t("label.viewingLatest") : t("label.viewingOldest")}</span>
                </p>
              </div>}
          />
        </div>
        { state.loading ? renderLoading() : renderHistoryTable() }
        { !isEmpty(state.data) && 
          <TablePagination
            className={classes.pagination}
            data-testid="pagination"
            component="div"
            count={state.totalData || 0}
            rowsPerPage={state.rowsPerPage}
            page={state.currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 15, 25, 50]}
            onRowsPerPageChange={handleRowChange}
          />
        }
      </div>
    </div>
  );
}

DeviceHistoryWidget.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  membershipId: PropTypes.string,
  deviceId: PropTypes.string
};

export default DeviceHistoryWidget;