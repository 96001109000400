import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { lockLicenseConstantsById } from "_constants/lock.constants";
import { convertToHex, formatDateAsMMMDDYYYY, lsyRouter } from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { useLsyHistory } from "_hooks";
import appRoutes from "_routes";

import CustomModal from "_components/Modal/CustomModal";
import ErrorBoundary from "_components/ErrorBoundary";

import { AccountCircle, DescriptionOutlined } from "@mui/icons-material";
import GenericLock from "assets/teleporte/GenericLock";
import { LockIcons } from "assets/teleporte/Icons";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessHistoryStyle";

const useStyles = makeStyles()(styles);
const baseUrl = appRoutes.private.lockstasy;

function SummaryPopoverWidget(props) {
  const { type, data, open, setOpen, anchorEl } = props;

  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const { setValue, errors, control, clearErrors } = useForm();
  const history = useLsyHistory();
  const orgServerAlias = useSelector((state) => state?.memberships?.currentMembership.server_alias);

  const userGroupMap = {
    "administrator": t("form.administrator"),
    "user": t("widgetField.user"),
    "access.manager": t("form.accessManager"),
    "configuration.manager": t("form.configurationManager")
  };

  const getIdValue = () => {
    if (type === "user")
      return data.membership_id;
    else if (type === "lock" || type === "site")
      return data.id;
  };

  const handleSubmit = (e) => {
    if (e.metaKey) {
      window.open(`${baseUrl}/${orgServerAlias}${lsyRouter(type, getIdValue())}`, "_blank", "noreferrer");
    } else {
      history.push(lsyRouter(type, getIdValue()));
    }
  };

  const renderTitle = () => {
    if (type === "user")
      return data.name || `${data.first_name} ${data.last_name}`;
    else if (type === "lock")
      return data.full_identifier || getFormattedLockName(data);
    else if (type === "site")
      return data.name;
    else if (type === "lockNote")
      return t("label.lockNote");
  };

  const handleTagClick = (v) => {
    history.push(`/tags?search=${v.name}`);
  };

  const renderFormattedTags = (tags) => {
    return (
      <div className={classes.tagContainer}>
        {tags.map((v, i) => {
          return (
            <div className={classes.box} key={i} onClick={() => handleTagClick(v)}>
              {v.name}
            </div>
          );
        })}
      </div>
    );
  };

  const renderUserSummary = () => {
    return <>
      {data.email ? <div className={classes.maxSize}><b>{`${t("form.email")}: `}</b><span>{data.email}</span><br/></div> : null}
      {data.username ? <div className={classes.maxSize}><b>{`${t("auth:login.username")}: `}</b><span>{data.username}</span><br/></div> : null}
      {data.user_group ? <div className={classes.maxSize}><b>{`${t("label.role")}: `}</b><span>{userGroupMap[data.user_group]}</span><br/></div> : null}
      {data?.last_seen?.web ? <div className={classes.maxSize}><b>{`${t("label.lastSeen")}: `}</b><span>{formatDateAsMMMDDYYYY(data?.last_seen?.web)}</span><br/></div> : null}
      {data.deleted ? <div className={classes.maxSize}><span>{`(${t("label.deletedUser")})`}</span><br/></div> : null}
    </>;
  };

  const renderSiteSummary = () => {
    return <>
      {data.name ? <div className={classes.maxSize}><b>{`${t("form.name")}: `}</b><span>{data.name}</span><br/></div> : null}
    </>;
  };

  const renderLockSummary = () => {
    return <>
      {data.name ? <div className={classes.maxSize}><b>{`${t("form.name")}: `}</b><span>{data.name}</span><br/></div> : null}
      <div className={classes.maxSize}><b>{`${t("label.hardwareId")}: `}</b><span>{data.hardware_id || convertToHex(data.id, 4)}</span><br/></div>
      {data.hardware_type ? <div className={classes.maxSize}><b>{`${t("label.hardwareType")}: `}</b><span>{data.hardware_type}</span><br/></div> : null}
      {data.firmware_version_next ? <div className={classes.maxSize}><b>{`${t("label.nextFirmwareVersion")}: `}</b><span>{data.firmware_version_next}</span><br/></div> : null}
      {data.last_report?.last_known_timestamp ? <div className={classes.maxSize}><b>{`${t("widgetField.lastReport")}: `}</b><span>{formatDateAsMMMDDYYYY(data.last_report?.last_known_timestamp)}</span><br/></div> : null}
      {data?.site?.name ? <div className={classes.maxSize}><b>{`${t("widgetField.site")}: `}</b><span>{data?.site?.name}</span><br/></div> : null}
      {data?.tags ? <div className={classes.maxSize}><b>{`${t("features.tags")}: `}</b><span>{renderFormattedTags(data.tags)}</span></div> : null}
      {data?.lock_group ? <div className={classes.maxSize}><b>{`${t("label.lockGroup")}: `}</b><span>{data.tags.map(v => `${v.name} `)}</span><br/></div> : null}
      {data?.license ? <div className={classes.maxSize}><b>{`${t("widgetField.license")}: `}</b><span>{lockLicenseConstantsById(data?.license?.id)}</span><br/></div> : null}

    </>;
  };

  const renderSummary = () => {
    if (type === "user")
      return renderUserSummary();
    else if (type === "lock")
      return renderLockSummary();
    else if (type === "lockNote")
      return <>
        {data.message ? <div className={classes.maxSize}><span>{data.message}</span><br/></div> : null}
      </>;
    else if (type === "site")
      return renderSiteSummary();
  }; 

  const renderCalloutIcon = () => {
    if (type === "user") 
      return <AccountCircle className={classes.icon}/>;
    else if (type === "lock")
      return data?.hardware_type ?
        <LockIcons type={data.hardware_type} defaultStyle={classes.icon} height="25px" width="25px"/> :
        <GenericLock className={classes.icon} closedColor="black" height="25px" width="25px"/>;
    else if (type === "lockNote")
      return <DescriptionOutlined className={classes.icon}/>;
  };

  return (
    <ErrorBoundary>
      <CustomModal
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        anchorEl={anchorEl}
        type="customPopover"
        title={renderTitle()}
        calloutIcon={renderCalloutIcon()}
        confirm={t("label.viewProfile")}
        manualClose
        description={renderSummary()}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        confirmButton={!data.deleted && type !== "lockNote"}
        setValue={setValue}
        modalStyle={classes.modal}
        submit
      />
    </ErrorBoundary>
  );
}

SummaryPopoverWidget.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  anchorEl: PropTypes.object
};

export default SummaryPopoverWidget;