import { get } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchLicensesCount = async (options) => {
  return await get(`${twsWebUrl}/locks/licenses/counts`, options);
};

const fetchExpiredLicensesCount = async (options) => {
  return await get(`${twsWebUrl}/locks/licenses/expired`, options);
};

export const licenseService = {
  fetchExpiredLicensesCount,
  fetchLicensesCount
};