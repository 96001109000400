import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { alertActions } from "_actions";
import { authApps } from "_constants/thirdParty.constants";
import RegularButton from "_components/Button/RegularButton";

import { makeStyles } from "tss-react/mui";

import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/FileCopy";

const styles = (theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column"
  },
  hiddenContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  textMargin: {
    marginBottom: "8px",
    marginTop: "0px"
  },
  list: {
    fontSize: "14px",
    marginBottom: "8px",
    marginTop: "0px"
  },
  QRImg: {
    width: "160px",
    height: "160px",
    marginBottom: "8px"
  },
  code: {
    padding: "2px 4px",
    fontSize: "0.9em",
    color: theme.lsyPalette.primary.mainDark,
    backgroundColor: theme.lsyPalette.primary.light,
    borderRadius: "4px",
    margin: "0px 5px"
  },
  codeInstruction: {
    fontSize: "14px"
  },
  button: {
    textTransform: "none",
    margin: "0px"
  }
});
const useStyles = makeStyles()(styles);

const MfaSetupForm = ({ onChange, data, mfaEnabled, onSubmit, code }) => {
  const [showMfaCode, setShowMfaCode] = useState(false);
  const { classes, cx } = useStyles();
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();

  const appList = () => {
    return (
      <>
        <ul className={classes.list}>
          {authApps.map((val, key) => {
            return (
              <li key={key}>
                <a target="_blank" rel="noreferrer" href={val.appLink}>
                  {val.appName}
                </a>
              </li>
            );
          })}
        </ul>
        <p className={classes.list}>
          {t("mfa.authApps")}
        </p>
      </>
    );
  };

  const handleOnChange = e => {
    const regex = /^[0-9\b]{0,6}/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      onChange(e);
    }
  };

  const verificationForm = () => {
    return <Fragment>
      { !mfaEnabled ? (
        <p className={classes.textMargin}>
          <strong>{t("mfa.step3")}</strong>
        </p> ) : null }
      <TextField 
        label={t("mfa.verificationCode")} 
        variant="standard" 
        value={code}
        onChange={handleOnChange}
        onKeyDown={e => {
          if (e.key === "Enter" && onSubmit) {
            e.preventDefault();
            onSubmit();
          }
        }}/>
    </Fragment>;
  };

  const topText = () => {
    return !mfaEnabled ? (
      <>
        <p className={classes.textMargin}>
          {t("mfa.followSteps")}
        </p>
        <p className={classes.textMargin}>
          <strong>{t("mfa.step1")}</strong>
        </p>
      </>
    ) : (
      <p className={cx(classes.textMargin, classes.codeInstruction)}>
        {t("mfa.needApp")}
      </p>
    );
  };

  const bottomText = () => {
    return !mfaEnabled ? (
      <p className={classes.textMargin}>
        <strong>{t("mfa.step2")}{" "}{t("mfa.removePreviousProfiles")}</strong>
      </p>
    ) : null;
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(data.base32);
      dispatch(alertActions.send(t("mfa.clipboardNotif")));
    } catch (e) {
      dispatch(alertActions.send(t("mfa.clipboardNotifFail"), "error"));
    }
  };

  const openMfaCode = () => {
    setShowMfaCode(!showMfaCode);
  };

  const qrCode = () => {
    return (
      <div className={classes.hiddenContainer}>
        <img className={classes.QRImg} src={data.qrcode_image}></img>
        <Collapse in={showMfaCode}>
          <Typography className={classes.codeInstruction}>{t("mfa.enterCode", { ns: "auth" })}</Typography>
        </Collapse>
        <Collapse in={showMfaCode}>
          <code className={classes.code}>{data.base32}</code>
          <IconButton size={"small"} onClick={copyToClipboard}>
            <ContentCopyIcon fontSize={"small"}></ContentCopyIcon>
          </IconButton>
        </Collapse>
        <RegularButton data-testid="showMfaCodeButton" className={classes.button} onClick={openMfaCode}>
          {!showMfaCode ? t("mfa.cantScan") : t("mfa.canScan")}
        </RegularButton>
        <br></br>
      </div>
    );
  };

  return (
    <span className={classes.formContainer}>
      {topText()}
      {appList()}
      {bottomText()}
      {qrCode()}
      {verificationForm()}
    </span>
  );
};

MfaSetupForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  mfaEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func
};

MfaSetupForm.defaultProps = {
  mfaEnabled: false
};

export default MfaSetupForm;
