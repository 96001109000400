import { twsWebUrl } from "_services/helper";
import { get, post } from "_helpers/axios/gw.client";

const fetchAccessHistoryStats = async ({ membershipId, ...options }) => {
  return await get(`${twsWebUrl}/users/${membershipId}/access_history/stats`, options);
};

const fetchAccessHistory = async (membershipId, options) => {
  return await get(`${twsWebUrl}/users/${membershipId}/access_history`, options);
};

const fetchUserEvents = async (membershipId, options) => {
  return await get(`${twsWebUrl}/users/${membershipId}/events`, options);
};

const exportAccessHistory = async ({ membershipId, ...options }) => {
  return await post(`${twsWebUrl}/users/${membershipId}/access_history/export`, options.data);
};

const fetchLockAccessHistoryStats = async ({ lockId, ...options }) => {
  return await get(`${twsWebUrl}/locks/${lockId}/access_history/stats`, options);
};

const fetchLockAccessHistory = async ( lockId, options ) => {
  return await get(`${twsWebUrl}/locks/${lockId}/access_history`, options);
};

const fetchLockEvents = async ( lockId, options ) => {
  return await get(`${twsWebUrl}/locks/${lockId}/events`, options);
};

const exportLockAccessHistory = async ({ lockId, ...options }) => {
  return await post(`${twsWebUrl}/locks/${lockId}/access_history/export`, options.data);
};

export const accessHistoryService = {
  fetchAccessHistoryStats,
  fetchAccessHistory,
  fetchUserEvents,
  exportAccessHistory,
  fetchLockAccessHistoryStats,
  fetchLockAccessHistory,
  fetchLockEvents,
  exportLockAccessHistory
};