import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/ax5Style";
const useStyles = makeStyles()(styles);

function AX5(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 672 672" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve">
      <path className={cx(classes.ax5, (props.pathClassName || ""))} d="M555.8,155.4l-113-90.1c-6.8-5.4-15.1-8.3-23.8-8.3H239.5c-9.7,0-19,3.7-26,10.3l-99.4,92.9c-7.7,7.2-12.1,17.3-12.1,27.8
	v300.1c0,11.1,4.9,21.7,13.3,28.9l102.1,87.6c6.9,5.9,15.7,9.2,24.8,9.2h187.9c9.1,0,17.9-3.3,24.9-9.2l101.9-87.6
	c8.4-7.2,13.3-17.8,13.3-28.9v-303C570.2,173.6,564.9,162.6,555.8,155.4z M229.9,84.8C229.9,84.8,229.9,84.8,229.9,84.8
	c2.6-2.4,6.1-3.8,9.7-3.8h179.6c3.2,0,6.3,1.1,8.8,3.1l62.6,49.9H177.3L229.9,84.8z M439.3,586.6c-2.6,2.2-5.8,3.4-9.2,3.4H242.2
	c-3.4,0-6.6-1.2-9.2-3.4L175.3,537H497L439.3,586.6z M546.2,488.2c0,4.1-1.8,8-4.9,10.7l-28,24.1H159l-28-24.1
	c-3.1-2.7-4.9-6.6-4.9-10.7V188.1c0-3.9,1.6-7.6,4.5-10.3l31.8-29.8H508l32.8,26.2c3.4,2.7,5.3,6.7,5.3,11V488.2z M502.7,249.1
	l-82-81c-5.2-5.2-12.3-8.1-19.7-8.1h-65h-9.5h-54.5c-7.4,0-14.4,2.9-19.7,8.1l-82,81h0c-5.3,5.3-8.3,12.4-8.3,19.9v129.9
	c0,7.4,2.9,14.4,8.1,19.7l82.1,83.1c5.3,5.3,12.4,8.3,19.9,8.3h54.4h9.5h64.9c7.5,0,14.7-3,19.9-8.3l82.1-83.1
	c5.2-5.2,8.1-12.3,8.1-19.7V269C511,261.5,508,254.3,502.7,249.1L502.7,249.1z M497,398.9c0,3.7-1.5,7.2-4,9.8l-82.1,83.1
	c-2.6,2.7-6.2,4.2-10,4.2H336h-9.5h-54.4c-3.7,0-7.3-1.5-10-4.2l-82.1-83.1c-2.6-2.6-4-6.2-4-9.8V269c0-3.7,1.5-7.3,4.2-10l0,0
	l82-81c2.6-2.6,6.2-4,9.8-4h54.5h9.5h65c3.7,0,7.2,1.5,9.8,4l82,81c0,0,0,0,0,0c2.7,2.6,4.2,6.2,4.2,10V398.9z"/>
    </svg>
  );
}
AX5.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string

};
export default AX5;
