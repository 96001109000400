const passwordlessFormStyle = theme => ({
  spaceAround: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  topMargin: {
    marginTop: theme.spacing(2)
  },
  button: {
    textTransform: "none"
  },
  select: {
    fontSize: theme.typography.normalFont.fontSize,
    height: "34px"
  },
  spinner: {
    color: theme.lsyPalette.secondary.main
  },
  link: {
    "cursor": "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

export default passwordlessFormStyle;