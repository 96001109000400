import get from "lodash/get";

export const fetchStatus = (e) => {
  return (get(e, "response.status"));
};

export const fetchErrors = (e) => {
  return (get(e, "response.data.error.errors") || {});
};

export const fetchErrorSymbols = (e) => {
  const errors = fetchErrors(e);
  if (Object.keys(errors).length > 0) {
    return errors.map((el) => el.detail);
  } else {
    return [];
  }
};

// example: mapErrorToNCodes(e, { "password": { "weak": /weak/ }})
//  will return { "weak": ["weak", "weak"] } if the error message contains the regex}
export const mapErrorToCodes = (errors, fieldObject, options = {returnFirst: true}) => {
  var matches = {};

  if (!errors || Object.keys(errors).length === 0)
    return matches;

  Object.keys(fieldObject).forEach(el => {
    let value = get(errors, `${el}.msg`);
    if (value) {
      let pattern = fieldObject[el];
      Object.keys(fieldObject[el]).forEach(code => {
        let match = value.match(pattern[code]);
        if (match) {
          matches[code] = match;

          if (options.returnFirst) {
            return matches;
          }
        }
      });
    }
  });

  return matches;
};
