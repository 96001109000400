import { get } from "lodash";

// lock calls return very similarly formatted errors from LD
export const findLockErrorString = (e, options) => {
  if (!e)
    return null;

  if (e.isAxiosError) {

    if (e.response?.status === 499)
      return `Error updating ${options?.resourceName}.  Data not on tenant server.`;

    if (e.response?.status >= 400) {
      const errors = get(e.response.data, "error.errors", {});
      if (e.response.status === 422) {
        if (errors.semaphore_already_aquired) {
          return "Transaction already in progress, please clear the transaction first.";
        } else if (errors.valid_from || errors.valid_till) {
          return "Please recheck your start and end dates.";
        } else if (errors.upstream_code === 404) {
          return `Error updating ${options?.resourceName}.  Data not on tenant server.`;
        } else if (errors.length > 0 && errors[0].detail === "error.lock.missing.certificate") {
          return errors[0].detail;
        }
      } else if (e.response.status === 404 && options?.resourceName) {
        return `${options?.resourceName} not found`;
      }

      if (errors.tenant_server)
        return "Could not find tenant server";
    }
  }

  return null;
};
