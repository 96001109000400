const tagsCard2Style = (theme) => ({
  card: {
    marginTop: "15px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  cardContent: {
    "flexGrow": "1",
    "& p": {
      margin: "0px"
    },
    "&:last-child": {
      paddingBottom: "8px"
    }
  },
  tagName: {
    textAlign: "center",
    marginBottom: "8px"
  },
  name: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title1,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  description: {
    textAlign: "center",
    marginTop: "8px",
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.subtitle3,
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  defaultIcon: {
    height: "40px",
    width: "40px",
    marginLeft: "10px"
  },
  divBar: {
    width: "100%",
    height: "15px",
    backgroundColor: theme.lsyPalette.secondary.background
  },
  iconDiv: {
    display: "flex",
    minHeight: "20px",
    alignItems: "center",
    marginBottom: "5px"
  },
  icon: {
    marginLeft: "5px"
  },
  personIcon: {
    marginLeft: "5px",
    marginRight: "2px",
    width: "20px",
    height: "20px",
    color: "#88828b"
  },
  count: {
    fontSize: theme.lsyPalette.body1
  },
  placeholderDiv: {
    height: "34px"
  }
});

export default tagsCard2Style;
