import { useState, useCallback, useContext, useReducer, Fragment } from "react";
import PropTypes from "prop-types";
import RegularButton from "_components/Button/RegularButton";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { Can } from "@casl/react";
import { useCustomCompareEffect } from "use-custom-compare";

// @mui/material components
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";

// icons
import LocalOffer from "@mui/icons-material/LocalOffer";
import LoadingPlaceHolder from "_components/Loading";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";

//styles
import styles from "assets/jss/views/lockstasy/tagsStyle.js";

// helpers
import { isEqual } from "lodash";
import { userService } from "_services";
import { lockService } from "_services";
import { tagService } from "_services";
import { compactObj, isBlank, lsyRouter } from "_helpers";
import { hasLockLicense } from "_services/lockstasy/helper";

import moment from "moment";
import { SlideMenuContext } from "_components/AnimatedMenu/SlideMenu.js";
import Divider from "_components/Divider/Divider";
import GridItem from "components/Grid/GridItem";
import CustomModal from "_components/Modal/CustomModal";
import Badge from "_components/UI/Badge";

const useStyles = makeStyles()(styles);

export default function EditTags(props) {
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { handleSubmit, setValue, reset, formState: { errors }, control, clearErrors } = useForm();
  const menuCtx = useContext(SlideMenuContext);

  const [usersAscending, setUsersAscending] = useState(true);
  const [locksAscending, setLocksAscending] = useState(true);
  const [buttonOption, setButtonOption] = useState(true);
  const [lastModified, setLastModified] = useState(props.data.updated_at);
  const [editName, setEditName] = useState(false);
  const [tagName, setTagName] = useState(props.data.name);
  const { classes } = useStyles();

  const getDefaultValues = () => {
    var defaultValues = {
      search: ""
    };
    return defaultValues;
  };

  const [userState, setUserState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      page: 1,
      totalData: 0,
      filterActive: false,
      filterOpen: false,
      filterVariables: getDefaultValues(),
      additionOpen: false,
      loading: true,
      failedFetch: false
    }
  );
  const [lockState, setLockState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      page: 1,
      totalData: 0,
      filterActive: false,
      filterOpen: false,
      filterVariables: getDefaultValues(),
      additionOpen: false,
      loading: true,
      failedFetch: false
    }
  );

  const setUserFilterOpen = (filterOpen) => setUserState({ filterOpen });
  const setUserAdditionOpen = (additionOpen) => setUserState({ additionOpen });
  const setLockFilterOpen = (filterOpen) => setLockState({ filterOpen });
  const setLockAdditionOpen = (additionOpen) => setLockState({ additionOpen });
  const setUserPage = (page) => setUserState({ page });
  const setLockPage = (page) => setLockState({ page });
  const rowsPerPage = 10;
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage + 1);
  };
  const handleChangeLockPage = (event, newPage) => {
    setLockPage(newPage + 1);
  };

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, []);

  const fetchLocksWithInput = useCallback(async (inputValue) => {
    try {
      const result = await lockService.fetchLocks({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? {
        id: v.id,
        name: v.name,
        description: v.description,
        disabled: !hasLockLicense("tags", v) || v.semaphore_locked} : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
    }
  }, []);
  const getUserAdditionOptions = () => {
    var options = [
      {
        field: "membership_id",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("form.search"),
        async: true,
        asyncProps: {cacheOptions: true},
        promiseOptions: fetchUsersWithInput
      }
    ];
    return options;
  };
  const getTagEditOptions = () => {
    var options = [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      defaultValue: tagName,
      pattern: /^.{1,32}$/
    }];
    return options;
  };
  const getLockAdditionOptions = () => {
    var options = [
      {
        field: "lock_id",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("form.search"),
        async: true,
        asyncProps: {cacheOptions: true},
        promiseOptions: fetchLocksWithInput
      }
    ];
    return options;
  };
  const getFilterUserOptions = () => {
    var options = [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        autoFocus: true,
        defaultValue: userState.filterVariables.search
      }
    ];
    return options;
  };
  const getFilterLockOptions = () => {
    var options = [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        autoFocus: true,
        defaultValue: lockState.filterVariables.search
      }
    ];
    return options;
  };

  const reverseLocks = (obj) => {
    let reversedObj = [];
    Object.keys(obj).reverse().forEach(key => {
      reversedObj.push(obj[key]);
    });
    setLockState({data: reversedObj});
  };

  const onUserSubmit = (data) => {
    if (!isBlank(data)) {
      setUserState({ filterOpen: false, filterVariables: data, filterActive: true, page: 1, loading: true });
    } else {
      setUserState({ filterOpen:false });
    }
  };

  const onLockSubmit = (data) => {
    if (!isBlank(data)) {
      setLockState({ filterOpen: false, filterVariables: data, filterActive: true, page: 1, loading: true });
    } else {
      setLockState({ filterOpen:false });
    }
  };

  const fetchUsers = useCallback(async () => {
    try {
      const result = await userService.fetchUsers({ page: userState.page, page_size: 10, tag_ids: props.data.id , ...compactObj(userState.filterVariables)});
      let formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name}`, email: `${v.email}` } : null);
      setUserState({totalData: result.meta.pagination.total, data: formattedResult, loading: false});
    } catch (e) {
      setUserState({loading: false, failedFetch: true});
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, [userState.filterVariables, userState.page, props]);

  const fetchLocks = useCallback(async () => {
    try {
      const result = await lockService.fetchLocks({ page: lockState.page, page_size: 10, tag_ids: props.data.id, ...compactObj(lockState.filterVariables)});
      const formattedResult = result.data.map(v => v ? {
        id: v.id, name: `${v.name}`,
        description: `${v.description}`,
        semaphore_locked: v.semaphore_locked
      } : null);
      setLockState({totalData: result.meta.pagination.total, data: formattedResult, loading: false});
    } catch (e) {
      setLockState({loading: false, failedFetch: true});
      console.warn("Warning, failed to fetch locks with given input", e);
    }
  }, [lockState.filterVariables, lockState.page, props]);

  const addUserTag = async (data) => {
    try {
      await tagService.createUserTags({ tag_ids: props.data.id, membership_id: data.membership_id.id });
      dispatch(alertActions.send(t("success.addUser")));
      fetchUsers();
      setUserState({additionOpen: false});
      setLastModified(moment());
    } catch (e) {
      if (e.response.status === 422) {
        dispatch(alertActions.send(t("error.maxTagUsers", {maxTagUsers: 5}), "error"));
      } else {
        console.warn("Warning, failed to add user", e);
        dispatch(alertActions.send(t("error.addUser"), "error"));
      }
    }
  };
  const addLockTag = async (data) => {
    try {
      await tagService.createLockTags({ tag_ids: props.data.id, lock_id: data.lock_id.id });
      dispatch(alertActions.send(t("success.addLock")));
      fetchLocks();
      setLockState({additionOpen: false});
      setLastModified(moment());
    } catch (e) {
      if (e.response.status === 422) {
        dispatch(alertActions.send(t("error.maxTagLocks", {maxTagLocks: 5}), "error"));
      } else {
        console.warn("Warning, failed to add lock", e);
        dispatch(alertActions.send(t("error.addLock"), "error"));
      }
    }
  };
  const updateTag = async (data) => {
    if (data.name === tagName) {
      setEditName(!editName);
      dispatch(alertActions.send(t("error.updateTag"), "error"));
      return;
    }
    try {
      await tagService.updateTag({ tag_id: props.data.id, name: data.name });
      dispatch(alertActions.send(t("success.editTag")));
      setTagName(data.name);
      setEditName(!editName);
      setLastModified(moment());
    } catch (e) {
      if (e.response.status === 409) {
        dispatch(alertActions.send(t("error.tagNameTaken"), "error"));
      } else {
        console.warn("Warning, failed to update tag", e);
        dispatch(alertActions.send(t("error.updateTag"), "error"));
      }
    }
  };

  const deleteTagUser = async (data, membership_id) => {
    try {
      await tagService.deleteTagUser({ data: data.id, membership_id: membership_id });
      dispatch(alertActions.send(t("success.deleteUser")));
      fetchUsers();
      setLastModified(moment());
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteUser"), "error"));
    }
  };

  const deleteTagLock = async (data, lock_id) => {
    try {
      await tagService.deleteTagLock({ data: data.id, lock_id: lock_id });
      dispatch(alertActions.send(t("success.removeLock")));
      fetchLocks();
      setLastModified(moment());
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.removeLock"), "error"));
    }
  };

  const resetUserFilter = () => {
    if (!isEqual(getDefaultValues(), userState.filterVariables)) {
      reset(getDefaultValues());
      setUserState({
        filterVariables: getDefaultValues(),
        page: 1,
        filterActive: false
      });
    }
  };

  const resetLockFilter = () => {
    if (!isEqual(getDefaultValues, lockState.filterVariables)) {
      reset(getDefaultValues());
      setLockState({
        filterVariables: getDefaultValues(),
        page: 1,
        filterActive: false
      });
    }
  };

  useCustomCompareEffect(() => {
    fetchUsers();
  }, [props.data.id, userState.page, userState.filterVariables, fetchUsers], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    fetchLocks();
  }, [props.data.id, lockState.page, lockState.filterVariables, fetchLocks], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    setTimeout(() => {
      props.fetchWidgetData();
    }, [1000]);
  }, [lastModified], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const showLockLoadingSymbol = () => {
    return (<LoadingPlaceHolder title="Loading Lock Information" />);
  };

  const showUserLoadingSymbol = () => {
    return (<LoadingPlaceHolder title="Loading User Information" />);
  };

  const showTagBody = () => {
    return (
      <Fragment>
        <Grid className={classes.gridItem} container alignItems="center" spacing={2}>
          <GridItem className={classes.tagsItem} xs={1} md={1} container direction="row">
            <LocalOffer className={classes.tagsIcon}/>
          </GridItem>
          <GridItem className={classes.tagsItem} xs={8} md={10} container direction="row">
            <div className={classes.title}>{tagName}</div>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.editTag")}>
              <IconButton size="small" onClick={() => setEditName(!editName)}>
                <EditIcon className={classes.editIcon} fontSize={"small"}/>
              </IconButton>
            </Tooltip>
          </GridItem>
        </Grid>
        <Divider className={classes.divider} />
        <Grid className={classes.header} container justifyContent="center" alignItems="center" spacing={2}>
          <GridItem className={classes.tagsItem} xs={8} md={8} container direction="row">
            <div><b>{t("label.created")}: </b>{moment(props.data.created_at).format("lll")}</div>
            <div><b>{t("label.recentlyModified")}: </b>{moment(lastModified).format("lll")}</div>
          </GridItem>
        </Grid>
        <Divider className={classes.divider} />
        {buttonOption ? (
          <div>
            <div className={classes.modalButton}>
              <RegularButton
                onClick={() => setButtonOption(true)}
                className={classes.buttonActive}
                variant="text"
              >
                {t("features.locks")} ({lockState.totalData})
              </RegularButton>
              <RegularButton
                onClick={() => setButtonOption(false)}
                className={classes.button}
                variant="text"
              >
                {t("features.users")} ({userState.totalData})
              </RegularButton>
            </div>

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <GridItem className={classes.tagsItem} xs={6} md={7}>
                <div className={classes.tableTitle}>
                  <span>{t("form.name")}</span>
                  <IconButton size="small" onClick={() => {
                    setLocksAscending(!locksAscending);
                    reverseLocks(lockState.data);
                  }}
                  >
                    {locksAscending ?
                      (<KeyboardArrowDownIcon className={classes.arrowDownIcon}/>) :
                      (<KeyboardArrowDownIcon className={classes.arrowUpIcon}/>)}
                  </IconButton>
                </div>
              </GridItem>
              <GridItem className={classes.buttonGroup} xs={4} md={3} container direction="row">
                <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.filter")}>
                  <IconButton size="small" onClick={() => setLockFilterOpen(!lockState.filterOpen)}>
                    <Badge invisible={!lockState.filterActive}>
                      <FilterListIcon className={classes.icon} fontSize={"medium"}/>
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Can I="update" on="lock_collections" ability={props.ability}>
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.addLock")}>
                    <IconButton className={classes.addButton} size="small" onClick={() => setLockAdditionOpen(!lockState.additionOpen)}>
                      <AddIcon className={classes.icon}/>
                    </IconButton>
                  </Tooltip>
                </Can>
              </GridItem>
            </Grid>
            <Divider className={classes.divider} variant="middle"/>
            <Grid className={classes.itemNames} container justifyContent="center" spacing={2}>
              {!lockState.failedFetch ? lockState.data.map((tableEntry) => {
                const goToLock = () => {
                  menuCtx.closeMenu();
                  props.history.push(lsyRouter("lock", tableEntry.id));
                };

                return (
                  <Grid container justifyContent="center" spacing={2}
                    key={tableEntry.id}
                    value={tableEntry.name}
                  >
                    <GridItem className={classes.itemName} xs={8} md={7}>
                      <span
                        onClick={goToLock}
                        className={classes.linkName}
                      >{tableEntry.name}
                      </span>
                    </GridItem>
                    <GridItem container className={classes.removeIconWrap} xs={2} md={3} justifyContent="center">
                      <Can I="update" on="lock_collections" ability={props.ability}>
                        <Tooltip 
                          classes={{ tooltip: classes.tooltip }}
                          title={tableEntry.semaphore_locked ? t("error.lock.locked") : t("button.removeLock")}
                        >
                          <span>
                            <IconButton
                              disabled={tableEntry.semaphore_locked}
                              size="small"
                              onClick={() => {
                                props.createModal({
                                  type: "confirm",
                                  confirm: t("button.confirm"),
                                  confirmAction: () => deleteTagLock(props.data, tableEntry.id),
                                  title: t("confirmation.deleteTitle"),
                                  description: t("confirmation.removeLock"),
                                  submit: true
                                });
                              }}>
                              <RemoveIcon className={tableEntry.semaphore_locked ? null : classes.icon}/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Can>
                    </GridItem>
                  </Grid>
                );
              }) :
                <p className={classes.errorMessage}>{t("error.noRecords")}</p>
              }
            </Grid>
            <Divider className={classes.divider} variant="middle"/>
            <TablePagination
              component="div"
              count={lockState.totalData}
              rowsPerPage={rowsPerPage}
              page={lockState.page - 1}
              onPageChange={handleChangeLockPage}
              rowsPerPageOptions={[]}
            />
            <form onSubmit={handleSubmit(addLockTag)}>
              <CustomModal
                open={lockState.additionOpen}
                setOpen={setLockAdditionOpen}
                handleSubmit={handleSubmit(addLockTag)}
                type="formCreator"
                title={t("button.addLock")}
                formOptions={getLockAdditionOptions()}
                errors={errors}
                clearErrors={clearErrors}
                control={control}
                setValue={setValue}
                manualClose
                submit
                modalStyle={classes.modal}
              />
            </form>
            <form onSubmit={handleSubmit(onLockSubmit)}>
              <CustomModal
                open={lockState.filterOpen}
                setOpen={setLockFilterOpen}
                handleSubmit={handleSubmit(onLockSubmit)}
                handleCancel={resetLockFilter}
                type="formCreator"
                title={t("button.filter")}
                confirm={t("button.apply")}
                cancel={t("button.reset")}
                formOptions={getFilterLockOptions()}
                errors={errors}
                control={control}
                setValue={setValue}
                manualClose
                submit
                modalStyle={classes.modal}
              />
            </form>
          </div>
        ) : (
          <div>
            <div className={classes.modalButton}>
              <RegularButton
                onClick={() => setButtonOption(true)}
                className={classes.button}
                variant="text"
              >
                {t("features.locks")} ({lockState.totalData})
              </RegularButton>
              <RegularButton
                onClick={() => setButtonOption(false)}
                className={classes.buttonActive}
                variant="text"
              >
                {t("features.users")} ({userState.totalData})
              </RegularButton>
            </div>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <GridItem className={classes.tagsItem} xs={6} md={7}>
                <div className={classes.tableTitle}>
                  <div>
                    <span>{t("form.name")}</span>
                    <IconButton size="small" onClick={() => {
                      setUsersAscending(!usersAscending);
                      userState.data.reverse();
                    }}
                    >
                      {usersAscending ?
                        (<KeyboardArrowDownIcon className={classes.arrowDownIcon}/>) :
                        (<KeyboardArrowDownIcon className={classes.arrowUpIcon}/>)}
                    </IconButton>
                  </div>
                </div>
              </GridItem>
              <GridItem className={classes.buttonGroup} xs={4} md={3} container direction="row">
                <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.filter")}>
                  <IconButton size="small" onClick={() => setUserFilterOpen(!userState.filterOpen)}>
                    <Badge invisible={!userState.filterActive}>
                      <FilterListIcon fontSize={"medium"} className={classes.icon}/>
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Can I="update" on="lock_collections" ability={props.ability}>
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.addUser")}>
                    <IconButton size="small" onClick={() => setUserAdditionOpen(!userState.additionOpen)}>
                      <AddIcon className={classes.icon}/>
                    </IconButton>
                  </Tooltip>
                </Can>
              </GridItem>
            </Grid>
            <Divider className={classes.divider} variant="middle"/>
            <Grid className={classes.itemNames} container justifyContent="center" spacing={2}>
              {!userState.failedFetch ? userState.data.map((tableEntry) => {
                const goToUser = () => {
                  menuCtx.closeMenu();
                  props.history.push(lsyRouter("user", tableEntry.id));
                };

                return (
                  <Grid className={classes.row} container justifyContent="center" spacing={2}
                    key={tableEntry.id}
                    value={tableEntry.name}
                  >
                    <GridItem className={classes.itemName} xs={8} md={7}>
                      <span
                        onClick={goToUser}
                        className={classes.linkName}
                      >{tableEntry.name}
                      </span>
                    </GridItem>
                    <GridItem className={classes.removeIconWrap} xs={2} md={3} container justifyContent="center">
                      <Can I="update" on="lock_collections" ability={props.ability}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.removeUser")}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              props.createModal({
                                type: "confirm",
                                confirm: t("button.confirm"),
                                confirmAction: () => deleteTagUser(props.data, tableEntry.id),
                                title: t("confirmation.deleteTitle"),
                                description: t("confirmation.removeUser"),
                                submit: true
                              });
                            }}>
                            <RemoveIcon className={classes.icon}/>
                          </IconButton>
                        </Tooltip>
                      </Can>
                    </GridItem>
                  </Grid>
                );
              }) :
                <p className={classes.errorMessage}>{t("error.noRecords")}</p>
              }
            </Grid>
            <Divider className={classes.divider} variant="middle"/>
            <TablePagination
              component="div"
              count={userState.totalData}
              rowsPerPage={rowsPerPage}
              page={userState.page - 1}
              onPageChange={handleChangeUserPage}
              rowsPerPageOptions={[]}
            />
            <form onSubmit={handleSubmit(addUserTag)}>
              <CustomModal
                open={userState.additionOpen}
                setOpen={setUserAdditionOpen}
                handleSubmit={handleSubmit(addUserTag)}
                type="formCreator"
                title={t("button.addUser")}
                formOptions={getUserAdditionOptions()}
                errors={errors}
                clearErrors={clearErrors}
                control={control}
                setValue={setValue}
                manualClose
                submit
                modalStyle={classes.modal}
              />
            </form>
            <form onSubmit={handleSubmit(onUserSubmit)}>
              <CustomModal
                open={userState.filterOpen}
                setOpen={setUserFilterOpen}
                handleSubmit={handleSubmit(onUserSubmit)}
                handleCancel={resetUserFilter}
                type="formCreator"
                title={t("button.filter")}
                formOptions={getFilterUserOptions()}
                confirm={t("button.apply")}
                cancel={t("button.reset")}
                errors={errors}
                control={control}
                setValue={setValue}
                manualClose
                submit
                modalStyle={classes.modal}
              />
            </form>
          </div>
        )
        }
        <form onSubmit={handleSubmit(updateTag)}>
          <CustomModal
            open={editName}
            setOpen={setEditName}
            handleSubmit={handleSubmit(updateTag)}
            type="formCreator"
            title={t("button.editTag")}
            formOptions={getTagEditOptions()}
            errors={errors}
            clearErrors={clearErrors}
            control={control}
            setValue={setValue}
            manualClose
            submit
            modalStyle={classes.modal}
          />
        </form>
        <Can I="delete" on="tags" ability={props.ability}>
          <Tooltip title={t("button.deleteTag")} classes={{ tooltip: classes.tooltip }} >
            <IconButton
              onClick={() => {
                props.createModal({
                  type: "confirm",
                  confirm: t("button.confirm"),
                  confirmAction: () => props.deleteTag(props.data, props.fetchWidgetData),
                  title: t("confirmation.deleteTitle"),
                  description: t("confirmation.deleteTag"),
                  submit: true
                });
              }}
              className={classes.tagDelete}
              aria-label="delete"
              size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Can>
      </Fragment>
    );
  };

  return (
    <div className={classes.sidebar}>
      {lockState.loading ?
        <div className={classes.loading}>{showLockLoadingSymbol()}</div> :
        userState.loading ?
          <div className={classes.loading}>{showUserLoadingSymbol()}</div> :
          showTagBody()}
    </div>
  );
}

EditTags.propTypes = {
  data: PropTypes.shape({
    updated_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    lock_count: PropTypes.number.isRequired,
    user_count: PropTypes.number.isRequired
  }),
  history: PropTypes.object,
  createModal: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  deleteTag: PropTypes.func,
  ability: PropTypes.object
};