import axios from "axios";
import getKey from "lodash/get";
import { config } from "_configs/server-config";
import { handleResponse } from "_services/helper";
import twsResourceFactory from "_resources";
import { fetchErrors } from "_utils";
import { alertActions, modalActions } from "_actions";
import i18n from "i18n";

const TIMEOUT = 1 * 60 * 1000;

// for issuing a cancel request to stop mem leaks in case of movement between calls
const cancelToken = axios.CancelToken;

let store;

export const injectStore = _store => store = _store;

const api = axios.create({
  baseURL: config.s4SSOGwUrl,
  timeout: TIMEOUT,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json" 
  }
});

const setupAxiosInterceptors = (instance, onUnauthenticated) => {
  const onRequestSuccess = config => {
    const membership = sessionStorage.getItem("membershipId");
    if (membership) {
      config.headers["tws-account-id"] = localStorage.getItem("accountId");
      config.headers["tws-membership-id"] = membership;
      try {
        config.headers["tws-client-timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        console.warn(e);
      }
    }
    return config;
  };

  const onResponseSuccess = response => response;

  const onResponseError = err => {
    const status = err.status || getKey(err, "response.status", 400);

    try {
      const url = err.response.config.url;
      const method = err.response.config.method;

      if (
        (status === 401 && url === "sessions" && method === "post") ||
        (status === 401 && url.startsWith("identity/mfa"))
      ) {
        return Promise.reject(err);
      }
    } catch (e) {
      console.error("Error getting response URL", e);
    }

    if (status === 401) {
      if (!["/logout", "/login", "/login/passwordless"].includes(window.location.pathname)) {
        console.debug(`Received an unauthenticated code on ${window.location.pathname}.  Logging out.`);
        twsResourceFactory.flushResources();
        onUnauthenticated();
      }
    } else if (status === 403) {
      console.debug("You are unauthorized to perform this action");
    } else if (status === 422) {
      const errors = fetchErrors(err);
      const errorDetails = Array.isArray(errors) ? errors.map(error => error.detail) : [errors.detail];

      if (errorDetails.includes("ongoing.process.read.only.allowed")) {
        store.dispatch(alertActions.send(i18n.t("error.lock.locked"), "error"));
      }
    } else if (status === 488) {
      store.dispatch(modalActions.showModal({
        modalType: "alert",
        modalProps: {
          title: i18n.t("default:error.maintenanceMode"),
          message: i18n.t("default:error.maintenanceModeMessage")
        }
      }));
    } else if (status === 502) {
      store.dispatch(modalActions.showModal({
        modalType: "alert",
        modalProps: {
          title: i18n.t("default:error.outageTitle"),
          message: i18n.t("default:error.outageMessage", { newLine: <br/> })
        }
      }));
    }

    return Promise.reject(err);
  };

  instance.interceptors.request.use(onRequestSuccess);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);
};


/* requires atleast a URL */
const getBase = async (requestOptions,data) => {
  const cancelSource = cancelToken.source();
  if (typeof(requestOptions) === "string" ) {
    let options = {
      method: "GET", url: requestOptions, cancelToken: cancelSource.token
    };
    if (data) {
      return api({ ...options, params: data });
    }
    return api({ ...options});
  }

  if (requestOptions.cancelToken === undefined) {
    requestOptions.cancelToken = cancelSource.token;
  }

  return api(requestOptions);
};

const putBase = async (requestOptions, payload = {}) => {
  if (typeof(requestOptions) === "string" ) {
    return api({ method: "PUT", url: requestOptions, data: payload});
  }

  return api(requestOptions);
};

const postBase = async (requestOptions, payload = {}) => {
  if (typeof(requestOptions) === "string" ) {
    if ((typeof(payload) === "object") && (Object.keys(payload).length == 0)) {
      // POST requests hate empty {}
      return api({ method: "POST", url: requestOptions });
    } else {
      return api({ method: "POST", url: requestOptions, data: payload});
    }
  }

  return api(requestOptions);
};

const deleteBase = async (requestOptions, payload = {}) => {
  // gw issue with empty payloads
  if ((typeof(payload) === "object") && (Object.keys(payload).length === 0)) {
    payload = undefined;
  }

  if (typeof(requestOptions) === "string" ) {
    return api({ method: "DELETE", url: requestOptions, data: payload});
  }

  return api(requestOptions);
};

export const get = async (requestOptions, data) => {
  const res = await getBase(requestOptions, data);
  return handleResponse(res);
};

export const post = async (requestOptions, payload) => {
  const res = await postBase(requestOptions, payload);
  return handleResponse(res);
};

export const put = async (requestOptions, payload) => {
  const res = await putBase(requestOptions, payload);
  return handleResponse(res);
};

// avoiding a reserved word of delete
export const del = async requestOptions => {
  const res = await deleteBase(requestOptions);
  return handleResponse(res);
};

export const request = async requestOptions => {
  const { method, url, data } = requestOptions;
  const res = await api({ method, url, data });
  return handleResponse(res);
};

setupAxiosInterceptors(api, () => {
  window.location = "/logout";
});

export default api;
