const lockGroupsStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  lockGroupWidget: {
    marginTop: theme.spacing(2.5)
  },
  card: {
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  lockgroupCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    color: "green",
    padding: `0 ${theme.spacing(1)}`
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main,
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px"
    }
  },
  cardContent: {
    flexGrow: "1",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.5)
    }
  },
  lockgroupDelete: {
    marginRight: "25px",
    color: theme.lsyPalette.primary.main
  },
  divBar: {
    width: "100%", 
    height: "15px", 
    backgroundColor: theme.lsyPalette.secondary.background
  },
  additionIcon: {
    color: theme.lsyPalette.secondary.main
  },
  lockgroupDetailGrid: {
    paddingLeft: "10px"
  },
  lockgroupIconItem: {
    alignSelf: "flex-end"
  },
  lockgroupNameItem: {
    paddingLeft: "5px"
  },
  lockgroupName: {
    color: theme.lsyPalette.primary.main,
    paddingTop: "20px"
  },
  lockgroupDescription: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body2
  },
  settingsItem: {
    alignSelf: "flex-end",
    marginLeft: "auto"
  },
  editLockgroup: {
    color: theme.lsyPalette.primary.mainDark
  },
  lockgroupWidget: {
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContents: "center"
    }
  },
  icon: {
    color: theme.lsyPalette.secondary.main
  },
  lockgroupIcon: {
    marginLeft: "5px"
  },
  lockIcon:{
    color: theme.lsyPalette.primary.main
  },
  disabledColor: {
    color: theme.lsyPalette.primary.mainLight
  }
});

export default lockGroupsStyle;