const embeddedMapStyle = theme => ({
  noLocation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "360px",
    marginBottom: theme.spacing(1),
    color: theme.lsyPalette.subText,
    backgroundColor: theme.lsyPalette.secondary.background,
    fontWeight: "500"
  },
  iframe: {
    width: "100%",
    height: "360px",
    border: "0"
  },
  mapWrap: {
    filter: "grayscale(1)",
    marginBottom: theme.spacing(1)
  },
  requireLicense: {
    height: "310px"
  },
  title: {
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  }
});

export default embeddedMapStyle;