import { useState, cloneElement, forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "tss-react/mui";
import { Button, Typography } from "@mui/material";
import { modalActions } from "_actions";
import { useDispatch } from "react-redux";

import RegularButton from "_components/Button/RegularButton";

import { isBlank } from "_helpers";

const useStyles = makeStyles()(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    borderRadius: theme.shape.borderRadius
  },
  rightButton: {
    float: "right"
  },
  leftButton: {
    float: "left"
  },
  descriptionText: {
    ...theme.typography.body2,
    marginTop: "10px"
  },
  actionRow: {
    margin: "20px 0 35px 0"
  },
  warningText: {
    color: theme.lsyPalette.stdRed
  }
}));

const titleBar = (title) => {
  return (
    <Typography variant="h6" id="modal-title">{title}</Typography>
  );
};

const MainMessage = (message) => {
  const { classes } = useStyles();

  return (
    <div className={classes.descriptionText} id="modal-description">
      {message}
    </div>
  );
};

const dismiss = async(onClose, buttonHandler, selectedValue) => {
  let result;

  if (buttonHandler && (typeof(buttonHandler) === "function")) {
    if (selectedValue) {
      result = await buttonHandler(selectedValue);
    }
    else {
      result = await buttonHandler();
    }
  }

  if (onClose && (typeof(onClose) === "function")) {
    if (result !== false)
      onClose();
  }
};

// eslint-disable-next-line react/display-name
const AlertModal = forwardRef(({onClose, title, confirmText, message, modalStyle, ...props }, ref) => {
  const { classes } = useStyles();
  const { t } = useTranslation("default");

  if (!confirmText) {
    confirmText = t("button.ok");
  }

  return  (
    <div style={modalStyle} className={classes.paper} ref={ref}>
      {titleBar(title)}
      {MainMessage(message)}
      <div className={classes.actionRow}>
        <RegularButton onClick={() => dismiss(onClose, props.confirmHandler)} className={classes.rightButton} color="primary">{ confirmText }</RegularButton>
      </div>
    </div>
  );
});

AlertModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  modalStyle: PropTypes.object
};

// eslint-disable-next-line react/display-name
const ConfirmModal = ({ onClose, title, confirmText, confirmHandler, declineText, declineHandler, message, modalStyle, nextModal, selectedValue }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();

  if (!confirmText) {
    confirmText = t("button.ok");
  }

  if (!declineText) {
    declineText = t("button.cancel");
  }

  const buttonHandler = () => {
    if (nextModal) {
      nextModal.modalProps.selectedValue = selectedValue;
      dispatch(modalActions.hideModal());
      dispatch(modalActions.showModal({ modalProps: nextModal.modalProps, modalType: nextModal.type }));
    }
    else {
      dismiss(onClose, confirmHandler);
    }
  };

  return (
    <div style={modalStyle} className={classes.paper}>
      {titleBar(title)}
      {MainMessage(message)}
      <div className={classes.actionRow}>
        <RegularButton onClick={() => dismiss(onClose, declineHandler)} className={classes.leftButton}>{ declineText }</RegularButton>
        <RegularButton onClick={buttonHandler} className={classes.rightButton}>{ confirmText }</RegularButton>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func,
  declineHandler: PropTypes.func,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
  message: PropTypes.string,
  modalStyle: PropTypes.object,
  nextModal: PropTypes.object,
  selectedValue: PropTypes.object
};

// eslint-disable-next-line react/display-name
const CustomButtonModal = forwardRef(({ onClose, title, message, customButtonText, cancelButtonText, confirmPhrase, modalStyle, confirmHandler, customButtonHandler, FormComponent, alertConfirm }, ref) => {
  const { classes, cx } = useStyles();
  const [text, setText] = useState("");
  const { t } = useTranslation("default");

  const content = FormComponent ? <FormComponent onChange={(e) => setText(e.target.value)}/> : null;

  // should this allow confirmation immediately or does it need a phrase
  let canConfirm = true;

  if (!isBlank(confirmPhrase)) {
    if (text === confirmPhrase){
      canConfirm = true;
    } else{
      canConfirm = false;
    }
  }

  return (
    <div>
      <div style={modalStyle} className={classes.paper} ref={ref}>
        {titleBar(title)}
        {MainMessage(message)}
        {content}
        <div className={classes.actionRow}>
          <RegularButton
            disabled={!canConfirm}
            onClick={() => dismiss(onClose, customButtonHandler)}
            className={cx(classes.rightButton, { [classes.warningText]: alertConfirm })}
          >
            {customButtonText}
          </RegularButton>
          <RegularButton
            onClick={() => dismiss(onClose, confirmHandler)}
            className={classes.rightButton}
          >
            {cancelButtonText || t("button.cancel")}
          </RegularButton>
        </div>
      </div>
    </div>
  );
});

CustomButtonModal.defaultProps = {
  alertConfirm: false // whether it's text color is alert
};

CustomButtonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customButtonHandler: PropTypes.func,
  customButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  alertConfirm: PropTypes.bool,
  confirmPhrase: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  declineText: PropTypes.string,
  confirmText: PropTypes.string,
  modalStyle: PropTypes.object,
  confirmHandler: PropTypes.func,
  FormComponent: PropTypes.object
};

CustomButtonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customButtonHandler: PropTypes.func,
  customButtonText: PropTypes.string,
  customButtonStyle: PropTypes.object,
  alertConfirm: PropTypes.bool,
  confirmPhrase: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  declineText: PropTypes.string,
  confirmText: PropTypes.string,
  modalStyle: PropTypes.object,
  confirmHandler: PropTypes.func,
  FormComponent: PropTypes.object
};

const CustomSelectModal = ({ title, SelectComponent, customButtonText, customButtonHandler, modalStyle, confirmHandler, message, onClose, nextModal, isConfirm, confirmMessage, bodyMessage }) => {
  const { classes } = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);
  const bodyComponent = cloneElement(SelectComponent, {onChange: (_event, val) => setSelectedValue(val)});
  const dispatch = useDispatch();

  const buttonHandler = () => {
    if (isConfirm) {
      const confirmProps = {
        title: "Confirmation",
        message: bodyMessage + `to ${selectedValue.name}.  ` + confirmMessage,
        nextModal: nextModal,
        modalStyle: { width: "550px" },
        selectedValue: selectedValue
      };
      dispatch(modalActions.showModal({ modalProps: confirmProps, modalType: "confirm"}));
    }
    else {
      dismiss(onClose, customButtonHandler, selectedValue);
    }
  };

  return (
    <div>
      <div style={modalStyle} className={classes.paper}>
        {titleBar(title)}
        {MainMessage(message)}
        <div style={{marginTop: "10px"}}>
          {bodyComponent}
        </div>
        <div className={classes.actionRow}>
          <Button disableFocusRipple disableRipple onClick={() => dismiss(onClose, confirmHandler)} className={classes.leftButton} variant="text" color="primary">Close</Button>
          <Button disabled={selectedValue === null} disableFocusRipple disableRipple onClick={buttonHandler} className={classes.rightButton} variant="text" color="primary">{ customButtonText }</Button>
        </div>
      </div>
    </div>
  );
};

CustomSelectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customButtonHandler: PropTypes.func,
  customButtonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  SelectComponent: PropTypes.element,
  modalStyle: PropTypes.object,
  confirmHandler: PropTypes.func,
  nextModal: PropTypes.object,
  isConfirm: PropTypes.bool,
  confirmMessage: PropTypes.string,
  bodyMessage: PropTypes.string
};

const CustomFormModal = ({ title, FormComponent, customButtonText, customButtonHandler, modalStyle, confirmHandler, message, onClose, fields, extra}) => {
  const { classes } = useStyles();
  const obj = fields.reduce((o, key) => Object.assign(o, {[key]: null}), {});
  const [formValues, setFormValues] = useState(obj);
  const [formErrors, setFormErrors] = useState({});
  const bodyComponent = cloneElement(FormComponent, { formValues: formValues, setFormValues: setFormValues, extra, formErrors, setFormErrors });

  return (
    <div>
      <div style={modalStyle} className={classes.paper}>
        {titleBar(title)}
        {MainMessage(message)}
        <div style={{marginTop: "10px"}}>
          {bodyComponent}
        </div>
        <div className={classes.actionRow}>
          <RegularButton
            onClick={() => dismiss(onClose, confirmHandler)}
            className={classes.leftButton}
            variant="text"
          >
            Close
          </RegularButton>
          <RegularButton
            disabled={Object.values(formValues).includes(null) || Object.values(formErrors).length !== 0}
            onClick={() => dismiss(onClose, customButtonHandler, formValues)}
            className={classes.rightButton}
            variant="text"
          >
            { customButtonText }
          </RegularButton>
        </div>
      </div>
    </div>
  );
};

CustomFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customButtonHandler: PropTypes.func,
  customButtonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  FormComponent: PropTypes.element,
  modalStyle: PropTypes.object,
  confirmHandler: PropTypes.func,
  fields: PropTypes.array,
  extra: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

const CustomListModal = ({ title, ListComponent, confirmHandler, modalStyle, message, onClose, selectedValue }) => {
  const { classes } = useStyles();
  const bodyComponent = cloneElement(ListComponent, { selectedOrg: selectedValue });

  return (
    <div>
      <div style={modalStyle} className={classes.paper}>
        {titleBar(title)}
        {MainMessage(message)}
        <div style={{marginTop: "10px"}}>
          {bodyComponent}
        </div>
        <div className={classes.actionRow}>
          <Button disableFocusRipple disableRipple onClick={() => dismiss(onClose, confirmHandler)} className={classes.rightButton} variant="text" color="primary">Close</Button>
        </div>
      </div>
    </div>
  );
};

CustomListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  ListComponent: PropTypes.element,
  modalStyle: PropTypes.object,
  listData: PropTypes.array,
  selectedValue: PropTypes.object
};

const modalTypes = {
  alertModal: AlertModal,
  confirmModal: ConfirmModal,
  customModal: CustomButtonModal,
  customSelectModal: CustomSelectModal,
  customFormModal: CustomFormModal,
  customListModal: CustomListModal
};

export default modalTypes;