import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/rolesIconStyle";
const useStyles = makeStyles()(styles);

function RolesIcon(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 230 234" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve">
      <path className={cx(classes.rolesIcon, (props.pathClassName || ""))} d="M206,90.7c-3.9,0.6-5.6-1.5-6.8-4.7c-1.6-4.3-3.2-8.6-5.1-13.6c1.8-1.7,4.1-3.7,6.1-5.9c5.5-5.9,5.4-15.4-0.2-21.1
		c-4.7-4.8-9.4-9.6-14.1-14.3c-6.2-6.2-15.4-6.2-21.6,0c-2,2-3.5,4.5-4.7,6.1c-6-2.5-10.4-4.8-15.1-6.1c-3-0.9-3.6-2.4-3.4-5
		c0.1-1.5,0-3,0-4.6c0-7.4-4-13.9-11.2-15C121.2,5.2,112,4.9,103.3,6c-7.9,0.9-12.2,8-12.2,16.3c0,2.3,0,4.7,0,7
		c-6,2.5-11.7,5-18,7.6c-1.4-1.6-2.8-3.5-4.5-5.1C61,24.4,52.7,24.5,45.3,32c-4.5,4.5-9,9-13.4,13.6c-5.4,5.7-5.4,15.1,0,20.8
		c2.1,2.2,4.5,4,6.5,5.7c-1.8,4.3-3.9,7.5-4.4,11c-1.1,7-5.3,7.9-11.2,7.5c-6.9-0.4-13.2,4-14.5,11c-1.2,6.5-0.6,13.3-1.2,20
		c-1.2,12.4,7.7,21.1,19.1,19.6c3.9-0.6,5.6,1.5,6.8,4.7c1.6,4.3,3.2,8.6,5.3,14c-1.4,1.2-3.5,2.6-5.2,4.4c-7.1,7.5-6.9,16,0.2,23.3
		c4.4,4.4,8.7,8.9,13.1,13.3c6.2,6.2,15.4,6.2,21.6-0.1c2-2,3.5-4.5,5.2-6.7c3.1,2.1,5.2,3.1,7.3,4c2.2,1,4.5,2.1,6.8,2.6
		c3.4,0.7,4.3,2.5,3.9,5.7c-0.2,1.3,0,2.7,0,4.1c0,7.4,4,13.7,11.2,15c7.6,1.3,15.5,1,23.2,1.1c8.6,0.1,15.5-7.1,15.6-15.9
		c0-2.7,0-5.4,0-8c6-2.5,11.7-5,17.9-7.6c1.9,2.1,3.8,4.5,6,6.5c5.7,5.4,15.1,5.2,20.5-0.2c5-5,10-10,14.8-15.1
		c5.4-5.7,5.4-15.1,0-20.8c-2.1-2.2-4.5-4-6.5-5.7c1.8-4.3,3.9-7.5,4.4-11c1.1-7,5.3-7.9,11.2-7.5c6.9,0.4,13.2-4,14.5-11
		c1.2-6.5,0.6-13.3,1.2-20C226.3,97.9,217.4,89.1,206,90.7z M208.8,130.2c-2.8-0.1-5.7-0.1-8.5,0c-4.3,0.1-7.1,1.3-8.6,6.3
		c-2.1,6.9-5.2,13.5-8.4,20c-1.7,3.5-1.2,5.9,1.4,8.4c2.4,2.4,4.7,4.8,7.1,7.2c2.4,2.5,3,5.3,0.5,7.9c-4.7,4.7-9.6,9.2-14.4,13.8
		c-2.4,2.3-4.3,1.4-6.4-0.6c-3.6-3.5-7-8.7-11.2-9.4c-4-0.7-8.8,3.4-13.2,5.3c-3.6,1.5-7.2,3-11,4c-4.6,1.1-5.8,4.4-6,8.4
		c-0.2,3-0.2,6.1,0,9.1c0.3,3.5-1.2,4.9-4.5,5c-6.3,0-12.7,0.3-19-0.2c-1.6-0.1-4-2.7-4.4-4.5c-0.7-3-0.1-6.4-0.2-9.6
		c-0.2-4.1-1.4-6.8-6-8.3c-7-2.2-13.6-5.5-20.2-8.7c-3-1.5-5.3-1.1-7.5,1.1c-2.5,2.5-4.9,5-7.4,7.5c-2.4,2.4-5.2,3.2-7.8,0.7
		c-4.8-4.8-9.5-9.9-13.8-15.1c-0.9-1.1-0.6-4.7,0.4-5.4c10.7-7.4,11.6-15.9,4.2-26.3c-2-2.8-3-6.6-3.7-10.2
		c-0.9-5.1-4.3-6.2-8.5-6.3c-3-0.1-6-0.2-9,0c-3.2,0.3-4.6-1.2-4.7-4.2c-0.1-6.6-0.3-13.2,0.2-19.7c0.1-1.6,2.8-3.8,4.6-4.3
		c3-0.7,6.3-0.1,9.4-0.2c3.9-0.1,6.5-1.5,7.9-5.9c2.2-6.9,5-13.7,8.2-20.1c2-4,1.3-6.5-1.5-9.2c-2.3-2.2-4.5-4.5-6.7-6.8
		s-3-5.1-0.7-7.5c4.7-4.9,9.7-9.7,14.9-14c1.1-0.9,4.9-0.7,5.7,0.4c7.2,10.4,15.4,11.7,25.5,4.3c2.9-2.1,6.8-3.2,10.5-3.9
		c4.5-0.9,5.7-4,6-7.8c0.2-3.4,0.2-6.8,0-10.1c-0.2-3,1.2-4.4,3.9-4.5c6.7-0.1,13.3-0.3,20,0.2c1.5,0.1,3.6,2.7,4,4.4
		c0.7,3.1,0.1,6.4,0.2,9.6c0.2,4,1.4,6.9,6,8.3c6.8,2.1,13.4,5.1,19.8,8.4c3.7,1.9,6.1,1.2,8.6-1.5c2.3-2.4,4.7-4.8,7.1-7.1
		c2.1-2,4.8-2.9,7-0.7c5.1,5,9.8,10.4,14.7,15.7c0.5,0.6,0.7,1.4,1.2,2.7c-0.9,1.2-1.8,2.5-2.9,3.6c-10.1,10.5-10,10.5-3.8,23.6
		c1.7,3.7,3.4,7.6,4.2,11.6c1,5.1,4.2,6.3,8.5,6.4c3,0,6,0.2,9,0c3.5-0.3,4.8,1.5,4.8,4.7c0,6.2,0,12.5,0,18.7
		C214.1,128.8,212.3,130.3,208.8,130.2z"/>
      <path className={cx(classes.rolesIcon, (props.pathClassName || ""))} d="M116,51.1c-35-1.4-61.9,29.3-64.1,59.3c-3.1,41.9,30.1,68.7,59,70.7c40.7,2.9,67.6-30.6,69.3-60.3
		C182.6,76.8,150.1,50.3,116,51.1z M147.3,160.5c-1.1,0.8-2.2,1.5-3.4,2.2c-8.3,4.9-19.2,8-28.3,8.1c-8.6-0.4-16.7-2.4-24.2-6.3
		c-2.5-1.3-4.9-2.8-7.3-4.5c-1.1-0.8-1.7-3-1.5-4.5c2-16.8,15.9-28.5,32.3-29.1c17.7-0.6,32,11.6,34.9,30.2
		C150,157.8,148.5,159.6,147.3,160.5z M116.5,86.5c8,0.3,13.2,5.9,13.6,14.3c0.3,7.1-4.8,14.6-13.9,14.8c-9,0.3-14.8-7-14.2-14.8
		C100.8,92.3,109.2,86.2,116.5,86.5z M159,148.8c-4.2-14-12.9-23.2-25-29.3c12.3-18.4,6.6-29.7-1.8-37.3
		c-10.5-9.5-23.7-8.8-33.1,0.6c-8.6,8.6-12.8,20-0.9,36.6c-12.5,5.9-20.7,15.8-25.2,29.1c-16.2-22.1-14.5-53.4,5.9-72.1
		c22-20.2,51.2-20.3,74.6-0.6C173.4,92.4,175.7,125.1,159,148.8z"/>
    </svg>
  );
}

RolesIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

export default RolesIcon;
