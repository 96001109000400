const apiVersion = "v3";

// Build-time configuration settings, set with process.env:
const googleMapsKey = process.env.REACT_APP_S4_GOOGLE_API_KEY;
const monitoringToken = process.env.REACT_APP_MONITORING_TOKEN;
const adminUrl = "/portal";
const tapUrl = "/tap";

const version = process.env.REACT_APP_VERSION;

// Run-time configuration settings, set with window._env_:

const serviceHost   = window._env_.REACT_APP_S4_TWS_SERVICE_DOMAIN;
const telescopioUrl = window._env_.REACT_APP_TELESCOPIO_URL;
const tenantProtocol = window._env_.REACT_APP_FORCE_NON_SSL_TENANT === "1" ? "http://" : "https://";
const wsProtocol = window._env_.REACT_APP_FORCE_NON_SSL_TENANT === "1" ? "ws://" : "wss://";
const sentryEnvironment = window._env_.REACT_APP_SENTRY_ENVIRONMENT;

const tenantPort = serviceHost.endsWith(":30000") ? ":30000" : "";
const pirateLanguageSupport = window._env_.REACT_APP_S4_PIRATE_LANG_SUPPORT === "1" ? true : false;
const reduxDebug = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || (window._env_.REACT_APP_S4_REDUX_DEBUG === "1")) ? true : false;

export const config = {
  swVersion: version,
  domain: window._env_.REACT_APP_S4_TWS_SERVICE_DOMAIN || "sera4.com",
  tenantProtocol: tenantProtocol,
  tenantPort: tenantPort,
  adminUrl: adminUrl,
  tapUrl: tapUrl,
  telescopioUrl: telescopioUrl,
  googleMapsAPIKey: googleMapsKey,
  monitoringAPIToken: monitoringToken,
  s4WSGWURL: `${wsProtocol}${serviceHost}/${apiVersion}`,
  s4SSOGwUrl: `${tenantProtocol}${serviceHost}/${apiVersion}`,
  reduxDebug: reduxDebug,
  secureProtocol: /https/.test(tenantProtocol),
  pirateLangSupport: pirateLanguageSupport,
  sentryEnvironment: sentryEnvironment
};
