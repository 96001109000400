// This aids in dealing with TWS-1673 ChunkLoading issues on
//  lazy loaded routes.  Sometimes the components are missing due to cache
export const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport().then((component) => {
      // set this to YES if it does load well
      sessionStorage.setItem("retry-lazy-refreshed", "false");
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        sessionStorage.setItem("retry-lazy-refreshed", "true");
        return window.location.reload();
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};