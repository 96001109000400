import "rc-time-picker/assets/index.css";

const dateTimeRangeStyle = theme => ({
  spacing: {
    marginTop: theme.spacing(1.5)
  },
  time: {
    marginBottom: theme.spacing(2)
  },
  inputDate: {
    width: "100%"
  },
  background: {
    backgroundColor: theme.lsyPalette.secondary.main
  },
  pointer: {
    cursor: "pointer"
  },
  quickTimeOptions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: theme.typography.xsFontSize
  },
  toggleContainer: {
    marginLeft: "auto"
  },
  toggleButton: {
    "height": "25px",
    "fontSize": theme.typography.xsFontSize,
    "transition": "color .2s linear,background-color .3s linear",
    "margin": "0 0.1rem",
    "&.Mui-disabled": {
      border: 0
    }
  },
  buttonActive: {
    "&.MuiToggleButton-root": {
      "--size": "1.5px",
      "--color": theme.palette.text.light,
      "borderRadius": "0",
      "border": "none",
      "backgroundColor": "white",
      "background": "linear-gradient(var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 0 / 10px 100%,linear-gradient(var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 100% 0 / 10px 100%,linear-gradient(to right, var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 0 / 100% 8px,linear-gradient(to right, var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 100% / 100% 8px",
      "backgroundRepeat": "no-repeat"
    }
  },
  typeDateRange: {
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(1.5)} ${theme.spacing(1)}`
  }
});

export default dateTimeRangeStyle;