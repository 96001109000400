import { useContext } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { siteService, lockgroupService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter } from "_helpers";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/sitesStyle.js";
import moment from "moment";

const useStyles = makeStyles()(styles);
const timeFormat = "MMM D YYYY, hh:mm a";

function KeyCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { data, type, isDeleteButtonDisabled } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const deleteKeyFromSite = async (siteId, keyId, fetchWidgetData) => {
    try {
      await siteService.deleteKey({ siteId: siteId, keyId: keyId });
      dispatch(alertActions.send(t("success.removedUserAccess")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.removeuseraccessfromsite"), "error"));
    }
  };

  const deleteKeyFromLockgroup = async (lockgroupId, keyId, fetchWidgetData) => {
    try {
      await lockgroupService.deleteKey({ id: lockgroupId, keyId: keyId });
      dispatch(alertActions.send(t("success.deleteKey")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteKey"), "error"));
    }
  };

  const getTypePermission = () => {
    if (type === "site") {
      return "lock_collection_keys";
    } else if (type === "lock_group") {
      return "lock_group_keys";
    } else {
      return null;
    }
  };

  const handleDeleteKey = () => {
    const confirmAction = () => {
      if (props.type === "site") {
        deleteKeyFromSite(data.site.id, data.id, props.fetchWidgetData);
      } else {
        deleteKeyFromLockgroup(data.lock_group_id, data.id, props.fetchWidgetData);
      }
    };

    props.createModal({
      type: "confirm",
      confirm: t("button.confirm"),
      confirmAction: confirmAction,
      title: t("confirmation.deleteTitle"),
      description: t("confirmation.deleteKey"),
      submit: true
    });
  };

  return (
    <Card className={classes.card}>
      <div className={classes.siteCardContent} data-testid="siteCard">
        <VpnKeyIcon
          fontSize="large"
          className={classes.cardIcon}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h5"
            component="h2"
          >
            <span
              onClick={() => props.history.push(lsyRouter("user", data.user.membership_id))}
              className={classes.name}
            >
              {`${data.user.name} (${data.user.email})`}
            </span>
          </Typography>
          <Typography>
            <span className={classes.field}>{`${t("label.validFrom")}:`}</span>
            <span className={classes.value}>
              {moment(data.start_date).format(timeFormat)}
            </span>
          </Typography>
          <Typography>
            <span className={classes.field}>{`${t("label.validTill")}:`}</span>
            <span className={classes.value}>
              {moment(data.end_date).format(timeFormat)}
            </span>
          </Typography>
          <Typography>
            <span className={classes.field}>{`${t("label.issuedBy")}:`}</span>
            <span className={`${classes.actionLink}`} onClick={() =>
              props.history.push(lsyRouter("user", data.issuer.membership_id))
            }
            >
              {`${data.issuer.name} (${data.issuer.email})`}
            </span>
          </Typography>
          <Typography>
            <span className={classes.field}>{`${t("label.updatedAt")}:`}</span>
            <span className={classes.value}>
              {moment(data.created_at).format(timeFormat)}
            </span>
          </Typography>
        </CardContent>
        <Can I="delete" on={getTypePermission()} ability={ability}>
          <Tooltip
            title={isDeleteButtonDisabled ? t("error.lock.locked") : t("button.deleteKey")}
            classes={{ tooltip: classes.tooltip }}
          >
            <span>
              <IconButton
                disabled={isDeleteButtonDisabled}
                onClick={handleDeleteKey}
                className={classes.siteDelete}
                aria-label="delete"
                size="large">
                <DeleteIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        </Can>
      </div>
    </Card>
  );
}

KeyCard.propTypes = {
  history: PropTypes.object,
  type: PropTypes.string,
  org: PropTypes.string,
  data: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  isDeleteButtonDisabled: PropTypes.bool
};

export default KeyCard;
