import { get } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchSystemLogs = async (options) => {
  return get(`${twsWebUrl}/system_events`, options);
};

const fetchChartSystemEvents = async (options) => {
  return get(`${twsWebUrl}/system_events/summary`, options);
};

const exportSystemLogs = async(options) => {
  return get(`${twsWebUrl}/reports/system_events`, options);
};

export const systemLogService = {
  fetchSystemLogs,
  fetchChartSystemEvents,
  exportSystemLogs
};