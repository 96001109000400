const notificationCommentStyle = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  commentItem: {
    marginBottom: "10px"
  },
  name: {
    fontSize: theme.lsyPalette.body2,
    color: theme.lsyPalette.text,
    margin: "0px"
  },
  date: {
    marginLeft: "8px", 
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.lsyPalette.primary.mainLight
  },
  comment: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    margin: "0px"
  }
});
export default notificationCommentStyle;
