/* This file compliments member.actions - if there's a dispatch call, please use that file instead */
import gwClient, { post } from "_helpers/axios/gw.client";
import { handleResponse, lsyServerV2Url } from "_services/helper";

/* funny call, as this one requires ONE membership ID to succeed */
const fetchMyMemberships = () => {
  const requestOptions = {
    method: "GET",
    url: "mymemberships?withPrivileged=1"
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r, {refresh: false});
    })
    .then(memberships => {
      return memberships;
    });
};

const resendMembershipConsent = (id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "tws-membership-id": id
    },
    url: `memberships/${id}/resend_invitation`
  };

  return gwClient(requestOptions)
    .then(r => handleResponse(r));
};

const confirmPendingConsent = (accountId, membershipId, subdomain) => {
  return post(`${lsyServerV2Url(subdomain)}/membership_consents`, { account_id: accountId, membership_id: membershipId });
};

export const memberService = {
  fetchMyMemberships,
  resendMembershipConsent,
  confirmPendingConsent
};