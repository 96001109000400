import TwsResource from "./tws-resource";

export default new TwsResource({
  endpoint: "identity",
  resource: "licenses",
  name: "twsTenantLicenses",
  requests: {
    query: { method: "GET", isArray: true, url: "licenses" }
  }
});
