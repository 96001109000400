const LockBatteryIconStyle = theme => ({
  svg: {
    enableBackground: "new 0 0 672 672",
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  row: {
    stroke: theme.lsyPalette.primary.mainLight,
    strokeWidth: "0px",
    fill: theme.lsyPalette.primary.mainLight
  },
  lowBattery: {
    fill: theme.lsyPalette.stdRed
  }
});
  
export default LockBatteryIconStyle;
  