import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "typeface-roboto";

import { store } from "_helpers";
import { injectStore } from "_helpers/axios/gw.client";
import Sera4App from "App/App";
import LoadingAppScreen from "_components/Loading";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Replay } from "@sentry/browser";
import { config } from "_configs/server-config";

import "./i18n";

import { initDB } from "__indexedDB/db";
import ErrorBoundary from "_components/ErrorBoundary";
import * as serviceWorker from "_workers/serviceWorkerRegistration";

initDB();
injectStore(store);

const shouldTurnSentryOn = () => {
  const validEnvironments = ["production", "staging", "qa"];

  return config.sentryEnvironment && validEnvironments.includes(config.sentryEnvironment);
};
/*Set tracesSampleRate to 1.0 to capture 100%
  of transactions for performance monitoring.
  We recommend adjusting this value in production
*/
const getSampleRateConfigs = () => {
  switch(config.sentryEnvironment) {
    case "production":
      return {
        tracesSampleRate: 0.3,
        replaysOnErrorSampleRate: 0.3
      };
    case "staging":
      return {
        tracesSampleRate: 0.2,
        replaysOnErrorSampleRate: 0.2
      };
    case "qa":
      return {
        tracesSampleRate: 0.2,
        replaysOnErrorSampleRate: 0
      };
    default:
      return {
        tracesSampleRate: 0,
        replaysOnErrorSampleRate: 0
      };
  }
};

if (shouldTurnSentryOn()) {
  Sentry.init({
    dsn: "https://ce878f2b3c614172815dc3da88411a9d@o390986.ingest.sentry.io/5839304",
    integrations: [
      new Integrations.BrowserTracing(),
      new Replay()
    ],
    ...getSampleRateConfigs(),
    environment: config.sentryEnvironment
  });
}

const container = document.getElementById("app");
const root = createRoot(container);

root.render(<Provider store={store}>
  <Suspense
    fallback={
      <LoadingAppScreen title="Sera4 Teleporte" fullHeight={true} />
    }
  >
    <ErrorBoundary fullPage>
      <Sera4App />
    </ErrorBoundary>
  </Suspense>
</Provider>);

serviceWorker.register();