const chartsStyle = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`

  },
  divider: {
    width: "100%"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    minWidth: "fit-content",
    margin: 0,
    fontSize: theme.lsyPalette.body2,
    display: "flex",
    alignItems: "center"
  },
  buttonActive: {
    "&.MuiToggleButton-root": {
      "--size": "1.5px",
      "--color": theme.palette.text.light,
      "borderRadius": "0",
      "border": "none",
      "backgroundColor": "white",
      "background": "linear-gradient(var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 0 / 10px 100%,linear-gradient(var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 100% 0 / 10px 100%,linear-gradient(to right, var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 0 / 100% 8px,linear-gradient(to right, var(--color) var(--size), transparent 0 calc(100% - var(--size)), var(--color) 0) 0 100% / 100% 8px",
      "backgroundRepeat": "no-repeat"
    }
  },
  buttonInactive: {
    backgroundColor: "none",
    border: "none"
  },
  toggleButton: {
    "height": "13px",
    "fontSize": theme.lsyPalette.subtitle3,
    "transition": "color .2s linear,background-color .3s linear",
    "margin": "0 0.25rem",
    "&.Mui-disabled": {
      border: 0
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(0.75)
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast,
    float: "right"
  },
  icon: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body3,
    marginLeft: "0.5rem",
    cursor: "pointer"
  },
  iconButton: {
    margin: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    padding: 0
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonHeader: {
    display: "flex",
    marginLeft: "auto"
  },
  timePeriod: {
    color: theme.palette.text.light,
    fontSize: theme.lsyPalette.subtitle3,
    margin: "0"
  },
  helpIcon: {
    color: theme.lsyPalette.primary.main,
    width: "25px",
    height: "auto"
  },
  arrowDownIcon: {
    transition: "transform 0.5s"
  },
  arrowUpIcon: {
    transform: "rotate(-180deg)",
    transition: "transform 0.5s"
  },
  noDescription: {
    transition: "margin 1s"
  },
  showDescription: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.light,
    fontSize: theme.lsyPalette.subtitle3,
    margin: `0 ${theme.spacing(1.5)} 0 0`,
    marginBottom: theme.spacing(0.25),
    transition: "margin 0.5s"
  },
  chart: {
    "width": "100%",
    "& .echarts-for-react": {
      minHeight: "475px"
    }
  },
  placeholder: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "flexDirection": "column",
    "color": theme.lsyPalette.primary.mainDark,
    "height": "225px",
    "& div": {
      textAlign: "center"
    },
    "minHeight": "475px"
  },
  placeholderIcon: {
    fontSize: "64px",
    margin: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: theme.typography.largeFont.fontSize,
    color: theme.lsyPalette.primary.mainDark
  },
  skeleton: {
    minHeight: "475px"
  },
  chip: {
    "margin": `${theme.spacing(0.25)} 0`,
    "marginLeft": theme.spacing(0.5),
    "padding": "0",
    "height": "27px",
    "& input": {
      width: "150px"
    }
  },
  groupByChip: {
    "marginTop": theme.spacing(0.25),
    "marginBottom": theme.spacing(0.25),
    "marginLeft": theme.spacing(0.25),
    "padding": "0",
    "height": "27px",
    "& .MuiChip-label": {
      padding: "0"
    }
  },
  chipIcon: {
    margin: "0",
    width: "14px",
    height: "auto",
    color: theme.lsyPalette.primary.main
  },
  modal: {
    "maxWidth": "500px",
    "& .MuiPopover-paper": {
      position: "relative"
    }
  },
  align: {
    textAlign: "end"
  },
  popoverText: {
    margin: theme.spacing(1),
    fontSize: theme.lsyPalette.body1
  }
});
export default chartsStyle;