import { get } from "_helpers/axios/gw.client";

import { lockdepotUrl } from "_services/helper";


const fetchLocksQuality = async (options) => {
  return get(`${lockdepotUrl}/reports/locks/quality`, options);
};

export const reportService = {
  fetchLocksQuality
};