import { twsWebUrl } from "_services/helper";
import { get, put, post, del } from "_helpers/axios/gw.client";

const fetchApiTokens = async (options) => {
  return await get(`${twsWebUrl}/api_tokens`, options);
};

const updateApiToken = async (options) => {
  return await put(`${twsWebUrl}/api_tokens/${options.reference_id}`, { api_token: { ...options.bodyParams } });
};

const deleteApiToken = async (options) => {
  return await del(`${twsWebUrl}/api_tokens/${options.reference_id}`);
};

const createApiToken = async () => {
  return await post(`${twsWebUrl}/api_tokens`);
};

export const apiTokenService = {
  fetchApiTokens,
  createApiToken,
  deleteApiToken,
  updateApiToken
};
