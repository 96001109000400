import { PropTypes } from "prop-types";

import {
  Alert,
  Grid,
  Typography
} from "@mui/material";

const Info = ({ message, className }) => {
  return <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    className={className}
  >
    <Grid item>
      <Alert severity={"info"} style={{alignItems: "center"}}>
        <Typography variant="caption">{message}</Typography>
      </Alert>
    </Grid>
  </Grid>;
};

Info.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
};

export default Info;