import { config } from "_configs/server-config";

const publicRoutes = {
  login: "/login",
  password: "/login/password",
  emailLink:"/login/email",
  passwordless: "/login/passwordless",
  loginMob: "/login-mob/passwordless",
  passwordReset: "/password_reset/:token",
  forgotPassword: "/forgot_password",
  activateAccount: "/activate_account/:token"
};

const privateRoutes = {
  logout: "/logout",
  memberships: "/memberships",
  adminPanel: config.adminUrl,
  tapPanel: config.tapUrl,
  lockstasy: "/tenants"
};

const routes = {
  public: publicRoutes,
  private: privateRoutes
};

export default routes;
