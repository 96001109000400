import md5 from "md5";
import client from "_helpers/axios/generic.client";

const avatarUrl = "https://www.gravatar.com/avatar";

/* fetch the image from gravatar or fallback to a nice default */
const getAvatar = (email) => {
  let gravatarUrl = `${avatarUrl}/${md5(email)}`;

  return client.get(gravatarUrl)
    .then((resp) => {
      // content-disposition: inline; filename="none.jpg"
      // hack - length of this file is 2637, since content-disp is not shown to browsers
      if (resp.headers["content-length"] === "2637") {
        return null;
      } else {
        return gravatarUrl;
      }
    })
    .catch(() => {
      return null;
    });
};

export const imageHelper = {
  getAvatar
};