import { useSelector, useDispatch } from "react-redux";
import { authActions } from "_actions";
import appRoutes from "_routes";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";
import { setTitle } from "_helpers/page-title";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @mui/material components
import Button from "@mui/material/Button";

// Icons
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const newStyles = theme => ({
  title: {
    "&::before": {
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "12px",
      height: "3px",
      background:theme.palette.text.primary,
      content: "''"
    },
    "display": "inline-block",
    "margin": "0 0 10px",
    "padding": "0 0 10px",
    "position": "relative",
    "z-index": 2,
    "fontWeight": 300,
    "fontSize": "1.1em",
    "letterSpacing": "0.25em",
    "textTransform": "uppercase",
    "color": theme.palette.primary.text,
    "textAlign": "center"
  },
  catcher: {
    position: "relative",
    zIndex: 2,
    textTransform: "lowercase",
    fontSize: "2.5em",
    color: theme.palette.primary.text
  },
  loggedIn: {
    color: theme.palette.secondary.main,
    fontSize: "1.3em",
    textTransform: "lowercase",
    wordSpacing: "1px"
  },
  logoutButton: {
    fontSize: theme.lsyPalette.body1,
    color: theme.palette.primary.contrastText,
    marginTop: "10px"
  }
});
const useStyles = makeStyles()(newStyles);

export default function NotFound() {
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(["pageErrors", "default"]);
  const dispatch = useDispatch();
  const loggedInState = useSelector((state) => state.auth.loggedIn);
  const routes = [...Object.values(appRoutes.private), ...Object.values(appRoutes.public)].map((route) => `/${route.split("/")[1]}`);
  const validPathname = routes.includes(`/${location.pathname.split("/")[1]}`);

  return (
    <GridContainer>
      <GridItem xs={true}>
        <h1 className={classes.title}>
          { t("genericError", { error: 404 }) }
        </h1>
        <div className={classes.catcher}>
          { t("doesNotExist") }
        </div>
        {loggedInState && 
        <>
          {validPathname &&
          <div className={classes.loggedIn}>
            {t("loggedInError")}
          </div>
          }
          <div>
            <Button
              onClick={() => {
                setTitle();
                if(validPathname) {
                  dispatch(authActions.logout());
                  history.replace(`${location.pathname}${location?.search}`);
                } else {
                  history.replace(appRoutes.public.login);
                }
              }}
              startIcon={<ExitToAppOutlinedIcon />}
              className={classes.logoutButton}
              variant="contained"
              color="primary"
            >
              {validPathname ? t("button.signOut", {ns: "default" }) : t("button.returnToDashboard", {ns: "default" })}
            </Button>
          </div>
        </>}
      </GridItem>
    </GridContainer>
  );
}