import { useEffect, useMemo, useReducer } from "react";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCustomCompareMemo } from "use-custom-compare";
import queryString from "query-string";

import { compactObj, formatDate, getFilterVars, isBlank } from "_helpers";
import { getReport } from "_helpers/ReportHelper";
import { genericReducer } from "_reducers/general.reducer";

import {
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  ExpandLessRounded,
  ExpandMoreRounded
} from "@mui/icons-material";

import ErrorBoundary from "_components/ErrorBoundary";
import ReportsTable from "./ReportsTable";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/reportsStyle";

const useStyles = makeStyles()(styles);

function ReportsExportWidget(props) {
  const { location, history } = props;
  const { search } = location;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const { report } = useParams();
  const isDownSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const reportHelper = useMemo(() => getReport(report, t, classes), [report, t, classes]);
  const getInitialState = () => {
    const filterVars = search ? getFilterVars({...reportHelper.initialFilterValues}, search) : reportHelper.initialFilterValues;
    const filterOptions = compactObj({ ...filterVars });
    const filterOptionsKeys = Object.keys(filterOptions);

    filterOptionsKeys.forEach(field => {
      if (typeof reportHelper.initialFilterValues[field] === "object" && !isBlank(filterOptions[field])) {
        if (Array.isArray(filterOptions[field])) {
          filterOptions[field] = filterOptions[field].map(item => {
            const [ name, value ] = item.split(",");
            return { 
              ...field === "eventType" ? { value: value } : { id: value },
              label: name
            };
          });
        } else {
          const [ name, value ] = filterOptions[field].split(",");
          filterOptions[field] = [{ 
            ...field === "eventType" ? { value: value } : { id: value },
            label: name
          }];
        }
      }
    });

    return { ...filterOptions };
  };

  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      selectedColumns: [{ id: "all", label: t("label.all") }],
      showChooseColumns: false,
      filterVariables: initialState
    }
  );

  const updateUrlQuery = (newState) => {
    const newFilterVariables = {
      ...newState,
      ... newState.start_date && { start_date: formatDate(newState.start_date, "YYYY/MM/DD") },
      ... newState.end_date && { end_date: formatDate(newState.end_date, "YYYY/MM/DD") }
    };

    if (!isEqual(newFilterVariables, state.filterVariables)) {
      const filterOptions = compactObj({ ...newFilterVariables });
      const filterOptionsKeys = Object.keys(filterOptions);

      filterOptionsKeys.forEach(field => {
        if (typeof filterOptions[field] === "object" && !isBlank(filterOptions[field])) {
          filterOptions[field] = filterOptions[field].map(item => {
            return `${item.label},${field === "eventType" ? item.value : item.id}`;
          });
        }
      });

      history.replace(`/reports/${report}?${queryString.stringify({...filterOptions}, { arrayFormat: "separator", arrayFormatSeparator: "|" })}`);
    }
  };

  const setShowChooseColumns = () => setState({ showChooseColumns: !state.showChooseColumns });
  const setFilterVariables = newFilters => {
    const newFilterVariables = { ...state.filterVariables, ...newFilters };
    updateUrlQuery(newFilterVariables);
    setState({ filterVariables: newFilterVariables });
  };

  const setSelectedColumns = column => {
    const isAll = column.id === "all";
    const isRemovingColumn = state.selectedColumns.some(selCol => selCol.id === column.id);

    const getNewSelectedColumns = () => isRemovingColumn ?
      state.selectedColumns.filter(selCol => selCol.id !== column.id) :
      [...state.selectedColumns.filter(selCol => selCol.id !== "all"), column];

    const newSelectedColumns = isAll ?
      [column] :
      getNewSelectedColumns();

    setState({ selectedColumns: isEmpty(newSelectedColumns) ? [{ id: "all", label: t("label.all") }] : newSelectedColumns });
  };

  useEffect(() => {
    setSelectedColumns({ id: "all", label: t("label.all") });
    if (search) {
      setState({ filterVariables: initialState });
    } else {
      setState({ filterVariables: reportHelper.initialFilterValues });
      updateUrlQuery({ ...reportHelper.initialFilterValues });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [report]);

  const renderSelectColumnsSection = () => {
    const columns = [
      { id: "all", label: t("label.all") },
      ...reportHelper.header
    ];

    return <ErrorBoundary>
      <List data-testid="chooseColumnList" className={classes.selectColumns}>
        {columns.map((column) => {
          const labelId = `list-label-${column.label}`;
          const handleSelectColumn = () => setSelectedColumns(column);

          return (
            <ListItem
              key={column.id}
              disablePadding
            >
              <ListItemButton onClick={handleSelectColumn} dense>
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    edge="start"
                    checked={state.selectedColumns.some(selCol => selCol.id === column.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={column.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </ErrorBoundary>;
  };

  const renderReportTable = useCustomCompareMemo(() => {
    return <ReportsTable
      search={search}
      exports={reportHelper}
      selectedColumns={state.selectedColumns}
      filterVariables={state.filterVariables}
      setFilterVariables={setFilterVariables}
    />;
  }, [reportHelper, state.filterVariables, state.selectedColumns], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return <Grid container spacing={1}>
    <Grid item xs={12} sm={10}>
      <Grid container alignItems="flex-start">
        <Grid item>
          <Typography data-testid="reportsWidgetTitle">{t("reports.report")}</Typography>
          <Typography data-testid="reportsWidgetDesc" variant="caption" className={classes.description}>
            {reportHelper.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={2}>
      <Grid container data-testid="chooseColumnTitleSection" onClick={setShowChooseColumns} className={classes.clickable}>
        <Grid item xs={11}>
          <Typography>{t("reports.chooseColumns")}</Typography>
          {state.showChooseColumns && <Typography variant="caption" className={classes.description}>{t("reports.chooseColDescription")}</Typography>}
        </Grid>
        <Grid item xs={1}>
          {state.showChooseColumns ? <ExpandLessRounded/> : <ExpandMoreRounded/>}
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Divider className={classes.divider} />
    </Grid>
    {state.showChooseColumns && isDownSm ? null :
      <Grid item xs={12} sm={state.showChooseColumns ? 10 : 12}>
        <ErrorBoundary>
          {renderReportTable}
        </ErrorBoundary>
      </Grid>
    }
    {state.showChooseColumns && 
      <Grid item xs={12} sm={2} className={classes.selectColumnsBorder}>
        <ErrorBoundary>
          {renderSelectColumnsSection()}
        </ErrorBoundary>
      </Grid>
    }
  </Grid>;
}

ReportsExportWidget.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default ReportsExportWidget;