import { twsWebUrl } from "_services/helper";
import { get, put } from "_helpers/axios/gw.client";

const fetchRequests = async (options) => {
  return await get(`${twsWebUrl}/access_requests`, options);
};

const fetchRequest = async (id) => {
  return await get(`${twsWebUrl}/access_requests/${id}`);
};

const updateRequest = async ({id, action, ...options}) => {
  return await put(`${twsWebUrl}/access_requests/${id}/${action}`, options.data);
};

export const accessRequestService = {
  fetchRequests,
  fetchRequest,
  updateRequest
};