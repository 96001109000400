import { useEffect, Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

function ScrollToTop({ history, children }) {
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const isScrollToTop = lsyAdminDataContext.isScrollToTop;

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isScrollToTop) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollToTop]);

  return <Fragment>{children}</Fragment>;
}

ScrollToTop.propTypes = {
  children: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(ScrollToTop);
