import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect, useCustomCompareCallback } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";

import {
  formatDateAsMMMMDDYYYYhhmmLocal,
  formatLocation,
  generateGoolgeMapsUrlPoint
} from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";
import { lockgroupService, siteService, tagService } from "_services/lockstasy";
import { getLicenseName, getLicenseInfoFromNote, hasLockLicense } from "_services/lockstasy/helper";

import {
  Grid,
  Skeleton,
  Typography
} from "@mui/material";

import Placeholder from "_components/Helper/Placeholder";
import LockReplacementDetails from "_containers/Widgets/LockReplacementDetails";
import SummaryPopoverWidget from "./SummaryPopoverWidget";

import { 
  DescriptionOutlined,
  AccountCircle,
  LocationOn,
  Launch
} from "@mui/icons-material";

import { LockIcons } from "assets/teleporte/Icons";
import SiteIcon from "assets/teleporte/SiteIcon";

import styles from "assets/jss/widgets/lockNoteDetailsWidgetStyle";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

function LockNoteDetailsWidget(props) {
  const { data, classNameImage, isLoading, images,
    groups, sites, setGroups, setSites, tags, setTags,
    canOpenLockSummary, canOpenUserSummary, canOpenSiteSummary } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const isReplacementNote = data?.note?.message === "lock.replacement" || data?.note?.message === "lock.replaced";
  const isDeactivationNote = data?.note?.message === "lock.deactivated" || data?.note?.message === "lock.reactivated";

  const [state, setState] = useReducer(genericReducer,
    {
      isImgLoading: true,
      image: ""
    }
  );

  const [popoverState, setPopoverState] = useReducer(genericReducer,
    {
      isSummaryOpen: false,
      data: {},
      anchorEl: null,
      type: "user"
    }
  );

  const setIsSummaryOpen = (isSummaryOpen) => setPopoverState({ isSummaryOpen });

  const addGroups = (newGroups) => {
    if (isEmpty(newGroups)) {
      return;
    }
    
    const newGroupsFiltered = newGroups.filter(group => !groups.some(g => g.id === group.id));

    setGroups(newGroupsFiltered);
  };

  const addSites = (newSites) => {
    if (isEmpty(newSites)) {
      return;
    }
    
    const newSitesFiltered = newSites.filter(site => !sites.some(s => s.id === site.id));

    setSites(newSitesFiltered);
  };

  const addTags = newTags => {
    if (isEmpty(newTags)) {
      return;
    }
    
    const newTagsFiltered = newTags.filter(tag => !tags.some(t => t.id === tag.id));

    setTags(newTagsFiltered);
  };
  
  const fetchSite = async () => {
    const note = data?.note || {};
    const details = note.replacement_details || note.deactivation_details || {};
    const lockSiteId = details.lock?.before?.collection_id || details.lock?.collection_id;
    const replacedBySiteId = details.lock_replacement?.before?.collection_id;
    let newSites = [];

    try {
      if (lockSiteId && (isEmpty(sites) || sites.every(site => site.id !== lockSiteId))) {
        const result = await siteService.fetchSiteData({ siteId: lockSiteId });
        newSites.push({ id: result.data.id, name: result.data.name });
      }
      if (replacedBySiteId && (isEmpty(sites) || sites.every(site => site.id !== replacedBySiteId))) {
        const result = await siteService.fetchSiteData({ siteId: replacedBySiteId });
        newSites.push({ id: result.data.id, name: result.data.name });
      }

      addSites(newSites);
    } catch (e) {
      console.warn("Warning, failed to fetch site data", e);
    }
  };

  const fetchLockGroup = async () => {
    const note = data?.note || {};
    const details = note.replacement_details || note.deactivation_details || {};
    const lockGroupId = details.lock?.before?.lock_group_id || details.lock?.lock_group_id;
    const replacedByGroupId = details.lock_replacement?.before?.lock_group_id;
    let newGroups = [];

    try {
      if (lockGroupId && (isEmpty(groups) || groups.every(group => group.id !== lockGroupId))) {
        const result = await lockgroupService.fetchLockgroup(lockGroupId);
        newGroups.push({ id: result.data.id, name: result.data.name });
      }
      if (replacedByGroupId && (isEmpty(groups) || groups.every(group => group.id !== replacedByGroupId))) {
        const result = await lockgroupService.fetchLockgroup(replacedByGroupId);
        newGroups.push({ id: result.data.id, name: result.data.name });
      }

      addGroups(newGroups);
    } catch (e) {
      console.warn("Warning, failed to fetch lock group data", e);
    }
  };

  const fetchTags = useCustomCompareCallback(async () => {
    const note = data?.note || {};
    const details = note.deactivation_details || {};
    const lockTagIds = details.lock?.tag_ids || [];

    await Promise.all(lockTagIds.map(id => {
      const tag = tags.find(tag => tag.id === id);
      return tag ? { data: tag } : tagService.fetchTag(id);
    }))
      .then(values => addTags(values.map(result => ({ id: result.data.id, name: result.data.name }))))
      .catch(error => console.warn("Warning, failed to fetch tags", error.message));
  }, [data], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const fetchNoteImage = useCallback(() => {
    const img = images?.find(img => img.id === data.photos[0].id);

    if (img) {
      setState({
        image: img.data,
        isImgLoading: false
      });
    }
  }, [data?.photos, images]);

  useCustomCompareEffect(() => {
    fetchSite();
    fetchLockGroup();
    fetchTags();
  }, [data], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    setState({isImgLoading: true});
    if (!isEmpty(data?.photos)) {
      fetchNoteImage();
    } else {
      setState({
        image: "",
        isImgLoading: false
      });
    }
  }, [data?.photos, fetchNoteImage]);

  const handleSummary = async (e, type, data) => {
    try {
      setPopoverState({
        data: data,
        isSummaryOpen: true,
        type: type,
        anchorEl: e.target
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const getLocation = location => {
    return location ? 
      <span>
        <a
          href={generateGoolgeMapsUrlPoint(location)}
          className={classes.redirectLink}
          target="_blank"
          rel="noreferrer"
        >
          <b>{formatLocation(location)}</b>
          <Launch className={classes.redirectIcon}/>
        </a>
      </span> : null;
  };

  const getLockReplacementMessage = note => {
    const replacementDetails = note.replacement_details || {};
    const lock = replacementDetails.lock || {};
    const lockName = getFormattedLockName({...lock.before, id: lock.id});
    const replacementLock = replacementDetails.lock_replacement || {};
    const replacementLockName = getFormattedLockName({...replacementLock.before, id: replacementLock.id});

    return t(`resourceNotes.${note.message}`, { lock: lockName, replacementLock: replacementLockName });
  };

  const getLicense = note => {
    const { name, expiration } = getLicenseInfoFromNote(note);

    return {
      license: t(name),
      expiration: expiration ? t("resourceNotes.license.validTill", {expiration: expiration.params.till}) : ""
    };
  };

  const getSystemGeneratedMessage = () => {
    const note = data?.note || {};

    if (isReplacementNote) {
      return getLockReplacementMessage(note);
    } else if (note.message === "license.created") {
      return t(`resourceNotes.${note.message}`, getLicense(note));
    } else {
      return t(`resourceNotes.${note.message}`);
    }
  };

  const getUserComment = (note) => {
    if (isReplacementNote) {
      return note.replacement_details?.comments;
    } else if (isDeactivationNote) {
      return note.deactivation_details?.comments;
    } else {
      return "";
    }
  };

  const getDeactivationTableRows = (field, value) => {
    return <tr>
      <td data-testid="lockDetailLabel" className={classes.field}>{field}</td>
      <td data-testid="lockDetailValue">{value}</td>
    </tr>;
  };

  const getDeactivationInfo = () => {
    const note = data?.note || {};
    const deactivationDetails = note.deactivation_details || {};
    const license = getLicenseName({...deactivationDetails.lock.license, id: deactivationDetails.lock.license.license_definition_id});
    const formattedLicense = `${t(license.name)}${isEmpty(license.expiration?.params?.till) ? "" : " | " + license.expiration.params.till}`;
    
    const getGroupName = () => groups.find(group => deactivationDetails.lock.lock_group_id === group.id)?.name;
    const getSiteName = () => sites.find(site => deactivationDetails.lock.collection_id === site.id)?.name;
    const getTagsName = () => {
      return tags.filter(tag => deactivationDetails.lock.tag_ids.includes(tag.id)).map(tag => tag.name).join(", ");
    };

    return !isEmpty(deactivationDetails) &&
      <table className={classes.tableDetails}>
        <tbody>
          {getDeactivationTableRows(t("label.lockName"), deactivationDetails.lock.name)}
          {getDeactivationTableRows(t("label.lockDescription"), deactivationDetails.lock.description)}
          {getDeactivationTableRows(t("widgetField.license"), formattedLicense)}
          {getDeactivationTableRows(t("label.lockGroup"), getGroupName())}
          {getDeactivationTableRows(t("widgetField.site"), getSiteName())}
          {!isEmpty(deactivationDetails.lock.tag_ids) && getDeactivationTableRows(t("features.tags"), getTagsName())}
        </tbody>
      </table>;
  };

  const renderExtraInfo = (note) => {
    if (isReplacementNote) {
      const replacementDetails = note.replacement_details || {};
      const lock = replacementDetails.lock;
      const replacementLock = replacementDetails.lock_replacement;

      return !isEmpty(lock) && !isEmpty(replacementLock) &&
      <LockReplacementDetails
        lock={lock}
        replaceBy={replacementLock}
        type="note"
        sites={sites}
        groups={groups}
      />;
    } else if (isDeactivationNote) {
      return getDeactivationInfo();
    } else {
      return null;
    }
  };

  const renderMessage = () => {
    const note = data?.note || {};
    const canShowMessage = hasLockLicense("notes", data.related_resource) || data.related_resource.deactivated;

    const message = data.system_generated ?
      getSystemGeneratedMessage() :
      canShowMessage ? note.message : t("error.lock.license.required");

    return data.system_generated ?
      <Grid container>
        <Grid item xs={12} className={classes.spaceAround}>
          <Typography variant="body2" className={classes.textAlignRight}>
            { message }
          </Typography>
          <Typography variant="body2" className={classes.textAlignRight}>
            { getUserComment(note) }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          { renderExtraInfo(note) }
        </Grid>
      </Grid> :
      <Grid container>
        <Grid item xs={12} className={classes.spaceAround}>
          { message }
        </Grid>
      </Grid>;
  };

  const renderLoading = () => {
    return <>
      <Skeleton width="100%" height={100} />
      <Skeleton width="100%" height={500} />
    </>;
  };

  const handleLockSummary = (e) => {
    if (!canOpenLockSummary) {
      return;
    }

    handleSummary(e, "lock", data?.related_resource);
  };

  const handleUserSummary = (e) => {
    if (!canOpenUserSummary) {
      return;
    }

    handleSummary(e, "user", data?.user);
  };

  const handleSiteSummary = (e) => {
    if (!canOpenSiteSummary) {
      return;
    }

    handleSummary(e, "site", data?.related_resource.site);
  };

  const renderDetails = () => {
    return !hasLockLicense("notes", data.related_resource) && !data.system_generated && !data.related_resource.deactivated ?
      <Placeholder 
        message={t("error.lock.license.required")} 
        icon={<DescriptionOutlined/>}
        classNameMessage={classes.placeholderText}
        classNameIcon={classes.placeholderIcon}
        classNamePlaceholder={classes.placeholderMargin}
      /> :
      <>
        <div className={cx(classes.header, classes.cardHeader)}>
          {data?.related_resource?.name ? 
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenLockSummary ? classes.clickable : null)}
              onClick={handleLockSummary}
            >
              <LockIcons type={data.related_resource?.hardware_type} className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{getFormattedLockName(data?.related_resource)}</b>
            </div> : null}
          {data?.user ?
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenUserSummary ? classes.clickable : null)}
              onClick={handleUserSummary}
            >
              <AccountCircle className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{data?.user?.name}</b>
            </div> :  null}
          {data?.related_resource?.site?.name ? 
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenSiteSummary ? classes.clickable : null)}
              onClick={handleSiteSummary}
            >
              <SiteIcon className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{data?.related_resource?.site?.name}</b>
            </div> : null}
          {data?.location ? 
            <div className={classes.cardHeaderItem}>
              <LocationOn className={classes.cardHeaderIcon}/>
              {getLocation(data?.location)}
            </div> : null}
        </div>
        <Typography className={classes.tableItemTime} variant="caption">
          {formatDateAsMMMMDDYYYYhhmmLocal(data.created_at)}
        </Typography>
        { renderMessage() }
        <div className={classes.cardBody}>
          {state.isImgLoading ?
            <Skeleton width="100%" height={500} /> :
            state.image ? 
              <div className={classes.imageContainer}>
                <img className={cx(classes.noteImage, classNameImage)} 
                  src={`data:image/jpeg;base64,${state.image}`} 
                />
              </div>
              : null}
        </div>
      </>;
  };
  
  const renderNoteInfo = () => {
    return isEmpty(data) ?
      <Placeholder 
        message={t("fallbacks.noLockNoteSelected")} 
        icon={<DescriptionOutlined/>}
        classNameMessage={classes.placeholderText}
        classNameIcon={classes.placeholderIcon}
        classNamePlaceholder={classes.placeholderMargin}
      /> :
      renderDetails();
  };

  return <div className={classes.cardContainer} data-testid="lockNoteDetailsWidget">
    <Typography variant="body1"><b>{t("widget.noteDetails")}</b></Typography>
    { isLoading ? renderLoading() : renderNoteInfo() }
    <SummaryPopoverWidget
      type={popoverState.type}
      data={popoverState.data}
      open={popoverState.isSummaryOpen}
      setOpen={setIsSummaryOpen}
      anchorEl={popoverState.anchorEl}
    />
  </div>;
}

LockNoteDetailsWidget.defaultProps = {
  canOpenLockSummary: true,
  canOpenUserSummary: true,
  canOpenSiteSummary: true
};

LockNoteDetailsWidget.propTypes = {
  classNameImage: PropTypes.string,
  data: PropTypes.object,
  images: PropTypes.array,
  isLoading: PropTypes.bool,
  groups: PropTypes.array,
  sites: PropTypes.array,
  tags: PropTypes.array,
  setGroups: PropTypes.func,
  setSites: PropTypes.func,
  setTags: PropTypes.func,
  canOpenUserSummary: PropTypes.bool,
  canOpenLockSummary: PropTypes.bool,
  canOpenSiteSummary: PropTypes.bool
};

export default LockNoteDetailsWidget;