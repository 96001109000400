const accountCardStyle = theme => ({
  accountCard: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1",
    transform: "scale(0.8)"
  },
  svg: {
    marginTop: "2px",
    enableBackground: "new 0 0 672 672"
  }
});

export default accountCardStyle;
