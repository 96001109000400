//Used material ui Enhanced table
import PropTypes from "prop-types";
import { useState } from "react";

import { makeStyles } from "tss-react/mui";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/widgets/tableWidget2Style.js";
import { keyStatus } from "_constants/key.constants";

const useStyles = makeStyles()(styles);

/*
  The LsyTable2 component is used to make a key related table. It offers specific configurations
  for key formatting, allowing you to easily customize the appearance of the line according to
  the key status.
  The component KeyWidget is a good example of how to use this table.
*/
function EnhancedTableHead(props) {
  const { t } = useTranslation("default");
  const { classes, order, orderBy, onRequestSort } = props;
  const { cx } = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead className={classes.tableHeader}>
      <TableRow className={classes.tableHeaderRow}>
        {props.headers.map((headCell) => {
          const headerCellClassName = cx(
            classes.tableHeaderCell,
            {
              [headCell.classNameProps]: headCell.classNameProps
            }
          );

          return <TableCell
            key={headCell.id}
            align="left"
            data-testid="tableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            className={headerCellClassName}
          >
            {headCell.sortable ? (
              <Tooltip
                title={
                  order === "desc" ? t("button.sortAsc") : t("button.sortDesc")
                }
                classes={{ tooltip: classes.tooltip }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            ) : (headCell.label)}
          </TableCell>;
        }
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headers: PropTypes.array
};

export default function EnhancedTable2(props) {
  const { classes, cx } = useStyles();
  const [order, setOrder] = useState(props.sortDirection || "asc");
  const [orderBy, setOrderBy] = useState(props.orderBy || "noSort");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    props.sort(property, isAsc ? "desc" : "asc");
  };
  const headers = props.data ? props.data[0] : [];
  const bodyData = props.data ? props.data[1] : [];

  return (
    <div className={classes.tableBackground}>
      <Paper className={classes.tablePaper} elevation={0}>
        <TableContainer>
          <Table
            data-testid="lsyTable2"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {bodyData.map(
                (row, index) => {
                  return (
                    <TableRow
                      data-testid="tableRow"
                      role="dataRow"
                      tabIndex={-1}
                      key={`row-${index}`}
                      elevation={0}
                      onClick={row.rowAction ? () => { row.rowAction(); } : null}
                      className={cx({[classes.tableRowAction]: row.rowAction, [classes.tableRow]: !row.rowAction})}
                    >
                      {Object.entries(row).map((cell, index) => {
                        if (cell[0] !== "rowAction") {
                          const activeKey = row.validTill?.key_status !== keyStatus.EXPIRED && row.validTill?.key_status !== keyStatus.DELETED;
                          const expiredKey = row.validTill?.key_status === keyStatus.EXPIRED;
                          const deletedKey = row.validTill?.key_status === keyStatus.DELETED;

                          const cellClassName = cx(
                            classes.tableBodyCell,
                            {
                              [classes.active]: activeKey,
                              [classes.expired]: expiredKey,
                              [classes.deleted]: deletedKey,
                              [cell[1].overflowHidden]: cell[1].overflowHidden,
                              [cell[1].classNameProps]: cell[1].classNameProps
                            }
                          );

                          return (
                            <TableCell key={`cell=${index}`} className={cellClassName} elevation={0} align="left">
                              {cell[1].action ? ( 
                                <span
                                  id={index}
                                  onClick={cell[1].action}
                                  className={classes.actionLink}
                                >
                                  {cell[1].text}
                                </span>
                              ) : (cell[1].text)}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
EnhancedTable2.propTypes = {
  data: PropTypes.array,
  sortDirection: PropTypes.string,
  orderBy: PropTypes.string,
  sort: PropTypes.func
};
