import { get, post, del } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

import queryString from "query-string";

const updateUserNotification = async (options) => {
  return await post(`${twsWebUrl}/notifications/subscriptions`, options);
};

const deleteUserNotification = async (options) => {
  return await del(`${twsWebUrl}/notifications/subscriptions?${queryString.stringify(options)}`);
};

const getAllUserNotifications = async () => {
  let options = { page_size: 10000000000 };
  return await get(`${twsWebUrl}/notifications/subscriptions`, options);
};

const getUserNotification = async (options) => {
  return await get(`${twsWebUrl}/notifications/subscriptions`, options);
};

export const userNotificationService = {
  updateUserNotification,
  deleteUserNotification,
  getUserNotification,
  getAllUserNotifications
};