/* eslint-disable no-unused-vars */
import { get, put, post } from "_helpers/axios/gw.client";
import { compactObj } from "_helpers";
import { lockdepotUrl } from "_services/helper";

const fetchRtmBoards = async (options) => {
  const requestOptions = compactObj(options);
  return get(`${lockdepotUrl}/rtm_boards`, requestOptions);
};

const fetchRtmBoardInfo = async (id) => {
  return get(`${lockdepotUrl}/rtm_boards/${id}?include=lock&include=rtm_connection`);
};

const updateBoardConfiguration = async (id, payload) => {
  return put(`${lockdepotUrl}/rtm_boards/${id}`, payload);
};

const updateBulkBoardConfiguration = async (payload) => {
  return put(`${lockdepotUrl}/rtm_boards/bulk_update`, payload);
};

const registerNewBoard = async (payload) => {
  return put(`${lockdepotUrl}/rtm_boards/setup`, payload);
};

const sendBleCommand = async (id, payload) => {
  return post(`${lockdepotUrl}/rtm_boards/${id}/ble_command`, payload);
};

const getUsage = async(id, options = {}) => {
  const requestOptions = compactObj(options);
  return get(`${lockdepotUrl}/rtm_boards/${id}/data_usage`, requestOptions);
};

const migrateToNewLock = async(id, payload) => {
  return put(`${lockdepotUrl}/rtm_boards/${id}/set_new_hardware_id`, payload);
};

export const rtmService = {
  getUsage,
  sendBleCommand,
  fetchRtmBoards,
  registerNewBoard,
  migrateToNewLock,
  fetchRtmBoardInfo,
  updateBoardConfiguration,
  updateBulkBoardConfiguration
};
