import * as actionTypes from "_actions/actionTypes";
import { updateObject } from "_helpers/utility";

let user;
let sera4Mode = false;
try {
  user = JSON.parse(localStorage.getItem("user"));
  sera4Mode = JSON.parse(localStorage.getItem("sera4Mode"));
} catch (e) {
  console.debug(e);
}

const baseDefaults = {
  loggingIn: false,
  error: null,
  success: null,
  loggedIn: false,
  loginMethod: "password",
  sera4Mode: sera4Mode,
  user: null
};

const initialState = user
  ? { ...baseDefaults, loggedIn: true, mfaEnabled: false, mfaSetup: false, tcStep: false, piStep: false, user }
  : { ...baseDefaults, mfaEnabled: false, mfaSetup: false, tcStep: false, piStep: false };

const authStart = (state) => {
  return updateObject(state, {
    ...baseDefaults
  });
};

const generalError = (state, action) => {
  return updateObject(state, {
    error: action.error,
    success: null
  });
};

const generalSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    success: action.success
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    loggedIn: true,
    loginMethod: action.loginMethod,
    loggingIn: false,
    tcStep: false,
    piStep: false,
    user: action.user,
    error: null
  });
};

const authTCStep = (state, action) => {
  return updateObject(state, {
    loggedIn: false,
    loggingIn: false,
    tcStep: action.tcStep,
    piStep: action.piStep,
    success: action.success
  });
};

const authMfaStep = (state, action) => {
  return updateObject(state, {
    loggedIn: false,
    loggingIn: false,
    tcStep: false,
    mfaEnabled: action.mfaEnabled,
    success: action.success
  });
};

const authMfaSetup = (state, action) => {
  return updateObject(state, {
    loggedIn: false,
    loggingIn: false,
    tcStep: false,
    mfaSetup: true,
    mfaSetupData: action.mfaSetupData,
    mfaEnabled: action.mfaEnabled,
    success: action.success
  });
};

const authMfaReset = (state, action) => {
  return updateObject(state, {
    loggingIn: true,
    mfaEnabled: action.mfaEnabled,
    mfaSetup: action.mfaSetup
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    loggedIn: false,
    loggingIn: false,
    error: action.error,
    success: null
  });
};

const setSera4Mode = (state, action) => {
  localStorage.setItem("sera4Mode", action.sera4Mode);
  return updateObject(state, { sera4Mode: action.sera4Mode });
};

const authLogout = (state) => {
  return updateObject(state, {
    ...baseDefaults,
    sera4Mode: false
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const authUpdate = (state, action) => {
  return { ...state, ...action.data };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_TC_STEP: return authTCStep(state, action);
    case actionTypes.AUTH_MFA_STEP: return authMfaStep(state, action);
    case actionTypes.AUTH_MFA_SETUP: return authMfaSetup(state, action);
    case actionTypes.AUTH_MFA_RESET: return authMfaReset(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_UPDATE: return authUpdate(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
    case actionTypes.PASS_RESET_SUCCESS: return generalSuccess(state, action);
    case actionTypes.PASS_RESET_FAIL: return generalError(state, action);
    case actionTypes.SERA4_MODE: return setSera4Mode(state, action);
    default:
      return state;
  }
};

export default reducer;