import { AbilityBuilder, Ability } from "@casl/ability";

export function DefineFeatureAbility(features) {
  const { rules, can } = new AbilityBuilder();
  Object.keys(features).map(function (key) {
    if (features[key]) {
      can(["view", "edit"], key);
    }
  });
  return new Ability(rules);
}
