import { useEffect, useState, useReducer, useContext, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { cloneDeep, has } from "lodash";

import { adminConstants } from "_constants/admin.constants";
import { adminSchema } from "_constants/schema.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { formatSchema } from "_services/lockstasy/helper";
import { sortReducer } from "_reducers/sort.reducer";

//mui components
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Fade from "@mui/material/Fade";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

//icons
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/roleWizardStyle.js";

const useStyles = makeStyles()(styles);

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade in={true} {...props} timeout={1000} ref={ref}/>;
});

const actions = Object.freeze(["read", "create", "update", "delete"]);

export default function RoleWizard2(props) {
  const { open, setOpen, roles } = props;
  const [state, setState] = useReducer(sortReducer.reducer, adminSchema);

  const [currentRole, setCurrentRole] = useState(roles.length > 0 ? roles[0].id : null);
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const userGroupMap = lsyAdminDataContext.userGroupMap;

  const resourceLabelMap = {
    users: t("features.users"),
    locks: t("features.locks"),
    lock_notes: t("features.lockNotes"),
    lock_logs: t("label.lockLogs"),
    keys: t("label.keys"),
    lock_collection_keys: t("label.lockCollectionKeys"),
    lock_group_keys: t("label.lockGroupKeys"),
    lock_groups: t("features.lockGroups"),
    lock_collections: t("features.lockCollections"),
    //access_requests: t("features.accessRequests"),
    // locations: t("features.locations"),
    // logs: t("features.systemLogs"),
    tags: t("features.tags"),
    // reports: t("features.reports"),
    // charts: t("features.charts"),
    // work_sessions: t("label.workSessions"),
    // notifications: t("label.notifications"),
    organizations: t("features.organizations"),
    api_tokens: t("features.api")
  };

  const handleRoleChange = () => {
    let { id, schema } = roles.filter(v => v.id === currentRole)[0];
    let formattedSchema;
    if (id === adminConstants.LSY_ADMIN) {
      setState(adminSchema);
    } else {
      formattedSchema = formatSchema(cloneDeep(schema));
      setState(formattedSchema);
    }
  };

  useEffect(() => {
    handleRoleChange();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentRole]);

  const handleClose = () => {
    setOpen(false);
  };

  const formatRows = () => {
    var resources = Object.keys(resourceLabelMap);
    if (!state["organizations"]["update"]) {
      resources = resources.filter((value) => !["organizations", "api_tokens"].includes(value));
    }

    const Row = (props) => {
      const {resource, name} = props;
      return (
        <tr key={resource}>
          <th>{name}</th>
          {actions.map((action) => {
            if (has(adminSchema[resource], action)) {
              return (<td key={action}>
                <Checkbox
                  inputProps={{"data-testid": `${resource}.${action}`}}
                  checked={state[resource][action]}
                  disabled name={resource + "." + action}
                  icon={<FiberManualRecordOutlinedIcon />}
                  checkedIcon={<FiberManualRecordIcon />} />
              </td>);
            } else {
              return <td key={action}></td>;
            }
          })}
        </tr>
      );
    };

    Row.propTypes = {
      resource: PropTypes.string,
      name: PropTypes.string
    };

    const rows = resources.map((resource, index) => { //locks, lock_notes, users, tags etc
      const key = `${resource}_${index}`;
      if(Array.isArray(resourceLabelMap[resource])) {
        return [<Row key={key} resource={resource} name={resourceLabelMap[resource][0]}/>, <Row key={1} resource={resource} name={resourceLabelMap[resource][1]}/>];
      } else {
        return <Row resource={resource} key={key} name={resourceLabelMap[resource]}/>;
      }
    });
    return rows;
  };

  const getTable = () => {
    return (<table className={classes.table} data-testid="roleTable">
      <thead data-testid="roleTableHeader">
        <tr>
          <th className={classes.actionTitle}><div><span>{t("label.permission")}</span></div></th>
          <th className={classes.actionTitle}><div><span>{t("actions.read")}</span></div></th>
          <th className={classes.actionTitle}><div><span>{t("actions.create")}</span></div></th>
          <th className={classes.actionTitle}><div><span>{t("actions.update")}</span></div></th>
          <th className={classes.actionTitle}><div><span>{t("actions.delete")}</span></div></th>
        </tr>
      </thead >
      <tbody data-testid="roleTableBody">
        {formatRows().map((row) => row)}
      </tbody>
    </table>);
  };

  const getLegend = () => {
    return (<table className={cx(classes.table, classes.legend)} style={{borderSpacing: "0 10px"}} data-testid="legendTable">
      <thead data-testid="legendTableHeader">
        <tr>
          <th colSpan="2" className={classes.actionTitle}><div><span style={{textTransform: "uppercase"}}>{t("label.legend.legend")}</span></div></th>
        </tr>
      </thead>
      <tbody data-testid="legendTableBody">
        <tr>
          <th >
            <Checkbox
              checked={true}
              disabled
              icon={<FiberManualRecordOutlinedIcon />}
              checkedIcon={<FiberManualRecordIcon />} />
          </th>
          <td>{t("label.legend.fullCircle")}</td>
        </tr>
        <tr>
          <th>
            <Checkbox
              checked={false}
              disabled
              icon={<FiberManualRecordOutlinedIcon />}
              checkedIcon={<FiberManualRecordIcon />} />
          </th>
          <td>{t("label.legend.lackCircle")}</td>
        </tr>
        <tr>
          <th>
            {t("label.legend.noCircle")}
          </th>
          <td>{t("label.legend.emptyCircle")}</td>
        </tr>
      </tbody>
    </table>);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      setCurrentRole(e.currentTarget.dataset.value);
    }
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} PaperProps={{ className: classes.paper }}>
        <div className={classes.borderDiv}>
          <Grid container className={classes.container1} justifyContent="center">
            <Grid item xs={12} md={8} className={classes.item1}>
              <Grid container className={classes.container2} direction="column" justifyContent="center" alignItems="stretch">
                <Grid item xs={12}>
                  <IconButton data-testid="backButton" edge="start" onClick={handleClose} aria-label="close" size="large">
                    <KeyboardBackspaceRoundedIcon />
                  </IconButton>
                  <Typography variant="h6" data-testid="rolesTitle">
                    {t("label.roles")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className={classes.itemGrid}>
                    <InputLabel id="role">
                      {t("label.role")}
                    </InputLabel>
                    <Select
                      className={classes.itemBox}
                      value={currentRole}
                      role="listbox"
                      label={t("label.role")}
                      onChange={(e) => {
                        setCurrentRole(e.target.value);
                      }}>
                      {roles.filter(v => v.id !== adminConstants.LSY_USER).map((option, index) => {
                        return (
                          <MenuItem
                            value={option.id}
                            key={index}
                            onKeyDown={handleKeyDown}
                          >
                            {userGroupMap[option.name] || option.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8} className={classes.tableItem}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={8}>
                      {getTable()}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {getLegend()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

RoleWizard2.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  roles: PropTypes.array
};
