import { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import moment from "moment";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

import { Grid } from "@mui/material";

import KeyCreationChart from "_containers/Lockstasy/Charts/KeyCreationChart";
import LockAccessChart from "_containers/Lockstasy/Charts/LockAccessChart";
import LockOpeningsChart from "_containers/Lockstasy/Charts/LockOpeningsChart";
import UserOpeningsChart from "_containers/Lockstasy/Charts/UserOpeningsChart";
import UserTimeAccessChart from "_containers/Lockstasy/Charts/UserTimeAccessChart";

// styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/chartsStyle.js";

const useStyles = makeStyles()(styles);

function Charts(props) {
  const { location, history } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const { classes } = useStyles();
  const language = useSelector((state) => state.locale.language);
  const { t } = useTranslation("default");
  moment.locale(language);

  return (
    <div className={classes.container} data-testid="container">
      <Can I="read" on="charts" ability={ability}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <LockAccessChart
              title={t("label.accessesChart")}
              history={history}
              location={location}
              type={"lock_access"}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <UserOpeningsChart
                  title={t("label.usersAccesses")}
                  history={history}
                  location={location}
                  type={"user_openings"}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UserTimeAccessChart
                  title={t("label.usersAccessesTime")}
                  history={history}
                  location={location}
                  type={"user_time_access"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <LockOpeningsChart
                  title={t("label.locksAccesses")}
                  history={history}
                  location={location}
                  type={"lock_openings"}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <KeyCreationChart
                  title={t("label.keyAssignment")}
                  history={history}
                  location={location}
                  type={"key_creation"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Can>
    </div>
  );
}

Charts.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default Charts;
