import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { adminConstants } from "_constants/admin.constants";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// Services
import { userService } from "_services/lockstasy";

// @mui/material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Components
import GridItem from "components/Grid/GridItem";
import KeyWidget from "_containers/Widgets/KeyWidget";
import UserDetailsWidget from "_containers/Widgets/UserDetailsWidget";
import LockNoteWidget from "_containers/Widgets/LockNoteWidget";
import AccessWidget from "_containers/Widgets/AccessWidget";
import WorkSessionWidget from "_containers/Widgets/WorkSessionWidget";
import ErrorBoundary from "_components/ErrorBoundary";

// Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/userStyle.js";

const useStyles = makeStyles()(styles);

function User(props) {
  const { org, history } = props;
  const { classes } = useStyles();
  const params = useParams();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const [user, setUser] = useState({});
  const isRegularUser = currentMembership.role_id === adminConstants.LSY_USER;

  const fetchUser = useCallback(async () => {
    try {
      const result = await userService.fetchUser({ id: params.id, include: "tags" });
      setUser(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch user", e);
      return [];
    }
  }, [params.id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="space-evenly" className={classes.container}>
        <GridItem xs={12} md={4}>
          <ErrorBoundary>
            <UserDetailsWidget user={user} org={org} history={history} isRegularUser={isRegularUser} />
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <GridItem xs={12} md={7}>
          <ErrorBoundary>
            <Can I="read" on="access_history" ability={ability}>
              <AccessWidget user={user} org={org} history={history} isRegularUser={isRegularUser} type="user"/>
            </Can>
          </ErrorBoundary>
        </GridItem>
        <Divider className={classes.divider} />
        <Grid item xs={12} md={12} className={classes.tableWidgetItem}>
          <ErrorBoundary>
            <KeyWidget org={org} user={user} type="user"/>
          </ErrorBoundary>
        </Grid>
        <Divider className={classes.divider} />
        <GridItem xs={12} md={6}>
          <ErrorBoundary>
            <LockNoteWidget user={user} org={org} history={history} isRegularUser={isRegularUser} type="user"/>
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <Divider sx={{ display: { md: "none", xs: "block" } }} className={classes.divider} />
        <GridItem xs={12} md={5}>
          <ErrorBoundary>
            <Can I="read" on="work_sessions" ability={ability}>
              <WorkSessionWidget user={user} org={org} history={history} isRegularUser={isRegularUser} />
            </Can>
          </ErrorBoundary>
        </GridItem>
      </Grid>
    </div>
  );
}

User.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string
};

export default User;
