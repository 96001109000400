import { twsWebUrl } from "_services/helper";
import { get } from "_helpers/axios/gw.client";

const fetchRoles = async () => {
  return await get(`${twsWebUrl}/roles`);
};

const fetchRoleSchema = async (options) => {
  return await get(`${twsWebUrl}/users/${options.membershipId}/role`);
};

export const roleService = {
  fetchRoles,
  fetchRoleSchema
};