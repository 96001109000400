import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/teleporteTextLogoStyle";
const useStyles = makeStyles()(styles);

function TeleporteTextImage() {
  const { classes } = useStyles();
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 360 108"
      version="1.1"
    >
      <defs id="defs4"></defs>
      <title id="title6">Teleporte Logo</title>
      <g id="g26">
        <path
          className={classes.tpLogoText}
          d="M81.23,36.24c-11,0-18.24,8-18.24,17.59,0,9,6.71,17.65,19.25,17.65,6.17,0,11.18-2.26,15.93-7.31L93.5,60.62A15,15,0,0,1,82.18,66c-6.84,0-11.73-4.2-12.68-10H99.73C99.73,44.19,92.14,36.24,81.23,36.24ZM69.5,51.3c.61-4.59,4.2-9.64,11.66-9.64,6.3,0,11,4.07,11.66,9.64Z"
          id="path8"
        />
        <path
          className={classes.tpLogoText}
          d="M306.44,66c-6.84,0-11.73-4.2-12.68-10H324c0-11.9-7.59-19.85-18.5-19.85s-18.24,8-18.24,17.59c0,9,6.71,17.65,19.25,17.65,6.17,0,11.18-2.26,15.93-7.31l-4.68-3.56A15,15,0,0,1,306.44,66Zm-1-24.38c6.3,0,11,4.07,11.66,9.64H293.77C294.38,46.71,298,41.67,305.43,41.67Z"
          id="path10"
        />
        <path
          className={classes.tpLogoText}
          d="M137,36.24c-11,0-18.24,8-18.24,17.59,0,9,6.71,17.65,19.25,17.65,6.17,0,11.18-2.26,15.93-7.31l-4.68-3.56A15,15,0,0,1,138,66c-6.84,0-11.73-4.2-12.68-10h30.23C155.51,44.19,147.92,36.24,137,36.24ZM125.28,51.3c.61-4.59,4.2-9.64,11.66-9.64,6.3,0,11,4.07,11.66,9.64Z"
          id="path12"
        />
        <rect
          className={classes.tpLogoText}
          x="106.41"
          y="25.79"
          width="5.91"
          height="45.14"
          id="rect14"
        />
        <polygon
          className={classes.tpLogoText}
          points="68.11 31.43 68.11 25.79 55.01 25.79 49.1 25.79 36 25.79 36 31.43 49.1 31.43 49.1 70.93 55.01 70.93 55.01 31.43 68.11 31.43"
          id="polygon16"
        />
        <path
          className={classes.tpLogoText}
          d="M179.92,36.27c-10.27,0-18.59,7.94-18.59,17.73V82.21h5.91V67a19,19,0,0,0,12.67,4.77c10.27,0,18.59-7.94,18.59-17.73S190.19,36.27,179.92,36.27Zm0,29.83c-7,0-12.67-5.42-12.67-12.09s5.69-12.09,12.67-12.09S192.59,47.33,192.59,54,186.91,66.09,179.92,66.09Z"
          id="path18"
        />
        <path
          className={classes.tpLogoText}
          d="M221.46,36.27c-10.27,0-18.59,7.94-18.59,17.73s8.32,17.73,18.59,17.73S240,63.79,240,54,231.72,36.27,221.46,36.27Zm0,29.83c-7,0-12.67-5.42-12.67-12.09s5.69-12.09,12.67-12.09S234.13,47.33,234.13,54,228.44,66.09,221.46,66.09Z"
          id="path20"
        />
        <path
          className={classes.tpLogoText}
          d="M278.16,60.73V41.88h6.32V36.24h-6.34V25.79h-5.91V36.24h-3.8v5.64h3.8V61.8a13.76,13.76,0,0,0,5.86,11.41l.07.05a18.73,18.73,0,0,0,2.89,1.6l4-4.68A10.15,10.15,0,0,1,278.16,60.73Z"
          id="path22"
        />
        <path
          className={classes.tpLogoText}
          d="M252.16,39.38l-.07.05a13.76,13.76,0,0,0-5.86,11.41v20h5.94v-19A10.33,10.33,0,0,1,262,41.84V36.14A19,19,0,0,0,252.16,39.38Z"
          id="path24"
        />{" "}
      </g>
    </svg>
  );
}
TeleporteTextImage.propTypes = {
  fill: PropTypes.string
};
export default TeleporteTextImage;
