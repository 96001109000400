import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import MembershipsList from "_containers/Membership/List";

export default function Memberships(props) {
  /* basic login memberships view doesn't include admin permissions fetch */
  // const [memberships, setMemberships] = useState([]);

  const mainLayout = {xs: 12, sm: 8, md: 6 };
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  useLayoutEffect(() => {
    if (!isEqual(mainLayout, props.layoutProps))
      props.setLayoutProps(mainLayout);
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <MembershipsList auth={auth} history={history}/>
      </GridItem>
    </GridContainer>
  );
}

Memberships.propTypes = {
  layoutProps: PropTypes.object.isRequired,
  setLayoutProps: PropTypes.func.isRequired
};