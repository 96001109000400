import { debounce, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { isNullOrUndefined } from "_helpers";
import { TYPING_WAIT_TIME } from "_helpers/utility";
import { userService } from "_services/lockstasy";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";

const PAGE_SIZE = 10;

const fetchUsersWithInput = debounce((value, callback) => {
  userService.fetchUsers({ page: 1, page_size: PAGE_SIZE, search: value })
    .then((result) => {
      callback(result.data.map(u => u ? {
        id: u.membership_id,
        name: `${u.first_name} ${u.last_name} (${u.email})`,
        status: u.status
      } : null));
    })
    .catch((e) => {
      console.warn("Warning, failed to fetch users with given input", e);
      callback([]);
    });
}, TYPING_WAIT_TIME);

const SelectUser = (props) => {
  const { className, user, setUser, isOptionDisabled } = props;

  const { t } = useTranslation("default");

  const handleOnChange = (selectedOption) => {
    const option = isNullOrUndefined(selectedOption) ? {} : selectedOption;
    setUser(option);
  };

  return <CustomAsyncSelect
    class={className}
    autoFocus={false}
    placeholder={t("instructions.selectUser")}
    value={isEmpty(user) ? null : user}
    onChange={handleOnChange}
    loadOptions={fetchUsersWithInput}
    defaultOptions={true}
    isOptionDisabled={isOptionDisabled}
  />;
};

SelectUser.defaultProps = {
  isOptionDisabled: () => false
};

SelectUser.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func
};

export default SelectUser;