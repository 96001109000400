import gwClient from "_helpers/axios/gw.client";

import {
  handleResponse,
  identityUrl
} from "_services/helper";

const fetchSessionsById = (uuid) => {
  const requestOptions = {
    method: "GET",
    url: `${identityUrl}/sessions/accounts/${uuid}`

  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(sessions => {
      return sessions;
    });
};

const deleteSingleSession = (sessid) => {
  const requestOptions = {
    method: "DELETE",
    url: `${identityUrl}/sessions/${sessid}`
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(response => {
      return response;
    });
};

const deleteAllSessions = (uuid) => {
  const requestOptions = {
    method: "DELETE",
    url: `${identityUrl}/sessions/accounts/${uuid}`
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(response => {
      return response;
    });
};

export const sessionService = {
  fetchSessionsById,
  deleteSingleSession,
  deleteAllSessions
};