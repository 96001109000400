import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "tss-react/mui";

import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const styles = {
  topText: {
    marginBottom: "10px"
  }
};
const useStyles = makeStyles()(styles);

const MfaAuthConfirmForm = ({ onChange, submitted, password, message }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("auth");

  return (
    <>
      <div className={classes.topText}>
        {message || t("mfa.enterPassword")}
      </div>
      <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
        <InputLabel htmlFor="standard-adornment-password" error={submitted && !password}>{t("login.password")}</InputLabel>
        <Input
          id="standard-adornment-password"
          type="password"
          onChange={(event) => onChange(event.target.value)}
          error={submitted && !password}
          role="textbox"
        />
      </FormControl>
    </>
  );
};

MfaAuthConfirmForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  message: PropTypes.string
};

export default MfaAuthConfirmForm; // TODO: with translations
