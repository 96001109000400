import { useReducer, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { genericReducer } from "_reducers/general.reducer";

// @mui/material components
import {
  Button
} from "@mui/material";

//components
import CustomModal from "_components/Modal/CustomModal";
import WorkSessionsTable from "_components/Table/WorkSessionsTable";
import WorkSessionDetails from "_containers/Widgets/WorkSessionDetails";

//icons
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/workSessionWidgetStyle.js";
import { lsyRouter } from "_helpers";

const useStyles = makeStyles()(styles);

function WorkSessionWidget(props) {
  const { user, history } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const [workSessionModal, setWorkSessionModal] = useReducer(genericReducer,
    {
      open: false,
      description: null,
      selectedWorkSession: null
    }
  );
  const setWorkSessionModalOpen = (open) => setWorkSessionModal({ open });
  const setSelectedWorkSession = (selectedWorkSession) => {
    if (!ability.can("read", "work_sessions")) {
      return;
    }

    setWorkSessionModal({ selectedWorkSession, open: true });
  };

  const renderWorkSessionDetails = () => {
    return <WorkSessionDetails
      hideTitle={true}
      selectedWorkSession={workSessionModal.selectedWorkSession}
    />;
  };

  const navigateToWorkSessions = () => {
    history.pushWithState(lsyRouter("user_work_sessions", user.membership_id),
      { user: `${user.first_name} ${user.last_name} (${user.email})` });
  };

  return (
    <Fragment>
      <CustomModal
        open={workSessionModal.open}
        setOpen={setWorkSessionModalOpen}
        title={t("label.workSession")}
        type="alert"
        confirm={t("button.close")}
        description={renderWorkSessionDetails()}
        modalStyle={classes.commentModal}
        submit
      />
      <div className={classes.topBar} data-testid="titleSection">
        <span className={classes.title}>{t("label.workSessions")}</span>
        {ability.can("read", "work_sessions") ?
          <Button
            startIcon={<PhonelinkLockOutlinedIcon />}
            sx={{ textTransform: "none" }}
            onClick={navigateToWorkSessions}
          >
            {t("button.seeAllWorkSessions")}
          </Button> : null}
      </div>
      <WorkSessionsTable
        membershipId={user.membership_id}
        preview={true}
        setSelectedWorkSession={setSelectedWorkSession}
        shouldSelectFirstWorkSession={false}
      />
    </Fragment>
  );
}

WorkSessionWidget.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object
};

export default WorkSessionWidget;
