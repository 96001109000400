import { get, post, del, put } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchTag = async (id) => {
  return await get(`${twsWebUrl}/tags/${id}`);
};

const fetchTags = async (options) => {
  return await get(`${twsWebUrl}/tags`, options);
};

const createTag = async ({ data }) => {
  return post(`${twsWebUrl}/tags`, data);
};

const deleteTag = async ({ data }) => {
  return await del(`${twsWebUrl}/tags/${data}`);
};

const deleteTagUser = async ({ data, membership_id }) => {
  return await del(`${twsWebUrl}/users/${membership_id}/tags/${data}`);
};

const deleteTagLock = async ({ data, lock_id }) => {
  return await del(`${twsWebUrl}/locks/${lock_id}/tags/${data}`);
};

const createUserTags = async ({ tag_ids, membership_id }) => {
  return post(`${twsWebUrl}/users/${membership_id}/tags`, {tag:{id:[tag_ids]}});
};
const createLockTags = async ({ tag_ids, lock_id }) => {
  return post(`${twsWebUrl}/locks/${lock_id}/tags`, {tag:{id:[tag_ids]}});
};

const updateTag = async (options) => {
  const payload = {
    name: options.name 
  };
  return await put(`${twsWebUrl}/tags/${options.tag_id}`, payload);
};

export const tagService = {
  fetchTag,
  fetchTags,
  createTag,
  deleteTag,
  deleteTagUser,
  deleteTagLock,
  createUserTags,
  createLockTags,
  updateTag
};