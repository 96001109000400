const lockCardStyle = (theme) => ({
  card: {
    marginTop: theme.spacing(0.65),
    backgroundColor: theme.lsyPalette.secondary.main
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  cardContent: {
    "flexGrow": "1",
    "& p": {
      margin: "0px"
    },
    "&:last-child": {
      paddingBottom: theme.spacing(1)
    }
  },
  remove: {
    marginRight: theme.spacing(3),
    color: theme.lsyPalette.primary.main
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  title: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title1,
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    marginTop: "0px",
    marginBottom: "0px",
    flexGrow: 1
  },
  description: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body1,
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  keyValue: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": theme.lsyPalette.body1,
    "& span": {
      color: theme.lsyPalette.primary.mainDark,
      marginLeft: theme.spacing(1)
    }
  },
  value: {
    color: theme.lsyPalette.primary.mainDark
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  addIcon: {
    color: theme.lsyPalette.secondary.main
  },
  chip: {
    marginRight: "3px"
  },
  chips: {
    display: "inline"
  },
  tags: {
    marginBottom: 0,
    display: "inline",
    color: theme.lsyPalette.primary.mainLight
  },
  chipText: {
    color: theme.lsyPalette.primary.mainDark
  },
  defaultIcon: {
    height: "40px",
    width: "40px",
    marginLeft: "10px"
  },
  locationIcon: {
    transform: "scale(0.7)"
  },
  selected: {
    borderColor: theme.lsyPalette.stdRed,
    transition: "color .8s linear,background-color .8s linear, border-color .8s linear "
  },
  disabled: {
    filter: "opacity(0.5)"
  },
  urgencyDiv: {
    display: "inline",
    flexGrow: "1"
  },
  actionDiv: {
    marginTop: "0px", 
    paddingTop: "0px", 
    paddingBottom: "0px", 
    display: "flex", 
    alignItems: "center" 
  },
  dotIcon: {
    marginRight: "-15px",
    transform: "scale(0.4)"
  },
  low: {
    color: "#fbc106"
  },
  medium: {
    color: "orange"
  },
  high: {
    color: theme.lsyPalette.stdRed
  },
  iconPadding: {
    marginLeft: "6px"
  },
  root: {
    cursor: "pointer",
    width: "100%",
    marginBottom: theme.spacing(0.65)
  },
  field: {
    display: "block",
    color: "gray",
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word"
  },
  titleDiv: {
    display: "flex", 
    alignItems: "center"
  },
  tooltipDiv: {
    width: "fit-content"
  }
});
export default lockCardStyle;
