const recentlyUpdatedStyle = (theme) => ({  
  notificationIconDiv: {
    position: "relative"
  },
  notificationIcon: {
    position: "absolute",
    top: "0px",
    right: "7px",
    color: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.6)"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  }
});

export default recentlyUpdatedStyle;