import TwsResource from "./tws-resource";

export default new TwsResource({
  endpoint: "identity",
  resource: "accounts/names",
  name: "twsUuids",
  requests: {
    query: { method: "GET", isArray: true, url: "accounts/names" }
  }
});
