import { twsWebUrl } from "_services/helper";
import { get, post, put, del } from "_helpers/axios/gw.client";

const fetchUsers = async (options) => {
  return await get(`${twsWebUrl}/users`, options);
};

const fetchUser = async ({id, ...options}) => {
  return await get(`${twsWebUrl}/users/${id}`, options);
};

const addUser = async (options) => {
  return await post(`${twsWebUrl}/users`, options.data);
};

const updateUser = async (options) => {
  const payload = {
    user: { ...options.payload }
  };

  return await put(`${twsWebUrl}/users/${options.membershipId}`, payload);
};

const updatePassword = async (newPassword, currentPassword) => {
  let payload = { password: newPassword };

  if (currentPassword) {
    payload.current_password = currentPassword;
  }

  return await put(`${twsWebUrl}/users/update_password`, payload);
};

const deletePassword = async () => {
  return await del(`${twsWebUrl}/users/password`);
};

const deleteUser = async (options) => {
  return await del(`${twsWebUrl}/users/${options.membershipId}`);
};

const assignSiteKey = async (options) => {
  return await post(`${twsWebUrl}/users/${options.membershipId}/sites/keys`, options.data);
};

const assignGroupKey = async (options) => {
  return await post(`${twsWebUrl}/users/${options.membershipId}/lock_groups/keys`, options.data);
};

const fetchUserDevice = async ({ membershipId }) => {
  return await get(`${twsWebUrl}/users/${membershipId}/devices/latest`);
};

const toggleMembershipState = async ({ membershipId, data }) => {
  return await put(`${twsWebUrl}/users/${membershipId}/toggle_membership_state`, data);
};

const resendInvitation = async ({ membershipId }) => {
  return await post(`${twsWebUrl}/memberships/${membershipId}/invite`, {
    resend_invitation: true // the body for this request doesn't do anything meaningful, it just can't be empty
  });
};

export const userService = {
  fetchUsers,
  fetchUser,
  addUser,
  updateUser,
  deleteUser,
  assignSiteKey,
  assignGroupKey,
  fetchUserDevice,
  updatePassword,
  deletePassword,
  toggleMembershipState,
  resendInvitation
};