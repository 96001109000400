const lockReplacementStyle = (theme) => ({
  button: {
    textTransform: "none",
    fontSize: theme.lsyPalette.body1
  },
  description: {
    color: theme.lsyPalette.primary.mainLight
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textAlignRight: {
    textAlign: "end"
  }
});
  
export default lockReplacementStyle;