import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import Pagination from "@mui/material/Pagination";

export default function CustomPagination(props) {
  const useStyles = makeStyles()((theme) => ({
    root: {
      "justifyContent": "center",
      "display": "flex",
      "background": theme.lsyPalette.secondary.main,
      "borderRadius": "3px",
      "& .MuiPaginationItem-textPrimary, .MuiPaginationItem-ellipsis": { // text color
        color: theme.lsyPalette.text,
        fontSize: theme.lsyPalette.body1
      },
      "& .MuiPaginationItem-textPrimary.Mui-selected": { // selected text colour
        color: theme.lsyPalette.textContrast
      },
      // selected focused colour
      "& .MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible" : {
        backgroundColor: theme.lsyPalette.primary.mainHover
      }
    },
    ul: {
      "& .Mui-selected": {
        "background": theme.lsyPalette.primary.main,
        "margin": "1px",
        "borderRadius": "0px",
        "&:hover": {
          background: theme.lsyPalette.primary.main
        }
      }
    }
  }));
  const { classes } = useStyles();

  const handleChangeCurrentPage = (event, value) => {
    if (value)
      props.setCurrentPage(parseInt(value));
  };

  return (
    <Pagination
      size={props.size}
      shape={props.shape}
      color={props.color || "primary"}
      classes={props.type === "lockstasy" ? {root: classes.root, ul: classes.ul} : null}
      id="custom-pagination"
      data-testid="custom-pagination"
      count={props.totalPages}
      page={props.currentPage}
      siblingCount={props.siblingCount}
      onChange={handleChangeCurrentPage} />
  );
}

CustomPagination.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  color:PropTypes.string,
  type:PropTypes.string,
  shape:PropTypes.string,
  size:PropTypes.string,
  siblingCount: PropTypes.number
};
