import twsCryptos from "./tws-cryptos";
import twsUuids from "./tws-uuids";
import twsTenantLicenses from "./tws-tenantLicenses";
import twsFirmware from "./tws-firmware";
import twsOrganizations from "./tws-organizations";
import twsServers from "./tws-servers";
import twsBatteryTypes from "./tws-batteryTypes";

// ONLY use the TwsResourceFactory for creating and using TwsResources.  Do NOT directly import
// the resource files and use them.
class TwsResourceFactory {
  constructor() {
    this.resources = {};
    this.validResourceClasses = {
      twsCryptos: twsCryptos,
      twsUuids: twsUuids,
      twsTenantLicenses: twsTenantLicenses,
      twsFirmware: twsFirmware,
      twsOrganizations: twsOrganizations,
      twsServers: twsServers,
      twsBatteryTypes: twsBatteryTypes
    };
  }

  /* Options:
   *  avoidPrecache: [] // a list of resources to avoid Precaching for
   */
  createResources = async(resourceNames, options = {}) => {
    const resources = {};

    await Promise.all(resourceNames.map((name) => {
      let buildOptions = {};

      if ((options.avoidPrecache) && options.avoidPrecache.includes(name))
        buildOptions.avoidPrecache = true;

      return this.createResource(name, buildOptions);
    }))
      .then((values) => {
        values.forEach((value) => resources[value.name] = value);
      })
      .catch((error) => {
        console.error(error.message);
      });

    return resources;
  };

  createResource = async(resourceName, options = {}) => {
    if (!Object.keys(this.validResourceClasses).includes(resourceName)) {
      throw new Error(`Invalid TWS resource class name '${resourceName}'`);
    }

    if (!this.resources[resourceName]) {
      this.resources[resourceName] = this.validResourceClasses[resourceName];

      // by default, attempt to load them on init
      if (!options?.avoidPrecache)
        await this.resources[resourceName].query({ refresh: true });
    }
    return this.resources[resourceName];
  };

  getEmptyResource = () => {
    if (this.resources.empty) {
      return this.resources.empty;
    }
    
    this.resources.empty = {
      query: () => ({ data: [] }),
      flushCache: () => {},
      fetchAllFromCache: () => ({ data: [] }),
      fetchAll: () => ({ data: [] }),
      fetchOneFromCache: () => null,
      fetchOne: () => null
    };

    return this.resources.empty;
  };

  /* list the set of resources we currently know about/have successfully fetched */
  listResources = () => Object.keys(this.resources);

  getResource = (resourceName) => {
    const resource = this.resources[resourceName];
    if (!resource) {
      return this.createResource(resourceName);
    }
    return resource;
  };

  getResources = () => {
    return this.resources || {};
  };

  flushResources = () => this.resources = {};
}

export default new TwsResourceFactory();