import { useState, useContext, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { stack as Menu } from "react-burger-menu";
import SlideMenuFactory from "_factories/slideMenuFactory/slideMenuFactory.js";
import styles from "assets/jss/components/slideMenuStyle.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import isMobile from "_utils/mobile-detector";

export const SlideMenuContext = createContext();

// create the provider
export const SlideMenuProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [menuDataState, setMenuDataState] = useState(null);

  return (
    <SlideMenuContext.Provider value={{
      isMenuOpen: menuOpenState,
      menuData: menuDataState,
      closeMenu: () => {
        setMenuDataState(null);
        setMenuOpenState(false);
      },
      toggleMenu: (newMenuData) => {
        if (newMenuData) {
          setMenuDataState(newMenuData);
        } else {
          setMenuDataState(null);
        }
        setMenuOpenState(!menuOpenState);
      },
      updateMenuData: (newMenuData) => {
        setMenuDataState(newMenuData);
      },
      stateChangeHandler: (newState) => {
        if (!newState.isOpen)
          setMenuDataState(null);

        setMenuOpenState(newState.isOpen);
      }
    }}>
      {props.children}
    </SlideMenuContext.Provider>
  );
};

SlideMenuProvider.propTypes = {
  children: PropTypes.node.isRequired
};

/* produces a factory which will dynamically load in content from a container/component */
export const SlideMenu = () => {
  const ctx = useContext(SlideMenuContext);
  const [data, setData] = useState(ctx.menuData);

  useEffect(() => {
    setData(ctx.menuData);
  }, [ctx.menuData]);

  const keyDownHandler = (e) => {
    e = e || window.event;
    if (e.key === "Escape" || e.keyCode === 27) {
      ctx.closeMenu();
    }
  };

  const renderMenu = (menuData) => {
    if (menuData) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <SlideMenuFactory { ... menuData }/>
          </GridItem>
        </GridContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <Menu
      anchororigin={{
        vertical: "top",
        horizontal: "left"
      }}
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      customOnKeyDown={keyDownHandler}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      width={ isMobile() ? "100%" : "475px" }
      right styles={ styles }
    >
      { ctx.isMenuOpen ? renderMenu(data) : null  }
    </Menu>
  );
};
