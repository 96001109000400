/* eslint-disable no-use-before-define */
import { useEffect, useState, createContext, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { websocketService } from "_services/websocket.service";
import { config } from "_configs/server-config";

export const WebSocketDataContext = createContext();

const WEBSOCKET_STATUS = Object.freeze({
  0: { name: "CONNECTING", isClosingOrClosed: false },
  1: { name: "OPEN", isClosingOrClosed: false },
  2: { name: "CLOSING", isClosingOrClosed: true },
  3: { name: "CLOSED", isClosingOrClosed: true }
});

export const WebSocketDataProvider = (props) => {
  const [webSocketActive, setWebSocketActive] = useState(false);
  const [webSocketState, setWebSocketState] = useState("disconnected");
  const socket = useRef(null);
  const attempts = useRef(0);
  const restablishConnection = useRef(true);
  const currentMembership = useSelector(state => state?.memberships?.currentMembership);
  const accountId = useSelector(state => state?.auth?.user?.id);
  const currentMembershipId = currentMembership?.id;
  const {connectionParams, autoConnection} = props;

  const noLongerConnected = () => {
    if (!socket.current) {
      return false;
    }

    const currentStatus = WEBSOCKET_STATUS[socket.current.readyState];

    return currentStatus.isClosingOrClosed;
  };

  const getFormattedMessage = (msg) => {
    const newMsg = {
      ...msg,
      identifier: JSON.stringify(msg.identifier)
    };

    return JSON.stringify(newMsg);
  };

  const fetchTicket = async () => {
    attempts.current++;
    setWebSocketState("connecting");
    try {
      const result = await websocketService.authenticate(currentMembershipId);
      return result.ticket;
    } catch (e) {
      const status = e.response?.status;
      if (status !== 401 && status > 400 && status < 500) {
        if (status === 403) {
          if (attempts.current === 1) {
            initiateSocket();
          } else {
            setTimeout(() => {
              initiateSocket();
            }, 5000);
          }
        } else {
          setTimeout(() => {
            initiateSocket();
          }, 5000);
        }
      }
      console.warn("Warning, failed to authenticate", e);
      setWebSocketState("auth_error");
    }
  };

  const initiateSocket = async () => {
    const ticket = await fetchTicket();
    if (ticket) {
      let query = `${config.s4WSGWURL}/websockets/connect?ticket=${ticket}&account_id=${accountId}`;
      if (connectionParams?.query) {
        query += "&" + Object.keys(connectionParams.query)
          .map(key => `${key}=${encodeURIComponent(connectionParams.query[key])}`)
          .join("&");
      }
      const websocket = new WebSocket(query);

      websocket.onopen = () => {
        const payload = connectionParams?.payload ? connectionParams.payload : {command: "subscribe", identifier: "{\"channel\":\"TenantChannel\"}" };
        try {
          websocket.send(JSON.stringify(payload));
          websocket.send(JSON.stringify({command: "subscribe", identifier: "{\"channel\":\"UserChannel\"}" }));
          socket.current = websocket;
          setWebSocketActive(true);
          setWebSocketState("connected");
        } catch (e) {
          console.warn("Warning, failed to connect to live updates", e);
        }
      };


      websocket.onmessage = (e) => {
        const event = JSON.parse(e.data);
        
        if (event.identifier && event.message) {
          if (event.identifier === "lockdepot") {
            const payload = event.message.body;
            const { type } = payload;
            const windowEvent = new CustomEvent(type, { detail: payload });
            window.dispatchEvent(windowEvent);
          } else {
            const data = event.message.body?.data || event.message.data;
            const [type, action] = data.action.split(".");
            data.action = action;
            const windowEvent = new CustomEvent(type, { detail: data });
            window.dispatchEvent(windowEvent);
          }
        }
      };

      websocket.onclose = () => {
        // Double check: User changed organization or logged out
        if (noLongerConnected() && restablishConnection.current) {
          setWebSocketActive(false);
          setWebSocketState("disconnected");
          socket.current = null;
          if (autoConnection) {
            initiateSocket();
          }
        }
      };

      websocket.onerror = (e) => {
        console.error(e);
      };
    }
  };

  const closeSocket = async (isLogout) => {
    restablishConnection.current = !isLogout;

    if (socket.current) {
      socket.current.close();
    }
  };

  const sendMessage = async(msg) => {
    if (socket.current) {
      socket.current.send(getFormattedMessage(msg));
    } else {
      console.error("websocket not available");
    }
  };

  useEffect(() => {
    if (autoConnection) {
      if (socket.current) {
        sendMessage({ command: "unsubscribe", identifier: { channel: "TenantChannel" } });
        sendMessage({ command: "unsubscribe", identifier: { channel: "UserChannel" } });
        closeSocket();
      } else if (currentMembershipId && !props.testForceClose) {
        try {
          initiateSocket();
        } catch (e) {
          console.warn("Warning, failed to connect to live updates", e);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMembershipId]);

  useEffect(() => {
    return () => {
      sendMessage({ command: "unsubscribe", identifier: { channel: "TenantChannel" } });
      sendMessage({ command: "unsubscribe", identifier: { channel: "UserChannel" } });
      closeSocket(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebSocketDataContext.Provider value={{
      webSocketActive: webSocketActive,
      initiateSocket: initiateSocket,
      closeSocket: closeSocket,
      webSocketState: webSocketState,
      sendMessage : sendMessage
    }}>
      {props.children}
    </WebSocketDataContext.Provider>
  );
};

WebSocketDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  testForceClose: PropTypes.bool,
  connectionParams: PropTypes.object,
  autoConnection: PropTypes.bool
};

WebSocketDataProvider.defaultProps = {
  autoConnection: true
};