import { Fragment } from "react";
import { setTitle } from "_helpers/page-title";

// @mui/material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import ListSubheader from "@mui/material/ListSubheader";

import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import Divider from "_components/Divider/Divider";
import Sera4Icon from "assets/teleporte/Sera4Icon";

//Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lsyLeftDrawerStyle.js";

import PropTypes from "prop-types";

const useStyles = makeStyles()(styles);

function LsyLeftDrawer(props) {
  const { classes, cx } = useStyles();

  const populateListItem = (data, subheading = false) => {
    const feature = data.feature;
    const showLevel = data.showLevel;
    const permission = data.permission;
    let greyedOut = false;
    let show = true;

    const currentlySelected = (props.selectedRoute && (props.selectedRoute === data.route));

    if (showLevel === 0 && !permission) {
      greyedOut = true;
    } else if (( showLevel === 1 && !(props.currentFeatures[feature] && permission))) {
      greyedOut = true;
    } else if (showLevel === 2 && !(props.currentFeatures[feature] && permission)) {
      show = false;
    } else if (showLevel === 3 && !permission) {
      show = false;
    }

    if (!show)
      return;

    let primaryIcon, secondaryIcon;
    let onClick;

    if (data.preImage) {
      switch (data.preImage) {
        case "Sera4Logo":
          primaryIcon = (
            <ListItemIcon style={{ width: 35, height: 35 }}>
              <Sera4Icon animated={false} />
            </ListItemIcon>
          );
          break;
        default:
          break;
      }
    }

    if (data.url) {
      secondaryIcon = (
        <ListItemIcon style={{ transform: "scale(0.7)" }}>
          <OpenInNewOutlinedIcon />
        </ListItemIcon>
      );
      onClick = () => {
        window.open(data.url);
      };
    } else {
      onClick = () => {
        setTitle(data.text);
        props.setSelectedRoute(data.route);
        props.history.push(`${data.route}`);
        if (data.tab !== undefined) {
          props.setCurrentNavTab(data.tab);
        } else {
          props.setCurrentNavTab(-1);
        }
      };
    }

    return (
      <Fragment key={data.text + "key"}>
        <ListItem
          button
          disabled={greyedOut}
          key={data.text}
          onClick={onClick}
          classes={{
            root: cx(
              {
                [classes.listSubheader]: subheading,
                [classes.selectedItem]: currentlySelected
              })
          }}
        >
          {primaryIcon}
          <ListItemText
            disableTypography
            className={cx({ [classes.lsyLeftDrawerText]: true, [classes.soloItem]: !subheading })}
            primary={data.text}
          />
          {secondaryIcon}
        </ListItem>
        { data.divider ? <Divider /> : null}
      </Fragment>
    );
  };

  const drawerList = () => {
    return (props.dropdownData.map((data) => {
      let listContent = [];

      if (data.sectionHeader) {
        if (data.sectionFeatures) {
          const subFeatureMap = data.sectionFeatures.map((el) => {
            return populateListItem(el, true);
          });

          // don't print the section if it's empty
          if (subFeatureMap.every((v) => v == undefined)) {
            return;
          }

          listContent = (
            <li key={`section-${data.text}`} className={classes.listSection}>
              <ul className={classes.listUl}>
                <ListSubheader aria-label="navSectionHeader" id={"nav-header-" + data.text.replace(" ", "-").toLowerCase()}>{data.text}</ListSubheader>
                {subFeatureMap}
              </ul>
            </li>
          );
        }
      } else {
        const content = populateListItem(data);
        if (content)
          listContent.push(populateListItem(data));
      }

      return listContent;
    }));
  };

  return (
    <div
      className={classes.drawer}
      onClick={props.toggleDrawer("left", false)}
      onKeyDown={props.toggleDrawer("left", false)}
    >
      <List className={classes.listRoot} subheader={<li />}>
        {drawerList()}
      </List>
    </div>
  );
}
LsyLeftDrawer.propTypes = {
  org: PropTypes.string,
  toggleDrawer: PropTypes.func,
  history: PropTypes.object,
  setCurrentNavTab: PropTypes.func,
  currentFeatures: PropTypes.object,
  dropdownData: PropTypes.array,
  setSelectedRoute: PropTypes.func.isRequired,
  selectedRoute: PropTypes.string,
  baseUrl: PropTypes.string
};
export default LsyLeftDrawer;
