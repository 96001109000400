import { PropTypes } from "prop-types";
import { useTheme } from "@mui/material/styles";

function Sera4talIcon(props) {
  const { width, height } = props;
  const theme = useTheme();

  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" width={width} height={height}>
    <path
      style={{fill: theme.lsyPalette.stdRed}}
      d="M56.13,46.4h0a7.89,7.89,0,0,0-9.71,5.48h0A7.89,7.89,0,0,0,51.9,61.6h0a7.89,7.89,0,0,0,9.71-5.49h0A7.89,7.89,0,0,0,56.13,46.4Z"
    />
    <path
      style={{fill: theme.lsyPalette.stdRed}}
      d="M63.65,19.32A36,36,0,0,0,22.52,36.61l10.22,5.18A24.52,24.52,0,0,1,60.59,30.35,24.36,24.36,0,0,1,75.28,41.77L85.5,36.59c-.06-.1-.1-.21-.15-.31A35.76,35.76,0,0,0,63.65,19.32Z"
    />
    <path
      style={{fill: theme.lsyPalette.stdRed}}
      d="M54,43.66a10.32,10.32,0,0,1,8.55,4.55L72.82,43A21.76,21.76,0,0,0,54,32.2,21.87,21.87,0,0,0,35.19,43l10.26,5.19A10.37,10.37,0,0,1,54,43.66Z"
    />
    <path
      style={{fill: theme.lsyPalette.primary.mainDark}}
      d="M90,52.74c0-.86-.09-1.73-.19-2.59,0-.16,0-.32-.05-.49a36.39,36.39,0,0,0-3-10.74L76.47,44.1c.11.25.2.51.3.76l.11.27a24.68,24.68,0,0,1,1.18,13.72c-.11.57-.24,1.13-.39,1.7,0,.11-.07.21-.1.32-.16.55-.33,1.1-.53,1.64h0a24.34,24.34,0,0,1-1.68,3.61l-.07.11q-.46.79-1,1.55l-.08.12c-.36.52-.75,1-1.15,1.51s-.68.81-1,1.2l-.46.49c-.37.38-.75.75-1.15,1.11l-.22.19a24.74,24.74,0,0,1-4.54,3.17l-.16.09c-.44.23-.89.45-1.35.65l-.71.31c-.37.16-.75.3-1.13.44s-.75.26-1.13.37l-.85.25c-.6.16-1.21.3-1.83.41l-.25.05q-1.14.2-2.29.29h-.1c-.74.06-1.49.08-2.24.07h-.06c-.71,0-1.43-.06-2.13-.14l-.29,0c-.73-.09-1.46-.2-2.19-.35l-.17,0c-.58-.13-1.15-.28-1.72-.45s-.88-.28-1.32-.44-.67-.23-1-.37-.81-.36-1.22-.55-.68-.32-1-.49-.71-.4-1.07-.61-.71-.42-1-.65-.63-.45-.94-.68-.69-.51-1-.78-.78-.66-1.15-1l-.42-.39q-.65-.64-1.24-1.32a24.53,24.53,0,0,1-5.27-22.8,24.28,24.28,0,0,1,1.2-3.31L21.33,38.94A35.87,35.87,0,0,0,26.6,77.31h0l0,0c.53.63,1.1,1.23,1.68,1.83l.44.45q.86.85,1.78,1.65l.16.15.21.16c.84.7,1.69,1.36,2.57,2l.33.23q1.32.9,2.7,1.67l.41.22q1.36.73,2.76,1.34l.5.21c.93.39,1.87.74,2.83,1l.48.15.91.28c.67.19,1.36.35,2,.49l.18,0c1.07.22,2.16.39,3.24.52l.39,0c1.06.11,2.13.18,3.19.2h0L54,90c.85,0,1.7-.06,2.54-.12l.41,0c1-.08,2-.22,3-.38l.43-.06c.86-.15,1.72-.37,2.57-.58.28-.07.56-.12.84-.2.46-.13.92-.31,1.38-.45A35.81,35.81,0,0,0,90,53.23C90,53.07,90,52.91,90,52.74Z"
    />
  </svg>;
}

Sera4talIcon.defaultProps = {
  width: "35",
  height: "35"
};

Sera4talIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

export default Sera4talIcon;
