/* This file compliments member.actions - if there's a dispatch call, please use that file instead */
import { get, post, put } from "_helpers/axios/gw.client";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";

import {
  identityUrl
} from "_services/helper";

/* funny call, as this one requires ONE membership ID to succeed */
const fetchTenants = async (options) => {
  options.page_size = options.page_size || 1500;
  return get(`${identityUrl}/tenants`, options);
};

const updateLicense = async(payload = {}, id) => {
  return put(`${identityUrl}/tenants/${id}/license`, payload);
};

/* funny call, as this one requires ONE membership ID to succeed */
const fetchTenant = async (tenantId, options = {}) => {
  var additionalArgs = transformToQueryString(sanitizeKeys(options));
  if (additionalArgs.length > 0) {
    additionalArgs = "?".concat(additionalArgs);
  }

  return get(`${identityUrl}/tenants/${tenantId}${additionalArgs}`);
};

const requestTenant = async (payload = {}) => {
  return post(`${identityUrl}/tenants/request_new`, payload);
};


const createTenant = async (payload) => {
  return post(`${identityUrl}/tenants`, payload);
};

export const tenantService = {
  fetchTenant,
  fetchTenants,
  requestTenant,
  updateLicense,
  createTenant
};