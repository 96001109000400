const userStyle = (theme) => ({
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    borderRadius: "3px",
    marginBottom: "25px"
  },
  divider: {
    width: "100%"
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 10px 5%"
  },
  tableWidgetItem: {
    paddingLeft: "3%",
    paddingRight: "3%"
  }
});

export default userStyle;
