/* This file is used for s4 admin purposes */
import { del, get, put, post } from "_helpers/axios/gw.client";

import {
  identityUrl
} from "_services/helper";

const fetchAccounts = async (options) => {
  options.page_size = options.page_size || 1500;
  return get(`${identityUrl}/accounts`, options);
};

const fetchAccount = (uuid) => {
  return get({ url: `${identityUrl}/accounts/${uuid}`});
};

const fetchSera4talAccounts = () => {
  return get({ url: `${identityUrl}/accounts/sera4tal`});
};

const fetchTotalAccounts = () => {
  return get({ url: `${identityUrl}/accounts/count`});
};

const revokeSera4tal = (uuid) => {
  return del(`${identityUrl}/accounts/${uuid}/revokeSera4tal`);
};

const updatePreferences = (options) => {
  return put(`${identityUrl}/accounts/${options.id}/options`, options.data);
};

const update = (account) => {
  return post(`${identityUrl}/accounts/${account.id}`, account);
};

const fetchMemberships = async (tenant_id, options) => {
  options.page_size = options.page_size || 1500;
  return get(`${identityUrl}/memberships?tenant_id=${tenant_id}`, options);
};

export const accountService = {
  fetchAccount,
  fetchAccounts,
  fetchSera4talAccounts,
  fetchTotalAccounts,
  updatePreferences,
  update,
  fetchMemberships,
  revokeSera4tal
};