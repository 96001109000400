import {
  whiteColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import baseStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const rtmStyle = (theme) => ({
  ...baseStyles,
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "0px",
    borderRadius: "2px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem"
  },
  lockIdContainer: {
    display : "flex",
    alignItems: "center"
  },
  lockIcon: {
    height: "110px",
    width: "100px"
  },
  lockDetails: {
    marginBottom: "0px"
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "400px"
  },
  tableIcon: {
    color: theme.palette.text.primary
  },
  boldTitle: {
    marginRight: "15px",
    display: "inline",
    fontWeight: "800"
  },
  content: {
    fontWeight: "300"
  },
  key: {
    fontWeight: "800",
    display: "inline",
    color: theme.palette.text.primary,
    fontSize: theme.lsyPalette.body2
  },
  value: {
    display: "inline-block",
    color: theme.palette.text.primary,
    fontSize: theme.lsyPalette.body2
  },
  configValues: {
    fontFamily: "monospace",
    fontSize: "10px",
    whiteSpace: "pre",
    background: theme.lsyPalette.secondary.background
  },
  rtmMessageBox: {
    padding: 2
  },
  tableName: {
    display: "flex",
    justifyContent: "center",
    padding: 10
  },
  hiddenOverflow: {
    overflow: "hidden"
  },
  collapsedConfigValues: {
    maxHeight : 0
  },
  borderError : {
    borderColor: "red",
    borderStyle: "solid"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  editButton: {
    width: 30,
    height: 30
  },
  editIcon: {
    width: 20,
    height: 20,
    color: theme.palette.text.primary
  },
  keepHistorySwitch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.body1,
    "display": "inline",
    "fontWeight": "800" ,
    "& span": {
      fontWeight: "300"
    }
  },
  chip: {
    marginRight: "5px"
  },
  usageTable : {
    tableLayout: "fixed",
    width: "100%"
  },
  collapsedRowHeader : {
    backgroundColor: theme.lsyPalette.rowOdd
  },
  collapseableTitleBar: {
    backgroundColor: theme.lsyPalette.primary.mainDark,
    color: theme.lsyPalette.textContrast,
    fill: theme.lsyPalette.textContrast,
    border: "none",
    cursor: "pointer",
    padding: 5,
    width: "100%",
    textAlign: "left",
    outline: "none",
    fontSize: 10,
    fontWeight: "bold"
  },
  collapseableFooter: {
    height : 0
  },
  actionButtons : {
    marginTop: "20px"
  },
  noUpgrade: {
    textAlign: "center"
  }
});

export default rtmStyle;
