const workSessionsDetailsStyle = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  placeholderText: {
    fontSize: theme.lsyPalette.body2
  },
  placeholderIcon: {
    fontSize: "38px"
  },
  redColor: {
    color: theme.lsyPalette.stdRed
  },
  redirectIcon: {
    width: "20px",
    height: "20px"
  },
  redirectLink: {
    color: theme.lsyPalette.link,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    width: "fit-content"
  },
  value: {
    fontSize: theme.lsyPalette.body1
  }
});

export default workSessionsDetailsStyle;