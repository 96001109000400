import queryString from "query-string";
import _ from "lodash";

// react hooks don't always have access to this.props history, so we're doing it ourselves
const setQueryStringWithoutPageReload = qsValue => {
  const newurl = window.location.protocol + "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

export const setQueryStringValue = (key, value, qs = window.location.search) => {
  const values = queryString.parse(qs);
  const newQsValue = queryString.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, qs = window.location.search) => {
  const values = queryString.parse(qs);
  return values[key];
};

export const getFilterVars = (filterVars, queryParamString, queryParamVar) => {

  const queryParams = queryString.parse(queryParamString, { arrayFormat: "separator", arrayFormatSeparator: "|" });
  if (queryParamVar) {
    const keys = Object.keys(queryParams);
    keys.forEach((key) => {
      if (key.includes(queryParamVar)) {
        let newKey = key.replace(queryParamVar, "");
        queryParams[newKey] = queryParams[key];
        delete queryParams[key];
      } else {
        delete queryParams[key];
      }
    });
  }

  const extractedFilterVars = {};
  Object.keys(queryParams).forEach((key) => {
    if (_.has(filterVars, key)) {
      extractedFilterVars[key] = queryParams[key];
    }
  });

  return extractedFilterVars;
};

export const clearUrlQuery = (history, location) => {
  history.replace(`${location.pathname}`);
};