import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/swapStyle";

const useStyles = makeStyles()(styles);

function SwapIcon(props) {
  const { cx } = useStyles();
  return (
    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-17b9pbq-MuiSvgIcon-root-chipIcon" width={props.width} height={props.height} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SwapVertIcon">
      <path className={cx({[(props.pathClassName || "")]: props.sortDirection === "DESC"})} d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3 5 3z"></path>
      <path className={cx({[(props.pathClassName || "")]: props.sortDirection === "ASC"})} d="M16 21l4-3zM9 3 5 6.99h3V14h2V6.99h3L9 3z"></path>
    </svg>
  );
}

SwapIcon.defaultProps = {
  sortDirection: "DESC"
};

SwapIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  pathClassName: PropTypes.string,
  sortDirection: PropTypes.oneOf([ "DESC", "ASC" ])
};

export default SwapIcon;
