import { useState, useContext, useEffect, useReducer, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Can } from "@casl/react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lockgroupService } from "_services/lockstasy";
import { fetchErrors } from "_utils";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";

import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import GridItem from "components/Grid/GridItem";
import CardListWidget from "_containers/Widgets/CardListWidget";
import Placeholder from "_components/Helper/Placeholder";
import LockCard from "_components/Lockstasy/LockCard";
import KeyCard from "_components/Lockstasy/KeyCard";
import CustomModal from "_components/Modal/CustomModal";

import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import GenericLock from "assets/teleporte/GenericLock";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockGroupsStyle";

const useStyles = makeStyles()(styles);

function LockGroup(props) {
  const { classes } = useStyles();
  const { reset, setValue, getValues, formState: { errors }, control, clearErrors } = useForm();

  const params = useParams();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();

  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const [wizard, setWizard] = useState({ type: "lock", open: false, key: 0, fetchWidgetData: null });
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: null,
      editModalOpen: false,
      submitting: false,
      existsLockSemaphoreLocked: false
    }
  );

  const setEditModalOpen = (editModalOpen) => setState({ editModalOpen });
  const setExistsLockSemaphoreLocked = existsLockSemaphoreLocked => setState({ existsLockSemaphoreLocked });

  const getLockGroupData = useCallback(async () => {
    try {
      const result = await lockgroupService.fetchLockgroup(params.id);
      if (result) {
        setState({ data: result.data });
      }
    } catch (e) {
      console.warn("Warning, failed to fetch lock group data", e);
    }
  }, [params.id]);

  useEffect(() => {
    getLockGroupData();
  }, [getLockGroupData]);

  const fetchLocks = async (options) => {
    const result = await lockgroupService.fetchLocks({ id: params.id, options});
    return result;
  };

  const fetchKeys = async (options) => {
    const result = await lockgroupService.fetchKeys({ id: params.id, options  });
    return result;
  };

  const skeletonCard = <Card className={classes.card}>
    <div className={classes.lockgroupCardContent}>
      <Skeleton variant="circular" className={classes.lockIcon} width={70} height={70} />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CardContent>
      <Skeleton variant="circular" className={classes.lockgroupDelete} width={70} height={70} />
    </div>
  </Card>;

  const fetchLockData = (fetchWidgetData) => {
    fetchWidgetData();
    getLockGroupData();
  };

  const lockFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset) => {
    setExistsLockSemaphoreLocked(data.some(lock => lock.semaphore_locked));

    return data.map((data) => {
      return (<LockCard
        key={data.id}
        type="lock_group"
        org={props.org}
        data={data}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={() => fetchLockData(fetchWidgetData)}
        setValue={setValue}
        state={state}
        setState={setState}
        reset={reset}
        ability={ability}
      />
      );
    });
  };

  const keyFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset) => {
    const updatePageOrFetchData = async () => {
      if (data.length === 1 && state.totalPages > 1) {
        setState({ currentPage: state.totalPages - 1 });
      } else {
        await fetchWidgetData();
      }
    };

    return data.map((data) => {
      return (<KeyCard
        key={data.id}
        type="lock_group"
        org={props.org}
        data={data}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={updatePageOrFetchData}
        setValue={setValue}
        state={state}
        setState={setState}
        reset={reset}
        ability={ability}
        isDeleteButtonDisabled={state.existsLockSemaphoreLocked}
      />
      );
    });
  };

  const updateLockgroup = useCallback(async (data) => {
    try {
      const options = {
        lock_group: {
          ...data
        }
      };
      await lockgroupService.updateLockgroup({id: params.id, options: options});
      const stateData = {
        ...state.data,
        ...data
      };
      setState({
        data: stateData,
        editModalOpen: false
      });
      dispatch(alertActions.send(t("success.updateLockgroup")));
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors.length > 0) {
        const nameNotUnique = errors.find(o => o.detail === "error.name.not_unique");
        if (nameNotUnique) {
          dispatch(alertActions.send(t("error.existingLockgroup"), "error"));
        } else {
          console.warn("Warning, failed to update lock group", e);
          dispatch(alertActions.send(t("error.updateLockgroup"), "error"));
        }
      } else {
        console.warn("Warning, failed to update lock group", e);
        dispatch(alertActions.send(t("error.updateLockgroup"), "error"));
      }
    }
  }, [params.id, state.data, dispatch, t]);

  const onSubmit = () => {
    const newData = getValues();
    setEditModalOpen(false);
    reset(newData);
    updateLockgroup(newData);
  };

  const getEditOptions = useCallback(() => {
    if (state.data) {
      return [{
        field: "name",
        type: "textField",
        label: t("form.name"),
        required: t("error.requiredField"),
        defaultValue: state.data.name,
        inputProps: { maxLength: 100 },
        placeholder: `${t("label.maxChars", { num: 100 })}`,
        autoFocus: true
      },
      {
        field: "description",
        type: "textField",
        label: t("form.description"),
        defaultValue: state.data.description || "",
        inputProps: { maxLength: 100 },
        placeholder: `${t("label.maxChars", { num: 100 })}`
      }];
    }
    return [];
  }, [state.data, t]);

  const getLocksCustomButtons = () => {
    let buttons = [];

    if (ability.can("update", "lock_groups")) {
      buttons.push({
        label: t("button.addLock"),
        icon: <AddIcon className={classes.icon} />,
        action: (fetchWidgetData) => setWizard({ type: "lockgroupLock", open: true, key: Math.random(), fetchWidgetData: () => fetchLockData(fetchWidgetData) })
      });
    }

    return buttons;
  };

  const getKeysCustomButtons = () => {
    let buttons = [];

    if (ability.can("create", "lock_group_keys")) {
      const isDisabled = state.existsLockSemaphoreLocked || isEmpty(state.data?.lock_ids);

      buttons.push({
        label: state.existsLockSemaphoreLocked ? t("error.lock.locked") : t("button.issueKey"),
        icon: <AddIcon className={isDisabled ? classes.disabledColor : classes.icon} />,
        disabled: isDisabled,
        action: (fetchWidgetData) => setWizard({ type: "lockgroupAssignKey", open: true, key: Math.random(), fetchWidgetData: fetchWidgetData })
      });
    }
    
    return buttons;
  };

  return (
    <div className={classes.lsyBackground}>
      {wizard.open ?
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          setOpen={setWizard}
          fetchWidgetData={wizard.fetchWidgetData}
          org={props.org}
          type={wizard.type}
          lockgroupName={state?.data?.name}
          lockgroupId={params.id}
          maxLocks={state?.data?.max_locks}
          numLocks={state?.data?.lock_ids.length}
        /> : null}
      <Grid container justifyContent="flex-start" className={classes.lockgroupDetailGrid}>
        <Grid item className={classes.lockgroupIconItem} sx={{ display: { xs: "none", sm: "block" } }}>
          <LockGroupIcon className={classes.lockgroupIcon} width="50px" height="50px" />
        </Grid>
        <Grid item className={classes.lockgroupNameItem}>
          <Typography
            variant="h5"
            component="h2"
            role="cardTitle"
            aria-label="lockgroupName"
            data-testid="cardTitle"
            className={classes.lockgroupName}
          >
            {state.data ? state.data.name : ""}
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            role="cardTitle"
            aria-label="lockgroupDescription"
            data-testid="lockgroupDescription"
            className={classes.lockgroupDescription}
          >
            {state.data ? state.data.description : ""}
          </Typography>
        </Grid>
        <Can I="update" on="lock_groups" ability={ability}>
          <GridItem className={classes.settingsItem} sx={{ display: { xs: "none", sm: "block" } }}>
            <IconButton onClick={() => setEditModalOpen(true)} size="large">
              <SettingsIcon fontSize="large" data-testid="settingsIcon" className={classes.editLockgroup} />
            </IconButton>
          </GridItem>
        </Can>
      </Grid>
      <Grid container justifyContent="center">
        <Can I="read" on="locks" ability={ability}>
          <GridItem className={classes.lockgroupWidget} xs={12} md={6}>
            <CardListWidget
              skeletonCard={skeletonCard}
              fallbackData={<Placeholder message={t("fallbacks.noLocksFound")} icon={<GenericLock/>}/>}
              org={props.org}
              paginate
              stats
              title={t("features.locks")}
              rowsPerPage={5}
              secondPaginate={true}
              customButtons={getLocksCustomButtons()}
              fetchData={fetchLocks}
              dataFormatter={lockFormatter}
              appBarSize="sm"
              baseUrl={props.baseUrl}
              history={props.history}
              location={props.location}
            />
          </GridItem>
        </Can>
        <Can I="read" on="keys" ability={ability}>
          <GridItem className={classes.lockgroupWidget} xs={12} md={6}>
            <CardListWidget
              skeletonCard={skeletonCard}
              fallbackData={<Placeholder message={t("fallbacks.noKeysFound")} icon={<VpnKeyIcon/>}/>}
              org={props.org}
              paginate
              stats
              title={t("label.keys")}
              rowsPerPage={5}
              secondPaginate={true}
              customButtons={getKeysCustomButtons()}
              fetchData={fetchKeys}
              dataFormatter={keyFormatter}
              appBarSize="sm"
              baseUrl={props.baseUrl}
              history={props.history}
              location={props.location}
              isDisabledCardButton={state.existsLockSemaphoreLocked}
            />
          </GridItem>
        </Can>
      </Grid>
      <CustomModal
        open={state.editModalOpen}
        setOpen={setEditModalOpen}
        handleSubmit={onSubmit}
        title={t("button.editLockgroup")}
        type="formCreator"
        manualClose
        formOptions={getEditOptions()}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        setValue={setValue}
        modalStyle={classes.modal}
        confirm={t("button.save")}
        submit
        submitting={state.submitting}
      />
    </div>
  );
}

LockGroup.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string
};

export default LockGroup;
