/* Generic component to be used whenever the parent component has no data.
  It receives an icon and a message and if no icon is passed a default is used. */
import { cloneElement } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DefaultIcon from "@mui/icons-material/BrokenImageOutlined";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/placeholderStyle.js";

const useStyles = makeStyles()(styles);

function Placeholder(props) {
  const { classes, cx } = useStyles();
  const { message, classNameMessage, classNameIcon, classNamePlaceholder } = props;
  const Icon = cloneElement(props.icon || <DefaultIcon data-testid="placeholderDefaultIconId"/>, { className: cx(classes.disabledColor, classNameIcon), width: "30px", height: "30px" });

  return (
    <Grid data-testid="placeholderId" container className={cx(classes.placeholder, classNamePlaceholder)} direction="column">
      {props.showIcon ? <Grid item>{Icon}</Grid> : null}
      <Grid data-testid="placeholderMessageId" item>
        {message ? 
          <Typography className={classNameMessage || classes.message}>{message}</Typography> :
          props.children
        }
      </Grid>
    </Grid>
  );
}

Placeholder.defaultProps = {
  showIcon: true
};

Placeholder.propTypes = {
  children: PropTypes.any,
  message: PropTypes.string,
  icon: PropTypes.element,
  showIcon: PropTypes.bool,
  classNameMessage: PropTypes.any,
  classNameIcon: PropTypes.any,
  classNamePlaceholder: PropTypes.any
};

export default Placeholder;