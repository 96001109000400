import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import DeviceHistoryWidget from "_containers/Widgets/DeviceHistoryWidget";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/UserDevicesStyle.js";

const useStyles = makeStyles()(styles);

function UserDeviceHistory(props) {
  const { location, history } = props;

  const { classes } = useStyles();
  const membershipId = useParams().id;
  const deviceId = useParams().deviceId;

  return (
    <div className={classes.body}>
      <Grid item xs={false} md={1}></Grid>
      <Grid item xs={12} md={7} lg={5}>
        <DeviceHistoryWidget
          location={location}
          history={history}
          membershipId={membershipId}
          deviceId={deviceId}
        />
      </Grid>
      <Grid item xs={false} md={1}></Grid>
    </div>
  );
}

UserDeviceHistory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default UserDeviceHistory;