import { Fragment } from "react";
import PropTypes from "prop-types";

/* if you want a slide-in contextual menu, this is the place to define it */
import EditFirmwareBundle from "_containers/Firmware/EditFirmwareBundle";
import EditLock from "_containers/Locks/EditLock";
import EditOrganization from "_containers/Organizations/EditOrganization";
import ShowCrypto from "_containers/Cryptos/ShowCrypto";
import ShowRtm from "_containers/RTM/ShowRtm";
import EditTags from "_containers/Lockstasy/EditTags";

/* required to specify a menuType and auxilliary data */
const SlideMenuFactory = (props) => {
  const { menuType, ...otherProps } = props;

  const buildUI = () => {
    let renderElement = (null);
    switch (menuType) {
      case "tagShow": {
        renderElement = (<EditTags {...otherProps} />);
        break;
      }
      case "lockEdit":
        renderElement = (<EditLock {...otherProps} />);
        break;
      case "orgEdit":
        renderElement = (<EditOrganization {...otherProps} />);
        break;
      case "cryptoShow":
        renderElement = (<ShowCrypto {...otherProps} />);
        break;
      case "rtmShow":
        renderElement = (<ShowRtm {...otherProps} />);
        break;
      case "firmwareBundleEdit":
        renderElement = (<EditFirmwareBundle {...otherProps} />);
        break;
      default:

    }

    return renderElement;
  };

  return (
    <Fragment>
      {buildUI()}
    </Fragment>
  );
};

SlideMenuFactory.propTypes = {
  menuType: PropTypes.oneOf(["tagShow", "lockEdit", "orgEdit", "cryptoShow", "rtmShow", "firmwareBundleEdit"])
};

export default SlideMenuFactory;