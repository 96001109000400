const systemLogsStyle = (theme) => ({
  spaceAround: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  body: {
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`
    },
    padding: `${theme.spacing(3)} 0px`,
    backgroundColor: theme.backgroundColor
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  chip: {
    "margin": `${theme.spacing(0.25)} 0`,
    "marginRight": theme.spacing(0.5),
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& input": {
      width: "150px"
    }
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.subtitle3,
    "display": "inline",
    "fontWeight": theme.lsyPalette.lightWeight,
    "& span": {
      fontWeight: theme.lsyPalette.subtitle3
    }
  },	
  chipIcon: {
    margin: "0",
    width: "20px",
    height: "auto",
    paddingRight: theme.spacing(0.5),
    color: theme.lsyPalette.text
  },
  chartContainer: {
    backgroundColor: theme.backgroundColor,
    position: "sticky",
    top: "5vh",
    width: "100%",
    height: "100%",
    display: "flex",
    borderColor: theme.palette.text.light,
    borderRadius: theme.shape.borderRadius,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: "0",
      width: "100%",
      marginBottom: theme.spacing(0.25),
      height: "200px",
      minHeight: "200px"
    },
    margin: `0 ${theme.spacing(0.25)}`,
    padding: theme.spacing(4),
    minHeight: "60vh"
  },
  systemLogContainer: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(1),
      height: "200px",
      minHeight: "200px"
    }
  },
  chart: {
    width: "100%",
    minHeight: "85vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "50%"
    }
  },
  logItem: {
    display: "flex",
    width: "100%",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.text.light,
    alignItems: "center"
  },
  logTextContainer: {
    width: "100%"
  },
  description: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.palette.text.primary
  },
  red: {
    fill: theme.lsyPalette.stdRed
  },
  time: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.palette.text.light,
    marginLeft: "auto",
    alignSelf: "start"
  },
  icon: {
    margin: "0",
    color: theme.lsyPalette.primary.main,
    marginRight: theme.spacing(1),
    width: "25px",
    height: "25px"
  },
  swapIcon: {
    color: theme.lsyPalette.primary.main,
    margin: "0"
  },
  button: {
    fontSize: theme.lsyPalette.subtitle3,
    textTransform: "none"
  },
  link: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  timePeriod: {
    margin: `${theme.spacing(1)} 0`
  },
  date: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.palette.text.light,
    margin: `0 ${theme.spacing(0.5)}`
  },
  container: {
    maxWidth: "100%",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      maxWidth: "100%",
      alignItems: "center"
    }
  },
  action: {
    "fontSize": theme.lsyPalette.subtitle3,
    "&:hover": {
      cursor: "pointer"
    }
  },
  skeleton: {
    margin: theme.spacing(0.25)
  },
  chartSkeleton: {
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "50vh"
    }
  },
  select: {
    fontSize: theme.lsyPalette.body1
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "marginLeft": "auto",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  eventType: {
    minWidth: `calc(${theme.select.height} * 5)`,
    maxWidth: `calc(${theme.select.height} * 5)`,
    fontSize: theme.lsyPalette.subtitle3
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5)
  },
  eventText: {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(0.15)
  },
  maxSize: {
    maxWidth: "575px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "75vw"
    }
  },
  searchContainer: {
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: theme.lsyPalette.lightWeight
  },
  datePeriod: {
    color: theme.palette.text.light,
    fontSize: theme.lsyPalette.subtitle3
  },
  confirmSelection: {
    border: `${theme.shape.borderRadius} solid ${theme.lsyPalette.secondary.background}`,
    overflow: "auto",
    height: "250px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(1)} 0`,
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(1)} ${theme.spacing(0.15)}`
  },
  eventTypeForm: {
    minWidth: "250px",
    maxWidth: "375px"
  },
  selectionPageDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  selectedNames: {
    maxWidth: "375px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  removeItem: {
    color: theme.lsyPalette.primary.main,
    padding: 0,
    marginLeft: "auto"
  },
  placeholder: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  placeholderIcon: {
    fontSize: "48px",
    margin: "1rem",
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: "16px",
    color: theme.lsyPalette.primary.mainDark
  },
  chartDescription: {
    color: theme.lsyPalette.primary.mainLight
  }
});
export default systemLogsStyle;