import TwsResource from "./tws-resource";

// DO NOT directly import this resource, use a
export default new TwsResource({
  endpoint: "lockdepot",
  resource: "firmwares",
  name: "twsFirmware",
  requests: {
    query: { method: "GET", isArray: true, url: "firmwares" }
  }
});