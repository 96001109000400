const ap3Style = theme => ({
  ap3: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  svg: {
    enableBackground: "new 0 0 672 672"
  }
});

export default ap3Style;
