// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "tss-react/mui";
// @mui/icons-material

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/cardTextStyle.js";

const useStyles = makeStyles()(styles);

export default function CardText(props) {
  const { classes, cx } = useStyles();
  const { className, children, color, ...rest } = props;
  const cardTextClasses = cx({
    [classes.cardText]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });
  return (
    <div className={cardTextClasses} {...rest}>
      {children}
    </div>
  );
}

CardText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  children: PropTypes.node
};
