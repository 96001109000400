const siteIconStyle = theme => ({
  svg: {
    enableBackground: "new 0 0 672 672"
  },
  defaultColor: {
    color: theme.lsyPalette.primary.main
  },
  map: {
    stroke: "currentColor",
    strokeWidth: "18.8764"
  },
  dottedLine: {
    fill: "currentColor"
  },
  pin: {
    stroke: "currentColor",
    strokeWidth: "18.8764",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  },
  point: {
    stroke: "currentColor",
    strokeWidth: "18.8764"
  },
  pointContent: {
    fill: "currentColor"
  }
});
  
export default siteIconStyle;
  