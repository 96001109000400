const dividerStyle = (theme) => ({
  divider: {
    borderColor: theme.lsyPalette.secondary.background
  },
  padding: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  }
});

export default dividerStyle;