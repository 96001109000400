/* This file compliments member.service and uses it for any of it's backend functions
  -- actions are related to dispatches and using redux, so if you need direct access
    to services, then use the memberService class instead
*/
import * as actionTypes from "../actionTypes";
import { reduxConstants } from "_constants";

function setServers(servers) {
  return { type: actionTypes.GLOBAL_SET_SERVERS, globalServers: servers };
}

function storeServerData(data) {
  localStorage.setItem(reduxConstants.SERVER_DATA_LOCATION, JSON.stringify(data));
  return setServers(data);
}

export const globalServerActions = {
  setServers,
  storeServerData
};
