export const genericReducer = (state , newState) => {
  return {...state, ...newState};
};

// reducer which supports back to initial state if needed
export const simpleReducer = (state, action) => {
  if (action.reset) {
    if (typeof(action.reset) === "object")
      state = action.reset;
    else
      delete state[action.field];
  } else if (typeof action.value !== "undefined") {
    state[action.field] = action.value;
  }
  return state;
};