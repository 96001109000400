import { useState, useContext, useReducer, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { userService } from "_services/lockstasy";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual } from "lodash";
import queryString from "query-string";

// @mui/material components
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

//components
import LsyRouter from "_components/Navigation/LsyRouter";
import CustomSkeleton from "_components/Helper/CustomSkeleton";

//icons
import {
  Edit as EditIcon,
  Notifications as NotificationsIcon,
  PhonelinkSetup as PhonelinkSetupIcon
} from "@mui/icons-material";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/userDetailsWidgetStyle.js";
import { lsyRouter } from "_helpers";
import { getUserStatusName, getMfaStatusName, mfaMethods } from "_constants/account.constants";

const useStyles = makeStyles()(styles);

function UserDetailsWidget(props) {
  const { user, history, isRegularUser } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const userGroupMap = lsyAdminDataContext.userGroupMap;
  const [device, setDevice] = useState(null);
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      user: user,
      totalSize: 0,
      modalOpen: false,
      pwModalOpen: false,
      tagModalOpen: false,
      loading: true
    });

  const setLoading = (loading) => setState({ loading });
  const isSelf = currentMembership.id === user.membership_id;
  const canEdit = ability.can("update", "users") || isSelf;
  const canReadDevices = ability.can("read", "users.devices");

  const fetchUserDevice = async () => {
    try {
      const result = await userService.fetchUserDevice({ membershipId: user.membership_id });
      setDevice(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch users current device", e);
    }
    setLoading(false);
  };

  useCustomCompareEffect(() => {
    if (device) {
      setDevice(null);
    }
    if (user?.membership_id) {
      setState({ user });
      if (!user.device_restricted) {
        fetchUserDevice();
      } else {
        setLoading(false);
      }
    }
  }, [user], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getDeviceName = () => {
    const selectedDevice = state.user?.device_restricted ? state.user?.restricted_device : device;
    if (selectedDevice) {
      const { model, name, version } = selectedDevice.platform || { model: "", name: "", version: "" };
      return `${name} ${model} ${version}`;
    } else {
      return t("fallbacks.noDevice");
    }
  };

  const formatValue = (value) => {
    if (value && value.length > 40) {
      return <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={value}
        interactive
      >
        <span>{value.slice(0, 40) + "..."}</span>
      </Tooltip>;
    } else {
      return value;
    }
  };

  const decodeMfaStatus = (mfaMethod) => {
    const disabledState = getMfaStatusName(0);

    if (!state.user?.mfa) {
      return disabledState;
    } else {
      return getMfaStatusName(state.user.auth_options.mfa[mfaMethod]?.state) || disabledState;
    }
  };

  const openUserDevices = () => {
    if (!isRegularUser) {
      history.push(lsyRouter("user_devices", user.membership_id));
    }
  };

  return (
    <Fragment>
      <div className={classes.topBar}>
        <CustomSkeleton loading={state.loading}>
          <span className={classes.name}>{formatValue(state.user?.first_name + " " + state.user?.last_name)}</span>
        </CustomSkeleton>
        {!state.loading && canReadDevices &&
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={t("label.manageDevice")}
          >
            <IconButton className={classes.iconButton} size="small" onClick={openUserDevices}>
              <PhonelinkSetupIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
        {isSelf ?
          <Fragment>
            {!state.loading && !isRegularUser ?
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={t("label.viewNotifications")}
              >
                <IconButton className={classes.iconButton} size="small" onClick={() => history.push(lsyRouter("user_notification", user.membership_id))}>
                  <NotificationsIcon className={classes.icon} />
                </IconButton>
              </Tooltip> : null}
          </Fragment> : null}
        {!state.loading && canEdit ?
          <LsyRouter page="user_edit" id={user.membership_id}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("button.editUser")}
            >
              <IconButton data-testid="editUserButton" className={classes.iconButton} size="small">
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </LsyRouter> : null}
      </div>
      <Divider className={classes.divider} />
      <table className={classes.table}>
        <tbody>
          <tr>
            <td className={classes.field}>
              {t("form.email")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{formatValue(user.email)}</CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {t("login.username", { ns: "auth" })}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{user.username}</CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {t("label.membershipType")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{userGroupMap[state.user?.user_group]}</CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {t("label.status")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{t(getUserStatusName(state.user?.status))}</CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {t("label.mfa")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{t(decodeMfaStatus(mfaMethods[0]))}</CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {state.user?.device_restricted ? t("label.device") : t("label.lastDevice")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>
                {formatValue(getDeviceName())}
              </CustomSkeleton>
            </td>
          </tr>
          <tr>
            <td className={classes.field}>
              {t("label.deviceRestriction")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>
                {state.user?.device_restricted ? t("label.enabled") : t("label.disabled")}
              </CustomSkeleton>
            </td>
          </tr>
          {user?.phone_number && currentMembership.features.collect_phone_numbers && <tr>
            <td className={classes.field}>
              {t("label.phoneNumber")}
            </td>
            <td className={cx({ [classes.value]: state.loading })}>
              <CustomSkeleton loading={state.loading}>{formatValue(user.phone_number)}</CustomSkeleton>
            </td>
          </tr>}
        </tbody>
      </table>
      {state.user?.tags?.length > 0 && ability.can("read", "tags") ?
        <div className={classes.chips}>
          {state.user.tags.map((tag) => {
            return (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                key={tag.id}
                title={`${t("users.showTags")} ${tag.name}`}
              >
                <Chip
                  className={classes.chip}
                  label={tag.name}
                  onClick={() => history.push(`/users?${queryString.stringify({ tag_ids: [tag.id] })}`)}
                  size="small"
                />
              </Tooltip>
            );
          })}
        </div> : null}
    </Fragment>
  );
}

UserDetailsWidget.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  user: PropTypes.object,
  isLoaded: PropTypes.bool,
  isRegularUser: PropTypes.bool
};

export default UserDetailsWidget;