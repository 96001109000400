import { AbilityBuilder, Ability } from "@casl/ability";

export function defineAdminAbilities(role) {
  const { rules, can } = new AbilityBuilder();
  switch (role) {
    case 1:
      can(["manage", "view"], "adminComponent");
      can(["manage", "view"], "orgComponent");
      break;
    case 2:
      can(["manage", "view"], "tapComponent");
      break;
    default:
      break;
  }
  return new Ability(rules);
}
