import { omitBy } from "lodash";

// will assist in updating tables for their data
//   action.index - the table row to find
//   action.targetColumn - the table column to update (if action == update)
//   action.newValue - the value to update a column TO
const reservedReducerColumns = ["action"];

export const tableReducer = (state = [], action) => {
  if (!state || action.action === "clear")
    return [];

  const indexColumn = action.indexColumn || "id";

  if (action.action === "update") {
    const newState = state.map((item) => {
      if (action.index === item[indexColumn]) {
        return { ...item, [action.targetColumn]: action.newValue };
      } else {
        return item;
      }
    });

    return newState;
  } else if (action.action === "add") {
    const sanitizedElement = omitBy(action, function(value, key) {
      return reservedReducerColumns.includes(key);
    });
    state.push(sanitizedElement);
    return state;
  } else if (action.action === "remove") {
    return state.filter((item) => (action.index !== item[indexColumn]));
  } else if (action.action === "replace") {
    // outright replace the state for 1 shot updates
    return action.data;
  } else {
    throw new Error("Unhandled reducer action");
  }
};