import gwClient from "_helpers/axios/gw.client";

import {
  handleResponse,
  pushServiceUrl
} from "_services/helper";

const requestAutoSuportLogs = (accountId) => {
  const requestOptions = {
    method: "POST",
    url: `${pushServiceUrl}/support_notifications/${accountId}/request_logs_collection`
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(r => {
      return r;
    });
};

export const pushService = {
  requestAutoSuportLogs
};