import * as actionTypes from "_actions/actionTypes";
import { updateObject } from "_helpers/utility";
import { reduxConstants } from "_constants";

let globalOrganizations;
try {
  let storedOrgs = JSON.parse(localStorage.getItem(reduxConstants.GLOBAL_ORG_LOCATION));
  globalOrganizations = {};
  if (storedOrgs) {
    storedOrgs.forEach((el) => {
      globalOrganizations[el.id] = {
        ...el
      };
    });
  }
} catch (e) {
  console.debug(e);
}

let globalCryptos;
try {
  let storedCryptos = JSON.parse(localStorage.getItem(reduxConstants.CRYPTO_DATA_LOCATION));
  globalCryptos = {};
  if (storedCryptos) {
    storedCryptos.forEach((el) => {
      globalCryptos[el.id] = {
        ...el
      };
    });
  }
} catch (e) {
  console.debug(e);
}

const initialState = {
  local: {},
  globalCryptos,
  globalOrganizations
};

const orgSetSuccess = (state, action) => {
  let globalOrganizations = {};
  action.globalOrganizations.forEach((el) => {
    globalOrganizations[el.id] = {
      ...el
    };
  });

  return updateObject(state, {
    globalOrganizations
  });
};

const orgSetStart = (state, action) => {
  return updateObject(state, {
    lastUpdatedGlobalOrgs: action.timestamp
  });
};

const orgSetFail = (state) => {
  return state;
};

const cryptoSetSuccess = (state, action) => {
  let globalCryptos = {};
  action.globalCryptos.forEach((el) => {
    globalCryptos[el.id] = {
      ...el
    };
  });

  return updateObject(state, {
    globalCryptos
  });
};

const clearData = () => {
  localStorage.removeItem(reduxConstants.GLOBAL_ORG_LOCATION);
  localStorage.removeItem(reduxConstants.CRYPTO_DATA_LOCATION);

  return {};
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_ORGANIZATIONS_START: return orgSetStart(state, action);
    case actionTypes.GLOBAL_SET_ORGANIZATIONS: return orgSetSuccess(state, action);
    case actionTypes.GLOBAL_SET_CRYPTOS: return cryptoSetSuccess(state, action);
    case actionTypes.GLOBAL_ORGANIZATIONS_FAIL: return orgSetFail(state, action);
    case actionTypes.AUTH_LOGOUT: return clearData(state, action);
    default:
      return state;
  }
};

export default reducer;