/* eslint-disable react/prop-types */
import { Component } from "react";
import { connect } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { alertActions } from "../../_actions";

class Notifier extends Component {
  displayed = [];

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) 
        continue;
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach((notification) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) 
        return;
        // Display snackbar using notistack
      enqueueSnackbar(notification.message, { preventDuplicate: true, ...notification.options });
      // Keep track of snackbars that we have displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = store => ({
  notifications: store.alerts.notifications
});


const mapDispatchToProps = dispatch => {
  return {
    removeSnackbar: (key) => dispatch(alertActions.removeSnackbar(key))
  };
};
// const mapDispatchToProps = dispatch => bindActionCreators({ removeSnackbar }, dispatch);

const connectedNotifier = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifier);

export { connectedNotifier as Notifier };