import { get, post } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const lockReplacementPreview = async (options) => {
  const payload = {
    replace_by: options.replaceBy,
    comments: options.comments
  };

  return await get(`${twsWebUrl}/locks/${options.lockId}/preview_replace`, payload);
};

const lockReplacement = async (options) => {
  const payload = {
    replace_by: options.replaceBy,
    comments: options.comments
  };

  return await post(`${twsWebUrl}/locks/${options.lockId}/replace`, payload);
};

export const lockReplacementService = {
  lockReplacement,
  lockReplacementPreview
};