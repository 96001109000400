import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/lockGroupIconStyle";
const useStyles = makeStyles()(styles);

function LockGroupIconIcon(props) {
  const { classes, cx } = useStyles();
  return (
    <svg onClick={props.onClick || undefined} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 672 672" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve" data-testid="groupIcon">
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M245.3,223.8c0,1.9-1.3,3.8-4,4.5C244,227.6,245.3,225.7,245.3,223.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M149.6,228c0.4,0.1,0.7,0.3,1.2,0.3c0.2,0,0.5-0.1,0.7-0.1C150.7,228.2,150.1,228.2,149.6,228z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M409.6,320.4c0-1.3-0.1-2.6-0.2-3.8l0,0C409.6,317.7,409.6,319,409.6,320.4z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M427.2,226.6c0-0.1-0.1-0.1-0.1-0.2C427.2,226.5,427.2,226.5,427.2,226.6z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M166.5,296.8c0.7,38.8,58.4,38.8,59,0C224.8,258,167.1,258,166.5,296.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M433.1,128.9c0-0.2,0-0.3,0-0.5v-0.9C433.2,128,433.2,128.4,433.1,128.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M430.9,228.1c1.1-0.3,1.8-0.8,2.3-1.6C432.6,227.3,431.8,227.9,430.9,228.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M432.9,133.9c0-1,0-2.1,0.1-3.1c-0.1,0.8-0.1,1.6-0.1,2.3V133.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M154.7,167.5h57.8c2.4-0.7,21.9-0.3,24.8-0.3h-0.1c0.5-1.2-1-33.4,0.7-39.6v0h0c0.2-0.7,0.4-1.1,0.7-1
		c-8-50.1-77.6-49.6-84.9,0.7C155.6,126.4,154.4,166.7,154.7,167.5z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M480.2,219.1h36.6v-42h-21C476.4,178,493.1,203.2,480.2,219.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M426.9,221.5c-0.7,1.4-0.7,3.2,0.1,4.6C426.2,224.8,426.2,223,426.9,221.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M346,358.9c-3,1.4-5.6,3.6-7.3,6.9C340.4,362.9,342.9,360.6,346,358.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M144.4,127.5c7.9-63.4,95.8-63.5,103.7,0h0c0.1,0.3,0.1,0.7,0.1,1h0c0-0.3-0.1-0.7-0.1-1h11.9h0.1
		c-6.1-68.1-96.5-83.5-123.1-19.7c-2.5,6.3-4.1,13-4.6,19.7h-0.1c0,0.3-0.1,0.7-0.1,1h0.1c0.1-0.3,0.1-0.7,0.1-1H144.4z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M205.9,169.9c-3,1.4-5.6,3.6-7.3,6.9C200.3,173.9,202.9,171.6,205.9,169.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M215.8,167.5h-3.3c-2.3,0.4-4.6,1.2-6.6,2.4C208.9,168.5,212.2,167.8,215.8,167.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M151.4,228.1c1.1-0.3,1.8-0.8,2.3-1.6C153.1,227.3,152.3,227.9,151.4,228.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M238.9,127.5c-0.1-0.3-0.1-0.7-0.1-1c0.4-0.4,0.8-0.7,1.3-0.9c-0.5,0.2-0.9,0.5-1.3,0.9
		C238.8,126.9,238.8,127.2,238.9,127.5L238.9,127.5z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M237.3,167.5h-7.2C232.7,167.6,235.1,167.7,237.3,167.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M154.7,219.1v4C154.8,221.7,154.7,220.2,154.7,219.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M147.7,226.6c0-0.1-0.1-0.1-0.1-0.2C147.7,226.5,147.7,226.5,147.7,226.6z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M147.6,221.1C147.6,221.1,147.6,221,147.6,221.1C147.6,221,147.6,221.1,147.6,221.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M147.4,221.5c-0.7,1.4-0.7,3.2,0.1,4.6C146.8,224.8,146.7,223,147.4,221.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M263.2,316.6c1.9-18.3,8.4-33,17.7-44.1V146.6c0-1.8-1.5-3.2-3.2-3.2h-31.1v80.3c0,2.8-2.5,5-5.3,4.6
		c-0.9,0.2-52.9,0-53.9,0.1c-0.4,0.1-0.9,0.1-1.3,0.1h-34.8l36-0.1h-36c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0.1-0.5,0.1-0.7,0.1
		c-2.6,0-4.6-2-4.6-4.6v-80.3v0h-30.5c-1.8,0-3.2,1.5-3.2,3.2v261.1c0,1.8,1.5,3.2,3.2,3.2h121v-75.2
		C236.3,320.1,249.7,315.1,263.2,316.6z M156.7,296.8c0.9-51.7,77.8-51.7,78.7,0C234.5,348.4,157.5,348.4,156.7,296.8z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M196.3,202.9c0.4-6-1.5-21,2.4-26.1h-44v41.7h31.5C190.6,219,196.9,206.7,196.3,202.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M238,127.5L238,127.5C238,127.6,238,127.6,238,127.5L238,127.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M239.2,137.8c0-3.1,0.1-7.2-0.3-10.2c0.2,1.9,0.3,3.8,0.3,5.6V137.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M153.7,127.5L153.7,127.5c0,0.4,0,0.9,0,1.3c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0V127.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M153.4,133.9c0-1,0-2.1,0.1-3.1c-0.1,0.8-0.1,1.6-0.1,2.3V133.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M153.7,128.4L153.7,128.4L153.7,128.4l0,1.1c0-0.6,0-1.1,0-1.5C153.7,128.2,153.7,128.3,153.7,128.4z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M200.7,219.1h36.6v-42h-21C196.9,178,213.6,203.2,200.7,219.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M147.7,125.9c1.4-0.8,3.3-0.8,4.7,0c-0.7-0.4-1.4-0.6-2.3-0.6C149.2,125.2,148.4,125.5,147.7,125.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M433.2,128.4v1.1c0-0.6,0-1.1,0-1.5C433.2,128.2,433.2,128.3,433.2,128.4z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M516.8,167.5h-7.2C512.2,167.6,514.6,167.7,516.8,167.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M375.4,356.6L375.4,356.6l-5.2-0.1C372,356.6,373.7,356.7,375.4,356.6z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M429.1,228c0.4,0.1,0.7,0.3,1.2,0.3c0.2,0,0.5-0.1,0.7-0.1C430.2,228.2,429.6,228.2,429.1,228z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M296.9,407.5h29.3c4.5,0.5,10.7-11.8,10.1-15.6c0.4-6-1.5-21,2.4-26.1h-41.8V407.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M352.6,356.6c2.1-0.6,17-0.4,22.8-0.3v-52.5c-14.7-34.4-64.6-33.9-78.5,1.1v51.7H352.6z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M485.4,169.9c-3,1.4-5.6,3.6-7.3,6.9C479.8,173.9,482.4,171.6,485.4,169.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M518.7,137.8c0-3.1,0.1-7.2-0.3-10.2c0.2,1.9,0.3,3.8,0.3,5.6V137.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M434.2,218.5h31.5c4.5,0.5,10.7-11.8,10.1-15.6c0.4-6-1.5-21,2.4-26.1h-44V218.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M549.1,131.3c0-1.3-0.1-2.6-0.2-3.8h0C549,128.7,549.1,130,549.1,131.3z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M411.8,127.5c0,0.3-0.1,0.7-0.1,1h0.1c0.1-0.3,0.1-0.7,0.1-1H411.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M527.6,127.5L527.6,127.5c0.1,0.3,0.1,0.7,0.1,1h0C527.8,128.2,527.7,127.9,527.6,127.5h12h0.1
		c-6.1-68.1-96.5-83.5-123.1-19.7c-2.5,6.3-4.1,13-4.6,19.7h12C431.8,64.2,519.8,64.1,527.6,127.5z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M269.4,127.5L269.4,127.5c0.1,1.2,0.2,2.5,0.2,3.8C269.6,130,269.5,128.7,269.4,127.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M355.8,356.6h-3.3c-2.3,0.4-4.6,1.2-6.6,2.4C348.9,357.5,352.3,356.9,355.8,356.6z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M518.4,127.5c-0.1-0.3-0.1-0.7-0.1-1c0.4-0.4,0.8-0.7,1.3-0.9c-0.5,0.2-0.9,0.5-1.3,0.9
		C518.3,126.9,518.3,127.2,518.4,127.5L518.4,127.5z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M520.8,228.3c2.7-0.7,4-2.6,4-4.5C524.8,225.7,523.5,227.6,520.8,228.3z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M434.2,219.1v4C434.3,221.7,434.2,220.2,434.2,219.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M294.1,417.4l33.3-0.1L294.1,417.4C294.2,417.4,294.2,417.4,294.1,417.4z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M446,296.8c0.7,38.8,58.4,38.8,59,0C504.3,258,446.6,258,446,296.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M517.5,127.5L517.5,127.5C517.5,127.6,517.5,127.6,517.5,127.5L517.5,127.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M517.5,127.5c0.2-0.7,0.4-1.1,0.7-1c-8-50.1-77.6-49.6-84.9,0.7c1.7-0.8,0.5,39.5,0.9,40.3H492
		c2.4-0.7,21.9-0.3,24.8-0.3h-0.1C517.2,166,515.8,133.8,517.5,127.5L517.5,127.5L517.5,127.5z"/>
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M427.1,221.1C427.1,221.1,427.1,221,427.1,221.1C427.1,221,427.1,221.1,427.1,221.1z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M495.3,167.5H492c-2.3,0.4-4.6,1.2-6.6,2.4C488.4,168.5,491.7,167.8,495.3,167.5z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M296.7,485.8c0.9-51.7,77.8-51.7,78.7,0C374.5,537.5,297.6,537.5,296.7,485.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M375.4,366.2h-19c-19.4,0.9-2.8,26-15.7,42h34.7c0-0.2,0-0.3,0-0.5V366.2z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M427.2,125.9c1.4-0.8,3.3-0.8,4.7,0c-0.7-0.4-1.4-0.6-2.3-0.6C428.7,125.2,427.9,125.5,427.2,125.9z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M306.5,485.8c0.7,38.8,58.4,38.8,59,0C364.9,447,307.1,447,306.5,485.8z" />
      <path className={cx(classes.st0, (props.pathClassName || ""))} d="M520.8,228.3c-0.9,0.2-52.9,0-53.9,0.1c-0.4,0.1-0.9,0.1-1.3,0.1h-34.8l36-0.1h-36c-0.2,0-0.5-0.1-0.7-0.1
		c-0.2,0.1-0.5,0.1-0.7,0.1c-2.6,0-4.6-2-4.6-4.6v-80.3v0h-30.5c-1.8,0-3.2,1.5-3.2,3.2v125.2c9.6,11.1,16.4,26.1,18.3,44.8h8.3
		c10.5,0,19,8.5,19.1,19v75.2h120.3c1.8,0,3.2-1.5,3.2-3.2V146.6c0-1.8-1.5-3.2-3.2-3.2h-31.1v80.3
		C526.1,226.5,523.5,228.7,520.8,228.3z M436.2,296.8c0.9-51.7,77.8-51.7,78.7,0C514,348.4,437,348.4,436.2,296.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M245.3,223.8c0-2.8-2.8-5.6-8-4.7C242.5,218.2,245.3,221,245.3,223.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.5,323c0-1,0-2.1,0.1-3.1c-0.1,0.8-0.1,1.6-0.1,2.3V323z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M292.4,314.9c-0.7-0.4-1.4-0.6-2.3-0.6c-0.9,0-1.7,0.2-2.4,0.7C289.1,314.2,291,314.1,292.4,314.9z" />
      <rect x="237.5" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="0.4" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M291.4,417.2c1.1-0.3,1.8-0.8,2.3-1.6C293.2,416.3,292.4,416.9,291.4,417.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.4,221.5c0.1-0.2,0.1-0.3,0.2-0.5C147.5,221.2,147.4,221.4,147.4,221.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M154.7,223.7V223c-0.1,1.3-0.3,2.5-1,3.5C154.3,225.8,154.7,224.8,154.7,223.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.6,226.4c-0.1-0.1-0.1-0.2-0.2-0.3C147.5,226.2,147.6,226.3,147.6,226.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M239.2,227.5c0.4,0.3,0.8,0.5,1.3,0.6C240,228,239.6,227.8,239.2,227.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M239.2,227.5c-0.4-0.3-0.6-0.6-0.9-0.9C238.6,227,238.9,227.3,239.2,227.5z" />
      <rect x="237.9" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.7,317.4L293.7,317.4l0,1.1c0-0.6,0-1.1,0-1.5C293.8,317.2,293.8,317.3,293.7,317.4L293.7,317.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.8,415.6c0-0.1-0.1-0.1-0.1-0.2C287.7,415.5,287.7,415.6,287.8,415.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.4,137.8v-3.8C153.4,135.3,153.4,136.6,153.4,137.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.7,143.4v-13.9C153.6,134,153.7,143.2,153.7,143.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.7,128.9c-0.1,0.6-0.1,1.3-0.1,1.9C153.6,130.2,153.6,129.5,153.7,128.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M186.2,228.4c0.4,0,0.9,0,1.3-0.1l-36,0.1H186.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M294.7,408.1v4C294.8,410.7,294.7,409.3,294.7,408.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M289.6,417c0.4,0.1,0.7,0.3,1.2,0.3c0.2,0,0.5-0.1,0.7-0.1C290.7,417.3,290.1,417.2,289.6,417z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.7,316.6L293.7,316.6c0,0.4,0,0.9,0,1.3c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0V316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.4,410.6c-0.7,1.4-0.7,3.2,0.1,4.6C286.8,413.8,286.7,412,287.4,410.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M152.3,125.9c0.6,0.3,1.1,0.8,1.5,1.4C153.5,126.6,152.9,126.2,152.3,125.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.7,128.1c0-0.3,0-0.6,0.1-0.8C153.8,127.2,153.8,127.5,153.7,128.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.6,410.1C287.6,410.1,287.7,410.1,287.6,410.1C287.7,410.1,287.6,410.1,287.6,410.1z" />
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="153.7,128.4 153.7,128.4 153.7,128.4 	" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M260.1,127.5h-11.9c0,0.3,0.1,0.7,0.1,1h12C260.2,128.2,260.2,127.9,260.1,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M150.7,228.3c0.2,0,0.5,0.1,0.7,0.1v-0.2C151.2,228.2,150.9,228.3,150.7,228.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M260.3,128.5L260.3,128.5c0-0.3-0.1-0.7-0.1-1h-0.1h-0.1C260.2,127.9,260.2,128.2,260.3,128.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M151.4,219.1c-1.8-0.1-3,0.7-3.7,1.9C148.4,219.9,149.6,219.1,151.4,219.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M132.5,127.5c-0.1,0.3-0.1,0.7-0.1,1h12c0.1-0.3,0.1-0.7,0.1-1H132.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M144.3,128.5L144.3,128.5c0.1-0.3,0.1-0.7,0.2-1h-0.1C144.4,127.9,144.4,128.2,144.3,128.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.7,127.5v0.9c0-0.1,0-0.2,0-0.3c0-0.6,0.1-0.9,0.1-0.8c-0.4-0.6-0.9-1-1.5-1.4c-1.4-0.8-3.3-0.7-4.7,0
		c-0.7,0.4-1.2,1-1.6,1.6H153.7L153.7,127.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M239.2,227.5c-0.4-0.3-0.6-0.6-0.9-0.9c-1.3-2-1.1-5.2-1-7.5h-36.6c-2.8,4.2-7.3,8.7-13.3,9.3
		c0.9-0.1,53,0.1,53.9-0.1c-0.3,0-0.5-0.1-0.8-0.1C240,228,239.6,227.8,239.2,227.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M260.3,128.5h-12h0c0-0.3-0.1-0.7-0.1-1H246c1.1,1.5,0.4,8.5,0.6,10.2h23.1c0-1.9,0-4.2,0-6.5
		c0-1.3-0.1-2.6-0.2-3.8h-9.2C260.2,127.9,260.3,128.2,260.3,128.5L260.3,128.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M146,127.5c-1.1,1.5-0.5,8.5-0.6,10.2C145.6,136,144.9,129,146,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M245.3,223.8c0-2.8-2.8-5.5-8-4.7c-0.1,2.3-0.3,5.5,1,7.5c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.3,0.8,0.5,1.3,0.6
		c0.2,0.1,0.5,0.1,0.8,0.1C244,227.6,245.3,225.7,245.3,223.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M238,143.4v-15.8c-1.7,6.2-0.3,38.4-0.7,39.6h0.1h0.1h0.1v0.3h0.4h0.1v9.3h-0.1h-0.4v0.3h-0.1h-0.1h0v42
		c5.2-0.9,8,1.9,8,4.7c0,1.9-1.3,3.8-4,4.5c2.8,0.5,5.3-1.8,5.3-4.6v-80.3h-0.1H238z"/>
      <rect x="246.5" y="137.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="5.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M246,127.5L246,127.5c1.1,1.5,0.4,8.5,0.5,10.2h0.1C246.4,136,247.1,129,246,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.7,125.9c-0.7,0.4-1.3,0.9-1.6,1.6C146.5,126.9,147,126.3,147.7,125.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M123,137.8h22.5c0.1-1.7-0.5-8.8,0.6-10.2h-1.5c-0.1,0.3-0.1,0.7-0.1,1h-0.1h-12h-0.1c0-0.3,0.1-0.7,0.1-1
		h-9.2C122.9,130.7,123,134.7,123,137.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M269.6,131.3c0,2.2,0,4.5,0,6.5h-23.1v5.6h31.1c1.7,0,3.2,1.4,3.2,3.2v125.9c4.7-5.6,10.1-10.3,16-14V146.6
		c-0.1-10.5-8.6-19-19.1-19h-8.3h-0.1C269.5,128.7,269.6,130,269.6,131.3z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M132.5,127.5c0.5-6.7,2.1-13.4,4.6-19.7c26.6-63.8,117-48.4,123.1,19.7h0.1h9.2h0h0.1
		c-9.5-92.5-136.9-92.5-146.3,0h9.2H132.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M114.9,410.8c-1.7,0-3.2-1.4-3.2-3.2V146.6c0-1.7,1.4-3.2,3.2-3.2h30.5v-5.6H123c0-3.1-0.1-7.1,0.1-10.2h0.1
		c-13.5-1.5-26.9,3.5-27.3,19v261.1c0,10.5,8.5,19,19,19h121v-15.9H114.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M123,137.8c0-3.1-0.1-7.1,0.2-10.2H123C122.9,130.7,123,134.7,123,137.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M156.7,296.8c0.9,51.7,77.8,51.7,78.7,0C234.5,245.1,157.5,245.1,156.7,296.8z M166.5,296.8
		c0.7-38.8,58.4-38.8,59,0C224.8,335.6,167.2,335.6,166.5,296.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.7,143.4c0-0.2-0.1-9.4,0-13.9v-1.1c0,0.2,0,0.3,0,0.5c0,0.6-0.1,1.3-0.1,1.9c0,1-0.1,2.1-0.1,3.1v3.8h-8
		v5.6v0H153.7z"/>
      <rect x="294.7" y="365.8" className={cx(classes.st1, (props.pathClassName || ""))} width="2.1" height="41.7" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M296.9,304.9c-1.4,3.5-2.4,7.3-3,11.4c1.7-0.8,0.5,39.5,0.9,40.3h2.1V304.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M153.4,133.9v-0.8c0-0.8,0-1.5,0.1-2.3c0-0.7,0.1-1.3,0.1-1.9c0-0.5,0-0.9,0-1.3H146
		c-1.1,1.5-0.4,8.5-0.6,10.2h8C153.4,136.6,153.4,135.3,153.4,133.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.7,125.9c0.7-0.4,1.5-0.7,2.4-0.7c0.8,0,1.6,0.2,2.3,0.6c0.6,0.3,1.1,0.8,1.5,1.4
		c7.3-50.2,76.9-50.8,84.9-0.7c0.4-0.4,0.8-0.7,1.3-0.9c0.6-0.3,1.2-0.4,1.9-0.4c1.7,0,3.2,0.9,4,2.3h2.2h0
		c-7.9-63.5-95.8-63.4-103.7,0h0.1h1.5C146.4,126.8,147,126.3,147.7,125.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M205.9,169.9c2-1.1,4.3-1.9,6.6-2.4h-57.8v9.3h44C200.4,173.5,202.9,171.3,205.9,169.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M198.6,176.8h38.7v-9.3c-2.2,0.1-4.6,0.1-7.2,0h-14.3c-3.5,0.3-6.9,1-9.9,2.4
		C202.9,171.6,200.3,173.9,198.6,176.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M198.6,176.8c-3.8,5.1-2,20.1-2.4,26.1c0.6,3.8-5.6,16.1-10.1,15.6h-31.5v0.6h46c12.9-15.9-3.8-41.1,15.7-42
		h21v-0.3H198.6z"/>
      <rect x="237.3" y="176.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0" height="0.3" />
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="237.3,167.5 237.3,176.8 237.3,176.8 237.4,176.8 237.5,176.8 237.5,167.5 237.4,167.5 	" />
      <rect x="237.4" y="176.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M215.8,167.5h14.3C225.5,167.4,220.5,167.1,215.8,167.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.7,226.6c0.4,0.6,1,1.1,1.8,1.4C148.8,227.7,148.2,227.2,147.7,226.6z" />
      <rect x="146.8" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="7.9" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.7,221C147.6,221,147.6,221.1,147.7,221c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1.4-0.6,3.2,0.1,4.6
		c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,1.1,1.1,1.8,1.4c0.5,0.2,1.1,0.2,1.8,0.2v-9.1
		C149.6,219.1,148.4,219.9,147.7,221z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M151.4,228.1c0.9-0.3,1.7-0.9,2.3-1.6c0.6-1,0.9-2.2,1-3.5v-4h-3.3V228.1z" />
      <rect x="151.4" y="218.5" className={cx(classes.st1, (props.pathClassName || ""))} width="3.3" height="0.6" />
      <rect x="237.3" y="176.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M147.7,226.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.4-0.8-3.2-0.1-4.6
		c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0-0.1,0.1-0.1c0.7-1.2,2-2,3.7-1.9v-0.6h3.3v-41.7h-7.9v-9.3h7.9c-0.3-0.9,0.9-41.1-0.9-40.3
		c0,0.3-0.1,0.6-0.1,0.8c0,0.4,0,0.9,0,1.5v13.9h-8.3v80.3c0,2.6,2,4.6,4.6,4.6c0.2,0,0.5,0,0.7-0.1c-0.4-0.1-0.8-0.2-1.2-0.3
		C148.8,227.7,148.2,227.2,147.7,226.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M239.2,137.8h7.3c-0.1-1.7,0.5-8.8-0.6-10.2h-7C239.4,130.6,239.2,134.7,239.2,137.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M239.2,133.2c0-1.9-0.1-3.8-0.3-5.6h-0.1H238c0,0,0,0,0,0v15.8h8.5v-5.6h-7.3V133.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M238,127.5h0.8c-0.1-0.3-0.1-0.7-0.1-1C238.4,126.5,238.2,126.9,238,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M245.9,127.5L245.9,127.5c-0.8-1.4-2.2-2.3-4-2.3c-0.7,0-1.3,0.2-1.9,0.4C242.1,124.8,244.8,125.5,245.9,127.5
		z"/>
      <rect x="237.4" y="167.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="237.3" y="167.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M238.9,127.5h7c-1.1-2-3.8-2.8-5.9-1.9c-0.5,0.2-0.9,0.5-1.3,0.9C238.8,126.9,238.8,127.2,238.9,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M212.5,167.5h3.3c4.7-0.4,9.7-0.1,14.3,0h7.2v-0.3C234.5,167.3,214.9,166.9,212.5,167.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M200.7,219.1h-46c0,1.1,0.1,2.6,0,4v0.7c0,1.1-0.4,2-1,2.8c-0.5,0.8-1.2,1.3-2.3,1.6v0.2h36
		C193.4,227.8,197.9,223.3,200.7,219.1z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M430.2,228.3c0.2,0,0.5,0.1,0.7,0.1v-0.2C430.7,228.2,430.4,228.3,430.2,228.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M478.1,176.8c1.7-3.3,4.2-5.5,7.3-6.9c2-1.1,4.3-1.9,6.6-2.4h-57.8v9.3H478.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378.9,316.6c-0.1-0.3-0.1-0.7-0.1-1c0.4-0.4,0.8-0.7,1.3-0.9c-0.5,0.2-0.9,0.5-1.3,0.9
		C378.8,315.9,378.8,316.2,378.9,316.6L378.9,316.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M379.3,326.8c0-3.1,0.1-7.2-0.3-10.2c0.2,1.9,0.3,3.8,0.3,5.6V326.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M478.1,176.8h38.7v-9.3c-2.2,0.1-4.6,0.1-7.2,0h-14.3c-3.5,0.3-6.9,1-9.9,2.4
		C482.4,171.6,479.8,173.9,478.1,176.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.2,226.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.4-0.8-3.2-0.1-4.6
		c0.1-0.2,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.7-1.2,2-2,3.7-1.9v-0.6h3.3v-41.7h-7.9v-9.3h7.9c-0.3-0.9,0.9-41.1-0.9-40.3
		c0,0.3-0.1,0.6-0.1,0.8c0,0.4,0,0.9,0,1.5v13.9h-8.3v80.3c0,2.6,2,4.6,4.6,4.6c0.2,0,0.5,0,0.7-0.1c-0.4-0.1-0.8-0.2-1.2-0.3
		C428.3,227.7,427.7,227.2,427.2,226.6z"/>
      <rect x="426.3" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="7.9" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M433.2,143.4c0-0.2-0.1-9.4,0-13.9v-1.1c0,0.2,0,0.3,0,0.5c0,0.6-0.1,1.3-0.1,1.9c0,1-0.1,2.1-0.1,3.1v3.8h-8
		v5.6v0H433.2z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M518.7,133.2c0-1.9-0.1-3.8-0.3-5.6h-0.1h-0.8c0,0,0,0,0,0v15.8h8.5v-5.6h0h-7.3V133.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M521.4,125.2c-0.7,0-1.3,0.2-1.9,0.4c2.1-0.9,4.8-0.1,5.9,1.9h0.1l0,0C524.6,126.2,523.2,125.2,521.4,125.2z"
      />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M518.7,137.8h7.3c-0.1-1.7,0.5-8.8-0.6-10.2h-7C518.9,130.6,518.7,134.7,518.7,137.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M519.5,125.7c-0.5,0.2-0.9,0.5-1.3,0.9c0.1,0.3,0.1,0.7,0.1,1h7C524.3,125.5,521.6,124.8,519.5,125.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M425.5,127.5c-1.1,1.5-0.5,8.5-0.6,10.2C425.1,136,424.4,129,425.5,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M432.9,133.9v-0.8c0-0.8,0-1.5,0.1-2.3c0-0.7,0.1-1.3,0.1-1.9c0-0.5,0-0.9,0-1.3h-7.7
		c-1.1,1.5-0.4,8.5-0.6,10.2h8C432.9,136.6,432.9,135.3,432.9,133.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M412,127.5c-0.1,0.3-0.1,0.7-0.1,1h12c0.1-0.3,0.1-0.7,0.1-1H412z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M423.8,128.5L423.8,128.5c0.1-0.3,0.1-0.7,0.2-1h-0.1C423.9,127.9,423.9,128.2,423.8,128.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M539.8,128.5c0-0.3-0.1-0.7-0.1-1h-0.1h-0.1C539.7,127.9,539.7,128.2,539.8,128.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M433.2,128.4c0-0.1,0-0.2,0-0.3c0-0.6,0.1-0.9,0.1-0.8c-0.4-0.6-0.9-1-1.5-1.4c-1.4-0.8-3.3-0.7-4.7,0
		c-0.7,0.4-1.2,1-1.6,1.6h7.7V128.4z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.2,125.9c-0.7,0.4-1.3,0.9-1.6,1.6C425.9,126.9,426.5,126.3,427.2,125.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.2,125.9c0.7-0.4,1.5-0.7,2.4-0.7c0.8,0,1.6,0.2,2.3,0.6c0.6,0.3,1.1,0.8,1.5,1.4
		c7.3-50.2,76.9-50.8,84.9-0.7c0.4-0.4,0.8-0.7,1.3-0.9c0.6-0.3,1.2-0.4,1.9-0.4c1.7,0,3.2,0.9,4,2.3h2.2l0,0
		c-7.9-63.5-95.8-63.4-103.7,0h0.1h1.5C425.9,126.8,426.5,126.3,427.2,125.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M517.8,226.6c0.2,0.4,0.5,0.7,0.9,0.9C518.4,227.3,518.1,227,517.8,226.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M478.1,176.8c-3.8,5.1-2,20.1-2.4,26.1c0.6,3.8-5.6,16.1-10.1,15.6h-31.5v0.6h46c12.9-15.9-3.8-41.1,15.7-42
		h21v-0.3H478.1z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M466.9,228.3c0.9-0.1,53,0.1,53.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.5-0.1-0.9-0.3-1.3-0.6
		c-0.4-0.3-0.6-0.6-0.9-0.9c-1.3-2-1.1-5.2-1-7.5h-36.6C477.3,223.3,472.9,227.8,466.9,228.3z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M524.8,223.8c0-2.8-2.8-5.6-8-4.7C522,218.2,524.8,221,524.8,223.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M492,167.5h3.3c4.7-0.4,9.7-0.1,14.3,0h7.2v-0.3C514,167.3,494.4,166.9,492,167.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M520,228.1c-0.5-0.1-0.9-0.3-1.3-0.6C519.1,227.8,519.5,228,520,228.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M480.2,219.1h-46c0,1.1,0.1,2.6,0,4v0.7c0,1.1-0.4,2-1,2.8c-0.5,0.8-1.2,1.3-2.3,1.6v0.2h36
		C472.9,227.8,477.3,223.3,480.2,219.1z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M517.8,226.6c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.3,0.8,0.5,1.3,0.6c0.2,0.1,0.5,0.1,0.8,0.1c2.7-0.7,4-2.6,4-4.5
		c0-2.8-2.8-5.5-8-4.7C516.7,221.4,516.5,224.6,517.8,226.6z"/>
      <rect x="516.8" y="167.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="516.9" y="167.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="516.9" y="176.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="516.8,167.5 516.8,176.8 516.9,176.8 517,176.8 517,167.5 516.9,167.5 	" />
      <rect x="516.8" y="176.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M402.5,137.8h22.5c0.1-1.7-0.5-8.8,0.6-10.2H424c-0.1,0.3-0.1,0.7-0.1,1h-0.1h-12h-0.1c0-0.3,0.1-0.7,0.1-1
		h-9.2C402.4,130.7,402.5,134.7,402.5,137.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M433.2,143.4v-13.9C433.1,134,433.2,143.2,433.2,143.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M517.5,143.4v-15.8c-1.7,6.2-0.3,38.4-0.7,39.6h0.1h0.1h0.1v0.3h0.4h0.1v9.3h-0.1H517v0.3h-0.1h-0.1v42
		c5.2-0.9,8,1.9,8,4.7c0,1.9-1.3,3.8-4,4.5c2.8,0.5,5.3-1.8,5.3-4.6v-80.3H526H517.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M436.2,296.8c0.9,51.7,77.8,51.7,78.7,0C514,245.1,437,245.1,436.2,296.8z M446,296.8c0.7-38.8,58.4-38.8,59,0
		C504.3,335.6,446.7,335.6,446,296.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M465.6,228.4c0.4,0,0.9,0,1.3-0.1l-36,0.1H465.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M433.2,128.1c0-0.3,0-0.6,0.1-0.8C433.3,127.2,433.3,127.5,433.2,128.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M385.3,412.8c0,1.9-1.3,3.8-4,4.5C384,416.6,385.3,414.7,385.3,412.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M433.1,128.9c-0.1,0.6-0.1,1.3-0.1,1.9C433.1,130.2,433.1,129.5,433.1,128.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M432.9,137.8v-3.8C432.9,135.3,432.9,136.6,432.9,137.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M557.3,127.5H549h-0.1c0.1,1.2,0.2,2.5,0.2,3.8c0,2.2,0,4.5,0,6.5h-23.1v5.6h31.1c1.7,0,3.2,1.4,3.2,3.2v261.1
		c0,1.7-1.4,3.2-3.2,3.2H436.9v15.9h120.4c10.5,0,19-8.5,19-19V146.6C576.3,136.1,567.8,127.5,557.3,127.5z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M402.5,137.8c0-3.1-0.1-7.1,0.2-10.2h-0.1C402.4,130.7,402.5,134.7,402.5,137.8z" />
      <rect x="517" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="0.4" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M391.2,271.8V146.6c0-1.7,1.4-3.2,3.2-3.2h30.5v-5.6h-22.5c0-3.1-0.1-7.1,0.1-10.2h0.1
		c-13.5-1.5-26.9,3.5-27.3,19v111.6C381.2,261.8,386.5,266.4,391.2,271.8z"/>
      <rect x="526" y="137.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="5.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M431.8,125.9c0.6,0.3,1.1,0.8,1.5,1.4C433,126.6,432.4,126.2,431.8,125.9z" />
      <rect x="517.4" y="167.5" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M539.7,127.5c0,0.3,0.1,0.7,0.1,1h-12h0c0-0.3-0.1-0.7-0.1-1h-2.2l0,0c1.1,1.5,0.4,8.5,0.6,10.2h23.1
		c0-1.9,0-4.2,0-6.5c0-1.3-0.1-2.6-0.2-3.8H539.7z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M525.5,127.5L525.5,127.5c1.1,1.5,0.4,8.5,0.5,10.2h0h0.1C525.9,136,526.6,129,525.5,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M412,127.5c0.5-6.7,2.1-13.4,4.6-19.7c26.6-63.8,117-48.4,123.1,19.7h0.1h9.2h0h0.1
		c-9.5-92.5-136.9-92.5-146.3,0h9.2H412z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M527.7,127.5c0,0.3,0.1,0.7,0.1,1h12c-0.1-0.3-0.1-0.7-0.2-1H527.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M426.9,221.5c0.1-0.2,0.1-0.3,0.2-0.4C427,221.2,426.9,221.4,426.9,221.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378,316.6L378,316.6C378,316.6,378,316.6,378,316.6L378,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.1,226.4c-0.1-0.1-0.1-0.2-0.2-0.3C427,226.2,427.1,226.3,427.1,226.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M430.9,228.1c0.9-0.3,1.8-0.9,2.3-1.6c0.6-1,0.9-2.2,1-3.5v-4h-3.3V228.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M434.2,223.7V223c-0.1,1.3-0.3,2.5-1,3.5C433.8,225.8,434.2,224.8,434.2,223.7z" />
      <rect x="430.9" y="218.5" className={cx(classes.st1, (props.pathClassName || ""))} width="3.3" height="0.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.1,221C427.1,221,427.1,221.1,427.1,221c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1.4-0.6,3.2,0.1,4.6
		c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,1.1,1.1,1.8,1.4c0.5,0.2,1.1,0.2,1.8,0.2v-9.1
		C429.1,219.1,427.9,219.9,427.1,221z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M375.4,407.7c0,0.2,0,0.3,0,0.5h2v-42h-2V407.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M377.3,356.6h-2v0.1C376,356.6,376.7,356.6,377.3,356.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M495.3,167.5h14.3C505,167.4,500,167.1,495.3,167.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M427.2,226.6c0.4,0.6,1,1.1,1.8,1.4C428.3,227.7,427.7,227.2,427.2,226.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378,316.6c0.2-0.7,0.4-1.1,0.7-1c-0.7-4.3-1.8-8.3-3.4-11.8v52.5c0.9,0,1.6,0,2,0h-0.1
		C377.8,355,376.3,322.9,378,316.6L378,316.6L378,316.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M430.9,219.1c-1.8-0.1-3,0.7-3.7,1.9C427.9,219.9,429.1,219.1,430.9,219.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M517.5,127.5h0.8c-0.1-0.3-0.1-0.7-0.1-1C517.9,126.5,517.7,126.9,517.5,127.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M294.1,417.4h32c0.4,0,0.9,0,1.3-0.1L294.1,417.4C294.1,417.4,294.1,417.4,294.1,417.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M352.6,356.6h3.3c4.7-0.4,9.7-0.1,14.3,0h5.2v-0.3C369.5,356.2,354.6,356,352.6,356.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M420.9,596.7c0,1.7-1.4,3.2-3.2,3.2H254.9c-1.7,0-3.2-1.4-3.2-3.2v-170h-15.9v170c0,10.5,8.5,19,19,19h162.9
		c10.5,0,19-8.5,19-19v-170h-16V596.7z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M370.1,356.6h-14.3c-3.5,0.3-6.9,1-9.9,2.4c-3,1.7-5.6,4-7.3,6.9h36.7v-9.2C373.7,356.7,372,356.6,370.1,356.6
		z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M355.8,356.6h14.3C365.6,356.4,360.6,356.2,355.8,356.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M356.4,366.2h19v-0.3h-36.7c-3.8,5.1-2,20.1-2.4,26.1c0.6,3.8-5.6,16.1-10.1,15.6h-29.3v0.1c0,0.2,0,0.3,0,0.5
		h43.9C353.6,392.2,337,367,356.4,366.2z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M375.4,269.7v-11.5c-23.7-14.8-54.9-14.7-78.5,0.3v11.4C319.9,251.3,352.8,252.5,375.4,269.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M272.4,317.6L272.4,317.6c-0.1-0.3,0-0.7,0-1h-9.2c-0.3,3.1-0.2,7.1-0.2,10.2h17.9v-9.3H272.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M400.3,317.6c0-0.3-0.1-0.7-0.1-1h-0.1h-0.1C400.3,316.9,400.3,317.2,400.3,317.6L400.3,317.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M340.7,408.1h-43.9c-0.1,3.4-1,6.5-2.7,9.3h33.3C333.4,416.8,337.9,412.3,340.7,408.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M340.7,408.1c-2.8,4.2-7.3,8.7-13.3,9.3c0.8-0.1,39.1,0.1,50.6,0c-1.6-2.7-2.6-5.9-2.7-9.3H340.7z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M400.3,317.6c-0.1-0.3-0.1-0.7-0.2-1h-8.9v1H400.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M375.4,303.8v-17.4c-20.3-23.4-58.5-23.2-78.5,0.5v17.9C310.7,269.9,360.6,269.4,375.4,303.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M280.9,316.6h-8.4c-0.1,0.3-0.1,0.7-0.1,1h8.5V316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M263.2,316.6h-0.1c-0.1,3.1-0.1,7.1-0.1,10.2C263,323.7,262.9,319.7,263.2,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M400.3,316.6c0,0.3,0.1,0.7,0.1,1l0,0h-9.1v9.3h18.4c0-1.9,0-4.2,0-6.5c0-1.3-0.1-2.6-0.2-3.8H400.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M296.7,485.8c0.9,51.7,77.8,51.7,78.7,0C374.5,434.1,297.6,434.1,296.7,485.8z M306.5,485.8
		c0.7-38.8,58.4-38.8,59,0C364.9,524.6,307.2,524.6,306.5,485.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M254.9,332.4h25.9v-5.6H263c0-3.1-0.1-7.1,0.1-10.2h0.1c-13.5-1.5-26.9,3.5-27.3,19v75.2h15.9v-75.2
		C251.8,333.9,253.1,332.4,254.9,332.4z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M417.8,316.6h-8.3h-0.1c0.1,1.2,0.2,2.5,0.2,3.8c0,2.2,0,4.5,0,6.5h-18.4v5.6h26.5c1.7,0,3.2,1.4,3.2,3.2v75.2
		h16v-75.2C436.8,325.1,428.3,316.6,417.8,316.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M272.4,316.6h0.1c0.5-6.7,2.1-13.4,4.6-19.7c1.1-2.7,2.4-5.3,3.7-7.7v-16.7c-9.3,11.1-15.8,25.7-17.7,44.1
		H272.4z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M400.3,316.6h9.2l0,0h0.1c-1.9-18.7-8.7-33.7-18.3-44.8v15.9C396,295.9,399.2,305.5,400.3,316.6L400.3,316.6z"
      />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M346,358.9c2-1.1,4.3-1.9,6.6-2.4h-55.7v9.3h41.8C340.4,362.5,342.9,360.3,346,358.9z" />
      <rect x="280.9" y="326.8" className={cx(classes.st1, (props.pathClassName || ""))} width="4.6" height="5.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M292.4,314.9c-1.4-0.8-3.3-0.7-4.7,0c-0.7,0.4-1.2,1-1.6,1.6h7.7h0v0.9c0-0.1,0-0.2,0-0.3
		c0-0.6,0.1-0.9,0.1-0.8C293.5,315.7,293,315.2,292.4,314.9z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M292.4,314.9c0.6,0.3,1.1,0.8,1.5,1.4C293.5,315.7,293,315.2,292.4,314.9z" />
      <rect x="294.7" y="356.6" className={cx(classes.st1, (props.pathClassName || ""))} width="2.1" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.8,317.1c0-0.3,0-0.6,0.1-0.8C293.8,316.2,293.8,316.5,293.8,317.1z" />
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="293.7,317.4 293.7,317.4 293.7,317.4 	" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M296.9,407.5h-2.1v0.6h2.1c0-0.2,0-0.3,0-0.5V407.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M286.1,316.6c-1.1,1.5-0.5,8.5-0.6,10.2C285.7,325,284.9,318,286.1,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.7,314.9c-0.7,0.4-1.3,0.9-1.6,1.6C286.5,315.9,287,315.3,287.7,314.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M286.1,316.6c-1.1,1.5-0.4,8.5-0.6,10.2h8c0-1.1,0-2.5,0-3.8v-0.8c0-0.8,0-1.5,0.1-2.3c0-0.7,0.1-1.3,0.1-1.9
		c0-0.5,0-0.9,0-1.3H286.1z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.5,326.8V323C293.5,324.3,293.5,325.7,293.5,326.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M284.4,317.6c0-0.3,0.1-0.7,0.1-1h-0.1C284.4,316.9,284.4,317.2,284.4,317.6L284.4,317.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M286.1,316.6h-1.5c-0.1,0.3-0.1,0.7-0.1,1h-0.1h-3.5v9.3h4.6C285.6,325.1,284.9,318,286.1,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M284.3,317.6c0.1-0.3,0.1-0.7,0.1-1h-3.6v1H284.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M286.1,316.6c0.4-0.7,1-1.3,1.6-1.6c0.7-0.4,1.5-0.7,2.4-0.7c0.8,0,1.6,0.2,2.3,0.6c0.6,0.3,1.1,0.8,1.5,1.4
		c0.6-4.1,1.6-7.9,3-11.4v-17.9c-6.3,7.5-10.9,17.4-12.4,29.6h0.1H286.1z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.7,318.6v-1.1c0,0.2,0,0.3,0,0.5c0,0.6-0.1,1.3-0.1,1.9c0,1-0.1,2.1-0.1,3.1v3.8h-8v5.6v0h8.3
		C293.7,332.2,293.7,323,293.7,318.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M294.7,408.1c0,1.1,0.1,2.6,0,4v0.7c0,1.1-0.4,2-1,2.8c-0.5,0.8-1.2,1.3-2.3,1.6v0.2h2.8
		c1.6-2.7,2.6-5.9,2.7-9.3H294.7z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M290.8,417.3c0.2,0,0.5,0.1,0.7,0.1v-0.2C291.2,417.2,291,417.3,290.8,417.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.8,415.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.4-0.8-3.2-0.1-4.6
		c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0-0.1,0.1-0.1c0.7-1.2,2-2,3.7-1.9v-0.6h3.3v-41.7h-7.9v-9.3h7.9c-0.3-0.9,0.9-41.1-0.9-40.3
		c0,0.3-0.1,0.6-0.1,0.8c0,0.4,0,0.9,0,1.5v13.9h-8.3v80.3c0,2.6,2,4.6,4.6,4.6c0.2,0,0.5,0,0.7-0.1c-0.4-0.1-0.8-0.2-1.2-0.3
		C288.8,416.7,288.2,416.3,287.8,415.6z"/>
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="291.4,417.4 294.1,417.4 294.1,417.4 	" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M291.4,408.1c-1.8-0.1-3,0.7-3.7,1.9C288.4,408.9,289.7,408.1,291.4,408.1z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.4,410.6c0.1-0.2,0.1-0.3,0.2-0.5C287.5,410.3,287.5,410.4,287.4,410.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.7,317.9c-0.1,0.6-0.1,1.3-0.1,1.9C293.6,319.2,293.6,318.6,293.7,317.9z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.7,415.5c-0.1-0.1-0.1-0.2-0.2-0.3C287.5,415.3,287.6,415.4,287.7,415.5z" />
      <rect x="235.9" y="410.8" className={cx(classes.st1, (props.pathClassName || ""))} width="15.9" height="15.9" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M280.9,272.5v16.7c4.4-7.9,9.8-14.3,16-19.2v-11.4C291,262.3,285.6,266.9,280.9,272.5z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M294.7,412.8v-0.7c-0.1,1.3-0.3,2.5-1,3.5C294.4,414.8,294.7,413.8,294.7,412.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M293.7,332.4v-13.9C293.7,323,293.7,332.2,293.7,332.4z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M291.4,417.2c0.9-0.3,1.7-0.9,2.3-1.6c0.6-1,0.9-2.2,1-3.5v-4h-3.3V417.2z" />
      <rect x="286.8" y="356.6" className={cx(classes.st1, (props.pathClassName || ""))} width="7.9" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.7,410C287.7,410.1,287.6,410.1,287.7,410c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1.4-0.6,3.2,0.1,4.6
		c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,1.1,1.1,1.8,1.4c0.5,0.2,1.1,0.2,1.8,0.2v-9.1
		C289.7,408.1,288.4,408.9,287.7,410z"/>
      <rect x="291.4" y="407.5" className={cx(classes.st1, (props.pathClassName || ""))} width="3.3" height="0.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M287.8,415.6c0.4,0.6,1,1.1,1.8,1.4C288.8,416.7,288.2,416.2,287.8,415.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M391.2,287.7v-15.9c-4.7-5.4-10-9.9-15.9-13.6v11.5C381.6,274.5,387,280.5,391.2,287.7z" />
      <rect x="386.5" y="326.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="5.6" />
      <rect x="420.9" y="410.8" className={cx(classes.st1, (props.pathClassName || ""))} width="16" height="15.9" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378.7,315.6c0.4-0.4,0.8-0.7,1.3-0.9c0.6-0.3,1.2-0.4,1.9-0.4c1.7,0,3.2,0.9,4,2.3h2.2h0
		c-1.6-12.5-6.2-22.6-12.8-30.2v17.4C376.9,307.3,378,311.3,378.7,315.6z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M385.3,412.8c0-2.8-2.8-5.5-8-4.7c-0.1,2.3-0.3,5.5,1,7.5c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.3,0.8,0.5,1.3,0.6
		c0.2,0.1,0.5,0.1,0.8,0.1C384,416.6,385.3,414.7,385.3,412.8z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378,332.4v-15.8c-1.7,6.2-0.3,38.4-0.7,39.6h0.1h0.1h0.1v0.3h0.4h0.1v9.3h-0.1h-0.4v0.3h-0.1h-0.1h0v42
		c5.2-0.9,8,1.9,8,4.7c0,1.9-1.3,3.8-4,4.5c2.8,0.5,5.3-1.8,5.3-4.6v-80.3h-0.1H378z"/>
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M379.3,416.6c-0.4-0.3-0.6-0.6-0.9-0.9c-1.3-2-1.1-5.2-1-7.5h-2c0.1,3.4,1,6.5,2.7,9.3c1.9,0,3.1,0,3.3-0.1
		c-0.3,0-0.5-0.1-0.8-0.1C380,417,379.6,416.8,379.3,416.6z"/>
      <rect x="386.6" y="326.8" className={cx(classes.st1, (props.pathClassName || ""))} width="4.6" height="5.6" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M388.3,317.6L388.3,317.6c0-0.3-0.1-0.7-0.1-1H386c1.1,1.5,0.4,8.5,0.6,10.2h4.6v-9.3H388.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M382,314.3c-0.7,0-1.3,0.2-1.9,0.4c2.1-0.9,4.8-0.1,5.9,1.9h0.1C385.1,315.2,383.7,314.3,382,314.3z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M379.3,416.6c-0.4-0.3-0.6-0.6-0.9-0.9C378.6,416,378.9,416.3,379.3,416.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378.9,316.6h7c-1.1-2-3.8-2.8-5.9-1.9c-0.5,0.2-0.9,0.5-1.3,0.9C378.9,315.9,378.9,316.2,378.9,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M375.4,365.8h2v-9.3c-0.6,0-1.3,0.1-2,0.1V365.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M378,316.6h0.8c-0.1-0.3-0.1-0.7-0.1-1C378.4,315.5,378.2,315.9,378,316.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M379.3,416.6c0.4,0.3,0.8,0.5,1.3,0.6C380,417,379.6,416.8,379.3,416.6z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M391.2,316.6h-3c0,0.3,0.1,0.7,0.1,1h2.9V316.6z" />
      <rect x="377.9" y="356.6" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M385.3,412.8c0-2.8-2.8-5.6-8-4.7C382.6,407.3,385.3,410,385.3,412.8z" />
      <rect x="375.4" y="365.8" className={cx(classes.st1, (props.pathClassName || ""))} width="2" height="0.3" />
      <rect x="377.5" y="356.6" className={cx(classes.st1, (props.pathClassName || ""))} width="0.4" height="9.3" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M375.4,356.2v0.3h2v-0.3C377,356.2,376.3,356.2,375.4,356.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M379.3,322.2c0-1.9-0.1-3.8-0.3-5.6h-0.1H378c0,0,0,0,0,0v15.8h8.5v-5.6h-7.3V322.2z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M386.5,326.8L386.5,326.8c-0.1-1.8,0.6-8.8-0.5-10.2h-0.1C387.1,318,386.4,325.1,386.5,326.8z" />
      <path className={cx(classes.st1, (props.pathClassName || ""))} d="M385.9,316.6h-7c0.5,3,0.3,7.1,0.3,10.2h7.3C386.4,325.1,387.1,318,385.9,316.6z" />
      <rect x="377.3" y="356.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="377.5" y="365.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="377.3" y="365.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
      <rect x="377.3" y="365.8" className={cx(classes.st1, (props.pathClassName || ""))} width="0" height="0.3" />
      <polygon className={cx(classes.st1, (props.pathClassName || ""))} points="377.3,356.6 377.3,365.8 377.3,365.8 377.5,365.8 377.5,365.8 377.5,356.6 377.5,356.6 	" />
      <rect x="377.5" y="356.2" className={cx(classes.st1, (props.pathClassName || ""))} width="0.1" height="0.3" />
    </svg>
  );
}

LockGroupIconIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  onClick: PropTypes.func
};

export default LockGroupIconIcon;
