export const keylessTenantLicenseFeatures = Object.freeze({
  dashboard: { name: "Dashboard", canEdit: false},
  key_requests: { name: "Key Requests (Keyless)", canEdit: true },
  rts: { name: "Real Time Status", canEdit: false },
  server_keys_management: { name: "Server Keys Management", canEdit: false },
  tenant_management: { name: "Tenant Management", canEdit: false }
});

export const enterpriseTenantLicenseFeatures = Object.freeze({
  ...keylessTenantLicenseFeatures,
  access_codes: { name: "Failsafe Unlock (Enterprise)", canEdit: true },
  api_tokens: { name: "API (Enterprise)", canEdit: true },
  charts: { name: "Analytics (Enterprise)", canEdit: true },
  collect_phone_numbers: { name: "Collect Phone Numbers (Enterprise)", canEdit: true },
  custom_roles: { name: "Custom Roles (Enterprise)", canEdit: true },
  device_restriction: { name: "Device Restriction (Enterprise)", canEdit: true },
  lock_collections: { name: "Site Management (Enterprise)", canEdit: true },
  lock_groups: { name: "Lock Groups (Enterprise)", canEdit: true },
  lock_notes: { name: "Photos and Notes Records (Enterprise)", canEdit: true },
  maps: { name: "Lock Mapping (Enterprise)", canEdit: true },
  site_visit_records: { name: "Work Sessions/Site Assurance (Enterprise)", canEdit: true }
});

export const getAllEditableTenantLicenseFeatures = () => {
  const tenantLicenseFeatures = {};

  Object.keys(enterpriseTenantLicenseFeatures).forEach(feature => {
    if (enterpriseTenantLicenseFeatures[feature].canEdit) {
      tenantLicenseFeatures[feature] = enterpriseTenantLicenseFeatures[feature].name;
    }
  });

  return tenantLicenseFeatures;
};