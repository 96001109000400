import PropTypes from "prop-types";

import VerifedUser from "@mui/icons-material/VerifiedUser";
import Button from "components/CustomButtons/Button";

const AdminButton = (props) => {
  //const classes = useStyles();
  if (props.adminMembershipClick) {
    return (
      <Button color="primary" style={{fontWeight: "bold", cursor: "pointer"}} onClick={() => props.adminMembershipClick()}>
        <VerifedUser/>{" " + props.text}
      </Button>
    );
  } else {
    return null;
  }
};

AdminButton.propTypes = {
  adminMembershipClick: PropTypes.func.isRequired,
  text: PropTypes.string
};
AdminButton.defaultProps = {
  text: ""
};

export default AdminButton;
