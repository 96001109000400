import { get, del, post, put } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchKeys = async (options) => {
  return get(`${twsWebUrl}/keys`, options);
};

const deleteKey = async ({ keyId, ...options }) => {
  return del(`${twsWebUrl}/keys/${keyId}`, options);
};

const addKey = async (options) => {
  return post(`${twsWebUrl}/keys`, options.data);
};

const updateKey = async ({ id, data }) => {
  return put(`${twsWebUrl}/keys/${id}`, data);
};

export const keyService = {
  fetchKeys,
  deleteKey,
  updateKey,
  addKey
};