import appRoutes from "_routes";
import { makeStyles } from "tss-react/mui";
import Button from "components/CustomButtons/Button";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(theme => ({
  button: {
    margin: theme.spacing(1),
    color: "#fff",
    float: "right"
  }
}));

const LogoutButton = () => {
  const { t } = useTranslation("auth");
  const { classes } = useStyles();

  return (
    <Button data-testid="logoutButton" disableFocusRipple disableRipple href={appRoutes.private.logout} size="sm" className={classes.button} variant="contained" color="primary">
      {t("logout.button")}
    </Button>
  );
};

export default LogoutButton;