const privateNotificationStyle = (theme) => ({
  notifContainer: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.lsyPalette.primary.light
  },
  notificationActionButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.25)
  },
  notificationText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  notificationItemIcon: {
    width: "20px",
    height: "20px"
  },
  notificationTimestamp: {
    whiteSpace: "nowrap",
    fontSize: theme.lsyPalette.subtitle2,
    marginRight: theme.spacing(1)
  }
});

export default privateNotificationStyle;