import { adminConstants } from "_constants/admin.constants";

const MembershipStatus = Object.freeze({
  PENDING_CONSENT: 0,
  ACTIVE  : 1,
  DISABLED: 2
});

function isActive(membership) {
  return membership.status === MembershipStatus.ACTIVE;
}

function isDisabled(membership) {
  return membership.status === MembershipStatus.DISABLED;
}

function isPending(membership) {
  return membership.status === MembershipStatus.PENDING_CONSENT;
}

function isLsyAdmin(membership) {
  return membership.role_id === adminConstants.LSY_ADMIN;
}

function getAdminMembership(memberships, role) {
  if (!memberships) {
    return null;
  }

  return memberships.filter(membership => membership.s4_role && membership.s4_role === role)[0];
}

function getSera4talAdmimMembership(memberships) {
  return getAdminMembership(memberships, adminConstants.SERA4TAL_ADMIN);
}

function getTrustedAdminMembership(memberships) {
  return getAdminMembership(memberships, adminConstants.TRUSTED_ADMIN);
}

function isSera4talAuthorized(memberships) {
  const sera4Membership = getSera4talAdmimMembership(memberships);

  return sera4Membership && sera4Membership.authorized;
}

/* find out if there's even a single pending consent membership within the array */
export function containsPendingConsent (memberships) {
  let hasPending = false;
  if (memberships) {
    hasPending = memberships.reduce((accum, val) => accum || (val.status === MembershipStatus.PENDING_CONSENT), hasPending);
  }
  return hasPending;
}

export const membershipHelper = {
  isActive: isActive,
  isDisabled: isDisabled,
  isPending: isPending,
  isLsyAdmin: isLsyAdmin,
  isSera4talAuthorized: isSera4talAuthorized,
  getSera4talAdmimMembership: getSera4talAdmimMembership,
  getTrustedAdminMembership: getTrustedAdminMembership
};