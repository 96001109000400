/*eslint quote-props: ["warn", "as-needed"]*/
const tableWidgetStyle = theme => ({
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "400px"
  },
  active: {
    color: theme.lsyPalette.text
  },
  expired: {
    color: theme.lsyPalette.primary.mainLight
  },
  deleted: {
    color: theme.lsyPalette.primary.mainLight,
    textDecoration: "line-through"
  },
  noData: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    marginLeft: "3px",
    marginRight: "3px",
    paddingTop: "4px",
    paddingBottom: "4px",
    flexGrow: 1,
    minHeight: "150px"
  },
  bodyText: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  root: {
    flexGrow: 1,
    margin: "3px"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  tableBackground: {
    width: "100%",
    minHeight: "100px"
  },
  tablePaper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none"
  },
  table: {
    minWidth: 150,
    width: "100%",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  tableRow: {
    height: "40px",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.lsyPalette.rowOdd
    },
    "&:nth-of-type(even)": { backgroundColor: theme.lsyPalette.secondary.main }
  },
  tableRowAction: {
    height: "25px",
    "&:hover": {
      backgroundColor: theme.lsyPalette.rowHover
    },
    cursor: "pointer"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  action: {
    cursor: "pointer"
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  title: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  topBar: {
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center"
  },
  tableBodyCell: {
    fontSize: theme.lsyPalette.body1,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  clearFilter: {
    marginRight: "5px",
    color: "white"
  },
  rowChangedColor: {
    height: "40px",
    backgroundColor: theme.lsyPalette.border.lightGrey
  }
});
export default tableWidgetStyle;
