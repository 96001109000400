import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/hsp1Style";
const useStyles = makeStyles()(styles);

function HSP1(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 1319 1646" className={cx(classes.svg, (props.className || ""))}>
      <path className={cx(classes.hsp1, (props.pathClassName || ""))} d="M722.286 1332.83C785.277 1352.83 803.848 1390.42 766.884 1437.65C729.92 1484.93 739.92 1518.99 766.884 1553.1C793.848 1587.2 799.92 1625.55 722.286 1625.55H583.714C506.08 1625.55 512.196 1587.2 539.116 1553.1C566.08 1518.99 576.08 1484.93 539.116 1437.65C502.152 1390.37 520.679 1352.78 583.714 1332.83H722.286Z"/>
      <path className={cx(classes.hsp1, (props.pathClassName || ""))} d="M659.369 1297.87C1012.21 1297.87 1298.24 1011.84 1298.24 659C1298.24 306.162 1012.21 20.131 659.369 20.131C306.531 20.131 20.5 306.162 20.5 659C20.5 1011.84 306.531 1297.87 659.369 1297.87Z"/>
      <path className={cx(classes.hsp1, (props.pathClassName || ""))} d="M658.929 1142.93C926.195 1142.93 1142.86 926.266 1142.86 659C1142.86 391.734 926.195 175.072 658.929 175.072C391.662 175.072 175 391.734 175 659C175 926.266 391.662 1142.93 658.929 1142.93Z"/>
      <path className={cx(classes.hsp1, (props.pathClassName || ""))} d="M658.821 979.643C700.144 979.643 733.643 946.144 733.643 904.821C733.643 863.499 700.144 830 658.821 830C617.499 830 584 863.499 584 904.821C584 946.144 617.499 979.643 658.821 979.643Z"/>
    </svg>
  );
}

HSP1.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};
export default HSP1;
