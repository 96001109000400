import * as actionTypes from "../_actions/actionTypes";
import { membershipHelper } from "_helpers";
import { isNullOrUndefined, updateObject } from "../_helpers/utility";

let list;
let currentMembership;

const hasSera4talMembership = membershipList => {
  return !isNullOrUndefined(membershipHelper.getSera4talAdmimMembership(membershipList));
};

try {
  list = JSON.parse(localStorage.getItem("memberships"));
} catch (e) {
  list = null;
}

try {
  currentMembership = JSON.parse(localStorage.getItem("currentTwsMembership"));
} catch (error) {
  currentMembership = null;
}

const initialState = {
  list: list,
  currentMembership: currentMembership, // We don't have a reason to start with a saved memembership yet.
  hasSera4talMembership: hasSera4talMembership(list),
  loading: false
};

const membershipStart = (state) => {
  return updateObject(state, {
    loading: true
  });
};

/* keep sorted on the initial fetch */
const membershipSet = (state, action) => {
  let memberships = action.memberships;
  if (memberships) {
    if (memberships && memberships.length > 0) {
      memberships = memberships.sort(function (v1, v2) {
        if (v1.tenant_name.toLowerCase() > v2.tenant_name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  return updateObject(state, {
    list: memberships,
    hasSera4talMembership: hasSera4talMembership(memberships),
    loading: false
  });
};

const membershipFail = (state) => {
  return updateObject(state, {
    loading: false
  });
};

const membershipSetCurrent = (state, action) => {
  return updateObject(state, {
    currentMembership: action.currentMembership
  });
};

const membershipSetCurrentSettings = (state, action) => {
  const currentMembership = updateObject(state.currentMembership, {
    settings: action.settings
  });

  return updateObject(state, { currentMembership: currentMembership });
};

const membershipRemoveCurrent = (state) => {
  return updateObject(state, {
    currentMembership: null
  });
};

// this is becasue fo the initial state on the reducer
const clearData = () => {
  localStorage.removeItem("memberships");
  return {};
};

const membershipUpdate = (state, action) => {
  var newList = state.list;
  let index;
  for (index = 0; index < state.list.length; index++) {
    if (state.list[index].tenant_id === action.id) {
      newList[index] = updateObject(newList[index], action.data);
      return { ...state, list: newList, hasSera4talMembership: hasSera4talMembership(newList) };
    }
  }
  return state;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MEMBERSHIPS_START: return membershipStart(state, action);
    case actionTypes.MEMBERSHIPS_SET: return membershipSet(state, action);
    case actionTypes.AUTH_LOGOUT: return clearData(state, action);
    case actionTypes.MEMBERSHIPS_FAIL: return membershipFail(state, action);
    case actionTypes.MEMBERSHIP_SET_CURRENT: return membershipSetCurrent(state, action);
    case actionTypes.MEMBERSHIP_SET_CURRENT_SETTINGS: return membershipSetCurrentSettings(state, action);
    case actionTypes.MEMBERSHIP_REMOVED_CURRENT: return membershipRemoveCurrent(state, action);
    case actionTypes.MEMBERSHIP_UPDATE: return membershipUpdate(state, action);
    default:
      return state;
  }
};

export default reducer;
