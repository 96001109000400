import { Fragment } from "react";
import PropTypes from "prop-types";

import { isNullOrUndefined } from "_helpers";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles()(styles);

export default function SimpleSelect(props) {
  const { classes, cx } = useStyles();

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      props.onChange;
    }
  };

  const value = isNullOrUndefined(props.selectedValue) ? "" : props.selectedValue;

  return (
    <Fragment>
      <FormControl variant="standard" fullWidth className={classes.formControl}>
        <InputLabel
          htmlFor={props.id}
          className={cx(props.labelStyle, classes.labelRoot)}
          required={props.required}>  
          {props.title}
        </InputLabel>
        <Select
          variant="standard"
          id={props.id}
          MenuProps={{
            className: classes.selectMenu
          }}
          className={props.highlighted ? classes.highlight : ""}
          classes={{
            select: classes.select
          }}
          value={value}
          name={props.name}
          onChange={props.onChange}
          disabled={props.disabled}
          required={props.required}>
          { props.options.map((o) => (
            <MenuItem
              key={o.id}
              value={o.id}
              onKeyDown={handleKeyDown}
            >
              {o.name}
            </MenuItem>
          ))
          }
        </Select>
      </FormControl>
    </Fragment>
  );
}

SimpleSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  highlighted: PropTypes.bool,
  labelStyle: PropTypes.string
};

SimpleSelect.defaultProps = {
  id: "simple-select",
  onChange: () => {}
};