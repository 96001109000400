import { get, post } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchImage = async ({ id }) => {
  return get(`${twsWebUrl}/buckets/lock_notes/image/${id}.jpg`);
};

const fetchNotes = (options) => {
  return get(`${twsWebUrl}/locks/notes`, options);
};

const fetchLockNotes = (options) => {
  return get(`${twsWebUrl}/locks/${options.lock_id}/notes`, options);
};

const fetchResourcesNotes = (options) => {
  const params = {
    note_type: 1,
    ...options
  };

  return get(`${twsWebUrl}/resources/notes`, params);
};

const fetchNote = (id) => {
  return get(`${twsWebUrl}/resources/notes/${id}`);
};

const createNote = (options) => {
  return post(`${twsWebUrl}/resources/notes`, options);
};

export const lockNoteService = {
  fetchImage,
  fetchNotes,
  fetchLockNotes,
  fetchResourcesNotes,
  fetchNote,
  createNote
};