import { get } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchWorkSession = async (id) => {
  return get(`${twsWebUrl}/work_sessions/${id}`);
};

const fetchWorkSessions = async (options) => {
  return get(`${twsWebUrl}/work_sessions`, options);
};

const fetchWorkSessionEvents = async (id, options) => {
  return get(`${twsWebUrl}/work_sessions/${id}/events`, options);
};

export const workSessionService = {
  fetchWorkSession,
  fetchWorkSessions,
  fetchWorkSessionEvents
};