const occStyle = theme => ({
  occ: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1",
    fillRule: "evenodd"
  },
  svg: {
    enableBackground: "new 0 0 672 672"
  }
});

export default occStyle;
