import { useState } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import ErrorBoundary from "_components/ErrorBoundary";

import {
  Alert,
  Grid,
  Typography,
  Divider,
  Collapse,
  IconButton
} from "@mui/material";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Panel/panelStyle.js";

const useStyles = makeStyles()(styles);

function Panel(props) {
  const { classes } = useStyles();
  const { title, description, isCollapse, isSubPanel, extraInfo } = props;
  const [expand, setExpand] = useState(isCollapse);

  const handleExpand = () => isCollapse ? setExpand(!expand) : () => {};

  const renderPanel = () => {
    return <Grid container direction="column" className={isSubPanel ? classes.subPanel : classes.panel}>
      <Grid item onClick={handleExpand} className={isCollapse ? classes.clickableGrid : ""}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={isCollapse ? 11 : 12}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="caption" className={classes.description}>{description}</Typography>
          </Grid>
          { isCollapse ?
            <Grid item xs={0.7}>
              <IconButton className={classes.iconButton} size="small" onClick={handleExpand}>
                {expand ? <ExpandLessRoundedIcon className={classes.icon}/> : <ExpandMoreRoundedIcon className={classes.icon}/>}
              </IconButton>
            </Grid> :
            null }
        </Grid>
      </Grid>
      <Collapse in={isCollapse ? expand : true}>
        { !isEmpty(extraInfo) ?
          <Grid item>
            <Alert severity={"info"} style={{alignItems: "center"}}>
              <Typography variant="caption">{extraInfo}</Typography>
            </Alert>
          </Grid> :
          null
        }
        <Divider className={classes.divider} />
        <ErrorBoundary>
          { props.children }
        </ErrorBoundary>
      </Collapse>
    </Grid>;
  };

  return isSubPanel ?
    <Grid item>{renderPanel()}</Grid> :
    renderPanel();
}

Panel.defaultProps = {
  isCollapse: true,
  isSubPanel: false,
  extraInfo: ""
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  extraInfo: PropTypes.string,
  isCollapse: PropTypes.bool,
  isSubPanel: PropTypes.bool
};

export default Panel;