import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import KeyListenerComponent, { isMac } from "_components/KeyListener";

import appRoutes from "_routes";
import { localeActions } from "_actions";

import LoginFrame from "_components/Auth/LoginFrame";
import ContainedButton from "_components/Button/ContainedButton";
import Divider from "_components/Divider/Divider";
import SelectLanguage from "_components/Select/SelectLanguage";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/loginOptionsStyle";

const useStyles = makeStyles()(styles);

const LoginOptions = () => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (!open) {
      setTimeout(() => setOpen(true), 500);
    }
  });

  const onEmail = () => {
    history.push(appRoutes.public.emailLink);
  };

  const onPassword = () => {
    history.push(appRoutes.public.password);
  };

  const onChangeLanguage = (language) => {
    dispatch(localeActions.setLocale({ language: language }));
  };

  const registerKeyListener = () => {
    const modifiers = [];
    // detect if the user is on a mac device
    if (isMac()) {
      modifiers.push("meta", "shift");
    } else {
      modifiers.push("ctrl", "alt");
    }

    return <>
      <KeyListenerComponent
        mainKeyCode="KeyE"
        modifiers={modifiers}
        onKeyPress={onEmail}
      />
      <KeyListenerComponent
        mainKeyCode="KeyP"
        modifiers={modifiers}
        onKeyPress={onPassword}
      />
    </>;
  };

  const renderDivider = () => {
    return <>
      <Grid item xs={4}>
        <Divider />
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.centerText}>{t("label.or")}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Divider />
      </Grid>
    </>;
  };

  const renderContent = () => {
    return <>
      {registerKeyListener()}
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.spaceAround}>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={10}>
              <Typography className={classes.centerText}>{t("label.signinOptions")}</Typography>
            </Grid>
            <Grid item xs={10}>
              <ContainedButton
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onEmail}
              >
                {t("button.emailLink")}
              </ContainedButton>
            </Grid>
            { renderDivider() }
            <Grid item xs={10}>
              <ContainedButton
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onPassword}
              >
                {t("auth:login.password")}
              </ContainedButton>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-start" alignItems="center" className={classes.topMargin}>
                <Grid item>
                  <SelectLanguage showLabel={false} handleSubmit={onChangeLanguage} selectClass={classes.select}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>;
  };

  return <LoginFrame open={open}>{ renderContent() }</LoginFrame>;
};

export default LoginOptions;