import { useState, useRef, useEffect, Fragment } from "react";
import { PropTypes } from "prop-types";

import { makeStyles } from "tss-react/mui";
import { Box, Grid } from "@mui/material";

const useStyles = makeStyles()(theme => ({
  chevron: {
    transition: "transform 0.25s ease"
  },
  chevronWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    height:"100%",
    padding: 4
  },
  rotate: {
    transform: "rotate(90deg)"
  },
  collapsible: {
    "backgroundColor": theme.lsyPalette.primary.mainDark,
    "color": theme.lsyPalette.textContrast,
    "fill": theme.lsyPalette.textContrast,
    "border": "none",
    "cursor": "pointer",
    "padding": 18,
    "width": "100%",
    "textAlign": "left",
    "outline": "none",
    "fontSize": 15,
    "fontWeight": "bold",
    "&:hover": {
      backgroundColor: theme.lsyPalette.primary.mainDark,
      color: theme.lsyPalette.textContrast,
      fill: theme.lsyPalette.textContrast
    }
  },
  bottomPadding: {
    marginBottom: theme.spacing(1)
  },
  content: {
    padding: "0 18px",
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.25s ease-out"
  },
  footer: {
    height: 18
  }
}));

function Chevron(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      viewBox="0 0 266 438"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m258.476 235.971-194.344 194.343c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901l154.021-154.746-154.021-154.745c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0l194.343 194.343c9.373 9.372 9.373 24.568.001 33.941z"
      />
    </svg>
  );
}

Chevron.propTypes = {
  className: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number.isRequired
};

export default function Collapsable(props) {
  const [collapsed, setCollapsed] = useState(props.collapsed);
  const [height, setHeight] = useState(null);
  const content = useRef(null);
  const { classes, cx } = useStyles();
  const {contentClassName, headerClassName, footerClassName, chevronSize} = props;
  const contentClass = contentClassName || classes.content;
  const collapsableClass = headerClassName || classes.collapsible;
  const footerClass = footerClassName || classes.footer;
  const chevronWidth = chevronSize || 10;

  useEffect(() => {
    if (!collapsed) {
      setHeight((props.scrollHeight ? props.scrollHeight : (content.current.scrollHeight + "px")));
    }
  }, [height, collapsed, props.scrollHeight, props.children]);

  const handleClick = () => {
    const collapseNow = !collapsed;

    setCollapsed(collapseNow);
    if (!collapseNow && props.executeOnUnfold)
      props.executeOnUnfold();

    setHeight(collapseNow ? "0px" : content.current.scrollHeight + "px");
  };

  return (
    <div className={cx({[classes.bottomPadding]: props.padBottom })}>
      <div className={collapsableClass} onClick={() => handleClick()}>
        <Grid container>
          <Grid item xs={10}>
            {props.title}
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.chevronWrapper}>
              <Chevron className={cx({[classes.chevron]: true, [classes.rotate]: !collapsed})} width={chevronWidth}></Chevron>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div ref={content} className={contentClass} style={{ maxHeight : height }}>
        <Fragment>
          {props.children}
          <div className={footerClass}></div>
        </Fragment>
      </div>
    </div>
  );
}

Collapsable.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  collapsed: PropTypes.bool,
  scrollHeight: PropTypes.string,
  executeOnUnfold: PropTypes.func,
  padBottom: PropTypes.bool,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  chevronSize: PropTypes.number
};