const notificationCardStyle = (theme) => ({
  root: {
    display: "flex",
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: "0px",
    paddingTop: "10px",
    paddingBottom: "5px"
  },
  cover: {
    width: 151
  },
  actions: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid var(--ds-border-neutral,${theme.lsyPalette.secondary.background})`,
    paddingLeft: theme.spacing(1)
  },
  details: {
    display: "flex",
    flexDirection: "column",
    borderLeft: "22px solid #b3d4ff",
    width: "100%",
    transition: "border 500ms ease-out"
  },
  skeletonDetails: {
    display: "flex",
    flexDirection: "column",
    borderLeft: `22px solid ${theme.lsyPalette.secondary.background}`,
    width: "100%",
    transition: "border 500ms ease-out"
  },
  critical: {
    borderLeft: "22px solid #ff9191"
  },
  dismissed: {
    borderLeft: "22px solid #807587"
  },
  icon: {
    textAlign: "center",
    backgroundColor: "#2684ff",
    borderRadius: "3px",
    color: "white",
    transition: "500ms ease-out",
    width: "24px",
    height: "24px"
  },
  criticalIcon: {
    backgroundColor: "#FF5E63"
  },
  dismissedIcon: {
    backgroundColor: "#37323D"
  },
  svgIconPath: {
    fill: "white",
    stroke: "white",
    strokeWidth: "4"
  },
  actionModal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  commentsBox: {
    width: "100%", 
    overflow: "auto", 
    height: "400px", 
    marginBottom: "10px"
  },
  commentFallback: {
    fontSize: theme.lsyPalette.body2,
    color: theme.lsyPalette.text
  },
  textFieldBox: {
    backgroundColor: theme.lsyPalette.secondary.main, 
    width: "100%"
  },
  textFieldInput: {
    backgroundColor: "#f5f5f5",
    borderRadius: "5px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  title: {
    display: "flex", 
    alignItems: "center", 
    fontSize: theme.lsyPalette.body3, 
    color: theme.lsyPalette.text,
    marginLeft: "-12px"
  },
  titleText: {
    marginLeft: "5px",
    color: theme.lsyPalette.text
  },
  message: {
    fontSize: theme.lsyPalette.body1, 
    color: theme.lsyPalette.text,
    marginLeft: "12px"
  },
  updatedByContainer: {
    fontSize: theme.lsyPalette.body1, 
    marginLeft: "auto", 
    marginRight: "5px",
    color: theme.lsyPalette.text
  },
  updatedByText: {
    fontSize: theme.lsyPalette.body1, 
    color: theme.lsyPalette.text,
    textAlign: "right",
    margin: "0px"
  },
  date: {
    flexGrow: 1, 
    margin: "0px", 
    fontSize: theme.lsyPalette.subtitle2,
    color: theme.lsyPalette.text
  },
  commentCount: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.lsyPalette.primary.mainDark
  },
  photoModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  actionButtons: {
    color: theme.lsyPalette.primary.mainDark
  },
  skeletonButton: {
    marginRight: "5px"
  }
});
export default notificationCardStyle;
