import { useState, useCallback, createContext } from "react";
import PropTypes from "prop-types";

// Components
import CustomModal from "_components/Modal/CustomModal";

// Our Context
export const ModalContext = createContext();

// Create the provider
export const ModalProvider = ({children}) => {
  const [customModal, setCustomModal] = useState(null);
  const [customModalOpen] = useState(true);

  const createAsyncModal = useCallback(async (params) => {
    const customAction = async() => {
      const confirmAction = params.confirmAction;
      if (confirmAction)
        confirmAction();
      setCustomModal(null);
    };

    setCustomModal(
      <CustomModal
        open={customModalOpen}
        setOpen={() => setCustomModal(null)}
        handleSubmit={customAction}
        {...params}
      />
    );
    
    return customAction;
  },[customModalOpen]);

  const createModal = useCallback((params) => {
    const {confirmAction} = params;
    const handleCustomAction = () => {
      if (confirmAction) {
        confirmAction();
      }
      setCustomModal(null);
    };

    setCustomModal(
      <CustomModal
        open={customModalOpen}
        setOpen={() => setCustomModal(null)}
        handleSubmit={handleCustomAction}
        
        {...params}
      />
    );
  }, [customModalOpen]);
  
  return (
    <ModalContext.Provider value={{createModal, createAsyncModal}}>
      {customModal}
      {children}
    </ModalContext.Provider>   
  );
};
  
ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};