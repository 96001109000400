import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Badge as BadgeMUI } from "@mui/material";

const Badge = forwardRef((props, ref) => {
  const { color, variant, invisible, ...rest } = props;

  return <BadgeMUI ref={ref} color={color} variant={variant} invisible={invisible} {...rest}>
    {props.children}
  </BadgeMUI>;
});

Badge.displayName = "Badge";

Badge.defaultProps = {
  color: "redColor",
  variant: "dot",
  overlap: "rectangular"
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  invisible: PropTypes.bool
};

export default Badge;