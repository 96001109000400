/*eslint quote-props: ["warn", "as-needed"]*/
import { keyframes } from "tss-react";

const tableWidgetStyle = theme => {
  const live = {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "inline",
    textAlign: "center",
    color: "transparent",
    fontSize: theme.lsyPalette.body1,
    cursor: "default",
    background: `linear-gradient(90deg, ${theme.lsyPalette.primary.mainLight} 40%, ${theme.lsyPalette.secondary.main} 50%, ${theme.lsyPalette.primary.mainLight} 40%)`,
    backgroundSize: "14em 1.2em",
    WebkitBackgroundClip: "text",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 0",
    backgroundColor: "#222"
  };

  return {
    "@keyframes lsyTableFadeIn": {
      "0%": { opacity: 0 },
      "100%": { opacity: 1 }
    },
    widgetCard: {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      animation: `$lsyTableFadeIn 1000ms ${theme.transitions.easing.easeInOut}`,
      backgroundColor: theme.lsyPalette.secondary.main
    },
    noData: {
      fontSize: theme.lsyPalette.body1,
      color: theme.lsyPalette.text,
      marginLeft: "3px",
      marginRight: "3px",
      paddingTop: "4px",
      paddingBottom: "4px",
      flexGrow: 1
    },
    marginRight: {
      marginRight: "7px"
    },
    bodyText: {
      fontSize: theme.lsyPalette.body1,
      color: theme.lsyPalette.text
    },
    tooltip: {
      backgroundColor: theme.lsyPalette.primary.mainHover,
      color: theme.lsyPalette.textContrast
    },
    root: {
      flexGrow: 1,
      margin: "3px"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    titleSm: {
      flexGrow: 1,
      marginLeft: "5px",
      fontSize: theme.lsyPalette.body1,
      color: theme.lsyPalette.textContrast
    },
    titleMd: {
      flexGrow: 1,
      marginLeft: "5px",
      fontSize: theme.lsyPalette.title1,
      color: theme.lsyPalette.textContrast
    },
    appBar: {
      position: "static",
      backgroundColor: theme.lsyPalette.primary.main,
      display: "flex",
      justifyContent: "center"
    },
    titleLg: {
      flexGrow: 1,
      marginLeft: "5px",
      fontSize: theme.lsyPalette.title4,
      color: theme.lsyPalette.textContrast
    },
    appBarSm: {
      position: "static",
      backgroundColor: theme.lsyPalette.primary.main,
      display: "flex",
      justifyContent: "center",
      height: "40px"
    },
    appBarMd: {
      position: "static",
      backgroundColor: theme.lsyPalette.primary.main,
      display: "flex",
      justifyContent: "center",
      height: "64px"
    },
    appBarLg: {
      position: "static",
      backgroundColor: theme.lsyPalette.primary.main,
      display: "flex",
      justifyContent: "center",
      height: "80px"
    },
    pagination: {
    },
    refresh: {
      color: theme.lsyPalette.secondary.main
    },
    tableBackground: {
      width: "100%"
    },
    tablePaper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      boxShadow: "none"
    },
    table: {
      minWidth: 150,
      width: "100%",
      borderSpacing: "3px 0px",
      borderCollapse: "separate",
      backgroundColor: theme.lsyPalette.secondary.main
    },
    tableRow: {
      height: "40px",
      "&:nth-of-type(odd)": {
        backgroundColor: theme.lsyPalette.rowOdd
      },
      "&:nth-of-type(even)": { backgroundColor: theme.lsyPalette.secondary.main }
    },
    tableRowAction: {
      height: "40px",
      "&:nth-of-type(odd)": {
        backgroundColor: theme.lsyPalette.rowOdd
      },
      "&:nth-of-type(even)": { backgroundColor: theme.lsyPalette.secondary.main },
      "&:hover": {
        backgroundColor: theme.lsyPalette.rowHover

      },
      cursor: "pointer"
    },
    tableHeaderRow: {
      height: "47px"
    },
    tableHeader: {
      backgroundColor: theme.lsyPalette.primary.main
    },
    actionLink: {
      color: theme.lsyPalette.link,
      cursor: "pointer"
    },
    action: {
      cursor: "pointer"
    },
    sortLabel: {
      "&.Mui-active, &.Mui-active .MuiTableSortLabel-icon": {
        color: theme.lsyPalette.textContrast
      }
    },
    tableHeaderCell: {
      color: theme.lsyPalette.textContrast,
      fontSize: theme.lsyPalette.body1,
      border: "none",
      fontWeight: "normal"
    },
    tableBodyCell: {
      fontSize: theme.lsyPalette.body1,
      color: theme.lsyPalette.text,
      border: "none"
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    clearFilter: {
      marginRight: "5px",
      color: "white"
    },
    notificationButton: {
      marginRight: "5px",
      flexGrow: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: theme.lsyPalette.subtitle1
      }
    },
    liveOn: {
      ...live,
      animation: `${keyframes`
        0% {
          background-position: top right;
        }
        100% {
          background-position: top left;
        }
        `} 10s 1`
    },
    liveOff: {
      ...live
    }
  };
};

export default tableWidgetStyle;
