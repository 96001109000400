import { useState, useReducer, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty, isEqual } from "lodash";
import queryString from "query-string";

import { userService, lockService, lockgroupService } from "_services/lockstasy";
import { userNotificationService } from "_services/lockstasy/userNotification.service";
import { compactObj, getFilterVars, lsyRouter } from "_helpers";
import { notificationGroups } from "_helpers/userNotifications";

import Grid from "@mui/material/Grid";
import UserNotificationsWidget from "_containers/Widgets/UserNotificationWidget";
import LsyRouter from "_components/Navigation/LsyRouter";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { FiberManualRecord, KeyboardArrowDown, KeyboardBackspaceRounded, RadioButtonUnchecked } from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/userNotificationStyle.js";

const useStyles = makeStyles()(styles);

const defaultInitialState = Object.freeze({
  setting: 0
});

function UserNotifications(props) {
  const { location, history } = props;
  const { search } = location;

  const getInitialState = () => {
    const filterVars = getFilterVars(defaultInitialState, search);

    if (Object.keys(filterVars).length > 0) {
      if (filterVars.setting) {
        filterVars.setting = parseInt(filterVars.setting);
      }
      const newState = { ...defaultInitialState, ...filterVars };
      return newState;
    } else {
      return defaultInitialState;
    }
  };
  const initialState = getInitialState();
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const size = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const user = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.memberships.currentMembership.id);
  const membershipId = useParams().id;

  const [currNotification, setCurrNotification] = useState(notificationGroups[initialState.setting]);
  const [value, setValue] = useState(initialState.setting);

  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      access_request: [],
      lock_group: [],
      open_lock: [],
      latch_sensor: [],
      door_sensor: [],
      low_battery: [],
      work_session_without_close_confirmation: [],
      work_session_time_exceeded: [],
      lock_note: [],
      lock_replacement: [],
      lock_deactivation: []
    }
  );

  const [selectAll, setSelectAll] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      access_request: false,
      lock_group: false,
      open_lock: false,
      latch_sensor: false,
      door_sensor: false,
      low_battery: false,
      work_session_without_close_confirmation: false,
      work_session_time_exceeded: false,
      lock_note: false,
      lock_replacement: false,
      lock_deactivation: false
    }
  );

  const [workSessionOpen, setWorkSessionOpen] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      work_session_without_close_confirmation: false,
      work_session_time_exceeded: false,
      lock_group: false,
      open_lock: false
    }
  );

  const formatFetchedData = (result) => {
    let obj = {};
    let select = {};
    for (const datum of result.data) {
      let group; // Find notificationGroup
      if (datum.type.id === 8) {
        if (Object.keys(datum).includes("lock_group")) {
          group = notificationGroups[1].data.find(x => x.api_label === "lock_group");
        } else {
          group = notificationGroups[1].data.find(x => x.api_label === "lock");
        }
      } else if (datum.type.id === 5 || datum.type.id === 6) {
        group = notificationGroups[5].data.find(x => x.api_type === datum.type.id);
      } else {
        group = notificationGroups.find(x => Array.isArray(x.api_type) ? x.api_type.includes(datum.type.id) : x.api_type === datum.type.id);
      }

      if (!Object.keys(datum).includes(group.api_label)) { // Find if isSelectAll
        select[group.type] = true;
      } 
      if (obj[group.type]) { // Add to dataObj
        if (obj[group.type].every(x => x.id !== datum.id)) {
          obj[group.type].push(datum);
        }
      } else {
        obj[group.type] = [datum];
      }
    }

    return { data: obj, selectAll: select };
  };

  const fetchData = useCallback(async () => {
    try {
      const result = await userNotificationService.getAllUserNotifications();
      const formattedData = formatFetchedData(result);
      setData(formattedData.data);
      setSelectAll(formattedData.selectAll);
    } catch (e) {
      console.warn("Warning, failed to fetch all user notifications", e);
    } 
  }, [setData]);

  useEffect(() => {
    if (userId !== membershipId) {
      history.push(lsyRouter("dashboard"));
    }
    fetchData();
  }, [membershipId, history, userId, fetchData]);

  const fetchUsersWithInput = async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  };

  const fetchLocksWithInput = async (searchInput) => {
    try {
      const result = await lockService.fetchLocks({ search: searchInput });
      return result.data.map(lock => lock ? { id: lock.id, name: lock.full_identifier } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
      return [];
    }
  };

  const fetchLockGroupsWithInput = async (inputValue) => {
    try {
      const result = await lockgroupService.fetchLockgroups({ search: inputValue });
      const formattedResult = result.data.map((group) => group ? { id: group.id, name: group.name } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch lock groups with given input", e);
    }
  };

  const clearUrlQuery = () => {
    history.replace(`/users/${membershipId}/notifications`);
  };

  const updateUrlQuery = (newState) => {
    if (!isEqual(newState, 0)) {
      const filterOptions = compactObj({
        setting: newState
      });

      history.replace(`/users/${membershipId}/notifications?${queryString.stringify(filterOptions)}`);
    } else {
      clearUrlQuery();
    }
  };

  const handleNotificationLabel = (v) => {
    setCurrNotification(v);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateUrlQuery(newValue);
  };

  const handleWorkSessions = (type) => {
    setWorkSessionOpen({
      ...workSessionOpen,
      [type]: !workSessionOpen[type]
    });
  };

  const getTabStatusIcon = (v, isForTab = true) => {
    if (!isEmpty(data[v.type]) || (isForTab ? 
      (v.type === "locks" && (!isEmpty(data["lock_group"]) || !isEmpty(data["open_lock"]))) || 
      (v.type === "work_sessions" && (!isEmpty(data["work_session_without_close_confirmation"]) || !isEmpty(data["work_session_time_exceeded"]))) : null)
    ) {
      return <FiberManualRecord className={cx(classes.dotIcon, classes.greenIcon, {[classes.statusIcon]: size})}/>;
    } else {
      return <RadioButtonUnchecked className={cx(classes.dotIcon, {[classes.statusIcon]: size})}/>;
    }
  };

  const renderUserNotificationForm = () => {
    let fetchWithInput;
    let fetchType;
    if (currNotification.data) {
      if (currNotification.type === "work_sessions") {
        fetchWithInput = fetchUsersWithInput;
        fetchType = t("features.users");
      } else if (currNotification.type === "locks") {
        fetchWithInput = fetchLocksWithInput;
        fetchType = t("features.locks");
      } 
      return (
        <div>
          {currNotification.data.map((v, i) => {
            return (
              <UserNotificationsWidget
                key={v.name}
                title={
                  <div className={cx(classes.titleWorkSessions, classes.clickable)} onClick={() => handleWorkSessions(v.type)}>
                    {`${t("label.numberOfItems", { currentItem: i + 1, totalItems: currNotification.data.length })} - ${t(v.name)}`}
                    {!workSessionOpen[v.type] ? 
                      (<KeyboardArrowDown className={classes.arrowDownIcon}/>) :
                      (<KeyboardArrowDown className={classes.arrowUpIcon}/>)
                    }
                  </div>
                }
                isCollapsed={!workSessionOpen[v.type]}
                fetchWithInput={v.api_label === "lock_group" ? fetchLockGroupsWithInput : fetchWithInput}
                fetchType={fetchType}
                type={v.type}
                apiLabel={v.api_label}
                apiType={v.api_type}
                data={data[v.type]}
                setData={setData}
                currNotification={v}
                isTypeAhead={v.typeAhead}
                hasSelectAll={v.hasSelectAll}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                statusIcon={getTabStatusIcon(v, false)}
              /> 
            );
          })}
        </div>
      );
    } else if (currNotification.type === "lock_group") {
      fetchWithInput = fetchLockGroupsWithInput;
      fetchType = t("features.lockGroups");
    } else {
      fetchWithInput = fetchLocksWithInput;
      fetchType = t("features.locks");
    }
    return (
      <UserNotificationsWidget
        isCollapsed={false}
        fetchWithInput={fetchWithInput}
        fetchType={fetchType}
        type={currNotification.type}
        apiType={currNotification.api_type}
        apiLabel={currNotification.api_label}
        data={data[currNotification.type]}
        setData={setData}
        currNotification={currNotification}
        isTypeAhead={currNotification.typeAhead}
        hasSelectAll={currNotification.hasSelectAll}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        statusIcon={getTabStatusIcon(currNotification, false)}
      />
    );
  };

  return (
    <div className={classes.body}>
      <Grid item xs={false} md={1}></Grid>
      <Grid item xs={12} md={10}>
        <div className={classes.title}><b>{`${user.first_name} ${user.last_name} ${t("features.notificationSettings")}`}</b></div>
        <LsyRouter className={classes.backContainer} page="user" id={membershipId}>
          <KeyboardBackspaceRounded className={classes.icon}/>
          <span>{t("button.toUserProfile")}</span>
        </LsyRouter>
        <Box className={cx(classes.box, {[classes.labels]: size})} sx={{ flexGrow: 1, bgcolor: "background.paper", height: "fit-content" }}>
          <Tabs
            orientation={size ? "vertical" : "horizontal"}
            className={classes.tabPanel}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            fullwidth="true"
            scrollButtons="auto"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {notificationGroups.map((v) => {
              return (
                <Tab 
                  className={classes.notificationLabel} 
                  onClick={() => handleNotificationLabel(v)} 
                  key={v.name} 
                  label={
                    <div className={classes.notificationTab}>
                      {t(v.name)}
                      {getTabStatusIcon(v)}
                    </div>
                  }
                />
              );
            })}
          </Tabs>
          <Grid item sm={12} md={10}>
            {renderUserNotificationForm()}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={false} md={1}></Grid>
    </div>
  );
}

UserNotifications.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default UserNotifications;