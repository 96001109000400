const selectLanguageStyle = theme => ({
  select: {
    "height": theme.select.height,
    "& .MuiOutlinedInput-input": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }
});
  
export default selectLanguageStyle;