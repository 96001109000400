import { useState, useReducer, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isEqual, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useCustomCompareCallback, useCustomCompareEffect } from "use-custom-compare";
import moment from "moment";
import queryString from "query-string";

import { alertActions } from "_actions";
import { compactObj, formatDate, isBlank, systemLogsHelper } from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { userService, systemLogService } from "_services";

import {
  Chip,
  FormControl,
  Tooltip,
  Typography
} from "@mui/material";
import Select from "react-select";

import ErrorBoundary from "_components/ErrorBoundary";
import CustomModal from "_components/Modal/CustomModal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DateTimeRange from "_components/Date/DateTimeRange";
import SystemChartsWidget from "_containers/Widgets/SystemChartsWidget";
import SystemLogsWidget from "_containers/Widgets/SystemLogsWidget";
import CustomAsyncSearchForm from "_components/Form/CustomAsyncSearchForm";

import { GetApp, People, Style } from "@mui/icons-material";
import SwapIcon from "assets/teleporte/SwapIcon";

import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import styles from "assets/jss/views/lockstasy/systemLogsStyle";

const useStyles = makeStyles()(styles);

const defaultInitialState = Object.freeze({
  start_date: moment().subtract(1, "week").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  search: [],
  eventType: []
});

function SystemLogs(props) {
  const { location, history, baseUrl, org } = props;
  const { search } = location;
  const theme = useTheme();
  const size = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { classes, cx } = useStyles();
  const { t } = useTranslation(["systemEvents", "default"]);
  const { handleSubmit, reset, setValue, errors, control, clearErrors } = useForm();
  const language = useSelector((state) => state.locale.language);
  const dispatch = useDispatch();
  moment.locale(language);

  const eventTypeFilterOptions = useMemo(() => {
    const excludes = ["legend.9"];
    let options = [];

    systemLogsHelper.logCategories.map(cat => {
      if (!excludes.includes(cat.label)) {
        options.push({ label: t(cat.label), value: cat.value });
      }
    });

    return options;
  }, [t]);

  const [usersForSearch, setUsersForSearch] = useState([]);
  const addUsersForSearch = (users) => {
    let newUsers = [];
    users.map(newUser => {
      const hasUser = usersForSearch.find(user => user.id === newUser.id);
      if (!hasUser) {
        newUsers.push(newUser);
      }
    });

    setUsersForSearch(prevState => [...prevState, ...newUsers]);
  };

  const fetchUser = async (membershipId) => {
    const user = usersForSearch.find(user => user.id === membershipId);
    if (user) {
      return user;
    }

    try {
      const result = await userService.fetchUser({ id: membershipId });
      const user = { id: result.data.membership_id, name: `${result.data.first_name} ${result.data.last_name} (${result.data.email})` };
      addUsersForSearch([user]);
      return user;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  };

  const getInitialState = () => {
    const filterVars = {
      ...defaultInitialState,
      ...search ? 
        queryString.parse(search, { arrayFormat: "separator", arrayFormatSeparator: "," }) : {}
    };
    const filterOptions = compactObj({ ...filterVars });
    const filterOptionsKeys = Object.keys(filterOptions);
    const formattedFilterOp = {};

    filterOptionsKeys.forEach(field => {
      if (Array.isArray(filterOptions[field])) {
        if (field === "membership_ids") {
          formattedFilterOp.search = filterOptions[field].map(item => {
            fetchUser(item);
            return { id: item };
          });
        } else if (field === "event_type") {
          formattedFilterOp.eventType = filterOptions[field].map(item => {
            return { value: item };
          });
        }
      } else if (field === "membership_ids") {
        fetchUser(filterOptions[field]);
        formattedFilterOp.search = [{ id: filterOptions[field] }];
      } else if (field === "event_type") {
        formattedFilterOp.eventType = [{ value: filterOptions[field] }];
      } else {
        formattedFilterOp[field] = filterOptions[field];
      }
    });

    formattedFilterOp.start_date = moment(filterOptions.start_date, "YYYY/MM/DD");
    formattedFilterOp.end_date = moment(filterOptions.end_date, "YYYY/MM/DD");

    return { ...formattedFilterOp };
  };
  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      currentPage: 1,
      rowsPerPage: 25,
      totalData: 0,
      formattedData: {},
      data: [],
      chartData: [],
      chartGrouping: "",
      filterVariables: initialState,
      start_date: initialState.start_date,
      end_date: initialState.end_date,
      searchOpen: false,
      eventTypeOpen: false,
      exportTypeOpen: false,
      sortDirection: "DESC",
      userSummaryOpen: false,
      userData: {},
      calloutIcon: undefined,
      loading: true,
      chartLoading: true,
      selectorValues: initialState.search || []
    }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const MAX_EXPORT_ROWS = 50000;

  const setStartDate = (startDate) => {
    setState({ start_date: startDate });
  };
  const setEndDate = (endDate) => {
    setState({ end_date: endDate, currentPage: 1 });
  };
  const setSearchOpen = (searchOpen) => setState({ searchOpen });
  const setEventType = (eventType) => setState({filterVariables: { ...state.filterVariables, eventType: eventType }, currentPage: 1});
  const setSelectorValues = useCallback((selectorValues) => setState({ selectorValues }), []);
  const setEventTypeOpen = (eventTypeOpen) => setState({ eventTypeOpen });
  const setExportTypeOpen = (exportTypeOpen) => setState({ exportTypeOpen });
  const setSortDirection = (sortDirection) => setState({ sortDirection, currentPage: 1 });

  const onSubmit = (data) => {
    const isSameUsers = data.search.every(u => state.filterVariables.search?.find(v => v.id === u.id)) && data.search.length === state.filterVariables.search.length;

    if (data.search && !isSameUsers) {
      setState({ 
        filterVariables: { ...state.filterVariables, ...data } ,
        currentPage: 1,
        searchOpen: false,
        eventTypeOpen: false,
        exportTypeOpen: false
      });
    }
    if (!isEqual(data, state.filterVariables)) {
      reset(data);
      setState({
        filterVariables: {
          ...state.filterVariables,
          ...data
        },
        currentPage: 1,
        searchOpen: false,
        eventTypeOpen: false,
        exportTypeOpen: false
      });
    }
  };

  const getFormattedFilterOptions = useCallback(() => {
    const category_ids = state.filterVariables.eventType?.map(v => v.value) || [];
    const initiator_ids = state.filterVariables.search?.map(v => v.id) || [];

    return {
      start_date: moment.utc(moment(state.start_date).startOf("day")).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: moment.utc(moment(state.end_date).endOf("day")).format("YYYY-MM-DDTHH:mm:ss"),
      initiator_ids: initiator_ids.toString(),
      target_ids: initiator_ids.toString(),
      target: "user",
      category_ids: category_ids
    };
  }, [state.start_date, state.end_date, state.filterVariables.eventType, state.filterVariables.search]);

  const handleExport = async (data) => {
    const category_ids = state.filterVariables.eventType?.map(v => v.value) || [];
    const initiator_ids = state.filterVariables.search?.map(v => v.id) || [];

    try {
      const options = {
        start_date: moment.utc(moment(state.start_date).startOf("day")).format("YYYY-MM-DDTHH:mm:ss"),
        end_date: moment.utc(moment(state.end_date).endOf("day")).format("YYYY-MM-DDTHH:mm:ss"),
        initiator_ids: initiator_ids,
        target_ids: initiator_ids,
        target: "user",
        category_ids: category_ids.toString(),
        export: data.export
      };

      await systemLogService.exportSystemLogs(compactObj(options));
      dispatch(alertActions.send(t("default:success.exportSystemEventData")));
    } catch (e) {
      console.warn("Warning, failed to export system logs", e);
      if (e.response?.status === 429) {
        if (e.response?.statusText === "Too Many Requests") {
          dispatch(alertActions.send(t("error.manyRequests"), "error"));
        }
      } else {
        dispatch(alertActions.send(t("default:label.error"), "error"));
      }
    }
  };

  const updateUrlQuery = useCallback(newState => {
    const newFilterVariables = {
      ...newState,
      ... newState.start_date && { start_date: formatDate(newState.start_date, "YYYY-MM-DD") },
      ... newState.end_date && { end_date: formatDate(newState.end_date, "YYYY-MM-DD") }
    };

    if (!isEqual(newFilterVariables, state.filterVariables)) {
      const filterOptions = compactObj({ ...newFilterVariables });
      const filterOptionsKeys = Object.keys(filterOptions);
      const formattedFilterOp = {};

      filterOptionsKeys.forEach(field => {
        if (Array.isArray(filterOptions[field]) && !isBlank(filterOptions[field])) {
          if (field === "search") {
            formattedFilterOp.membership_ids = filterOptions[field].map(item => item.id);
          } else if (field === "eventType") {
            formattedFilterOp.event_type = filterOptions[field].map(item => item.value);
          }
        } else {
          formattedFilterOp[field] = filterOptions[field];
        }
      });

      history.replace(`/system_logs?${queryString.stringify({...formattedFilterOp}, { arrayFormat: "separator", arrayFormatSeparator: ",", encode: false })}`);
    }
  }, [history, state.filterVariables]);
  
  const resetFilter = (filterVar) => {
    reset(filterVar);
    let newState = {
      ...state.filterVariables,
      [filterVar]: defaultInitialState[filterVar]
    };
    setState({
      filterVariables: newState,
      currentPage: 1,
      selectorValues: filterVar === "search" ? defaultInitialState[filterVar] : state.selectorValues
    });
    updateUrlQuery({...newState, start_date: state.start_date, end_date: state.end_date});
  };

  const setStartDateValue = (v) => {
    let newState = {
      start_date: v
    };
    updateUrlQuery(newState);

    setStartDate(v);
  };

  const setEndDateValue = (v) => {
    let newState = {
      end_date: v
    };
    updateUrlQuery(newState);

    setEndDate(v);
  };

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, []);

  const renderSystemForm = () => {
    return (
      <div className={classes.eventTypeForm}>
        <Select
          defaultValue={state.filterVariables.eventType?.map(t => eventTypeFilterOptions.find(cat => cat.value == t.value)) || []}
          isMulti
          options={eventTypeFilterOptions}
          className={cx("basic-multi-select", classes.eventType)}
          classNamePrefix="select"
          styles={{ menu: base => ({ ...base, position: "relative" }) }}
          onChange={value => {
            setEventType(value);
          }}
          theme={(base) => ({
            ...base,
            colors: {
              ...base.colors,
              primary25: theme.lsyPalette.rowHover,
              primary50: theme.lsyPalette.rowHover,
              primary: theme.lsyPalette.primary.mainLight
            }
          })}
        />
      </div>
    );
  };

  const renderEventTypes = () => {
    return isEmpty(state.filterVariables.eventType) ? null :
      (
        state.filterVariables.eventType?.map((v) => {
          const event = eventTypeFilterOptions.find(cat => cat.value == v.value);
          return ` ${event.label}`;
        })
      );
  };

  const DateTimeRangeForm = () => {
    return (
      <DateTimeRange 
        startDate={state.start_date}
        setStartDate={setStartDateValue}
        endDate={state.end_date}
        setEndDate={setEndDateValue}
        showTime={false}
        label={" "}
        format="YYYY/MM/DD"
        inputProps={{variant: "standard", disableUnderline: true, style: {fontSize: theme.lsyPalette.subtitle3, margin: 0, marginTop: "auto", marginBottom: "auto", fontWeight: "300"}}}
      />
    );
  };

  const handleDirectionChange = () => {
    if (state.sortDirection === "DESC") {
      setSortDirection("ASC");
    } else {
      setSortDirection("DESC");
    }
  };

  const renderDirectionText = () => {
    if (state.sortDirection === "DESC") {
      return t("default:label.viewingLatest");
    } else {
      return t("default:label.viewingOldest");
    }
  };

  const fetchChartSystemEvents = useCallback(async() => {
    const options = compactObj(getFormattedFilterOptions());

    try {
      const resp = await systemLogService.fetchChartSystemEvents(options);
      setState({
        chartData: resp.data.dates,
        chartGrouping: resp.data.grouped_by,
        chartLoading: false
      });
    } catch(e) {
      console.warn(e);
    }
  }, [setState, getFormattedFilterOptions]);

  useCustomCompareEffect(() => {
    setState({chartLoading: true});
    fetchChartSystemEvents();
    updateUrlQuery(state.filterVariables);
  }, [fetchChartSystemEvents, state.start_date, state.end_date, state.filterVariables], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getXAxis = useCallback(() => {
    let axis = [];
    let count;
    if (state.chartGrouping !== "") {
      let end_date = moment(state.end_date);
      let start_date = moment(state.start_date);
      count = end_date.diff(start_date, state.chartGrouping + "s") + 2;

      for (let i = 0; i < count; i++) {
        axis.push(moment(end_date).subtract(i, state.chartGrouping + "s").endOf(state.chartGrouping + "s").format("YYYY-MM-DD"));
      }
      axis = axis.reverse();
    }

    return axis;
  }, [state.chartGrouping, state.start_date, state.end_date]);

  const getDataPoints = useCallback((xAxis) => {
    const obj = {};
    for (let i = 0; i < systemLogsHelper.logCategories.length; i++) {
      obj[i] = new Array(xAxis.length).fill(0);
    }
    if (state.chartData[0]) {
      for (const datum of state.chartData) {
        let index = xAxis.indexOf(datum[0].substring(0, 10));
        obj[datum[1] - 1][index] = datum[2];
      }
    }
    return obj;
  }, [state.chartData]);

  const getOptions = useCustomCompareCallback(() => {
    let xAxis = getXAxis();
    let dataPoints = getDataPoints(xAxis);

    let series = [];
    let legend = [];
    if (!isEmpty(xAxis)) {
      for (let i = 0; i < Object.keys(dataPoints).length; i++) {
        if (!dataPoints[i].every(item => item === 0)) {
          series.push({
            name: t(`legend.${i + 1}`),
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series"
            },
            data: dataPoints[i]
          });
          legend.push(t(`legend.${i + 1}`));
        }
      }
    }

    const options = {
      toolbox: {
        feature: {
          magicType: { 
            type: ["line", "bar"],
            title: {
              line: t("default:widget.chart.lineChart"),
              bar: t("default:widget.chart.barChart")
            }
          },
          saveAsImage: {
            show: true,
            title: t("default:widget.chart.saveImage")
          }
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: theme.palette.primary.dark
          }
        }
      },
      legend: {
        top: theme.spacing(5),
        data: legend
      },
      grid: {
        left: theme.spacing(3),
        right: theme.spacing(4),
        bottom: theme.spacing(4),
        top: `${size ? "15%" : "40%"}`,
        containLabel: true
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: xAxis
        }
      ],
      yAxis: [
        {
          type: "value",
          minInterval: 1
        }
      ],
      series: series
    };

    return options;
  }, [state.chartData, state.start_date, state.end_date, size], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleSearchSubmit = () => handleSubmit(onSubmit({search: state.selectorValues}));
  const handleSearchCancel = () => resetFilter("search");
  
  return (
    <div className={classes.body}>
      <GridContainer className={classes.container}>
        <GridItem xs={false} md={1}></GridItem>
        <GridItem xs={12} md={5} className={classes.systemLogContainer}>
          <Typography variant="body1"><b>{t("default:features.systemLogs")}</b></Typography>
          <div className={classes.header} data-testid="chips">
            <Chip
              className={classes.chip}
              data-testid="date-picker"
              label={
                <FormControl>
                  <DateTimeRangeForm/>
                </FormControl>
              }
            />
            <Chip
              label={
                <div className={classes.header}>
                  <People className={classes.chipIcon}/>
                  <div className={classes.chipText}>
                    <div className={classes.searchContainer}>
                      <span>
                        { state.filterVariables.search?.length > 0 ?
                          `${t("default:features.users")}: ${state.filterVariables.search.length}` :
                          t("default:label.searchUsers")
                        }
                      </span>
                    </div>
                  </div>
                </div>}
              onClick={() => setSearchOpen(true)}
              className={classes.chip}
            />
            <Chip
              label={
                <div className={classes.header}>
                  <Style className={classes.chipIcon}/>
                  <div className={classes.chipText}>
                    <span>{!isEmpty(state.filterVariables.eventType) ? 
                      <span className={classes.subHeader}>
                        <div className={classes.eventText}>{`${renderEventTypes()}`}</div> 
                        <span>({state.filterVariables.eventType.length})</span>
                      </span> : 
                      <span>{t("default:label.eventTypes")}</span>}</span>
                  </div>
                </div>}
              onClick={(event) => {
                setEventTypeOpen(true);
                setAnchorEl(event.currentTarget);
              }}
              className={classes.chip}
            />
            <Chip
              label={
                <div className={classes.header}>
                  <SwapIcon className={classes.chipIcon} pathClassName={classes.red} sortDirection={state.sortDirection} width="20" height="20"/>
                  <p className={classes.chipText}>
                    <span>{state.sortDirection ? <span>{renderDirectionText()}</span> : t("default:label.sortDirection")}</span>
                  </p>
                </div>}
              onClick={handleDirectionChange}
              className={classes.chip}
            /> 
            <Chip
              label={
                <Tooltip classes={{ tooltip: classes.tooltip }} arrow title={state.totalData > MAX_EXPORT_ROWS ? t("default:error.maxExportExceeded") : ""}>
                  <div className={classes.header}>
                    <GetApp className={classes.chipIcon}/>
                    <p className={cx(classes.chipText, {[classes.disabled]: state.totalData > MAX_EXPORT_ROWS})}>
                      <span>{t("default:actions.export")}</span>
                    </p>
                  </div>
                </Tooltip>}
              onClick={() => {state.totalData < MAX_EXPORT_ROWS ? setExportTypeOpen(true) : null;}}
              className={classes.chip}
            /> 
          </div>
          <ErrorBoundary>
            <SystemLogsWidget
              state={state}
              setState={setState}
              setEventType={setEventType}
              getFormattedFilterOptions={getFormattedFilterOptions}
              baseUrl={baseUrl}
              org={org}
              history={history}
            />
          </ErrorBoundary>
        </GridItem>
        <GridItem className={classes.chartContainer} data-testid="chart" xs={12} md={5}>
          <ErrorBoundary>
            <SystemChartsWidget 
              title={t("default:features.systemLogsChart")} 
              startDate={state.start_date} 
              endDate={state.end_date} 
              getOptions={getOptions} 
              chartLoading={state.chartLoading}
              classNameChart={classes.chart}
            />
          </ErrorBoundary>
        </GridItem>
        <GridItem xs={false} md={1}></GridItem>
      </GridContainer>
      
      <CustomModal
        open={state.searchOpen}
        setOpen={setSearchOpen}
        handleSubmit={handleSearchSubmit}
        handleCancel={handleSearchCancel}
        type="custom"
        title={t("default:label.searchUsers")}
        confirm={t("default:button.apply")}
        cancel={t("default:button.reset")}
        manualClose
        description={
          <CustomAsyncSearchForm 
            setSelectorValues={setSelectorValues} 
            placeholder={t("default:label.searchUsers")} 
            fetchWithInput={fetchUsersWithInput} 
            selectorValues={state.selectorValues.map(v => usersForSearch.find(u => u.id === v.id) || v)}
          />
        }
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        cancelButton
        confirmButton
        setValue={setValue}
        modalStyle={classes.modal}
        submit
      />

      <CustomModal
        open={state.eventTypeOpen}
        setOpen={setEventTypeOpen}
        anchorEl={anchorEl}
        type="customPopover"
        description={renderSystemForm()}
        control={control}
        setValue={setValue}
        modalStyle={classes.modal}
        submit
      />
      <CustomModal
        open={state.exportTypeOpen}
        setOpen={setExportTypeOpen}
        handleSubmit={handleSubmit(handleExport)}
        title={t("default:label.exportType")}
        type="formCreator"
        confirm={t("default:actions.export")}
        cancel={t("default:button.cancel")}
        formOptions={[{
          field:"export",
          type: "select",
          defaultValue: "csv",
          options: [
            { name: "CSV", value: "csv" },
            { name: "XLSX", value: "xlsx" }
          ]
        }]}
        control={control}
        setValue={setValue}
        modalStyle={classes.modal}
        submit
      />
    </div>
  );

}

SystemLogs.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string
};

export default SystemLogs;