import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getReportTypeName } from "_helpers/ReportHelper";
import { translateFormatter } from "_utils";

import { Grid } from "@mui/material";

import Placeholder from "_components/Helper/Placeholder";
import PrivateNotification from "_components/Notifier/PrivateNotification";

import {
  NotificationsNone as NotificationsNoneIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/privateNotificationPanelStyle.js";

const useStyles = makeStyles()(styles);

function PrivateNotificationPanel(props) {
  const { classes } = useStyles();
  const { t } = useTranslation(["default"]);
  const { notifications, setNotifications } = props;

  const fallback = () => <Grid item xs={12}>
    <Placeholder  message={t("fallbacks.privateNotifications")} icon={<NotificationsNoneIcon/>} />
  </Grid>;

  const formattedNotif = notifications.map(notif => {
    const formatter = translateFormatter()
      .setLink("download", t("label.download"), notif.access_token);

    const handleCloseNotification = () => {
      const newUserNotifications = [...notifications.filter(n => n.id !== notif.id)];
      setNotifications(newUserNotifications);
    };

    return <Grid item xs={11} key={notif.id}>
      <PrivateNotification
        messageWithLink={t("reports.notification", {reportType: getReportTypeName(notif.report_type, t), ...formatter.getInterpolatedKeys()})}
        createdAt={notif.completed_at}
        closeNotification={handleCloseNotification}
        accessToken={notif.access_token}
      />
    </Grid>;
  });

  const data = notifications.length > 0 ? formattedNotif : fallback();

  return <Grid container justifyContent="center" spacing={1} className={classes.notifications}>
    {data}
  </Grid>;
}

PrivateNotificationPanel.propTypes = {
  notifications: PropTypes.array,
  setNotifications: PropTypes.func
};

export default PrivateNotificationPanel;