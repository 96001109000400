const keyWidgetStyle = (theme) => ({
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  details: {
    marginBottom: "10px"
  },
  deleteKeyMsg: {
    marginTop: "0px"
  },
  editKeyModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "600px"
  },
  filterModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "400px"
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  iconDiv: {
    width: "fit-content"
  },
  invisIcon: {
    visibility: "hidden"
  },
  keyValueItem: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.body1,
    "marginBottom": "5px",
    "& span": {
      "&:nth-of-type(2)": {
        fontWeight: theme.lsyPalette.heavyWeight
      }
    }
  },
  tableBodyCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.subtitle3
  },
  tableHeaderCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.body1,
    whiteSpace: "nowrap"
  },
  title: {
    fontSize: theme.lsyPalette.body3
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  typeIconWrap: {
    display: "flex",
    alignItem: "center",
    padding: "3px 0px"
  }
});

export default keyWidgetStyle;