import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { versionService } from "_services/lockstasy";

import {
  Grid
} from "@mui/material";

import LsyRouter from "_components/Navigation/LsyRouter";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/lsyFooterStyle.js";

export default function LsyFooter(props) {
  const useStyles = makeStyles()(styles);
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const { org } = props;
  const [data, setData] = useState(null);

  const fetchFooterData = async () => {
    try {
      const result = await versionService.fetchVersion();
      setData(result.data);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (org) {
      fetchFooterData();
    }
  }, [org]);

  return (
    <Grid className={classes.lsyFooter} container justifyContent="center">
      {data ? (
        <GridItem className={classes.footer}>
          <span className={classes.footerText}>
            {t("footer.statement", {
              version: `${data.version}/${data.sha}`,
              copyright: data.copyright
            })}
          </span>
          <span className={classes.footerItems}>|</span>
          <a
            className={classes.footerLinks}
            href="http://www.sera4.com/terms-of-use/"
          >
            {t("footer.termsOfService")}
          </a>
          <span className={classes.footerItems}>|</span>
          <a
            className={classes.footerLinks}
            href="http://www.sera4.com/privacy-policy/"
          >
            {t("footer.privacy")}
          </a>
          <span className={classes.footerItems}>|</span>
          <LsyRouter page="about" className={classes.footerLinks}>
            {t("footer.about")}
          </LsyRouter>
        </GridItem>
      ) : null}
    </Grid>
  );
}
LsyFooter.propTypes = {
  org: PropTypes.string
};
