import { MAX_EMAIL_LENGTH } from "_constants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ContainedButton from "_components/Button/ContainedButton";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Auth/forgotPasswordFormStyle";

const useStyles = makeStyles()(styles);

const ForgotPasswordForm = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const { username, isUsernameEmpty, isLoading, setUsername, onCancel, onForgotPassword } = props;

  const handleChangeUsername = event => setUsername(event.target.value);

  const renderUsernamePasswordFields = () => {
    return <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={10}>
        <TextField
          variant="outlined"
          fullWidth required
          error={isUsernameEmpty}
          id="login.name"
          label={`${t("auth:login.email")}/${t("auth:login.username")}`}
          inputProps={{maxLength: MAX_EMAIL_LENGTH}}
          name="username"
          autoComplete="username"
          value={username}
          autoFocus={true}
          onChange={handleChangeUsername}
          helperText={isUsernameEmpty ? t("error.requiredField") : null}
        />
      </Grid>
    </Grid>;
  };

  const renderContainedButton = () => {
    return <Grid item xs={10}>
      <ContainedButton
        fullWidth
        variant="contained"
        disabled={isLoading}
        color="secondary"
        onClick={onForgotPassword}
      >
        {isLoading ?
          <CircularProgress className={classes.spinner} size={30} /> :
          t("auth:login.resetTitle")}
      </ContainedButton>
    </Grid>;
  };

  const renderFooter = () => {
    return <Grid container justifyContent="center" alignItems="center" className={classes.topMargin}>
      <Grid item xs={10}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button variant="text" disableRipple size="small" className={classes.button} onClick={onCancel}>
              {t("button.cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  return <form name="forgotForm" onSubmit={onForgotPassword}>
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
      <Grid item>
        {renderUsernamePasswordFields()}
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          {renderContainedButton()}
        </Grid>
      </Grid>
      <Grid item>
        {renderFooter()}
      </Grid>
    </Grid>
  </form>;
};

ForgotPasswordForm.propTypes = {
  username: PropTypes.string,
  isUsernameEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  setUsername: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired
};

export default ForgotPasswordForm;