import { useState, useEffect, useCallback, useContext, useMemo, useReducer, Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual, isEmpty } from "lodash";
import PropTypes from "prop-types";

import { adminConstants } from "_constants/admin.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter } from "_helpers";
import { setTitle } from "_helpers/page-title";
import { genericReducer } from "_reducers/general.reducer";
import { announcementService } from "_services/lockstasy";

// @mui/material components
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import {
  AccountBox as AccountBoxIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon
} from "@mui/icons-material";

//Components
import LsyRightDrawer from "_components/Lockstasy/LsyRightDrawer";
import LsyLeftDrawer from "_components/Lockstasy/LsyLeftDrawer";
import LsyNav from "_components/Lockstasy/LsyNav";
import GridItem from "components/Grid/GridItem";

//Assets
import Sera4Icon from "assets/teleporte/Sera4Icon";
import teleporteImage from "assets/images/logos/teleporte-logo.svg";

//Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/lsyHeaderStyle.js";

const useStyles = makeStyles()(styles);

function LsyHeader(props) {
  let location = useLocation();
  const [announcement, setAnnouncement] = useState(null);
  const [userNotifications, setUserNotifications] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();

  const [drawerStatus, setDrawerStatus] = useReducer(genericReducer, {
    left: false,
    right: false,
    renderRightContent: "user"
  });
  const currentFeatures = props.currentMembership
    ? props.currentMembership.features
    : null;
  const language = useSelector((state) => state.locale.language);
  const authUser = useSelector((state) => {
    return {
      firstName: state.auth.user.first_name,
      lastName: state.auth.user.last_name
    };
  });
  const currentMembershipRole = props.currentMembership?.role_id;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const { t } = useTranslation("default");
  const { classes } = useStyles();

  const { org, baseUrl } = props;

  const toggleDrawer = (anchor, open, content) => (event) => {
    if (event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerStatus({ [anchor]: open, ...open && { renderRightContent: content } });
  };

  const getAnnouncement = useCallback(
    async (options) => {
      try {
        var result = await announcementService.fetchAnnouncement(options);
        if (result.length !== 0) {
          setAnnouncement(result[0].maintenance_window ?
            `${t("error.maintenanceMode")}. ${t("error.maintenanceModeMessage")}` :
            result[0].contents);
        } else if (announcement !== null) {
          setAnnouncement(null);
        }
      } catch (e) {
        console.warn(e);
      }
    }, [announcement, t]);

  useEffect(() => {
    if (org) {
      getAnnouncement({
        language: language
      });
    }
  }, [getAnnouncement, org, language]);

  useEffect(() => {
    setSelectedRoute(`/${location.pathname.split("/")[3]}`);
  }, [location]);

  const hasNotifications = useMemo(() => !isEmpty(userNotifications), [userNotifications]);

  const handleReportAlert = (e) => {
    const { object } = e.detail;
    if (object) {
      setUserNotifications(prevUserNotif => [object, ...prevUserNotif]);
    }
  };

  useCustomCompareEffect(() => {
    window.addEventListener("report", handleReportAlert);

    return () => {
      window.removeEventListener("report", handleReportAlert, false);
    };
  }, [handleReportAlert], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  // showLevel: 0 = "always show but disable if no perm", 1 = "grey out if no license or no perm",
  //  2 = "don't show if no license or no perm"

  const dropdownData = [
    {
      preImage: "Sera4Logo",
      route: "/dashboard",
      text: t("features.dashboard"),
      feature: "dashboard",
      permission: true,
      showLevel: 0,
      divider: true
    },
    {
      sectionHeader: true,
      text: t("features.locks"),
      feature: "locks",
      showLevel: 0,
      permission: true,
      sectionFeatures: [
        {
          route: "/locks",
          text: t("features.locks"),
          feature: "",
          showLevel: 0,
          permission: ability.can("read", "locks"),
          tab: 2
        },
        {
          route: "/lock_notes",
          text: t("features.lockNotes"),
          feature: "lock_notes",
          showLevel: 1,
          permission: ability.can("read", "lock_notes")
        }
      ]
    },
    {
      sectionHeader: true,
      text: t("features.users"),
      feature: "",
      showLevel: 0,
      permission: true,
      sectionFeatures: [
        {
          route: "/users",
          text: t("features.users"),
          feature: "",
          showLevel: 0,
          permission: ability.can("read", "users"),
          tab: 1
        },
        {
          route: "/access_requests",
          text: t("features.accessRequests"),
          feature: "key_requests",
          permission: ability.can("read", "access_requests"),
          showLevel: 1
        }
      ]
    },
    {
      sectionHeader: true,
      text: t("features.groups"),
      feature: "",
      showLevel: 0,
      sectionFeatures: [
        {
          route: "/lock_groups",
          text: t("features.lockGroups"),
          feature: "lock_groups",
          permission: ability.can("read", "lock_groups"),
          showLevel: 1
        },
        {
          route: "/tags",
          text: t("features.tags"),
          feature: "",
          permission: ability.can("read", "tags"),
          showLevel: 0
        },
        {
          route: "/sites",
          text: t("features.lockCollections"),
          feature: "lock_collections",
          permission: ability.can("read", "lock_collections"),
          showLevel: 1,
          tab: 0
        }
      ]
    },
    {
      sectionHeader: true,
      text: t("features.analytics"),
      feature: "",
      showLevel: 0,
      sectionFeatures: [
        {
          route: "/locations",
          text: t("features.locations"),
          permission: ability.can("read", "locations"),
          feature: "maps",
          showLevel: 1
        },
        {
          route: "/charts",
          text: t("features.charts"),
          feature: "charts",
          permission: ability.can("read", "charts"),
          showLevel: 1
        },
        {
          route: "/reports/access_history",
          text: t("features.reports"),
          feature: "",
          permission: ability.can("read", "reports"),
          showLevel: 0
        },
        {
          route: "/system_logs",
          text: t("features.systemLogs"),
          feature: "",
          permission: ability.can("read", "system_logs"),
          showLevel: 0
        }
      ]
    },
    {
      sectionHeader: true,
      text: t("features.developers"),
      showLevel: 2,
      permission: ability.can("update", "organizations"),
      sectionFeatures: [
        {
          route: "/developers",
          text: t("features.api"),
          feature: "api_tokens",
          permission: ability.can("update", "organizations"),
          showLevel: 2
        },
        {
          url: "https://apidocs.sera4.com",
          text: t("features.apiDocumentation"),
          feature: "api_tokens",
          permission: ability.can("update", "organizations"),
          showLevel: 2
        }
      ]
    },
    {
      sectionHeader: true,
      text: t("features.system"),
      showLevel: 3,
      permission: ability.can("update", "organizations"),
      sectionFeatures: [
        {
          route: "/systemsettings",
          text: t("features.settings"),
          feature: "",
          permission: ability.can("update", "organizations"),
          showLevel: 3
        }
      ]
    }
  ];

  const menuButton = () => {
    return (
      <IconButton onClick={toggleDrawer("left", true)} size="medium">
        <MenuIcon fontSize="large" className={classes.menuIcon} />
      </IconButton>
    );
  };

  const userButton = () => {
    return (
      <Tooltip title={t("button.moreOptions")} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} placement="bottom" arrow>
        <Button
          className={classes.userButton}
          onClick={toggleDrawer("right", true, "user")}
          startIcon={<AccountBoxIcon style={{ marginBottom: 2 }} />}
        >
          <span className={classes.authUserName}>
            {authUser.firstName}
          </span>
        </Button>
      </Tooltip>
    );
  };

  const notificationsButton = () => {
    return (
      <Tooltip 
        title={hasNotifications ? t("label.newNotifications") : t("label.notifications")} 
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} 
        placement="bottom"
        arrow
      >
        <Button
          className={classes.notificationsButton}
          onClick={toggleDrawer("right", true, "notifications")}
          startIcon={<NotificationsIcon style={{ marginBottom: 2, fontSize: 20 }}/>}
        >
          {hasNotifications && <div className={classes.pulse}/>}
        </Button>
      </Tooltip>
    );
  };

  const showLogo = () => {
    return (
      <div
        className={classes.logoImageDiv}
        onClick={() => {
          setTitle("Dashboard");
          props.history.push(lsyRouter("dashboard"));
        }}
      >
        <Sera4Icon animated={true} style={{ height: "42px", margin: "4px" }} />
        <div className={classes.tpLogoContainer}>
          <img
            src={teleporteImage}
            className={classes.tpLogo}
            alt="Teleporte"
          />
          <span className={classes.designation}>{props.currentMembership?.tenant_name}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.header}>
      <Grid container justifyContent="center" alignItems="center">
        <GridItem xs={4} md={1}>
          {currentMembershipRole === adminConstants.LSY_USER ? null : menuButton()}
          <Drawer
            disableScrollLock
            anchor="left"
            open={drawerStatus["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {props.currentMembership ? (
              <LsyLeftDrawer
                org={props.org}
                setSelectedRoute={setSelectedRoute}
                selectedRoute={selectedRoute}
                dropdownData={dropdownData}
                currentFeatures={currentFeatures}
                baseUrl={baseUrl}
                history={props.history}
                setCurrentNavTab={props.setCurrentNavTab}
                toggleDrawer={toggleDrawer}
              />
            ) : null}
          </Drawer>
        </GridItem>
        <GridItem xs={4} md={3}>
          {showLogo()}
        </GridItem>
        <GridItem
          md={5}
          lg={6}
          className={announcement ? null : classes.navItem}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {props.org === "" ? (
            <Fragment />
          ) : (
            <Fragment>
              { currentMembershipRole === adminConstants.LSY_USER ? null :
                <LsyNav
                  org={props.org}
                  baseUrl={baseUrl}
                  history={props.history}
                  currentNavTab={props.currentNavTab}
                  setCurrentNavTab={props.setCurrentNavTab}
                  features={props.currentMembership.features}
                  selectedRoute={selectedRoute}
                />}
            </Fragment>
          )}
        </GridItem>
        <GridItem
          xs={4}
          md={3}
          lg={2}
          className={announcement ? null : classes.userItem}
          style={{ marginBottom: 5 }}
        >
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              {userButton()}
            </Grid>
            <Grid item>
              {notificationsButton()}
            </Grid>
          </Grid>
          <Drawer
            disableScrollLock
            anchor="right"
            open={drawerStatus["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <LsyRightDrawer
              onChange={props.onChange}
              toggleDrawer={toggleDrawer}
              authUser={authUser}
              language={language}
              org={props.org}
              memberships={props.memberships}
              history={props.history}
              baseUrl={baseUrl}
              userNotifications={userNotifications}
              setUserNotifications={setUserNotifications}
              content={drawerStatus.renderRightContent}
            />
          </Drawer>
        </GridItem>
      </Grid>
      {
        announcement ? (
          <Fragment>
            <Grid container justifyContent="center">
              <GridItem xs={12} md={8}>
                <Divider className={classes.divider} />
              </GridItem>
            </Grid>
            <Grid container justifyContent="center">
              <GridItem className={classes.announcementContainer}>
                <span className={classes.announcement}>{announcement}</span>
              </GridItem>
            </Grid>
          </Fragment>
        ) : null
      }
    </div >
  );
}

LsyHeader.propTypes = {
  onChange: PropTypes.func,
  org: PropTypes.string,
  currentMembership: PropTypes.object,
  history: PropTypes.object,
  memberships: PropTypes.array,
  currentNavTab: PropTypes.number,
  setCurrentNavTab: PropTypes.func,
  getAnnouncement: PropTypes.func,
  baseUrl: PropTypes.string
};

export default LsyHeader;
