const connectedIconStyle = theme => ({
  cls1: {
    fill: theme.lsyPalette.primary.main,
    strokeMiterlimit: 10,
    strokeWidth: ".64px"
  },
  cls2: {
    fill: theme.lsyPalette.primary.main,
    fontFamily: "Helvetica",
    fontSize: "7.37px",
    fontWeight: 100
  },
  cls3: {
    fill: theme.lsyPalette.primary.main,
    letterSpacing: "-.07em"
  },
  boldFont: {
    fontWeight: "bold"
  }
});

export default connectedIconStyle;