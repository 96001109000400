import { useCallback, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useCustomCompareEffect, useCustomCompareMemo } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";

import { LOCK_STATUS } from "_constants/lock.constants";
import { NOTE_TYPE } from "_constants/resourceNote.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { findNoteErrorString, hasAbility } from "_helpers";
import { lockNoteService } from "_services/lockstasy";

// @mui/material components
import {
  Button,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";

//components
import CustomModal from "_components/Modal/CustomModal";
import Placeholder from "_components/Helper/Placeholder";
import LockNoteCard from "_containers/Cards/LockNoteCard";
import LockNoteDetailsWidget from "_containers/Widgets/LockNoteDetailsWidget";

//icons
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  List
} from "@mui/icons-material";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/lockNoteWidgetStyle.js";

const useStyles = makeStyles()(styles);

function LockNoteWidget(props) {
  const { user, history, isRegularUser, lock, type } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector(
    (state) => state.memberships.currentMembership
  );
  const [noteModal, setNoteModal] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: false,
      note: null
    }
  );
  const setNoteModalOpen = (open) => setNoteModal({ open });
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      lockNotes: [],
      images: [],
      page: 1,
      totalData: 0,
      loading: true,
      errorMsg: "",
      groups: [],
      sites: []
    }
  );
  const setLoading = (loading) => setState({ loading });
  const setGroups = useCallback(newGroups => setState({ groups: [...state.groups, ...newGroups] }), [state.groups]);
  const setSites = useCallback(newSites => setState({ sites: [...state.sites, ...newSites] }), [state.sites]);
  const addImages = useCallback((images) => {
    if (isEmpty(images)) {
      return;
    }

    const newImages = images.filter(img => !state.images.some(ni => ni.id === img.id));

    setState({ images: [...state.images, ...newImages] });
  }, [state.images]);

  const shouldDisplayLockNotesButton = hasAbility(currentMembership, "view", "lock_notes") && ability.can("read", "lock_notes");

  const fetchAllImages = useCallback(async () => {
    const results = [];

    for (const note of state.lockNotes) {
      const img = state.images.find(img => note.photos.some(photo => photo.id === img.id));

      if (isEmpty(note.photos) || img) {
        continue;
      }
        
      try {
        const result = await lockNoteService.fetchImage({ id: note.photos[0].id });
        results.push({ id: note.photos[0].id, data: result.data });
      } catch(e) {
        console.warn("Warning, failed to fetch image ", e?.response);
      }
    }

    addImages(results);
  }, [state.lockNotes, state.images, addImages]);

  const fetchLockNotes = async () => {
    try {
      let options = {
        page_size: 6,
        page: state.page,
        note_type: NOTE_TYPE.lock
      };

      let result;

      if (type === "lock") {
        options["resource_ids"] = lock?.id;
        result = await lockNoteService.fetchResourcesNotes({...options, lock_status: LOCK_STATUS.all});
      } else if (type === "user"){
        options["membership_id"] = user?.membership_id;
        result = await lockNoteService.fetchResourcesNotes(options);
      }

      setState({
        lockNotes: result.data,
        totalData: result.meta.pagination.total,
        loading: false,
        errorMsg: ""
      });
    } catch (e) {
      const errorMsg = t(findNoteErrorString(e, {
        defaultMsg: `${t("fallbacks.noLockNotesFound")} (${t("error.couldNotFetch")})`
      }));
      setState({ loading: false, errorMsg: errorMsg });
      console.warn("Warning, failed to fetch lock notes", e);
    }
  };

  useCustomCompareEffect(
    () => {
      if (user?.membership_id) {
        setLoading(true);
        fetchLockNotes();
      }
      if (lock?.id) {
        setLoading(true);
        fetchLockNotes();
      }
    },
    [user, state.page, lock],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  useCustomCompareEffect(() => {
    fetchAllImages();
  }, [state.lockNotes], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleOpenLockNotes = () => {
    if (type === "user") {
      history.push(
        `/lock_notes?${queryString.stringify({
          noteType: NOTE_TYPE.lock,
          membershipId: user.membership_id
        })}`
      );
    } else {
      history.push(
        `/lock_notes?${queryString.stringify({
          noteType: NOTE_TYPE.lock,
          lockId: lock.id,
          ...lock.deactivated && {lockStatus: LOCK_STATUS.deactivated}
        })}`
      );
    }
  };

  const lockNoteDetailsWidget = useCustomCompareMemo(() => {
    const note = noteModal.note || {};

    return <LockNoteDetailsWidget 
      data={note} 
      images={state.images}
      history={history}
      classNameImage={classes.image}
      groups={state.groups}
      sites={state.sites}
      setGroups={setGroups}
      setSites={setSites}
      canOpenLockSummary={!isRegularUser && type === "user" && ability.can("read", "locks")}
      canOpenUserSummary={!isRegularUser && type === "lock" && ability.can("read", "users")}
      canOpenSiteSummary={!isRegularUser && ability.can("read", "lock_collections")}
    />;
  }, [noteModal, state, classes, history, isRegularUser, type, ability, setGroups, setSites], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleOpenNote = note => {
    setNoteModal({
      open: true,
      note: note
    });
  };

  const renderLastNote = useCustomCompareMemo(() => {
    const note = state.lockNotes[0];
    const openNoteModal = () => handleOpenNote(note);

    return note &&
    <LockNoteCard 
      note={note}
      onClick={openNoteModal}
      images={state.images}
      showLockName={type === "user"}
    />;
  }, [state.lockNotes, state.images], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderPreviousNotes = useCustomCompareMemo(() => {
    let previousNotes = [];
    state.lockNotes.forEach((note, i) => {
      if (i > 0 && !isEmpty(note.photos)) {
        const openNoteModal = () => handleOpenNote(note);

        previousNotes.push(<Grid item key={note.id}>
          <LockNoteCard
            note={note}
            onClick={openNoteModal}
            images={state.images}
            showLockName={type === "user"}
            isShortCard={true}
          />
        </Grid>);
      }
    });

    return isEmpty(previousNotes) ?
      <Placeholder message={t("fallbacks.previousNotesPhotos")} icon={<List/>} /> :
      previousNotes;
  }, [state.lockNotes, state.images], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderCardNotes = () => {
    return isEmpty(state.lockNotes) ?
      <Grid item className={classes.fallback}><Placeholder message={state.errorMsg || t("fallbacks.noLockNotesFound")} icon={<List/>} /></Grid> :
      <>
        <Grid item data-testid="lastNoteSection">
          <Typography>{t("label.lastNote")}</Typography>
          { renderLastNote }
        </Grid>
        <Grid item data-testid="previusNotesSection">
          <Typography>{t("label.previousNotesPhotos")}</Typography>
          <Grid container alignItems="center" spacing={4}>
            {renderPreviousNotes}
          </Grid>
        </Grid>
      </>;
  };

  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.paddingBottom}>
        <Grid item data-testid="headerSection">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <span className={classes.title}>{t("features.lockNotes")}</span>
            </Grid>
            <Grid item>
              { shouldDisplayLockNotesButton &&
                <Button
                  startIcon={<DescriptionOutlinedIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={handleOpenLockNotes}
                >
                  {t("button.seeAllNotes")}
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
        { state.loading ?
          <Grid item>
            <Skeleton data-testid="skeleton" height={100} />
            <Skeleton data-testid="skeleton" height={400} />
          </Grid> :
          renderCardNotes()
        }
      </Grid>
      <CustomModal
        open={noteModal.open}
        setOpen={setNoteModalOpen}
        type="alert"
        confirm={t("button.close")}
        description={lockNoteDetailsWidget}
        modalStyle={classes.noteModal}
        submit
      />
    </>
  );
}

LockNoteWidget.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  lock: PropTypes.object,
  type: PropTypes.oneOf(["lock", "user"]),
  isRegularUser: PropTypes.bool
};

export default LockNoteWidget;
