const lsyUsersStyle = (theme) => ({
  userCard: {
    minWidth: 275,
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  pos: {
    marginBottom: 0
  },
  tags: {
    marginBottom: 0,
    display: "inline"
  },
  chips: {
    display: "inline"
  },
  userCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto"
  },
  cardContent: {
    flexGrow: "1",
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  userDelete: {
    marginRight: theme.spacing(3),
    color: theme.lsyPalette.primary.main
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)"
  },
  icon2: {
    fill: theme.lsyPalette.primary.main
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  personIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  userName: {
    color: theme.lsyPalette.primary.main,
    cursor: "pointer",
    fontSize: theme.lsyPalette.title1
  },
  inactiveOrSuspendedUser: {
    color: theme.lsyPalette.primary.mainLight
  },
  field: {
    color: theme.lsyPalette.primary.mainLight,
    fontSize: theme.lsyPalette.body1
  },
  value: {
    marginLeft: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1
  },
  actionLink: {
    marginLeft: theme.spacing(1),
    color: theme.lsyPalette.link,
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.secondary.main
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  },
  userWidget: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  chip: {
    marginRight: "0.7em",
    marginBottom: "0.3em"
  },
  enabled: {
    fontSize: "12px",
    backgroundColor: "#41c300",
    fontWeight: "bold",
    color: "white"
  },
  button: {
    textTransform: "none",
    fontSize: theme.lsyPalette.body1,
    marginRight: theme.spacing(4.5)
  }
});
export default lsyUsersStyle;
