import TwsResource from "./tws-resource";

export default new TwsResource({
  endpoint: "identity",
  resource: "tenants",
  name: "twsOrganizations",
  requests: {
    query: { method: "GET", isArray: true, url: "tenants" }
  },
  processors: {
    query: ((data, options = {}) => {
      const { search, tenant_id } = options;
      if (tenant_id) {
        // Filter tenants by id
        return [data.find(org => org.id == tenant_id)];
      } else if (search) {
        // Filter by org name
        return data.filter(org => org.name.toLowerCase().includes(search.toLowerCase()));
      } else {
        // No filter
        return data;
      }
    })
  },
  postUpdateFunctions: (data, options) => {
    const { setOrganizationList } = options;
    if (setOrganizationList) {
      setOrganizationList(data);
    }
  }
}); 

