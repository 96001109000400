function getUser(openedVia, user, t) {
  if (!openedVia && !user) {
    return { name: "" };
  }

  switch(openedVia) {
    case 9:
      return { name: t("accessHistory.lock.event.38") };
    case 2:
      return { name: t("accessHistory.lock.event.40") };
    case 3:
      return { name: t("accessHistory.lock.log.74") };
    case 4:
    case 5:
    case 7:
      return { name: t("accessHistory.lock.openWithoutTeleporte") };
    case(10):
      return { name: t("accessHistory.lock.unlockedViaVAP") };
    default:
      return { ...user, action: true };
  }
}

export const accessHistoryHelper = {
  getUser
};