import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/connectedIconStyle";
const useStyles = makeStyles()(styles);

// when the icon is too small, we need to remove the font bolding to ensure blurring doesn't occur
const BOLDING_TOLERANCE = 50;

function ConnectedIcon(props) {
  const { classes, cx } = useStyles();

  const addFontBolding = (props.width && parseInt(props.width) >= BOLDING_TOLERANCE) ||
                            (props.height && parseInt(props.height) >= BOLDING_TOLERANCE);

  const connectedIconStyle = () => {
    return(
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={props.width} height={props.height} viewBox="0 0 24 24" className={cx(classes.row, props.className)} xmlSpace="preserve">
        <rect className={cx(classes.cls1, props.maskClass)} x=".76" y="13.84" width="4.64" height="9.3" rx=".2" ry=".2"/>
        <rect className={cx(classes.cls1, props.maskClass)} x="6.7" y="9.84" width="4.64" height="13.31" rx=".2" ry=".2"/>
        <rect className={cx(classes.cls1, props.maskClass)} x="12.65" y="5.84" width="4.64" height="17.31" rx=".2" ry=".2"/>
        <rect className={cx(classes.cls1, props.maskClass)} x="18.6" y="1.83" width="4.64" height="21.31" rx=".2" ry=".2"/>
        <text className={cx(classes.cls2, props.maskClass, { [classes.boldFont]: addFontBolding})} transform="translate(1.05 6.89)">
          <tspan className={classes.cls3} x="0" y="0">5</tspan>
          <tspan x="4.04" y="0">G</tspan>
        </text>
      </svg>
    );
  };

  const disconnectedIconStyle = () => {
    return(
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={props.width} height={props.height} viewBox="0 0 24 24" className={cx(classes.row, props.className)} xmlSpace="preserve">
        <rect className={cx(classes.cls2, props.maskClass)} x=".71" y="13.84" width="4.64" height="9.3" rx=".2" ry=".2"/>
        <g>
          <rect className={cx(classes.cls1, props.maskClass)} x="4.1" y=".68" width=".86" height="7.09" rx=".19" ry=".19" transform="translate(4.31 -1.97) rotate(45)"/>
          <rect className={cx(classes.cls1, props.maskClass)} x="4.1" y=".68" width=".86" height="7.09" rx=".19" ry=".19" transform="translate(10.72 4.01) rotate(135)"/>
        </g>
        <rect className={cx(classes.cls2, props.maskClass)} x="6.66" y="9.84" width="4.64" height="13.31" rx=".2" ry=".2"/>
        <rect className={cx(classes.cls2, props.maskClass)} x="12.61" y="5.84" width="4.64" height="17.31" rx=".2" ry=".2"/>
        <rect className={cx(classes.cls2, props.maskClass)} x="18.55" y="1.83" width="4.64" height="21.31" rx=".2" ry=".2"/>
      </svg>
    );
  };

  return (
    <>
      { props.connected ? connectedIconStyle() : disconnectedIconStyle() }
    </>
  );
}

ConnectedIcon.defaultProps = {
  connected: true,
  width: "50px",
  height: "50px"
};

ConnectedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  maskClass: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  connected: PropTypes.bool
};

export default ConnectedIcon;