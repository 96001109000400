import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { deepFreeze } from "_services/lockstasy/helper";

import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Date/dateTimeRangeStyle.js";

const useStyles = makeStyles()(styles);

const quickTimeOptions = deepFreeze([
  { name: "W", value: "week", quantity: 1, tooltipMessage: "label.lastWeek" },
  { name: "M", value: "month", quantity: 1, tooltipMessage: "label.lastMonth" },
  { name: "Y", value: "year", quantity: 1, tooltipMessage: "label.lastYear" }
]);

function QuickTimeOptions(props) {
  const { setDateRange, startDate  } = props;
  const { t } = useTranslation("default");
  const { classes, cx } = useStyles();

  const handleRange = (quantity, type) => {
    setDateRange([moment().subtract(quantity, type), moment()]);
  };

  const checkSelected = (value, quantity) => {
    if (!startDate) {
      return false;
    }
    
    const evalStartDate = moment().subtract(quantity, value).format("DD-MM-YYYY");
    const actualStartDate = startDate.format("DD-MM-YYYY");
    return evalStartDate === actualStartDate;
  };

  return (
    <div className={classes.toggleContainer}>
      {quickTimeOptions.map((toggleButtonProp, k) => {
        const rangeUnit = t(`button.range.${toggleButtonProp.name}`);
        return (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={t(toggleButtonProp.tooltipMessage)}
            key={k}
          >
            <span>
              <ToggleButton 
                data-testid={`quickTimeOption${toggleButtonProp.name}`}
                className={cx(classes.toggleButton, { [classes.buttonActive]: checkSelected(toggleButtonProp.value, toggleButtonProp.quantity), [classes.buttonInactive]: !checkSelected(toggleButtonProp.value, toggleButtonProp.quantity) })}
                value={toggleButtonProp.value}
                selected={checkSelected(toggleButtonProp.value, toggleButtonProp.quantity)}
                onClick={() => handleRange(toggleButtonProp.quantity, toggleButtonProp.value)}
              >
                {`${toggleButtonProp.quantity}${rangeUnit}`}
              </ToggleButton>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}

QuickTimeOptions.propTypes = {
  setDateRange: PropTypes.func,
  startDate: PropTypes.object
};

export default QuickTimeOptions;