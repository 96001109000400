import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import appRoutes from "_routes";

export function useLsyHistory() {
  const history = useHistory();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const org = currentMembership?.server_alias;
  const baseUrl = appRoutes.private.lockstasy;

  const getUrl = route => `${baseUrl}/${org}${route}`;

  const newHistory = {
    ...history,
    // send the EVENT as well if you want to enable it being able to be opened in a new tab (optional)
    push1: (route, event) => {
      if (event) {
        // META OR CTRL + Click
        if (event.metaKey || event.ctrlKey) {
          return window.open(route, "_blank");
        }
      }
      history.push(route);
    },
    replace1: history.replace,
    push: (route, event) => {
      const url = getUrl(route);
      if (event) {
        // META OR CTRL + Click
        if (event.metaKey || event.ctrlKey) {
          return window.open(url, "_blank");
        }
      }
      history.push(url);
    },
    pushWithState: (route, state) => {
      const url = getUrl(route);
      history.push(url, state);
    },
    replace: (route, state) => {
      const url = getUrl(route);
      history.replace(url, state ? state : history.location?.state);
    },
    id: Math.random()
  };

  return newHistory;
}