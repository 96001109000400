import { getUuidParams } from "_services/admin/cache";

export async function fetchUuidNames(data, uuidResource, field) {
  let params = getUuidParams(data, uuidResource.fetchAllFromCache(), field);
  let result;

  if (params.length > 0) {
    if (params.length > 50) {
      // Split up the uuids into groups of 50
      let smallerParams = [];
      while (params.length) {
        smallerParams.push(params.splice(0, 50));
      }

      for (const param of smallerParams) {
        result = await uuidResource.query({ refresh: true, q: { uuids: JSON.stringify(param) } });
      }
    } else {
      result = await uuidResource.query({ refresh: true, q: { uuids: JSON.stringify(params) } });
    }

    return result;
  } else {
    return uuidResource.fetchAllFromCache();
  }
}