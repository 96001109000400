import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { config } from "_configs/server-config";
import { connect } from "react-redux";
import { Paper, Typography, Grid } from "@mui/material";
import { withStyles } from "tss-react/mui";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody";
import StaticAdminLink from "_components/Button/AdminButton";
import TrustedAdminLink from "_components/Button/TrustedAdminButton";
import Placeholder from "_components/Helper/Placeholder";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { memberActions, alertActions, modalActions, authActions } from "_actions";

import classnames from "classnames";
import LogoutButton from "_components/Button/Logout";
import { memberService } from "_services/member.service";
import { authService } from "_services";

import { membershipHelper, sortByKey } from "_helpers";

import { translateFormatter } from "_utils";
import { has, isEmpty, reject } from "lodash";

import LoadingPlaceHolder from "_components/Loading";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Grow from "@mui/material/Grow";
import LibraryBooksOutlined from "@mui/icons-material/LibraryBooksOutlined";

// import membershipStyles
const listStyles = theme => ({
  ...styles,
  selectionDiv: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px"
  },
  updateButton: {
    "color": theme.lsyPalette.textContrast,
    "backgroundColor": theme.lsyPalette.stdRed,
    "marginRight": "5px",
    "&:hover": {
      backgroundColor: theme.lsyPalette.stdRed
    }
  },
  selectionTitle: {
    fontSize: theme.lsyPalette.title2,
    marginTop: "0px",
    fontWeight: theme.lsyPalette.heavyWeight
  },
  buttonContainer: {
    position: "absolute",
    bottom: "2%",
    right: "2%"
  },
  buttonContainer2: {
    flex: "1",
    marginLeft: "4%"
  },
  message: {
    marginTop: theme.spacing(2),
    fontSize: theme.lsyPalette.title2
  },
  icon: {
    fontSize: theme.icon.xLarge
  }
});

class MembershipList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
      showOption: false
    };
  }

  useMembership = (m, e) => {
    // autoselect doesn"t have a click event
    const membershipSelected = m;
    if (e)
      e.preventDefault();

    this.updateToPortal(membershipSelected);
  };

  updateToPortal = (m) => {
    let { targetPath } = this.props;
    if (targetPath.startsWith("/")) {
      targetPath = targetPath.replace("/", "");
    }
    this.props.onMembershipSelected(m);
    this.props.history.replace(`/tenants/${m.server_alias}/${targetPath}`);
  };

  confirmPendingConsent = async (membership) => {
    const accountId = this.props.auth.user.id;

    try {
      await memberService.confirmPendingConsent(accountId, membership.id, membership.server_alias);
      const membershipUpdated = {
        ...membership,
        status: 1
      };
      this.props.membershipUpdate(membership.tenant_id, { status: membershipUpdated.status });
      this.setState({ redirecting: true });
      this.useMembership(membershipUpdated);
    } catch (e) {
      this.props.onNotification(this.props.t("memberships:membership.consent.error"), "error");
    }
    this.props.hideModal();
  };

  navigateToAdminPanel = (el) => {
    this.props.onMembershipSelected(el);
    this.props.history.push(`${config.adminUrl}/dashboard`);
  };

  navigateToTrustedAdminPanel = (el) => {
    this.props.onMembershipSelected(el);
    this.props.history.push(`${config.tapUrl}`);
  };

  sendPasswordlessEmail = async () => {
    try {
      await authService.loginPasswordless({ username: this.props.auth.user.email });
    } catch (e) {
      console.debug("Error to send email", e);
    }
  };

  handleUnauthorizedAccess = () => {
    this.props.logout();
  };

  openMembershipActionModal = (m) => {
    const options = {
      open: true
    };

    const formatter = translateFormatter();
    formatter.setBoldKeys(m);

    if (membershipHelper.isDisabled(m)) {
      options["title"] = this.props.t("memberships:membership.suspended.title");
      options["message"] = this.props.t("memberships:membership.suspended.body", formatter.getInterpolatedKeys());
      options["customButtonText"] = this.props.t("default:button.dismiss");
    } else if (!m.authorized) {
      options["title"] = this.props.t("memberships:membership.unauthorized.title");
      options["message"] = this.props.t("memberships:membership.unauthorized.body", formatter.getInterpolatedKeys());
      options["cancelButtonText"] = this.props.t("default:button.ok");
      options["onClose"] = this.handleUnauthorizedAccess;
      this.sendPasswordlessEmail();
    } else  if (membershipHelper.isPending(m)) {
      formatter.setBoldText("user_name", this.props.auth.user.first_name);
      formatter.setBoldText("name", this.props.t("default:form.name"));
      formatter.setBoldText("email", this.props.t("auth:login.emailAddress"));
      options["title"] = this.props.t("memberships:membership.consent.required");
      options["message"] = this.props.t("memberships:membership.consent.pleaseAccept", formatter.getInterpolatedKeys());
      options["customButtonText"] = this.props.t("default:button.accept");
      options["cancelButtonText"] = this.props.t("default:button.decline");
      options["customButtonHandler"] = () => this.confirmPendingConsent(m);
    } else {
      // this will be a bug, so let"s show
      // an empty dialog so users can report it
    }
    this.props.showModal(options, "custom");
  };

  hasMemberships = () => {
    return this.props.memberships && this.props.memberships.length > 0;
  };

  handleSera4talAccess = () => {
    const adminMembership = membershipHelper.getSera4talAdmimMembership(this.props.memberships);

    if (adminMembership.authorized) {
      this.navigateToAdminPanel.bind(this, adminMembership)();
      return;
    }

    const formatter = translateFormatter();

    const options = {
      open: true,
      title: this.props.t("memberships:membership.enforceMFA.title"),
      message: this.props.t("memberships:membership.enforceMFA.body", formatter.getInterpolatedKeys())
    };
    
    this.props.showModal(options, "alert");
  };

  adminLink = () => {
    const adminMembership = membershipHelper.getSera4talAdmimMembership(this.props.memberships);

    if (!this.hasMemberships() || isEmpty(adminMembership)) {
      return null;
    }

    return <StaticAdminLink adminMembershipClick={this.handleSera4talAccess} text="SERA4TAL" />;
  };

  trustedAdminLink = () => {
    if (!this.hasMemberships()) {
      return null;
    }
  
    const trustedAdminMembership = membershipHelper.getTrustedAdminMembership(this.props.memberships);

    if (trustedAdminMembership) {
      const adminClick = this.navigateToTrustedAdminPanel.bind(this, trustedAdminMembership);
      return (<TrustedAdminLink adminMembershipClick={adminClick} />);
    }
    return null;
  };

  findAutoSelectMembership = () => {
    const { targetSubdomain, memberships } = this.props;
    let autoSelectMembership = null;

    // We should auto select a membership in the following use cases:
    // 1. The user has only one membership - no point in asking to choose.
    // 2. The user used a different client that sent him here to AUTH
    // and the client probably is waiting for the user to come back.
    // The way back is sent to us via query params and if that is the case
    // we don"t need to present the choice to the user as he might not make the
    // correct choice. We choose for him in this case.
    if (memberships?.length === 1 && memberships[0]?.s4_role == null) {
      autoSelectMembership = memberships[0];
    } else if (targetSubdomain) {
      for (let i = 0; i < memberships.length; ++i) {
        let m = memberships[i];
        // The client will send us a path and a subdomain. Use the subdomain
        // to determine if it"s auto select or not.
        if ((m.server_alias.toLowerCase() === targetSubdomain.toLowerCase())) {
          autoSelectMembership = m;
          break;
        }
      }
    }
    return autoSelectMembership;
  };

  membershipAction = (_event, membership) => {
    if (membershipHelper.isActive(membership) && membership.authorized) {
      this.setState({ redirecting: true });
      this.useMembership(membership);
      return;
    }
    
    this.openMembershipActionModal(membership);
  };

  render() {
    const { classes, memberships } = this.props;
    const showOption = this.state.showOption;

    const autoSelectedMembership = this.findAutoSelectMembership();
    let selectMembership = null;

    if (this.hasMemberships()) {

      if (autoSelectedMembership && membershipHelper.isActive(autoSelectedMembership) && autoSelectedMembership.authorized && !showOption) { //only autoroute if we aren't asking for a choice
        this.setState({ redirecting: true });
        this.useMembership(autoSelectedMembership);
      } else {
        selectMembership = !this.state.redirecting ?
          <Autocomplete
            data-testid="membership-selector"
            id="membership-selector"
            style={{ width: "80%" }}
            autoHighlight={true}
            autoSelect={true}
            getOptionLabel={(option) => option.tenant_name}
            options={sortByKey(reject(memberships, (membership => has(membership, "s4_role") && membership.s4_role === 1)) || [], "tenant_name")}
            onChange={this.membershipAction}
            renderInput={(params) => <TextField {...params} label={this.props.t("membership.select")} variant="outlined" />}
          /> :
          <LoadingPlaceHolder title={this.props.t("default:actions.redirecting")} />;
      }
    }

    return !this.hasMemberships() ?
      <Grow in
        style={{ transformOrigin: "0 0 0" }}
        {...({ timeout: 1000 })}>
        <Paper className={classnames(classes.membershipSelection)}>
          <Grid direction="column" container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Placeholder message={this.props.t("membership.empty")} classNameMessage={classes.message} icon={<LibraryBooksOutlined/>} classNameIcon={classes.icon}/>
            </Grid>
            <Grid item>
              <LogoutButton />
            </Grid>
          </Grid>
        </Paper>
      </Grow> :
      selectMembership ?
        <Fragment>
          <Grow in
            style={{ transformOrigin: "0 0 0" }}
            {...({ timeout: 1000 })}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <GridItem xs={12} sm={10} md={8}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle} style={{ color: "#fff" }}>{this.props.t("membership.server.choose")}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "20px" }}>
                      {selectMembership}
                    </GridContainer>
                  </CardBody>
                  <CardFooter stats>
                    <GridItem xs={12} key={"logout"}>
                      <LogoutButton />
                    </GridItem>
                  </CardFooter>
                </Card>
              </GridItem>
            </Grid>
          </Grow>
          <GridContainer justifyContent="flex-end" direction="row" className={classes.buttonContainer}>
            <GridItem container justifyContent="space-between" className={classes.buttonContainer2}>
              {this.trustedAdminLink()}
              {this.adminLink()}
            </GridItem>
          </GridContainer>
        </Fragment> :
        <Fragment>
          <GridContainer direction="row" justifyContent="center" alignItems="center">
            <GridItem xs={12} sm={10} md={8}>
              <Card>
                <CardFooter stats>
                  <GridContainer direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="h6" color="textSecondary" gutterBottom align="center">
                      {this.props.t("membership.server.autoselect", autoSelectedMembership)}
                    </Typography>
                  </GridContainer>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </Fragment>;
  }
}

MembershipList.propTypes = {
  memberships: PropTypes.array,
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  onMembershipSelected: PropTypes.func.isRequired,
  onPreferenceSelected: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  targetSubdomain: PropTypes.string,
  targetPath: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  onNotification: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  membershipUpdate: PropTypes.func.isRequired
};

MembershipList.defaultProps = {
  memberships: []
};

const mapDispatchtoProps = dispatch => {
  return {
    onNotification: (message, level = "success") => dispatch(alertActions.send(message, level)),
    onMembershipSelected: (m) => dispatch(memberActions.storeCurrentMembership(m)),
    onPreferenceSelected: (m) => dispatch(authActions.userUpdate(m)),
    membershipUpdate: (orgId, data) => dispatch(memberActions.membershipUpdate(orgId, data)),
    hideModal: () => dispatch(modalActions.hideModal()),
    showModal: (modalProps, modalType) => {
      dispatch(modalActions.showModal({ modalProps, modalType }));
    },
    logout: () => dispatch(authActions.logout())
  };
};

const mapState = (state) => {
  const { path, subdomain } = state.redirect;
  const { memberships } = state;

  return {
    memberships: memberships.list,
    targetPath: path,
    targetSubdomain: subdomain
  };
};

const styledPage = withTranslation(["memberships"], ["auth"], ["default"])(connect(mapState, mapDispatchtoProps)(withStyles(MembershipList, listStyles)));
export default styledPage;
