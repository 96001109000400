import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import GridContainer from "components/Grid/GridContainer";
import AutocompleteFetchSelect from "_components/Select/AutocompleteFetchSelect";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.5),
      width: "350px"
    }
  }
}));

export default function AssignCryptoForm(props) {
  const { classes } = useStyles();
  const {dataSource, key, prompt} = props.extra;
  const handleChange = (field, value) => {
    props.setFormValues({
      ...props.formValues,
      [field]: value
    });
    const errors = props.formErrors;
    if (field === key) {
      errors[field] = !value;
    }
    if (!errors[field]) {
      delete errors[field];
    }
    props.setFormErrors(errors);
  };

  return (
    <form className={classes.root} autoComplete="off">
      <GridContainer direction="column" alignItems="stretch" justifyContent="space-between">
        <AutocompleteFetchSelect
          id={key}
          title={prompt}
          error={!!props.formErrors.tenant_id}
          required={true}
          className={classes.formControl}
          dataSource={dataSource}
          onChange={(_event, val) => handleChange(key, val.id)}
          inputProps={{disableUnderline: true, endAdornment: null}} 
          autoHighlight={true}
          autoSelect={true}
        />
      </GridContainer>
    </form>
  );
}

AssignCryptoForm.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  setFormValues: PropTypes.func,
  setFormErrors: PropTypes.func,
  extra: PropTypes.object,
  prompt: PropTypes.string,
  id: PropTypes.string
};

AssignCryptoForm.defaultProps = {
  prompt: "Select",
  id: "id",
  disabled: false
};