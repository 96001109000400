import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    "fontWeight": "bold",
    "cursor": "pointer",
    "backgroundColor": theme.palette.primary.contrastText,
    "color": theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      opacity: 1
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      opacity: 1
    }
  }
}));

const TrustedAdmin = (props) => {
  const { classes } = useStyles();
  if (props.adminMembershipClick) {
    return (
      <Button className={classes.button} onClick={() => props.adminMembershipClick()}>
        <PersonIcon />{" "}TRUSTED ADMIN
      </Button>
    );
  } else {
    return null;
  }
};

TrustedAdmin.propTypes = {
  adminMembershipClick: PropTypes.func
};

export default TrustedAdmin;