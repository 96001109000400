import { useCallback, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, isEmpty } from "lodash";
import { BroadcastChannel } from "broadcast-channel";

import { genericReducer } from "_reducers/general.reducer";
import { localeActions, authActions, alertActions } from "_actions";
import { authService } from "_services";
import { getFilterVars, compactObj } from "_helpers";
import { MFA_CODE_LENGTH } from "_constants";
import appRoutes from "_routes";

import LoginFrame from "_components/Auth/LoginFrame";
import MfaSetupForm from "_components/Form/MfaSetupForm.js";
import MfaCodeModal from "_components/Modal/MfaCodeModal";
import { MfaForm } from "_components/Auth/MfaForm";
import CustomAlert from "_components/Alert/CustomAlert.js";
import Loader from "_components/Loading";
import TermsAndConditions from "_components/Auth/Form/TermsAndConditions";
import PersonalInformation from "_components/Auth/Form/PersonalInformation";
import ContainedButton from "_components/Button/ContainedButton";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/passwordlessStyle.js";

const useStyles = makeStyles()(styles);

const defaultInitialState = {
  magic: "",
  referer: "",
  language: "en",
  terms_required: false
};

const Passwordless = (props) => {
  const broadcastChannel = useMemo(() => new BroadcastChannel("appChannel"), []);
  const { classes } = useStyles();
  const { t } = useTranslation(["auth", "default"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = props.location;
  const currentLanguage = useSelector((state) => state.locale.language);
  const params = getFilterVars(defaultInitialState, search);
  const [ state, setState ] = useReducer(genericReducer,
    {
      open: false,
      isLoading: true,
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      termsRequired: false,
      termsAccepted: false,
      twsAccount: {},
      twsMemberships: [],
      twsToken: {},
      mfaStepRequired: false,
      mfaCodeIncorrect: false,
      mfaSetupRequired: false,
      openMfaBkCode: false,
      mfaCode: "",
      mfaBackupCodes: [],
      errorMsg: ""
    }
  );

  const setUsername = username => setState({ username });
  const setFirstName = firstName => setState({ firstName });
  const setLastName = lastName => setState({ lastName });
  const setTermsAccepted = accepted => setState({ termsAccepted: accepted });

  const verifyMagic = async () => {
    let data = {
      magic: params.magic,
      referer: params.referer
    };

    if (state.termsRequired) {
      data.terms_and_conditions = params.terms_required;
    }

    try {
      const result = await authService.verifyPasswordless(data);
      
      dispatch(authActions.start());

      if (result.tws_account) {
        setState({
          twsAccount: result.tws_account,
          twsMemberships: result.tws_memberships,
          twsToken: result.tws_token,
          email: result.tws_account.email,
          username: result.tws_account.username,
          firstName: result.tws_account.first_name,
          lastName: result.tws_account.last_name,
          mfaStepRequired: result.mfa_step_required,
          mfaSetupRequired: result.setup_required,
          data: result.data,
          isLoading: false,
          open: true,
          termsRequired: false
        });
      } else if (result.terms) {
        setState({ 
          isLoading: false,
          open: true,
          termsRequired: result.terms.required
        });
      } else {
        setState({
          mfaStepRequired: result.mfa_step_required,
          mfaSetupRequired: result.setup_required,
          data: result,
          isLoading: false,
          open: true,
          termsRequired: false
        });
      }
      dispatch(localeActions.setLocale({ language: params.language }));
    } catch (e) {
      let errorMsg = t("auth:login.networkError");
      let errors = {};

      if (e.response?.data?.error?.errors) {
        errors = e.response.data.error.errors;
      }
      const error = errors.mfa?.msg || errors.passwordless?.msg || errors.session?.msg;

      switch (error) {
        case "account_locked":
          errorMsg = t("auth:login.account.locked");
          break;
        case "invalid_magic":
          if (errors.passwordless?.expired) {
            errorMsg = t("auth:login.expiredMagic");
          } else if (errors.passwordless?.decoded) {
            errorMsg = t("auth:login.magicAlreadyUsed");
          } else {
            errorMsg = t("auth:login.invalidMagic");
          }
          break;
        default:
          errorMsg = t("auth:login.invalidMagic");
      }

      history.replace(appRoutes.public.emailLink, {
        showPasswordlessNotif: true,
        passwordlessNotifSeverity: "error",
        passwordlessNotifMessage: errorMsg
      });
    }
  };

  useEffect(() => {
    if (props.shouldLogout) {
      dispatch(authActions.logout());
    } else {
      if (!state.open) {
        setTimeout(() => verifyMagic(), 500);
      }
    }
  });

  const notifyAllTabs = useCallback(() => {
    sessionStorage.setItem("isBCOrigin", "true");
    broadcastChannel.postMessage("REFRESH");
  }, [broadcastChannel]);

  const login = useCallback(() => {
    dispatch(authActions.success(state.twsAccount, state.twsMemberships, "passwordless"));
    history.replace(appRoutes.private.memberships);
    notifyAllTabs();
  }, [state, history, dispatch, notifyAllTabs]);

  useEffect(() => {
    if (!state.termsRequired && !state.mfaSetupRequired && !state.mfaStepRequired && !state.termsAccepted && !isEmpty(state.twsAccount)) {
      login();
    }
  }, [state, login]);

  const handleMfaError = e => {
    let errors = {};
      
    if (e.response.data?.error?.errors) {
      errors = e.response.data.error.errors;
    }
    
    const error = errors.mfa?.msg || errors.passwordless?.msg || errors.session?.msg || errors.account?.msg;

    if (error) {
      if (error === "invalid_mfa_code") {
        dispatch(alertActions.send(t("mfa.codeInvalidNotif"), "error"));
      } else if (error === "account_locked") {
        dispatch(alertActions.send(t("auth:login.account.locked"), "error"));
      }

      if (state.mfaStepRequired) {
        setState({ mfaCode: "", mfaCodeIncorrect: true });
        let mfaFormInput = document.getElementsByClassName(
          "mfaform-input-field"
        )[0];
        mfaFormInput?.focus();
      }
    }
  };

  const onClickConfirmSetupMfaCode = async () => {
    const data = {
      magic: params.magic,
      referer: params.referer,
      mfa_code: state.mfaCode
    };
    
    try {
      const result = await authService.verifyPasswordless(data);

      setState({
        mfaBackupCodes: result.tws_account?.auth_options?.TOTP?.backup_codes || [],
        twsAccount: result.tws_account,
        twsMemberships: result.tws_memberships,
        email: result.tws_account.email,
        username: result.tws_account.username,
        firstName: result.tws_account.first_name,
        lastName: result.tws_account.last_name,
        openMfaBkCode: true
      });
      dispatch(alertActions.send(t("mfa.enabledNotif"), "success"));
    } catch (e) {
      handleMfaError(e);
    }
  };

  const onChangeLanguage = (language) => {
    dispatch(localeActions.setLocale({ language: language }));
  };

  const onSetupChangeMfaCode = (event) => {
    setState({ mfaCode: event.target.value });
  };

  const closeMfaBkCodesModal = () => {
    dispatch(authActions.resetMfa());
    setState({
      openMfaBkCode: false,
      mfaSetupRequired: false
    });
  };

  const renderSetupMfaForm = () => {
    return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.spaceAround}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <CustomAlert type="MfaInfo" />
            <MfaSetupForm
              data={state.data}
              onChange={onSetupChangeMfaCode}
              code={state.mfaCode}
              onSubmit={onClickConfirmSetupMfaCode}
            />
            <MfaCodeModal
              showModal={state.openMfaBkCode}
              handleClose={closeMfaBkCodesModal}
              codes={state.mfaBackupCodes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <ContainedButton
              fullWidth
              variant="contained"
              disabled={state.isLoading || state.mfaCode.length !== MFA_CODE_LENGTH}
              color="secondary"
              onClick={onClickConfirmSetupMfaCode}
            >
              {state.isLoading ?
                <CircularProgress className={classes.spinner} size={30} /> :
                t("default:button.enable")}
            </ContainedButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const onCancelMfaCode = () => {
    history.push("/login");
  };

  const onChangeVerifyMfaCode = async (mfa_code) => {
    setState({ mfaCode: mfa_code });
    if (mfa_code.length === MFA_CODE_LENGTH) {
      const data = {
        magic: params.magic,
        referer: params.referer,
        mfa_code: mfa_code
      };
      
      try {
        const result = await authService.verifyPasswordless(data);
        dispatch(authActions.resetMfa());
        setState({
          twsAccount: result.tws_account,
          twsMemberships: result.tws_memberships,
          twsToken: result.tws_token,
          email: result.tws_account.email,
          username: result.tws_account.username,
          firstName: result.tws_account.first_name,
          lastName: result.tws_account.last_name,
          mfaStepRequired: false
        });
      } catch (e) {
        handleMfaError(e);
      }
    }
  };

  const renderMfaForm = () => {
    return state.mfaSetupRequired ? 
      renderSetupMfaForm() : 
      state.mfaStepRequired ?
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.spaceAround}>
          <Grid item>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10}>
                <MfaForm
                  onChange={onChangeVerifyMfaCode}
                  value={state.mfaCode}
                />
              </Grid>
              {state.mfaCodeIncorrect &&
                <Grid item xs={10}>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      <CustomAlert type={"MfaFirstAttemptIncorrect"} style={{alignItems: "center"}}/>
                    </Grid>
                  </Grid>
                </Grid>}
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button variant="text" disableRipple size="small" className={classes.button} onClick={onCancelMfaCode}>
                      {t("default:button.cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> : null;
  };

  const onAgreement = () => {
    verifyMagic();
  };

  const renderTermsConditions = () => {
    return <TermsAndConditions termsAccepted={state.termsAccepted} setTermsAccepted={setTermsAccepted} onAgreement={onAgreement}/>;
  };

  const onConfirmPersonalInfo = async (newPassword) => {
    if ((!isEmpty(state.username) && state.username !== state.twsAccount.username) || state.firstName !== state.twsAccount.first_name || state.lastName !== state.twsMemberships.last_name) {
      const data = {
        username: state.username,
        first_name: state.firstName,
        last_name: state.lastName,
        language: currentLanguage,
        terms_and_conditions: state.termsAccepted,
        password: newPassword
      };

      try {
        await authService.updatePersonalInfoAccount(state.twsAccount.id, compactObj(data));
        setState({ termsAccepted: false });
      } catch (e) {
        if (get(e, "response.status") === 409) {
          dispatch(alertActions.send(t("default:error.notUniqueUsername"), "error"));
        } else {
          dispatch(alertActions.send(t("default:error.updateUser"), "error"));
        }
      }
    }
  };

  const onSkip = () => setState({ termsAccepted: false });

  const renderPersonalInfo = () => {
    return <PersonalInformation 
      defaultLanguageValue={params.language}
      onChangeLanguage={onChangeLanguage}
      email={state.email}
      username={state.username}
      onChangeUsername={setUsername}
      firstName={state.firstName}
      onChangeFirstName={setFirstName}
      lastName={state.lastName}
      onChangeLastName={setLastName}
      onConfirmPersonalInfo={onConfirmPersonalInfo}
      onCancel={onSkip}
    />;
  };

  const renderContent = () => {
    return state.termsRequired ?
      renderTermsConditions() :
      state.mfaSetupRequired || state.mfaStepRequired ?
        renderMfaForm() :
        state.termsAccepted ?
          renderPersonalInfo() :
          null;
  };

  return state.isLoading ? <Loader title={t("default:actions.authenticating")} /> :
    <LoginFrame open={state.open}>{ renderContent() }</LoginFrame>;
};

Passwordless.defaultProps = {
  shouldLogout: false
};

Passwordless.propTypes = {
  location: PropTypes.object,
  shouldLogout: PropTypes.bool
};

export default Passwordless;