export const lsyRouter = (type, param) => {
  switch (type) {
    case "about":
      return "/about";
    case "lock":
      return `/locks/${param}`;
    case "locks":
      return "/locks";
    case "lock_access_histories":
      return `/locks/${param}/access_histories`;
    case "site":
      return `/sites/${param}`;
    case "sites":
      return "/sites";
    case "access_request":
      return `/access_requests/${param}`;
    case "access_requests":
      return "/access_requests";
    case "events":
      return `/locks/${param}/events`;
    case "lock_group":
      return `/lock_groups/${param}`;
    case "lock_groups":
      return "/lock_groups";
    case "lock_edit":
      return `/locks/${param}/edit`;
    case "tag":
      return `/tags/${param}`;
    case "tags":
      return "/tags";
    case "user":
      return `/users/${param}`;
    case "user_edit":
      return `/users/${param}/edit`;
    case "users":
      return "/users";
    case "user_access_histories":
      return `/users/${param}/access_histories`;
    case "user_devices":
      return `/users/${param}/devices`;
    case "user_notification":
      return `/users/${param}/notifications`;
    case "user_work_sessions":
      return `/users/${param}/work_sessions`;
    case "lock_notes":
      return "/lock_notes";
    case "locations":
      return "/locations";
    case "charts":
      return "/charts";
    case "reports":
      return `/reports/${param}`;
    case "system_logs":
      return "/system_logs";
    case "dashboard":
      return "/dashboard";
    case "developers":
      return "/developers";
    default:
      throw new Error("Invalid route");
  }
};