/**
 * The translation framework escapes all the html in the strings by default for security reasons.
 * This however makes the formatting of our texts a little complicated especially when using
 * the t function instead of the Trans component.
 * Luckily, the i18next-react-postprocessor plugin allows us to define react tags that can be safely
 * embedded in the translation strings.
 * You can request bold an italic test. New line is added to the options by default via <newLine> or
 * <new_line>
 *
 * USAGE:
 *
 * For example, requesting bold text can be done like this:
 *
 * const formatter = formatter();
 * formatter.setBoldText("tenant_name", "Lockstasy");
 *
 * Then call the translator like this:
 *
 * t('key', formatter.getInterpolatedKeys());
 *
 * Where key should have a string that has the <tenant_name> tag which will be replaced with
 * <strong>Lockstasy</strong>
 *
 */
export const translateFormatter = () => {
  const params = {
    newLine : (<br/>),
    new_line : (<br/>)
  };
  const f = {
    setLink : (k, v, ref) => {
      params[k] = (<a href={ref} target="_blank" rel="noreferrer">{v}</a>);
      return f;
    },
    setBoldText : (k, v) => {
      params[k] = (<strong>{v}</strong>);
      return f;
    },
    setBoldKeys : (o) => {
      for(let k in o) {
        if (Object.prototype.hasOwnProperty.call(o, k)) {
          f.setBoldText(k, o[k]);
        }
      }
      return f;
    },
    setItalicText : (k, v) => {
      params[k] = (<i>{v}</i>);
      return f;
    },
    setItalicKeys : (o) => {
      for(let k in o) {
        if (Object.prototype.hasOwnProperty.call(o, k)) {
          f.setItalicText(k, o[k]);
        }
      }
      return f;
    },
    getInterpolatedKeys : () => {
      return params;
    }
  };
  return f;
};