const passwordFormStyle = theme => ({
  margins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    textTransform: "none"
  },
  select: {
    fontSize: theme.typography.normalFont.fontSize,
    height: "34px"
  },
  spinner: {
    color: theme.lsyPalette.secondary.main
  }
});

export default passwordFormStyle;