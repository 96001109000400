const licenseExpireAlertWidgetStyle = (theme) => ({
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  alert: {
    "alignItems": "center",
    "& .MuiAlert-message": {
      padding: "0px"
    }
  }
});

export default licenseExpireAlertWidgetStyle;