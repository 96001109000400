/* This file is used for s4 admin purposes */
import { getGeneric } from "_helpers/axios/generic.client";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";
import { config } from "_configs/server-config";
const monitoringSecrets = { Authorization: `Bearer ${config.monitoringAPIToken}`};

const fetchStatuses = async (options = { }) => {
  let baseUrl = `${config.telescopioUrl}/live_status_raw`;
  let showAll = delete options.showAll;
  if (showAll) {
    baseUrl = `${config.telescopioUrl}/live_status_raw_all`;
    delete options.endpoints;
  }

  var additionalArgs = transformToQueryString(sanitizeKeys(options));

  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
    baseUrl = baseUrl + "?";
  }

  return getGeneric({
    url: encodeURI(baseUrl + additionalArgs),
    headers: monitoringSecrets
  });
};

export const monitoringService = {
  fetchStatuses
};