import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/auditStyle";
const useStyles = makeStyles()(styles);

function AuditIcon(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_AuditIcon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 115.43 122.88" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve">
      <path className={cx(classes.audit, (props.pathClassName || ""))} d="M60.51,27.68l6,10.37a12,12,0,1,1-7.72-22.22l1.73,11.85ZM84.64,67.32a24.25,24.25,0,0,1,20.29,37.51l10.5,11.43-7.24,6.62L98.07,111.75A24.24,24.24,0,1,1,84.64,67.32ZM75.51,93.83a2.79,2.79,0,0,1-.34-.43,3.62,3.62,0,0,1-.25-.48,3.09,3.09,0,0,1,4.44-3.84,2.74,2.74,0,0,1,.48.36h0c.84.81,1.11,1,2,1.81l.81.73,6.59-7.08c2.79-2.87,7.24,1.33,4.46,4.27L85.54,98l-.42.43a3.1,3.1,0,0,1-4.31.19l0,0L80.15,98l-1.57-1.37c-1.22-1.06-1.93-1.64-3.07-2.79Zm9.15-21.45A19.29,19.29,0,1,1,65.37,91.66,19.29,19.29,0,0,1,84.66,72.38Zm-49.14-47H41.1a.54.54,0,0,1,.54.54v11a.54.54,0,0,1-.54.54H35.52a.54.54,0,0,1-.54-.54v-11a.54.54,0,0,1,.54-.54ZM88,16.77H97.1a6.49,6.49,0,0,1,6.46,6.46V56.79c-.19,2-5.16,2.05-5.58,0V23.23a.91.91,0,0,0-.26-.65.89.89,0,0,0-.66-.27H88V56.79c-.48,1.83-4.62,2.11-5.55,0V6.46a.89.89,0,0,0-.92-.91H6.43a.91.91,0,0,0-.65.26.88.88,0,0,0-.27.65V94a.91.91,0,0,0,.92.92H50.54c2.8.28,2.88,5.11,0,5.54H21v10.3a.89.89,0,0,0,.27.66.91.91,0,0,0,.65.26H50.54c2,.23,2.68,4.83,0,5.55H22a6.34,6.34,0,0,1-4.56-1.9,6.42,6.42,0,0,1-1.91-4.57v-10.3H6.46A6.49,6.49,0,0,1,0,94V6.46A6.37,6.37,0,0,1,1.9,1.9,6.43,6.43,0,0,1,6.46,0H81.58a6.37,6.37,0,0,1,4.56,1.9A6.43,6.43,0,0,1,88,6.46V16.77Zm-73,39.64a2.45,2.45,0,0,1,0-4.89h44.1a2.45,2.45,0,0,1,0,4.89Zm0,12.76a2.45,2.45,0,0,1,0-4.9H51.84a2.45,2.45,0,0,1,0,4.9Zm.28,14.11a2.45,2.45,0,0,1,0-4.9H42.83a2.45,2.45,0,0,1,0,4.9Zm.26-64.65h5.59a.54.54,0,0,1,.54.54V36.89a.55.55,0,0,1-.54.54H15.6a.54.54,0,0,1-.54-.54V19.17a.54.54,0,0,1,.54-.54Zm10-3.79h5.58a.54.54,0,0,1,.54.54V36.89a.54.54,0,0,1-.54.54H25.56a.54.54,0,0,1-.54-.54V15.38a.54.54,0,0,1,.54-.54ZM63,28.19l11.17,0a11.13,11.13,0,0,1-5,9.25L63,28.19ZM61.75,26l-.67-12.77,1.31-.06A12.8,12.8,0,0,1,75.31,25l.06.8-13.58,1,0-.82Zm1-11.21.54,10.3,10.29-.76a11.15,11.15,0,0,0-10.83-9.54Z"></path>
    </svg>
  );
}

AuditIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

export default AuditIcon;
