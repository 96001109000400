import TwsResource from "./tws-resource";

export default new TwsResource({
  endpoint: "lockdepot",
  resource: "battery_types",
  name: "twsBatteryTypes",
  requests: {
    query: { method: "GET", isArray: true, url: "battery_types" }
  },
  processors: {
    query: ((data, options = {}) => {
      const { battery_id } = options;
      if (battery_id) {
        // Filter tenants by id
        return [data.find(battery => battery.id == battery_id)];
      } else {
        // No filter
        return data;
      }
    })
  }
}); 

