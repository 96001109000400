const lockIconsStyle = (theme) => ({
  disabled: {
    fill: theme.lsyPalette.primary.mainLight
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  firmwareIcon: {
    backgroundColor: "white",
    border: `1px solid ${theme.lsyPalette.primary.mainDark}`,
    borderRadius: "50%"
  },
  firmwareIconAP3: {
    right: "30%",
    position: "relative"
  },
  firmwareIconAX5: {
    right: "26%",
    position: "relative"
  },
  firmwareIconSLC: {
    right: "30%",
    position: "relative"
  },
  lockWithUpdateWrap :{
    marginRight: "-23px"
  },
  relativePositioning: {
    position: "relative"
  }
});

export default lockIconsStyle;
  