import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { config } from "_configs/server-config";
import rootReducer from "../_reducers";

const loggerMiddleware = createLogger();
/* Redux dev-tools debugging extensions */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...[
  thunkMiddleware,
  config.reduxDebug && loggerMiddleware
].filter(Boolean)));

export const store = createStore(
  rootReducer,
  enhancer
);
