import * as actionTypes from "_actions/actionTypes";
import { updateObject } from "_helpers/utility";

let lang = localStorage.getItem("language");
if (!lang) {
  lang = navigator.language || navigator.userLanguage || "en";
  // We do not support country specific variant at the moment
  // so remove the country
  if (lang.includes("-")) {
    lang = lang.split("-")[0];
  }
}

const baseDefaults = {
  language: lang
};

const initialState = { ...baseDefaults, language: lang };

const langUpdate = (state, action) => {
  return updateObject(state, {
    language: action.language
  });
};

const getLanguage = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_SET: return langUpdate(state, action);
    default:
      return state;
  }
};

export default getLanguage;
