import { Fragment, useCallback } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormControl from "@mui/material/FormControl";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";

function CustomForm(props) {
  const { form, items } = props;
  const { t } = useTranslation("default");

  const chooseComponent = useCallback((item, field, formState) => {
    const showHelperText = item.showHelperText || false;

    const onSelectChange = (event) => {
      if (item.onChange) {
        item.onChange(event.target.value);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Tab") {
        item.onChange ?
          item.onChange(event.currentTarget.dataset.value) : 
          form.setValue(item.field, event.currentTarget.dataset.value, {shouldDirty: true});
      }
    };

    switch (item.type) {
      case "textField":
        return (
          <TextField
            {...field}
            data-testid={`textField-${item.field}`}
            onKeyDown={(ev) => {
              form.setValue(item.field, ev.target.value);
              if (item.submitOnEnter && ev.key === "Enter") {
                form.handleSubmit(item.handleSubmit)();
                ev.preventDefault();
              }
            }}
            className={item.className}
            disabled={item.disabled}
            id={item.field}
            required={!isEmpty(item.required)}
            size={item.size || "small"}
            fullWidth={item.fullWidth}
            variant="outlined"
            autoComplete={item.autoComplete}
            type={item.inputType}
            multiline={item.multiline}
            maxRows={item.rowsMax}
            inputProps={item.inputProps}
            InputProps={item.InputProps}
            label={item.label}
            placeholder={item.placeholder || ""}
            error={!isEmpty(formState.errors[item.field])}
            helperText={showHelperText && !isEmpty(formState.errors[item.field]) ? formState.errors[item.field].message || item.errorMsg : null}
          />
        );
      case "typeAhead":
        return (
          <CustomAsyncSelect
            {...field}
            class={item.className}
            isMulti={item.isMulti || false}
            autoFocus={item.autoFocus}
            backspaceRemovesValue={item.backspaceRemovesValue}
            placeholder={item.placeholder}
            loadOptions={item.promiseOptions}
            isOptionDisabled={(option) => item?.disabled || option.disabled}
            {...item.asyncProps}
          />
        );
      case "select":
        return (
          <Select
            {...field}
            className={item.className}
            role="listbox"
            variant="outlined"
            label={item.label}
            inputProps={item.inputProps}
            onChange={onSelectChange}
            value={item.defaultValue}
            fullWidth
          >
            {item.selectOptions?.map((item, index) => {
              return (
                <MenuItem
                  key={`${item.field}-${index}`}
                  value={item.value}
                  onKeyDown={handleKeyDown}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        );
    }
  }, [form]);

  const getComponent = (item) => {
    switch (item.form) {
      case "formControl":
        return <FormControl variant="standard" fullWidth>
          {item.label &&
            <InputLabel>
              {item.label}
            </InputLabel>}
          { chooseComponent(item, {}, {}) }
        </FormControl>;
      default:
        return <Controller
          control={form.control}
          name={item.field}
          rules={{
            required: item.required,
            pattern: item.pattern || null,
            maxLength: item.maxLength,
            validate: {
              ... item.validateEntry &&
                { validEntry: value => item.validateEntry(value, item.field) || item.validateEntryMessage || t("error.invalidEntry") }
            }
          }}
          defaultValue={item.defaultValue || ""}
          render={({ field, formState }) => chooseComponent(item, field, formState) }
        />;
    }
  };

  return <Grid container alignItems="center" spacing={items.length > 1 ? 2 : 1}>
    { items ? items.map((item, index) => {
      return (
        <Fragment key={`${item.field}${index}`}>
          <Grid item key={`${item.field}${index}`} xs={item.columns}>
            { getComponent(item) }
          </Grid>
          { item.icon &&
            <Grid item>
              <Collapse in={!item.showIcon} >
                {item.icon}
              </Collapse>
            </Grid>
          }
        </Fragment>
      );
    }) : null}
  </Grid>;
}

CustomForm.propTypes = {
  items: PropTypes.array,
  form: PropTypes.object
};

export default CustomForm;