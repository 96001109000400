const loginFrameStyle = theme => ({
  container: {
    "&.MuiGrid-item": {
      maxWidth: "600px",
      width: "100%"
    }
  },
  icon: {
    height: "275px",
    width: "275px"
  },
  s4Logo: {
    minHeight: "100px",
    marginBottom: "20px"
  },
  s4LogoIconContainer: {
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  s4LogoIcon: {
    width: "70px",
    height: "70px",
    background: theme.lsyPalette.secondary.background,
    transform: "rotate(45deg)",
    position: "relative",
    top: "-35px",
    borderRadius: "35px"
  }
});

export default loginFrameStyle;