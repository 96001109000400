import { isValidPublicKey } from "_helpers";
import { uuidValidator } from "_helpers";
import TwsResource from "./tws-resource";

export default new TwsResource({
  endpoint: "lockdepot",
  resource: "cryptos",
  name: "twsCryptos",
  requests: {
    query: { method: "GET", isArray: true, url: "cryptos?include=crypto_locations&include=crypto_ownerships" },
    assign: { method: "POST", url: "cryptos/:id/assign"}
  },
  processors: {
    query: ((data, options = {}) => {
      const { search, tenant_id } = options;
      if (tenant_id) {
        // Filter only cryptos that have ownerships
        // associated with this tenant
        return data.filter(e => {
          const { crypto_ownerships } = e;
          return crypto_ownerships && crypto_ownerships.find(co => co.tenant_id === tenant_id);
        });
      } else if (uuidValidator(search)) {
        /// Filter cryptos by id
        return data.filter(e => e.id.toLowerCase() === search.toLowerCase());
      } else if (isValidPublicKey(search)) {
        // Filter cryptos by public key
        return data.filter(e => e.public_key.toLowerCase() === search.toLowerCase());
      } else if (search) {
        // The next and last supported crypto search is the name
        return data.filter(e => e.name.toLowerCase().includes(search.toLowerCase()));
      } else {
        // No filter
        return data;
      }
    })
  }
}); 