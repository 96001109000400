const AccessHistoryStyle = (theme) => ({
  body: {
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`
    },
    padding: `${theme.spacing(3)} 0`,
    backgroundColor: "white"
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  goBackContainer: {
    maxWidth: "100%",
    margin: 0,
    padding: 0
  },
  chip: {
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& input": {
      width: "135px"
    }
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.subtitle3,
    "display": "inline",
    "fontWeight": theme.fontweightHeavy,
    "marginLeft": theme.spacing(0.5),
    "& span": {
      fontWeight: theme.typography.fontWeight
    }
  },	
  chipIcon: {
    margin: "0",
    width: "20px",
    height: "auto",
    paddingRight: theme.spacing(0.75),
    color: theme.lsyPalette.text
  },
  chartContainer: {
    backgroundColor: theme.backgroundColor,
    position: "sticky",
    top: "5vh",
    width: "100%",
    height: "50%",
    display: "flex",
    borderColor: theme.palette.text.light,
    borderRadius: theme.shape.borderRadius,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: "0",
      width: "100%",
      marginBottom: theme.spacing(0.25),
      height: "200px",
      minHeight: "200px"
    },
    margin: `0 ${theme.spacing(0.25)}`,
    padding: theme.spacing(4),
    minHeight: "20vh"
  },
  systemLogContainer: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(1),
      height: "200px",
      minHeight: "200px"
    }
  },
  chart: {
    width: "100%",
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "50%"
    }
  },
  logItem: {
    display: "flex",
    width: "100%",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.text.light,
    alignItems: "center"
  },
  logTextContainer: {
    width: "100%"
  },
  titleDescription: {
    color: theme.lsyPalette.primary.mainLight,
    marginBottom: theme.spacing(1)
  },
  description: {
    fontSize: theme.typography.xsFontSize,
    color: theme.palette.text.primary
  },
  red: {
    fill: theme.lsyPalette.stdRed
  },
  mainLight: {
    fill: theme.lsyPalette.link
  },
  time: {
    fontSize: theme.typography.xsFontSize,
    color: theme.palette.text.light,
    marginLeft: "auto",
    alignSelf: "start"
  },
  icon: {
    margin: "0",
    color: theme.lsyPalette.primary.main,
    marginRight: theme.spacing(1)
  },
  swapIcon: {
    color: theme.lsyPalette.primary.main,
    margin: "0"
  },
  link: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  timePeriod: {
    margin: `${theme.spacing(1)} 0`
  },
  date: {
    fontSize: theme.typography.xsFontSize,
    color: theme.palette.text.light,
    margin: `0 ${theme.spacing(0.5)}`
  },
  container: {
    maxWidth: "100%",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      maxWidth: "100%",
      alignItems: "center"
    }
  },
  action: {
    fontSize: theme.typography.xsFontSize,
    width: "fit-content"
  },
  alignRight: {
    marginLeft: "auto"
  },
  skeleton: {
    margin: theme.spacing(0.25)
  },
  chartSkeleton: {
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "50vh"
    }
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "marginLeft": "auto",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.typography.xsFontSize
    },
    "& button": {
      "fontSize": theme.typography.xsFontSize,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.typography.xsFontSize,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  eventType: {
    fontSize: theme.typography.xsFontSize,
    width: "fit-content"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5)
  },
  eventText: {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(0.15)
  },
  maxSize: {
    maxWidth: "575px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "75vw"
    }
  },
  searchContainer: {
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeight
  },
  datePeriod: {
    color: theme.palette.text.light,
    fontSize: theme.typography.xsFontSize
  },
  confirmSelection: {
    border: `${theme.shape.borderRadius} solid ${theme.lsyPalette.secondary.background}`,
    overflow: "auto",
    height: "250px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(1)} 0`,
    padding: `${theme.spacing(1)} ${theme.spacing(0.15)}`
  },
  eventTypeForm: {
    minHeight: "250px"
  },
  selectionPageDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  selectedNames: {
    maxWidth: "375px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  removeItem: {
    color: theme.lsyPalette.primary.main,
    position: "absolute",
    right: "0",
    paddingTop: "0"
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    float: "left",
    color: theme.lsyPalette.primary.mainLight,
    fontSize: "13px",
    width: "fit-content"
  },
  rotate: {
    transform: "rotate(180deg)"
  },
  backIcon: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": "13px",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  histIcon: {
    "width": "26px",
    "height": "auto",
    "marginLeft": "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  infoIcon: {
    "width": "24px",
    "height": "auto",
    "marginLeft": theme.spacing(1),
    "&:hover": {
      cursor: "pointer"
    }
  },
  accessEvent: {
    float: "right"
  },
  timelineHeader: {
    maxWidth: "100%",
    whiteSpace: "break-spaces",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: theme.spacing(0.5)
  },
  timelineContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  placeholder: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  placeholderIcon: {
    fontSize: "48px",
    margin: "1rem",
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: "16px",
    color: theme.lsyPalette.primary.mainDark
  },
  tagContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  box: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.light,
    fontSize: theme.typography.xsFontSize,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.25),
    width: "fit-content",
    maxWidth: "150px",
    boxSizing: "border-box",
    cursor: "pointer"
  },
  redirectLink: {
    color: theme.lsyPalette.link,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px"
  },
  redirectIcon: {
    width: "20px",
    height: "20px"
  },
  chipSelect: {
    margin: `${theme.spacing(0.25)} 0`,
    marginRight: theme.spacing(0.5),
    padding: "0",
    height: "27px"
  },
  select: {
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": theme.lsyPalette.lightWeight,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      padding: "0"
    },
    "& .MuiMenuItem-root": {
      fontSize: theme.lsyPalette.subtitle3
    }
  },
  selectStatus: {
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": theme.lsyPalette.lightWeight,
    "backgroundColor": theme.lsyPalette.secondary.background,
    "borderRadius": theme.shape.borderRadius,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      paddingTop: "5px",
      paddingBottom: "4.75px",
      paddingLeft: theme.spacing(1.5)
    }
  },
  selectOptions: {
    fontSize: theme.lsyPalette.subtitle3
  },
  selectHiddenOption: {
    display: "none"
  },
  clickable: {
    cursor: "pointer"
  },
  rtsInfo: {
    fontStyle: "italic"
  }
});
export default AccessHistoryStyle;