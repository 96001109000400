import { useEffect, useState, Suspense, Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import SimpleSelect from "_components/Select/SimpleSelect";
import LoadingSection from "_components/Loading";
import RegularButton from "_components/Button/RegularButton";
import DateTimeRange from "_components/Date/DateTimeRange";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { alertActions } from "_actions";
import { lockService } from "_services/admin";

export const FSU_24_HOURS = 1;
export const FSU_4_HOURS = 2;

export default function FsuComponent ({lockId, fsuMode}) {
  const [fsuDate, setFsuDate] = useState();
  const [selectedWindow, setSelectedWindow] = useState("");
  const [fsuWindows, setFsuWindows] = useState([]);
  const [showFsuCode, setShowFsuCode] = useState(false);
  const [accessCode, setAccessCode] = useState();
  const dispatch = useDispatch();

  const SIZE_24_HOURS = 24;
  const SIZE_4_HOURS = 4;

  const maxDate = () => {
    return fsuMode === FSU_24_HOURS ? Datetime.moment().subtract(2, "day").endOf("day") : Datetime.moment().endOf("day");
  };

  const findMaximumWindow = (date, windowSize) => {


    // 20:57 (i.e. It's 8:57 PM GMT-0300)
    let now = Datetime.moment();

    // Yesterday or before. Consider current time as the start of next day (+ one extra window for edge cases) so you can have all previous day windows available
    if (Datetime.moment(date).startOf("day").isBefore(Datetime.moment().startOf("day"))) {
      now = Datetime.moment(date).add(1, "day").startOf("day").add(windowSize, "hours");
    }

    // 23:00
    let nowUtc = now.subtract(now.utcOffset(), "minutes").set({minute:0,second:0,millisecond:0});
    // 23
    let nowUtcHours = nowUtc.hours();
    // 23 % 4 = 3
    let utcRemainder = nowUtcHours % windowSize;
    // 23:00 - 3 = 20:00
    let intervalUtc = nowUtc.subtract(utcRemainder, "hours");
    // 17:00
    return intervalUtc.add(now.utcOffset(), "minutes").subtract(windowSize, "hours");
  };
  
  const loadValidWindows = (date) => {
    if (![FSU_24_HOURS,FSU_4_HOURS].includes(fsuMode)) {
      console.warn("Lock does not have valid FSU Mode");
      setFsuWindows([]);
      return;
    } 

    const windowSize = fsuMode === FSU_24_HOURS ? SIZE_24_HOURS : SIZE_4_HOURS;
    
    let timeWindow = findMaximumWindow(date, windowSize);
    let timeWindows = [];
    let startOfDay = Datetime.moment(date).startOf("day");

    while (timeWindow.toDate() >= startOfDay.toDate()) {
      timeWindows.push({ id: timeWindow.toDate().getTime(), name: timeWindow.format("lll") });
      timeWindow.subtract(windowSize, "hours");
    }

    setFsuWindows(timeWindows);
    if (timeWindows[0]) {
      setSelectedWindow(timeWindows[0].id);
    }
  };

  useEffect(() => { 
    setFsuDate(maxDate());
    loadValidWindows(maxDate()); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFsuDate = (event) => {
    setFsuDate(event);
    loadValidWindows(event);
    setShowFsuCode(false);
  };

  const changeFsuWindow = (window) => {
    setSelectedWindow(window);
    setShowFsuCode(false);
  };

  const requestFsu = async () => {
    try {
      let result = await lockService.generateAccessCode(lockId, {fsu_mode: fsuMode, start_date_time: Datetime.moment(selectedWindow).utc().format("YYYY-MM-DD HH:mm:ss")});
      if (result) {
        setAccessCode(result.access_code);
      } else {
        setShowFsuCode(result.toString());
      }
    } catch(e) {
      setAccessCode(e.toString());
    }
    setShowFsuCode(true);
  };
 
  const FsuCodeButton = () => {
    if (showFsuCode) {
      return (
        <Suspense fallback={<LoadingSection title="Loading Access Code" />}>
          <Fragment>
            <h3><b>Code:</b> {accessCode} </h3>
            <CopyToClipboard text={accessCode}>
              <RegularButton
                onClick={() => { dispatch(alertActions.send("Copied to clipboard")); }}
              >
                { "Copy code to Clipboard" }
              </RegularButton>
            </CopyToClipboard>
          </Fragment>
        </Suspense>
      );
    } else {
      return (
        <RegularButton
          disabled={selectedWindow === null || selectedWindow === undefined}
          onClick={() => requestFsu()}
        >
          { "Issue Access Code"}
        </RegularButton>
      );
    }
  };

  return (
    <GridContainer alignItems="center">
      <GridItem sm={6} style={{ marginTop: "12px" }}>
        <DateTimeRange 
          date={fsuDate || maxDate()}
          maxDate={maxDate().toDate()}
          setDate={changeFsuDate}
          isRange={false}
          numberOfMonths={1}
          label={"Day"}
          format="YYYY/MM/DD"
          showTime={false}
          inputProps={{ variant: "standard" }}
        />
      </GridItem>
      <GridItem xs={6}>
        <SimpleSelect
          title="Start Time"
          required={true}
          options={fsuWindows}
          selectedValue={selectedWindow}
          onChange={(event) => { changeFsuWindow(event.target.value); }}
        ></SimpleSelect>
      </GridItem>
      <GridContainer direction="row" justifyContent="center" style={{ marginTop: "20px" }}>
        <GridItem style={{ textAlign: "center" }}>
          {FsuCodeButton()}
        </GridItem>
      </GridContainer>
    </GridContainer>
  );

}

FsuComponent.propTypes = {
  lockId: PropTypes.number.isRequired,
  fsuMode: PropTypes.number.isRequired
};