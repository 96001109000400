import { useEffect, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import { useCustomCompareEffect } from "use-custom-compare";

import { alertActions } from "_actions";
import { LockConfigOptionsLabels } from "_constants/lock.constants";
import { lsyRouter } from "_helpers";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { lockService, lockReplacementService } from "_services/lockstasy";

import {
  Alert,
  AlertTitle,
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";

import ContainedButton from "_components/Button/ContainedButton";
import LsyFrame from "_components/Lockstasy/LsyFrame";
import LockReplacementDetails from "_containers/Widgets/LockReplacementDetails";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockReplacementStyle";

const useStyles = makeStyles()(styles);

function LockReplacement() {
  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useLsyHistory();

  const { id, replacementId } = useParams();
  const [ state, setState ] = useReducer(genericReducer,
    {
      lock: {},
      replacementLock: {},
      replacementNote: "",
      lockFullIdentifier: "",
      replacementLockFullIdentifier: "",
      sites: [],
      groups: [],
      isLoading: true,
      error: { errorMsg: "", fields: [] }
    }
  );

  const getHistoryState = useCallback(() => {
    return history.location?.state || {};
  }, [history.location?.state]);

  const fetchLocksSummary = useCallback(async () => {
    let lockFullIdentifier = "";
    let replacementLockFullIdentifier = "";

    try {
      const previewOptions = { lockId: id, replaceBy: replacementId };

      const lockResult = await lockService.fetchLock({ lockId: id });
      lockFullIdentifier = lockResult.data.full_identifier;
      const replacementLockResult = await lockService.fetchLock({ lockId: replacementId });
      replacementLockFullIdentifier = replacementLockResult.data.full_identifier;
      const result = await lockReplacementService.lockReplacementPreview(previewOptions);

      setState({
        lock: { ...result.data.lock },
        replacementLock: { ...result.data.lock_replacement },
        lockFullIdentifier: lockFullIdentifier,
        replacementLockFullIdentifier: replacementLockFullIdentifier,
        sites: [lockResult.data.site, replacementLockResult.data.site],
        groups: [
          { name: lockResult.data.lock_group_name, id: lockResult.data.lock_group_id },
          { name: replacementLockResult.data.lock_group_name, id: replacementLockResult.data.lock_group_id }
        ],
        isLoading: false
      });
    } catch (e) {
      console.warn("Warning, failed to fetch locks or preview", e);
      let errorMsg = t("error.lockReplacement.general");
      let fields = [];
      if (e.response?.status === 422) {
        const errors = e.response.data?.error?.errors || [];
        const errorFields = errors.map(error => {
          const source = error.source || {};

          if (error.detail.startsWith("different")) {
            return source.field;
          }
        });

        if (!isEmpty(errorFields)) {
          errorMsg = t("error.lockReplacement.differentFields");
          fields = [...errorFields];
        }
      }

      setState({
        lockFullIdentifier: lockFullIdentifier,
        replacementLockFullIdentifier: replacementLockFullIdentifier,
        error: { errorMsg: errorMsg, fields: fields }
      });
    }
  }, [id, replacementId, t]);

  useEffect(() => {
    fetchLocksSummary();
  }, [fetchLocksSummary]);

  useCustomCompareEffect(() => {
    const historyState = getHistoryState();
    if (!isEmpty(historyState.replacementNote) && historyState.replacementNote !== state.replacementNote) {
      setState({ replacementNote: historyState.replacementNote });
    }
  }, [getHistoryState], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleReplacement = async () => {
    try {
      await lockReplacementService.lockReplacement({ lockId: id, replaceBy: replacementId, comments: state.replacementNote });
      dispatch(alertActions.send(t("success.lockReplacement")));

      history.replace(lsyRouter("lock", id));
    } catch (e) {
      console.warn("Warning, failed to replace lock", e);
      dispatch(alertActions.error(t("error.updateLock")));
    }
  };

  const handleCancel = () => {
    setState({
      lock: {},
      replacementLock: {},
      replacementNote: "",
      lockFullIdentifier: "",
      replacementLockFullIdentifier: "",
      sites: [],
      groups: [],
      isLoading: true
    });

    history.replace(lsyRouter("lock_edit", id));
  };

  const renderErrorMessage = () => {
    return <Alert severity="error">
      <AlertTitle>{state.error.errorMsg}</AlertTitle>
      <List dense disablePadding sx={{ listStyleType: "disc", pl: 4 }}>
        {state.error.fields.map(field => {
          return <ListItem key={field} disableGutters sx={{ display: "list-item" }}>
            <Typography variant="body2">
              {field === "lock_type" ? t("lock.lockType") : t(LockConfigOptionsLabels[field].name)}
            </Typography>
          </ListItem>;
        })}
      </List>
    </Alert>;
  };

  const renderButtons = () => {
    return <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={2}>
        <ContainedButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleCancel}
        >
          {t("button.cancel")}
        </ContainedButton>
      </Grid>
      <Grid item xs={2} className={classes.textAlignRight}>
        <ContainedButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleReplacement}
          disabled={!isEmpty(state.error.fields)}
        >
          {t("button.confirm")}
        </ContainedButton>
      </Grid>
    </Grid>;
  };

  return <LsyFrame>
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="h6">{t("label.lockReplacement.title")}</Typography>
        <Typography variant="caption" className={classes.description}>
          {t("label.lockReplacement.description", { lock: state.lockFullIdentifier, replacementLock: state.replacementLockFullIdentifier })}
        </Typography>
      </Grid>
      {isEmpty(state.error.fields) && 
        <Grid item>
          <Alert severity="warning" style={{alignItems: "center"}}>
            <Typography>
              <b>{t("label.lockReplacement.alert")}</b>
            </Typography>
          </Alert>
        </Grid>
      }
      {isEmpty(state.error.fields) ?
        <LockReplacementDetails
          lock={state.lock}
          replaceBy={state.replacementLock}
          sites={state.sites}
          groups={state.groups}
          isLoading={state.isLoading}
        /> :
        <Grid item>{renderErrorMessage()}</Grid>
      }
      <Grid item>
        {renderButtons()}
      </Grid>
    </Grid>
  </LsyFrame>;
}

export default LockReplacement;