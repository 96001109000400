import { twsWebUrl } from "_services/helper";
import { put, post } from "_helpers/axios/gw.client";

const configureAuthenticator = async (password) => {
  let data = { method: "TOTP" };

  if (password) {
    data.password = password;
  }

  return await post(`${twsWebUrl}/users/mfa/setup/authenticator`, data);
};

const confirmAuthenticator = async (mfa_code) => {
  return await post(`${twsWebUrl}/users/mfa/setup/confirm-authenticator`,
    { method: "TOTP", mfa_code }
  );
};

const enable = async (membershipId) => {
  return await put(`${twsWebUrl}/users/${membershipId}/mfa/enable`,
    { method: "TOTP" }
  );
};

const disable = async (membershipId, password) => {
  let data = { method: "TOTP" };

  if (password) {
    data.password = password;
  }
  return await put(`${twsWebUrl}/users/${membershipId}/mfa/disable`, data);
};

/*rotate also accepts a value of ‘codes’ from 3 → 10. Not specifying it will leave it as 5, but it supports up to 10
  For example, { method: "TOTP", codes: 6 }*/
const rotateBackupCodes = async (membershipId) => {
  return await post(`${twsWebUrl}/users/${membershipId}/mfa/rotate`, { method: "TOTP" });
};

export const mfaService = {
  configureAuthenticator,
  confirmAuthenticator,
  enable,
  disable,
  rotateBackupCodes
};