const workSessionTableItemStyle = (theme) => ({
  clickable: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.lsyPalette.primary.light
    }
  },
  dotIcon: {
    color: theme.lsyPalette.stdRed,
    fontSize: theme.lsyPalette.body1,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1)
  },
  hiddenOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  tableItem: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1)
  },
  tableItemComment: {
    color: theme.palette.secondary.main,
    fontSize: theme.lsyPalette.subtitle3,
    marginBottom: theme.spacing(0.5),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "inherit"
  },
  tableItemSelected: {
    backgroundColor: theme.lsyPalette.primary.light
  },
  tableItemSubtitle: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.chipFontSize,
    marginBottom: theme.spacing(0.5)
  },
  tableItemTitle: {
    fontSize: theme.lsyPalette.body1
  },
  width: {
    width: "inherit"
  }
});

export default workSessionTableItemStyle;