const mfaFormStyle = (theme) => ({
  "topMargin": {
    marginTop: "8px"
  },
  "fullWidth": {
    minWidth: "100%"
  },
  "containerStyle": {
    border: "1px solid lightgrey",
    borderRadius: "6px"
  },
  "input": {
    fontFamily: "monospace",
    border: "1px solid lightgrey",
    padding: "0px 8px",
    width: "1.3em !important",
    height: "1.7em",
    fontSize: "2.3em",
    color: theme.palette.text.darkGrey,
    backgroundColor: "white",
    textAlign: "center"
  },
  "focusStyle": {
    outline: "none"
  },
  "button": {
    "marginTop": "30px",
    "textTransform": "none",
    "backgroundColor": theme.lsyPalette.primary.mainDark,
    "color": theme.palette.primary.contrastText,
    "width": "100%",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  "animate": {
    animation: "$glowred",
    animationDuration: "0.7s, 0.35s",
    animationIterationCount: "1, 2"
  },
  "@keyframes glowred": {
    "50%": {
      borderColor: theme.lsyPalette.stdRed
    }
  },
  "topBar": {
    paddingBottom: "20px"
  },
  "mfaInfoLink": {
    "color": theme.lsyPalette.link,
    "&:hover": {
      cursor: "pointer"
    }
  }
});

export default mfaFormStyle;
