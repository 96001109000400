const lsyFooterStyle = theme => ({
  lsyFooter:{
    backgroundColor:theme.lsyPalette.secondary.background
  },
  footerLinks:{
    color:theme.lsyPalette.link,
    textDecoration:"none"
  },
  footer:{
    fontSize: "14px", marginBottom: "25px"
  },
  footerItems:{
    marginLeft: "15px", marginRight: "15px",
    color:theme.lsyPalette.footerText
  },
  footerText:{
    color:theme.lsyPalette.footerText
  }
});
export default lsyFooterStyle;