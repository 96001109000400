import { Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

export const PrivateRoute = ({ component: Component, layoutProps, setLayoutProps, s4_role, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.loggedIn || false);

  return  (
    <Route {...rest} render={props => (
      isAuthenticated
        ? <Component {...props} layoutProps={layoutProps} setLayoutProps={setLayoutProps} s4_role={s4_role} />
        : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    )} />
  );
};

PrivateRoute.propTypes = {
  layoutProps: PropTypes.object,
  setLayoutProps: PropTypes.func,
  s4_role: PropTypes.number,
  location: PropTypes.object,
  component: PropTypes.elementType
};