import Cookies from "universal-cookie";

const cookies = new Cookies();

export const tokenCookieKey = "twsToken";
// no longer stored in a cookie, but rather in localStorage
export const tokenExpKey = tokenCookieKey + "Exp";

const [subdomain] = window.location.hostname.split(/\./);

export const isAuthedDomain = () => (cookies.get("twsAuthSD") && (subdomain === cookies.get("twsAuthSD")));
export const isNotAuthedDomain = () => (cookies.get("twsAuthSD") && (subdomain !== cookies.get("twsAuthSD")));