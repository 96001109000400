import { useContext, useCallback, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { useCustomCompareEffect } from "use-custom-compare";
import { useTranslation } from "react-i18next";
import { isEmpty, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import moment from "moment";

import { alertActions } from "_actions";
import { membershipStatusNameConstants } from "_constants/account.constants";
import { keyStatusName } from "_constants/key.constants";
import { FilteredHardwareTypeOptionMenu, MAX_LOCKS_QUANTITY_FILTER, LOCK_STATUS } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { compactObj, getMomentFor, isNullOrUndefined, systemLogsHelper } from "_helpers";
import { formatLockHexIDs, getLockStatuses } from "_helpers/lock";
import { urlMap } from "_helpers/ReportHelper";
import { tagService, lockgroupService, lockService, userService, siteService } from "_services/lockstasy";
import { genericReducer } from "_reducers/general.reducer";

import ChipDateTimeRange from "_components/Date/ChipDateTimeRange";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import LsyTable2 from "_components/Table/LsyTable2";
import CustomModal from "_components/Modal/CustomModal";
import GridItem from "components/Grid/GridItem";

import {
  Skeleton,
  TablePagination,
  IconButton,
  CircularProgress,
  Chip,
  Grid,
  MenuItem,
  Select as SelectMui,
  TextField,
  Typography
} from "@mui/material";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
  Clear,
  GetApp,
  List as ListIcon,
  Search,
  Style
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import styles from "assets/jss/views/lockstasy/reportsStyle";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

const MAX_AH_TAGS_FILTER = 10;

function ReportsTable(props) {
  const { exports, selectedColumns, filterVariables, setFilterVariables } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation(["default", "systemEvents"]);
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const canReadDeactivationLocks = ability.can("read", "locks.deactivation");
  const lockStatusOptions = getLockStatuses(t);

  const theme = useTheme();

  const [state, setState] = useReducer(genericReducer,
    {
      data: [],
      type: exports.type,
      currentPage: 1,
      rowsPerPage: 10,
      totalData: 0,
      loading: false,
      openSearchUserModal: false,
      openSearchLockModal: false,
      openSearchLockGroupModal: false,
      openSearchSiteModal: false,
      openSearchTagModal: false,
      openSearchUserTagModal: false,
      openSearchLockTagModal: false,
      openEventType: false,
      openExportSelect: false,
      hardwareOptions: [],
      lockIds: "",
      lockIdsError: "",
      isLoadingFetchLocks: false
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const setOpenSearchUser = open => setState({ openSearchUserModal: open });
  const openSearchUser = () => setOpenSearchUser(true);
  const setOpenSearchLock = open => setState({ openSearchLockModal: open, ...!open && { lockIds: "", lockIdsError: "" } });
  const openSearchLock = () => setOpenSearchLock(true);
  const setOpenSearchLockGroup = open => setState({ openSearchLockGroupModal: open });
  const openSearchLockGroup = () => setOpenSearchLockGroup(true);
  const setOpenSearchSite = open => setState({ openSearchSiteModal: open });
  const openSearchSite = () => setOpenSearchSite(true);
  const setOpenSearchTag = open => setState({ openSearchTagModal: open });
  const openSearchTag = () => setOpenSearchTag(true);
  const setOpenSearchUserTag = open => setState({ openSearchUserTagModal: open });
  const openSearchUserTag = () => setOpenSearchUserTag(true);
  const setOpenSearchLockTag = open => setState({ openSearchLockTagModal: open });
  const openSearchLockTag = () => setOpenSearchLockTag(true);
  const setEventTypeOpen = openEventType => setState({ openEventType });
  const openEventType = event => {
    setEventTypeOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const setLockIds = useCallback(lockIds => setState({ lockIds }), []);
  const setLockIdsError = lockIdsError => setState({ lockIdsError });

  const setDateRange = dateRange => setFilterVariables({ start_date: dateRange[0], end_date: dateRange[1] });
  const setUserStatus = value => setFilterVariables({ userStatus: value });
  const setKeyStatus = value => setFilterVariables({ keyStatus: value });
  const setLockType = value => setFilterVariables({ lockType: value });
  const setSelectedUsers = useCallback(users => setFilterVariables({ selectedUsers: users }), [setFilterVariables]);
  const setSelectedLocks = useCallback(locks => setFilterVariables({ selectedLocks: locks }), [setFilterVariables]);
  const setSelectedLockGroups = useCallback(lockGroups => setFilterVariables({ selectedLockGroups: lockGroups }), [setFilterVariables]);
  const setSelectedSites = useCallback(sites => setFilterVariables({ selectedSites: sites }), [setFilterVariables]);
  const setSelectedTags = useCallback(tags => setFilterVariables({ selectedTags: tags }), [setFilterVariables]);
  const setSelectedUserTags = useCallback(tags => setFilterVariables({ selectedUserTags: tags }), [setFilterVariables]);
  const setSelectedLockTags = useCallback(tags => setFilterVariables({ selectedLockTags: tags }), [setFilterVariables]);
  const setEventType = (eventType) => setFilterVariables({ eventType: eventType });
  const setOpenExportSelect = open => setState({ openExportSelect: open });
  const setLockStatus = lockStatus => setFilterVariables({ lockStatus });

  const isExportDisabled = isEmpty(state.data);
  const handleOpenSelect = () => {
    if (isExportDisabled) {
      return;
    }

    setOpenExportSelect(true);
  };

  const handleCloseSelect = () => setOpenExportSelect(false);

  const userStatusOptions = [
    { name: t("reports.allStatus"), value: " "},
    ...Object.keys(membershipStatusNameConstants).map(key => {
      return { name: t(membershipStatusNameConstants[key]), value: key };
    })
  ];

  const keyStatusOptions = [
    { name: t(keyStatusName[0]), value: "0" },
    { name: t(keyStatusName[2]), value: "2" },
    { name: t(keyStatusName[1]), value: "1" },
    { name: t("reports.allStatus"), value: " "}
  ];

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ search: inputValue });
      const formattedResult = result.data.map(v => {
        return v ?
          {
            label: `${v.first_name} ${v.last_name} (${v.email})`,
            id: v.membership_id
          } :
          null;
      });
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, []);

  const fetchLocksWithInput = useCallback(async (inputValue) => {
    try {
      const result = await lockService.fetchLocks({ search: inputValue, status: filterVariables.lockStatus });
      return result.data.map(lock => lock ? { label: lock.full_identifier, id: lock.id } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
      return [];
    }
  }, [filterVariables.lockStatus]);

  const fetchLockGroupsWithInput = useCallback(async (inputValue) => {
    try {
      const result = await lockgroupService.fetchLockgroups({ search: inputValue });
      const formattedResult = result.data.map((group) => group ? { label: group.name, id: group.id } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch lock groups with given input", e);
    }
  }, []);

  const fetchSitesWithInput = useCallback(async (inputValue) => {
    try {
      const result = await siteService.fetchSites({ page_size: 10, search: inputValue });
      const formattedResult = result.data.map(site => site ? { label: site.name, id: site.id } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch sites with given input", e);
    }
  }, []);

  const fetchTagsWithInput = useCallback(async (inputValue) => {
    try {
      const result = await tagService.fetchTags({ page_size: 10, search: inputValue });
      return result.data.map(tag => tag ? { label: tag.name, id: tag.id } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  }, []);

  const createSkeletons = () => {
    let arr = [];
    for (let i = 0; i < state.rowsPerPage; i++) {
      arr.push({
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      });
    }
    return arr;
  };

  const defaultData = [
    [
      {
        id: "Date",
        numeric: false,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type2",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Open",
        numeric: true,
        disablePadding: false,
        label: "-"
      }
    ],
    [...createSkeletons()]
  ];

  useCustomCompareEffect(() => {
    setState({type: exports.type});
  }, [exports], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const filterColumns = data => {
    if (!data) {
      return [exports.header, []];
    }

    const newHeader = selectedColumns.some(column => column.id === "all") ? exports.header : selectedColumns;
    return exports.formatData(data, newHeader);
  };

  const getFilterOptions = useCallback(page => {
    const { start_date, end_date, userStatus, keyStatus, lockType,
      selectedUsers, selectedTags, selectedUserTags, selectedLockTags,
      selectedLocks, selectedLockGroups, selectedSites, orderBy, direction,
      eventType, lockStatus } = filterVariables;

    const getStatus = () => {
      if (!isNullOrUndefined(userStatus)) {
        return userStatus;
      } else if (!isNullOrUndefined(keyStatus)) {
        return keyStatus;
      } else if(exports.type === "Locks" && !isNullOrUndefined(lockStatus)) {
        return lockStatus;
      } else {
        return "";
      }
    };
    
    const sortMap = exports.sortMap;
    const sortBy = sortMap?.[orderBy] ? `${sortMap[orderBy]},${direction}` : null;

    const options = {
      start_date: start_date ? moment(start_date, "YYYY/MM/DD").startOf("day") : "",
      end_date: end_date ? moment(end_date, "YYYY/MM/DD").endOf("day") : "",
      ...page ?
        {
          page: page,
          page_size: state.rowsPerPage
        } : {},
      status: getStatus(),
      hardware_types: [lockType],
      ...exports.url === urlMap.systemLogs ?
        {
          initiator_ids: selectedUsers?.map(v => v.id),
          target_ids: selectedUsers?.map(v => v.id),
          target: "user"
        } :
        {membership_ids: selectedUsers?.map(v => v.id)},
      category_ids: eventType?.map(v => v.value).toString(),
      tag_ids: selectedTags?.map(v => v.id),
      user_tag_ids: selectedUserTags?.map(v => v.id),
      lock_tag_ids: selectedLockTags?.map(v => v.id),
      lock_ids: selectedLocks?.map(v => v.id),
      lock_group_ids: selectedLockGroups?.map(v => v.id),
      lock_collection_ids: selectedSites?.map(v => v.id),
      ...exports.type !== "Locks" && {lock_status: lockStatus},
      sort_by: sortBy
    };

    return compactObj(options);
  }, [filterVariables, state.rowsPerPage, exports.sortMap, exports.type, exports.url]);

  const canFetchReportsData = options => {
    if (!options.lock_ids || options.lock_ids.length <= MAX_LOCKS_QUANTITY_FILTER) {
      return true;
    }

    return false;
  };

  const fetchReportsData = useCallback(async (page) => {
    setState({loading: true});
    const fetchData = exports.fetchData;
    const newPage = page || 1;

    try {
      const options = getFilterOptions(newPage);
      if (!canFetchReportsData(options)) {
        return;
      }

      const result = await fetchData(options);

      setState({data: result.data, loading: false, totalData: result.meta.pagination.total, currentPage: newPage});
    } catch (e) {
      setState({data: [], loading: false, totalData: 0, currentPage: 1});
      console.warn("Warning, failed to fetch reports data with given input", e);
      dispatch(alertActions.send(t("reports.error.searchError"), "error"));
    }
  }, [exports.fetchData, getFilterOptions, dispatch, t]);

  const handleExportReport = async (event) => {
    const fetchData = exports.fetchData;

    try {
      const options = {
        ...getFilterOptions(),
        export: event.target.value
      };

      await fetchData(options);
      dispatch(alertActions.send(t("success.exportReportsData")));
    } catch (e) {
      console.warn("Warning, failed to export", e);
      let errorMsg = t("label.error");
      const response = e.response || {};
      const errors = response.data?.error?.errors || [{}];

      if (response.status === 429) {
        errorMsg = t("reports.error.manyRequests");
      } else if (response.status === 422) {
        if (errors[0].detail === "error.rows.exceeded") {
          const maxRows = errors[0].boundaries?.max || "";
          errorMsg = t("reports.error.tooManyRows", { maxRows: maxRows });
        }
      } else {
        errorMsg = t("label.error");
      }
      dispatch(alertActions.send(errorMsg, "error"));
    }
  };

  useCustomCompareEffect(() => {
    if (state.type === exports.type) {
      fetchReportsData();
    }
  }, [filterVariables, fetchReportsData], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const fetchLockType = useCallback(async () => {
    try {
      const serverLockTypes = await lockService.fetchHardwareTypes();
      setState({ hardwareOptions: [
        { name: t("label.allLocks"), value: " "},
        ...FilteredHardwareTypeOptionMenu(serverLockTypes.data["hardware_types"])
      ] });
    } catch (e) {
      console.warn("Failed to fetch hardware options: ", e);
      return [];
    }
  }, [t]);

  const eventTypeFilterOptions = () => {
    const excludes = ["legend.9"];
    let options = [];

    systemLogsHelper.logCategories.map(cat => {
      if (!excludes.includes(cat.label)) {
        options.push({ label: t(`systemEvents:${cat.label}`), value: cat.value });
      }
    });

    return options;
  };

  useCustomCompareEffect(() => {
    fetchLockType();
  }, [fetchLockType], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const sort = (orderBy, direction) => {
    setFilterVariables({
      orderBy: orderBy,
      direction: direction
    });
  };

  const handleChangePage = (event, newPage) => {
    fetchReportsData(newPage + 1);
  };

  const getFetchOption = useCallback((type) => {
    if (type === "users") {
      return fetchUsersWithInput;
    } else if (type === "locks") {
      return fetchLocksWithInput;
    } else if (type === "lockGroups") {
      return fetchLockGroupsWithInput;
    } else if (type === "sites") {
      return fetchSitesWithInput;
    } else if (type === "tags") {
      return fetchTagsWithInput;
    } else if (type === "userTags") {
      return fetchTagsWithInput;
    } else if (type === "lockTags") {
      return fetchTagsWithInput;
    }
  }, [fetchUsersWithInput, fetchLocksWithInput, fetchLockGroupsWithInput, fetchSitesWithInput, fetchTagsWithInput]);

  const getSelectorData = useCallback((type) => {
    if (type === "users") {
      return filterVariables.selectedUsers || [];
    } else if (type === "locks") {
      return filterVariables.selectedLocks || [];
    } else if (type === "lockGroups") {
      return filterVariables.selectedLockGroups || [];
    } else if (type === "sites") {
      return filterVariables.selectedSites || [];
    } else if (type === "tags") {
      return filterVariables.selectedTags || [];
    } else if (type === "userTags") {
      return filterVariables.selectedUserTags || [];
    } else if (type === "lockTags") {
      return filterVariables.selectedLockTags || [];
    }
  }, [
    filterVariables.selectedUsers,
    filterVariables.selectedLocks,
    filterVariables.selectedLockGroups,
    filterVariables.selectedSites,
    filterVariables.selectedTags,
    filterVariables.selectedUserTags,
    filterVariables.selectedLockTags
  ]);

  const setSelectorData = useCallback((e, type) => {
    if (type === "users") {
      setSelectedUsers(e);
    } else if (type === "locks") {
      setSelectedLocks(e);
    } else if (type === "lockGroups") {
      setSelectedLockGroups(e);
    } else if (type === "sites") {
      setSelectedSites(e);
    } else if (type === "tags") {
      setSelectedTags(e);
    } else if (type === "userTags") {
      setSelectedUserTags(e);
    } else if (type === "lockTags") {
      setSelectedLockTags(e);
    }
  }, [setSelectedUsers, setSelectedLocks, setSelectedLockGroups, setSelectedSites, setSelectedTags, setSelectedUserTags, setSelectedLockTags]);

  const reachedTagLimit = useCallback(type => {
    const reachedUserTagsLimit = type === "userTags" && filterVariables.selectedUserTags?.length === MAX_AH_TAGS_FILTER;
    const reachedLockTagsLimit = type === "lockTags" && filterVariables.selectedLockTags?.length === MAX_AH_TAGS_FILTER;

    return reachedUserTagsLimit || reachedLockTagsLimit;
  }, [filterVariables.selectedUserTags, filterVariables.selectedLockTags]);

  const selector = useCallback((type) => {
    return (
      <AsyncSelect
        className={classes.itemBox}
        isClearable={false}
        isDisabled={reachedTagLimit(type)}
        isMulti
        autoFocus
        backspaceRemovesValue={false}
        captureMenuScroll={true}
        hideSelectedOptions={true}
        value={getSelectorData(type)}
        onChange={(e) => setSelectorData(e, type)}
        defaultOptions={true}
        loadOptions={getFetchOption(type)}
        isOptionDisabled={(option) => option.disabled}
        autoBlur={false}
        closeMenuOnSelect={true}
        blurInputOnSelect={false}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => option.id}
        noOptionsMessage={() => t("fallbacks.noOptions")}
        loadingMessage={() => t("actions.loading")}
        theme={(base) => ({
          ...base,
          colors: {
            ...base.colors,
            primary25: theme.lsyPalette.rowHover,
            primary50: theme.lsyPalette.rowHover,
            primary: theme.lsyPalette.primary.mainLight
          }
        })}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            minHeight: "60px",
            height: "60px"
          }),
          valueContainer: (provided) => ({
            ...provided,
            height: "60px",
            padding: "0 6px"
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "60px"
          }),
          multiValue: (provided) => ({ ...provided, display: "none" })
        }}
        menuPortalTarget={document.body}
      />
    );
  }, [
    classes,
    t,
    getFetchOption,
    getSelectorData,
    setSelectorData,
    reachedTagLimit,
    theme
  ]);

  const renderMaxTagsMessage = useCallback((type) => {
    return reachedTagLimit(type) ?
      <Typography variant="caption">
        {t("reports.tagsSearch")}
      </Typography> :
      null;
  }, [reachedTagLimit, t]);

  const handleChangeLockIds = useCallback(event => setState({ lockIds: event.target.value, lockIdsError: "" }), []);

  const handleListLockIds = useCallback(async event => {
    if (event.key !== "Enter") {
      return;
    }
    setState({isLoadingFetchLocks: true});
    
    const formattedIds = formatLockHexIDs(state.lockIds, t);

    if (formattedIds.error) {
      setLockIdsError(formattedIds.error);
    } else if (!formattedIds.ids) {
      return;
    } else {
      try {
        const result = await lockService.fetchLocks({
          page: 1,
          page_size: MAX_LOCKS_QUANTITY_FILTER,
          ids: formattedIds.ids,
          status: isNullOrUndefined(filterVariables.lockStatus) ? LOCK_STATUS.active : filterVariables.lockStatus
        });
        const formattedResult = result.data.map(lock => lock ? { label: lock.full_identifier, id: lock.id } : null);
        const newSelectedLocks = filterVariables.selectedLocks ?
          [
            ...filterVariables.selectedLocks,
            ...formattedResult.filter(lock => filterVariables.selectedLocks.every(selectedLock => selectedLock.id !== lock.id))
          ] :
          formattedResult;
        setSelectedLocks(newSelectedLocks);
        setLockIds("");
      } catch (e) {
        console.warn("Warning, failed to fetch locks", e);
      }
    }
    setState({isLoadingFetchLocks: false});
  }, [state.lockIds, filterVariables.selectedLocks, filterVariables.lockStatus, setLockIds, setSelectedLocks, t]);

  const renderSearchLockIds = useCallback(type => {
    const searchByLockId = t("label.searchByLockId", { max: MAX_LOCKS_QUANTITY_FILTER, newLine: "" });

    return type === "locks" &&
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={state.isLoadingFetchLocks ? 11 : 12}>
          <TextField
            fullWidth
            value={state.lockIds}
            onChange={handleChangeLockIds}
            label={t("form.listLockIds")}
            className={classes.textField}
            InputLabelProps={{ className: classes.textFieldLabel }}
            InputProps={{ className: classes.textFieldInput }}
            FormHelperTextProps={{ className: classes.textFieldHelper }}
            onKeyDown={handleListLockIds}
            helperText={state.lockIdsError || searchByLockId}
            error={!isEmpty(state.lockIdsError)}
          />
        </Grid>
        {state.isLoadingFetchLocks &&
          <Grid item>
            <CircularProgress className={classes.circularProgress} size="30px" />
          </Grid>}
      </Grid>;
  }, [state.isLoadingFetchLocks, state.lockIds, state.lockIdsError, classes, handleChangeLockIds, handleListLockIds, t]);

  const renderLockMessage = useCallback(type => {
    const quantityLocksSelected = getSelectorData(type).length;
    const exceededLocksLimit = quantityLocksSelected > MAX_LOCKS_QUANTITY_FILTER;

    return type === "locks" ?
      <Typography variant="caption" {...exceededLocksLimit ? {color: "error"} : {}} className={classes.selectionPageCaption}>
        {exceededLocksLimit ?
          t("error.maxLocksSelected", { maxLocks: MAX_LOCKS_QUANTITY_FILTER, locks: quantityLocksSelected }) : 
          `${t("label.numOfLocksSelected")}: ${quantityLocksSelected}`}
      </Typography> : null;
  }, [getSelectorData, t, classes]);

  const selectionPage = useCallback((type) => {
    const page = (
      <Grid container>
        {selector(type)}
        {renderMaxTagsMessage(type)}
        {renderSearchLockIds(type)}
        <div className={classes.confirmSelection}>
          {getSelectorData(type).map((value) => {
            return (
              <GridItem className={classes.selectionItem} key={value.id}>
                <div className={classes.selectionPageDiv}>
                  <div className={classes.selectedNames}>{value.label}</div>
                  <IconButton
                    className={classes.removeItem}
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setSelectorData(
                        getSelectorData(type).filter((item) => {
                          return item.id !== value.id;
                        }), type
                      );
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </div>
              </GridItem>
            );
          })}
        </div>
        {renderLockMessage(type)}
      </Grid>
    );
    return page;
  }, [classes, selector, getSelectorData, setSelectorData, renderMaxTagsMessage, renderSearchLockIds, renderLockMessage]);

  const handleUserStatus = event => {
    const value = event.target.value;
    setUserStatus(value);
  };

  const renderUserStatus = () => {
    return <SelectMui
      value={isNullOrUndefined(filterVariables.userStatus) ? " " : filterVariables.userStatus}
      className={classes.select}
      onChange={handleUserStatus}
    >
      {userStatusOptions.map(option => (
        <MenuItem key={`userStatus${option.value}`} value={option.value} className={classes.selectOptions}>
          {option.name}
        </MenuItem>))}
    </SelectMui>;
  };

  const handleKeyStatus = event => {
    const value = event.target.value;
    setKeyStatus(value);
  };

  const renderKeyStatus = () => {
    return <SelectMui
      value={isNullOrUndefined(filterVariables.keyStatus) ? " " : filterVariables.keyStatus}
      className={classes.select}
      onChange={handleKeyStatus}
    >
      {keyStatusOptions.map(option => (
        <MenuItem key={`keyStatus${option.value}`} value={option.value} className={classes.selectOptions}>
          {option.name}
        </MenuItem>))}
    </SelectMui>;
  };

  const handleLockType = event => {
    const value = event.target.value;
    setLockType(value);
  };

  const renderLockType = () => {
    return <SelectMui
      value={filterVariables.lockType || " "}
      className={classes.select}
      onChange={handleLockType}
    >
      {state.hardwareOptions.map(option => (
        <MenuItem key={`userStatus${option.value}`} value={option.value} className={classes.selectOptions}>
          {option.name}
        </MenuItem>))}
    </SelectMui>;
  };

  //Reset function defintions
  const resetUsersFilter = () => {setSelectedUsers(exports.initialFilterValues.selectedUsers);};
  const resetUserStatusFilter =  () => {setUserStatus(exports.initialFilterValues.userStatus);};
  const resetLocksFilter = () => {setSelectedLocks(exports.initialFilterValues.selectedLocks);};
  const resetLockType = () => {setLockType(exports.initialFilterValues.LockType);};
  const resetKeyStatusFilter = () => {setKeyStatus(exports.initialFilterValues.keyStatus);};
  const resetLockGroupsFilter = () => {setSelectedLockGroups(exports.initialFilterValues.selectedLockGroups);};
  const resetSitesFilter = () => {setSelectedSites(exports.initialFilterValues.selectedSitesites);};
  const resetTagsFilter = () => {setSelectedTags(exports.initialFilterValues.selectedTags);};
  const resetUserTags = () => {setSelectedUserTags(exports.initialFilterValues.selectedUserTags);};
  const resetLockTags  = () => {setSelectedLockTags(exports.initialFilterValues.selectedLockTags);};
  const resetEventType = () => {setEventType(exports.initialFilterValues.selectedEventType);};
  
  const handleLockStatus = event => {
    const value = event.target.value;
    setLockStatus(value);
  };

  const renderLockStatusValue = () => {
    return `${t("label.lockStatus")}: ${t(lockStatusOptions.find(s => s.value == filterVariables.lockStatus)?.name)}`;
  };

  const renderLockStatus = () => {
    if (!canReadDeactivationLocks || !exports.filters.includes("lockStatus")) {
      return null;
    }

    return <Grid item>
      <SelectMui
        data-testid="lockStatusSelect"
        value={isNullOrUndefined(filterVariables.lockStatus) ? "" : filterVariables.lockStatus}
        className={classes.selectStatus}
        onChange={handleLockStatus}
        IconComponent={() => null}
        renderValue={renderLockStatusValue}
        size="small"
        SelectDisplayProps={{ style: { paddingRight: 12 }}}
      >
        {lockStatusOptions.map(option => (
          <MenuItem key={`keyStatus${option.value}`} value={option.value} className={classes.selectOptions}>
            {option.name}
          </MenuItem>
        ))}
      </SelectMui>
    </Grid>;
  };

  const renderFilters = () => {
    return <Grid container alignItems="center" spacing={1}>
      {exports.filters.includes("dates") &&
        <Grid item>
          <ChipDateTimeRange
            startDate={getMomentFor(filterVariables.start_date)}
            endDate={getMomentFor(filterVariables.end_date)}
            setDateRange={setDateRange}
          />
        </Grid>
      }
      { renderLockStatus() }
      {exports.filters.includes("users") &&
        <Grid item>
          <Chip
            data-testid="searchUserChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("features.users")}${filterVariables.selectedUsers?.length > 0 ? ": " + filterVariables.selectedUsers?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchUser}
            onDelete={resetUsersFilter}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("userStatus") &&
        <Grid item>
          <Chip
            data-testid="userStatusChip"
            label={renderUserStatus()}
            className={classes.chip}
            onDelete={resetUserStatusFilter}
          />
        </Grid>
      }
      {exports.filters.includes("userRole") &&
        <Grid item>
          <Chip
            label={renderUserStatus()}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("userMfa") &&
        <Grid item>
          <Chip
            label={renderUserStatus()}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("locks") &&
        <Grid item>
          <Chip
            data-testid="searchLockChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("features.locks")}${filterVariables.selectedLocks?.length > 0 ? ": " + filterVariables.selectedLocks?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchLock}
            onDelete={resetLocksFilter}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("lockType") &&
        <Grid item>
          <Chip
            data-testid="lockTypeChip"
            label={renderLockType()}
            className={classes.chip}
            onDelete={resetLockType}
          />
        </Grid>
      }
      {exports.filters.includes("keyStatus") &&
        <Grid item>
          <Chip
            data-testid="keyStatusChip"
            label={renderKeyStatus()}
            className={classes.chip}
            onDelete = {resetKeyStatusFilter}
          />
        </Grid>
      }
      {exports.filters.includes("lockGroups") &&
        <Grid item>
          <Chip
            data-testid="searchLockGroupChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("features.lockGroups")}${filterVariables.selectedLockGroups?.length > 0 ? ": " + filterVariables.selectedLockGroups?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchLockGroup}
            onDelete={resetLockGroupsFilter}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("sites") &&
        <Grid item>
          <Chip
            data-testid="searchSiteChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("features.lockCollections")}${filterVariables.selectedSites?.length > 0 ? ": " + filterVariables.selectedSites?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchSite}
            className={classes.chip}
            onDelete={resetSitesFilter}
          />
        </Grid>
      }
      {exports.filters.includes("tags") &&
        <Grid item>
          <Chip
            data-testid="searchTagChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("features.tags")}${filterVariables.selectedTags?.length > 0 ? ": " + filterVariables.selectedTags?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchTag}
            onDelete={resetTagsFilter}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("userTags") &&
        <Grid item>
          <Chip
            data-testid="searchUserTagChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("label.userTags")}${filterVariables.selectedUserTags?.length > 0 ? ": " + filterVariables.selectedUserTags?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchUserTag}
            onDelete={resetUserTags}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("lockTags") &&
        <Grid item>
          <Chip
            data-testid="searchLockTagChip"
            label={
              <div className={classes.header}>
                <Search className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>{`${t("label.lockTags")}${filterVariables.selectedLockTags?.length > 0 ? ": " + filterVariables.selectedLockTags?.length : ""}`}</span>
                </p>
              </div>}
            onClick={openSearchLockTag}
            onDelete={resetLockTags}
            className={classes.chip}
          />
        </Grid>
      }
      {exports.filters.includes("eventType") &&
        <Grid item>
          <Chip
            data-testid="searchEventTypeChip"
            label={
              <div className={classes.header}>
                <Style className={classes.chipIcon}/>
                <p className={classes.chipText}>
                  <span>
                    {`${t("label.eventTypes")}${filterVariables.eventType?.length > 0 ? ": " + filterVariables.eventType.length : ""}`}
                  </span>
                </p>
              </div>}
            onClick={openEventType}
            onDelete={resetEventType}
            className={classes.chip}
          />
        </Grid>
      }
    </Grid>;
  };

  const renderExportButton = () => {
    const exportLabel = () => t("actions.export");

    return <Chip
      label={
        <div className={classes.header}>
          <GetApp
            className={cx(classes.chipIcon, isExportDisabled ? "" : classes.clickable)}
            color={isExportDisabled ? "disabled" : "inherit"}
            onClick={handleOpenSelect}/>
          <SelectMui
            data-testid="exportReportSelect"
            value={exportLabel()}
            className={classes.select}
            onChange={handleExportReport}
            open={state.openExportSelect}
            onClose={handleCloseSelect}
            onOpen={handleOpenSelect}
            disabled={isExportDisabled}
            renderValue={exportLabel}
            IconComponent={() => null}
            SelectDisplayProps={{ style: { paddingRight: 0 } }}
          >
            <MenuItem value={exportLabel()} className={classes.selectHiddenOption}/>
            <MenuItem value={"csv"} className={classes.selectOptions}>CSV</MenuItem>
            <MenuItem value={"xlsx"} className={classes.selectOptions}>XLSX</MenuItem>
          </SelectMui>
        </div>}
      className={classes.chip}
    />;
  };

  const renderTable = () => {
    return <Grid container>
      <Grid item xs={12}>
        <ErrorBoundary>
          <div className={classes.table}>
            <div data-testid="reportTable">
              {!state.loading && isEmpty(state.data) ?
                <Placeholder message={t("reports.noDataFound")} icon={<ListIcon/>}/> :
                <LsyTable2
                  data={state.loading ? defaultData : filterColumns(state.data)}
                  sort={sort}
                  orderBy={state.orderBy}
                  sortDirection={state.direction}
                />
              }
            </div>
            <div data-testid="pagination">
              <TablePagination
                className={classes.pagination}
                component="div"
                count={state.totalData || 0}
                rowsPerPage={state.rowsPerPage}
                page={state.currentPage - 1}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
          </div>
        </ErrorBoundary>
      </Grid>
    </Grid>;
  };

  const renderSelectEventType = () => {
    return <div className={classes.eventTypeForm}>
      <Select
        defaultValue={filterVariables.eventType}
        isMulti
        autoFocus
        options={eventTypeFilterOptions()}
        className={cx("basic-multi-select", classes.eventType)}
        classNamePrefix="select"
        styles={{ menu: base => ({ ...base, position: "relative" }) }}
        onChange={value => setEventType(value)}
        theme={(base) => ({
          ...base,
          colors: {
            ...base.colors,
            primary25: theme.lsyPalette.rowHover,
            primary50: theme.lsyPalette.rowHover,
            primary: theme.lsyPalette.primary.mainLight
          }
        })}
      />
    </div>;
  };

  return <Grid container>
    <Grid item xs={12}>
      <Grid container justifyContent="space-between">
        <Grid item>
          {renderFilters()}
        </Grid>
        <Grid item>
          {renderExportButton()}
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      {renderTable()}
    </Grid>
    <div>
      <CustomModal
        open={state.openSearchUserModal}
        setOpen={setOpenSearchUser}
        type="custom"
        description={selectionPage("users")}
        title={t("instructions.selectUsers")}
        clearIcon
        modalStyle={classes.modal}
      />
      <CustomModal
        open={state.openSearchLockModal}
        setOpen={setOpenSearchLock}
        type="custom"
        description={selectionPage("locks")}
        title={t("instructions.selectLocks")}
        clearIcon
        modalStyle={classes.modal}
      />

      <CustomModal
        open={state.openSearchLockGroupModal}
        setOpen={setOpenSearchLockGroup}
        type="custom"
        description={selectionPage("lockGroups")}
        title={t("instructions.selectLockGroups")}
        clearIcon
        modalStyle={classes.modal}
      />

      <CustomModal
        open={state.openSearchSiteModal}
        setOpen={setOpenSearchSite}
        type="custom"
        description={selectionPage("sites")}
        title={t("instructions.selectSites")}
        clearIcon
        modalStyle={classes.modal}
      />

      <CustomModal
        open={state.openSearchTagModal}
        setOpen={setOpenSearchTag}
        type="custom"
        description={selectionPage("tags")}
        title={t("instructions.selectTags")}
        modalStyle={classes.modal}
        clearIcon
      />
      <CustomModal
        open={state.openSearchUserTagModal}
        setOpen={setOpenSearchUserTag}
        type="custom"
        description={selectionPage("userTags")}
        title={t("instructions.selectUserTags")}
        modalStyle={classes.modal}
        clearIcon
      />
      <CustomModal
        open={state.openSearchLockTagModal}
        setOpen={setOpenSearchLockTag}
        type="custom"
        description={selectionPage("lockTags")}
        title={t("instructions.selectLockTags")}
        modalStyle={classes.modal}
        clearIcon
      />
      <CustomModal
        open={state.openEventType}
        setOpen={setEventTypeOpen}
        anchorEl={anchorEl}
        type="customPopover"
        description={renderSelectEventType()}
        submit
      />
    </div>
  </Grid>;
}

ReportsTable.propTypes = {
  exports: PropTypes.object,
  filterVariables: PropTypes.object,
  setFilterVariables: PropTypes.func,
  selectedColumns: PropTypes.array
};

export default ReportsTable;