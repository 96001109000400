const ax5Style = theme => ({
  ax5: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  svg: {
    enableBackground: "new 0 0 672 672"
  }
});

export default ax5Style;
