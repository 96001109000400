import { twsWebUrl } from "_services/helper";
import { get } from "_helpers/axios/gw.client";

const fetchKeyCreation = async (options) => {
  return await get(`${twsWebUrl}/charts/key_summary`, options);
};

const fetchLockOpenings = async (options) => {
  return await get(`${twsWebUrl}/charts/lock_summary`, options);
};

const fetchLockAccess = async (options) => {
  return await get(`${twsWebUrl}/charts/open_summary`, options);
};

const fetchUserOpenings = async (options) => {
  return await get(`${twsWebUrl}/charts/user_summary`, options);
};

const fetchUserTimeAccess = async (options) => {
  return await get(`${twsWebUrl}/charts/user_time`, options);
};

export const chartService = {
  fetchKeyCreation,
  fetchLockAccess,
  fetchLockOpenings,
  fetchUserOpenings,
  fetchUserTimeAccess
};