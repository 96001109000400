import { forwardRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";

const styles = {
  grid: {
    padding: "0 15px !important"
  }
};

const useStyles = makeStyles()(styles);

const GridItem = forwardRef((props, ref) => {
  const { classes, cx } = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid item ref={ref} {...rest} className={cx(classes.grid, (className || ""))}>
      {children}
    </Grid>
  );
});

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

GridItem.displayName = "GridItem";

export default GridItem;