import { twsWebUrl } from "_services/helper";
import { get } from "_helpers/axios/gw.client";

const fetchAnnouncement = async (options) => {
  options.latest = 1;
  return await get(`${twsWebUrl}/announcements`, options);
};

export const announcementService = {
  fetchAnnouncement
};