/* Service which returns the currently stored UUIDs and their associated firstname lastname */

/**
 * Returns only the new uuids that need their firstname lastnames retrieved
 */
export const getUuidParams = (data, cachedData, accessor) => {
  let cachedUuids = cachedData.data;
  let params = [];
  let visited = new Set();

  for (let i = 0; i < cachedUuids.length; i++) {
    visited.add(cachedUuids[i].id);
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i][accessor] && !visited.has(data[i][accessor])) {
      params.push(data[i][accessor]);
      visited.add(data[i][accessor]);
    }
  }

  return params;
};
