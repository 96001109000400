import { useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import { useCustomCompareEffect } from "use-custom-compare";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get, isEqual, isEmpty } from "lodash";

import appRoutes from "_routes";
import { genericReducer } from "_reducers/general.reducer";
import { MFA_CODE_LENGTH } from "_constants";
import { localeActions, authActions, alertActions } from "_actions";
import { compactObj } from "_helpers";
import { authService } from "_services";

import LoginFrame from "_components/Auth/LoginFrame";
import PasswordForm from "_components/Auth/LoginForm/PasswordForm";
import ForgotPasswordForm from "_components/Auth/LoginForm/ForgotPasswordForm";
import CustomAlert from "_components/Alert/CustomAlert.js";
import ContainedButton from "_components/Button/ContainedButton.js";
import { MfaForm } from "_components/Auth/MfaForm";
import MfaSetupForm from "_components/Form/MfaSetupForm.js";
import Loader from "_components/Loading";
import MfaCodeModal from "_components/Modal/MfaCodeModal";
import TermsAndConditions from "_components/Auth/Form/TermsAndConditions";
import PersonalInformation from "_components/Auth/Form/PersonalInformation";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/passwordStyle";

const useStyles = makeStyles()(styles);

const Password = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const dispatch = useDispatch();
  const [ state, setState ] = useReducer(genericReducer,
    {
      open: false,
      isAuthenticating: false,
      isLoading: false,
      isLoadingPw: false,
      language: "",
      renderContent: "password",
      usernameEmail: "",
      password:"",
      isUsernameEmailEmpty: false,
      isPasswordEmpty: false,
      termsAccepted: false,
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      mfaCode: "",
      mfaCodeIncorrect: false
    }
  );
  const setRenderContent = content => setState({ renderContent: content });
  const setTermsAccepted = accepted => setState({ termsAccepted: accepted });
  const { tcStep, piStep, mfaEnabled, mfaSetup, mfaSetupData, error, success } = useSelector(state => state.auth);
  const { modalProps } = useSelector(state => state.modal);

  const displayNotifications = useCallback((level) => {
    if (level === "success") {
      let event = success;

      if (event.match(/reset_success/)) {
        dispatch(alertActions.send(t("auth:login.resetSuccess"), level));
      }
    } else {
      let event = error;

      if (event.match(/unauthenticated/)) {
        dispatch(alertActions.send(t("auth:login.unauthorized"), level));
      } else if (event.match(/account_inactive/)) {
        dispatch(alertActions.send(t("auth:login.account.inactive"), level));
      } else if (event.match(/account_disabled/)) {
        dispatch(alertActions.send(t("auth:login.account.disabled"), level));
      } else if (event.match(/account_locked/)) {
        dispatch(authActions.resetMfa());
        dispatch(alertActions.send(t("auth:login.account.locked"), level));
      } else if (event.match(/not_found/)) {
        dispatch(alertActions.send(t("auth:passwordless.accountNotFound"), level));
      } else if (event.match(/reset_failure/)) {
        dispatch(alertActions.send(t("auth:login.resetFailure"), level));
      } else if (event.match(/network_error/)) {
        dispatch(alertActions.send(t("auth:login.networkError"), level));
      } else if (event.match(/invalid_mfa_code/)) {
        dispatch(alertActions.send(t("auth:mfa.codeInvalidNotif"), level));
        if (!mfaSetup) {
          setState({ mfaCode: "", mfaCodeIncorrect: true });
          let mfaFormInput = document.getElementsByClassName(
            "mfaform-input-field"
          )[0];
          mfaFormInput?.focus();
        }
      }
    }

    setState({
      isLoading: false,
      isLoadingPw: false,
      isAuthenticating: false
    });
  }, [error, success, dispatch, t, mfaSetup]);

  useCustomCompareEffect(() => {
    if (error) {
      displayNotifications("error");
    } else if (success) {
      displayNotifications("success");
    }
  }, [displayNotifications], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    if (modalProps.user) {
      setState({
        email: modalProps.user.email,
        username: modalProps.user.username,
        firstName: modalProps.user.first_name,
        lastName: modalProps.user.last_name,
        language: modalProps.user.language
      });
    }
  }, [modalProps], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    if (!state.open) {
      const historyState = history.state?.state || {};

      if (historyState.isAuthenticating) {
        dispatch(authActions.login(historyState.usernameEmail, historyState.password, state.language));
        setState({ open: true, ...historyState });
      } else {
        setTimeout(() => setState({ open: true, ...historyState }), 500);
      }
    }

    if (props.renderContent !== state.renderContent) {
      setRenderContent(props.renderContent);
    }
  });

  const setUsernameEmail = usernameEmail => setState({ 
    usernameEmail: usernameEmail,
    isUsernameEmailEmpty: isEmpty(usernameEmail)
  });

  const setPassword = password => setState({
    password: password,
    isPasswordEmpty: isEmpty(password)
  });
  const setUsername = username => setState({ username });
  const setFirstName = firstName => setState({
    firstName,
    isFirstNameEmpty: isEmpty(firstName)
  });
  const setLastName = lastName => setState({
    lastName,
    isLastNameEmpty: isEmpty(lastName)
  });

  const onChangeLanguage = (language) => {
    dispatch(localeActions.setLocale({ language: language }));
    setState({ language: language });
  };

  const openForgotPassword = () => props.history.push(appRoutes.public.forgotPassword);
  const openPassword = () => props.history.push(appRoutes.public.password);

  const renderContainedButton = (label, isLoading, disabled, buttonProps) => {
    return <Grid item xs={10}>
      <ContainedButton
        fullWidth
        variant="contained"
        disabled={disabled}
        {...buttonProps}
      >
        {isLoading ?
          <CircularProgress className={classes.spinner} size={30} /> :
          label}
      </ContainedButton>
    </Grid>;
  };

  const onForgotPassword = () => {
    if (isEmpty(state.usernameEmail)) {
      setState({ isUsernameEmailEmpty: isEmpty(state.usernameEmail) });
      return;
    }

    setState({ isLoading: true });
    dispatch(authActions.resetPassword(state.usernameEmail));
  };

  const renderForgotPassword = () => {
    return <ForgotPasswordForm
      username={state.usernameEmail}
      isUsernameEmpty={state.isUsernameEmailEmpty}
      isLoading={state.isLoading}
      setUsername={setUsernameEmail}
      onCancel={openPassword}
      onForgotPassword={onForgotPassword}
    />;
  };

  const onClickSignInWithPassword = () => {
    if (isEmpty(state.usernameEmail) || isEmpty(state.password)) {
      setState({ 
        isUsernameEmailEmpty: isEmpty(state.usernameEmail),
        isPasswordEmpty: isEmpty(state.password)
      });
      return;
    }

    setState({ isLoadingPw: true });
    dispatch(authActions.login(state.usernameEmail, state.password, state.language, state.mfaCode, mfaSetup));
  };

  const verifyMfaCode = (mfa_code) => {
    setState({ mfaCode: mfa_code });
    if (mfa_code.length === MFA_CODE_LENGTH) {
      dispatch(authActions.login(state.usernameEmail, state.password, state.language, mfa_code, mfaSetup, piStep));
    }
  };

  const onCancelMfaCode = () => {
    dispatch(authActions.resetMfa());
    setState({ mfaCode: "", mfaCodeIncorrect: false });
  };

  const renderMfaCode = () => {
    return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={10}>
            <MfaForm
              onChange={verifyMfaCode}
              value={state.mfaCode}
            />
          </Grid>
          {state.mfaCodeIncorrect &&
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <CustomAlert type={"MfaFirstAttemptIncorrect"} style={{alignItems: "center"}}/>
                </Grid>
              </Grid>
            </Grid>}
          <Grid item xs={10}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button variant="text" disableRipple size="small" className={classes.button} onClick={onCancelMfaCode}>
                  {t("button.cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const onChangeSetupMfaCode = (event) => {
    setState({ mfaCode: event.target.value });
  };

  const closeMfaBkCodesModal = () => {
    dispatch(authActions.resetMfa());
    if (!piStep) {
      dispatch(authActions.success(modalProps.user, modalProps.memberships, "password"));
    }
  };

  const enableMfa = () => {
    dispatch(authActions.login(state.usernameEmail, state.password, state.language, state.mfaCode, mfaSetup, piStep));
  };

  const renderMfaSetup = () => {
    return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <CustomAlert type="MfaInfo" />
            <MfaSetupForm
              data={mfaSetupData}
              onChange={onChangeSetupMfaCode}
              code={state.mfaCode}
              onSubmit={enableMfa}
            />
            <MfaCodeModal
              showModal={modalProps.open || false}
              handleClose={closeMfaBkCodesModal}
              codes={modalProps.user?.auth_options?.TOTP?.backup_codes || []}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center" className={classes.bottomMargin}>
          {renderContainedButton(t("button.enable"), state.isLoading, state.isLoading || state.mfaCode.length !== MFA_CODE_LENGTH,
            { color:"secondary", onClick: enableMfa })}
        </Grid>
      </Grid>
    </Grid>;
  };

  const renderMfa = () => mfaSetup ? renderMfaSetup() : renderMfaCode();

  const agreeTC = () => {
    setState({ isLoading: true });
    dispatch(authActions.login(state.usernameEmail, state.password, state.language, state.mfaCode, mfaSetup, piStep, state.termsAccepted));
  };
  
  const renderTermsConditions = () => {
    return <TermsAndConditions termsAccepted={state.termsAccepted} setTermsAccepted={setTermsAccepted} onAgreement={agreeTC}/>;
  };
  
  const onSkip = () => dispatch(authActions.success(modalProps.user, modalProps.memberships, "password"));

  const onConfirmPersonalInfo = async (newPassword) => {
    if ((!isEmpty(state.username) && state.username !== modalProps.user.username) || state.firstName !== modalProps.user.first_name || state.lastName !== modalProps.user.last_name) {
      try {
        const data = {
          language: state.language,
          username: state.username,
          first_name: state.firstName,
          last_name: state.lastName,
          password: newPassword
        };

        await authService.updatePersonalInfoAccount(modalProps.user.id, compactObj(data));
        dispatch(authActions.success(modalProps.user, modalProps.memberships, "password"));
      } catch(e) {
        if (get(e, "response.status") === 409) {
          dispatch(alertActions.send(t("error.notUniqueUsername"), "error"));
        } else {
          dispatch(alertActions.send(t("error.updateUser"), "error"));
        }
      }
    }
  };

  const renderPersonalInfo = () => {
    return <PersonalInformation 
      onChangeLanguage={onChangeLanguage}
      email={state.email}
      username={state.username}
      onChangeUsername={setUsername}
      firstName={state.firstName}
      onChangeFirstName={setFirstName}
      lastName={state.lastName}
      onChangeLastName={setLastName}
      onConfirmPersonalInfo={onConfirmPersonalInfo}
      onCancel={onSkip}
    />;
  };

  const renderPasswordForm = () => {
    return tcStep ? renderTermsConditions() :
      mfaEnabled ? 
        renderMfa() :
        piStep ?
          renderPersonalInfo() :
          <PasswordForm
            username={state.usernameEmail}
            isUsernameEmpty={state.isUsernameEmailEmpty}
            password={state.password}
            isPasswordEmpty={state.isPasswordEmpty}
            isLoadingPw={state.isLoadingPw}
            setUsername={setUsernameEmail}
            setPassword={setPassword}
            openForgotPassword={openForgotPassword}
            onChangeLanguage={onChangeLanguage}
            onClickSignInWithPassword={onClickSignInWithPassword}
          />;
  };

  const renderContent = () => {
    switch (state.renderContent) {
      case "password":
        return renderPasswordForm();
      case "forgotPassword":
        return renderForgotPassword();
      default:
        return renderPasswordForm();
    }
  };

  return state.isAuthenticating ?
    <Loader title={t("default:actions.authenticating")} /> :
    <LoginFrame open={state.open}>{renderContent()}</LoginFrame>;
};

Password.defaultProps = {
  renderContent: "password"
};

Password.propTypes = {
  renderContent: PropTypes.string,
  history: PropTypes.object.isRequired
};

export default Password;