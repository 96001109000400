import { grayColor } from "assets/jss/material-dashboard-pro-react";

const passwordResetStyle = theme => ({
  title: {
    whitSpace: "nowrap",
    fontSize: theme.lsyPalette.title2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  pwInfo: {
    color: grayColor[2]
  },
  margin: {
    marginTop: theme.spacing(2)
  },
  link: {
    "display": "flex",
    "textAlign": "center",
    "cursor": "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  button: {
    textTransform: "none",
    marginBottom: theme.spacing(2)
  }
});

export default passwordResetStyle;