import { get, put, post } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";

const fetchOrganizationData = async (options) => {
  return await get(`${twsWebUrl}/organizations/${options.id}`);
};

const updateOrganizationWebhooks = async (options) => {
  const data = { ...options.bodyParams };
  return await put(`${twsWebUrl}/organizations/webhooks`, data);
};

const fetchWebhookLogs = async (options = {}) => {
  const { entries, ...otherOptions } = options;

  let additionalArgs = transformToQueryString(sanitizeKeys(otherOptions, ["entries"]));
  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
  }

  return await get(`${twsWebUrl}/organizations/webhooks/logs?page_size=${entries || 3}${additionalArgs}`);
};

const reFetchWebhookLog = async (id) => {
  return await post(`${twsWebUrl}/organizations/webhooks/logs/${id}/resend_event`, { id: id });
};

const fetchPreferences = async () => {
  return await get(`${twsWebUrl}/organizations/preferences`);
};

const updatePreferences = async (options = {}) => {
  return await put(`${twsWebUrl}/organizations/preferences`, options);
};

export const organizationService = {
  fetchOrganizationData,
  updateOrganizationWebhooks,
  fetchWebhookLogs,
  reFetchWebhookLog,
  updatePreferences,
  fetchPreferences
};
