import PropTypes from "prop-types";

import {
  Chip,
  useMediaQuery
} from "@mui/material";

import DateTimeRange from "_components/Date/DateTimeRange";

import styles from "assets/jss/components/Date/chipDateTimeRangeStyle";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

function ChipDateTimeRange(props) {
  const { startDate, endDate, onDelete, setDateRange } = props;
  const isDownSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { classes } = useStyles();

  return <Chip
    data-testid="dateTimeRangeChip"
    className={classes.chip}
    onDelete={onDelete}
    label={
      <DateTimeRange
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        numberOfMonths={isDownSm ? 1 : 2}
        showTime={false}
        showQuickFilter={true}
        label={" "}
        format="YYYY/MM/DD"
        inputProps={{
          variant: "standard",
          disableUnderline: true,
          className: classes.input
        }}
      />
    }
  />;
}

ChipDateTimeRange.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  setDateRange: PropTypes.func,
  onDelete: PropTypes.func
};

export default ChipDateTimeRange;