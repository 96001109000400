import { Children, forwardRef } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { isBlank } from "_helpers";
import { components } from "react-select";

const { ValueContainer, Placeholder } = components;

const LABEL_TOP_POSITION = -25;
const LABEL_TOP_POSITION_MULTI_VALUE = -12;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const getTopPosition = (state) => {
  /*Determines the position of the label when it is moved from the middle
    to the top of the AsyncSelect. We need to change this value when
    AsyncSelect has more than one line.
  */
  if (state.value?.length > 2) {
    return LABEL_TOP_POSITION_MULTI_VALUE;
  } else {
    return LABEL_TOP_POSITION;
  }
};

const getPlaceholderTransitionStyle = (state, theme) => {
  if (state.hasValue || state.selectProps.inputValue) {
    return {
      top: getTopPosition(state.selectProps),
      fontSize: theme.lsyPalette.body1,
      backgroundColor: theme.lsyPalette.secondary.main
    };
  }

  return {
    fontSize: theme.lsyPalette.body3
  };
};

const getTopPadding = (state) => {
  if (state.value?.length > 2) {
    return "10px";
  } else {
    return "0px";
  }
};

const CustomAsyncSelect = forwardRef((props, ref) => {
  const { t } = useTranslation("default");
  const theme = useTheme();
  return (
    <AsyncSelect
      ref={ref}
      autoFocus={props.autoFocus}
      className={props.class}
      isClearable
      backspaceRemovesValue={props.backspaceRemovesValue}
      isMulti={false}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      isOptionDisabled={option => option.disabled}
      components={{
        ValueContainer: CustomValueContainer
      }}
      noOptionsMessage={({ inputValue }) => {
        if (isBlank(inputValue)) {
          return t("instructions.typeSomething");
        } else {
          return t("fallbacks.noOptions");
        }
      }}
      loadingMessage={() => t("actions.loading")}
      theme={(base) => ({
        ...base,
        colors: {
          ...base.colors,
          primary25: theme.lsyPalette.rowHover,
          primary50: theme.lsyPalette.rowHover,
          primary: theme.lsyPalette.primary.mainLight
        }
      })}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          minHeight: "60px"
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          padding: "0 6px",
          paddingTop: state.hasValue || state.selectProps.inputValue ? getTopPadding(state.selectProps) : "0px",
          overflow: "visible"
        }),
        indicatorsContainer: (provided) => ({
          ...provided
        }),
        placeholder: (provided, state) => ({
          ...provided,
          ...getPlaceholderTransitionStyle(state, theme),
          position: "absolute",
          transition: "top 0.1s, font-size 0.1s",
          color: theme.lsyPalette.primary.mainDark,
          paddingLeft: "4px",
          paddingRight: "4px",
          fontWeight: 400
        }),
        singleValue: (provided) => ({
          ...provided,
          color: theme.lsyPalette.text,
          fontSize: theme.lsyPalette.body3,
          fontWeight: 400
        }),
        multiValueRemove: (provided, state) => state.isMulti && state.isDisabled ? { ...provided, display: "none" } : provided
      }}
      menuPortalTarget={document.body}
      {...props}
    />
  );
});

CustomAsyncSelect.defaultProps = {
  backspaceRemovesValue: true,
  autoFocus: true
};

CustomAsyncSelect.propTypes = {
  class: PropTypes.string,
  children: PropTypes.object,
  isFocused: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  selectProps: PropTypes.any,
  autoFocus: PropTypes.bool
};

CustomValueContainer.propTypes = {
  class: PropTypes.string,
  children: PropTypes.array,
  isFocused: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  selectProps: PropTypes.object
};

CustomAsyncSelect.displayName = "CustomAsyncSelect";

export default CustomAsyncSelect;
