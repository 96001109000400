const lockGroupIconStyle = theme => ({
  lockGroupIcon: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1"
  },
  svg: {
    enableBackground: "new 0 0 672 672"
  },
  st0: {
    fill: "none",
    stroke: "#000000",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10
  },
  st1: {
    stroke: "#000000",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10
  }
});

export default lockGroupIconStyle;
