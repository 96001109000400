import { MAX_EMAIL_LENGTH } from "_constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import appRoutes from "_routes";

import ContainedButton from "_components/Button/ContainedButton";
import SelectLanguage from "_components/Select/SelectLanguage";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Auth/passwordFormStyle";

const useStyles = makeStyles()(styles);

const PasswordForm = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const { username, isUsernameEmpty, password, isPasswordEmpty, isLoadingPw,
    setUsername, setPassword, openForgotPassword, onChangeLanguage,
    onClickSignInWithPassword} = props;

  const history = useHistory();
  const handleChangeUsername = event => setUsername(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);

  const goToSignInOptions = () => history.push(appRoutes.public.login);

  const handleKeypress = event => {
    if (event.key === "Enter") {
      onClickSignInWithPassword();
    }
  };

  const renderUsernamePasswordFields = () => {
    return <form name="loginForm">
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <TextField
            variant="outlined"
            fullWidth required
            error={isUsernameEmpty}
            id="login.name"
            label={`${t("auth:login.email")}/${t("auth:login.username")}`}
            inputProps={{maxLength: MAX_EMAIL_LENGTH}}
            name="username"
            autoComplete="username"
            value={username}
            autoFocus={true}
            onChange={handleChangeUsername}
            helperText={isUsernameEmpty ? t("error.requiredField") : null}
            onKeyDown={handleKeypress}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            role="textbox"
            aria-label="password"
            variant="outlined"
            fullWidth required
            error={isPasswordEmpty}
            id="login.password"
            label={t("auth:login.password")}
            type="password"
            autoComplete="current-password"
            name="password"
            value={password}
            onChange={handleChangePassword}
            helperText={isPasswordEmpty ? t("error.requiredField") : null}
            onKeyDown={handleKeypress}
          />
        </Grid>
      </Grid>
    </form>;
  };

  const renderContainedButton = (label, isLoading, buttonProps) => {
    return <Grid item xs={10}>
      <ContainedButton
        fullWidth
        variant="contained"
        disabled={isLoading}
        {...buttonProps}
      >
        {isLoading ? 
          !buttonProps.startIcon ?
            <CircularProgress className={classes.spinner} size={30} /> : label :
          label}
      </ContainedButton>
    </Grid>;
  };

  const renderSignInButtons = () => {
    return <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {renderContainedButton(t("auth:login.button"), isLoadingPw,
        { color: "secondary", onClick: onClickSignInWithPassword })}
    </Grid>;
  };

  const renderBackButton = () => {
    return <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Button 
          variant="text"
          size="small"
          className={classes.button}
          startIcon={<KeyboardBackspaceRoundedIcon/>}
          onClick={goToSignInOptions}
        >
          {t("button.backSignIn")}
        </Button>
      </Grid>
    </Grid>;
  };

  const renderFooter = () => {
    return <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <SelectLanguage showLabel={false} handleSubmit={onChangeLanguage} selectClass={classes.select}/>
          </Grid>
          <Grid item>
            <Button variant="text" disableRipple size="small" className={classes.button} onClick={openForgotPassword}>
              {t("auth:login.forgotPassword")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
    <Grid item>
      {renderUsernamePasswordFields()}
    </Grid>
    <Grid item>
      {renderSignInButtons()}
    </Grid>
    <Grid item>
      {renderBackButton()}
    </Grid>
    <Grid item>
      {renderFooter()}
    </Grid>
  </Grid>;
};

PasswordForm.defaultProps = {
  username: "",
  isUsernameEmpty: false,
  password: "",
  isPasswordEmpty: false,
  isLoadingPW: false
};

PasswordForm.propTypes = {
  username: PropTypes.string,
  isUsernameEmpty: PropTypes.bool,
  password: PropTypes.string,
  isPasswordEmpty: PropTypes.bool,
  isLoadingPw: PropTypes.bool,
  setUsername: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onClickSignInWithPassword: PropTypes.func.isRequired
};

export default PasswordForm;