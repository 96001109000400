import { useEffect, useState, useContext, useCallback, useReducer, useMemo, Fragment  } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import { map, get, capitalize, omitBy, find, isEmpty } from "lodash";
import { Can } from "@casl/react";

import { defineAdminAbilities } from "_abilities/adminAbility";
import { alertActions, modalActions, memberActions } from "_actions";
import { config } from "_configs/server-config";
import { getAllEditableTenantLicenseFeatures } from "_constants/admin/license.constants";
import {
  MIN_NAME_LENGTH,
  MAX_NAME_LENGTH,
  getTenantLicenseName,
  tenantLicenseOptions
} from "_constants/admin/tenant.constants";
import { isBlank, fetchUuidNames } from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { organizationService, tenantService } from "_services/admin";
import { fetchErrors } from "_utils";

// @mui/material components
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip
} from "@mui/material";

// core components
import CustomAlert from "_components/Alert/CustomAlert.js";
import RegularButton from "_components/Button/RegularButton";
import Collapsable from "_components/Collapsabale/Collapsable";
import ErrorBoundary from "_components/ErrorBoundary";
import AssignCryptoForm from "_components/Form/AssignCryptoForm";
import LoadingPlaceHolder from "_components/Loading";
import AutocompleteFetchSelect from "_components/Select/AutocompleteFetchSelect";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";
import { UUIDWrapper } from "_components/Uuid";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HelpIcon from "@mui/icons-material/HelpOutline";

import { AuditIcon } from "assets/teleporte/Icons";

import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData.js";
import { SlideMenuContext } from "_components/AnimatedMenu/SlideMenu.js";
import { ModalContext } from "_contexts/ModalData/ModalData";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/editOrganizationStyle";

const useStyles = makeStyles()(styles);

const MIN_SESSION_LENGTH = 600;

const defaultCheckState = Object.freeze({
  maps: false,
  site_visit_records: false,
  lock_notes: false,
  key_requests: false,
  charts: false,
  rts: false,
  access_codes: false,
  lock_groups: false,
  lock_collections: false,
  device_restriction: false,
  api_tokens: false,
  collect_phone_numbers: false,
  custom_roles: false
});

const doNotShowKeys = ["server_keys_management", "tenant_management"];

export default function EditOrganization({ data, url, refreshData }) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { dirtyFields, errors },
    setValue
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const orgId = data["org_id"];
  const [state, setState] = useReducer(genericReducer,
    {
      orgData: null,
      checkState: defaultCheckState,
      submitting: false,
      editOrg: false,
      licenseData: null
    }
  );

  const setOrgData = (orgData) => setState({ orgData });
  const setEditOrg = (editOrg) => setState({ editOrg });
  const setSubmitting = (submitting) => setState({ submitting });

  const [cryptoData, setCryptoData] = useState([]);
  const [parentOrganization, setParentOrganization] = useState({});
  const [showLicenseDetails, setShowLicenseDetails] = useState(false);

  const dispatch = useDispatch();
  const appDataContext = useContext(AppAdminDataContext);
  const {createAsyncModal} = useContext(ModalContext);

  const uuidResource = appDataContext.getTwsResource("uuids");
  const { findUser, setUuidList } = appDataContext;

  const [newOwnerships, setNewOwnerships] = useState(0);
  const cryptoResource = appDataContext.getTwsResource("cryptos");
  const organizationList = appDataContext.organizationList;
  const menuCtx = useContext(SlideMenuContext);
  const currentMembershipRole = useSelector((state) => {
    return state?.memberships?.currentMembership?.s4_role;
  });
  const userId = useSelector((state) => {
    return state?.auth?.user?.id;
  });
  const ability = defineAdminAbilities(currentMembershipRole);
  const licenseOptions = useMemo(() => {
    return Object.keys(tenantLicenseOptions).map(key => {
      const license = tenantLicenseOptions[key];
      return { name: license.name, value: license.value };
    });
  }, []);

  const allEditableFeatures = useMemo(() => {
    return getAllEditableTenantLicenseFeatures();
  }, []);

  const getParentOrg = useCallback(organization => {
    const parentOrg = organizationList.find(org => org.id === organization.parent_id);
    return parentOrg || { };
  }, [organizationList]);

  const parentOrganizationChanged = (organization) => {
    return parentOrganization.id !== organization.parent_id;
  };

  const getOrgData = useCallback(async () => {
    var result;
    try {
      result = await organizationService.fetchGlobalOrganization(orgId, {
        include: ["server_alias", "licenses"]
      });
      if (result) {
        const enabledResult = {};
        const licenses = result.licenses;
        const options = Object.keys(licenses);
        for (let index of options) {
          if (licenses[index].enabled) {
            const feature = licenses[index].license.feature;
            enabledResult[feature] = true;
          }
        }

        const licenseData = omitBy(get(result, "licenses", []), (key) => {
          return doNotShowKeys.includes(get(key, "license.feature", ""));
        });

        setParentOrganization(getParentOrg(result));

        setState({
          checkState: { ...defaultCheckState, ...enabledResult },
          orgData: result,
          licenseData: licenseData
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }, [orgId, getParentOrg]);

  const handleLicenseChange = async (checked, feature, guarded = false) => {

    if(feature === "custom_roles" && !checked) {
      return await createAsyncModal({
        type: "confirm",
        confirm: t("button.disable"),
        confirmAction: () => handleLicenseChange(true, feature, true),
        modalStyle: classes.warningModal,
        title: t("confirmation.disableCustomRolesTitle"),
        description: t("confirmation.disableCustomRoles"),
        submit: true
      });
    }

    if(feature === "custom_roles" && checked && guarded)
      checked = false;

    try {
      const payload = {
        license_feature: feature,
        enabled: checked
      };

      await tenantService.updateLicense(payload, orgId);
      const newLicenseData = Object.keys(state.licenseData).map((index) => {
        const value = state.licenseData[index];
        if (feature === value.license.feature) {
          const param = checked ? "enabled_at" : "disabled_at";
          const editorId = userId;
          const newValue = value;
          newValue[param] = moment();
          newValue.editor_id = editorId;
          newValue.enabled = checked;
          return newValue;
        } else {
          return value;
        }
      });

      setState({
        checkState: {
          ...state.checkState,
          [feature]: checked
        },
        licenseData: newLicenseData
      });
      dispatch(memberActions.membershipUpdate(orgId, { features: { ...state.checkState, [feature]: checked } }));
    } catch (error) {
      console.warn(error);
    }
  };

  const getCryptoData = useCallback(async () => {
    try {
      let result = await cryptoResource.query({ refresh: true, q: { tenant_id: orgId, sort_by: "created_at" } });
      if (result) {
        setCryptoData(result);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [orgId, cryptoResource]);

  useEffect(() => {
    const { data } = cryptoData;
    let editors = [];

    data?.forEach(occ => {
      editors.push({ editor_id: occ.editor_id });
      occ.crypto_ownerships.forEach(ownership => {
        editors.push({ editor_id: ownership.editor_id });
      });
    });

    async function fetchNames() {
      try {
        const result = await fetchUuidNames(editors, uuidResource, "editor_id");
        if (result) {
          setUuidList(result.data);
        }
      } catch (e) {
        console.warn(e);
        dispatch(alertActions.send("Error fetching uuid names", "error"));
      }
    }

    if (data?.length > 0) {
      fetchNames();
    }

  }, [cryptoData, setUuidList, uuidResource, dispatch]);

  useEffect(() => {
    getOrgData(orgId);
    getCryptoData(orgId);
    return () => {
      setOrgData({});
    };
  }, [orgId, newOwnerships, getOrgData, getCryptoData]);

  const showParentOrgName = () => {
    return parentOrganization.name ? parentOrganization.name : "";
  };

  const renderFormInput = (fieldProps) => {
    return (
      <CustomInput
        id={fieldProps.id}
        labelText={fieldProps.label}
        inputRootCustomClasses={fieldProps.fullWidth ? classes.inputs : null}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          autoFocus: false,
          defaultValue: fieldProps.defaultVal,
          name: fieldProps.id,
          ...register(fieldProps.id, {
            required: fieldProps.required,
            minLength: fieldProps.minLength || MIN_NAME_LENGTH,
            maxLength: fieldProps.maxLength || MAX_NAME_LENGTH
          }),
          autoComplete: "off"
        }}
        error={!!errors[fieldProps.id]}
        errorMsg={fieldProps.errorMap ? fieldProps.errorMap[errors[fieldProps.id]?.type] : null}
      />
    );
  };

  const renderFormInputInt = (props) => {
    return (
      <Fragment>
        {props.tooltip}
        <CustomInput
          id={props.id}
          labelText={props.label}
          inputRootCustomClasses={props.fullWidth ? classes.inputs : null}
          inputProps={{
            autoFocus: false,
            defaultValue: props.defaultVal,
            name: props.id,
            ...register(props.id, {
              required: props.required,
              validate: {
                positive: (value) =>
                  parseInt(value, 10) >= 0 || (value === "" && !props.required),
                minVal: (value) => {
                  if (value === "" && !props.required) {
                    return true;
                  } else if (props.minVal) {
                    return value >= props.minVal;
                  } else {
                    return true;
                  }
                }
              },
              pattern: /^[0-9]*$/
            }),
            autoComplete: "off"
          }}
          formControlProps={{
            fullWidth: true
          }}
          error={!!errors[props.id]}
          errorMsg={errors[props.id] ? "Invalid entry" : null}
        />
      </Fragment>
    );
  };

  const resetForm = () => {
    reset({
      name: state.orgData.name,
      feature_level: !isBlank(state.orgData.feature_level) ? `${state.orgData.feature_level}` : "",
      server_alias_name: state.orgData.server_alias.name,
      session_length: state.orgData.session_length || "",
      work_session_wstl: state.orgData.work_session_wstl,
      work_session_auto_suspend: state.orgData.work_session_auto_suspend,
      custom_setup: state.orgData.custom_setup ? true : false
    });

    if (parentOrganizationChanged(state.orgData)) {
      setParentOrganization(getParentOrg(state.orgData));
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    var postData = {};

    Object.keys(dirtyFields).map(function (key) {
      switch (key) {
        case "name":
          postData.name = data.name;
          break;
        case "feature_level":
          postData.feature_level = parseInt(data.feature_level);
          break;
        case "work_session_auto_suspend":
          postData.work_session_auto_suspend = data.work_session_auto_suspend;
          break;
        case "work_session_wstl":
          postData.work_session_wstl = parseInt(data.work_session_wstl);
          break;
        case "session_length":
          postData.session_length = parseInt(data.session_length);
          break;
        case "server_alias_name":
          postData.server_alias = {
            name: data.server_alias_name
          };
          break;
        case "custom_setup":
          postData.custom_setup = data.custom_setup;
          break;
        default:
      }
    });

    if (parentOrganizationChanged(state.orgData)) {
      postData.parent_id = parentOrganization.id;
    }

    var params = {
      data: postData,
      id: orgId
    };
    //Must be after switch statement to prevent dirtyFields from changing
    setEditOrg(false);

    try {
      let result = await organizationService.updateOrganization(params);
      if (result) {
        setOrgData({
          ...state.orgData,
          ...postData,
          parent_id: parentOrganization.id,
          server_alias: {
            ...state.orgData.server_alias,
            name: dirtyFields.server_alias_name
              ? data.server_alias_name
              : state.orgData.server_alias.name
          },
          updated_at: moment()
        });
        if (postData.name) {
          dispatch(memberActions.membershipUpdate(orgId, { tenant_name: postData.name }));
        }

        await appDataContext.updateTwsResource("organizations");
        dispatch(alertActions.send("Organization Successfully Updated"));
        refreshData();
      }
    } catch (e) {
      console.warn(e);
      const errors = fetchErrors(e);

      if(errors?.tenant?.name === "name_in_use") {
        dispatch(alertActions.send("Organization name is already in use", "error"));
      } else {
        dispatch(alertActions.send("Error occured when updating organization", "error"));
      }
    }
    setSubmitting(false);
  };

  const isFormDirty = () => {
    const is_Dirty = !!Object.keys(dirtyFields).length || parentOrganizationChanged(state.orgData);

    return is_Dirty;
  };

  const showData = () => {
    return (
      <Fragment key={"orgData-config" + state.orgData.id}></Fragment>
    );
  };

  const insertRow = (title, data) => {
    return (
      <tr>
        <td className={classes.rowTitle}>{title}</td>
        <td className={classes.rowData}>{data}</td>
      </tr>
    );
  };

  const showTable = () => {
    const alias_name = get(state.orgData, "server_alias.name", "");
    var server_name = get(state.orgData, "server_alias.server.endpoint", "")
      .replace(/^https*:\/\//, "")
      .replace(/:\d+$/, "");
    let region = server_name.match(/\.\w+/);
    if (region) {
      region = capitalize(region[0].replace(/^\./, ""));
    }
    server_name = server_name.replace(/\.\S+/, "");
    return (
      <GridItem className={classes.fieldBox} xs={12} >
        <table style={{ tableLayout: "fixed", width: "100%" }}>
          <tbody>
            {insertRow("Friendly Name", state.orgData.name)}
            {insertRow("Subdomain (.sera4.com)", alias_name.toLowerCase())}
            {insertRow("Parent Organization (TAP)", showParentOrgName())}
            {insertRow(<Fragment>Session Length<Tooltip
              title={`This value (in seconds) is the longest a user can go without
              logging into the mobile application to extend his current valid login session -
              and will otherwise be required to login again. Default is 90 days (7776000 seconds). Value must be at least ${MIN_SESSION_LENGTH / 60} minutes (${MIN_SESSION_LENGTH} seconds).
              This is applied per organization and the value for an end-user is the LOWEST of all of their organizations.
              This affects mobile, desktop and API sessions. `}
            >
              <HelpIcon fontSize="small" className={classes.helpIcon} />
            </Tooltip>
            </Fragment>, state.orgData.session_length || "")}
            {insertRow(
              "WorkSession Auto-Suspend (Alpha)", state.orgData.work_session_auto_suspend ? "True" : "False")}
            {insertRow(<Fragment>WorkSession Timeout<Tooltip
              title="The value (minutes) before a worksession warning is noted. Any worksession exceeding this length will be marked in RED.
            There is no limit by default."
            >
              <HelpIcon fontSize="small" className={classes.helpIcon} />
            </Tooltip>
            </Fragment>, state.orgData.work_session_wstl)}
            {insertRow("License", getTenantLicenseName(state.orgData.feature_level) || "")}
            {insertRow("Deployment/Container", server_name)}
            {region ? insertRow("Region/Namespace", region) : null}
            {insertRow("Custom Setup Configuration", state.orgData.custom_setup ? "True" : "False")}
          </tbody>
        </table>
        {state.orgData.custom_setup ? (
          <GridItem style={{ marginTop: "10px" }}>
            <CustomAlert type="CustomConfigurationOrg"></CustomAlert>
          </GridItem>
        ) : null}
      </GridItem>
    );
  };

  const showForm = () => {
    return (
      <Grid container alignItems="center" >
        <GridItem className={classes.itemBox} xs={12}>
          <AutocompleteFetchSelect
            id="autocomplete-organization-id"
            title="Parent Organization (TAP)"
            selectedValue={isEmpty(parentOrganization) ? null : parentOrganization}
            resourceName="organizationList"
            onChange={(event, value) => {
              setParentOrganization(value);
            }}
            outlined={true}
            autoHighlight={true}
            autoSelect={true}
          />
        </GridItem>
        <GridItem xs={12}>
          <FormControl variant="outlined" className={classes.itemGrid}>
            <InputLabel id="license">
              License
            </InputLabel>
            <Controller
              defaultValue=""
              render={({ field }) =>
                <Select
                  {...field}
                  className={classes.itemBoxSelect}
                  label="License"
                  aria-label="License">
                  {licenseOptions.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={option.value}
                        onKeyDown={(event) => {
                          if (event.key === "Tab") {
                            setValue(option.field, event.currentTarget.dataset.value);
                          }
                        }}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              control={control}
              name="feature_level"
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} className={classes.marginBottom}>
          {renderFormInput({
            id: "name",
            label: "Friendly Name",
            defaultVal: state.orgData.name,
            required: true,
            minLength: MIN_NAME_LENGTH,
            maxLength: MAX_NAME_LENGTH,
            fullWidth: true,
            errorMap: {
              required: "This field is required",
              minLength: `Organization name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters`,
              maxLength: `Organization name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters`
            }
          })}
        </GridItem>
        <GridItem xs={6} className={classes.marginBottom}>
          {renderFormInputInt({
            id: "session_length",
            label: "Session Length",
            defaultVal: state.orgData.session_length,
            required: false,
            fullWidth: true,
            minVal: MIN_SESSION_LENGTH
          })}
        </GridItem>
        <GridItem xs={6} className={classes.marginBottom}>
          {renderFormInputInt({
            id: "work_session_wstl",
            label: "WorkSession Timeout",
            defaultVal: state.orgData.work_session_wstl,
            required: true,
            half: true
          })}
        </GridItem>
        <GridItem >
          <FormControlLabel
            label="WorkSession Auto-Suspend (Alpha)"
            labelPlacement="start"
            className={classes.switchLabel}
            control={
              <Controller
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                )}
                defaultValue={state.orgData.work_session_auto_suspend}
                control={control}
                name="work_session_auto_suspend"
              />
            }
          />
        </GridItem>
        <GridItem >
          <FormControlLabel
            label="Custom Setup Configuration"
            labelPlacement="start"
            className={classes.switchLabel}
            control={
              <Controller
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                )}
                defaultValue={state.orgData.custom_setup ? true : false}
                control={control}
                name="custom_setup"
              />
            }
          />
        </GridItem>
      </Grid>
    );
  };

  const formatLicense = (license, currentLicenses) => {
    const name = allEditableFeatures[license];
    const hideDetails = showLicenseDetails ? false : true;
    const licenseDetails = find(currentLicenses, (obj) => {
      if (obj.license.feature === license)
        return true;
    }) || { enabled: false };

    const licenseMeta = licenseDetails.enabled_at && licenseDetails.enabled ? (
      <small>
        <span className={classes.licenseMeta}>
          Enabled On: {moment(licenseDetails.enabled_at).format("L")}
        </span>
        <br />
        <span className={classes.licenseMeta}>
          Enabled By: {licenseDetails.editor_id}
        </span>
      </small>
    ) : (licenseDetails.disabled_at && !licenseDetails.enabled) ? <small>
      <span className={classes.licenseMeta}>
        Disabled On: {moment(licenseDetails.disabled_at).format("L")}
      </span>
      <br />
      <span className={classes.licenseMeta}>
        Disabled By: {licenseDetails.editor_id}
      </span>
    </small> : null;

    return (
      <GridItem xs={12} key={"license-" + license}>
        <GridContainer>
          <GridItem xs={10}>
            {name}
            <br />
            {hideDetails ? null : licenseMeta}
          </GridItem>
          <GridItem xs={2}>
            <ToggleSwitch
              checked={state.checkState[license]}
              disabled={doNotShowKeys.includes(license)}
              handleChange={(checked) => handleLicenseChange(checked, license)}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  };

  const showNotification = async (assignmentForm) => {
    const data = {
      tenant_id: orgId,
      id: assignmentForm.id
    };
    try {
      let result = await cryptoResource.assign(data);
      if (result) {
        setNewOwnerships(newOwnerships + 1);
        dispatch(alertActions.send("OCC Assigned.", "success"));
      }
    } catch (error) {
      console.warn(error.response);
      if (error.response && error.response.data) {
        const { status } = error.response;
        const { crypto_location, crypto_ownership } = error.response.data.error.errors;
        if (status === 409 && crypto_ownership) {
          dispatch(alertActions.send("OCC already assigned to this organization", "error"));
        } else if (crypto_location) {
          dispatch(alertActions.send("OCC and organization are not on the same server", "error"));
        }
      } else {
        dispatch(alertActions.send("Failed to assign OCC", "error"));
      }
    }
  };

  const tableOfCryptos = (c) => {
    let occsFormatted = [];
    c.forEach(occ => {
      occ.crypto_ownerships.forEach(ownership => {
        if (ownership.tenant_id === orgId) {
          occsFormatted.push({
            name: occ.name,
            assignedOn: ownership.created_at,
            assignedBy: { id: ownership.editor_id, name: findUser(ownership.editor_id) }
          });
        }
      });
    });

    return (
      <div>
        <br />
        <table style={{ tableLayout: "fixed", width: "100%" }}>
          <thead>
            <tr className={classes.cryptoRowHeader}>
              <th>OCC</th>
              <th>Assigned On</th>
              <th>Assigned By</th>
            </tr>
          </thead>
          <tbody>
            {
              occsFormatted.map((occ, key) => {
                return <tr className={classes.cryptoRowData} key={key}>
                  <td>{occ.name}</td>
                  <td>{moment(occ.assignedOn).format("L")}</td>
                  <td>{<UUIDWrapper
                    uuid={occ.assignedBy.id}
                    name={occ.assignedBy.name}
                    url={url}
                  />}</td>
                </tr>;
              })
            }
          </tbody>
        </table>
        <br />
      </div>
    );
  };

  const showLicenseData = () => {
    const showDetailsLink = (
      <RegularButton onClick={() => setShowLicenseDetails(!showLicenseDetails)}>
        {showLicenseDetails ? "Hide" : "Show"} Details
      </RegularButton>
    );
    return (
      <Collapsable title="Licenses" collapsed={true} padBottom={true}>
        <GridContainer className={classes.licenseBody}>
          {map(allEditableFeatures, (value, key) => {
            return formatLicense(key, state.licenseData);
          })}
        </GridContainer>
        <GridContainer style={{ justifyContent: "flex-end" }}>
          <GridItem>{showDetailsLink}</GridItem>
        </GridContainer>
      </Collapsable>
    );
  };

  const showAssignForm = () => {
    // Filter out already assigned orgs
    const { data } = cryptoData;
    const existingIds = data?.map(co => co.id);
    const cryptoList = cryptoResource.fetchAllFromCache().data.filter(v => !existingIds?.includes(v.id));
    const modalProps = {
      title: `Assign new OCC to ${state.orgData.name}`,
      FormComponent: <AssignCryptoForm />,
      customButtonText: "Assign",
      customButtonHandler: showNotification,
      fields: ["id"],
      extra: { dataSource: cryptoList, key: "id", prompt: "Enter OCC" }
    };
    dispatch(modalActions.showModal({ modalProps, modalType: "custom-form" }));
  };

  const showCryptos = () => {
    const { data } = cryptoData;
    return (
      <Collapsable title="OCCs" collapsed={true}>
        <GridContainer>
          <div>{
            data?.length > 0 && tableOfCryptos(data)
            || <p><br /><strong>None Assigned</strong></p>
          }
          </div>
        </GridContainer>
        <GridContainer direction="row" justifyContent="flex-end">
          <GridItem>
            <RegularButton onClick={() => showAssignForm()}>
              {"Assign New OCC"}
            </RegularButton>
          </GridItem>
        </GridContainer>
      </Collapsable>
    );
  };

  const showFooterInfo = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <hr />
          <GridContainer>
            <GridItem xs={12}>{state.orgData.name}</GridItem>
            <GridItem xs={12} sm={6}>
              <b>Created</b>:&nbsp;
              <Tooltip
                title={moment(state.orgData.created_at).format()}
                aria-label="Created At"
              >
                <span>{moment(state.orgData.created_at).format("L")}</span>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <b>Updated</b>:&nbsp;
              <Tooltip
                title={moment(state.orgData.updated_at).format()}
                aria-label="Updated At"
              >
                <span>{moment(state.orgData.updated_at).format("L")}</span>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <b>ID</b>:&nbsp;
              <Tooltip
                title="Organization identity"
                aria-label="Updated At"
              >
                <span>{state.orgData.id}</span>
              </Tooltip>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer
                direction="row"
                justifyContent="flex-end"
                style={{ marginTop: "20px" }}
              ></GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const showOrgBody = () => {
    return (
      <Fragment>
        {showData()}
        <GridContainer direction="row" justifyContent="flex-end">
          <GridItem xs={12}>
            <hr />
            <GridContainer>
              {
                <form onSubmit={handleSubmit(onSubmit)}>
                  {state.editOrg ? showForm() : showTable()}
                  {state.editOrg ? (
                    <GridItem xs={12}>
                      <GridContainer justifyContent="flex-end">
                        <GridItem>
                          <RegularButton
                            color="primary"
                            onClick={() => {
                              setEditOrg(!state.editOrg);
                              resetForm();
                            }}
                          >
                            Cancel
                          </RegularButton>
                        </GridItem>
                        <GridItem>
                          <RegularButton
                            disabled={!isFormDirty() || state.submitting || !isEmpty(errors)}
                            color="primary"
                            type="submit"
                          >
                            {state.submitting ? (
                              <LoadingPlaceHolder height={12} />
                            ) : (
                              "Save"
                            )}
                          </RegularButton>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  ) : null}
                </form>
              }
            </GridContainer>
            {showLicenseData()}
            {showCryptos()}
          </GridItem>
        </GridContainer>
        {showFooterInfo()}
      </Fragment>
    );
  };

  const getHistoryURL = () => {
    const options = {
      table: "Tenant",
      tenant: orgId
    };

    return `${config.adminUrl}/history?${queryString.stringify(options)}`;
  };

  const getLocksQualityReportUrl = () => {
    return {
      pathname: `${config.adminUrl}/reports`,
      state: { locksQuality: { organizationId: orgId } }
    };
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.card}>
          <CardHeader color="primary" text>
            <CardText color="primary">
              <h4 className={classes.cardTitle}>
                <b>Organization</b> {state.orgData ? `${state.orgData.name}` : `${orgId}`}
              </h4>
            </CardText>
            <Can I="modify" on="orgComponent" ability={ability}>
              {!state.editOrg ?
                <IconButton
                  className={classes.editOrgButton}
                  onClick={() => {
                    setEditOrg(!state.editOrg);
                    resetForm();
                  }}
                  size="large">
                  <EditIcon fontSize="small" />
                </IconButton> : null}
            </Can>
          </CardHeader>
          <CardBody>
            {!state.editOrg ?
              <NavLink to={`${config.adminUrl}/explore_memberships?tenant_id=${orgId}`} className={classes.link}>
                <RegularButton
                  onClick={() => menuCtx.closeMenu()}
                  startIcon={<PeopleAltIcon />}
                >
                  View Memberships
                </RegularButton>
              </NavLink> : null}
            <NavLink to={getHistoryURL()} className={classes.link} data-testid="linkViewHistory">
              <RegularButton
                data-testid="viewHistory"
                onClick={() => menuCtx.closeMenu()}
                startIcon={<AuditIcon />}
              >
                View History
              </RegularButton>
            </NavLink>
            <NavLink to={getLocksQualityReportUrl()} className={classes.link} data-testid="linkLocksQualityReport">
              <RegularButton
                data-testid="locksQualityReport"
                onClick={() => menuCtx.closeMenu()}
                startIcon={<AssignmentIcon />}
              >
                View Lock Quality Report
              </RegularButton>
            </NavLink>
            <ErrorBoundary>
              {state.orgData ? (
                showOrgBody()
              ) : (
                <LoadingPlaceHolder title="Loading Organization Data" />
              )}
            </ErrorBoundary>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditOrganization.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.object.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  defaultVal: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  tooltip: PropTypes.element,
  minVal: PropTypes.number,
  url: PropTypes.string,
  refreshData: PropTypes.func
};

EditOrganization.defaultProps = {
  readOnly: false
};
