const systemSettingsStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 0px 5%",
    [theme.breakpoints.down("500")]: {
      padding: 0
    }
    
  },
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    borderRadius: "3px",
    padding: "20px 35px",
    [theme.breakpoints.down("325")]: {
      padding: "10px 15px"
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "-25px",
    [theme.breakpoints.down("505")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "start",
      gap: "15px"
    }
  },
  settingsItemWrap: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    gap: "50px",
    [theme.breakpoints.down("md")]: {
      gap: "25px"
    }
  },
  itemDetailsBox: {
    height: "auto", 
    backgroundColor: theme.palette.secondary.modernLightGray,
    borderRadius: "5px",
    padding: "20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "15px"
  },
  itemDetailsBoxSkeleton: {
    height: "100%",
    width: "50%"
  },
  itemDescription: {
    color: theme.lsyPalette.subText,
    marginTop: "7px"
  },
  itemDetailsHeader: {
    "textTransform": "uppercase",
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": theme.lsyPalette.mediumWeight,
    "color": theme.lsyPalette.subText,
    "marginBottom": "10px",

    "&.Mui-focused":{
      color: theme.lsyPalette.subText
    }
  },
  itemInputWrap: {
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    width: "100%",
    margin: "50px 0 50px 0"
  },
  heading: {
    color: theme.lsyPalette.subText
  },
  actionBtnsWrap: {
    opacity: "1",  
    transition: "opacity 600ms ease",
    position: "sticky",
    bottom: "0",
    backgroundColor: theme.lsyPalette.secondary.main,
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    padding: "15px",
    [theme.breakpoints.down("500")]: {
      justifyContent: "space-between"
    },
    [theme.breakpoints.down("325")]: {
      flexDirection: "column",
      gap: "10px"
    }
  },
  actionBtn: {
    width: "150px",
    textTransform: "capitalize",
    [theme.breakpoints.down("500")]: {
      width: "50%"
    },
    [theme.breakpoints.down("325")]: {
      width: "100%"
    }
  },
  restoreBtn: {
    width: "auto",
    border: "0",
    borderRadius: "0",
    marginTop: "15px",
    padding: "7px 15px",
    color: theme.lsyPalette.subText,
    fontWeight: theme.lsyPalette.mediumWeight,
    fontSize: theme.lsyPalette.subtitle3,
    backgroundColor: theme.palette.secondary.modernLightGray
  },
  hidden: {
    transition: "opacity 300ms ease",
    opacity: "0"
  },
  errorMsgWrap: {
    marginTop: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign:"center",
    flexDirection: "column"
  },
  errorIcon: {
    width: "50px",
    height: "50px"
  },
  panelSpacing: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  toggleSwitch: {
    marginTop: theme.spacing(0.625)
  }
});
  
export default systemSettingsStyle;