import { useCallback, useReducer, useRef } from "react";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";

import { genericReducer } from "_reducers/general.reducer";
import { lockService } from "_services/lockstasy";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";
import { isNullOrUndefined } from "_helpers";

const SelectLock = (props) => {
  const { autoFocus, className, lock, lockFilter, setLock, optionDisabled,
    optionsLimit, hideDisabledOptions, noOptionsMessage, placeholder,
    menuPlacement } = props;

  const { t } = useTranslation("default");
  const [state, setState] = useReducer(genericReducer,
    {
      defaultOptions: [],
      inputValue: ""
    });
  const setInputValue = value => setState({ inputValue: value });
  const currentPage = useRef(1);
  const totalPages = useRef(1);

  const concatDefaultOptions = useCallback((options, concat) => {
    if(concat) {
      setState({
        defaultOptions: [
          ...state.defaultOptions, 
          ...options.filter(op => !state.defaultOptions.some(stateOp => stateOp.id === op.id))]
      });
    } else {
      setState({ defaultOptions: [...options] });
    }
  }, [state.defaultOptions]);

  const formatLock = useCallback(locks => {
    return locks.map(lock => lock ? 
      {
        id: lock.id,
        name: lock.full_identifier,
        disabled: optionDisabled(lock)
      } :
      null
    );
  }, [optionDisabled]);

  const fetchLocks = useCallback(async (inputValue, loadOnScroll) => {
    if (currentPage.current > totalPages.current) {
      return;
    }

    try {
      const payload = { search: inputValue, page_size: optionsLimit, page: currentPage.current, ...lockFilter };
      const result = await lockService.fetchLocks(payload);
      const formattedResult = formatLock(result.data);

      const options = hideDisabledOptions ? formattedResult.filter(lock => !lock.disabled) : formattedResult;
      const concat = typeof loadOnScroll == "boolean" && loadOnScroll;
      concatDefaultOptions(options, concat);
      totalPages.current = result.meta.pagination.total_pages;
      return options;
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e?.response);
    }
  }, [hideDisabledOptions, lockFilter, optionsLimit, concatDefaultOptions, formatLock]);

  useCustomCompareEffect(() => {
    if (isEmpty(lock)) {
      fetchLocks(state.inputValue);
    }
  }, [lock], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleOnChange = useCallback((selectedOption) => {
    const option = isNullOrUndefined(selectedOption) ? {} : selectedOption;
    if (isEmpty(option)) {
      setInputValue("");
      fetchLocks("");
    } else {
      setState({ defaultOptions: [] });
    }

    currentPage.current = 1;
    setLock(option);
  }, [setLock, fetchLocks]);

  const fetchMoreData = useCallback(() => {
    currentPage.current += 1;
    fetchLocks(state.inputValue, true);
  }, [fetchLocks, state.inputValue]);

  const handleInputChange = useCallback((query, { action }) => {
    if (action === "set-value" || action === "input-blur") {
      return;
    }

    setInputValue(query);
    currentPage.current = 1;

    if (action === "menu-close") {
      fetchLocks("");
    }
  }, [fetchLocks]);

  const renderSelector = useCallback(() => {
    return <CustomAsyncSelect
      class={className}
      autoFocus={autoFocus}
      placeholder={placeholder || t("instructions.selectLock")}
      value={isEmpty(lock) ? null : lock}
      onChange={handleOnChange}
      loadOptions={fetchLocks}
      onInputChange={handleInputChange}
      defaultOptions={state.defaultOptions}
      hideSelectedOptions={true}
      onMenuScrollToBottom={fetchMoreData}
      captureMenuScroll={true}
      menuPlacement={menuPlacement}
      {...noOptionsMessage && { noOptionsMessage }}
    />;
  }, [autoFocus, className, state.defaultOptions, fetchLocks, fetchMoreData, handleOnChange, handleInputChange, lock, menuPlacement, noOptionsMessage, placeholder, t]);

  return renderSelector();
};

SelectLock.defaultProps = {
  autoFocus: false,
  optionDisabled: () => false,
  optionsLimit: 5,
  lockFilter: {},
  hideDisabledOptions: false,
  menuPlacement: "bottom"
};

SelectLock.propTypes = {
  className: PropTypes.string,
  lock: PropTypes.object.isRequired,
  setLock: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  optionDisabled: PropTypes.func,
  optionsLimit: PropTypes.number,
  lockFilter: PropTypes.object,
  hideDisabledOptions: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string
};

export default SelectLock;