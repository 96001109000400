const selectRoleStyle = theme => ({
  select: {
    "height": theme.select.height,
    "minWidth": "350px",
    "& .MuiOutlinedInput-input": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  }
});
  
export default selectRoleStyle;