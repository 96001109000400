import { isEmpty } from "lodash";
import { Android, Apple, DevicesOther } from "@mui/icons-material";

const PLATFORM_REGEX = {
  iOS: /iOS|iPhone|iPad/i,
  Android: /Android/i
};

const getDeviceIconType = (platformName, classes) => {
  if (!platformName) {
    return null;
  }

  const name = platformName.toLowerCase();

  if (PLATFORM_REGEX.iOS.test(name))
    return <Apple className={classes.deviceSOIcon}/>;
  else if (PLATFORM_REGEX.Android.test(name))
    return <Android className={classes.deviceSOIcon}/>;
  else
    return <DevicesOther className={classes.deviceSOIcon}/>;
};

const formatDeviceData = data => {
  if (isEmpty(data)) {
    return [];
  }

  return data.map(d => {
    const platform = d.platform || {};
    const platformName = platform.name || d.platform_name || "";
    const platformModel = platform.model || d.platform_model || "";
    const teleporteVersion = d.teleporte_version || d.version || "";

    const isIOS = PLATFORM_REGEX.iOS.test(`${platformName} ${platformModel}`);
    // TWS-2904 - formatting bad version from Teleporte iOS
    const newTeleporteVersion = isIOS && teleporteVersion === "13" ? "6.7.1.13" : teleporteVersion;

    return {
      ...d,
      ...d.teleporte_version ?
        {teleporte_version: newTeleporteVersion} :
        {version: newTeleporteVersion}
    };
  });
};

export const userDeviceHelper = {
  getDeviceIconType,
  formatDeviceData
};