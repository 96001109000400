const mfaCodeModalStyle = (theme) => ({
  codeContainer: {
    [theme.breakpoints.up("500")]: {
      minWidth: "25em"
    },
    [theme.breakpoints.down("500")]: {
      minWidth: "18em"
    },
    [theme.breakpoints.down("400")]: {
      minWidth: "10em"
    },
    [theme.breakpoints.down("300")]: {
      minWidth: "auto"
    },
    border: "1px solid",
    borderColor: theme.lsyPalette.primary.light,
    textAlign: "center"
  },
  codeTitle: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
    border: "1px solid",
    borderColor: theme.lsyPalette.primary.light,
    backgroundColor: theme.lsyPalette.primary.light,
    textAlign: "center"
  },
  code: {
    marginLeft: "5px",
    marginRight: "5px",
    ...theme.typography.codeFont,
    color: theme.lsyPalette.primary.mainDark
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  content: {
    overflowY: "hidden"
  },
  alert: {
    alignItems: "center",
    maxWidth: "25em"
  },
  button: {
    textTransform: "capitalize"
  }
});

export default mfaCodeModalStyle;
