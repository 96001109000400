/* eslint-disable */
import { useEffect, useState, useContext, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";
import { map } from "lodash";
import { makeStyles } from "tss-react/mui";
import { Tooltip } from "@mui/material";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import RegularButton from "_components/Button/RegularButton";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import LoadingPlaceHolder from "_components/Loading";
import ErrorBoundary from "_components/ErrorBoundary";
import { UUIDWrapper } from "_components/Uuid";
import { alertActions, modalActions } from "_actions";
import Collapsable from "_components/Collapsabale/Collapsable";
import baseStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData";
import AssignCryptoForm from "_components/Form/AssignCryptoForm";

const styles = theme => ({
  ...baseStyles,
  labelHorizontal: {
    ...baseStyles.labelHorizontal,
    textTransform: "capitalize"
  },
  optionsLabel: {
    paddingTop: "0px"
  },
  rowTitle: {
    fontWeight: "bold"
  },
  rowData: {
    textAlign: "right"
  },
  licenseMeta: {
    marginLeft: "5%"
  },
  collapsedRowHeader : {
    backgroundColor: "#dddddd"
  },
  collapsedRowData: {
    "border": "1px solid #dddddd",
    "textAligh": "left",
    "padding": "8px",
    "&:nth-of-type(even)" : {
      backgroundColor: "#dddddd"
    }
  },
  card: {
    marginTop: "30px"
  }
});

const useStyles = makeStyles()(styles);

export default function ShowCrypto(props) {
  const { data } = props;
  const appDataContext = useContext(AppAdminDataContext);
  const { findUser } = useContext(AppAdminDataContext);
  const cryptoResource = appDataContext.getTwsResource("cryptos");
  const organizationResource = appDataContext.getTwsResource("organizations");
  const serverResource = appDataContext.getTwsResource("servers");
  const { classes } = useStyles();
  const { crypto_id } = data;
  const [cryptoData, setCryptoData] = useState(null);
  const [newOnwerships, setNewOwnerships] = useState(0);
  const dispatch = useDispatch();
  const getCryptoData = useCallback(async(id) => {
    try {
      let result = await cryptoResource.get({q:{id:crypto_id}, refresh: true});
      if (result) {
        setCryptoData(result);
      }
    } catch(e) {
      console.warn(e);
    }
  });

  useEffect(() => {
    getCryptoData(crypto_id);
    return () => {
      setCryptoData({});
    };

  }, [crypto_id, newOnwerships]);

  const showAssignForm = () => {
    // Filter out already assigned orgs
    const { crypto_ownerships } = cryptoData;
    const existingIds = crypto_ownerships?.map(co => co.tenant_id);
    const orgList = Object.values(appDataContext.organizationList).filter(v => !existingIds?.includes(v.id));
    const modalProps = {
      title: `Assign ${cryptoData.name}`,
      FormComponent: <AssignCryptoForm />,
      customButtonText: "Assign",
      customButtonHandler: showNotification,
      fields: ["tenant_id"],
      extra: {dataSource: orgList, key: "tenant_id", prompt: "Enter Organization"}
    };
    dispatch(modalActions.showModal({ modalProps, modalType: "custom-form" }));
  };

  const insertRow = (title, data) => {
    return (
      <tr>
        <td className={classes.rowTitle}>{ title }</td>
        <td className={classes.rowData}>{ data }</td>
      </tr>
    );
  };
  const showCryptoInfo = () => {
    return (
      <Fragment key={"cryptoData-config" + cryptoData.id}>
        <GridItem xs={12}>
          <table style={{tableLayout: "fixed", width: "100%"}}>
            <tbody>
              { insertRow("Crypto", cryptoData.name) }
              { insertRow("Created On", moment(cryptoData.created_at).format("L"))}
              {
                insertRow("Created By",
                  <UUIDWrapper
                    uuid={cryptoData.editor_id}
                    name={findUser(cryptoData.editor_id)}
                    url={props.url}
                  />)
              }
            </tbody>
          </table>
        </GridItem>
      </Fragment>
    );
  };

  const tableOfAssignments = (ownerships) => {
    return(
      <div>
        <br/>
        <table style={{tableLayout: "fixed", width: "100%"}}>
          <thead>
            <tr className={classes.collapsedRowHeader}>
              <th>Organization</th>
              <th>Assigned On</th>
              <th>Assigned By</th>
            </tr>
          </thead>
          <tbody>
            {
              map(ownerships, (value, key) => {
                const t = organizationResource.fetchAllFromCache().data.find(c => c.id === value.tenant_id) || {};
                return (
                  <tr className={classes.collapsedRowData} key={key}>
                    <td>{t?.name }</td>
                    <td>{moment(value.created_at).format("L")}</td>
                    <td>
                      <UUIDWrapper
                        uuid={value.editor_id}
                        name={findUser(value.editor_id)}
                        url={props.url}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <br/>
      </div>
    );
  };

  const tableOfLocations = (ownerships) => {
    return(
      <div>
        <br/>
        <table style={{tableLayout: "fixed", width: "100%"}}>
          <thead>
            <tr className={classes.collapsedRowHeader}>
              <th>Server</th>
              <th>Placed On</th>
              <th>Placed By</th>
            </tr>
          </thead>
          <tbody>
            {
              map(ownerships, (value, key) => {
                const s = serverResource.fetchAllFromCache().data.find(c => c.id === value.server_id) || {};
                return (
                  <tr className={classes.collapsedRowData} key={key}>
                    <td>{s?.name || ""}</td>
                    <td>{moment(value.created_at).format("L")}</td>
                    <td>{value.editor_id}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <br/>
      </div>
    );
  };

  const showAssignments = () => {
    const { crypto_ownerships } = cryptoData;
    return (
      <Collapsable title="Organizations">
        <GridContainer>
          <div>{
            crypto_ownerships?.length > 0 && tableOfAssignments(crypto_ownerships)
            || <p><br/><strong>Currently Unassigned</strong></p>
          }
          </div>
        </GridContainer>
        <GridContainer direction="row" justifyContent="flex-end">
          <GridItem>
            <RegularButton onClick={() => showAssignForm()}>
              { "Assign New Org" }
            </RegularButton>
          </GridItem>
        </GridContainer>
      </Collapsable>
    );
  };

  const showLocations = () => {
    const { crypto_locations } = cryptoData;
    return (
      <Collapsable title="Servers" collapsed={true}>
        <GridContainer>
          <div>{
            tableOfLocations(crypto_locations)
          }
          </div>
        </GridContainer>
      </Collapsable>
    );
  };

  const showFooterInfo = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <hr/>
          <GridContainer>
            <GridItem xs={12}>
              { cryptoData.name }
            </GridItem>
            <GridItem xs={12} sm={6}>
              <b>Created</b>: <Tooltip title={moment(cryptoData.created_at).format()} aria-label="Created At"><span>{ moment(cryptoData.created_at).format("L") }</span></Tooltip>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <b>Updated</b>: <Tooltip title={moment(cryptoData.updated_at).format()} aria-label="Updated At"><span>{ moment(cryptoData.updated_at).format("L") }</span></Tooltip>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const showBody = () => {
    return (
      <Fragment>
        <GridContainer direction="row" justifyContent="flex-end">
          <GridItem xs={12}>
            <hr/>
            <GridContainer>
              { showCryptoInfo() }
            </GridContainer>
            { showAssignments() }
            { showLocations() }
          </GridItem>
        </GridContainer>
        { showFooterInfo() }
      </Fragment>
    );
  };

  const showNotification = async (assignmentForm) => {
    const data = {
      id: props.data.crypto_id,
      tenant_id: assignmentForm.tenant_id
    };

    try {
      let result = await cryptoResource.assign(data);
      if (result) {
        setNewOwnerships(newOnwerships + 1);
        dispatch(alertActions.send("Crypto Assigned.", "success"));
      }
    } catch (error) {
      console.warn(error.response);
      if (error.response && error.response.data) {
        const { crypto_location } = error.response.data.error.errors;
        if (crypto_location) {
          dispatch(alertActions.send("Crypto and organization are not on the same server", "error"));
        }
      } else {
        dispatch(alertActions.send("Failed to assign crypto", "error"));
      }
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} >
        <Card className={classes.card}>
          <CardHeader color="primary" text>
            <CardText color="primary">
              <h4 className={classes.cardTitle}><b>Crypto</b> {cryptoData ? cryptoData.name : crypto_id} </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <ErrorBoundary>
              { cryptoData ? showBody() : <LoadingPlaceHolder title="Loading Crypto Data"/> }
            </ErrorBoundary>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


ShowCrypto.propTypes = {
  data: PropTypes.object.isRequired,
  url: PropTypes.string
};
