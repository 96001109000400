/* This file is used for s4 admin purposes */
/* This will fetch directly from IS and not from a proxied gateway */
import gwClient from "_helpers/axios/gw.client";
import { get, del } from "_helpers/axios/gw.client";
import { sanitizeKeys, transformToQueryString } from "_helpers/utility";

import {
  handleResponse,
  identityUrl
} from "_services/helper";

const fetchMembership = (uuid) => {
  const requestOptions = {
    method: "GET",
    url: `${identityUrl}/memberships/${uuid}`

  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(memberships => {
      return memberships;
    });
};

const fetchMembershipByAccountId = (uuid) => {
  const requestOptions = {
    method: "GET",
    url: `${identityUrl}/accounts/${uuid}/memberships`
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(memberships => {
      return memberships;
    });
};

const fetchMemberships = async (options = { }) => {
  var entries = options["entries"] || 300;

  var additionalArgs = transformToQueryString(sanitizeKeys(options, ["entries"]));
  if (additionalArgs.length > 0) {
    additionalArgs = "&".concat(additionalArgs);
  }

  return get(`${identityUrl}/memberships?page_size=${entries}${additionalArgs}`);
};

const deleteMembership = (membershipId) => {
  return del(`${identityUrl}/memberships/${membershipId}`);
};

const deleteAllMemberships = (accountId) => {
  return del(`${identityUrl}/accounts/${accountId}/memberships`);
};

const clearMembershipCache = async (accountId) => {
  return del(`/memberships/accounts/${accountId}`);
};

export const memberService = {
  fetchMembership,
  fetchMembershipByAccountId,
  fetchMemberships,
  clearMembershipCache,
  deleteMembership,
  deleteAllMemberships
};