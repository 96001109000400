const workSessionWidgetStyle = theme => ({
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "500px"
  },
  topBar: {
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center"
  },
  title: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  table: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    tableLayout: "fixed"
  },
  tableTitle: {
    color: theme.lsyPalette.primary.mainLight,
    textDecoration: "underline",
    textUnderlineOffset: "2px",
    textDecorationColor: theme.lsyPalette.primary.mainLight
  },
  tableTitleComment: {
    color: theme.lsyPalette.primary.mainLight,
    textDecoration: "underline",
    textUnderlineOffset: "2px",
    textDecorationColor: theme.lsyPalette.primary.mainLight,
    width: "22%"
  },
  divider: {
    backgroundColor: theme.lsyPalette.primary.mainLight
  },
  commentModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    minWidth: "200px",
    maxWidth: "600px"
  },
  sessionCard: {
    paddingTop: "5px",
    paddingBottom: "5px",
    marginTop: "1px"
  },
  comment: {
    marginTop: "0px",
    marginBottom: "8px",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  startTime: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  duration: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  date: {
    color: theme.lsyPalette.primary.mainLight
  },
  fallback: {
    minHeight: "280px",
    flexGrow: 1
  },
  fallbackText: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  dotIcon: {
    color: theme.lsyPalette.stdRed,
    display: "inline",
    transform: "scale(0.5)"
  },
  commentIcon: {
    color: theme.lsyPalette.primary.main,
    transform: "scale(0.8)"
  },
  commentButton: {
    height: "28px",
    padding: "0px"
  },
  cell: {
    display: "flex",
    alignItems: "center"
  },
  skeleton: {
    width: "100%"
  },
  tableContainer: {
    minHeight: "310px"
  },
  commentCell: {
    width: "22%"
  }
});

export default workSessionWidgetStyle;
