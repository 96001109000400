// TWS-3144 - Maps Widget functionality costing and use
import { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";

import {
  generateGoolgeMapsEmbedApiUrl
} from "_helpers";

import { Grid, Typography } from "@mui/material";

import Info from "_components/Alert/Info";
import Placeholder from "_components/Helper/Placeholder";

import LocationOnIcon from "@mui/icons-material/LocationOn";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/maps/embeddedMapStyle";

const useStyles = makeStyles()(styles);

/*We needed to enable the Embed Map API on google API key*/
function EmbeddedMap(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const { center, hasLockLicense } = props;

  const renderMap = useCallback(() => {
    if (!center) {
      return <div className={classes.noLocation}>
        <Placeholder message={t("label.noLocationReported")} icon={<LocationOnIcon/>}/>
      </div>;
    }

    return <div className={classes.mapWrap}>
      <Iframe
        className={classes.iframe}
        allowfullscreen
        src={generateGoolgeMapsEmbedApiUrl(center)}/>
    </div>;
  }, [center, classes, t]);

  return <Grid container direction="column" alignItems="stretch" spacing={2}>
    <Grid item>
      <Typography className={classes.title}>{t("label.lastLocation")}</Typography>
    </Grid>
    { hasLockLicense ?
      <Grid item>
        {renderMap()}
      </Grid> :
      <Info message={t("error.lock.license.required")} className={classes.requireLicense}/>
    }
  </Grid>;
}

EmbeddedMap.defaultProps = {
  hasLockLicense: false
};

EmbeddedMap.propTypes = {
  center: PropTypes.object,
  hasLockLicense: PropTypes.bool
};

export default EmbeddedMap;