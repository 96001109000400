import * as actionTypes from "./actionTypes";
import i18n from "../i18n";

function setLocale(locale) {
  function setLanguage(language) { return { type: actionTypes.LANGUAGE_SET, language }; }
  
  return dispatch => {
    const lng = locale["language"];
    if (lng) {
      i18n.changeLanguage(lng);
      localStorage.setItem("language", lng);      
      
      i18n.changeLanguage(lng)
        .then(() => {
          dispatch(setLanguage(lng));
        });
    }
  };
}

export const localeActions = {
  setLocale
};