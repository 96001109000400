const lockstasyStyle = (theme) => ({
  lsyPage: {
    height: "100vh",
    width: "auto",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100vh",
    height: "fit-content"
  },
  lockstasyFrame: {
    height: "90%"
  },
  userCard: {
    minWidth: 275,
    marginTop: "5px"
  },
  pos: {
    marginBottom: 0
  },
  blankPage: {
    width: "100%", 
    height: "100%", 
    backgroundColor: theme.lsyPalette.secondary.background
  }
});
export default lockstasyStyle;
