const lockCardStyle = (theme) => ({
  card: {
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  lockCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  cardContent: {
    "flexGrow": "1",
    "& p": {
      margin: "0px"
    },
    "&:last-child": {
      paddingBottom: "8px"
    }
  },
  remove: {
    marginRight: "25px",
    color: theme.lsyPalette.primary.main
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  name: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title1,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  description: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body1,
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  keyValue: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": theme.lsyPalette.body1,
    "& span": {
      color: theme.lsyPalette.primary.mainDark,
      marginLeft: theme.spacing(1)
    }
  },
  field: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.primary.mainLight
  },
  value: {
    marginLeft: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1
  },
  disabled: {
    color: theme.lsyPalette.primary.mainLight
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  addIcon: {
    color: theme.lsyPalette.secondary.main
  },
  chip: {
    marginRight: "3px"
  },
  chips: {
    display: "inline"
  },
  tags: {
    marginBottom: 0,
    display: "inline",
    color: theme.lsyPalette.primary.mainLight
  },
  chipText: {
    color: theme.lsyPalette.primary.mainDark
  },
  defaultIcon: {
    height: "40px",
    width: "40px",
    marginLeft: "10px"
  },
  firmwareUpgradeIcon: {
    stroke: theme.lsyPalette.stdRed
  },
  arrow: {
    fill: theme.lsyPalette.secondary.main,
    stroke: theme.lsyPalette.stdRed
  }
});
export default lockCardStyle;
