const lockReplacementDetailsStyle = (theme) => ({
  border: {
    border: "1px solid",
    borderColor: theme.lsyPalette.border.lightGrey,
    borderRadius: theme.shape.borderRadius,
    minHeight: "230px"
  },
  description: {
    color: theme.lsyPalette.primary.mainLight
  },
  displayFlex: {
    display: "flex"
  },
  icon: {
    with: "20px",
    height: "20px"
  },
  infoLine: {
    display: "flex",
    alignItems: "center"
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  maxWidth: {
    maxWidth: "230px"
  },
  skeleton: {
    height: "230px",
    width: "100%"
  },
  spaceAround: {
    margin: theme.spacing(1)
  },
  spaceSidesBottom: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  spaceTopBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  value: {
    marginLeft: theme.spacing(1)
  }
});

export default lockReplacementDetailsStyle;