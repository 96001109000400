import { isBlank } from "_helpers";

export const setTitle = (title) => {
  if (!isBlank(title))
    title = title.trim();

  if (document.title.includes("Sera4 Teleporte")){
    document.title = `Sera4 Teleporte ${title || ""}`.trim();
  }
};

export const setS4talTitle = (title) => {
  setTitle(title);
};