import { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { useCustomCompareEffect } from "use-custom-compare";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";

import { alertActions } from "_actions";
import { getLocalTZ, lsyRouter } from "_helpers";
import { exportChartData, getInitialState, handleError } from "_services/lockstasy/ChartHelper";
import { deepFreeze } from "_services/lockstasy/helper";
import { genericReducer } from "_reducers/general.reducer";
import { chartService } from "_services/lockstasy/chart.service";

import ChartsWidgetTemplate from "../ChartsWidgetTemplate";

import { useTheme } from "@mui/material/styles";

const defaultInitialState = deepFreeze({
  group_by: "month",
  start_date: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
  end_date: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
});

function UserTimeAccessChart(props) {
  const { history, location, type } = props;
  const { search } = location;
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const theme = useTheme();

  const initialState = getInitialState(defaultInitialState, search, type);

  const [chartState, setChartState] = useReducer(genericReducer,
    {
      data: [],
      filterVariables: {...initialState},
      start_date: initialState.start_date,
      end_date: initialState.end_date,
      loading: true,
      failedFetchMessage: "",
      exportModelOpen: false
    }
  );

  const setExportModalOpen = (exportModalOpen) => setChartState({ exportModalOpen });

  const getXAxis = useCallback(() => {
    if (chartState.data) {
      let obj = {};
      chartState.data.map(v => {
        let name = v[v.length - 3] + " " + v[v.length - 2];
        if (obj[name]) {
          obj[name] += v[v.length - 1];
        } else {
          obj[name] = v[v.length - 1];
        }
      });

      let sortable = [];
      for (var item in obj) {
        sortable.push([item, obj[item]]);
      }

      sortable.sort(function(a, b) {
        return a[1] - b[1];
      });

      return sortable;
    }
  }, [chartState.data]);

  const formatTime = value => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = (value % 3600) % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const getOptions = () => {
    const xAxis = getXAxis();

    const options = {
      toolbox: {
        show: true,
        orient: "vertical",
        feature: {
          saveAsImage: {
            show: true,
            title: t("widget.chart.saveImage"),
            name: `${props.title.replaceAll(" ", "")}_${moment(chartState.start_date).format("YYYYMMDD")}_${moment(chartState.end_date).format("YYYYMMDD")}`
          }
        }
      },
      xAxis: {
        type: "value",
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          formatter: formatTime,
          width: 50,
          overflow: "break",
          marginRight: theme.spacing(1)
        }
      },
      yAxis: {
        type: "category",
        data: xAxis.map(v => v[0]).slice(xAxis.length - 10, xAxis.length),
        triggerEvent: true,
        axisLabel: {
          width: 100,
          overflow: "break",
          marginRight: theme.spacing(1)
        }
      },
      series: [{
        data: xAxis.map(v => v[1]).slice(xAxis.length - 10, xAxis.length),
        type: "bar",
        color: theme.chartPalette.color,
        label: {
          show: true,
          position: "right",
          formatter: data => formatTime(data.value)
        }
      }],
      tooltip: {
        trigger: "axis",
        valueFormatter: value => formatTime(value)
      },
      grid: {
        left: theme.spacing(1),
        right: theme.spacing(10),
        top: theme.spacing(2),
        containLabel: true,
        height: "450px"
      }
    };

    return options;
  };

  const onXAxisClick = (params) => {
    if (params.componentType === "yAxis") {
      const user = chartState.data.find(value => params.value === `${value[3]} ${value[4]}`);
      history.push(lsyRouter("user", user[1]));
    }
  };

  const getChartEvents = () => {
    return {
      click: onXAxisClick
    };
  };

  const onExportSubmit = (data) => {  
    if (moment(data.end_date).isSameOrBefore(data.start_date)) {
      dispatch(alertActions.send(t("error.invalidDates"), "error"));
    } else {
      setExportModalOpen(false);
      data.client_timezone = getLocalTZ();
      exportChartData(data, dispatch, t);
    }
  };

  const fetchCharts = useCallback(async () => {
    const options = {
      start_date: chartState.start_date,
      end_date: chartState.end_date,
      group_by: chartState.filterVariables.group_by,
      time_zone: chartState.filterVariables.client_timezone
    };

    try {
      const result = await chartService.fetchUserTimeAccess(options);
      let data = result.data.dates;
      setChartState({data: data, loading: false, failedFetchMessage: ""});
    } catch (e) {
      setChartState({
        data: [],
        loading: false,
        failedFetchMessage: t(handleError(e, ""))
      });
      console.warn("Warning, failed to fetch chart data with given input", e);
    }
  }, [chartState.filterVariables, chartState.start_date, chartState.end_date, t]);


  useCustomCompareEffect(() => {
    fetchCharts();
  }, [chartState.filterVariables, chartState.start_date, chartState.end_date, fetchCharts], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return (
    <ChartsWidgetTemplate
      title={props.title}
      history={history}
      location={location}
      type={props.type}
      chartState={chartState}
      setChartState={setChartState}
      getOptions={getOptions}
      defaultInitialState={defaultInitialState}
      descriptionMessage={`${t("description.userTimeChart")} ${t("description.oneYearInterval")}`}
      setExportModalOpen={setExportModalOpen}
      onExportSubmit={onExportSubmit}
      getChartEvents={getChartEvents}
    />
  );
}

UserTimeAccessChart.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object
};

export default UserTimeAccessChart;
