import moment from "moment";

import { lockLicenseConstants, lockLicenseTranslationKeys, lockLicenseConstantsById } from "_constants/lock.constants";
import { emptySchema, adminRules } from "_constants/schema.constants";

const ACTIONS = ["read", "create", "update", "delete", "use"];

function flattenNestedRersource(schema, parentResource = "", result = {}) {
  for (let resourceAction in schema) {
    if (!ACTIONS.includes(resourceAction)) {
      flattenNestedRersource(schema[resourceAction], parentResource ? `${parentResource}.${resourceAction}` : resourceAction, result);
    } else {
      if (!result[parentResource]) {
        result[parentResource] = {};
      }
      result[parentResource][resourceAction] = schema[resourceAction];
    }
  }
  return result;
}

export function formatSchema(schema, temp) {
  let formattedSchema = flattenNestedRersource({...emptySchema});
  let selectedSchema = flattenNestedRersource({...schema});

  if (selectedSchema["*"] === true)
    selectedSchema["*"] = adminRules;

  if (selectedSchema["*"]){
    const resources = Object.keys(formattedSchema);
    resources.forEach((resource) => {
      const actions = Object.keys(formattedSchema[resource]);
      actions.forEach((action) => {
        formattedSchema[resource][action] = selectedSchema["*"][action];
      });
    });
  }

  const resources = Object.keys(selectedSchema).filter((resource) => resource !== "*");
  resources.forEach((resource) => { //locks, lock_notes, users, tags etc
    try {
      const actions = Object.keys(selectedSchema[resource]);
      actions.forEach((action) => { //read, create, update, delete
        formattedSchema[resource][action] = selectedSchema[resource][action];
      });
    } catch (e) {
      console.warn("Failed to format resource: ", resource);
    }
  });
  if (temp) {
    formattedSchema["temp"] = {
      read: true
    };
  }

  return formattedSchema;
}

export function isBasicLicense(license) {
  return (!license || license.id === lockLicenseConstants["BASIC"]) ? true : false;
}

function isExpired(license) {
  if (license.valid_from && license.valid_till) {
    // We give 24hs TOLERANCE before and after to avoid middle of day issues due to client's Timezone
    const validFrom = moment(license.valid_from).subtract(1, "day");
    const validTill = moment(license.valid_till).add(1, "day");
    const now = moment();
    return validFrom.isAfter(now) || validTill.isBefore(now);
  } else {
    return true;
  }
}

function isKeyless(license) {
  return [
    lockLicenseConstants["KEYLESS"],
    lockLicenseConstants["KEYLESS-POC"],
    lockLicenseConstants["KEYLESS-DEMO"]
  ].includes(license["id"]);
}

function isStandard(license) {
  return [
    lockLicenseConstants["STANDARD"],
    lockLicenseConstants["STANDARD-POC"],
    lockLicenseConstants["STANDARD-DEMO"]
  ].includes(license["id"]);
}

function isEnterprise(license) {
  return [
    lockLicenseConstants["ENTERPRISE"],
    lockLicenseConstants["ACAAS"],
    lockLicenseConstants["ENTERPRISE-POC"],
    lockLicenseConstants["ENTERPRISE-DEMO"],
    lockLicenseConstants["FLEX"]
  ].includes(license["id"]);
}

export function hasFeature(feature, license) {
  // Enterprise Features (the list bellow + all Standard)
  // TENANT LICENSES
  //  - Maps
  //  - Notes
  // LOCK LICENSE
  //  - Multi Auth (manually enabled)
  //  - Open Delay (manually enabled)
  //  - Maps
  //  - FSU
  //  - Notes
  
  // Standard Features
  // TENANT LICENSES
  //  - RTS
  //  - Lock Collections / Site
  //  - Lock Groups
  //  - Dashboard
  //  - Work Sessions
  // LOCK LICENSE
  //  - Access History
  //  - Lock Collections / Site
  //  - Tags
  //  - Battery
  //  - Alerts

  // Keyless Features
  // TENANT LICENSES
  //  - Access Requests
  // LOCK LICENSE
  //  - Access History

  if (!license) {
    return false;
  }

  const keylessFeatures = ["access_history"];
  const standardFeatures = ["access_history", "battery_alerts", "open_lock_alerts", "lock_collections", "lock_groups", "tags", "maps"];

  // Basic or Expired has no feature
  if (isBasicLicense(license) || isExpired(license)) {
    return false;
  }

  // Look for valid Keyless
  if (isKeyless(license)) {
    return keylessFeatures.includes(feature);
  }

  // Look for valid Standard
  if (isStandard(license)) {
    return standardFeatures.includes(feature);
  }
  
  // Enterprise has all
  if (isEnterprise(license)) {
    return true;
  }

  return false;
}

export function hasLockLicense(feature, lock) {
  if (!lock) {
    return false;
  }  
  
  return hasFeature(feature, lock.license);
}

function applyUtcOffset(date) {
  return moment(date).subtract(moment(date).utcOffset(), "minutes");
}

export function getLicenseName(license) {  
  
  let name = lockLicenseTranslationKeys["BASIC"];

  // BASIC
  if (isBasicLicense(license) || isExpired(license)) {
    return {name};
  }

  // NON Basic
  name = lockLicenseTranslationKeys[lockLicenseConstantsById(license.id)];
  let expiration;

  // NON Basic with START/END
  if (license.valid_from && license.valid_till) {
    // License is always the User's start/end of day in their own Timezone
    const from = applyUtcOffset(license.valid_from).format("YYYY-MM-DD");
    const till = applyUtcOffset(license.valid_till).format("YYYY-MM-DD");
    expiration = { key: "lock.license.expiration", params: {from: from, till: till} };
  }

  return {name, expiration};
}

export function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self 
  for (let name of propNames) {
    let value = object[name];

    object[name] = value && typeof value === "object" ? 
      deepFreeze(value) : value;
  }

  return Object.freeze(object);
}

export function getLicenseInfoFromNote(note) {
  if (!note?.license_details) {
    return {};
  }

  const license = {
    id: note.license_details.license_definition_id,
    valid_from: note.license_details.valid_from,
    valid_till: note.license_details.valid_till
  };

  return getLicenseName(license);
}