import { get } from "_helpers/axios/gw.client";

import {
  identityUrl
} from "_services/helper";

const fetchServers = (options) => {
  const url = `${identityUrl}/servers?page_size=100`; // our server count is pretty low. hardcoded page size is fine
  return get(url, options);
};

const validateDns = (options) => {
  const url = `${identityUrl}/server_aliases/dns_exists`; // our server count is pretty low. hardcoded page size is fine
  return get(url, options);
};

export const serverService = {
  fetchServers,
  validateDns
};