const baseSupportUrl = "https://cx.sera4.com/";
export const SUPPORT_EMAIL = "support@sera4.com";

const languageMap = {
  en: "",
  fr: "",
  es: "es-mx",
  pt: "pt-br"
};

export const getSupportResourcesUrl = (language) => {
  return `${baseSupportUrl}${languageMap[language]}`;
};