const GenericLocationIconStyle = theme => ({
  svg: {
    enableBackground: "new 0 0 24 24",
    fill: theme.lsyPalette.primary.mainLight,
    fillOpacity: "1",
    marginTop: "10px"
  },
  path: {
    scale: "1.2",
    marginLeft: "15px"
  }
});
export default GenericLocationIconStyle;
        