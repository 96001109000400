import { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { isBlank } from "_helpers";

import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import AsyncSelect from "react-select/async";

import { Clear } from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import styles from "assets/jss/views/lockstasy/systemLogsStyle";

const useStyles = makeStyles()(styles);

function CustomAsyncSearchForm(props) {
  const { setSelectorValues, placeholder, fetchWithInput, selectorValues, loadDefaultOptions, isMulti, closeMenuOnSelect } = props;

  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const theme = useTheme();

  const handleSelectorSubmit = useCallback((e) => {
    setSelectorValues(e);
  }, [setSelectorValues]);

  const handleTheme = useCallback((base) => ({
    ...base,
    colors: {
      ...base.colors,
      primary25: theme.lsyPalette.rowHover,
      primary50: theme.lsyPalette.rowHover,
      primary: theme.lsyPalette.primary.mainLight
    }
  }), [theme]);

  const handleNoOptionsMessage = useCallback(({ inputValue }) => {
    if (isBlank(inputValue)) {
      return t("instructions.typeSomething");
    }

    return t("fallbacks.noOptions");
  }, [t]);

  const handleLoadingMessage = useCallback(() => t("actions.loading"), [t]);

  const selector = useCallback(() => {
    return (
      <AsyncSelect
        autoFocus={true}
        isClearable={false}
        backspaceRemovesValue={false}
        isMulti={isMulti}
        cacheOptions={true}
        defaultOptions={loadDefaultOptions}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        noOptionsMessage={handleNoOptionsMessage}
        loadingMessage={handleLoadingMessage}
        theme={handleTheme}
        styles={{
          container: provided => ({
            ...provided,
            width: "100%"
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            minHeight: "40px"
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: `0 ${theme.spacing(0.75)}`,
            paddingTop: "0px",
            overflow: "visible"
          }),
          indicatorsContainer: (provided) => ({
            ...provided
          }),
          placeholder: (provided) => ({
            ...provided,
            backgroundColor: theme.lsyPalette.secondary.main,
            color: theme.lsyPalette.primary.mainDark,
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            fontWeight: 400,
            fontSize: theme.lsyPalette.body1
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme.lsyPalette.text,
            fontSize: theme.lsyPalette.body3,
            fontWeight: 400
          }),
          multiValue: () => ({
            display: "none"
          })
        }}
        menuPortalTarget={document.body}
        label={placeholder}
        loadOptions={fetchWithInput}
        isOptionDisabled={(option) => option.disabled}
        value={selectorValues}
        autoBlur={false}
        closeMenuOnSelect={closeMenuOnSelect}
        blurInputOnSelect={false}
        onChange={handleSelectorSubmit}
      />
    );
  }, [
    selectorValues,
    fetchWithInput,
    placeholder,
    theme,
    loadDefaultOptions,
    isMulti,
    closeMenuOnSelect,
    handleSelectorSubmit,
    handleNoOptionsMessage,
    handleLoadingMessage,
    handleTheme
  ]);

  const filterSelectedValues = useCallback((selectorValues, value) => {
    const filteredValues = selectorValues.filter((item) => item.id !== value.id);

    setSelectorValues(filteredValues);
  }, [setSelectorValues]);

  const selectionPage = useCallback(() => {
    const page = (
      <Grid container>
        {selector()}
        {isMulti && !isEmpty(selectorValues) && <div className={classes.confirmSelection}>
          {selectorValues.map((value) => {
            const handleDeleteItem = () => filterSelectedValues(selectorValues, value);

            return (
              <GridItem className={classes.selectionItem} key={value.id}>
                <div className={classes.selectionPageDiv}>
                  <div className={classes.selectedNames}>{value.name}</div>
                  <IconButton
                    className={classes.removeItem}
                    aria-label="delete"
                    size="small"
                    onClick={handleDeleteItem}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </div>
              </GridItem>
            );
          })}
        </div>}
      </Grid>
    );
    return page;
  }, [classes, selectorValues, isMulti, filterSelectedValues, selector]);

  return selectionPage();
}

CustomAsyncSearchForm.defaultProps = {
  loadDefaultOptions: true,
  closeMenuOnSelect: false,
  isMulti: true
};

CustomAsyncSearchForm.propTypes = {
  setSelectorValues: PropTypes.func, 
  placeholder: PropTypes.string, 
  fetchWithInput: PropTypes.func, 
  selectorValues: PropTypes.array,
  loadDefaultOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool
};

export default CustomAsyncSearchForm;