import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/ap3Style";
const useStyles = makeStyles()(styles);

function AP3(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 672 672" className={classes.svg + " " + (props.className || "")} xmlSpace="preserve">
      <path className={cx(classes.ap3, (props.pathClassName || ""))} d="M339.5,237H273v-14h87.5C351.7,224.6,343.8,229.7,339.5,237z M322.5,315c1.4-0.1,80.2,0.2,81.5-0.1c-6.8-0.7-6.2-8.9-6-13.9
	h-55.4C338.3,307.3,331.5,314.1,322.5,315z M267,314.9c0.3,0,0.7,0.1,1,0.1v-0.3C267.7,314.8,267.3,314.9,267,314.9z M459.2,162.5
	h-12.6c0.4,4.7,0.3,10.8,0.3,15.5h-35v8.5h47.2c2.6,0,4.8,2.1,4.8,4.8v395c0,2.6-2.1,4.8-4.8,4.8H212.8c-2.6,0-4.8-2.1-4.8-4.8v-395
	c0-2.6,2.1-4.8,4.8-4.8H259V178h-34c0-4.7-0.1-10.8,0.3-15.5c-20.4-2.2-40.7,5.3-41.3,28.8c0,0,0,395,0,395
	c0,15.9,12.9,28.8,28.8,28.8c0,0,246.5,0,246.5,0c15.9,0,28.8-12.9,28.8-28.8c0,0,0-395,0-395C488,175.4,475.1,162.5,459.2,162.5z
	 M239.2,164h18.2c0-0.5,0.1-1,0.2-1.5h-18.2C239.3,163,239.3,163.5,239.2,164z M414.6,164h18.2c0-0.5-0.1-1-0.1-1.5h-18.2
	C414.5,163,414.6,163.5,414.6,164z M266,159c2.4,0,4.4,1.2,5.7,3c11-76,116.3-76.8,128.4-1c3-3.1,8.9-2.3,10.9,1.5h3.4
	c-11.9-96-145-95.9-156.9,0h2.4C261.2,160.4,263.4,159,266,159z M246.4,132.7c40.2-96.6,177-73.3,186.2,29.8h14.1
	c-14.4-140-207.1-139.9-221.4,0h14.1C240.2,152.3,242.6,142.2,246.4,132.7z M225,178h34c0.3-2.7-0.8-13.3,0.9-15.5h-2.4
	c-0.1,0.5-0.1,1-0.2,1.5h-18.2c0-0.5,0.1-1,0.1-1.5h-14.1C224.9,167.2,225,173.3,225,178z M432.8,164h-18.2c0-0.5-0.1-1-0.2-1.5
	h-3.4c1.7,2.2,0.7,12.9,0.9,15.5h35c0-4.7,0.1-10.8-0.3-15.5h-14.1C432.7,163,432.7,163.5,432.8,164z M399,162.5h1.3
	c-0.1-0.5-0.1-1-0.2-1.5c-4.2-1-1.4,59.4-2.2,61.5h0.3v0.5h0.7v14h-0.7v0.5H398V301c13.3-2.2,16.1,11.2,6,13.9c4.2,0.7,8-2.7,8-6.9
	c0,0,0-121.5,0-121.5h-13V162.5z M399,237v-14h-0.7v14H399z M404,314.9c10.1-2.7,7.3-16.2-6-13.9C397.8,306,397.2,314.2,404,314.9z
	 M400.9,171c0-2.8-0.2-5.7-0.5-8.5H399v24h13V178h-11.1V171z M405,159c-1.9,0-3.6,0.7-4.8,2c0.1,0.5,0.1,1,0.2,1.5h10.7
	C409.8,160.4,407.6,159,405,159z M411.1,162.5h-10.7c0.7,4.6,0.5,10.8,0.5,15.5H412C411.7,175.3,412.8,164.7,411.1,162.5z M266,315
	c0.3,0,0.7,0,1-0.1c-8.4-1-7.5-14.1,1-13.9v-0.9h5V237h-12v-14h12c-0.5-1.3,1.3-62.2-1.3-61c-0.4-0.6-0.2,24.1-0.2,24.5H259V308
	C259,311.9,262.1,315,266,315z M261,223v14h12v-14H261z M268,301c-9.1-0.4-9,14.7,0,13.7V301z M271.5,163.8
	c-0.5,4.1-0.4,10-0.4,14.2H259v8.5h12.5V163.8z M271.5,163.8c0.1-0.6,0.1-1.2,0.2-1.8c-2.6-4.1-9.5-3.8-11.8,0.5h11.6V163.8z
	 M271.1,171c-0.1-2.8,0.4-5.7,0.4-8.5h-11.6c-1.7,2.2-0.7,12.9-0.9,15.5h12.1V171z M268,315.1h52.6c0.6,0,1.3,0,1.9-0.1L268,315.1
	L268,315.1z M339.5,237c-5.8,7.7-3,30.4-3.6,39.5c0.9,5.7-8.5,24.3-15.3,23.6c0,0-47.6,0-47.6,0v0.9h69.6
	c19.5-24.1-5.7-62.2,23.7-63.5c0,0,31.7,0,31.7,0V237H339.5z M360.5,223H398v-0.5C393.7,222.6,364.1,222,360.5,223z M339.5,237H398
	v-14C380.3,224.1,349.8,217.1,339.5,237z M342.6,301H273c0,4.6,1,12.3-5,13.7v0.3h54.5C331.5,314.1,338.3,307.3,342.6,301z
	 M398,237.5h0.3V237H398V237.5z M398.3,223v-0.5H398v0.5H398.3z M398,237h0.3v-14H398V237z M273,300.1h-5v0.9h5V300.1z M273,308v-7
	h-5v13.7C270.9,313.8,273,311.2,273,308z M395,418.5c-1.3,78.2-117.7,78.2-119,0C277.3,340.3,393.7,340.3,395,418.5z M380.1,418.5
	c-1-58.7-88.3-58.7-89.3,0C291.9,477.2,379.1,477.2,380.1,418.5z"/>
    </svg>
  );
}

AP3.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

export default AP3;
