import PropTypes from "prop-types";
import { SnackbarProvider as Snackbar } from "notistack";
import isMobile from "_utils/mobile-detector";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles()(styles);

const SnackbarProvider = (props) => {
  const { classes } = useStyles();

  return <Snackbar
    classes={{
      variantError: classes.error,
      variantWarning: classes.warning,
      variantInfo: classes.info,
      variantSuccess: classes.success
    }}
    iconVariant={{
      error: "",
      success: "",
      warning: "",
      info: ""
    }}
    maxSnack={3}
    anchorOrigin={
      isMobile()
        ? { vertical: "top", horizontal: "center" }
        : { vertical: "bottom", horizontal: "left" }
    }
  >
    {props.children}
  </Snackbar>;
};

SnackbarProvider.propTypes = {
  children: PropTypes.any
};

export default SnackbarProvider;