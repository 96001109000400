import { useCallback, useEffect, useMemo, useRef, useReducer } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import { genericReducer } from "_reducers/general.reducer";

import SessionTimeoutModal from "_components/Modal/SessionTimeoutModal";

/*Session timeout is used in milliseconds*/
const DEFAULT_SESSION_TIMEOUT = 1200000;

const IdleSessionTimeout = (props) => {
  const [ state, setState ] = useReducer(genericReducer,
    {
      showModal: false,
      isLogout: false,
      timeoutOrigin: "",
      showOrganization: false
    }
  );
  const memberships = useSelector((state) => state.memberships);
  const membershipList = useMemo(() => memberships?.list || [], [memberships]);
  const currentMembership = memberships.currentMembership;
  const events = useMemo(() => ["click", "scroll", "keydown"], []);
  const { onLogout } = props;
  let timer = useRef();
  const sessionTimeout = currentMembership?.settings?.session_timeout;

  const setShowModal = open => setState({
    showModal: open,
    showOrganization: membershipList.length > 1
  });
  
  /*Need to convert minutes in milliseconds because it is saved in the DB in minutes*/
  const timeoutInterval = sessionTimeout !== undefined ? sessionTimeout * 60000 : DEFAULT_SESSION_TIMEOUT;

  const startTimer = useCallback(() => {
    if(timer.current){
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      const lastInteractionTime = localStorage.getItem("lastInteractionTime");
      const diff = moment.duration(moment().diff(moment(lastInteractionTime)));

      if(state.isLogout){
        clearTimeout(timer.current);
      }else if(diff._milliseconds < timeoutInterval){
        startTimer();
      }else{
        setState({
          showModal: true,
          timeoutOrigin: currentMembership?.tenant_name,
          showOrganization: membershipList.length > 1
        });
      }
    }, timeoutInterval);
  }, [state.isLogout, timeoutInterval, currentMembership, membershipList]);

  const eventHandler = useCallback(() => {
    if (!state.isLogout) {
      localStorage.setItem("lastInteractionTime", moment().format());
      startTimer();
    }
  }, [state.isLogout, startTimer]);
  
  const addEvents = useCallback(() => {
    events.forEach(event => {
      window.addEventListener(event, eventHandler);
    });
    
    startTimer();
  }, [eventHandler, events, startTimer]);
  
  const removeEvents = useCallback(() => {
    events.forEach(event => {
      window.removeEventListener(event, eventHandler);
    });
  }, [eventHandler, events]);

  const isSessionTimeoutEnabled = useCallback(() => {
    return timeoutInterval > 0;
  }, [timeoutInterval]);
  
  useEffect(() => {
    if (!localStorage.getItem("lastInteractionTime", moment())) {
      localStorage.setItem("lastInteractionTime", moment());
    }

    if (isSessionTimeoutEnabled()) {
      addEvents();
    }
    
    return () => {
      removeEvents();
      clearTimeout(timer.current);
    };
  }, [isSessionTimeoutEnabled, addEvents, removeEvents]);
  
  const continueSession = () => {
    setState({
      showModal: false,
      isLogout: false
    });
  };

  const logout = () => {
    removeEvents();
    clearTimeout(timer.current);
    setState({
      showModal: false,
      isLogout: true
    });
    onLogout();
  };

  return (
    <SessionTimeoutModal
      showModal={state.showModal}
      setShowModal={setShowModal}
      logout={logout}
      continueSession={continueSession}
      timeoutOrigin={state.timeoutOrigin}
      membership={currentMembership}
      showOrganization={state.showOrganization}
    />
  );
};
  
IdleSessionTimeout.propTypes = {
  onLogout: PropTypes.func.isRequired
};

export default IdleSessionTimeout;