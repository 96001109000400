const lockStyle = (theme) => ({
  accessWidgetWrap: {
    padding: "20px"
  },
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    borderRadius: "3px",
    marginBottom: "25px"
  },
  divider: {
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  lockDetailsWidget: {
    flexGrow: "1"
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 10px 5%"
  },
  mapWrap: {
    display: "flex", 
    alignItems: "center", 
    minWidth: "300px", 
    flexGrow: "8" 
  },
  tableWidgetItem: {
    paddingLeft: "3%",
    paddingRight: "3%"
  }
});

export default lockStyle;
