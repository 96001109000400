import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Can } from "@casl/react";

import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// Services
import { lockService } from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";

// @mui/material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Components
import GridItem from "components/Grid/GridItem";
import KeyWidget from "_containers/Widgets/KeyWidget";
import LockDetailsWidget from "_containers/Widgets/LockDetailsWidget";
import LockNoteWidget from "_containers/Widgets/LockNoteWidget";
import AccessWidget from "_containers/Widgets/AccessWidget";
import EmbeddedMap from "_containers/Widgets/Maps/EmbeddedMap";
import ErrorBoundary from "_components/ErrorBoundary";

// Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockStyle.js";

const useStyles = makeStyles()(styles);

function Lock(props) {
  const { org, history } = props;
  const { classes } = useStyles();
  const params = useParams();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const [lock, setLock] = useState({});
  const [refreshKeys, setRefreshKeys] = useState(false);

  const ability = lsyAdminDataContext.ability;
  const canReadDeactivatedLock = ability.can("read", "locks.deactivation");
  const isRegularUser = false;

  const fetchLock = useCallback(async () => {
    try {
      const result = await lockService.fetchLock({ lockId: params.id });
      setLock(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
      return [];
    }
  }, [params.id]);

  useEffect(() => {
    fetchLock();
  }, [fetchLock]);

  useEffect(() => {
    if (lock?.deactivated && !canReadDeactivatedLock) {
      history.replace("/Dashboard");
    }
  }, [lock, canReadDeactivatedLock, history]);

  const renderKeysSection = () => {
    if (lock.deactivated) {
      return null;
    }

    return <>
      <Divider className={classes.divider} />
      <Grid item xs={12} className={classes.tableWidgetItem}>
        <ErrorBoundary>
          <KeyWidget org={org} lock={lock} type="lock" refreshKeys={refreshKeys} setRefreshKeys={setRefreshKeys}/>
        </ErrorBoundary>
      </Grid>
    </>;
  };

  const renderMapSection = () => {
    if (lock.deactivated) {
      return null;
    }

    return <>
      <Divider className={classes.divider} />
      <GridItem  className={classes.mapWrap}>
        <ErrorBoundary>
          <EmbeddedMap
            center={lock.location}
            hasLockLicense={hasLockLicense("maps", lock)}
          />
        </ErrorBoundary>
      </GridItem>
    </>;
  };

  const renderLockNoteSection = () => {
    return <>
      <Divider className={classes.divider}/>
      <GridItem xs={12}>
        <ErrorBoundary>
          <LockNoteWidget
            org={org}
            history={history}
            isRegularUser={isRegularUser}
            type="lock"
            lock={lock}
          />
        </ErrorBoundary>
      </GridItem>
    </>;
  };
  
  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="space-evenly" className={classes.container}>
        <GridItem xs={12} lg={6} className={classes.LockDetailsWidget}>
          <ErrorBoundary>
            <LockDetailsWidget
              lock={lock}
              history={history}
              fetchLock={fetchLock}
              setRefreshKeys={setRefreshKeys}
            />
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem sx={{ display: { md: "block", xs: "none" } }}/>
        <Divider className={classes.divider} sx={{ display: { lg: "none", xs: "block" } }} />
        <GridItem xs={12} lg={5} className={classes.accessWidgetWrap}>
          <ErrorBoundary>
            <Can I="read" on="access_history" ability={ability}>
              <AccessWidget
                lock={lock}
                org={org}
                history={history}
                isRegularUser={isRegularUser}
                type="lock"
              />
            </Can>
          </ErrorBoundary>
        </GridItem>
        { renderKeysSection() }
        { renderMapSection() }
        { renderLockNoteSection() }
      </Grid>
    </div>
  );
}

Lock.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string
};

export default Lock;
