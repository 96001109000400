export const authApps = [
  {
    appName: "Microsoft Authenticator",
    appLink: "https://www.microsoft.com/en-us/security/mobile-authenticator-app"
  },
  {
    appName: "Google Authenticator",
    appLink:
      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US"
  },
  {
    appName: "Bitwarden",
    appLink: "https://bitwarden.com/"
  }
];