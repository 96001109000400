const hsp1Style = theme => ({
  hsp1: {
    fill: theme.lsyPalette.textContrast,
    stroke: theme.lsyPalette.primary.main,
    strokeWidth: "40",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fillOpacity: "1"
  },
  svg: {
    enableBackground: "new 0 0 1319 1646"
  }
});

export default hsp1Style;
