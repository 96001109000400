const dynamicMapWidgetStyle = theme => ({
  noLocation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    color: theme.lsyPalette.subText,
    backgroundColor: theme.lsyPalette.secondary.background,
    fontWeight: "500"
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  lockSelectLabel: {
    color: theme.lsyPalette.text
  },
  lockSelect: {
    backgroundColor: theme.lsyPalette.secondary.main,
    color: theme.lsyPalette.text
  },
  lockOptions: {
    color: theme.lsyPalette.text,
    backgroundColor: theme.lsyPalette.secondary.main
  },
  select: {
    backgroundColor: theme.lsyPalette.secondary.main,
    color: theme.lsyPalette.text
  },
  underline: {
    "borderColor": theme.lsyPalette.text,
    "&:before": {
      borderColor: theme.lsyPalette.text
    },
    "&:after": {
      borderColor: theme.lsyPalette.text
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.lsyPalette.text
    }
  },
  itemRoot: {
    "&:hover": {
      backgroundColor: theme.lsyPalette.secondary.mainHover,
      color: theme.lsyPalette.text
    }
  },
  map: {
    width: "100%",
    padding: "0px !important" // <GridItem> also has !important in it's style
  },
  keyValue: {
    "fontWeight": "800",
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.body1,
    "& span": {
      display: "inline-block",
      fontWeight: "normal"
    }
  },
  lockName: {
    color: theme.lsyPalette.text,
    fontSize: theme.lsyPalette.body2,
    fontWeight: "800",
    marginTop: "0px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  lockDetails: {
    "fontSize": theme.lsyPalette.body1,
    "fontWeight": "800",
    "& span": {
      color: theme.lsyPalette.link,
      cursor: "pointer"
    }
  },
  controls: {
    alignSelf: "center",
    marginTop: "15px",
    border: `1px solid ${theme.lsyPalette.primary.mainLight}`,
    backgroundColor: theme.lsyPalette.secondary.main,
    width: "100%"
  },
  controls2: {
    alignSelf: "center",
    borderRadius: "3px",
    backgroundColor: theme.lsyPalette.secondary.main,
    width: "100%"
  },
  controlDiv: {
    textAlign: "center"
  },
  liveButtonInactive: {
    backgroundColor: theme.lsyPalette.secondary.background
  },
  liveButtonActive: {
    "backgroundColor": "#b3d4ff",
    "&:hover": {
      backgroundColor: "#b3d4ff"
    }
  },
  borders: {
    borderRight: `1px solid ${theme.lsyPalette.primary.mainLight}`,
    borderLeft: `1px solid ${theme.lsyPalette.primary.mainLight}`,
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    display: "inline"
  },
  lockStatusButton: {
    transition: "color .2s linear,background-color .3s linear",
    marginRight: "5px"
  },
  liveButton: {
    marginLeft: "5px",
    marginRight: "5px",
    transition: "color .2s linear,background-color .3s linear"
  },
  buttonActive: {
    "transition": "color .2s linear,background-color .3s linear",

    "&:hover": {
      backgroundColor: theme.lsyPalette.secondary.background
    },
    "color": theme.lsyPalette.text
  },
  data: {
    verticalAlign: "middle",
    color: theme.lsyPalette.text,
    fontSize: theme.lsyPalette.body1,
    minWidth: "70px",
    display: "inline-block",
    paddingLeft: "5px"
  },
  content: {
    paddingBottom: "75px",
    color: theme.lsyPalette.text,
    fontSize: theme.lsyPalette.body1
  },
  content2: {
    color: theme.lsyPalette.text,
    fontSize: theme.lsyPalette.body1
  },
  redLegendItem: {
    fill: theme.lsyPalette.stdRed
  },
  legendUnderline: {
    width: "90%",
    borderTop: `1px solid ${theme.palette.text.light}`,
    marginTop: "-12px",
    marginRight: "12px"
  },
  blackLegendItem: {
    fill: "black"
  },
  controlsWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "absolute",
    top: "40px",
    left: "5px",
    width: "140px"
  },
  legend: {
    display: "flex",
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    padding: "0px 13px 7px",
    fontSize: "12px",
    flexDirection: "column",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    color: theme.palette.text.main 
  },
  legendItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "20px"
  },
  red: {
    display: "inline-block",
    width: "7px",
    height: "7px",
    backgroundColor: "red",
    borderRadius: "50%"
  },
  black: {
    display: "inline-block",
    width: "7px",
    height: "7px",
    backgroundColor: "black",
    borderRadius: "50%"
  },
  accordian: {
    width: "100%"
  },
  expandIcon: {
    padding: "0 12px"
  },
  switchOption: {
    "alignItems": "center",
    "justifyContent": "space-between",
    "display": "flex",
    "& p": {
      margin: "0px"
    },
    "&:nth-of-type(3)": {
      marginTop: "10px"
    }
  },
  accordianDetails: {
    display: "block",
    fontSize: theme.lsyPalette.subtitle3,
    paddingTop: "0px"
  },
  sunIcon: {
    fontSize: "20px",
    marginTop: "2px",
    marginLeft: "4px"
  },
  accordianSummary: {
    height: "48px"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px"
  },
  itemGrid: {
    width: "100%"
  },
  formGrid: {
    width: "400px",
    [theme.breakpoints.down("lg")]: {
      width: "100%"
    },
    paddingTop: "15px"
  },
  controlsItemDiv: {
    width: "100%",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "0px"
  },
  popOver: {
    top: "10px",
    border: `1px solid ${theme.lsyPalette.primary.mainLight}`
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)",
    verticalAlign: "middle"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  loader: {
    display: "inline-block",
    paddingLeft: "10px"
  },
  title: {
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  mapWrap: {
    height: "360px",
    padding: "10px 0px",
    width: "100%"
  },
  requireLicense: {
    height: "310px"
  }
});

export default dynamicMapWidgetStyle;