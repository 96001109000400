import { useState, useEffect, useCallback, useContext, useReducer, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { defaultLocation } from "_constants/map.constants";
import { accessRequestService, userService, lockService, siteService } from "_services/lockstasy";
import { alertActions } from "_actions";
import { compactObj, lsyRouter } from "_helpers";
import { generateGoolgeMapsEmbedApiUrl, isNullOrUndefined } from "_helpers/utility";
import { sortReducer } from "_reducers/sort.reducer";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import ListIcon from "@mui/icons-material/List";

// @mui/material components
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import { Divider, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Iframe from "react-iframe";

//components
import ErrorBoundary from "_components/ErrorBoundary";
import LoadingPlaceHolder from "_components/Loading";
import CustomModal from "_components/Modal/CustomModal";
import RequestCard from "_components/Lockstasy/RequestCard";
import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import Placeholder from "_components/Helper/Placeholder";
import Badge from "_components/UI/Badge";

//styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessRequestsStyle.js";

const maxFetchSize = 5000;

const initialState = Object.freeze({
  search_by: "requestor_id",
  requestor_id: [],
  approver_id: [],
  lock_id: [],
  site_id: [],
  status: "0"
});

const MAX_ZOOM = 15;
const DEFAULT_ZOOM = 7;

const useStyles = makeStyles()(styles);

function AccessRequests(props) {
  const { classes } = useStyles();
  const { history } = props;
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [denyModal, setDenyModal] = useState({ lock: null, open: false, key: 0, id: null });
  const setDenyModalOpen = (open) => setDenyModal({ open });
  const [wizard, setWizard] = useState({ lock: null, open: false, key: 0, id: null, siteName: "" });
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const { t } = useTranslation("default");
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("lg"));
  const params = useParams();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const shouldScroll = useRef(false);

  const { handleSubmit, reset, setValue, watch, formState: { errors }, control } = useForm();

  const fetchUsersWithInput = async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  };

  const fetchLocksWithInput = async (searchInput) => {
    try {
      const result = await lockService.fetchLocks({ search_name: searchInput });
      return result.data.map(lock => lock ? { id: lock.id, name: lock.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
      return [];
    }
  };

  const fetchSitesWithInput = async (searchInput) => {
    try {
      const result = await siteService.fetchSites({ search: searchInput });
      return result.data.map(site => site ? { id: site.id, name: site.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch sites with given input", e);
      return [];
    }
  };

  const [state, setState] = useReducer(sortReducer.reducer,
    {
      data: [],
      totalSize: 0,
      loading: false,
      filterOpen: false,
      filterActive: false,
      filterVariables: initialState,
      currentPage: 1
    });
  const setFilterOpen = (filterOpen) => setState({ filterOpen });

  const searchBy = watch("search_by");

  const fetchRequests = async () => {
    setLoading(true);
    try {
      let options = {
        page: 1,
        page_size: maxFetchSize,
        ...state.filterVariables
      };
      options = compactObj(options);

      if (options[options.search_by]) {
        options[options.search_by] = options[options.search_by].id;
      }
      delete options.search_by;

      let result = await accessRequestService.fetchRequests(options);

      const tempDialogStatus = {};
      var formattedResult = [];
      for (let i = 0; i < result.data.length; i++) {
        const request = result.data[i];
        try {
          let location = request.location;
          if (location) {
            tempDialogStatus[request.id] = false;
            location = { lat: parseFloat(location.lat), lng: parseFloat(location.lng) };
          }

          formattedResult.push({ id: request.id, location: location, data: request });
        } catch (e) {
          continue;
        }
      }
      setLocations(formattedResult);

      if (params.id) {
        try {
          let id = parseInt(params.id);
          setSelected(id);
        } catch (e) {
          console.warn("Failed to find request with id " + params.id, e);
        }
        history.replace(lsyRouter("access_requests"));
      } else if (!isEmpty(formattedResult)) {
        setSelected(formattedResult[0].id);
      }

    } catch (e) {
      console.warn("Failed to fetch requests", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterVariables]);


  const filterForm = () => {
    let searchByFilter = searchBy || state.filterVariables.search_by;

    let filterOption = {
      field: "",
      type: "typeAhead",
      defaultValue: [],
      placeholder: "",
      async: true,
      promiseOptions: ""
    };
    if (searchByFilter === "requestor_id" || searchByFilter === "approver_id") {
      filterOption.field = searchByFilter;
      filterOption.promiseOptions = fetchUsersWithInput;
      filterOption.placeholder = t("widgetField.user");
    } else if (searchByFilter === "lock_id") {
      filterOption.field = searchByFilter;
      filterOption.promiseOptions = fetchLocksWithInput;
      filterOption.placeholder = t("widgetField.lock");
    } else if (searchByFilter === "site_id") {
      filterOption.field = searchByFilter;
      filterOption.promiseOptions = fetchSitesWithInput;
      filterOption.placeholder = t("widgetField.site");
    }

    const options = [
      {
        field: "search_by",
        type: "select",
        label: t("label.searchBy"),
        defaultValue: "requestor_id",
        options: [
          { name: t("label.requestor"), value: "requestor_id" },
          { name: t("label.approver"), value: "approver_id" },
          { name: t("form.lockId"), value: "lock_id" }
        ]
      },
      filterOption,
      {
        field: "status",
        type: "select",
        label: t("label.status"),
        defaultValue: "0",
        options: [
          { name: t("label.pending"), value: "0" },
          { name: t("label.approved"), value: "1" },
          { name: t("label.denied"), value: "2" },
          { name: t("label.all"), value: " " }
        ]
      }
    ];

    if (ability.can("read", "lock_collections")) {
      options[0].options.push({ name: t("label.siteId"), value: "site_id" });
    }

    return options;
  };

  const denyForm = () => {
    const options = [
      {
        field: "message",
        type: "textField",
        label: t("label.confirmationEmail"),
        defaultValue: t("label.accessRequestDenied", { lock: denyModal.lock }),
        autoFocus: true,
        multiline: true
      }
    ];

    return options;
  };

  const resetFilter = () => {
    reset(initialState);
    setState({ filterActive: false, filterVariables: initialState, currentPage: 1 });
  };

  const updateRequest = async (options) => {
    try {
      await accessRequestService.updateRequest(options);
      dispatch(alertActions.send(t("success.updateAccessRequest")));
      fetchRequests();
    } catch (e) {
      console.warn("Failed to update access request", e);
      dispatch(alertActions.send(t("error.updateAccessRequest"), "error"));
    }
  };

  const onFilterSubmit = (data) => {
    if (!isEqual(data, state.filterVariables)) {
      reset(data);
      setState({
        filterVariables: {
          ...data
        },
        filterActive: true,
        filterOpen: false,
        currentPage: 1
      });
    } else {
      setFilterOpen(false);
    }
  };

  const onDenySubmit = ({ message }) => {
    setDenyModalOpen(false);
    updateRequest({ action: "decline", id: denyModal.id, data: { message: message } });
  };

  const requestFallback = <Placeholder message={t("error.noRecords")} icon={<ListIcon/>}/>;

  const requestCards = locations?.length > 0 ? locations.map((request) => {
    return <RequestCard
      history={history}
      key={request.id}
      data={request.data}
      selected={selected === request.id}
      isMobile={isMobile}
      shouldScroll={shouldScroll}
      setSelected={setSelected}
      setDenyModal={setDenyModal}
      setWizard={setWizard}
    />;
  }) : requestFallback;

  const title = (locations.length === maxFetchSize ? `${maxFetchSize}+` : locations.length) + " " + t("features.accessRequests");

  const skeletonCard = <Card className={classes.skeletonTitle} variant="outlined">
    <Skeleton className={classes.skeletonContent} variant="rectangular" width="35%" height="22px" />
    <Skeleton className={classes.skeletonContent} variant="rectangular" width="80%" height="22px" />
    <Skeleton className={classes.skeletonContent} variant="rectangular" width="80%" height="22px" />
    <Skeleton className={classes.skeletonContent} variant="rectangular" width="80%" height="22px" />
  </Card>;

  const renderMap = useCallback(() => {
    if (loading) {
      return <LoadingPlaceHolder title={t("actions.loading")} fullHeight={true} />;
    }

    const location = locations.find(l => l.id === selected)?.location || defaultLocation;
    const zoom = isNullOrUndefined(selected) ? DEFAULT_ZOOM : MAX_ZOOM;

    return <Iframe
      className={classes.iframe}
      allowfullscreen
      src={generateGoolgeMapsEmbedApiUrl(location, zoom)}
    />;
  }, [loading, locations, selected, classes.iframe, t]);

  const renderRequestCards = () => {
    return <Grid container alignItems="center" justifyContent="center" className={classes.requestList}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {loading ?
              <div className={classes.loader}>
                <LoadingPlaceHolder color={muiTheme.lsyPalette.primary.mainLight} height={12}/>
              </div> :
              <p className={classes.title}>{title}</p>
            }
          </Grid>
          <Grid item>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={state.filterActive ? t("label.filterApplied") : t("button.filter")}>
              <IconButton size="small" aria-label="filter" onClick={() => setFilterOpen(true)}>
                <Badge invisible={!state.filterActive}>
                  <FilterListIcon className={classes.icon} />
                </Badge>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <div className={classes.cardDiv}>
          {loading ? [...Array(5).keys()].map((index) => {
            return <Fragment key={index}>{skeletonCard}</Fragment>;
          }) : requestCards}
        </div>
      </Grid>
    </Grid>;
  };

  return (
    <div className={classes.lsyBackground}>
      <form onSubmit={handleSubmit(onFilterSubmit)}>
        <CustomModal
          open={state.filterOpen}
          setOpen={setFilterOpen}
          handleSubmit={handleSubmit(onFilterSubmit)}
          title={t("button.filter")}
          type="formCreator"
          modalStyle={classes.filterModal}
          handleCancel={resetFilter}
          confirm={t("button.apply")}
          cancel={t("button.reset")}
          submit
          manualClose
          errors={errors}
          control={control}
          setValue={setValue}
          formOptions={filterForm()}
          submitting={state.loading}
        />
      </form>
      <form onSubmit={handleSubmit(onDenySubmit)}>
        <CustomModal
          key={denyModal.key}
          open={denyModal.open}
          setOpen={setDenyModalOpen}
          handleSubmit={handleSubmit(onDenySubmit)}
          title={t("label.denyAccessRequestFor", { lock: denyModal.lock })}
          type="formCreator"
          modalStyle={classes.denyModal}
          handleCancel={resetFilter}
          confirm={t("actions.deny")}
          cancel={t("button.close")}
          submit
          manualClose
          errors={errors}
          control={control}
          setValue={setValue}
          formOptions={denyForm()}
          submitting={state.loading}
        />
      </form>
      {wizard.open ?
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          lockName={wizard.lock}
          requestId={wizard.id}
          siteName={wizard.siteName}
          setOpen={setWizard}
          type="accessRequest"
          fetchWidgetData={fetchRequests}
        /> : null}
      <Grid container justifyContent="center" direction="row">
        <GridItem className={classes.controls} md={3} sx={{ display: { xs: "none", md: "block" } }}>
          {renderRequestCards()}
        </GridItem>
        <GridItem className={classes.map} xs={11.5} md={8}>
          <ErrorBoundary>
            {renderMap()}
          </ErrorBoundary>
        </GridItem>
        <GridItem className={classes.controls} xs={11.5} md={8} sx={{ display: { xs: "block", md: "none" } }}>
          {renderRequestCards()}
        </GridItem>
      </Grid>
    </div>
  );
}

AccessRequests.propTypes = {
  history: PropTypes.object
};

export default AccessRequests;
