import { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";

import { hardwareTypeByCode, lockTypeById } from "_constants/lock.constants";
import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData";
import { firmwareService } from "_services/admin/firmware.service";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import LoadingPlaceHolder from "_components/Loading";
import DataTable from "_components/Table/DataTable.js";
import OverflowWrapper from "_components/Table/OverflowWrapper.js";
import { UUIDWrapper } from "_components/Uuid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";

import List from "@mui/icons-material/List";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/editFirmwareBundleStyle";

const useStyles = makeStyles()(styles);

function EditFirmwareBundle(props) {
  const { data } = props;
  const { classes } = useStyles();
  const { findUser } = useContext(AppAdminDataContext);

  const [bundleState, setBundleState] = useState({});

  const getFirmwareBundleData = useCallback(async() => {
    try {
      const result = await firmwareService.fetchBundlesData(data.bundleId);
      if (result) {
        setBundleState(result);
      }
    } catch(e) {
      console.warn(e);
    }
  }, [data.bundleId]);

  useEffect(() => {
    getFirmwareBundleData();

    return () => {
      setBundleState({});
    };
  }, [getFirmwareBundleData]);

  const renderBundleInfo = () => {
    return <>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography><b>Bundle Id</b></Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{bundleState.id}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography><b>Updated At</b></Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{moment(bundleState.updated_at).format("lll")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography><b>Created At</b></Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{moment(bundleState.updated_at).format("lll")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography><b>Created By</b></Typography>
          </Grid>
          <Grid item>
            <UUIDWrapper
              uuid={bundleState.created_by}
              name={findUser(bundleState.created_by)}
              url={props.url}
            />
          </Grid>
        </Grid>
      </Grid>
    </>;
  };

  const getColumns = () => {
    return [
      {
        name: "Component",
        selector: (row) => row.firmware.component,
        width: "25%"
      },
      {
        name: "Version",
        cell: (row) => <OverflowWrapper title={`${row.firmware.version} ${row.firmware.deprecated ? "(Deprecated)" : ""}`} />,
        width: "25%"
      },
      {
        name: "HW Type",
        selector: (row) => hardwareTypeByCode(row.firmware.hardware_type),
        width: "25%"
      },
      {
        name: "Lock Type",
        selector: (row) => lockTypeById(row.firmware.lock_type),
        width: "25%"
      }
    ];
  };

  const renderFirmwareTable = () => {
    return <Grid item>
      <hr/>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography><b>Firmware</b></Typography>
        </Grid>
      </Grid>
      <DataTable
        data={bundleState.components || []}
        loadingText="Searching for firmware"
        noDataComponent={<Placeholder message={"No records were found"} icon={<List/>} />}
        sortServer
        highlightOnHover
        striped
        columns={getColumns()}
      />
    </Grid>;
  };

  const renderContent = () => {
    return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
      <Grid item><hr/></Grid>
      { renderBundleInfo() }
      { renderFirmwareTable() }
    </Grid>;
  };

  return <Grid container>
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.card}>
        <CardHeader color="primary" text>
          <CardText color="primary">
            <h4 className={classes.cardTitle}><b>Firmware Bundle</b></h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <ErrorBoundary>
            { isEmpty(bundleState) ? <LoadingPlaceHolder title="Loading Bundle Data"/> : renderContent() }
          </ErrorBoundary>
        </CardBody>
      </Card>
    </Grid>
  </Grid>;
}

EditFirmwareBundle.propTypes = {
  data: PropTypes.object.isRequired,
  fetchedData: PropTypes.object,
  readOnly: PropTypes.bool,
  customButtonHandler: PropTypes.func,
  refreshData: PropTypes.func,
  url: PropTypes.string
};

export default EditFirmwareBundle;