const sessionTimeoutModalStyle = (theme) => ({
  modal: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "25em"
    },
    [theme.breakpoints.down("md")]: {
      width: "auto"
    }
  },
  button: {
    textTransform: "none"
  },
  description: {
    color: theme.lsyPalette.primary.mainLight,
    display: "block",
    textAlign: "end"
  }
});

export default sessionTimeoutModalStyle;