import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/slc2Style";
const useStyles = makeStyles()(styles);

function SLC2(props) {
  const { classes, cx } = useStyles();
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width={props.width} height={props.height} viewBox="0 0 672 672" className={cx(classes.svg, (props.className || ""))} xmlSpace="preserve">

      <path className={cx(classes.slc2, (props.pathClassName || ""))} d="M446.8,129H224.2c-16.2,0-29.2,13.1-29.2,29.2v356c0,16.2,13.1,29.2,29.2,29.2h222.5
	c16.2,0,29.2-13.1,29.2-29.2v-356C476,142.1,462.9,129,446.8,129z M462,514.2c0,8.4-6.8,15.2-15.2,15.2H224.2
	c-8.4,0-15.2-6.8-15.2-15.2v-356c0-8.4,6.8-15.2,15.2-15.2h222.5c8.4,0,15.2,6.8,15.2,15.2V514.2z M509.4,118.9
	c-12.6-8.6-21.5-18.8-25.6-30c-5.1-14-17.9-32-38.7-32H223.4c-18.6,0-31.5,14.4-36.1,28.8c-4.6,14.5-13.5,25.4-24.3,33.1
	c-9.2,6.5-17,17.4-17,30.8V336v186.9c0,12.9,7.2,23.7,16.6,30.1l0,0c12.6,8.6,21.5,18.8,25.6,30v0c5.1,14,17.9,32,38.7,32h221.7
	c18.6,0,31.5-14.4,36.1-28.8v0c4.6-14.5,13.5-25.4,24.3-33.1c9.2-6.5,17-17.4,17-30.8V336V149.1C526,136.2,518.8,125.4,509.4,118.9z
	 M502,522.3c0,3.6-2.2,7.9-6.9,11.2c-14.5,10.3-26.9,25.4-33.2,45.3c-2.5,8-8.3,12.1-13.2,12.1H226.9c-5.4,0-12.3-5.5-16.1-16.1
	c-6.3-17.4-19.3-31.2-34.6-41.7c-4.1-2.8-6.2-6.7-6.2-10.3V336V149.7c0-3.6,2.2-7.9,6.9-11.2c14.5-10.3,26.9-25.4,33.2-45.3
	c2.5-8,8.3-12.1,13.2-12.1h221.7c5.4,0,12.3,5.5,16.1,16.1c6.3,17.4,19.3,31.2,34.6,41.7c4.1,2.8,6.2,6.7,6.2,10.3V336V522.3z"/>
    </svg>
  );
}

SLC2.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};
export default SLC2;
