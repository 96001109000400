import { useState } from "react";
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import { deepFreeze } from "_services/lockstasy/helper";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";

import DateTimeRange from "_components/Date/DateTimeRange";
import CustomModal from "_components/Modal/CustomModal";
import Placeholder from "_components/Helper/Placeholder";
import Skeleton from "@mui/material/Skeleton";
import ErrorBoundary from "_components/ErrorBoundary";

import { InfoOutlined, QueryStatsOutlined, GetApp } from "@mui/icons-material";

// styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/chartsStyle.js";

const useStyles = makeStyles()(styles);

const quickTimeOptions = deepFreeze([
  { name: "D", value: "day", quantity: 7, limitRange: 31, message: "description.availableOneMonthInterval" },
  { name: "W", value: "week", quantity: 31, limitRange: 180, message: "description.availableSixMonthsInterval" },
  { name: "M", value: "month", quantity: 12, limitRange: 366, message: "description.availableOneYearInterval" }
]);

const preSelectedDateOptions = deepFreeze([
  { name: "button.range.W", value: 1, unit: "week", message: "description.quickSelect1W" },
  { name: "button.range.M", value: 1, unit: "month", message: "description.quickSelect1M" },
  { name: "button.range.M", value: 3, unit: "month", message: "description.quickSelect3M" },
  { name: "button.range.ytd", message: "description.quickSelectYTD" }
]);

const exportOptions = Object.freeze([{
  name: "XLSX",
  value: true
},
{
  name: "CSV",
  value: false
}]);

function ChartsWidgetTemplate(props) {

  const { title, chartState, setChartState, getOptions, descriptionMessage, setExportModalOpen, onExportSubmit, getChartEvents, showGroupBy, showExport } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const { handleSubmit, setValue, errors, control, clearErrors } = useForm();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const changeRangeWhenInvalidLimit = (startDate, endDate) => {
    const currentRange = quickTimeOptions.find(option => option.value === chartState.filterVariables.group_by);
    const DateRangeInDays = endDate.diff(startDate, "days");
    let filterVar = {...chartState.filterVariables};

    if (showGroupBy && DateRangeInDays > currentRange.limitRange) {
      const newRange = quickTimeOptions.find(option => DateRangeInDays <= option.limitRange);
      filterVar = {
        ...filterVar,
        group_by: newRange.value,
        timeQuantity: newRange.quantity,
        client_timezone: chartState.filterVariables.client_timezone,
        start_date: startDate,
        end_date: endDate
      };
    }

    return filterVar;
  };

  const setDates = dates => {
    const startDate = dates[0].startOf("day");
    const endDate = dates[1].endOf("day");

    setChartState({
      filterVariables: {...changeRangeWhenInvalidLimit(startDate, endDate)},
      start_date: startDate,
      end_date: endDate
    });
  };

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const getExportForm = () => {
    let options = [{
      field: "xlsx",
      type: "select",
      label: t("label.exportFormat"),
      defaultValue: true,
      options: exportOptions
    }];

    options = options.concat([
      {
        field: "start_date",
        type: "datePicker",
        label: t("label.startDate"),
        defaultValue: chartState.start_date,
        disableFuture: true
      },
      {
        field: "end_date",
        type: "datePicker",
        label: t("label.endDate"),
        defaultValue: chartState.end_date
      }]);
  
    return options;
  };

  const handleRange = (timeQuantity, group_by) => {
    if (group_by === chartState.filterVariables.group_by) {
      return;
    }

    setChartState({
      filterVariables: {
        group_by: group_by,
        timeQuantity: timeQuantity,
        client_timezone: chartState.filterVariables.client_timezone,
        start_date: chartState.start_date,
        end_date: chartState.end_date
      }
    });
  };

  const DateTimeRangeForm = () => {
    return (
      <DateTimeRange 
        startDate={moment(chartState.start_date)}
        endDate={moment(chartState.end_date)}
        setDateRange={setDates}
        maxDate={moment().endOf("day").toDate()}
        showTime={false}
        label={" "}
        format="YYYY/MM/DD"
        inputProps={{variant: "standard", disableUnderline: true, style: {fontSize: theme.lsyPalette.subtitle3, margin: 0, marginTop: "auto", marginBottom: "auto", fontWeight: "300"}}}
      />
    );
  };

  const getDateRangeInDays = () => {
    const startDate = moment(chartState.start_date);
    const endDate = moment(chartState.end_date);

    return endDate.diff(startDate, "days");
  };

  return (
    <ErrorBoundary t={t}>
      <div data-testid="graph">
        <div className={classes.header}>
          <p className={classes.title} data-testid="title">
            <b>{title}</b>
            <InfoOutlined data-testid="infoIconId" className={classes.icon} onClick={openPopover}/>
          </p>
          <div className={classes.buttonHeader}>
            <Chip
              className={classes.chip}
              data-testid="date-picker"
              label={
                <FormControl>
                  <DateTimeRangeForm/>
                </FormControl>
              }
            />
            {showExport && <Chip
              label={
                <div className={classes.header}>
                  <GetApp className={classes.chipIcon}/>
                </div>}
              onClick={() => {setExportModalOpen(true);}}
              className={classes.chip}
            />}
          </div>
        </div>
        <Grid container alignItems="center" justifyContent="space-between" className={classes.buttons} >
          <Grid item xs={4}>
            <div className={classes.timePeriod} data-testid="timePeriod">
              {`${moment(chartState.start_date).format("MMM Do, YYYY")} - ${moment(chartState.end_date).format("MMM Do, YYYY")}`}
            </div>
          </Grid>
          <Grid item xs={8} className={classes.align}>
            <Chip
              label={
                <div>
                  {preSelectedDateOptions.map((toggleButtonProp, k) => {
                    const handlePreSelectedDates = () => {
                      const startDate = toggleButtonProp.value ?
                        moment().subtract(toggleButtonProp.value, toggleButtonProp.unit).startOf("day") :
                        moment().startOf("year");
                      const endDate = moment().endOf("day");

                      setDates([startDate, endDate]);
                    };

                    return (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={`${t(toggleButtonProp.message)}`}
                        key={k}
                      >
                        <span>
                          <ToggleButton 
                            data-testid="preSelectedDates"
                            className={cx(classes.toggleButton, classes.buttonInactive)}
                            value={toggleButtonProp.value || ""}
                            selected={chartState.filterVariables.group_by === toggleButtonProp.value}
                            onClick={handlePreSelectedDates}
                          >
                            {`${toggleButtonProp.value ? toggleButtonProp.value : ""}${t(toggleButtonProp.name)}`}
                          </ToggleButton>
                        </span>
                      </Tooltip>
                    );
                  })}
                </div>}
              className={classes.groupByChip}
            />
            <Chip
              label={
                <div>
                  {showGroupBy ? 
                    quickTimeOptions.map((toggleButtonProp, k) => { 
                      return (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title={`${t("label.displayBy")} ${t("button.range." + toggleButtonProp.value)}. ${t(toggleButtonProp.message)}`}
                          key={k}
                        >
                          <span>
                            <ToggleButton 
                              data-testid="groupByButton"
                              className={cx(classes.toggleButton, { [classes.buttonActive]: chartState.filterVariables.group_by === toggleButtonProp.value, [classes.buttonInactive]: chartState.filterVariables.group_by !== toggleButtonProp.value })}
                              value={toggleButtonProp.value}
                              selected={chartState.filterVariables.group_by === toggleButtonProp.value}
                              onClick={() => handleRange(toggleButtonProp.quantity, toggleButtonProp.value)}
                              disabled={getDateRangeInDays() > toggleButtonProp.limitRange}
                            >
                              {t(`button.range.${toggleButtonProp.name}`)}
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      );
                    }) : null}
                </div>}
              className={classes.groupByChip}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div data-testid="chart" className={classes.chart}>
          {chartState.loading ? <Skeleton className={classes.skeleton} /> : 
            !isEmpty(chartState.data) ? 
              <ErrorBoundary t={t}>
                <ReactECharts
                  option={getOptions()}
                  notMerge={true}
                  lazyUpdate={true}
                  onEvents={getChartEvents()}
                  className={classes.chart}
                />
              </ErrorBoundary> : 
              <div className={classes.placeholder}>
                <Placeholder
                  icon={<QueryStatsOutlined/>}
                  classNameMessage={classes.placeholderText}
                  classNameIcon={classes.placeholderIcon}
                >
                  <Typography className={classes.placeholderText}>{t("fallbacks.noChartData")}</Typography>
                  {chartState.failedFetchMessage && <Typography>{chartState.failedFetchMessage}</Typography>}
                </Placeholder>
              </div>
          }
        </div>
        <Popover
          className={classes.modal}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <Typography data-testid="descriptionId" className={classes.popoverText}>
            {descriptionMessage}
          </Typography>
        </Popover>
        <CustomModal
          open={chartState.exportModalOpen}
          setOpen={setExportModalOpen}
          handleSubmit={handleSubmit(onExportSubmit)}
          title={t("label.exportChartData")}
          type="formCreator"
          modalStyle={classes.modal}
          confirm={t("actions.export")}
          cancel={t("button.cancel")}
          submit
          manualClose
          errors={errors}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          formOptions={getExportForm()}
        />
      </div>
    </ErrorBoundary> 
  );
}

ChartsWidgetTemplate.defaultProps = {
  getChartEvents: () => {},
  showExport: false,
  setExportModalOpen: () => {},
  onExportSubmit: () => {}
};

ChartsWidgetTemplate.propTypes = {
  title: PropTypes.string,
  chartState: PropTypes.object.isRequired,
  setChartState: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  descriptionMessage: PropTypes.string.isRequired,
  setExportModalOpen: PropTypes.func,
  onExportSubmit: PropTypes.func,
  getChartEvents: PropTypes.func,
  showGroupBy: PropTypes.bool,
  showExport: PropTypes.bool
};

export default ChartsWidgetTemplate;
