/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useCallback, useContext, Suspense, useReducer, lazy, Fragment } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { capitalize, get } from "lodash";

import { getAllFavouteLocks, updateFavouriteLocks } from "__indexedDB/controllers/favouritesController";

// @mui/material components
import {
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip
} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import RegularButton from "_components/Button/RegularButton";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomAlert from "_components/Alert/CustomAlert.js";
import Collapsable from "_components/Collapsabale/Collapsable";
import AutocompleteFetchSelect from "_components/Select/AutocompleteFetchSelect";
import SimpleSelect from "_components/Select/SimpleSelect";
import EditLockLicense from "./EditLockLicense";
import LoadingPlaceHolder from "_components/Loading";
import CustomModal from "_components/Modal/CustomModal";
import ErrorBoundary from "_components/ErrorBoundary";

import { lockService, organizationService } from "_services/admin";
import {
  convertToHex,
  convertToInt,
  findLockErrorString,
  isBlank
} from "_helpers";
import { getLockStatusNameSera4tal } from "_helpers/lock";
import {
  HardwareTypeOptions,
  hardwareTypeByCode,
  LockConfigOptions,
  LockTypeOptions,
  noBatteryType,
  shackleTypes,
  defaultShackle
} from "_constants/lock.constants";
import { config } from "_configs/server-config";
import { fetchErrors } from "_utils";
import { alertActions } from "_actions";
import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData.js";
import { SlideMenuContext } from "_components/AnimatedMenu/SlideMenu.js";
import { simpleReducer } from "_reducers/general.reducer";
import moment from "moment";

//icons
import {
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
  Lock as LockIcon,
  MonitorHeart as MonitorHeartIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon
} from "@mui/icons-material";

import LockIcons from "assets/teleporte/LockIcons";

// License
import { getTenantLicenseName } from "_constants/admin/tenant.constants";
import { tenantService } from "_services/admin/tenant.service";

// FSU
import { FSU_24_HOURS, FSU_4_HOURS } from "_components/FSU/Code";
const FsuComponent = lazy(() => import("_components/FSU/Code"));

// Skeleton
import Skeleton from "@mui/material/Skeleton";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/locks/editLockStyle";

const useStyles = makeStyles()(styles);

// this is SUPER rarely used
const QRComponent = lazy(() => import("_components/QR/Code"));

export default function EditLock({ data, fetchedData, readOnly = false, refreshData }) {
  const currentMembership = useSelector((state) => state?.memberships?.currentMembership);
  const appDataContext = useContext(AppAdminDataContext);
  const cryptoList = appDataContext.getTwsResource("cryptos").fetchAllFromCache().data;
  const organizationResource = appDataContext.getTwsResource("organizations");
  const batteryTypes = appDataContext.getTwsResource("batteryTypes").fetchAllFromCache().data;

  const { classes } = useStyles();
  const history = useHistory();
  const editFormHandle = useRef(null);
  const transferFormHandle = useRef(null);
  const batteryTypeFormHandle = useRef(null);
  const shackleTypeFormHandle = useRef(null);
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  // some folks want to pass in HEX, argh
  if (data.lock_id && /[a-f]/i.test(data.lock_id)) {
    data.lock_id = convertToInt(data.lock_id);
  }

  const lockId = data["lock_id"];
  const [originalLockData, setOriginalLockData] = useState(fetchedData);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  // either move or edit
  const [modifyMode, setModifyMode] = useState(false);
  const [modifyTenantOwner, setModifyTenantOwner] = useState(false);
  const [modifyBatteryType, setModifyBatteryType] = useState(false);
  const [modifyShackleType, setModifyShackleType] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showFSUCode, setShowFSUCode] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [containerName, setContainerName] = useState(null);
  const [ilsOpen, setIlsOpen] = useState(false);
  const [otaOptions, setOtaOptions] = useState([]);

  const [tenantLicense, setTenantLicense] = useState("");
  const [tenantOwner, setTenantOwner] = useState();

  const [customSetup, setCustomSetup] = useState(false);
  const [transferCustomSetupOrg, setTransferCustomSetupOrg] = useState(false);
  const [transferCustomSetupTap, setTransferCustomSetupTap] = useState(false);

  const [lockConfigurations, lockConfigurationsDispatch] = useReducer(simpleReducer, {});
  const [lockTransfer, lockTransferDispatch] = useReducer(simpleReducer, {});
  const [batteryType, setBatteryType] = useState({});
  const [shackleType, setShackleType] = useState({});

  const [editForm, editFormDispatch] = useReducer(simpleReducer, {});
  const [transferForm, transferFormDispatch] = useReducer(simpleReducer, {});

  const [ isFavourite, setIsFavourite ] = useState(false);

  const dispatch = useDispatch();
  const menuCtx = useContext(SlideMenuContext);

  const modalProps = {
    title: "ILS Reset Procedure",
    message: (
      <div>
        ILS is done to ENSURE we do not have more than 1 lock with the same HWID.<br />
        <div style={{ marginTop: "1em" }}>
          When resetting the status you <strong><u>MUST</u></strong> ensure:<br />

          <strong>1.</strong> You have the correct hardware in hand
          <ul>
            <li>VISUALLY VERIFY the sticker if it is on a remote site</li>
          </ul>

          <strong>2.</strong> Understand the reason ILS needs to be reset:<br />
          <ul>
            <li>Has ILS failed on this device before?</li>
          </ul>

          <strong>3.</strong> Check if another lock has claimed this HWID<br />
          <ul>
            <li>Use LockFinder to review dates and hardware address for previous ILS completion</li>
          </ul>
        </div>
      </div>
    ),
    customButtonHandler: async () => {
      setIlsOpen(false);
      try {
        await lockService.resetILS(lockId);
        dispatch(alertActions.send("Lock Deprovisioned"));
        menuCtx.closeMenu();
      } catch (e) {
        let statusMessage;
        switch (e.response.status) {
          case 404:
            statusMessage = "Lock Not Found on LockDepot";
            break;
          case 422:
            statusMessage = "Error With Information Given to LockDepot";
            break;
          default:
            statusMessage = "Lock failed to deprovision";
        }
        console.debug(e.response.status);
        dispatch(alertActions.send(statusMessage, "error"));
      }
      return true;
    }
  };

  const getTenantByIdFromCache = (id) => {
    return organizationResource.fetchAllFromCache().data.find(c => c.id === id) || {};
  };

  const getCryptoByIdFromCache = (id) => {
    return cryptoList.find(c => c.id === id) || {};
  };

  const getBatteryTypeByIdFromCache = (id) => {
    return batteryTypes.find(c => c.id === id) || {};
  };

  useEffect(() => {
    const getLock = async (fd) => {
      try {
        if (fd === undefined)
          fd = await lockService.fetchLock(lockId);

        const allFavoriteLocks = await getAllFavouteLocks(currentMembership.id);
        setIsFavourite(allFavoriteLocks.includes(lockId));

        if (fd) {
          // Format shackle type
          if(fd?.shackle)
            fd.shackle = { name: capitalize(fd.shackle), value: fd.shackle };

          // Store original data from the server
          // Will be used later to determine actual changes
          // and reset the form to original state
          setOriginalLockData(fd);

          // Fill in the edit form with lock data
          Object.keys(fd).forEach(field => {
            editFormDispatch({ field, value: fd[field] });
          });

          let defaultShackleCopy = {
            name: defaultShackle.name,
            value: defaultShackle.value
          };
          if(!fd?.shackle && fd?.type === LockTypeOptions[1].id) {
            defaultShackleCopy.name = shackleTypes[0].name;
            defaultShackleCopy.value = shackleTypes[0].value;
          }

          // Fill in the transfer form with lock data
          const cachedTenant = getTenantByIdFromCache(fd.tenant_id);
          const cachedCrypto = getCryptoByIdFromCache(fd.crypto_next_id);
          const cachedTenantOwner = getTenantByIdFromCache(fd?.tenant_owner_id);
          const cachedBatteryType = getBatteryTypeByIdFromCache(fd?.battery_type_id);
          setTransferCustomSetupTap(cachedTenantOwner.custom_setup ? true : false);
          setTransferCustomSetupOrg(cachedTenant.custom_setup ? true : false);
          transferFormDispatch({field: "include_license", value: false});
          transferFormDispatch({ field: "tenant", value: cachedTenant });
          transferFormDispatch({ field: "crypto", value: cachedCrypto });
          transferFormDispatch({ field: "tenant_owner", value: cachedTenantOwner });
          lockTransferDispatch({field: "include_license", value: false});
          lockTransferDispatch({ field: "tenant_id", value: cachedTenant.id });
          lockTransferDispatch({ field: "crypto_next_id", value: cachedCrypto.id });
          lockTransferDispatch({ field: "tenant_owner_id", value: cachedTenantOwner.id });
          setBatteryType(Object.keys(cachedBatteryType).length > 1 ? cachedBatteryType : noBatteryType);
          setShackleType(fd?.shackle || defaultShackleCopy);
          setLoading(false);
        }
      } catch (e) {
        if (get(e, "response.status") === 404) {
          menuCtx.closeMenu();
          dispatch(alertActions.send("Lock does not exist", "error"));
        }
        console.warn(e);
      }
    };

    getLock(fetchedData);

    return () => {
      setModifyMode(false);
    };
  }, [lockId, fetchedData, appDataContext.organizationList, dispatch, menuCtx]);

  const getContainerName = useCallback(async () => {
    if (originalLockData === undefined) {
      return;
    }

    try {
      let result = await organizationService.fetchGlobalOrganization(originalLockData.tenant_id, { include: ["server_alias"] });
      if (result) {
        const server_name = get(result, "server_alias.server.endpoint", "")
          .replace(/^https*:\/\//, "")
          .replace(/:\d+$/, "")
          .replace(/\.\S+/, "");
        setContainerName(server_name);
      }
    } catch (e) {
      console.warn(e);
    }
  });

  const getOTAOptions = useCallback(async () => {
    try {
      let result = await lockService.fetchOTAOptions(lockId);
      if (result) {
        setOtaOptions(result.map((option) => {
          return { id: option.id, name: option.version };
        }));
      }
    } catch (e) {
      console.warn(e);
    }
  }, [lockId]);

  useEffect(() => {
    getContainerName();
  }, [getContainerName]);

  useEffect(() => {
    getOTAOptions();
  }, [getOTAOptions]);


  const resetLockConfiguration = (field) => {
    lockConfigurationsDispatch({ field, reset: true });
    setUpdateCounter(updateCounter + 1);
  };

  const updateLockConfiguration = (event) => {
    const {name: field, value: v} = event.target;
    let value = v;
    if (field === "open_delay" || field === "configuration") {
      if (!value || isNaN(value)) {
        resetLockConfiguration("configuration");
        return;
      } else {
        value = parseInt(v);
      }
    }

    if (originalLockData[field] !== value) {
      lockConfigurationsDispatch({ field, value });
    } else {
      lockConfigurationsDispatch({ field, reset: true });
    }

    editFormDispatch({ field, value });
    setUpdateCounter(updateCounter + 1);
  };

  const updateLockTransfer = (field, value) => {
    if (originalLockData[field] !== value) {
      lockTransferDispatch({ field, value });
    }

    if (field === "tenant_id") {
      const t = getTenantByIdFromCache(value);
      transferFormDispatch({ field: "tenant", value: t });
      transferFormDispatch({ field: "crypto", value: null });
      setTransferCustomSetupOrg(t.custom_setup ? true : false);
    } else if (field === "crypto_next_id") {
      const c = getCryptoByIdFromCache(value);
      transferFormDispatch({ field: "crypto", value: c });
    } else if (field === "tenant_owner_id") {
      const to = getTenantByIdFromCache(value);
      setTransferCustomSetupTap(to.custom_setup ? true : false);
      transferFormDispatch({ field: "tenant_owner", value: to });
    } else if(field === "include_license") {
      transferFormDispatch({ field: "include_license", value });
    }
    setUpdateCounter(updateCounter + 1);
  };

  const getDefaultShackleType = () => {
    let defaultShackleCopy = {
      name: defaultShackle.name
    };
    if(!originalLockData?.shackle && originalLockData?.type === LockTypeOptions[1].id) {
      defaultShackleCopy.name = shackleTypes[0].name;
    }

    return defaultShackleCopy;
  };

  const resetEditForm = () => {
    Object.keys(lockConfigurations).forEach(field => {
      lockConfigurationsDispatch({ field, reset: true });
      editFormDispatch({ field, value: Object.keys(originalLockData).includes(field) ? originalLockData[field] : "" });
    });
    setUpdateCounter(0);
    editFormHandle.current.reset();
  };

  const resetBatteryForm = () => {
    setBatteryType(originalLockData?.battery_type || noBatteryType);
    batteryTypeFormHandle.current.reset();
  };

  const resetShackleTypeForm = () => {
    setShackleType(originalLockData?.shackle || getDefaultShackleType());
    shackleTypeFormHandle.current.reset();
  };

  const resetTransferForm = () => {
    Object.keys(lockTransfer).forEach(field => {
      lockTransferDispatch({ field, reset: true });
    });
    // Fill in the transfer form with lock data
    const cachedTenant = getTenantByIdFromCache(originalLockData.tenant_id);
    const cachedCrypto = getCryptoByIdFromCache(originalLockData.crypto_next_id);
    const cachedTenantOwner = getTenantByIdFromCache(originalLockData?.tenant_owner_id);
    transferFormDispatch({ field: "tenant", value: cachedTenant });
    transferFormDispatch({ field: "crypto", value: cachedCrypto });
    transferFormDispatch({ field: "tenant_owner", value: cachedTenantOwner });
    transferFormDispatch({ field: "include_license", value: false });
    setUpdateCounter(0);
    setTransferCustomSetupOrg(cachedTenant.custom_setup ? true : false);
    setTransferCustomSetupTap(cachedTenantOwner.custom_setup ? true : false);
    transferFormHandle.current.reset();
  };

  const hasChangedConfiguration = (field) => {
    return typeof lockConfigurations[field] !== "undefined";
  };

  const hasChangedTransfer = (field) => {
    return lockTransfer[field] !== originalLockData[field];
  };

  const hasChangedBatteryType = () => {
    if(!batteryType?.id || originalLockData?.battery_type_id)
      return false;
    return batteryType.id !== originalLockData.battery_type_id;
  };

  const hasShackleTypeChanged = () => {
    if(!originalLockData?.shackle)
      return shackleType?.name !== getDefaultShackleType().name;

    return shackleType?.name !== originalLockData?.shackle;
  };

  const batteryFormIsDirty = () => {
    return Object.keys(batteryType).length > 0 && batteryType.id !== -1;
  };

  const shackleTypeFormIsDirty = () => {
    return Object.keys(shackleType).length > 0;
  };

  const editFormIsDirty = () => {
    return Object.keys(lockConfigurations).length > 0;
  };

  const transferFormIsDirty = () => {
    return Object.keys(lockTransfer).length > 0;
  };

  const updateLock = async (data, successMessage, resetForm) => {
    setSubmitting(true);
    try {
      const result = await lockService.updateLock(lockId, data);

      if(result) {
        resetForm();
        menuCtx.closeMenu();
        dispatch(alertActions.send(successMessage));
        refreshData();
      }
    } catch (e) {
      console.warn(e.response?.data);
      const errors = fetchErrors(e);
      const keys = Object.keys(errors);
      if (keys.includes("battery_type_id")) {
        dispatch(alertActions.send("Error Updating Battery Type: Invalid battery type ID", "error"));
      } else {
        dispatch(alertActions.send(`Error Updating Lock: ${e.message}`, "error"));
      }
    }
    setSubmitting(false);
  };

  const onShackleTypeSubmit = async () => {
    updateLock({shackle: shackleType.value}, "Shackle type successfully changed", resetShackleTypeForm);
  };

  const onBatteryTypeSubmit = async () => {
    updateLock({battery_type_id: batteryType.id}, "Battery type successfully changed", resetBatteryForm);
  };

  const onMoveSubmit = async ({ isRemovingTAP }) => {
    setSubmitting(true);
    try {
      let result;
      if (modifyMode) {
        result = await lockService.transferLock(lockId, lockTransfer);
      } else if (modifyTenantOwner) {
        result = await lockService.updateLock(lockId, isRemovingTAP ? { tenant_owner_id: "" } : lockTransfer);
      } else {
        result = await lockService.transferLock(lockId, lockTransfer);
      }
      if (result) {
        resetTransferForm();
        menuCtx.closeMenu();
        dispatch(alertActions.send("Transfer request accepted and is being processed"));
        refreshData();
      }
    } catch (e) {
      console.warn(e.response.data);
      const errors = fetchErrors(e);
      const keys = Object.keys(errors);

      if (keys.includes("semaphores_not_unique")) {
        dispatch(alertActions.send("The lock ids provided are already queued for processing", "error"));
      } else if (keys.includes("crypto_current_id") && errors["crypto_current_id"] === "no_ownership_found") {
        dispatch(alertActions.send("The current crypto on the lock is not owned by the new organization.", "error"));
      } else if (keys.includes("crypto_next_id")) {
        if (errors["crypto_next_id"] === "no_ownership_found") {
          dispatch(alertActions.send("The next crypto on lock is not owned by the new organization.", "error"));
        }
      } else if (keys.includes("lock")) {
        if (errors["lock"] === "pending_key_rotation") {
          dispatch(alertActions.send("This lock has a pending key rotation.", "error"));
        } else {
          dispatch(alertActions.send("There was a lock related error.", "error"));
        }
      } else {
        dispatch(alertActions.send(`Error Transferring Lock: ${e.message}`, "error"));
      }
    }
    setSubmitting(false);
  };

  const onSubmit = async () => {
    setSubmitting(true);

    try {
      let result = await lockService.updateLock(lockId, lockConfigurations);
      if (result) {
        resetEditForm();
        menuCtx.closeMenu();
        dispatch(alertActions.send("Lock Successfully Updated"));
        refreshData();
      }
    } catch (e) {
      let warningMessage = findLockErrorString(e, {
        resourceName: "License"
      });

      if (isBlank(warningMessage)) {
        dispatch(alertActions.send(`Error Updating Lock: ${e.message}`, "error"));
      } else {
        dispatch(alertActions.send(warningMessage, "error"));
      }
    }

    setSubmitting(false);
  };

  const showRtmInfo = async () => {
    const rtm = { id: originalLockData.rtm_board.id };
    menuCtx.updateMenuData({ menuType: "rtmShow", data: { rtm } });
  };

  const showLoadingSymbol = () => {
    return (<LoadingPlaceHolder title="Loading Lock Information" />);
  };

  const showTenantName = () => {
    const tenant = getTenantByIdFromCache(originalLockData.tenant_id);

    return (
      <Fragment>
        <b>Organization:</b>&nbsp;
        <Tooltip title={tenant.id} aria-label={tenant.id}><span>{tenant ? tenant.name : null}</span></Tooltip>
        {!readOnly ?
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Move Lock">
            <EditIcon className={classes.editIcon} fontSize={"small"} onClick={() => setModifyMode(true)}/>
          </Tooltip> : null}
      </Fragment>
    );
  };

  const selectableEditCryptoList = () => {
    // Only present crypto options that match the same tenant
    // as the lock to prevent the user sending bad requests
    // that will result in errors down the road anyways
    return cryptoList.filter(c => {
      const { crypto_ownerships } = c;
      return crypto_ownerships && crypto_ownerships.find(co => co.tenant_id === originalLockData.tenant_id);
    });
  };

  const selectableTransferCryptoList = () => {
    // Only present crypto options that match the same tenant
    // as the lock to prevent the user sending bad requests
    // that will result in errors down the road anyways
    return cryptoList.filter(c => {
      const { crypto_ownerships } = c;
      const tenant_id = transferForm.tenant?.id ?? originalLockData.tenant_id;
      return crypto_ownerships && crypto_ownerships.find(co => co.tenant_id === tenant_id);
    });
  };

  const showResetILSModal = async () => {
    setIlsOpen(true);
  };
  
  const ResetILSButton = () => {
    return (
      <RegularButton onClick={() => showResetILSModal()}>
        { "Reset ILS"}
      </RegularButton>
    );
  };

  const QRCodeButton = () => {
    if (showQRCode) {
      return (
        <Suspense fallback={<LoadingPlaceHolder title="Loading QR Data" />}>
          <QRComponent lockId={lockId} />
        </Suspense>
      );
    } else {
      return (
        <RegularButton
          onClick={() => setShowQRCode(true)}
        >
          { "Generate QR Code"}
        </RegularButton>
      );
    }
  };

  const FsuCodeButton = () => {
    if ([FSU_24_HOURS,FSU_4_HOURS].includes(editForm.fsu_mode)) {
      if (showFSUCode) {
        return (<FsuComponent lockId={lockId} fsuMode={editForm.fsu_mode} />);
      } else {
        return (
          <RegularButton onClick={() => setShowFSUCode(true)}>
            {"Issue Access Code (FSU)"}
          </RegularButton>
        );
      }
    } else {
      return (<h4 style={{ textAlign: "center" }}> Invalid FSU Mode </h4>);
    }
  };

  const showEditForm = () => {
    return (
      <Fragment key={"lockEdit-config" + originalLockData.id}>
        <form onSubmit={handleSubmit(onSubmit)} ref={editFormHandle}>
          <Collapsable title="Basic Configurations" collapsed={false} padBottom={true}>
            <GridContainer>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Hardware Type"
                  options={HardwareTypeOptions}
                  selectedValue={editForm.hardware_type}
                  highlighted={hasChangedConfiguration("hardware_type")}
                  name="hardware_type"
                  onChange={updateLockConfiguration}
                >
                </SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Lock Type"
                  options={LockTypeOptions}
                  selectedValue={editForm.type}
                  highlighted={hasChangedConfiguration("type")}
                  name="type"
                  onChange={updateLockConfiguration}
                >
                </SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="configuration"
                  labelText="Configuration"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: editForm.configuration,
                    name: "configuration",
                    ...register("configuration", { required: true, pattern: /^\s*\d+\s*$/ }),
                    disabled: readOnly,
                    classes: { select: classes.select },
                    autoComplete: "off",
                    onChange: updateLockConfiguration
                  }}
                  name="configuration"
                  highlighted={hasChangedConfiguration("configuration")}
                  error={!!errors.configuration}
                />
              </GridItem>
            </GridContainer>
            <GridContainer alignItems="center" className={classes.modifyForm}>
              <GridItem xs={6}>
                <CustomInput
                  id="firmware_version_current"
                  labelText="Current Version"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: editForm.firmware_current?.version,
                    name: "firmware_current_id",
                    ...register("firmware_current_id", { required: false, pattern: /^(\d+[.]{0,1}\d*|\d+[.]\d{1,3}[.]\d{1,3})$/ }),
                    disabled: true,
                    classes: { select: classes.select },
                    autoComplete: "off"
                  }}
                  highlighted={hasChangedConfiguration("firmware_current_id")}
                  name="firmware_current_id"
                  onChange={updateLockConfiguration}
                  error={!!errors.firmware_version_current}
                />
              </GridItem>
              <GridItem xs={6} className={classes.nextVersion}>
                {otaOptions.length > 0 ? <SimpleSelect
                  title="Next Version"
                  options={otaOptions || []}
                  selectedValue={editForm.firmware_next_id || ""}
                  highlighted={hasChangedConfiguration("firmware_next_id")}
                  name="firmware_next_id"
                  onChange={updateLockConfiguration}
                >
                </SimpleSelect> : <p className={classes.noUpgrade}>No upgrade available.</p>}
              </GridItem>
            </GridContainer>
          </Collapsable>
          <Collapsable title="OCC Configurations" collapsed={true} padBottom={true}>
            <GridContainer>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Current OCC"
                  options={selectableEditCryptoList()}
                  selectedValue={editForm.crypto_current_id}
                  disabled={readOnly}
                  highlighted={hasChangedConfiguration("crypto_current_id")}
                  name="crypto_current_id"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Next OCC"
                  options={selectableEditCryptoList()}
                  selectedValue={editForm.crypto_next_id}
                  disabled={readOnly}
                  highlighted={hasChangedConfiguration("crypto_next_id")}
                  name="crypto_next_id"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
          </Collapsable>
          <Collapsable title="Advanced Configurations" collapsed={true} padBottom={true}>
            <GridContainer>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Sleep Advertising"
                  options={LockConfigOptions["sleep_advertising"]}
                  highlighted={hasChangedConfiguration("sleep_advertising")}
                  selectedValue={editForm.sleep_advertising}
                  disabled={readOnly}
                  name="sleep_advertising"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Battery Charging"
                  options={LockConfigOptions["charge_mode"]}
                  selectedValue={editForm.charge_mode}
                  highlighted={hasChangedConfiguration("charge_mode")}
                  disabled={readOnly}
                  name="charge_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Maintenance Mode"
                  options={LockConfigOptions["maintenance_mode"]}
                  highlighted={hasChangedConfiguration("maintenance_mode")}
                  selectedValue={editForm.maintenance_mode}
                  disabled={readOnly}
                  name="maintenance_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Lock Sensor Type"
                  options={LockConfigOptions["lock_sensor_type"]}
                  highlighted={hasChangedConfiguration("lock_sensor_type")}
                  selectedValue={editForm.lock_sensor_type}
                  disabled={readOnly}
                  name="lock_sensor_type"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Door Sensor Type"
                  options={LockConfigOptions["door_sensor_type"]}
                  highlighted={hasChangedConfiguration("door_sensor_type")}
                  selectedValue={editForm.door_sensor_type}
                  disabled={readOnly}
                  name="door_sensor_type"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Relatch Time (Seconds)"
                  options={LockConfigOptions["relatch_time"]}
                  highlighted={hasChangedConfiguration("relatch_time")}
                  selectedValue={editForm.relatch_time}
                  disabled={readOnly}
                  name="relatch_time"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Advertising Interval"
                  options={LockConfigOptions["ble_advertising_interval"]}
                  highlighted={hasChangedConfiguration("ble_advertising_interval")}
                  selectedValue={editForm.ble_advertising_interval}
                  disabled={readOnly}
                  name="ble_advertising_interval"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="TX Power"
                  options={LockConfigOptions["ble_tx_power"]}
                  highlighted={hasChangedConfiguration("ble_tx_power")}
                  selectedValue={editForm.ble_tx_power}
                  disabled={readOnly}
                  name="ble_tx_power"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="RTM Interface"
                  options={LockConfigOptions["rtm_interface"]}
                  highlighted={hasChangedConfiguration("rtm_interface")}
                  selectedValue={editForm.rtm_interface}
                  disabled={readOnly}
                  name="rtm_interface"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Button Mode"
                  options={LockConfigOptions["gpio_mode"]}
                  highlighted={hasChangedConfiguration("gpio_mode")}
                  selectedValue={editForm.gpio_mode}
                  disabled={readOnly}
                  name="gpio_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Debug Mode"
                  options={LockConfigOptions["debug_mode"]}
                  highlighted={hasChangedConfiguration("debug_mode")}
                  selectedValue={editForm.debug_mode}
                  disabled={readOnly}
                  name="debug_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={4}>
                <SimpleSelect
                  title="Power Mode"
                  options={LockConfigOptions["power_mode"]}
                  highlighted={hasChangedConfiguration("power_mode")}
                  selectedValue={editForm.power_mode}
                  disabled={readOnly}
                  name="power_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Lock Control Green"
                  options={LockConfigOptions["lock_control_green"]}
                  highlighted={hasChangedConfiguration("lock_control_green")}
                  selectedValue={editForm.lock_control_green}
                  disabled={readOnly}
                  name="lock_control_green"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Lock Control Orange"
                  options={LockConfigOptions["lock_control_orange"]}
                  highlighted={hasChangedConfiguration("lock_control_orange")}
                  selectedValue={editForm.lock_control_orange}
                  disabled={readOnly}
                  name="lock_control_orange"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
          </Collapsable>
          <Collapsable title="Licensed Based Configurations" collapsed={true}>
            <GridContainer>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Multi-Authentication Unlock"
                  options={LockConfigOptions["auth_mode"]}
                  highlighted={hasChangedConfiguration("auth_mode")}
                  selectedValue={editForm.auth_mode}
                  labelStyle={classes.wideSelect}
                  disabled={readOnly}
                  name="auth_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <SimpleSelect
                  title="FSU Mode"
                  options={LockConfigOptions["fsu_mode"]}
                  highlighted={hasChangedConfiguration("fsu_mode")}
                  selectedValue={editForm.fsu_mode}
                  disabled={readOnly}
                  name="fsu_mode"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="open_delay"
                  labelText="Delayed Entry (seconds)"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: editForm.open_delay,
                    name: "open_delay",
                    ...register("open_delay", { required: true, pattern: /^\s*\d+\s*$/ }),
                    disabled: readOnly,
                    classes: { select: classes.select },
                    autoComplete: "off",
                    onChange: updateLockConfiguration
                  }}
                  name="open_delay"
                  highlighted={hasChangedConfiguration("open_delay")}
                  error={!!errors.open_delay}
                />
              </GridItem>
              <GridItem xs={6}>
                <SimpleSelect
                  title="Connected Latch"
                  options={LockConfigOptions["unlatch_by_default"]}
                  selectedValue={editForm.unlatch_by_default}
                  highlighted={hasChangedConfiguration("unlatch_by_default")}
                  disabled={readOnly}
                  name="unlatch_by_default"
                  onChange={updateLockConfiguration}
                ></SimpleSelect>
              </GridItem>
            </GridContainer>
          </Collapsable>
          <GridContainer direction="row" justifyContent="flex-end" style={{ marginTop: "20px" }}>
            <GridItem>
              {(readOnly || !editFormIsDirty()) ? (null) : (
                <RegularButton
                  onClick={() => resetEditForm()}
                >
                  { "Reset"}
                </RegularButton>
              )}
            </GridItem>
            <GridItem>
              {readOnly ? (null) : (
                <RegularButton
                  disabled={!editFormIsDirty()}
                  type="submit"
                >
                  { submitting ? (<LoadingPlaceHolder height={6} />) : "Apply Changes"}
                </RegularButton>
              )}
            </GridItem>
          </GridContainer>
        </form>
        <EditLockLicense lockId={lockId} submitHandler={setSubmitting} readOnly={readOnly} refreshData={refreshData} />
        <hr></hr>
        <GridContainer justifyContent="center">
          <GridItem style={{ textAlign: "center" }}>
            {QRCodeButton()} {ResetILSButton()}
          </GridItem>
        </GridContainer>
        <hr></hr>
        <GridContainer justifyContent="center">
          <GridItem style={{ textAlign: "center" }}>
            {FsuCodeButton()}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  };

  const showShackleTypeForm = () => {
    return <form onSubmit={handleSubmit(onShackleTypeSubmit)} ref={shackleTypeFormHandle}>
      <GridContainer>
        <GridItem xs={12}>
          <AutocompleteFetchSelect
            title="Shackle Type"
            dataSource={shackleTypes}
            selectedValue={shackleType || shackleTypes[0]}
            value={shackleType || shackleTypes[0]}
            onChange={(event, value) => {
              setShackleType({name: value.name, value: value.name.toLowerCase()});
            }}
            highlighted={hasShackleTypeChanged()}
            outlined={true}
            autoHighlight={true}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.modifyForm} direction="row" justifyContent="center">
        <GridItem>
          {readOnly ? (null) : (
            <RegularButton
              onClick={() => { resetShackleTypeForm(); setModifyShackleType(false); }}
            >
                Cancel
            </RegularButton>
          )}
        </GridItem>
        <GridItem>
          {readOnly ? (null) : (
            <RegularButton
              disabled={!shackleTypeFormIsDirty()}
              type="submit"
            >
              { submitting ? (<LoadingPlaceHolder height={6} />) : "Change Shackle Type"}
            </RegularButton>
          )}
        </GridItem>
      </GridContainer>
    </form>;
  };

  const showBatteryTypeForm = () => {
    return <form onSubmit={handleSubmit(onBatteryTypeSubmit)} ref={batteryTypeFormHandle}>
      <GridContainer>
        <GridItem xs={12}>
          <AutocompleteFetchSelect
            title="Battery Type"
            dataSource={batteryTypes}
            selectedValue={batteryType || noBatteryType}
            value={batteryType || noBatteryType}
            onChange={(event, value) => setBatteryType({name: value.name, id: value.id})}
            highlighted={hasChangedBatteryType()}
            outlined={true}
            autoHighlight={true}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.modifyForm} direction="row" justifyContent="center">
        <GridItem>
          {readOnly ? (null) : (
            <RegularButton
              onClick={() => { resetBatteryForm(); setModifyBatteryType(false); }}
            >
                Cancel
            </RegularButton>
          )}
        </GridItem>
        <GridItem>
          {readOnly ? (null) : (
            <RegularButton
              disabled={!batteryFormIsDirty()}
              type="submit"
            >
              { submitting ? (<LoadingPlaceHolder height={6} />) : "Change Battery Type"}
            </RegularButton>
          )}
        </GridItem>
      </GridContainer>
    </form>;
  };

  const removeTAP = () => {
    onMoveSubmit({ isRemovingTAP: true });
  };

  const showTenantOwnerForm = () => {
    return (
      <form onSubmit={handleSubmit(onMoveSubmit)} ref={transferFormHandle}>
        <GridContainer>
          <GridItem xs={12}>
            <AutocompleteFetchSelect
              id="selectTransferTenantOwner"
              title="TAP Organization"
              selectedValue={transferForm.tenant_owner}
              resourceName="organizationList"
              onChange={(event, value) => {
                updateLockTransfer("tenant_owner_id", value.id);
              }}
              highlighted={hasChangedTransfer("tenant_owner_id")}
              outlined={true}
              autoHighlight={true}
            />
          </GridItem>
        </GridContainer>
        {transferCustomSetupTap ? (
          <GridItem className={classes.alertWrapper}>
            <CustomAlert type="CustomConfigurationOrg"></CustomAlert>
          </GridItem>
        ) : null}
        <GridContainer className={classes.modifyForm} direction="row" justifyContent="center">
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                onClick={() => { resetTransferForm(); setModifyTenantOwner(false); }}
              >
                Cancel
              </RegularButton>
            )}
          </GridItem>
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                disabled={!transferFormIsDirty()}
                type="submit"
              >
                { submitting ? (<LoadingPlaceHolder height={6} />) : "Change Organization"}
              </RegularButton>
            )}
          </GridItem>
        </GridContainer>
        {lockTransfer.tenant_owner_id &&
        <GridContainer className={classes.modifyForm} direction="row" justifyContent="center">
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                color="redColor"
                variant="contained"
                onClick={removeTAP}
              >
                Remove TAP Organization
              </RegularButton>
            )}
          </GridItem>
        </GridContainer>
        }
      </form>
    );
  };

  const showMoveForm = () => {
    return (
      <form onSubmit={handleSubmit(onMoveSubmit)} ref={transferFormHandle}>
        <GridContainer>
          <GridItem xs={12}>
            <AutocompleteFetchSelect
              id="selectTransferTenant"
              title="Organization"
              selectedValue={transferForm.tenant}
              resourceName="organizationList"
              onChange={(event, value) => {
                updateLockTransfer("tenant_id", value.id);
              }}
              highlighted={hasChangedTransfer("tenant_id")}
              outlined={true}
              autoHighlight={true}
            />
          </GridItem>
          {transferCustomSetupOrg ? (
            <GridItem className={classes.alertWrapper}>
              <CustomAlert type="CustomConfigurationOrg"></CustomAlert>
            </GridItem>
          ) : null}
          <GridItem className={classes.modifyForm} xs={12}>
            <AutocompleteFetchSelect
              id="selectTransferKey"
              title="Next Key"
              selectedValue={transferForm.crypto}
              dataSource={selectableTransferCryptoList()}
              onChange={(event, value) => {
                updateLockTransfer("crypto_next_id", value.id);
              }}
              highlighted={hasChangedTransfer("crypto_next_id")}
              outlined={true}
              autoHighlight={true}
            />
          </GridItem>
          <GridItem className={classes.modifyForm} xs={12}>
            <FormControlLabel
              label={"Transfer License"}
              labelPlacement="start"
              control={
                <Switch
                  onChange={(event) => {
                    updateLockTransfer("include_license", event.target.checked);
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                  defaultValue={false}
                  defaultChecked={false}
                />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer direction="row" justifyContent="flex-end" style={{ marginTop: "20px" }}>
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                onClick={() => setModifyMode(false)}
              >
                Cancel
              </RegularButton>
            )}
          </GridItem>
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                disabled={!transferFormIsDirty()}
                onClick={() => resetTransferForm()}
              >
                { "Clear Form"}
              </RegularButton>
            )}
          </GridItem>
          <GridItem>
            {readOnly ? (null) : (
              <RegularButton
                disabled={!transferFormIsDirty()}
                type="submit"
              >
                { submitting ? (<LoadingPlaceHolder height={6} />) : "Move Lock"}
              </RegularButton>
            )}
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  // Get the lock organization type and owner name
  useEffect(() => {
    const getTenantInfo = async () => {
      if (originalLockData === undefined)
        return;

      if (!isBlank(tenantLicense) || !isBlank(tenantOwner))
        return;

      try {
        const tenantId = originalLockData.tenant_id;
        if(tenantId) {
          const tenantData = await tenantService.fetchTenant(tenantId, {});
          const organizationLicense = getTenantLicenseName(tenantData.feature_level);
          setTenantLicense(organizationLicense || "-");
          setCustomSetup(tenantData.custom_setup ? true : false);
          setTransferCustomSetupOrg(tenantData.custom_setup ? true : false);
        } else {
          setTenantLicense("-");
        }
      } catch (e) {
        setTenantLicense("-");
        console.warn("Failed to find Tenant Data: " + e);
      }

      try {
        const tenantOwnerId = originalLockData.tenant_owner_id;
        if(tenantOwnerId) {
          const tenantOwnerData = await tenantService.fetchTenant(tenantOwnerId, {});
          const tenantOwnerName = tenantOwnerData.name;
          setTenantOwner(tenantOwnerName || "-");
        } else {
          setTenantOwner("-");
        }
      } catch (e) {
        setTenantOwner("-");
        console.warn("Failed to find Tenant Owner Data: " + e);
      }
    };
    getTenantInfo();
  }, [originalLockData]);

  const showLockDetails = () => {
    const onFavouriteClick = async () => {
      await updateFavouriteLocks(currentMembership.id, lockId);
      setIsFavourite(prev => !prev);
      refreshData();
    };
    const type = hardwareTypeByCode(editForm.hardware_type);
    const content = <GridContainer alignItems="center">
      <GridItem xs={3} align="center">
        <LockIcons type={type} defaultStyle={classes.lockIcons} height="110px" width="100px" />
        {originalLockData.rtm_board && <RegularButton onClick={() => showRtmInfo()}>View RTM</RegularButton>}
      </GridItem>
      <GridItem className={classes.lockDetailsItem} xs={9}>
        <h3 className={classes.title}><b>Lock:</b> 0x{convertToHex(lockId)} ({lockId})
          <IconButton onClick={onFavouriteClick} size="small">
            {isFavourite ? <StarIcon/> : <StarBorderIcon/>}
          </IconButton></h3>
        {showTenantName()}
        <p className={classes.lockDetailsFlex}><b>Organization Type: </b>{tenantLicense || <Skeleton width={150} />}</p>
        <p className={classes.lockDetailsFlex}>
          <b>TAP Organization: </b>{tenantOwner || <Skeleton width={150} />}
          {!readOnly ?
            <Tooltip classes={{ tooltip: classes.tooltip }} title="TAP Organization">
              <EditIcon className={classes.editIcon} fontSize={"small"} onClick={() => setModifyTenantOwner(true)}/>
            </Tooltip> : null}
        </p>
        <p className={classes.lockDetails}><b>Deployment/Container: </b>{containerName || ""}</p>
        <p className={classes.lockDetails}><b>Certificate ID: </b>{originalLockData.certificate_id}</p>
        <p className={classes.lockDetails}><b>Hardware Address: </b>{originalLockData.hardware_address}</p>
        <p className={classes.lockDetails}><b>Created: </b><Tooltip title={moment(originalLockData.created_at).format()} aria-label="Created At"><span>{moment(originalLockData.created_at).format("L")}</span></Tooltip></p>
        <p className={classes.lockDetails}><b>Updated: </b><Tooltip title={moment(originalLockData.updated_at).format()} aria-label="Updated At"><span>{moment(originalLockData.updated_at).format("L")}</span></Tooltip></p>
        <p className={classes.lockDetails}><b>Status: </b>{getLockStatusNameSera4tal(originalLockData)}</p>
        <p className={classes.lockDetails}><b>In Service: </b>
          {originalLockData.in_service &&
            <Tooltip title={moment(originalLockData.in_service).format()} aria-label="In Service">
              <span>{moment(originalLockData.in_service).format("L")}</span>
            </Tooltip>
          }
        </p>
        <p className={classes.lockDetailsFlex}>
          <b>Battery Type: </b>{batteryType?.name || <Skeleton width={150} />}
          {!readOnly ?
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Battery Type">
              <EditIcon className={classes.editIcon} fontSize={"small"} onClick={() => setModifyBatteryType(true)}/>
            </Tooltip> : null}
        </p>
        <p className={classes.lockDetailsFlex}>
          <b>Shackle Type: </b>{shackleType?.name || <Skeleton width={150} />}
          {!readOnly && originalLockData?.type === LockTypeOptions[1].id ?
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Shackle Type">
              <EditIcon className={classes.editIcon} fontSize={"small"} onClick={() => setModifyShackleType(true)}/>
            </Tooltip> : null}
        </p>
        <NavLink to={`${config.adminUrl}/history?lockId=0x${convertToHex(lockId)}`} className={classes.link}>
          <RegularButton
            onClick={() => menuCtx.closeMenu()}
            startIcon={<AssignmentIcon />}
          >
            View History
          </RegularButton>
        </NavLink>
        <NavLink 
          to={{
            pathname: `${config.adminUrl}/lock_log`,
            search: `?lockId=0x${convertToHex(lockId)}`,
            state: { previousSearch: history?.location?.search }
          }}
          className={classes.link}>
          <RegularButton
            onClick={() => menuCtx.closeMenu()}
            startIcon={<DescriptionIcon />}
          >
            View Logs
          </RegularButton>
        </NavLink>
        <NavLink
          to={{
            pathname: `${config.adminUrl}/lock_health_dashboard`,
            search: `?lockId=0x${convertToHex(lockId)}`,
            state: { previousSearch: history?.location?.search }
          }}
          className={classes.link}>
          <RegularButton
            onClick={() => menuCtx.closeMenu()}
            startIcon={<MonitorHeartIcon />}
          >
            View Lock Health Dashboard
          </RegularButton>
        </NavLink>
        <NavLink to={`${config.adminUrl}/lock_transactions?lock_id=0x${convertToHex(lockId)}`} className={classes.link}>
          <RegularButton
            onClick={() => menuCtx.closeMenu()}
            startIcon={<LockIcon />}
          >
            View Transactions
          </RegularButton>
        </NavLink>
      </GridItem>
      {customSetup ? (
        <GridItem className={classes.alertWrapper}>
          <CustomAlert type="CustomConfigurationOrg"></CustomAlert>
        </GridItem>
      ) : null}
    </GridContainer>;
    return content;
  };

  const showLockBody = () => {
    const isModifyPage = modifyMode || modifyTenantOwner || modifyBatteryType || modifyShackleType;

    return (
      <Fragment>
        {/* main section */}
        { isModifyPage ? null : showLockDetails()}
        { isModifyPage ? null : showEditForm()}

        {/* Edit sections */}
        {modifyMode && <div className={classes.moveForm}>{showMoveForm()}</div>}
        {modifyTenantOwner && <div className={classes.moveForm}>{showTenantOwnerForm()}</div>}
        {modifyBatteryType && <div className={classes.moveForm}>{showBatteryTypeForm()}</div>}
        {modifyShackleType && <div className={classes.moveForm}>{showShackleTypeForm()}</div>}
      </Fragment>
    );
  };

  return (
    <GridContainer>
      <CustomModal
        open={ilsOpen}
        setOpen={setIlsOpen}
        type="confirm"
        confirm="Proceed"
        handleSubmit={modalProps.customButtonHandler}
        title={modalProps.title}
        description={modalProps.message}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardBody>
            <ErrorBoundary>
              {loading ? showLoadingSymbol() : showLockBody()}
            </ErrorBoundary>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditLock.propTypes = {
  data: PropTypes.shape({
    lock_id: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  fetchedData: PropTypes.object,
  readOnly: PropTypes.bool,
  refreshData: PropTypes.func
};
