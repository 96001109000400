import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  button: {
    color: "#fff",
    fontSize: theme.typography.fontSize * 1.6,
    textTransform: "none",
    whiteSpace: "nowrap",
    margin: 0
  }
}));

const ContainedButton = (props) => {
  const { classes } = useStyles();
  const {
    href,
    children
  } = props;

  return (
    <Button disableFocusRipple disableRipple href={href} className={classes.button} color="primary" {...props}>{children}</Button>
  );
};

ContainedButton.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ContainedButton;
