/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useReducer, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { WebSocketDataContext } from "_contexts/WebSocketData/WebSocketData";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getFilterFor, saveLastFilter } from "__indexedDB/controllers/filtersController";
import { clearUrlQuery, compactObj, getFilterVars, isBlank, isNullOrUndefined } from "_helpers";
import { formatLockHexIDs } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";

// @mui/material components
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";

import GridItem from "components/Grid/GridItem";
import CustomModal from "_components/Modal/CustomModal";
import LsyAppBar from "_components/Lockstasy/LsyAppBar";

import styles from "assets/jss/widgets/cardListWidgetStyle.js";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual, cloneDeep } from "lodash";
import { quickSearch } from "_helpers/shortcut";
import queryString from "query-string";
import { notificationTypeConstants } from "_constants";

const useStyles = makeStyles()(styles);

function CardListWidget(props) {
  const { fetchData, org, dataFormatter, filterOptions, additionForm, handleAdditionFormCancel, stats,
    paginate, fielterDefaultValues, events, removeEmptyOptions, fallbackSize, location, queryParamVar,
    display, eventTypes, isDisabledCardButton } = props;
  const { search } = location;
  const history = useHistory();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const { rowsPerPage } = props || 10;
  const defaultInitialState = fielterDefaultValues;
  const getInitialState = () => {
    if (props.enableQueryParams) {
      let filterVars = getFilterVars(defaultInitialState, search, queryParamVar);
      //handle special case for react-async-select
      if (Object.keys(filterVars).length > 0) {
        filterOptions.forEach((v) => {
          if (typeof v.defaultValue === "object" && !isBlank(filterVars[v.field])) {
            if (typeof filterVars[v.field] === "object") {
              filterVars[v.field] = filterVars[v.field].map((value) => {
                return { id: value, name: value };
              });
            } else {
              const value = filterVars[v.field];
              filterVars[v.field] = [{ id: value, name: value }];
            }
          }
        });
      }

      return { ...defaultInitialState, ...filterVars };
    } else {
      return { ...defaultInitialState };
    }
  };
  const initialState = getInitialState();
  const getFilterOptions = () => {
    let options = filterOptions && [...filterOptions];
    if (props.enableQueryParams) {
      let filterVars = getFilterVars(defaultInitialState, search, queryParamVar);

      if (Object.keys(filterVars).length > 0) {
        options.forEach((v) => {
          if (filterVars[v.field]) {
            v.defaultValue = initialState[v.field];
          }
        });
      }
    }

    return options;
  };

  const [customModalState, setCustomModalState] = useReducer(genericReducer,
    {
      customModal: null,
      open: true
    }
  );
  const [state, setState] = useReducer(genericReducer,
    {
      currentPage: 1,
      rowsPerPage: rowsPerPage,
      filterVariables: initialState,
      submitting: false,
      filterActive: false,
      filterOpen: false,
      additionOpen: false,
      data: null,
      totalData: null,
      totalPages: null,
      showDismissed: false,
      isDisabledCardButton: isDisabledCardButton,
      lastFilters: []
    }
  );
  
  const stateRef = useRef({ data: [] });
  const setFilterVariables = (filterVariables) => setState({ filterVariables });
  const setCurrentPage = (currentPage) => setState({ currentPage });
  const setFilterOpen = (filterOpen) => setState({ filterOpen });
  const setFilterActive = (filterActive) => setState({ filterActive });
  const setAdditionOpen = (additionOpen) => setState({ additionOpen });
  const setSubmitting = (submitting) => setState({ submitting });
  const setShowDismissed = (showDismissed) => setState({ showDismissed });
  const setLastFilters = (lastFilters) => setState({ lastFilters });

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const addForm = useForm({ mode: "onChange" });
  const filterForm = useForm({ mode: "onChange" });
  const webSocketContext = useContext(WebSocketDataContext);
  const webSocketActive = webSocketContext.webSocketActive;

  const showStats = props.widget === "notification" && !state.showDismissed ? true : stats;
  const title = showStats && state.totalData ? `${props.title} (${state.totalData})` : `${props.title}`;
  const liveUpdates = !!(!state.filterActive && events && state.currentPage === 1 && webSocketActive);
  const fallbackData = (
    <Grid
      container
      className={cx({ [classes.noDataSm]: fallbackSize === "sm", [classes.noDataMd]: fallbackSize === "md", [classes.noDataLg]: fallbackSize === "lg" })}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item className={cx({ [classes.noDataBodySm]: fallbackSize === "sm", [classes.noDataBodyMd]: fallbackSize === "md", [classes.noDataBodyLg]: fallbackSize === "lg" })}>
        {props.fallbackData}
      </Grid>
    </Grid>
  );

  const loadSkeleton = () => {
    if (display === "grid") {
      return [...Array(12).keys()].map((index) => {
        return <GridItem className={classes.gridSkeleton} xs={12} sm={6} md={4} lg={3} key={index}>{props.skeletonCard}</GridItem>;
      });
    } else {
      return [...Array(2).keys()].map((index) => {
        return <GridItem key={index}>{props.skeletonCard}</GridItem>;
      });
    }
  };

  const createModal = useCallback((params) => {
    const handleCustomAction = () => {
      if (params.confirmAction) {
        params.confirmAction();
      }

      setCustomModalState({ customModal: null });
    };

    setCustomModalState({
      open: customModalState.open,
      customModal: <CustomModal
        open={customModalState.open}
        setOpen={() => setCustomModalState({ customModal: null })}
        handleSubmit={handleCustomAction}
        {...params}
      />
    });
  }, [customModalState]);

  const updateUrlQuery = (newState) => {
    if (!isEqual(newState, defaultInitialState)) {
      let filterVars = cloneDeep(compactObj(newState));
      const filterVarKeys = Object.keys(filterVars);

      //special handler for react-async-select
      filterVarKeys.forEach((field) => {
        if (Array.isArray(filterVars[field]) && !isBlank(filterVars[field])) {
          filterVars[field] = filterVars[field].map((item) => {
            let value = item.id;
            return value;
          });
        } else if (typeof filterVars[field] === "object" && !isBlank(filterVars[field])) {
          filterVars[field] = filterVars[field].id;
        }
      });

      if (queryParamVar && filterVarKeys.length > 0) {
        filterVarKeys.forEach((field) => {
          filterVars[`${queryParamVar}${field}`] = filterVars[field];
          delete filterVars[field];
        });
      }

      history.replace(`${location.pathname}?${queryString.stringify(filterVars, { arrayFormat: "separator", arrayFormatSeparator: "|" })}`);
    } else {
      clearUrlQuery(history, location);
    }
  };

  const fetchWidgetData = async () => {
    try {
      let filterVariables = { ...state.filterVariables };
      if (removeEmptyOptions) {
        filterVariables = Object.fromEntries(Object.entries(filterVariables).filter(([_, v]) => !isBlank(v)));
      }

      let options = { ...filterVariables } || {};
      options.page = state.currentPage;
      options.page_size = state.rowsPerPage;

      if (props.widget === "notification" && !state.showDismissed) {
        options.resolved = state.showDismissed;
      }

      const result = await fetchData(options);
      
      var newState = {};
      if (paginate) {
        newState.totalPages = result.meta.pagination.total_pages;
      }
      if (showStats) {
        newState.totalData = result.meta.pagination.total;
      }
      const formattedData = dataFormatter(result.data, setFilterVariables, state, setState, fetchWidgetData, createModal, addForm.setValue, addForm.reset, state.showDismissed, updateUrlQuery);

      setState({
        data: [...formattedData],
        ...newState,
        submitting: false
      });

      stateRef.current = {
        data: [...formattedData],
        ...newState
      };

    } catch (e) {
      setSubmitting(false);
      console.warn(e);
    }
  };

  const fetchLastFilters = async () => {
    try {
      const filters = await getFilterFor(currentMembership.id, history.location.pathname);
      setLastFilters(filters);
    } catch (e) {
      console.warn("Error fetching last filters", e);
      return [];
    }
  };

  const handleCreate = (data) => {
    let alreadyExists = false;

    stateRef.current.data?.map((currentData) => {
      if (currentData.key == data.id) {
        alreadyExists = true;
      }
    });

    if (!alreadyExists) {
      const eventFormattedData = dataFormatter([data], setFilterVariables, state, setState, fetchWidgetData, createModal, addForm.setValue, addForm.reset, state.showDismissed);
      const newData = eventFormattedData.concat(stateRef.current.data);

      setState({
        data: [...newData],
        totalData: stateRef.current.totalData + 1
      });

      stateRef.current = {
        ...stateRef.current,
        data: [...newData],
        totalData: stateRef.current.totalData + 1
      };
    }
  };

  const handleUpdate = (data) => {
    stateRef.current.data?.map((currentData, index) => {
      if (currentData.key == data.id) {
        const formattedEntry = dataFormatter([data], setFilterVariables, state, setState, fetchWidgetData, createModal, addForm.setValue, addForm.reset, state.showDismissed);
        stateRef.current.data[index] = formattedEntry[0];
        setState({ data: [...stateRef.current.data] });
      }
    });
  };

  const handleDelete = (id) => {
    let found = false;
    const filteredData = stateRef.current.data?.filter((data) => {
      if (data.key == id) {
        found = true;
      }
      return data.key != id;
    });

    if (found) {
      setState({
        data: [...filteredData],
        totalData: stateRef.current.totalData - 1
      });

      stateRef.current = {
        ...stateRef.current,
        data: [...filteredData],
        totalData: stateRef.current.totalData - 1
      };
    }
  };
  function handleAlert(e) {
    const typeID = e?.detail?.object?.type?.id;
    if (liveUpdates && eventTypes.includes(typeID) ) {
      const { action, object } = e.detail;
      switch (action) {
        case "create": handleCreate(object);
          break;
        case "update": handleUpdate(object);
          break;
        case "delete": handleDelete(object.id);
          break;
      }
    }
  }

  useCustomCompareEffect(() => {
    fetchLastFilters();
    fetchWidgetData();
  }, [org, state.currentPage, state.filterVariables, isDisabledCardButton], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    if (events) {
      events.map((alertType) => {
        window.addEventListener(alertType, handleAlert);
      });
    }

    return () => {
      if (events) {
        events.map((alertType) => {
          window.removeEventListener(alertType, handleAlert, false);
        });
      }
    };
  }, [events, handleAlert], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    if (props.widget === "notification") {
      if (state.currentPage !== 1) {
        setCurrentPage(1); //this will force a fetch anyways with new resolved param
      } else {
        fetchWidgetData();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state.showDismissed]);

  function openFilter(e) {
    quickSearch(e, () => {
      if (props.enableFilter) {
        setFilterOpen(true);
      }
    });
  }

  useCustomCompareEffect(() => {
    window.addEventListener("keydown", openFilter);
    return () => {
      window.removeEventListener("keydown", openFilter, false);
    };
  }, [openFilter], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const resetFilter = () => {
    if (!isEqual(defaultInitialState, state.filterVariables)) {
      setSubmitting(true);
      filterForm.reset(defaultInitialState);
      setState({
        filterVariables: defaultInitialState,
        currentPage: 1,
        filterActive: false
      });
    } else {
      setFilterOpen(false);
      filterForm.reset(state.filterVariables);
    }

    if (props.enableQueryParams) {
      clearUrlQuery(history, location);
    }
  };

  const onSubmit = async (data) => {
    if (!isEqual(data, state.filterVariables)) {
      await saveLastFilter(currentMembership.id, history.location.pathname, filterOptions, data);
      const formattedIds = data.search?.includes(",") ? formatLockHexIDs(data.search, t) : {};

      if (formattedIds.error) {
        filterForm.setError("search", { type: "custom", message: formattedIds.error });
        return;
      }

      if (props.enableQueryParams) {
        updateUrlQuery(data);
      }
      filterForm.reset(data);
      setState({
        filterVariables: {
          ...state.filterVariables,
          ...data,
          ...formattedIds
        },
        submitting: true,
        filterActive: true,
        filterOpen: false,
        currentPage: 1
      });
    } else {
      setState({
        filterOpen: false
      });
    }
  };

  const applyLastFilter = (lastFilter) => onSubmit({...lastFilter.variables});

  const resetAddForm = () => {
    let defaultValues = {};

    for (const field of additionForm) {
      defaultValues[field.field] = !isNullOrUndefined(field.defaultValue) ? field.defaultValue : "";
    }

    addForm.reset(defaultValues);
  };

  const onSubmitAddition = (data) => {
    setSubmitting(true);
    props.addition(data, fetchWidgetData);
    setAdditionOpen(false);
    resetAddForm();
  };

  const handleAdditionClose = () => {
    if (handleAdditionFormCancel) {
      handleAdditionFormCancel();
    }

    resetAddForm();
  };

  useCustomCompareEffect(() => {
    if (isEqual(defaultInitialState, state.filterVariables)) {
      if (state.filterActive) {
        setFilterActive(false);
      }
    } else {
      if (!state.filterActive) {
        setFilterActive(true);
      }
    }
  }, [state.filterVariables], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return (
    <Card elevation={5} className={classes.widgetCard} data-testid={`cardListWidget-${props.title}`}>
      <form onSubmit={filterForm.handleSubmit(onSubmit)}>
        <CustomModal
          open={state.filterOpen}
          setOpen={setFilterOpen}
          handleSubmit={filterForm.handleSubmit(onSubmit)}
          handleCancel={resetFilter}
          title={t("button.filter")}
          type="formCreator"
          confirm={t("button.apply")}
          cancel={t("button.reset")}
          manualClose
          formOptions={getFilterOptions()}
          errors={filterForm.formState.errors}
          control={filterForm.control}
          setValue={filterForm.setValue}
          modalStyle={classes.modal}
          submit
          submitting={state.submitting}
          lastFilters={state.lastFilters}
          applyLastFilter={applyLastFilter}
        />
        {customModalState.customModal}
        <LsyAppBar
          filterActive={state.filterActive}
          liveUpdates={liveUpdates}
          events={events}
          resetFilter={resetFilter}
          paginate={props.paginate}
          refresh={props.refresh}
          customButtons={props.customButtons}
          enableFilter={props.enableFilter}
          addition={props.addition}
          filterOpen={state.filterOpen}
          setFilterOpen={setFilterOpen}
          additionOpen={state.additionOpen}
          setAdditionOpen={setAdditionOpen}
          additionIcon={props.additionIcon}
          additionLabel={props.additionLabel}
          icon={props.icon}
          title={`${title}`}
          currentPage={state.currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={state.totalPages}
          fetchWidgetData={fetchWidgetData}
          size={props.appBarSize}
        />
      </form>
      <Grid className={classes.dataWrap} container direction={display === "grid" ? "row" : "column"}>
        {((state.data === null) || state.submitting)
          ? loadSkeleton() : state.data.length === 0 ? fallbackData : state.data.map((card, index) => {
            return <Fade key={index} timeout={{ enter: 1000, exit: 500 }} in={!state.submitting}>
              {display === "grid" ? card :
                <GridItem xs={12}>
                  {card}
                </GridItem>}
            </Fade>
            
            ;
          })}
      </Grid>
      {props.secondPaginate ? (
        <LsyAppBar
          bottomBar
          widget={props.widget}
          paginate={props.paginate}
          refresh={props.refresh}
          currentPage={state.currentPage}
          setCurrentPage={setCurrentPage}
          showDismissed={state.showDismissed}
          setShowDismissed={setShowDismissed}
          totalPages={state.totalPages}
          fetchWidgetData={fetchWidgetData}
          size={props.appBarSize}
        />
      ) : null}
      { props.additionForm ?
        <form onSubmit={addForm.handleSubmit(onSubmitAddition)}>
          <CustomModal
            open={state.additionOpen}
            setOpen={setAdditionOpen}
            handleSubmit={addForm.handleSubmit(onSubmitAddition)}
            handleClose={handleAdditionClose}
            type="formCreator"
            title={props.additionLabel}
            formOptions={additionForm}
            errors={addForm.formState.errors}
            control={addForm.control}
            setValue={addForm.setValue}
            clearErrors={addForm.clearErrors}
            manualClose
            submit
            submitting={state.submitting}
            modalStyle={classes.modal}
          />
        </form> : null}
    </Card>
  );
}

CardListWidget.defaultProps = {
  eventTypes: Object.values(notificationTypeConstants)
};

CardListWidget.propTypes = {
  stats: PropTypes.bool,
  paginate: PropTypes.bool,
  title: PropTypes.string,
  refresh: PropTypes.bool,
  icon: PropTypes.object,
  fetchData: PropTypes.func,
  org: PropTypes.string,
  label: PropTypes.string,
  dataFormatter: PropTypes.func,
  customButtons: PropTypes.array,
  rowsPerPage: PropTypes.number,
  secondPaginate: PropTypes.bool,
  enableFilter: PropTypes.bool,
  appBarSize: PropTypes.string,
  filterOptions: PropTypes.array,
  fielterDefaultValues: PropTypes.object,
  addition: PropTypes.func,
  additionIcon: PropTypes.object,
  additionForm: PropTypes.array,
  handleAdditionFormCancel: PropTypes.func,
  skeletonCard: PropTypes.object.isRequired,
  fallbackData: PropTypes.object.isRequired,
  additionLabel: PropTypes.string,
  events: PropTypes.array,
  eventTypes: PropTypes.array,
  widget: PropTypes.string,
  removeEmptyOptions: PropTypes.bool,
  fallbackSize: PropTypes.string,
  enableQueryParams: PropTypes.bool,
  queryParamVar: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  baseUrl: PropTypes.string,
  display: PropTypes.string,
  isDisabledCardButton: PropTypes.bool
};
export default CardListWidget;
