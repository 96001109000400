//TODO: TWS-1245 Theme doesn't work because errorboundary lies outside MuiThemeProvider

const errorPageStyles = () => ({
  subTitle: {
    fontSize: "36px",
    marginTop: "0",
    marginBottom: "8px",
    textAlign: "center",
    color: "white"
    // color: theme.palette.primary.contrastText
  },
  description: {
    fontSize: "16px",
    marginTop: "0",
    marginBottom: "8px",
    color: "white"
    // color: theme.palette.primary.contrastText
  },
  background: {
    width: "100vw",
    height: "100vh",
    margin: "0px",
    backgroundColor: "#544D5B"
    // backgroundColor: theme.palette.secondary.main
  }
});

export default errorPageStyles;
