import { useState, Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { userService, tagService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { alertActions } from "_actions";
import { hasAbility, userNameCannotContainsRegex, emailRegex, membershipHelper } from "_helpers";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";

//icons
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HelpIcon from "@mui/icons-material/HelpOutline";
import RolesIcon from "assets/teleporte/RolesIcon";

import CardListWidget from "_containers/Widgets/CardListWidget";
import RoleWizard2 from "_containers/Lockstasy/RoleWizard2";
import LsyUserCard from "_components/Lockstasy/LsyUserCard";
import GridItem from "components/Grid/GridItem";
import Placeholder from "_components/Helper/Placeholder";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/usersStyle.js";

import { LOCALES } from "_constants/locale.constants";
import { roleService } from "_services";
import { MAX_EMAIL_LENGTH } from "_constants";

const useStyles = makeStyles()(styles);

function LsyUsers(props) {
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const dispatch = useDispatch();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const userGroupMap = lsyAdminDataContext.userGroupMap;
  const isAdmin = membershipHelper.isLsyAdmin(currentMembership);
  const [roles, setRoles] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [disableMfa, setDisableMfa] = useState(false);
  const language = useSelector((state) => state.locale.language);

  const fetchRoles = async () => {
    try {
      const result = await roleService.fetchRoles();
      setRoles(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch roles", e);
      return [];
    }
  };

  const fetchTags = async (inputValue) => {
    try {
      const result = await tagService.fetchTags({ page_size: 3, search: inputValue });
      return result.data.map(v => v ? { id: v.id, name: v.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  const addUser = async (data, fetchWidgetData) => {
    try {
      const result = await userService.addUser({ data: { user: data } });

      if(result) {
        dispatch(alertActions.send(t("success.addUser")));
      }
    } catch (e) {
      if (e.response?.status === 409) {
        dispatch(alertActions.send("Email already has membership in this organization", "error"));
      } else {
        console.warn("Warning, failed to add user", e);
        dispatch(alertActions.send(t("error.addUser"), "error"));
      }
    }
    fetchWidgetData();
  };

  const fetchUsers = async (options) => {
    if (options.tag_ids && options.tag_ids.length > 0) {
      options.tag_ids = `[${options.tag_ids.map(tag => tag.id)}]`;
    }
    options.include = "tags";
    try {
      return await userService.fetchUsers(options);
    } catch (e) {
      console.warn("Warning, failed to fetch users", e);
      return {};
    }
  };

  useEffect(() => {
    fetchRoles();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const skeletonCard = <Card className={classes.userCard}>
    <div className={classes.userCardContent}>
      <PersonIcon
        sx={{ display: { xs: "none", sm: "block" } }}
        fontSize="large"
        className={classes.personIcon}
      />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CardContent>
    </div>
  </Card>;

  const userFormatter = ( data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset, dismissed, updateUrlQuery ) => {
    var cardList = data.map((user, index) => {
      return (<LsyUserCard
        org={props.org}
        key={index}
        user={user}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={fetchWidgetData}
        setValue={setValue}
        state={state}
        setState={setState}
        reset={reset}
        dismissed={dismissed}
        updateUrlQuery={updateUrlQuery}
      />
      );
    });
    return cardList;
  };

  const getSortedLanguageOptions = () => {
    return (
      Object.keys(LOCALES).sort((a, b) => a.localeCompare(b, language, {sensitivity: "base"})).map(key => {
        return { value: key, name: LOCALES[key].name };
      })
    );
  };

  const getAdditionOptions = () => { // we get all roles back but only add if schema is present for role
    let roleOptions = [];

    if (ability.can("update", "roles")) {
      roleOptions = roles.map((option) => {
        return { name: userGroupMap[option.name] || option.name, value: option.id };
      });
    } else {
      roleOptions = [{ name: userGroupMap["user"], value: 2 }];
    }

    let options = [{
      field: "first_name",
      type: "textField",
      label: t("form.firstName"),
      required: t("error.requiredField"),
      autoFocus: true,
      pattern: {
        value: userNameCannotContainsRegex,
        message: t("error.invalidEntry")
      }
    },
    {
      field: "last_name",
      type: "textField",
      label: t("form.lastName"),
      required: t("error.requiredField"),
      pattern: {
        value: userNameCannotContainsRegex,
        message: t("error.invalidEntry")
      }
    },
    {
      field: "email",
      type: "textField",
      label: t("form.email"),
      required: t("error.requiredField"),
      inputProps: {maxLength: MAX_EMAIL_LENGTH},
      pattern: {
        value: emailRegex,
        message: t("error.invalidEmail")
      },
      onChange: (value) => setDisableMfa(/@sera4\.com\s*$/.test(value))
    },
    {
      field: "role_id",
      type: "select",
      label: t("label.membershipType"),
      defaultValue: 2,
      size: 10,
      helperIcon: <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={t("label.roles")}
      >
        <IconButton onClick={() => setShowRoleModal(true)} size="large">
          <RolesIcon className={classes.rolesIcon} />
        </IconButton>
      </Tooltip>,
      options: roleOptions
    },
    {
      field: "language",
      type: "select",
      label: t("label.language"),
      defaultValue: language,
      options: getSortedLanguageOptions()
    }];

    if (hasAbility(currentMembership, "edit", "device_restriction")) {
      options.push({
        field: "device_restricted",
        type: "switch",
        padded: true,
        label: <Fragment>
          {t("label.restrictUser")}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={t("widget.explanations.restrictUserFull")}
          >
            <HelpIcon fontSize="small" className={classes.helpIcon} />
          </Tooltip>
        </Fragment>,
        defaultValue: true
      });
    }

    if (isAdmin) {
      options.push({
        field: "mfa",
        type: "switch",
        padded: true,
        label: <Fragment>
          {t("mfa.mfa", { ns: "auth" })}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={t("mfa.help", { ns: "auth" })}
          >
            <HelpIcon fontSize="small" className={classes.helpIcon} />
          </Tooltip>
        </Fragment>,
        defaultValue: false,
        disabled: disableMfa
      });
    }
    return options;
  };

  const handleAdditionFormCancel = () => {
    setDisableMfa(false);
  };

  const getFilterOptions = () => {
    let groupOptions = roles.map((role) => {
      return { name: userGroupMap[role.name] || role.name, value: role.id };
    });
    groupOptions.unshift({ name: t("form.allUsers"), value: " " });

    let options = [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        autoFocus: true
      },
      {
        field: "active",
        type: "select",
        label: t("label.status"),
        defaultValue: " ",
        options: [
          { name: t("form.allUsers"), value: " " },
          { name: t("form.enabled"), value: "true" },
          { name: t("form.suspended"), value: "false" }
        ]
      },
      {
        field: "mfa",
        type: "select",
        label: t("label.mfa"),
        defaultValue: " ",
        options: [
          { name: t("label.all"), value: " " },
          { name: t("label.disabled"), value: false },
          { name: t("label.enabled"), value: true }
        ]
      },
      {
        field: "role_id",
        type: "select",
        label: t("form.showUsersOfGroup"),
        defaultValue: " ",
        options: groupOptions
      },
      {
        field: "sort",
        type: "select",
        label: t("form.sortUsersBy"),
        defaultValue: " ",
        options: [
          { name: t("form.firstName"), value: " " },
          { name: t("form.lastName"), value: "last_name" },
          { name: t("form.email"), value: "email" }
        ]
      }
    ];

    if (ability.can("read", "tags")) {
      options.push({
        field: "tag_ids",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("features.tags"),
        async: true,
        isMulti: true,
        promiseOptions: fetchTags
      });
    }

    return options;
  };

  const getDefaultValues = () => {
    let defaultValues = {
      search: "",
      active: " ",
      role_id: " ",
      sort: " "
    };

    if (ability.can("read", "tags")) {
      defaultValues["tag_ids"] = [];
    }
    return defaultValues;
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        <GridItem className={classes.userWidget} xs={12} md={10}>
          {roles?.length > 0 ?
            <RoleWizard2
              open={showRoleModal}
              setOpen={setShowRoleModal}
              roles={roles}
            /> : null}
          <CardListWidget
            skeletonCard={skeletonCard}
            fallbackData={<Placeholder message={t("fallbacks.noUsersFound")} icon={<PersonIcon/>}/>}
            org={props.org}
            paginate
            stats
            title={t("features.users")}
            rowsPerPage={10}
            secondPaginate={true}
            enableFilter
            filterOptions={getFilterOptions()}
            fielterDefaultValues={getDefaultValues()}
            removeEmptyOptions
            additionForm={getAdditionOptions()}
            handleAdditionFormCancel={handleAdditionFormCancel}
            additionIcon={<PersonAddIcon className={classes.icon} />}
            additionLabel={t("button.addUser")}
            addition={ability.can("create", "users") ? addUser : null}
            fetchData={fetchUsers}
            dataFormatter={userFormatter}
            appBarSize="md"
            enableQueryParams
            baseUrl={props.baseUrl}
            history={props.history}
            location={props.location}
          />
        </GridItem>
      </Grid>
    </div>
  );
}

LsyUsers.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string
};

export default LsyUsers;
