import { Fragment, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
//core components
import Tooltip from "@mui/material/Tooltip";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

//components
import CustomPagination from "_components/Pagination/CustomPagination";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";
import Badge from "_components/UI/Badge";

import styles from "assets/jss/widgets/tableWidgetStyle.js";
import PropTypes from "prop-types";

function LsyAppBar(props) {
  const useStyles = makeStyles()(styles);
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const [ liveAnimation, setLiveAnimation ] = useState({ animation: "liveOn", count: 0 });

  var title;
  var appBar;
  var paginateSize;
  var refreshIcon;
  switch (props.size) {
    case "sm":
      appBar = "appBarSm";
      title = "titleSm";
      paginateSize = "small";
      refreshIcon = "medium";
      break;
    case "md":
      appBar = "appBarMd";
      title = "titleMd";
      paginateSize = "small";
      refreshIcon = "medium";
      break;
    case "lg":
      appBar = "appBarLg";
      title = "titleLg";
      paginateSize = "medium";
      refreshIcon = "large";
      break;
    default:
      title = "titleSm";
      appBar = "appBarSm";
      paginateSize = "small";
      refreshIcon = "medium";
      break;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLiveAnimation(prevState => {
        return prevState.animation === "liveOn" ?
          { animation: "liveOff", count: prevState.count + 1 } :
          { animation: "liveOn", count: prevState.count };
      });
    }, 8000);

    if (liveAnimation.count >= 3) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={classes.root} >
      <AppBar
        data-testid={"lsyAppBar"}
        elevation={0}
        className={classes[appBar]}
      >
        <Toolbar>
          {props.bottomBar && props.widget === "notification" ? <Fragment>
            <p className={`${classes[title]} ${classes.notificationButton}`}>{t("label.showDismissed")}</p>
            <ToggleSwitch
              checked={props.showDismissed}
              handleChange={(e) => props.setShowDismissed(e)}
              onColor="#c3c3c3"
            />
          </Fragment> : null}
          <Fragment >
            {props.icon}
          </Fragment>
          <div className={classes[title]}>
            <span className={cx({ [classes.action]: props.titleAction })}
              onClick={() => props.titleAction ? props.titleAction() : null}>
              {props.title}
            </span>
          </div>
          {
            props.customButtons ? props.customButtons.map((button, index) => {
              return (
                <Tooltip key={index} classes={{ tooltip: classes.tooltip }} title={button.label}>
                  <span>
                    <IconButton
                      disabled={button.disabled}
                      onClick={(e) => {
                        button.action(props.fetchWidgetData, e);
                      }}
                      size="large">
                      {button.icon}
                    </IconButton>
                  </span>
                </Tooltip>
              );
            }) : null
          }
          {props.addition ? <Tooltip classes={{ tooltip: classes.tooltip }} title={props.additionLabel}>
            <IconButton onClick={() => props.setAdditionOpen(!props.additionOpen)} size="large">
              {props.additionIcon}
            </IconButton>
          </Tooltip> : null}
          {props.events && props.liveUpdates && !props.bottomBar ? 
            <Tooltip classes={{ tooltip: classes.tooltip }} title={t("label.liveUpdates")}>
              <p className={classes[liveAnimation.animation]}>Live</p>
            </Tooltip>
            : null}
          {props.enableFilter ? <Tooltip classes={{ tooltip: classes.tooltip }} title={props.filterActive ? t("label.filterApplied") : t("button.filter")}>
            <IconButton data-testid={`lsyAppBarFilterButton-${props.title}`} onClick={() => props.setFilterOpen(!props.filterOpen)} size="large">
              <Badge invisible={!props.filterActive}>
                <FilterListIcon fontSize={refreshIcon} className={classes.refresh} />
              </Badge>
            </IconButton>
          </Tooltip> : null}
          {!props.bottomBar ?
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {props.paginate && props.totalPages > 1 ? (
                <Fade in={props.paginate && props.totalPages > 1}>
                  <div className={classes.pagination}>
                    <CustomPagination
                      type="lockstasy"
                      size={paginateSize}
                      shape="rounded"
                      currentPage={props.currentPage}
                      setCurrentPage={props.setCurrentPage}
                      totalPages={props.totalPages}
                      siblingCount={0}
                    />
                  </div>
                </Fade>
              ) : null}
            </Box> : props.paginate && props.totalPages > 1 ? (
              <Fade in={props.paginate && props.totalPages > 1}>
                <div className={classes.pagination}>
                  <CustomPagination
                    type="lockstasy"
                    size={paginateSize}
                    shape="rounded"
                    currentPage={props.currentPage}
                    setCurrentPage={props.setCurrentPage}
                    totalPages={props.totalPages}
                    siblingCount={0}
                  />
                </div>
              </Fade>
            ) : null}
          {props.refresh && parseInt(props.totalData) > 0 ? (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.refresh")}>
              <IconButton size="small" onClick={() => props.fetchWidgetData()}>
                <RefreshIcon fontSize={refreshIcon} className={classes.refresh} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}
LsyAppBar.propTypes = {
  bottomBar: PropTypes.bool,
  paginate: PropTypes.bool,
  liveUpdates: PropTypes.bool,
  events: PropTypes.array,
  title: PropTypes.string,
  titleAction: PropTypes.func,
  refresh: PropTypes.bool,
  icon: PropTypes.object,
  customButtons: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPages: PropTypes.number,
  fetchWidgetData: PropTypes.func,
  size: PropTypes.string,
  enableFilter: PropTypes.any,
  filterOpen: PropTypes.bool,
  setFilterOpen: PropTypes.func,
  additionOpen: PropTypes.bool,
  setAdditionOpen: PropTypes.func,
  fallbackTitle: PropTypes.string,
  totalData: PropTypes.string,
  addition: PropTypes.func,
  additionIcon: PropTypes.object,
  filterVariables: PropTypes.object,
  reset: PropTypes.func,
  setValue: PropTypes.func,
  filterActive: PropTypes.bool,
  resetFilter: PropTypes.func,
  additionLabel: PropTypes.string,
  showDismissed: PropTypes.bool,
  setShowDismissed: PropTypes.func,
  widget: PropTypes.string

};
export default LsyAppBar;