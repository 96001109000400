export const lightTheme = [
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 65
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 51
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 20
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 50
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#454545"
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        hue: "#ffff00"
      },
      {
        lightness: -25
      },
      {
        saturation: -97
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "on"
      },
      {
        lightness: -25
      },
      {
        saturation: -100
      }
    ]
  }
];

export const darkTheme = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      {
        hue: "#ff0000"
      },
      {
        saturation: -100
      },
      {
        lightness: -30
      }
    ]
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#353535"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#656565"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#505050"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#808080"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        saturation: 100
      },
      {
        lightness: -80
      },
      {
        invert_lightness: true
      },
      {
        gamma: 1.5
      },
      {
        visibility: "simplified"
      },
      {
        color: "#808080"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#454545"
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [
      {
        saturation: 100
      },
      {
        lightness: -40
      },
      {
        invert_lightness: true
      },
      {
        gamma: 1.5
      },
      {
        visibility: "simplified"
      },
      {
        color: "#f4f1f1"
      }
    ]
  }
];

export const turnOffPoi = {
  featureType: "poi",
  elementType: "labels.icon",
  stylers: [
    {
      visibility: "off"
    }
  ]
};

export const defaultLocation = Object.freeze({
  lat: 43,
  lng: -80
});
