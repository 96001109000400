import { useState } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useCustomCompareMemo } from "use-custom-compare";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import CustomForm from "_components/Form/CustomForm";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/textField/passwordStyle.js";

const useStyles = makeStyles()(styles);

const Password = (props) => {
  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const { form, label, fieldName, showHelperText, submitOnEnter, handleSubmit,
    fullWidth, size, columns, isRequired, shouldValidatePwStructure } = props;

  const [ showPassword, setShowPassword ] = useState(false);

  const visibilityIcon = (show, onClick) => {
    return (
      <InputAdornment position="end">
        <Tooltip
          title={show ? "Hide password" : "Show password"}
        >
          <IconButton tabIndex={-1} className={classes.internalIcon} onClick={onClick} size="large">
            { show ? <VisibilityOffOutlinedIcon fontSize="small"/> : <VisibilityOutlinedIcon fontSize="small"/> }
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  };

  const validatePassword = (password) => {
    if (shouldValidatePwStructure && (password.length < 6 || !/\d/.test(password) || !/[a-z]/.test(password) || !/[A-Z]/.test(password))) {
      return false;
    }
    return true;
  };

  const validate = props.validate ||
    {
      validateEntry: validatePassword,
      validateEntryMessage: t("error.passwordFormat")
    };

  const options = useCustomCompareMemo(() => {
    return [{
      field: fieldName,
      type: "textField",
      label: label,
      required: isRequired ? t("error.requiredField") : null,
      autoComplete: "off",
      defaultValue: "",
      size: size,
      columns: columns,
      fullWidth: fullWidth,
      inputType: showPassword ? "text" : "password",
      ...validate,
      showHelperText: showHelperText,
      InputProps: { endAdornment: visibilityIcon(showPassword, () => setShowPassword(!showPassword)) },
      inputProps: { "data-testid": fieldName },
      submitOnEnter: submitOnEnter,
      handleSubmit: handleSubmit
    }];
  }, [showPassword, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return <CustomForm items={options} form={form}/>;
};

Password.defaultProps = {
  shouldValidatePwStructure: true,
  submitOnEnter: false,
  showHelperText: false,
  isRequired: false,
  fullWidth: false,
  size: "small",
  handleSubmit: () => {}
};

Password.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  columns: PropTypes.number,
  submitOnEnter: PropTypes.bool,
  showHelperText: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleSubmit: PropTypes.func,
  validate: PropTypes.object,
  shouldValidatePwStructure: PropTypes.bool
};

export default Password;