import { useEffect, useReducer } from "react";
import { isEmpty, isEqual, keys, values } from "lodash";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useCustomCompareMemo } from "use-custom-compare";

import { localeActions, alertActions } from "_actions";
import { fetchErrors, mapErrorToCodes } from "_utils";
import authClient from "_helpers/axios/gw.client";
import { genericReducer } from "_reducers/general.reducer";
import routes from "_routes";

import { Button, Typography, TextField, Grid, Collapse } from "@mui/material";
import Alert from "@mui/material/Alert";

import LoginFrame from "_components/Auth/LoginFrame";
import ErrorBoundary from "_components/ErrorBoundary";
import Password from "_components/TextField/Password";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/passwordResetStyle";

const useStyles = makeStyles()(styles);

const PasswordReset = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["auth", "default"]);
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = props.match.params;
  const { email, language } = queryString.parse(props.location.search);

  const passwordForm = useForm({ mode: "onChange" });
  
  const [ state, setState ] = useReducer(genericReducer,
    {
      open: false,
      email: email,
      token: token,
      password: "",
      confirmPassword: "",
      showPasswordlessInfo: false
    }
  );

  const setShowPasswordlessInfo = () => setState({ showPasswordlessInfo: !state.showPasswordlessInfo });

  useEffect(() => {
    if (!state.open) {
      setTimeout(() => setState({ open: true }), 500);

      dispatch(localeActions.setLocale({ language: language }));
    }
  }, [state.open, language, dispatch]);

  const resetPassword = (password) => {
    const url = `/accounts/${state.email}/recover/${state.token}`;

    let requestOptions = {
      method: "post",
      url: url,
      data: { password }
    };

    authClient(requestOptions)
      .then((r) => {
        if (r) {
          dispatch(alertActions.send(t("password.reset.success")));
          history.replace(routes.public.password, {
            usernameEmail: state.email,
            password: password,
            isAuthenticating: true
          });
        }
      })
      .catch((e) => {
        let foundError = false;
        const handledErrors = {
          password: { weak: /weak/, min_size: /(\d+)\s*max=(\d+)/ },
          account: { account_inactive: /inactive/, no_activation: /no_activation/, not_found: /not_found/, already_used: /already_used/ },
          token: { token_inactive: /expired/, token_not_found: /not_found/, token_already_used: /already_used/ }
        };

        const errors = mapErrorToCodes(fetchErrors(e), handledErrors);

        if (Object.keys(errors).length > 0) {
          let match = values(errors)[0];
          switch (keys(errors)[0]) {
            case "min_size":
            case "max_size":
              dispatch(alertActions.send(t("password.size", match[1], match[2]), "error"));
              foundError = true;
              break;
            case "weak":
              foundError = true;
              dispatch(alertActions.send(t("password.too_weak"), "error"));
              break;
            case "not_found":
              foundError = true;
              dispatch(alertActions.send(t("account.resetTokenNotFound"), "error"));
              break;
            case "account_inactive":
              foundError = true;
              dispatch(alertActions.send(t("login.account.inactive"), "error"));
              break;
            case "already_used":
              foundError = true;
              dispatch(alertActions.send(t("token.alreadyUsed"), "error"));
              break;
            case "token_already_used":
              foundError = true;
              dispatch(alertActions.send(t("token.alreadyUsed"), "error"));
              break;
            case "token_inactive":
              foundError = true;
              dispatch(alertActions.send(t("token.expired"), "error"));
              break;
            case "token_not_found":
              foundError = true;
              dispatch(alertActions.send(t("token.notFound"), "error"));
              break;
            default:
          }
          setState({ hasErrors: true });
        } else if (!foundError && errors.length === 0 && e && e.error) {
          switch (true) {
            case /expired/.test(e.error):
              dispatch(alertActions.send(t("password.link.expired"), "error"));
              break;
            case /inactive/.test(e.error):
              dispatch(alertActions.send(t("password.activate.first"), "error"));
              break;
            default:
              console.debug(e.error);
              dispatch(alertActions.send(t("password.reset.error"), "error"));
          }
        }
      });
  };

  const handleSubmit = (data) => {
    if (data.password === data.password_confirm) {
      resetPassword(data.password);
    } else {
      passwordForm.setError("password_confirm", { type: "custom", message: t("default:error.passwordMatch") });
    }
  };

  const renderContent = useCustomCompareMemo(() => {
    const errors = passwordForm.formState.errors || {};
    
    const validatePWConfirmation = {
      validateEntry: passwordConfirmation => isEqual(passwordForm.getValues("password"), passwordConfirmation),
      validateEntryMessage: t("default:error.passwordMatch")
    };

    const isResetDisabled = !passwordForm.formState.isValid || !isEmpty(errors);

    return <ErrorBoundary>
      <form name="form" onSubmit={passwordForm.handleSubmit(handleSubmit)}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography data-testid="createPasswordTitle" className={classes.title} color="textSecondary" gutterBottom>
              {t("login.createPassword")}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Grid item>
                <TextField
                  inputProps={{ "data-testid": "emailTextField" }}
                  variant="outlined"
                  fullWidth required
                  id="login.name"
                  label={t("login.emailAddress")}
                  type="email"
                  name="email"
                  disabled
                  defaultValue={state.email}
                />
              </Grid>
              <Grid item>
                <Password
                  form={passwordForm}
                  label={t("login.password")}
                  fieldName="password"
                  showHelperText={true}
                  isRequired={true}
                  shouldValidatePwStructure={true}
                  fullWidth
                  size="medium"
                  columns={12}
                />
              </Grid>
              <Grid item>
                <Password
                  form={passwordForm}
                  label={t("login.confirmPassword")}
                  fieldName="password_confirm"
                  shouldValidatePwStructure={true}
                  submitOnEnter={true}
                  showHelperText={true}
                  isRequired={true}
                  handleSubmit={passwordForm.handleSubmit(handleSubmit)}
                  fullWidth
                  size="medium"
                  columns={12}
                  validate={validatePWConfirmation}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Typography data-testid="passwordInfo" variant="caption" className={classes.pwInfo} gutterBottom>
              {t("login.passwordConstraints")}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container justifyContent="center" alignItems="center" className={classes.margin}>
              <Grid item>
                <Typography data-testid="showPasswordlessInfo" variant="caption" onClick={setShowPasswordlessInfo} className={classes.link} >
                  {state.showPasswordlessInfo ? t("default:button.hideInfo") : t("passwordless.showInfoCreate", { newLine: (<br/>) })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Collapse in={state.showPasswordlessInfo}>
              <Alert severity="info" style={{alignItems: "center"}}>
                <Typography data-testid="passwordlessDescription">{t("passwordless.description")}</Typography>
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.margin} spacing={2}>
          <Grid item md={true} sm={true} xs={true}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Button
                  data-testid="resetPasswordButton"
                  disabled={isResetDisabled}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={passwordForm.handleSubmit(handleSubmit)}
                >
                  {t("login.resetTitle")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ErrorBoundary>;
  }, [state, passwordForm.formState, passwordForm.formState.isValid, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return <LoginFrame open={state.open}>{renderContent}</LoginFrame>;
};

PasswordReset.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object
};

export default PasswordReset;