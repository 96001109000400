import baseStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

const editLockStyle = theme => ({
  ...baseStyles,
  tooltip: tooltip,
  expiredWarning: {
    textAlign: "center",
    color: theme.lsyPalette.stdRed,
    fontWeight: "bold"
  },
  labelHorizontal: {
    ...baseStyles.labelHorizontal,
    textTransform: "capitalize"
  },
  optionsLabel: {
    paddingTop: "0px"
  },
  wideSelect: {
    width: "150px"
  },
  lockDetails: {
    marginBottom: "0px"
  },
  lockDetailsFlex: {
    "display": "flex",
    "marginBottom": "0px",
    "alignItems": "center",
    "& b": {
      marginRight: "4px"
    }
  },
  lockDetailsItem: {
    marginLeft: "0px"
  },
  nextVersion: {
    alignSelf: "center"
  },
  noUpgrade: {
    textAlign: "center",
    fontSize: "16px"
  },
  lockIcon: {
    height: "110px",
    width: "110px"
  },
  card: {
    marginTop: "0px"
  },
  lockIcons: {
    height: "40px",
    width: "40px"
  },
  modifyForm: {
    marginTop: "20px"
  },
  alertWrapper: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  link: {
    "color": theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main
    },
    "& button": {
      margin: "1px"
    }
  },
  moveForm: {
    marginTop: "40px"
  },
  editIcon: {
    "display": "inline-block",
    "maxHeight": "21px",
    "color": theme.lsyPalette.primary.mainDark,
    "transform": "scale(0.65)",
    "verticalAlign": "top",
    "cursor": "pointer",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  title: {
    display: "flex",
    alignItems: "center"
  }
});

export default editLockStyle;