import * as actionTypes from "_actions/actionTypes";
import { updateObject } from "_helpers/utility";

let baseDefaults = {
  path: "dashboard",
  subdomain: null
};

const initialState = baseDefaults;

const targetUpdate = (state, action) => {
  return updateObject(state, {
    path: action.target.path,
    subdomain: action.target.subdomain
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REDIRECTION_SET_TARGET: return targetUpdate(state, action);
    default:
      return state;
  }
};

export default reducer;
