import { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authActions } from "_actions";

function Logout({ nextLocation }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(authActions.logout());
    history.push(nextLocation);
  };

  useEffect(() => {
    onLogout();
  });

  return null;
}

Logout.propTypes = {
  nextLocation: PropTypes.string
};

Logout.defaultProps = {
  nextLocation: "/"
};

export { Logout };
