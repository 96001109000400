const mfaModalStyle = (theme) => ({
  modalBodyContent: { display: "flex", flexDirection: "column" },
  mfaButton: {
    textTransform: "none"
  },
  mfaAlert: {
    marginBottom: "10px"
  },
  spinner: {
    color: theme.palette.primary.contrastText
  }
});
export default mfaModalStyle;
