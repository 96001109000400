import { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { alertActions } from "_actions";
import { getLocalTZ } from "_helpers";
import { getInitialState, exportChartData, handleError } from "_services/lockstasy/ChartHelper";
import { deepFreeze } from "_services/lockstasy/helper";
import { chartService } from "_services/lockstasy/chart.service";
import { genericReducer } from "_reducers/general.reducer";

import ChartsWidgetTemplate from "../ChartsWidgetTemplate";

import { useTheme } from "@mui/material/styles";
import { fillDateGaps } from "_services/lockstasy/ChartHelper";

const defaultInitialState = deepFreeze({
  group_by: "month",
  start_date: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
  end_date: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
});

function KeyCreationChart(props) {
  const { history, location, type } = props;
  const { search } = location;
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const theme = useTheme();

  const initialState = getInitialState(defaultInitialState, search, type);

  const [chartState, setChartState] = useReducer(genericReducer,
    {
      data: [],
      filterVariables: {...initialState},
      start_date: initialState.start_date,
      end_date: initialState.end_date,
      loading: true,
      failedFetchMessage: "",
      exportModelOpen: false
    }
  );

  const setExportModalOpen = (exportModalOpen) => setChartState({ exportModalOpen });

  const getXAxis = useCallback(() => {
    return Object.keys(chartState.data);
  }, [chartState.data]);

  const getSerie = useCallback(() => {
    return Object.values(chartState.data);
  }, [chartState.data]);

  const getOptions = () => {
    const options = {
      toolbox: {
        show: true,
        orient: "vertical",
        feature: {
          magicType: {
            type: ["line", "bar"],
            title: {
              line: t("widget.chart.lineChart"),
              bar: t("widget.chart.barChart")
            }
          },
          saveAsImage: {
            show: true,
            title: t("widget.chart.saveImage"),
            name: `${props.title.replaceAll(" ", "")}_${moment(chartState.start_date).format("YYYYMMDD")}_${moment(chartState.end_date).format("YYYYMMDD")}`
          }
        }
      },
      xAxis: {
        type: "category",
        data: getXAxis(),
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: "value",
        minInterval: 1
      },
      series: [{
        data: getSerie(),
        color: theme.chartPalette.color,
        type: "bar"
      }],
      tooltip: {
        trigger: "axis"
      },
      grid: {
        left: theme.spacing(1),
        right: theme.spacing(10),
        top: theme.spacing(2),
        containLabel: true
      }
    };

    return options;
  };

  const onExportSubmit = (data) => {  
    if (moment(data.end_date).isSameOrBefore(data.start_date)) {
      dispatch(alertActions.send(t("error.invalidDates"), "error"));
    } else {
      setExportModalOpen(false);
      data.client_timezone = getLocalTZ();
      exportChartData(data, dispatch, t);
    }
  };

  const formatData = useCallback(data => {
    let formattedData = {};
    const dateFormat = chartState.filterVariables.group_by === "month" ? "YYYY-MM" : "YYYY-MM-DD";

    for (let idx = 0; idx < data.length; idx++) {
      const date = moment.parseZone(data[idx][0]).utc(true).format(dateFormat);

      if (idx > 0) {
        fillDateGaps(data[idx - 1][0], data[idx][0], dateFormat, chartState.filterVariables.group_by, formattedData);
      }

      if (Object.keys(formattedData).includes(date)) {
        formattedData[date] = formattedData[date] + data[idx][1];
      } else {
        formattedData[date] = data[idx][1];
      }
    }

    setChartState({ data: formattedData, loading: false, failedFetchMessage: "" });
  }, [chartState.filterVariables.group_by]);

  const fetchCharts = useCallback(async () => {
    const options = {
      start_date: chartState.start_date,
      end_date: chartState.end_date,
      group_by: chartState.filterVariables.group_by,
      time_zone: chartState.filterVariables.client_timezone
    };

    try {
      const result = await chartService.fetchKeyCreation(options);
      formatData(result.data.dates);
    } catch (e) {
      setChartState({
        data: [],
        loading: false,
        failedFetchMessage: t(handleError(e, ""))
      });
      console.warn("Warning, failed to fetch chart data with given input", e);
    }
  }, [chartState.filterVariables, chartState.start_date, chartState.end_date, formatData, t]);


  useCustomCompareEffect(() => {
    fetchCharts();
  }, [chartState.filterVariables, chartState.start_date, chartState.end_date, fetchCharts], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return (
    <ChartsWidgetTemplate
      title={props.title}
      history={history}
      location={location}
      type={props.type}
      chartState={chartState}
      setChartState={setChartState}
      getOptions={getOptions}
      defaultInitialState={defaultInitialState}
      descriptionMessage={`${t("description.keyCreationChart")} ${t("description.oneYearInterval")}`}
      setExportModalOpen={setExportModalOpen}
      onExportSubmit={onExportSubmit}
      showGroupBy
    />
  );
}

KeyCreationChart.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object
};

export default KeyCreationChart;
