import { isEmpty } from "lodash";
import moment from "moment";

const ct = require("countries-and-timezones");

const possibleDateTimeFormats = Object.freeze([
  "YYYY-MM-DD HH:mm:ss UTC",
  "MMM DD, YYYY hh:mm:ss a",
  "YYYY-MM-DD HH:mm:ss Z",
  "YYYY/MM/DD",
  moment.ISO_8601
]);

export const getMomentFor = dateTime => {
  if (!dateTime) {
    return null;
  }

  return moment(dateTime, possibleDateTimeFormats);
};

export const formatDate = (date, format) => {
  if (!date) {
    return "";
  }

  return moment(date, possibleDateTimeFormats).format(format || "lll");
};

export const formatParseDate = (date, format) => {
  const dateUtc = moment.utc(formatDate(date), "lll");

  return dateUtc.local().format(format || "lll");
};

export const formatDuration = (time, t) => {
  const duration = moment.duration(time * 1000);
  let formattedTime = "";

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 0) {
    formattedTime += `${days}${t("button.range.D").toLowerCase()} `;
  }
  if (hours > 0) {
    formattedTime += `${hours}${t("label.timeUnits.abbrHour")} `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}${t("label.timeUnits.abbrMinute")} `;
  }
  formattedTime += `${seconds}${t("label.timeUnits.abbrSecond")}`;

  return formattedTime;
};

export const isBrowserTimeMilitaryFormat = () => {
  const browserTimeFormat = new Intl.DateTimeFormat(navigator.language, {timeStyle: "medium"}).format();
  return !browserTimeFormat.match(/AM|PM/i);
};

const timeFormat = () => {
  return isBrowserTimeMilitaryFormat() ? "HH:mm:ss" : "hh:mm:ss a";
};

export const formatDateToAPI = date => moment.utc(date).toISOString();
export const formatDateAsdddMMMDDYYYY = date => formatDate(date, "ddd, MMM DD, YYYY");
export const formatDateAsMMMMDDYYYYhhmmssA = date => formatDate(date, "MMMM DD, YYYY hh:mm:ss A");
export const formatDateAsMMMMDDYYYYhhmm = date => formatDate(date, "lll");
export const formatDateAsMMMMDDYYYYhhmmss = date => formatDate(date, `MMM DD, YYYY ${timeFormat()}`);
export const formatDateAsYYYYMMDDhhmmss = date => formatDate(date, "YYYY-MM-DD hh:mm:ss");
export const formatDateAsLocal = date => formatDate(date, "ll");
export const formatDateAsMMMMDDYYYYhhmmLocal = date => formatDate(date, "LLL");
export const formatDateAsYYYYMMDD = date => formatDate(date, "YYYY-MM-DD");
export const formatDateAsYYYYMM = date => formatDate(date, "YYYY-MM");
export const formatDateAsMMMMYYYY = date => formatDate(date, "MMMM YYYY");
export const formatDateAsMMMDDYYYY = date => formatDate(date, "MMM DD, YYYY");
export const formatDateAshhmmssA = date => formatDate(date, "hh:mm:ss A");
export const formatDateAsLTS = date => formatDate(date, "LTS");

const getTZNameFromObject = (tz, showOffset) => {
  if (isEmpty(tz.countries) || tz.name.split("/").length > 2) {
    return `${tz.name.replaceAll("_", " ")} (${tz.utcOffsetStr})`;
  }

  const country = ct.getCountryForTimezone(tz.name);
  const countryStr = `/${country.name}/`;
  const tzNameWithoutOffset = `${tz.name.replace("/", countryStr).replaceAll("_", " ")}`;

  return showOffset ? `${tzNameWithoutOffset} (${tz.utcOffsetStr})` : tzNameWithoutOffset;
};

const getTZNameFromString = (tzName, showOffset) => {
  const timezoneList = ct.getAllTimezones();
  const timezone = Object.values(timezoneList).find(tz => tz.name === tzName);

  return timezone ? getTZNameFromObject(timezone, showOffset) : tzName;
};

export const getTZNameFormatted = (tz, showOffset) => {
  if (typeof tz === "string") {
    return getTZNameFromString(tz, showOffset);
  } else {
    return getTZNameFromObject(tz, showOffset);
  }
};

export const getLocalTZ = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getLocalTZNameFormatted = () => {
  const localTZ = getLocalTZ();
  return getTZNameFromString(localTZ, false);
};