import { sanitizeKeys, transformToQueryString } from "_helpers/utility";
import { request } from "_helpers/axios/gw.client";
import moment from "moment";

const defaultRequests = (res) => {
  return {
    query: { method: "GET", url: res, isArray: true },
    get: { method: "GET", url: `${res}/:id` },
    save: { method: "POST", url: `${res}` },
    update: { method: "PUT", url: `${res}/:id` },
    delete: { method: "DELETE", url: `${res}/:id` }
  };
};

const defaultProcessors = () => {
  return {
    /**
     * Default sort implementation of data.
     * @param {Array} data Data to be sorted
     * @param {String} sort_by Sort params where one parameter
     * is a string of 2 tokens separated by coma. The first token
     * is the field we sort on and the second token is a the sort direction.
     * E.g. created_at,asc. OR. updated_at,desc.
     * NOTE - only one sort parameter is supporting right now. We can concatenate
     * these tokens with & later to support multiple sorting.
     * @returns
     */
    sort: (data, sort_by) => {
      if (!sort_by) {
        return data;
      }
      // Sort by can come in the form of 'created_at,ascending'
      const tokens = sort_by.split(",");
      // For now just on sort field is supported. Otherwise
      // we should take in account for '&'. For example
      // we might have 'created_at,ascending&name=descending'
      const key = tokens[0];
      const direction = tokens.length > 1 ? tokens[1] : "asc";
      const down = ["desc", "descending"].includes(direction.toLowerCase());
      const up = !down;
      const res = data.sort((e1, e2) => {
        if (e1[key] < e2[key]) {
          return up ? -1 : 1;
        } else if (e1[key] > e2[key]) {
          return up ? 1 : -1;
        } else {
          return 0;
        }
      });
      return res;
    },

    /**
     * Default query implementation.
     * NOTE - this will return the data as is
     * so you need to overwrite the query for your own
     * resource because we can't predict how it should
     * be querried.
     * @param {Array} data data to query from
     * @param {Options} q query parameters
     */
    query: (data) => {
      return data;
    }
  };
};

class TwsResource {
  constructor(options) {
    // At the minimum, we need
    // an endpoint and resource
    if (!options.endpoint) {
      throw new Error("Endpoint required to initialize a TwsResource");
    }

    if (!options.resource) {
      throw new Error("Resource required to initialize a TwsResource");
    }

    this.endpoint = options.endpoint;
    this.resource = options.resource;
    this.name = options.name;
    this.processors = Object.assign(defaultProcessors(this.resource), options.processors);
    this.requests = Object.assign(
      defaultRequests(this.resource),
      options.requests
    );
    this.postUpdateFunctions = options.postUpdateFunctions;
    this.defaultPageSize = options.pageSize || 10000;
    this.storageKey = `sera4tal-${this.resource}`;
    this.timestampKey = `${this.storageKey}-last-updated`;
    this.data = [];

    const storage = localStorage.getItem(this.storageKey);

    if (storage) {
      try {
        this.data = JSON.parse(storage);
      } catch (e) {
        console.warn(`Failed to parse ${this.storageKey} from local storage`);
        console.warn(e);
      }
    }

    // For every request, register a new function to
    // this object to handle that particular request.
    Object.keys(this.requests).forEach((reqName) => {
      this[reqName] = this._registerRequestHandler(this.requests[reqName]);
    });
  }

  _registerRequestHandler(req) {
    return async function (options) {
      const { method, url, isArray } = req;
      switch (method.toLowerCase()) {
        case "get":
          return isArray
            ? this.fetchAll(url, options)
            : this.fetchOne(url, options);
        case "post":
          return this.performRequest({ url, method: "post", params: options });
        case "put":
          // TODO
          break;
        case "delete":
          // TODO
          break;
        default:
          console.warn(`${this.resource} doesn't support http ${method}`);
      }
    };
  }

  /**
   * Returns the storage/cache update timestamp
   * which is the UTC time we last updated our cache
   * with new data from the server.
   * @returns A string if available or null
   */
  getStorageTimestamp() {
    const storageTimestamp = localStorage.getItem(this.timestampKey);
    if (storageTimestamp && moment(storageTimestamp).isValid()) {
      return moment(storageTimestamp).toISOString();
    } else {
      return null;
    }
  }

  /**
   * Update the storage/cache update timestamp
   * @param {Date} date the new timestamp
   */
  updateStorageTimestamp(date) {
    localStorage.setItem(this.timestampKey, moment(date).toISOString());
  }

  /**
   * Appends a `updated_after=timestamp` query parameter
   * to the given query
   * @param {String} query the string query
   * @returns a new query with the the udpated_after parameter
   */
  appendUpdatedAfater(query) {
    const t = this.getStorageTimestamp();
    if (t) {
      if (query && query.length > 0) {
        return `${query}&updated_after=${t}`;
      } else {
        return `updated_after=${t}`;
      }
    } else {
      return query;
    }
  }

  /**
   * Removes everything from cache (both browser and memory)
   */
  flushCache() {
    console.debug(`Flushing TWS resource ${this.resource}`);
    localStorage.removeItem(this.storageKey);
    localStorage.removeItem(this.timestampKey);
    this.data = [];

    console.assert(!localStorage.getItem(this.storageKey), "Resource storage not emptied", this.resource);
    console.assert(!localStorage.getItem(this.timeStampKey), "Resource timestamp not reset", this.resource);
  }

  /**
   * Replaces specially marked tokens in a url with values
   * from an object that have their keys match our tokens.
   * For example a url '/users/:id/keys/:lock_id' and an
   * object {id: 1, lock_id: 56} will result in a string
   * '/users/1/keys/56;
   * @param {String} url containing our tokens
   * @param {Object} params object holding values referenced through tokens
   * @returns array of parameters found
   */
  extrapolateParams(url, params) {
    Object.keys(params).forEach((k) => {
      url = url.replace(`:${k}`, params[k]);
    });
    return url;
  }

  /**
   * Saves whatever we have in memory to local storage
   */
  recache(options) {
    localStorage.setItem(this.storageKey, JSON.stringify(this.data));
    if (this.postUpdateFunctions) {
      this.postUpdateFunctions(this.data, options);
    }
  }

  /**
   * Replaces the in memory and in cache object if one can be found,
   * otherwise, the new value is appended.
   * The replacement is done by finding old values that have
   * a matching "id" field
   * @param {Object} value the new value of the object
   */
  replace(value) {
    for (let i = 0; i < this.data.length; ++i) {
      const d = this.data[i];
      if (d.id === value.id) {
        this.data[i] = value;
        return;
      }
    }
    // if we reach here, the object couldn't be found
    // so we just append it
    this.data.push(value);
  }

  /**
   * Similar to replace method, but works on arrays.
   * @param {Array} values array of objects to replace
   */
  replaceAll(values) {
    values.forEach((v) => {
      this.replace(v);
    });
  }

  /**
   * Returns a subset of data sliced
   * according to page_size and page_index properties
   * of the given options object.
   * @param {Array} data Data to paginate
   * @param {Object} options An object containing
   * pagination and other query parameters
   * @returns subset of data
   */
  paginate(data, options) {
    // page starts at 1 and we need to translate that to a 0 based offset
    const { page_index, page_size } = options;
    const offset = page_size * page_index - page_size;
    const subset = data.slice(offset);
    const outOfBonds = subset.length === 0 && data.length !== 0;
    const pagination = {
      current_page: page_index,
      first_page: !outOfBonds && offset === 0,
      last_page: !outOfBonds && data.length <= offset * page_size + page_size,
      total_pages: Math.ceil(data.length / page_size),
      total_size: data.length,
      out_of_bounds: outOfBonds
    };
    return { pagination, data: subset };
  }

  /**
   * Perform a TWS request to fetch resources.
   * @param {Object} options Request options
   * @param {String} options.method The name of the request (query|get|delete|update|save)
   * @param {String} options.url Request url
   * @param {String} options.query Query parameters if any
   * @param {Object} options.params Request parameters
   * @returns a promise that will resolve the response from the server
   */
  async performRequest(options) {
    const { method, url, query = "", params = {} } = options;
    let theUrl = this.extrapolateParams(url, params);
    theUrl = `${this.endpoint}/${theUrl}`;

    // it is possible that the url already contains a query
    // because we might be dealing with complex/containing resources
    // so we need to determine if the provided query is appended as
    // a trailing query or as a new one
    if (query && theUrl.includes("?")) {
      theUrl = `${theUrl}&${query}`;
    } else if (query) {
      theUrl = `${theUrl}?${query}`;
    }
    return request({
      method: method,
      url: theUrl,
      data: params
    });
  }

  /**
   * Fetches data exclusively from cache.
   * The server is not queried and the cache update
   * timestamps are not touched.
   * @param {*} options Same options as for fetchAll
   * @returns
   */
  fetchAllFromCache(options = {}) {
    const { sort_by = "name" } = options;
    let data = this.processors.sort(this.data, sort_by);
    data = this.processors.query(data, options);
    data = this.paginate(data, options);
    return data;
  }

  /**
   * Fetches new data from the server or existing data from cache.
   * @param {Object} options Various options to fetching data.
   * These can be:
   * 1. options.refresh - If true, then a request to the server will
   *    be made to fetch records that have been updated after the last time
   *    we have fetched all the data. If this is false, then the data is retrieved
   *    from the cache without visiting the endpoint. NOTE - the endpoint must support
   *    fetching data based on last updated timestamp, otherwise this service will
   *    be fetching all the data. This is not going to be showing up as a bug in the UI
   *    since the local cache can handle duplicates, but it is better to have the support
   *    on the endpoint to reduce the performance impact
   * 2. options.q - This is the query parameters (including pagination) that will be
   *    send along with the request for resources
   * @returns a promise.
   */
  async fetchAll(url, options) {
    // Pagination is optional, so we need to
    // set our defaults if it's not provided.
    const pagination = {
      page_size: options.page_size || this.defaultPageSize,
      page_index: options.page_index || 1
    };
    const query = Object.assign(pagination, options.q);
    if (options.refresh) {
      let q = transformToQueryString(sanitizeKeys(query));
      q = this.appendUpdatedAfater(q);

      try {
        let res = await this.performRequest({ method: "get", query: q, url });
        if (Array.isArray(res)) {
          res = { data: res };
        }

        if (res.data) {
          this.updateStorageTimestamp(new Date());
          this.replaceAll(res.data);
          this.recache(options);
        }
        // Always return the items from the memory cache
        // because the HTTP request may be only returning a few or zero items
        // depending when they have been updated
        // Our memory cache, however, will contain everything after the http call
        return this.fetchAllFromCache(query);
      } catch (e) {
        console.warn(`Error fetching all ${this.resource}`);
        console.warn(e);
      }
    } else {
      return this.fetchAllFromCache(query);
    }
  }

  /**
   * Fetches on instance from cache.
   * @param {String} id the id of the object
   * @returns an object if found or undefined
   */
  fetchOneFromCache(id) {
    return this.data.find((d) => d.id === id);
  }

  /**
   * Fetches one instance from the backend or from cache.
   * @param {String} url the url for the endpoint
   * @param {Object} options query options. Following are supported:
   * 1. options.refresh - if true, a call to the backend will be made
   *    to fetch a new copy, otherwise the copy will be lookedup in the cache.
   *    NOTE - if this value is false (i.e. cache lookup) and an instance couldn't
   *    be found, a server call will be made to find one there after which the cache
   *    will be updated.
   * 2. options.q - the query itself. For example q: {id: 1234}
   * @returns
   */
  async fetchOne(url, options) {
    let data = null;
    if (!options.refresh && options.q.id) {
      data = this.fetchOneFromCache(options.q.id);
    }

    if (options.refresh || !data) {
      try {
        const res = await this.performRequest({
          method: "get",
          params: options.q,
          url
        });
        if (res) {
          this.replace(res);
          this.recache(options);
          data = res;
        }
      } catch (e) {
        console.warn(`Error fetching one ${this.resource}`);
        console.warn(e);
      }
    }
    return data;
  }
}

export default TwsResource;