import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    textOverflow: "ellipsis",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word"
  }
}));

export default function OverflowWrapper(props) {
  const { classes } = useStyles();

  return (
    <span data-tag="allowRowEvents" className={classes.root} title={props.title}>{props.title}</span>
  );
}

OverflowWrapper.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ])
};
