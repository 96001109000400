const termsAndConditionsStyle = theme => ({
  margins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  button: {
    textTransform: "none"
  },
  centerText: {
    textAlign: "center"
  }
});

export default termsAndConditionsStyle;