const sKey = 83;

const actionMaker = (e, action) => {
  e.preventDefault();
  action();
};

//the following functions are used to handle keydown events when passed to an event listener inside a component

export function quickSearch(e, action) {
  if (e.keyCode === sKey && e.ctrlKey) {
    actionMaker(e, action);
  }
}