/*eslint quote-props: ["warn", "as-needed"]*/
const lsyDevelopersStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100vh",
    height: "fit-content",
    paddingTop: "15px"
  },
  dataPlaceHolder: {
    width: "6vw"
  },
  cardBody: {
    margin: "20px",
    display: "flex",
    flexDirection: "column"
  },
  smallIcon: {
    transform: "scale(0.7)"
  },
  verticalAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  okIcon: {
    transition: "opacity 2s",
    opacity: 1,
    fill: "green"
  },
  textTop: {
    verticalAlign: "text-top"
  },
  text: {
    fontSize: theme.lsyPalette.body1,
    fontWeight: "400"
  },
  fadeIn: {
    animation: "fadeIn ease 2s",
    WebkitAnimation: "fadeIn ease 2s",
    MozAnimation: "fadeIn ease 2s",
    OAnimation: "fadeIn ease 2s",
    msAnimation: "fadeIn ease 2s"
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    }
  },
  "@keyframes fadeOut": {
    "0%": {
      opacity: 1
    },
    "100%": {
      opacity: 0,
      cursor: "none"
    }
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  signatureInput: {
    marginTop: 20
  },
  actionBox: {
    display: "flex",
    flexDirection: "row",
    minHeight: 50,
    minWidth: 200,
    paddingTop: "15px"
  },
  keyError: {
    fontSize: "20px",
    justifyContent: "center"
  },
  fadeInElement: {
    animation: "$fadeIn 1s linear forwards",
    WebkitAnimation: "$fadeIn 1s linear forwards"
  },
  fadeAfterRemoval: {
    animation: "$fadeOut .5s linear forwards",
    WebkitAnimation: "$fadeOut .5s linear forwards"
  },
  tabContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  endpointDataBox: {
    width: "100%",
    "svg:hover": {
      cursor: "pointer"
    }
  },
  codeContainer: {
    marginTop: theme.spacing(2),
    border: "1px solid",
    backgroundColor: theme.lsyPalette.codeBackgroundColor,
    borderColor: theme.lsyPalette.primary.light,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.xsFontSize,
    overflow: "auto",
    maxHeight: "400px",
    minWidth: "975px"
  },
  copyButton: {
    float: "right"
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  block: {
    display: "block"
  },
  title: {
    marginTop: theme.spacing(3)
  },
  button: {
    textTransform: "none",
    fontSize: theme.lsyPalette.body1
  }
});

export default lsyDevelopersStyle;
