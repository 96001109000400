const societyStyle = theme => ({
  society: {
    fill: theme.lsyPalette.primary.main,
    fillOpacity: "1",
    fillRule: "evenodd"
  },
  svg: {
  }
});

export default societyStyle;
