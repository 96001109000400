import { isEmpty } from "lodash";

import { formatDateAsYYYYMMDD } from "_helpers";

export const getFormattedFilterName = (filterOptions, filter) => {
  let name = "";
  const dataKeys = Object.keys(filter);
  const selectFields = ["role_id", "open", "type", "sort_by", "hardwareType", "lockType", "component", "connected_only", "tenant", "table"];
  const dateFields = ["start_date", "end_date"];
  dataKeys.forEach((field, idx) => {
    const filterOption = filterOptions.find(op => op.field === field) || {};
    if (isEmpty(filterOption)) {
      return;
    }
    
    const fieldName = filterOption.label || filterOption.placeholder || field;
    let value = filter[field];

    if (Array.isArray(value) && value.length > 0) {
      value = value.map((v) => v.name).join(", ");
    } else if (dateFields.includes(field) && typeof value === "object") {
      value = formatDateAsYYYYMMDD(value);
    } else if (typeof value === "object") {
      value = value.name;
    } else if (selectFields.includes(field)) {
      value = filterOption?.options?.find(role => role.value === value)?.name;
    }

    name += `${fieldName}: ${value ??= ""}${idx < dataKeys.length - 1 ? "| " : ""}`;
  });

  return name;
};