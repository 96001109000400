const roleWizardStyle = theme => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title2
  },
  title: {
    marginLeft: theme.spacing(2),
    color: theme.lsyPalette.textContrast,
    flex: 1
  },
  borderDiv: {
    height: "100%"
  },
  container1: {
    minHeight: "100vh",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px"
    },
    backgroundColor: theme.lsyPalette.secondary.background
  },
  container2: {
    minHeight: "100vh"
  },
  item1: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.lsyPalette.secondary.main,
    height: "100%"
  },
  expandIcon: {
    padding: "0 12px"
  },
  accordian: {
    backgroundColor: theme.lsyPalette.secondary.background
  },
  accordianSummary: {
    height: "48px"
  },
  accordianDetails: {
    backgroundColor: theme.lsyPalette.secondary.main
  },
  formGroup: {
    marginLeft: "20px"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px"
  },
  itemGrid: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  accordianItem: {
    marginBottom: "15px"
  },
  label: {
    fontSize: theme.lsyPalette.body2,
    color: theme.lsyPalette.text
  },
  table: {
    "height": "100%",
    "width": "100%",
    "& td": {
      textAlign: "center"
    },
    "paddingBottom": "25px"
  },
  legend: {
    "& td": {
      textAlign: "left",
      width: "70%",
      letterSpacing: "1px", 
      fontSize: "15px", 
      fontWeight: "400", 
      color: "#5f7080"
    }
  },
  tableItem: {
    alignSelf: "center",
    width: "100%"
  },
  actionTitle: {
    borderBottom: `1px solid ${theme.lsyPalette.primary.main}`
  },
  paper: {
    backgroundColor: theme.lsyPalette.secondary.background
  }
});

export default roleWizardStyle;