import gwClient from "_helpers/axios/gw.client";

import {
  handleResponse,
  identityUrl
} from "_services/helper";

const fetchLoginsById = (props) => {
  const requestOptions = {
    method: "GET",
    url: `${identityUrl}/login_trails/accounts/${props.uuid}?page_index=${props.page}&page_size=${props.page_size}`
  };
  return gwClient(requestOptions)
    .then(r => {
      return handleResponse(r);
    })
    .then(logins => {
      return logins;
    });
};

export const loginTrailService = {
  fetchLoginsById
};