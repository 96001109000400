export const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
const subdmoainRegex = /^((?!-)[A-Z0-9-]{3,50}(?<!-))$/i;

export const uuidValidator = (uuid) => {
  return uuidRegex.test(uuid);
};

export function isValidPublicKey(input) {
  return input && input.length === 96 && /^[A-Fa-f0-9]+$/.test(input);
}

// is this a list of lockIDs (1, 0x1, 0x2b) - comma separated
export const lockIDValidator = (values) => {
  if (values) {
    values = values.replace(/[\s\n]+/g, "");
    const arrRegex = /^([A-FXa-fx0-9],{0,1})\s*([A-FXa-fx0-9],{0,1})*$/;
    const noTrailingCommaRegex = /[^,]$/;
    return arrRegex.test(values) && noTrailingCommaRegex.test(values);
  }

  return false;
};

export function isValidSubdomain(subdomain) {
  return subdomain && subdmoainRegex.test(subdomain);
}

export const userNameCannotContainsRegex = /^[^,@/\\]+$/;
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const usernameRegex = /^[A-Za-z][A-Za-z0-9-]{3,64}$/i;
export const invalidSearchCharsRegex = /[!%&*':;<>,.?\\/]/i;
export const invalidTagRegex = /[#\r\n./[\]()?,;]/;