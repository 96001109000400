import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { authActions, localeActions, memberActions, modalActions, alertActions } from "_actions";
import { config } from "_configs/server-config";
import { translateFormatter } from "_utils";
import { lsyRouter, membershipHelper, sortByKey, getSupportResourcesUrl } from "_helpers";

// @mui/material components
import {
  Autocomplete,
  TextField,
  Button,
  List,
  Divider,
  ListItem,
  ListItemText
} from "@mui/material";

import PrivateNotificationPanel from "_components/Notifier/PrivateNotificationPanel";
import SelectLanguage from "_components/Select/SelectLanguage";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

//icons
import {
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  Launch as LaunchIcon
} from "@mui/icons-material";

//Styles
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lsyRightDrawerStyle.js";

import { setTitle } from "_helpers/page-title";
import { userService } from "_services/lockstasy";
import { authService } from "_services";
import { memberService } from "_services/member.service";

const useStyles = makeStyles()(styles);

function LsyRightDrawer(props) {
  const { t } = useTranslation(["default", "memberships"]);
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const memberships = useSelector((state) => state.memberships.list); //unfiltered list
  const canAccessSera4tal = useSelector((state) => state.memberships.hasSera4talMembership);
  const trustedAdminMembership = membershipHelper.getTrustedAdminMembership(memberships);
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const { userNotifications, setUserNotifications, content } = props;

  const confirmPendingConsent = async (_event, membership) => {
    try {
      await memberService.confirmPendingConsent(auth.user.id, membership.id, membership.server_alias);
      props.onChange(_event, membership);
    } catch (e) {
      dispatch(alertActions.send(t("memberships:membership.consent.error"), "error"));
    }
    dispatch(modalActions.hideModal());
  };

  const sendPasswordlessEmail = async () => {
    try {
      await authService.loginPasswordless({ username: auth.user.email });
    } catch (e) {
      console.debug("Error to send email", e);
    }
  };

  const handleUnauthorizedAccess = () => {
    dispatch(authActions.logout());
  };

  const openMembershipActionModal = (_event, m) => {
    const options = {
      open: true
    };

    const formatter = translateFormatter();
    formatter.setBoldKeys(m);

    if (membershipHelper.isDisabled(m)) {
      options["title"] = t("memberships:membership.suspended.title");
      options["message"] = t("memberships:membership.suspended.body", formatter.getInterpolatedKeys());
      options["customButtonText"] = t("default:button.dismiss");
    } else if (!m.authorized) {
      options["title"] = t("memberships:membership.unauthorized.title");
      options["message"] = t("memberships:membership.unauthorized.body", formatter.getInterpolatedKeys());
      options["cancelButtonText"] = t("default:button.ok");
      options["onClose"] = handleUnauthorizedAccess;
      sendPasswordlessEmail();
    } else  if (membershipHelper.isPending(m)) {
      formatter.setBoldText("user_name", auth.user.first_name);
      formatter.setBoldText("name", t("default:form.name"));
      formatter.setBoldText("email", t("auth:login.emailAddress"));
      options["title"] = t("memberships:membership.consent.required");
      options["message"] = t("memberships:membership.consent.pleaseAccept", formatter.getInterpolatedKeys());
      options["customButtonText"] = t("default:button.accept");
      options["cancelButtonText"] = t("default:button.decline");
      options["customButtonHandler"] = () => confirmPendingConsent(_event, m);
    } else {
      // this will be a bug, so let"s show
      // an empty dialog so users can report it
    }
    dispatch(modalActions.showModal({ modalProps: options, modalType: "custom" }));
  };

  const onChangeOrganization = (_event, mem) => {
    if (membershipHelper.isActive(mem) && mem.authorized) {
      props.onChange(_event, mem);
      props.toggleDrawer("right", false)(_event);
    } else {
      openMembershipActionModal(_event, mem);
    }
  };

  const renderNotifications = () => {
    return <PrivateNotificationPanel notifications={userNotifications} setNotifications={setUserNotifications} />;
  };

  const onChangeSera4Mode = () => {
    dispatch(authActions.setSera4Mode(!auth.sera4Mode));
  };

  const handleSupportResources = (e) => {
    window.open(getSupportResourcesUrl(props.language), "_blank");
    props.toggleDrawer("right", false)(e);
  };

  const renderSettings = () => {
    const onChangeLanguage = value => {
      try {
        userService.updateUser({ membershipId: currentMembership.id, payload: { language: value } });
      } catch (e) {
        console.debug("Error updating user:", e);
      }
      dispatch(localeActions.setLocale({ language: value }));
    };

    const isSera4talAuthorized = membershipHelper.isSera4talAuthorized(memberships);

    const handleAccessSera4tal = (event) => {
      if (isSera4talAuthorized) {
        props.history.push1(`${config.adminUrl}/dashboard`, event);
        return;
      }
  
      const formatter = translateFormatter();
  
      const options = {
        open: true,
        title: t("memberships:membership.enforceMFA.title"),
        message: t("memberships:membership.enforceMFA.body", formatter.getInterpolatedKeys())
      };
      
      dispatch(modalActions.showModal({ modalProps: options, modalType: "alert" }));
    };

    return <List data-testid="contentList">
      {canAccessSera4tal && isSera4talAuthorized ?
        <ListItem>
          <div className={classes.listItem}>
            <span>SERA4 MODE</span>
            <span>
              <ToggleSwitch
                onColor={theme.lsyPalette.primary.mainDark}
                checked={auth.sera4Mode || false}
                handleChange={onChangeSera4Mode}
                className={classes.toggleSwitch}
              />
            </span>
          </div>
        </ListItem> : null
      }
      <ListItem>
        <SelectLanguage showLabel={true} language={props.language} handleSubmit={onChangeLanguage} selectClass={classes.language}/>
      </ListItem>
      {props.memberships.length > 1 ? (
        <ListItem>
          <Autocomplete
            id="membership-selector-drawer"
            loading={!props.memberships}
            classes={{
              root: classes.autocompleteDrawer,
              paper: classes.menuContainer,
              inputRoot: classes.autocompleteLabel,
              hasPopupIcon: classes.icon,
              popupIndicator: classes.popupIndicator,
              option: classes.autocompleteLabel
            }}
            autoHighlight={true}
            autoSelect={true}
            getOptionLabel={(option) => option.tenant_name}
            options={sortByKey(props.memberships
              .filter((membership) => membership.s4_role !== 1), "tenant_name")
            }
            onChange={onChangeOrganization}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("instructions.changeOrg")}
                InputLabelProps={{
                  classes: {
                    root: classes.autocompleteLabel,
                    focused: "focused"
                  }
                }}
                variant="outlined"
              />
            )}
          />
        </ListItem>
      ) : null}
      {trustedAdminMembership ?
        <ListItem>
          <Button
            onClick={() => {
              dispatch(memberActions.storeCurrentMembership(trustedAdminMembership));
              props.history.push1(`${config.tapUrl}`);
            }
            }
            className={classes.accountInfoButton}
            startIcon={<ArrowBackIcon />}
          >
            {t("label.trustedAdministrator")}
          </Button>
        </ListItem> : null}
      {canAccessSera4tal ?
        <ListItem>
          <Button
            onClick={handleAccessSera4tal}
            className={classes.accountInfoButton}
            startIcon={<ArrowBackIcon />}
          >
            SERA4TAL
          </Button>
        </ListItem> : null}
      <ListItem>
        <Button
          onClick={(e) => {
            props.history.push(
              lsyRouter("user", currentMembership.id),
              e
            );
            props.toggleDrawer("right", false)(e);
          }}
          startIcon={<InfoOutlinedIcon />}
          className={classes.accountInfoButton}
        >
          {t("button.accountInfo")}
        </Button>
      </ListItem>
      <ListItem>
        <Button
          onClick={handleSupportResources}
          startIcon={<HelpOutlineIcon />}
          endIcon={<LaunchIcon />}
          className={classes.accountInfoButton}
        >
          {t("button.supportResources")}
        </Button>
      </ListItem>
      <ListItem>
        <Button
          onClick={() => {
            setTitle();
            dispatch(authActions.logout());
          }}
          startIcon={<ExitToAppOutlinedIcon />}
          className={classes.logoutButton}
        >
          {t("button.signOut")}
        </Button>
      </ListItem>
    </List>;
  };

  const getTitle = () => {
    if (content === "user") {
      return props.authUser.firstName + " " + props.authUser.lastName;
    } else if (content === "notifications") {
      return t("label.notifications");
    }
  };

  const renderContent = () => {
    if (content === "user") {
      return renderSettings();
    } else if (content === "notifications") {
      return renderNotifications();
    }
  };

  return (
    <div
      className={classes.drawer}
    >
      <List data-testid="headerList">
        <ListItem>
          <ListItemText
            disableTypography
            className={classes.authUserFullName}
            primary={getTitle()}
          />
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      {renderContent()}
    </div>
  );
}
LsyRightDrawer.propTypes = {
  onChange: PropTypes.func,
  toggleDrawer: PropTypes.func,
  language: PropTypes.string,
  history: PropTypes.object,
  memberships: PropTypes.array,
  userNotifications: PropTypes.array,
  setUserNotifications: PropTypes.func,
  authUser: PropTypes.object,
  content: PropTypes.string
};

export default LsyRightDrawer;
