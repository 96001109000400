import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Spring } from "react-spring/renderprops.cjs";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Loader from "_components/Loading";
import { Trail } from "_effects/Trail";

import TeleporteTextLogo from "assets/teleporte/TeleporteTextLogo.js";
import Sera4Icon from "assets/teleporte/Sera4Icon.js";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Auth/loginFrameStyle.js";

const useStyles = makeStyles()(styles);

const LoginFrame = (props) => {
  const { classes } = useStyles();
  const { open, children } = props;
  const { t } = useTranslation("default");

  const renderLoginFrame = props => {
    return <Grid container style={props} direction="column" justifyContent="center" alignItems="center">
      <Grid item className={classes.container}>
        <Grid container justifyContent="center" alignItems="center" >
          <Grid item xs={10} sm={12} color="textPrimary">
            <div className={classes.s4Logo}>
              <Trail open={open}>
                <div className={classes.icon}>
                  <TeleporteTextLogo />
                </div>
              </Trail>
            </div>
            <Paper>
              <div className={classes.s4LogoIconContainer}>
                <div>
                  <div className={classes.s4LogoIcon}>
                    <Sera4Icon
                      animated={true}
                      style={{ transform: "rotate(-45deg)" }}
                    />
                  </div>
                </div>
              </div>
              {children}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  return children ? 
    <Spring
      from={{ opacity: 0.6, marginTop: -200 }}
      to={{ opacity: 1, marginTop: 0 }}
    >
      {renderLoginFrame}
    </Spring> :
    <Loader title={t("actions.authenticating")} />;
};

LoginFrame.defaultProps = {
  open: true
};

LoginFrame.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool
};

export default LoginFrame;