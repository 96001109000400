const tagsStyle = (theme) => ({
  sidebar: {
    color: theme.lsyPalette.text,
    width: "100%",
    minHeight: "100%",
    height: "85vh",
    borderRadius: "4px",
    fontSize: ".875rem"
  },
  tagWidget: {
    marginTop: "20px"
  },
  gridItem: {
    margin: "5px"
  },
  card: {
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  tagCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    color: "green"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  cardContent: {
    flexGrow: "1"
  },
  tagsCardContent: {
    display: "flex",
    justifyContent: "center",
    overflowX: "auto",
    color: "green"
  },
  divBar: {
    width: "100%", 
    height: "15px", 
    backgroundColor: theme.lsyPalette.secondary.background
  },
  tagsDelete: {
    marginRight: "25px",
    color: theme.lsyPalette.primary.main
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  name: {
    color: theme.lsyPalette.primary.main,
    cursor: "pointer",
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: theme.lsyPalette.title1
  },
  description: {
    color: theme.lsyPalette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    fontSize: theme.lsyPalette.body1
  },
  field: {
    color: theme.lsyPalette.primary.mainLight,
    fontSize: theme.lsyPalette.body1
  },
  value: {
    marginLeft: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1
  },
  columnDiv: {
    display: "flex",
    flexDirection: "column",
    margin: 0
  },
  columnField: {
    color: theme.lsyPalette.primary.mainLight
  },
  columnValue: {
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1,
    textAlign: "center"
  },
  userColumn: {
    "marginRight": "25px",
    "&:last-child": {
      paddingBottom: "16px"
    }
  },
  invisible: {
    marginTop: "-20px",
    visibility: "hidden"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  tagsItem: {
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      justifyContents: "center",
      marginLeft: "10px",
      marginRight: "10px"
    }
  },
  addButton: {
    marginLeft: "auto"
  },
  editSite: {
    color: theme.lsyPalette.primary.mainDark
  },
  icon: {
    color: "black"
  },
  tagIcon: {
    marginLeft: "5px"
  },
  lockIcon: {
    color: theme.lsyPalette.primary.main,
    marginLeft: "15px"
  },
  arrowDownIcon: {
    color: "black",
    transition: "transform 0.5s"
  },
  arrowUpIcon: {
    color: "black",
    transform: "rotateX(180deg)",
    transition: "transform 0.5s"
  },
  tagDetailGrid: {
    paddingLeft: "10px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  tagIconItem: {
    alignSelf: "flex-end"
  },
  tagNameItem: {
    paddingLeft: "5px"
  },
  tagName: {
    color: theme.lsyPalette.primary.main,
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    }
  },
  tagDescription: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body2
  },
  settingsItem: {
    alignSelf: "flex-end",
    marginLeft: "auto"
  },
  modalButton: {
    display: "flex",
    color: theme.lsyPalette.textContrast,
    marginRight: "1em",
    marginLeft: "2em",
    textTransform: "none"
  },
  button: {
    padding: "5px",
    paddingLeft: "1em",
    paddingRight: "1em",
    display: "relative",
    color:"black", 
    textTransform: "none"
  },
  title: {
    fontSize: "20px",
    marginRight: "10px",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  tagsIcon: {
    color: theme.lsyPalette.primary.mainDark
  },
  editIcon: {
    color: theme.lsyPalette.primary.mainDark,
    transform: "scale(0.75)"
  },
  header: {
    display:"flex",
    marginTop: "1em",
    marginBottom: "1em"
  },
  text: {
    flex: 3,
    marginLeft: "auto"
  },
  removeButton: {
    paddingRight: "1em",
    paddingLeft: "1em",
    paddingTop: "0",
    paddingBottom: "0",
    float: "right",
    color: "black"
  },
  buttonGroup: {
    flexDirection: "row-reverse",
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      justifyContents: "center",
      marginLeft: "10px",
      marginRight: "10px"
    }
  },
  itemNames: {
    marginTop: "10px",
    marginBottom: "5px"
  },
  itemName: {
    marginTop: "8px",
    marginBottom: "8px",
    paddingRight: "15px",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    justifyContent: "center"
  },
  linkName: {
    cursor: "pointer",
    marginTop: "15px",
    marginBottom: "15px"
  },
  removeIconWrap: {
    marginTop: 0,
    marginBottom: "8px",
    paddingRight: "15px",
    width: "150px",
    justifyContent: "flex-end"
  },
  additionIcon: {
    color: theme.lsyPalette.secondary.main
  },
  loading: {
    marginTop: "10vh"
  },
  tagDelete: {
    float: "right"
  },
  buttonActive: {
    "padding": "5px",
    "paddingLeft": "1em",
    "paddingRight": "1em",
    "display": "relative",
    "backgroundColor": theme.lsyPalette.primary.main,
    "textTransform": "none",
    "color": "white",
    "&:hover": {
      backgroundColor: theme.lsyPalette.primary.main
    }
  },
  row: {
    justifyContent: "center"
  },
  testIcon: {
    "transition": "transform 0.8s",
    "&:hover": {
      transform: "rotateY(180deg)"
    }
  }
});
export default tagsStyle;