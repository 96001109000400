import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { config } from "_configs/server-config";
import { SUPPORT_EMAIL, getSupportResourcesUrl } from "_helpers";
import { versionService } from "_services/lockstasy";

import {
  Grid,
  Typography
} from "@mui/material";

import Sera4Logo from "assets/images/logos/sera4-teleporte.svg";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/aboutStyle";

const useStyles = makeStyles()(styles);

const About = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const currentLanguage = useSelector((state) => state.locale.language);
  const [data, setData] = useState();

  const fetchFooterData = async () => {
    try {
      const result = await versionService.fetchVersion();
      setData(result.data);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    fetchFooterData();
  }, []);

  const openKnowledgeBase = () => window.open(getSupportResourcesUrl(currentLanguage), "_blank");

  const renderLogoSection = () => {
    return <Grid item>
      <img src={Sera4Logo} width={400}/>
    </Grid>;
  };

  const renderDisclaimerSection = () => {
    return <Grid item>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body1" align="center">{t("disclaimer.localized")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t("disclaimer.copyright", { year: new Date().getFullYear()})}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="cente" spacing={2}>
            <Grid item>
              <a rel="noreferrer" href="http://www.sera4.com/terms-of-use/" target="_blank" className={classes.link}>
                {t("footer.termsOfService")}
              </a>
            </Grid>
            <Grid item>
              <a rel="noreferrer" href="http://www.sera4.com/privacy-policy/" target="_blank" className={classes.link}>
                {t("footer.privacy")}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const renderVersionSection = () => {
    if (!data) {
      return null;
    }

    const dataVersion = {lsyVersion: data.version, lsySha: data.sha, teleporteVersion: config.swVersion};

    return <Grid item>
      <Typography variant="body1">
        {t("disclaimer.systemVersion", dataVersion)}
      </Typography>
    </Grid>;
  };

  const renderContactSection = () => {
    const knowledgeData = {
      knowledgeBase: <span className={classes.link} onClick={openKnowledgeBase}>{t("disclaimer.knowledgeBase")}</span>
    };

    return <Grid item>
      <Typography variant="body1" align="center">{t("disclaimer.visitKnowledgeBase", knowledgeData)}</Typography>
      <Typography variant="body1" align="center">{t("disclaimer.contactUs", { email: SUPPORT_EMAIL })}</Typography>
    </Grid>;
  };

  return <Grid container alignItems="center" justifyContent="center" className={classes.marginTop}>
    <Grid item xs={11}>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={10}>
        {renderLogoSection()}
        {renderDisclaimerSection()}
        {renderVersionSection()}
        {renderContactSection()}
      </Grid>
    </Grid>
  </Grid>;
};

export default About;