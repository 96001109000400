import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { LOCALES } from "_constants/locale.constants";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Select/selectLanguageStyle.js";

const useStyles = makeStyles()(styles);

const SelectLanguage = (props) => {
  const { t } = useTranslation("default");
  const { classes, cx } = useStyles();
  const { language, handleSubmit, showLabel, selectClass } = props;
  const inputProps = { variant: "outlined", ...props.inputProps};
  const label = showLabel ? {label: t("label.language")} : {};
  const currentLanguage = useSelector((state) => state.locale.language);
  const localStorageLanguage = localStorage.getItem("language");

  const selectOptions = Object.keys(LOCALES).sort().map((key) => {
    return { name: LOCALES[key].name, value: key };
  });

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      handleSubmit(event.currentTarget.dataset.value);
    }
  };

  const onChange = (event) => {
    handleSubmit(event.target.value);
  };

  return <FormControl variant={inputProps.variant} fullWidth>
    {showLabel ? <InputLabel>{t("label.language")}</InputLabel> : null}
    <Select
      className={cx(classes.select, selectClass)}
      {...label}
      onChange={onChange}
      value={language || localStorageLanguage || currentLanguage || ""}
      fullWidth
      MenuProps={{ MenuListProps: { disablePadding: true } }}
    >
      {selectOptions.map((item, index) => {
        return (
          <MenuItem
            key={`language-${index}`}
            onKeyDown={handleKeyDown}
            value={item.value}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>;
};

SelectLanguage.defaultProps = {
  selectClass: "",
  handleSubmit: () => {}
};

SelectLanguage.propTypes = {
  language: PropTypes.string,
  handleSubmit: PropTypes.func,
  inputProps: PropTypes.object,
  showLabel: PropTypes.bool,
  selectClass: PropTypes.string
};

export default SelectLanguage;