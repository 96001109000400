export const keyStatus = Object.freeze({
  ACTIVE: 0, 
  DELETED: 1, 
  EXPIRED: 2 
});

export const keyStatusName = Object.freeze({
  0: "label.active",
  1: "label.deactivated",
  2: "label.expired"
});