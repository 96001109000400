import { useState, useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import RegularButton from "_components/Button/RegularButton";
import { convertToHex } from "_helpers";
import styles from "assets/jss/views/admin/rtmStyle.js";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import SimpleSelect from "_components/Select/SimpleSelect";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Collapsable from "_components/Collapsabale/Collapsable";
import { WebSocketDataProvider, WebSocketDataContext } from "_contexts/WebSocketData/WebSocketData";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles()(styles);

const PayloadTypes = [
  {id: 1, name: "req-stat"},
  {id: 2, name: "req-log"},
  {id: 3, name: "rtm-config"},
  {id: 4, name: "ble-command"},
  {id: 5, name: "disconnect"}
];

const RTM_LIVE_STREAM_EVENT = "rtm-live-stream";

const Message = (props) => {
  const { classes, cx } = useStyles();
  const {msg, collapsed} = props;

  return <Box className={classes.rtmMessageBox}>
    <Collapsable
      title={moment(msg.timestamp).format("YYYY/MM/DD HH:mm:ss")}
      collapsed={collapsed}
      contentClassName={cx(classes.configValues, classes.hiddenOverflow, classes.collapsedConfigValues)}
      headerClassName={classes.collapseableTitleBar}
      footerClassName={classes.collapseableFooter}
      chevronSize={5}
    >
      <Box>
        {JSON.stringify(msg.data, "", 2)}
      </Box>
    </Collapsable>
  </Box>;
};

const SessionDialog = (props) => {
  const {open, onClose, rtm} = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [payloadType, setPayloadType] = useState(PayloadTypes[0].id);
  const [messagesUp, setMessagesUp] = useState([]);
  const [messagesDown, setMessagesDown] = useState([]);
  const [configs, setConfigs] = useState("");
  const [configsError, setConfigsError] = useState(false);
  const [lastEvent, setLastEvent] = useState({});
  const upLink = `0x${convertToHex(rtm.lock.id, 8).toLowerCase()}.up`;
  const downLink = `0x${convertToHex(rtm.lock.id, 8).toLowerCase()}.down`;
  const webSocketContext = useContext(WebSocketDataContext);

  function handleEvent(ev) {
    setLastEvent(ev);
  }

  function handleClose() {
    webSocketContext.closeSocket();
    window.removeEventListener(RTM_LIVE_STREAM_EVENT, handleEvent, false);
    setMessagesUp([]);
    setMessagesDown([]);
    onClose();
  }

  function changePayloadType(event) {
    setPayloadType(event.target.value);
  }

  useEffect(() => {
    const { detail } = lastEvent;
    if (detail) {
      const { routing_key, data } = detail;
      const timestamp = new Date();
      if (routing_key.includes("up")) {
        setMessagesUp(old => [{timestamp, data}, ...old]);
      } else if (routing_key.includes("down")) {
        setMessagesDown(old => [{timestamp, data}, ...old]);
      } else {
        console.error("can't handle event", lastEvent);
      }
    }
  }, [lastEvent]);

  function sendMessage() {
    // Check out this page for formatting the data
    // https://lockedup.atlassian.net/wiki/spaces/TWS/pages/967901185/IoT+and+TWS#Messages-from-server
    webSocketContext.sendMessage({
      command: "rtm_message",
      identifier: "lockdepot",
      data: {
        action : PayloadTypes.find(pt => pt.id === payloadType)?.name,
        content_type : "text/plain",
        src : "s4tal",
        payload : JSON.parse(configs)
      }
    });
  }

  function handleConfigChanges(data) {
    try {
      JSON.parse(data);
      setConfigsError(false);
    } catch (e) {
      setConfigsError(true);
    }
    setConfigs(data);
  }

  function toggleConnection() {
    if (webSocketContext.webSocketActive) {
      webSocketContext.closeSocket();
      window.removeEventListener(RTM_LIVE_STREAM_EVENT, handleEvent, false);
    } else {
      webSocketContext.initiateSocket();
      window.addEventListener(RTM_LIVE_STREAM_EVENT, handleEvent);
    }
  }

  function getToolTip(column) {
    return (
      <CopyToClipboard text={JSON.stringify(column.map(c => c.data), null, 2)}>
        <Tooltip
          id="tooltip-top"
          title="Copy Data To Clipboard"
          placement="bottom"
        >
          <IconButton size="small">
            <FileCopyIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    );
  }

  function getDeleteAction(column) {
    return (
      <Tooltip
        id="tooltip-top"
        title="Clear"
        placement="bottom"
      >
        <IconButton
          size="small"
          onClick={() => {
            if (column === "up") {
              setMessagesUp([]);
            } else {
              setMessagesDown([]);
            }
          }}
        >
          <DeleteIcon  fontSize="small"/>
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"} fullScreen={fullScreen}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={8}>
            {`Live Debug Session for 0x${convertToHex(rtm.lock.id)} (${webSocketContext.webSocketState})`}
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <RegularButton onClick={toggleConnection}>{webSocketContext.webSocketActive ? "Disconnect Websocket" : "Connect Websocket"}</RegularButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={6}>
                <SimpleSelect
                  title="Payload type"
                  options={PayloadTypes}
                  selectedValue={payloadType}
                  onChange={changePayloadType}
                >
                </SimpleSelect>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <RegularButton disabled={configsError || !configs || !webSocketContext.webSocketActive} onClick={sendMessage}>Send Payload</RegularButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              multiline
              fullWidth
              rows={10}
              value={configs}
              disabled={!webSocketContext.webSocketActive}
              placeholder={"Type in your JSON payload here. Ensure you have a connected session first."}
              onChange={event => handleConfigChanges(event.target.value)}
              InputProps={{
                disableUnderline: true,
                className : (configs?.length > 0 && configsError) ? classes.borderError : "",
                classes: {root: classes.configValues}
              }}>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.tableName}>
              <Typography variant="inherit">
                Please review <a
                  href="https://lockedup.atlassian.net/wiki/spaces/TWS/pages/967901185/IoT+and+TWS#Messages-from-server"
                  target="_blank"
                  rel="noreferrer"
                >
                this wiki
                </a> for reference on supported payloads.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.tableName}>
              <Typography variant="h5">
                Payload Records
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Typography>
                              {`${downLink} - ${messagesDown.length} message(s)`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {messagesDown.length > 0 && <Box display="flex" justifyContent={"flex-end"}>
                              {getToolTip(messagesDown)}
                              {getDeleteAction("down")}
                            </Box>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {messagesDown.map((m, i) => {
                      return <Message msg={m} key={i} collapsed={i !== 0}></Message>;
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Typography>
                              {`${upLink} - ${messagesUp.length} message(s)`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {messagesUp.length > 0 && <Box display="flex" justifyContent={"flex-end"}>
                              {getToolTip(messagesUp)}
                              {getDeleteAction("up")}
                            </Box>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {messagesUp.map((m, i) => {
                      return <Message msg={m} key={i} collapsed={i !== 0}></Message>;
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <RegularButton onClick={handleClose}>End Session</RegularButton>
      </DialogActions>
    </Dialog>
  );
};

export default function LiveDebugSession(props) {
  const {rtm} = props;
  const connectionParams = {
    query: {
      service: "lockdepot",
      path: "ws/rtmlive"
    },
    payload: {
      command: "subscribe",
      identifier: "lockdepot",
      data: {
        rtm_topics : [
          `0x${convertToHex(rtm.lock.id, 8).toLowerCase()}.up`,
          `0x${convertToHex(rtm.lock.id, 8).toLowerCase()}.down`
        ]
      }
    }
  };
  return (
    <WebSocketDataProvider connectionParams={connectionParams} autoConnection={false}>
      <SessionDialog {...props}></SessionDialog>
    </WebSocketDataProvider>
  );
}

SessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rtm: PropTypes.object.isRequired
};
LiveDebugSession.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rtm: PropTypes.object.isRequired
};

Message.propTypes = {
  msg: PropTypes.object.isRequired,
  collapsed: PropTypes.bool
};
