const sitesStyle = (theme) => ({
  card: {
    marginTop: "5px",
    backgroundColor: theme.lsyPalette.secondary.main
  },
  siteCardContent: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    color: "green"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  cardContent: {
    flexGrow: "1"
  },
  siteDelete: {
    marginRight: "25px",
    color: theme.lsyPalette.primary.main
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%"
  },
  cardIcon: {
    margin: "0px 20px",
    color: theme.lsyPalette.primary.main
  },
  name: {
    color: theme.lsyPalette.primary.main,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    fontSize: theme.lsyPalette.title1
  },
  description: {
    color: theme.lsyPalette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    fontSize: theme.lsyPalette.body1
  },
  field: {
    color: theme.lsyPalette.primary.mainLight,
    fontSize: theme.lsyPalette.body1
  },
  value: {
    marginLeft: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1
  },
  columnDiv: {
    display: "flex",
    flexDirection: "column"
  },
  columnField: {
    color: theme.lsyPalette.primary.mainLight
  },
  columnValue: {
    color: theme.lsyPalette.primary.mainDark,
    fontSize: theme.lsyPalette.body1,
    textAlign: "center"
  },
  keyColumn: {
    "marginRight": "25px",
    "&:last-child":{
      paddingBottom: "16px"
    }
  },
  invisible: {
    marginTop: "-20px",
    visibility: "hidden"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  addIcon: {
    color: theme.lsyPalette.secondary.main
  },
  sitesWidget: {
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContents: "center",
      marginLeft: "10px",
      marginRight: "10px"
    }
  },
  editSite: {
    color: theme.lsyPalette.primary.mainDark
  },
  icon: {
    color: theme.lsyPalette.secondary.main
  },
  disabledColor: {
    color: theme.lsyPalette.primary.mainLight
  },
  siteIcon: {
    marginLeft: "5px"
  },
  lockIcon:{
    color: theme.lsyPalette.primary.main,
    marginLeft: "15px"
  },
  siteDetailGrid: {
    paddingLeft: "10px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  siteIconItem: {
    alignSelf: "flex-end"
  },
  siteNameItem: {
    paddingLeft: "5px"
  },
  siteName: {
    color: theme.lsyPalette.primary.main,
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    }
  },
  siteDescription: {
    color: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.body2
  },
  settingsItem: {
    alignSelf: "flex-end",
    marginLeft: "auto"
  },
  divBar: {
    width: "100%",
    height: "15px",
    backgroundColor: theme.lsyPalette.secondary.background
  }
});
export default sitesStyle;