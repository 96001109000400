import { useState, useEffect, useReducer, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { siteService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { fetchErrors } from "_utils";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";

//icons
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SettingsIcon from "@mui/icons-material/Settings";
import SiteIcon from "assets/teleporte/SiteIcon";
import GenericLock from "assets/teleporte/GenericLock";

import CardListWidget from "_containers/Widgets/CardListWidget";
import LockCard from "_components/Lockstasy/LockCard";
import KeyCard from "_components/Lockstasy/KeyCard";
import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import GridItem from "components/Grid/GridItem";
import CustomModal from "_components/Modal/CustomModal";
import Placeholder from "_components/Helper/Placeholder";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/sitesStyle.js";

const useStyles = makeStyles()(styles);

function Site(props) {
  const [wizard, setWizard] = useState({ type: "lock", open: false, key: 0, fetchWidgetData: null });
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: null,
      editModalOpen: false,
      submitting: false,
      existsLockSemaphoreLocked: false
    }
  );
  const setData = (data) => setState({ data });
  const setEditModalOpen = (editModalOpen) => setState({ editModalOpen });
  const setExistsLockSemaphoreLocked = existsLockSemaphoreLocked => setState({ existsLockSemaphoreLocked });

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { handleSubmit, reset, setValue, formState: { errors }, control, clearErrors } = useForm();
  const { t } = useTranslation("default");
  const { org } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const getSiteData = useCallback(async () => {
    try {
      const result = await siteService.fetchSiteData({ siteId: params.id });
      if (result) {
        setData(result.data);
      }
    } catch (e) {
      console.warn("Warning, failed to fetch site data", e);
    }
  }, [params.id]);

  const updateSite = useCallback(async (data) => {
    try {
      await siteService.updateSite({ siteId: params.id, data: data });
      var stateData = {
        ...state.data,
        ...data
      };
      setState({
        data: stateData,
        editModalOpen: false
      });
      dispatch(alertActions.send(t("success.updateSite")));
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors.length > 0) {
        const nameNotUnique = errors.find(o => o.detail === "error.name.not_unique");
        if (nameNotUnique) {
          dispatch(alertActions.send(t("error.existingSite"), "error"));
        } else {
          console.warn("Warning, failed to update site", e);
          dispatch(alertActions.send(t("error.updateSite"), "error"));
        }
      } else {
        console.warn("Warning, failed to update site", e);
        dispatch(alertActions.send(t("error.updateSite"), "error"));
      }
    }
  }, [params.id, state.data, dispatch, t]);

  useEffect(() => {
    getSiteData();
  }, [getSiteData]);

  const onSubmit = (data) => {
    setEditModalOpen(false);
    reset(data);
    updateSite(data);
  };

  const getEditOptions = useCallback(() => {
    if (state.data) {
      var options = [{
        field: "name",
        type: "textField",
        label: t("form.name"),
        required: t("error.requiredField"),
        defaultValue: state.data.name,
        inputProps: { maxLength: 100 },
        placeholder: `${t("label.maxChars", { num: 100 })}`,
        autoFocus: true
      },
      {
        field: "description",
        type: "textField",
        label: t("form.description"),
        defaultValue: state.data.description || "",
        inputProps: { maxLength: 100 },
        placeholder: `${t("label.maxChars", { num: 100 })}`
      }];
      return options;
    }
    return [];
  }, [state.data, t]);

  const fetchLocks = async (options) => {
    const result = await siteService.fetchLocks({ ...options, siteId: params.id });
    return result;
  };

  const fetchKeys = useCallback(async (options) => {
    const result = await siteService.fetchKeys({ ...options, siteId: params.id });
    return result;
  }, [params.id]);

  const lockSkeletonCard = <Card className={classes.card}>
    <div className={classes.siteCardContent}>
      <Skeleton variant="circular" className={classes.lockIcon} width={70} height={70} />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CardContent>
      <Skeleton variant="circular" className={classes.siteDelete} width={70} height={70} />
    </div>
  </Card>;

  const getKeySkeletonCard = useCallback(() => <Card className={classes.card}>
    <div className={classes.siteCardContent}>
      <VpnKeyIcon
        fontSize="large"
        className={classes.cardIcon}
      />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CardContent>
      <Skeleton variant="circular" className={classes.siteDelete} width={70} height={70} />
    </div>
  </Card>, [classes.card, classes.cardContent, classes.cardIcon, classes.siteDelete, classes.siteCardContent]);

  const lockFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset) => {
    setExistsLockSemaphoreLocked(data.some(lock => lock.semaphore_locked));

    return data.map((data) => {
      return (<LockCard
        key={data.id}
        type="site"
        org={props.org}
        data={data}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={fetchWidgetData}
        setValue={setValue}
        state={state}
        setState={setState}
        reset={reset}
        ability={ability}
      />
      );
    });
  };

  const keyFormatter = (data, setFilterVariables, cardListState, setState, fetchWidgetData, createModal, setValue, reset) => {
    return data.map((data) => {
      return (<KeyCard
        key={data.id}
        type="site"
        org={props.org}
        data={data}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={fetchWidgetData}
        setValue={setValue}
        state={cardListState}
        setState={setState}
        reset={reset}
        ability={ability}
        isDeleteButtonDisabled={state.existsLockSemaphoreLocked}
      />
      );
    });
  };

  const getLocksCustomButtons = () => {
    let buttons = [];

    if (ability.can("update", "lock_collections")) {
      buttons.push({
        label: t("button.addLock"),
        icon: <AddIcon className={classes.icon} />,
        action: (fetchWidgetData) => setWizard({ type: "lock", open: true, key: Math.random(), fetchWidgetData: fetchWidgetData })
      });
    }

    return buttons;
  };

  const getKeysCustomButtons = () => {
    let buttons = [];

    if (ability.can("create", "lock_collection_keys")) {

      buttons.push({
        label: state.existsLockSemaphoreLocked ? t("error.lock.locked") : t("button.issueKey"),
        icon: <AddIcon className={state.existsLockSemaphoreLocked ? classes.disabledColor : classes.icon} />,
        disabled: state.existsLockSemaphoreLocked,
        action: (fetchWidgetData) => setWizard({ type: "siteKey", open: true, key: Math.random(), fetchWidgetData: fetchWidgetData })
      });
    }

    return buttons;
  };

  return (
    <div className={classes.lsyBackground}>
      {wizard.open ?
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          setOpen={setWizard}
          fetchWidgetData={wizard.fetchWidgetData}
          org={org}
          type={wizard.type}
          siteName={state?.data?.name}
          maxLocks={state?.data?.max_locks}
          numLocks={state?.data?.lock_ids.length}
          siteId={params.id}
        /> : null}
      <CustomModal
        open={state.editModalOpen}
        setOpen={setEditModalOpen}
        handleSubmit={handleSubmit(onSubmit)}
        title={t("button.editSite")}
        type="formCreator"
        manualClose
        formOptions={getEditOptions()}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        setValue={setValue}
        modalStyle={classes.modal}
        confirm={t("button.save")}
        submit
        submitting={state.submitting}
      />
      <Grid container justifyContent="flex-start" className={classes.siteDetailGrid}>
        <Grid item className={classes.siteIconItem} sx={{ display: { xs: "none", sm: "block" } }}>
          <SiteIcon className={classes.siteIcon} width="50px" height="50px" />
        </Grid>
        <Grid item className={classes.siteNameItem}>
          <Typography
            variant="h5"
            component="h2"
            role="cardTitle"
            aria-label="siteLockName"
            className={classes.siteName}
          >
            {state.data ? state.data.name : ""}
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            role="cardTitle"
            aria-label="siteLockName"
            className={classes.siteDescription}
          >
            {state.data ? state.data.description : ""}
          </Typography>
        </Grid>
        <Can I="update" on="lock_collections" ability={ability}>
          <GridItem className={classes.settingsItem} sx={{ display: { xs: "none", sm: "block" } }}>
            <IconButton onClick={() => setEditModalOpen(true)} size="large">
              <SettingsIcon fontSize="large" className={classes.editSite} />
            </IconButton>
          </GridItem>
        </Can>
      </Grid>
      <Grid container justifyContent="center">
        <Can I="read" on="locks" ability={ability}>
          <GridItem className={classes.sitesWidget} xs={12} md={6}>
            <CardListWidget
              skeletonCard={lockSkeletonCard}
              fallbackData={<Placeholder message={t("fallbacks.noLocksFound")} icon={<GenericLock/>}/>}
              org={props.org}
              paginate
              stats
              title={t("features.locks")}
              rowsPerPage={10}
              secondPaginate={true}
              customButtons={getLocksCustomButtons()}
              fetchData={fetchLocks}
              dataFormatter={lockFormatter}
              appBarSize="sm"
              baseUrl={props.baseUrl}
              history={props.history}
              location={props.location}
            />
          </GridItem>
        </Can>
        <Can I="read" on="keys" ability={ability}>
          <GridItem className={classes.sitesWidget} xs={12} md={6}>
            <CardListWidget
              skeletonCard={getKeySkeletonCard()}
              fallbackData={<Placeholder message={t("fallbacks.noKeysFound")} icon={<VpnKeyIcon/>}/>}
              org={props.org}
              paginate
              stats
              title={t("label.keys")}
              rowsPerPage={10}
              secondPaginate={true}
              customButtons={getKeysCustomButtons()}
              fetchData={fetchKeys}
              dataFormatter={keyFormatter}
              appBarSize="sm"
              baseUrl={props.baseUrl}
              history={props.history}
              location={props.location}
              isDisabledCardButton={state.existsLockSemaphoreLocked}
            />
          </GridItem>
        </Can>
      </Grid>
    </div>
  );
}

Site.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string
};

export default Site;
