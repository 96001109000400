import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import { default as MaterialDivider } from "@mui/material/Divider";

import styles from "assets/jss/components/dividerStyle";

const useStyles = makeStyles()(styles);

export default function Divider(props) {
  const { classes, cx } = useStyles();

  return <MaterialDivider variant={props.variant} classes={{root: cx(classes.divider, classes.root, { [classes.padding]: props.autoPadded })}} />;
}

Divider.defaultProps = {
  classes: {
    root: {}
  }
};

Divider.propTypes = {
  autoPadded: PropTypes.bool,
  classes: PropTypes.object,
  variant: PropTypes.string
};
