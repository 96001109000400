import { getFilterVars, compactObj } from "_helpers";
import moment from "moment";
import queryString from "query-string";
import { isEqual, cloneDeep, isEmpty } from "lodash";
import { alertActions } from "_actions";
import { chartService } from "./chart.service";

export const getInitialState = (defaultInitialState, search, type) => {
  let dates = getFilterVars({[type + "_start_date"]: moment().subtract(12, "month").format("YYYY-MM-DD"), [type + "_end_date"]: moment().format("YYYY-MM-DD")}, search);
  if (!isEmpty(dates)) {
    dates = {start_date: dates[type + "_start_date"], end_date: dates[type + "_end_date"]};
  } else {
    dates = {start_date: defaultInitialState.start_date, end_date: defaultInitialState.end_date};
  }
  const newState = { ...defaultInitialState, ...dates };
  return newState;
};

const clearUrlQuery = (history, baseUrl) => {
  history.replace(`${baseUrl}/charts`);
};

export const updateUrlQuery = (newState, defaultInitialState, history, location, type, search, defaultChartDates, isReset) => {
  let allDates = getFilterVars(defaultChartDates, search);

  let urlObject;
  if (isReset) {
    urlObject = {
      ...allDates,
      [type + "_start_date"]: "",
      [type + "_end_date"]: ""
    };
  } else {
    urlObject = {
      ...allDates,
      [type + "_start_date"]: moment(newState.start_date).format("YYYY-MM-DD"),
      [type + "_end_date"]: moment(newState.end_date).format("YYYY-MM-DD")
    };
  }

  if (!isEqual(newState, defaultInitialState)) {
    const filterVarCopy = cloneDeep(compactObj(urlObject));
    history.replace(`/charts?${queryString.stringify(filterVarCopy)}`);
  } else {
    clearUrlQuery(history, location);
  }
};

export const exportChartData = async (data, dispatch, t) => {
  try {
    data.start_date = moment(data.start_date).startOf("day");
    data.end_date = moment(data.end_date).endOf("day").utc();

    await chartService.exportChartHistory({ data: data });

    dispatch(alertActions.send(t("success.exportAccessHistory")));
  } catch (e) {
    console.warn("Warning, failed to export access history", e);
    dispatch(alertActions.send(t("error.exportAccessHistory"), "error"));
  }
};

export const isFilterActive = (initialState, defaultInitialState) => {
  let filterActive;
  if (!isEqual(initialState, defaultInitialState)) {
    filterActive = true;
  } else {
    filterActive = false;
  }
  return filterActive;
};

export const handleError = (error, defaultMessage) => {
  if (error.response?.status === 422) {
    const detail = error.response.data?.error?.errors[0]?.detail;

    if (detail === "error.group_by.day.exceeds.31.days") {
      return "error.oneMonthInterval";
    }
    if (detail === "error.group_by.week.exceeds.6.months") {
      return "error.sixMonthsInterval";
    }
    if (detail === "error.time.range.exceeds.1.year") {
      return "error.oneYearInterval";
    }
  }

  return defaultMessage;
};

export const fillDateGaps = (startDate, endDate, dateFormat, groupBy, formattedData) => {
  const lastDate = moment.parseZone(endDate).utc(true).format(dateFormat);
  const currentDate = moment.parseZone(startDate).utc(true).format(dateFormat);
  
  const diff = moment(lastDate).diff(moment(currentDate), groupBy);

  if (diff > 1) {
    for (let count = 1; count < diff; count++) {
      const newDate = moment(currentDate).add(count, groupBy).format(dateFormat);

      if (!Object.keys(formattedData).includes(newDate)) {
        formattedData[newDate] = 0;
      }
    }
  }
};