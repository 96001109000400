import { useEffect, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { authService } from "_services";
import { mfaService } from "_services/lockstasy";
import { alertActions } from "_actions";
import { MFA_CODE_LENGTH } from "_constants";

// @mui/material components
import { makeStyles } from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { config } from "_configs/server-config";
import CustomAlert from "_components/Alert/CustomAlert";
import CustomModal from "_components/Modal/CustomModal";
import MfaSetupForm from "_components/Form/MfaSetupForm.js";
import MfaConfirmForm from "_components/Form/MfaConfirmForm.js";
import MfaSetForm from "_components/Form/MfaSetForm.js";
import MfaCodeModal from "_components/Modal/MfaCodeModal";

import styles from "assets/jss/components/Modal/mfaModalStyle";

const useStyles = makeStyles()(styles);

const isSera4tal = () => {
  const path = window.location.pathname;
  return path.startsWith(config.adminUrl);
};

const MfaModal = ({
  showMfaModal,
  handleClose,
  getMembershipData,
  onNotification,
  personalAccount,
  isMfaPending,
  isDisabling,
  mfa,
  email,
  id,
  hasPassword
}) => {
  const { classes } = useStyles();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState}),
    {
      authenticated: false,
      mfaData: {},
      password: "",
      mfaCode: "",
      loading: false,
      mfaEnabled: false,
      originalMfaEnabled: false,
      submitted: false,
      showMfaBackupCodes: false,
      mfaBackupCodes: []
    }
  );

  const setPassword = (password) => setState({ password });
  const setMfaCode = (mfaCode) => setState({ mfaCode });
  const setLoading = (loading) => setState({ loading });
  const setMfaEnabled = (mfaEnabled) => setState({ mfaEnabled });
  const setShowMfaBackupCodes = (open) => setState({ showMfaBackupCodes: open });
  const { t } = useTranslation(["auth", "default"]);

  const shouldAuthenticate = hasPassword && !state.authenticated;

  const handleError = (error) => {
    const response = error?.response;

    if (!response) {
      return t("mfa.failedUpdateNotif");
    }

    const status = response.status;

    switch(status) {
      case 403:
        return t("mfa.notAllowed");
      case 422:
        return response.data?.message === "error.invalid.password" ? t("mfa.passwordNotif") : t("mfa.failedUpdateNotif");
      default:
        return t("mfa.failedUpdateNotif");
    }
  };

  const getAuthenticatorData = async (password) => {
    try {
      let authenticatorData = {};

      if (isSera4tal()) {
        authenticatorData = await authService.getAuthenticator(
          email,
          password
        );
      }
      else {
        const result = await mfaService.configureAuthenticator(password);
        authenticatorData = result.user.auth;
      }

      if (authenticatorData) {
        setState({
          mfaData: authenticatorData,
          authenticated: true,
          mfaEnabled: authenticatorData.mfa_enabled,
          originalMfaEnabled: authenticatorData.mfa_enabled
        });
      } else {
        setPassword("");
      }
      setLoading(false);
    } catch (e) {
      onNotification(t("mfa.passwordNotif"), "error");
      setLoading(false);
    }
  };

  const closeModal = (close = false) => {
    getMembershipData();
    handleClose(close);
    setState({
      password: "",
      mfaCode: "",
      mfaData: {},
      loading: false,
      authenticated: false,
      submitted: false
    });
  };
  
  const confirmAuthenticatorData = async (mfa_code) => {
    try {

      let authenticatorData = {};

      if (isSera4tal()) {
        authenticatorData = await authService.confirmAuthenticator(
          mfa_code
        );
      }
      else {
        authenticatorData = await mfaService.confirmAuthenticator(mfa_code);
      }

      if (authenticatorData) {
        setState({
          mfaEnabled: authenticatorData.user.auth.mfa_enabled,
          mfaCode: "",
          mfaBackupCodes: authenticatorData.user.auth.mfa_backup_codes
        });
        onNotification(t("mfa.enabledNotif"));
        closeModal();
      }
      setLoading(false);
      setShowMfaBackupCodes(true);
    } catch (e) {
      onNotification(t("mfa.invalidNotif"), "error");
      setLoading(false);
    }
  };

  const disableAuthenticator = async (password) => {
    try {
      let authenticatorData = {};
      if (isSera4tal()) {
        authenticatorData = await authService.setMfa(
          id, false, email, password);
      }
      else {
        authenticatorData = await mfaService.disable(id, password);
      }

      if (authenticatorData) {
        setMfaEnabled(false);
        onNotification(t("mfa.disabledNotif"));
        closeModal();
      }
      setLoading(false);
    } catch (e) {
      onNotification(handleError(e), "error");
      setLoading(false);
    }
  };

  const updateAuthenticator = async (status) => {
    try {
      let mfa = false;
      if (isSera4tal()) {
        const authenticatorData = await authService.setMfa(id, status, email);
        mfa = authenticatorData.mfa_enabled;
      }
      else {
        if (status) {
          const authenticatorData = await mfaService.enable(id);
          mfa = authenticatorData.data.mfa;
        }
        else {
          const authenticatorData = await mfaService.disable(id, hasPassword ? state.password : null);
          mfa = authenticatorData.data.mfa;
        }
      }

      setMfaEnabled(mfa);
      onNotification(mfa ? t("mfa.enabledSuccessNotif") : t("mfa.disabledSuccessNotif"));
      closeModal();
      setLoading(false);
    } catch (e) {
      onNotification(handleError(e), "error");
      setLoading(false);
    }
  };

  const handleAuthSubmit = (event) => {
    setState({
      loading: true,
      submitted: true
    });
    if (event) {
      event.preventDefault();
    }

    getAuthenticatorData(state.password);
  };

  const handleConfirmSubmit = (event) => {
    setLoading(true);
    if (event) {
      event.preventDefault();
    }

    if (isSera4tal() && state.mfaEnabled && !isMfaPending) {
      disableAuthenticator(state.password);
    } else {
      confirmAuthenticatorData(state.mfaCode);
    }
  };

  const handleSetSubmit = (event) => {
    setLoading(true);
    if (event) {
      event.preventDefault();
    }

    updateAuthenticator(!state.mfaEnabled);
  };

  const handleDisableSubmit = () => {
    setLoading(true);
    updateAuthenticator(false);
  };

  const Spinner = () => <CircularProgress size={30} className={classes.spinner}/>;

  const setupForm = () => (
    <form onSubmit={handleConfirmSubmit}>
      <div className={classes.modalBodyContent}>
        {state.mfaEnabled && !isMfaPending ? (
          <div className={classes.mfaAlert}>
            <CustomAlert type="MfaSuccess" />
          </div>
        ) : null}
        <MfaSetupForm
          data={state.mfaData}
          onChange={(event) => setMfaCode(event.target.value)}
          mfaEnabled={state.mfaEnabled && !isMfaPending}
          code={state.mfaCode}
          onSubmit={handleConfirmSubmit}
        />
      </div>
    </form>
  );

  const authForm = () => (
    <form onSubmit={handleAuthSubmit}>
      <div className={classes.modalBodyContent}>
        <MfaConfirmForm
          onChange={(password) => setPassword(password)}
          password={state.password}
          submitted={state.submitted}
        ></MfaConfirmForm>
      </div>
    </form>
  );

  const setForm = () => (
    <form onSubmit={handleAuthSubmit}>
      <div className={classes.modalBodyContent}>
        <div className={classes.mfaAlert}>
          <CustomAlert type={state.mfaEnabled ? "MfaEnabled" : "MfaDisabled"} />
        </div>
        <MfaSetForm mfaEnabled={state.mfaEnabled} isMfaPending={isMfaPending} personalAccount={personalAccount}/>
      </div>
    </form>
  );

  const disableForm = () => (
    <div className={classes.modalBodyContent}>
      <div className={classes.mfaAlert}>
        <CustomAlert type={"MfaEnabled"} />
      </div>
      <MfaSetForm mfaEnabled={state.mfaEnabled} isMfaPending={isMfaPending} personalAccount={personalAccount}/>
      { hasPassword &&
        <FormControl variant="standard">
          <InputLabel htmlFor="standard-adornment-pass">
            {t("login.password")}
          </InputLabel>
          <Input
            data-testid="passwordInput"
            id="standard-adornment-pass"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleDisableSubmit();
              }
            }}
          />
        </FormControl>}
    </div>
  );

  useEffect(() => {
    setState({
      originalMfaEnabled: state.mfaEnabled,
      mfaEnabled: mfa
    });

    if (showMfaModal && personalAccount && !hasPassword && isEmpty(state.mfaData) && !isDisabling) {
      getAuthenticatorData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMfaModal, mfa, state.mfaEnabled, personalAccount, hasPassword]);

  const personalCancelText = useMemo(
    () =>
      state.authenticated
        ? isSera4tal() && state.mfaEnabled
          ? t("default:button.close")
          : t("default:button.cancel")
        : null,
    [state.authenticated, state.mfaEnabled, t]
  );

  const personalConfirmText = useMemo(
    () =>
      state.authenticated ? (
        state.loading ? (
          <Spinner></Spinner>
        ) : isSera4tal() && state.mfaEnabled ? (
          t("default:button.disable")
        ) : (
          t("default:button.enable")
        )
      ) : state.loading ? (
        <Spinner></Spinner>
      ) : (
        t("default:button.continue")
      ),
    [state.authenticated, state.loading, state.mfaEnabled, t]
  );

  const cancelText = useMemo(
    () => (state.mfaEnabled !== state.originalMfaEnabled ? 
      t("button.close", {ns: "default"}) : 
      t("button.cancel", {ns: "default"})),
    [state.mfaEnabled, state.originalMfaEnabled, t]
  );

  const confirmText = useMemo(
    () =>
      state.loading ? (
        <Spinner></Spinner>
      ) : state.mfaEnabled ? (
        t("default:button.disable")
      ) : (
        t("default:button.enable")
      ),
    [state.loading, state.mfaEnabled, t]
  );

  return (
    <div>
      {personalAccount ?
        !isDisabling ? (
          <CustomModal
            title={shouldAuthenticate ? t("mfa.loginTitle") : t("mfa.mfaSetupTitle")}
            description={shouldAuthenticate ? authForm() : setupForm()}
            open={showMfaModal}
            setOpen={closeModal}
            manualClose={true}
            handleSubmit={shouldAuthenticate ? handleAuthSubmit : handleConfirmSubmit}
            type={shouldAuthenticate ? "form" : "custom"}
            cancelButton
            confirmButton
            cancel={personalCancelText}
            confirm={personalConfirmText}
            confirmDisabled={shouldAuthenticate ? isEmpty(state.password) : state.mfaCode.length !== MFA_CODE_LENGTH}
            confirmButtonStyle={classes.mfaButton}
            cancelButtonStyle={classes.mfaButton}
          />
        ) : (
          <CustomModal
            title={t("mfa.confirmTitle")}
            description={disableForm()}
            open={showMfaModal}
            setOpen={closeModal}
            manualClose={true}
            handleSubmit={handleDisableSubmit}
            type="form"
            cancel={cancelText}
            confirm={confirmText}
            cancelButtonStyle={classes.mfaButton}
            confirmButtonStyle={classes.mfaButton}
            confirmDisabled={hasPassword && !state.password}
          />
        ) : (
          <CustomModal
            title={t("mfa.confirmTitle")}
            description={setForm()}
            open={showMfaModal}
            setOpen={closeModal}
            manualClose={true}
            handleSubmit={handleSetSubmit}
            type="custom"
            cancelButton={true}
            confirmButton={true}
            cancel={cancelText}
            confirm={confirmText}
            cancelButtonStyle={classes.mfaButton}
            confirmButtonStyle={classes.mfaButton}
          />
        )}
      <MfaCodeModal
        showModal={state.showMfaBackupCodes}
        handleClose={setShowMfaBackupCodes}
        codes={state.mfaBackupCodes}
      />
    </div>
  );
};

MfaModal.propTypes = {
  showMfaModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getMembershipData: PropTypes.func.isRequired,
  onNotification: PropTypes.func.isRequired,
  personalAccount: PropTypes.bool.isRequired,
  isMfaPending: PropTypes.bool,
  isDisabling: PropTypes.bool,
  mfa: PropTypes.bool,
  email: PropTypes.string,
  id: PropTypes.string.isRequired,
  hasPassword: PropTypes.bool
};

const mapDispatchtoProps = (dispatch) => {
  return {
    onNotification: (message, level = "success") =>
      dispatch(alertActions.send(message, level))
  };
};

export default connect(null, mapDispatchtoProps)(MfaModal);
