import { AbilityBuilder, Ability } from "@casl/ability";
import { formatSchema } from "_services/lockstasy/helper";

export function lockstasyAbility(schema) {
  const { rules, can } = new AbilityBuilder();
  const formattedSchema = formatSchema(schema);

  const featureKeys = Object.keys(formattedSchema);
  featureKeys.forEach((feature) => {
    const permissionKeys = Object.keys(formattedSchema[feature]);
    var permissionList = [];
    permissionKeys.forEach((permission) => {
      if (formattedSchema[feature][permission]) {
        return permissionList.push(permission);
      }
    });

    can(permissionList, feature);
  });

  return new Ability(rules);
}