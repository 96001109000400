import { isEmpty, isNull, isUndefined } from "lodash";

import { isNullOrUndefined } from "_helpers";

export const encodePreferences = (options) => {
  const {default_key_time, default_key_time_unit, authorizations_required, license_expiry_alerts} = options;
  
  const encodedOptions = {
    preference: {
      default_key_time: `${default_key_time},${default_key_time_unit}`,
      authorizations_required: authorizations_required?.toString(),
      ...!isNullOrUndefined(license_expiry_alerts) ? {license_expiry_alerts: license_expiry_alerts} : {}
    }};
  return encodedOptions;
};

export const decodePreferences = (options) => {
  const {default_key_time, authorizations_required, license_expiry_alerts} = options;
  const [keyLength, default_key_time_unit] = default_key_time.split(",");
  
  const decodedOptions = {
    default_key_time: keyLength,
    default_key_time_unit,
    authorizations_required: authorizations_required || [],
    license_expiry_alerts: license_expiry_alerts
  };
  
  return decodedOptions;
};

export const isPasswordlessRequired = (options) => {
  if (isNull(options) || isUndefined(options)) {
    return false;
  }

  const { authorizations_required } = options;

  return !isEmpty(authorizations_required) && authorizations_required.includes("PASSWORDLESS");
};

export const isLicenseExpiryAlertsOn = (options) => {
  if (isNullOrUndefined(options) || isNullOrUndefined(options.license_expiry_alerts)) {
    return true;
  }

  const { license_expiry_alerts } = options;

  return (typeof license_expiry_alerts === "string" && !isEmpty(license_expiry_alerts)) || license_expiry_alerts;
};