import { createContext, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { isEqual } from "lodash";
import { useCustomCompareEffect } from "use-custom-compare";

import { adminConstants } from "_constants/admin.constants";
import {
  HardwareTypeOptions,
  LockConfigOptions,
  LockTypeOptions
} from "_constants/lock.constants";
import { genericReducer } from "_reducers/general.reducer";
import twsResourceFactory from "_resources";

export const AppAdminDataContext = createContext();

const TAP_RESOURCES_NAMES = Object.freeze(["twsOrganizations"]);
const SERA4TAL_RESOURCES_NAMES = Object.freeze([
  ...TAP_RESOURCES_NAMES,
  "twsBatteryTypes", "twsFirmware", "twsServers", "twsTenantLicenses", "twsUuids",
  "twsCryptos"
]);

const getResource = (name, currentMembership) => {
  // extremely useful in debugging a TWS resource
  // console.debug(`getTwsResource ${name}, existing:`, twsResourceFactory.listResources());
  if (currentMembership?.s4_role === adminConstants.TRUSTED_ADMIN && name !== "organizations") {
    return twsResourceFactory.getEmptyResource();
  }

  if (name === "cryptos") {
    return twsResourceFactory.getResource("twsCryptos");
  } else if (name === "uuids") {
    return twsResourceFactory.getResource("twsUuids");
  } else if (name === "tenantLicenses") {
    return twsResourceFactory.getResource("twsTenantLicenses");
  } else if (name === "organizations") {
    return twsResourceFactory.getResource("twsOrganizations");
  } else if (name === "servers") {
    return twsResourceFactory.getResource("twsServers");
  } else if (name === "batteryTypes") {
    return twsResourceFactory.getResource("twsBatteryTypes");
  }
};

// create the provider
export const AppAdminDataProvider = (props) => {
  const currentMembership = useSelector((state) => state?.memberships?.currentMembership);
  const [loadedResourcesStates, setLoadedResourcesStates] = useReducer(genericReducer, {
    resourcesLoaded: false,
    batteryTypesLoaded: false,
    cryptosLoaded: false,
    firmwareLoaded: false,
    organizationsLoaded: false,
    serversLoaded: false,
    tenantLicensesLoaded: false,
    uuidsLoaded: true // this is merely a local cache that we load 1 by 1
  });

  const [organizationList, setOrganizationList] = useState([]);
  const [firmwareList, setFirmwareList] = useState([]);
  const [uuidList, setUuidList] = useState([]);
  const [tenantLicenseList, setTenantLicenseList] = useState([]);
  const [batteryTypesList, setBatteryTypesList] = useState([]);
  const [serverList, setServerList] = useState([]);

  const findUser = (uuid) => {
    try {
      let name = uuidList?.find((e) => e.id === uuid);
      if (name) {
        name = name.first_name + " " + name.last_name;
      } else {
        name = uuid;
      }

      return name;
    } catch(e) {
      return undefined;
    }
  };

  useCustomCompareEffect(() => {
    async function fetchInitialResourceData(){
      if (!currentMembership?.s4_role) {
        return;
      }

      const currentMembershipCache = localStorage.getItem("currentMembershipLoaded");

      if (!loadedResourcesStates.resourcesLoaded) {
        if (currentMembershipCache && currentMembershipCache !== currentMembership.id) {
          const resources = twsResourceFactory.getResources();
          Object.keys(resources).forEach(resourceName => resources[resourceName].flushCache());
          twsResourceFactory.flushResources();
        }

        const resourcesNames = currentMembership.s4_role === adminConstants.TRUSTED_ADMIN ?
          TAP_RESOURCES_NAMES :
          SERA4TAL_RESOURCES_NAMES;

        // we need these all in 1 call, so that they come as 1 synchronous chunk and no
        //  consumers beat it to the data.  This can cause problems on refreshes of the screen
        //  where the consumer races the producer
        const {
          twsBatteryTypes,
          twsFirmware,
          twsOrganizations,
          twsServers,
          twsTenantLicenses,
          /* eslint-disable-next-line no-unused-vars */
          twsUuids,
          /* eslint-disable-next-line no-unused-vars */
          twsCryptos // not used as a get/Setter ATM
        } = await twsResourceFactory.createResources(resourcesNames, { avoidPrecache: ["twsUuids"]});

        setLoadedResourcesStates({
          resourcesLoaded: true,
          batteryTypesLoaded: true,
          cryptosLoaded: true,
          firmwareLoaded: true,
          organizationsLoaded: true,
          serversLoaded: true,
          tenantLicensesLoaded: true
        });

        localStorage.setItem("currentMembershipLoaded", currentMembership.id);

        /* eslint-disable-next-line no-debugger */
        setOrganizationList(twsOrganizations?.fetchAllFromCache().data || []);
        setFirmwareList(twsFirmware?.fetchAllFromCache().data || []);
        setServerList(twsServers?.fetchAllFromCache().data || []);
        setTenantLicenseList(twsTenantLicenses?.fetchAllFromCache().data || []);
        setBatteryTypesList(twsBatteryTypes?.fetchAllFromCache().data || []);
      }
    }
    fetchInitialResourceData();
  }, [loadedResourcesStates, currentMembership], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getTwsResource = (name) => {
    return getResource(name, currentMembership);
  };

  return (
    <AppAdminDataContext.Provider value={{
      batteryTypesList, firmwareList, organizationList, serverList, tenantLicenseList, uuidList,
      setBatteryTypesList, setFirmwareList, setTenantLicenseList, setUuidList,
      lockConfigOptions: LockConfigOptions,
      lockTypeOptions: LockTypeOptions,
      hardwareTypeOptions: HardwareTypeOptions,
      findUser,
      setOrganizationListFromSource: setOrganizationList,
      getTwsResource,
      updateTwsResource: async (name) => {
        switch (name) {
          case "organizations":
            await getTwsResource(name).query({refresh: true, setOrganizationList: setOrganizationList });
            return;
          default:
            await getTwsResource(name).query({refresh: true});
            return;
        }
      }
    }}>
      {props.children}
    </AppAdminDataContext.Provider>
  );
};

AppAdminDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  value: PropTypes.object
};