import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";

// @mui/material components
import { makeStyles } from "tss-react/mui";

const styles = (theme) => ({
  uuidLabel: {
    color: theme.palette.secondary.main
  }
});

const useStyles = makeStyles()(styles);

const systemDefault = "00000000-0000-0000-0000-000000000000";

export const UUIDWrapper = ({ uuid, name, url }) => {
  const { classes } = useStyles();

  let showProfile = uuid === name ? false : true;

  return showProfile ? (
    <NavLink to={`${url}/account/${uuid}`}>
      <span title={uuid} className={classes.uuidLabel}>
        {name}
      </span>
    </NavLink>
  ) : (
    <span title={uuid} className={classes.uuidLabel}>
      {name === systemDefault ? "System Generated" : name}
    </span>
  );
};

UUIDWrapper.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
