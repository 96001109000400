import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { lockService } from "_services";
import { hasAbility, lsyRouter } from "_helpers";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { isEmpty } from "lodash";
import moment from "moment";

// Icons
import GenericLock from "assets/teleporte/GenericLock";

// Material UI Components
import { makeStyles } from "tss-react/mui";

// Components
import ErrorBoundary from "_components/ErrorBoundary";
import TableWidget from "_containers/Widgets/TableWidget";

//styles
import styles from "assets/jss/containers/openLockTableStyle.js";

const useStyles = makeStyles()(styles);

const OpenLocksTable = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const includeSites = hasAbility(currentMembership, "view", "lock_collections") && ability.can("read", "lock_collections");

  const fetchOpenLocks = useCallback(async (options) => {
    try {
      const result = await lockService.fetchOpenLocks(options);
      return result;
    } catch (e) {
      console.warn("Warning, failed to fetch locks data", e?.response);
    }
  }, []);

  const getIdFromLockWS = (data) => data?.id;

  const removeOpenLockWS = (data, id) => {
    const [headers, newData, newIds] = data;
    const indexToRemove = newIds.indexOf(id);
    newData.splice(indexToRemove, 1);
    newIds.splice(indexToRemove, 1);
    return [headers, newData, newIds];
  };

  const addOpenLockWS = (oldData, newData, orderBy, direction, pageSize, totalPages, setTotalPages) => {
    const [headers, oldLocks, oldLockIds] = oldData;
    const lockToAdd = newData[1][0];
    const lockIdToAdd = newData[2][0];
    const newHeader = isEmpty(headers) ? newData[0] : headers;

    let newLocks = [...oldLocks];
    let newLockIds = [...oldLockIds];

    if(orderBy === "Open") {
      switch (direction) {
        case "asc":
          if(oldLockIds.length < pageSize) {
            newLocks.push(lockToAdd);
            newLockIds.push(lockIdToAdd);
          } 
          break;
        case "desc":
          newLocks.unshift(lockToAdd);
          newLockIds.unshift(lockIdToAdd);

          if(newLockIds.length > pageSize) {
            if(oldData[2].length >= pageSize && totalPages < 2)
              setTotalPages(prev => prev + 1);
            newLocks.pop();
            newLockIds.pop();
          }
          break;
      }
    }

    return [newHeader, newLocks, newLockIds];
  };

  const openLocksFormatter = (data) => {
    const updateModifier = data.last_status_method === "log" ? "" : "*";
    const headers = [
      {
        id: "Lock",
        label: t("widgetField.lock")
      },
      {
        id: "User",
        label: t("widgetField.user")
      },
      {
        id: "Open",
        label: t("widgetField.open"),
        sortable: true
      }
    ];
    if (includeSites) {
      headers.unshift({
        id: "Site",
        label: t("widgetField.site")
      });
    }

    const lock_ids = [];

    const tableData = data.map((lock) => {
      let user_name = "";

      if(lock?.user) 
        user_name = lock?.user?.name || `${lock.user.first_name} ${lock.user.last_name}`;
      
      let tempData = {
        Lock: {
          text: lock?.lock?.name || lock.name,
          overflowHidden: classes.overflowCell,
          action: () => {
            props.history.push(lsyRouter("lock", lock?.lock?.id || lock.id));
          }
        },
        User: {
          text: user_name,
          overflowHidden: classes.overflowCell,
          action: () => {
            props.history.push(lsyRouter("user", lock.user.membership_id));
          }
        },
        Open: {
          text: lock.last_opened_at
            ? moment(lock.last_opened_at).fromNow()
            : moment(lock.last_reported_at).fromNow() + updateModifier,
          sortValue: moment(lock.last_opened_at).valueOf()
        }
      };
      if (includeSites) {
        tempData = {
          Site: {
            text: lock.site ? lock.site.name : t("widgetField.unassigned")
          },
          ...tempData
        };
      }

      lock_ids.push(lock.id);

      return tempData;
    });

    return [headers, tableData, lock_ids];
  };

  return (
    <ErrorBoundary>
      <TableWidget
        paginate
        icon={ <GenericLock
          className={classes.widgetIcon}
          width="23px"
          height="23px"
          closed={false}
          openedColor={"white"}
        />}
        stats
        appBarSize="sm"
        title={t("widgetField.openLocks")}
        rowsPerPage={15}
        fetchData={fetchOpenLocks}
        dataFormatter={openLocksFormatter}
        defaultSort="Open"
        sortMap={{ Open: "opened_at" }}
        sortDirection="desc"
        fallbackData={t("fallbacks.lock")}
        events={["lock_status"]}
        getIdFromWebsocket={getIdFromLockWS}
        addTableItem={addOpenLockWS}
        removeTableItem={removeOpenLockWS}
      />
    </ErrorBoundary>
  );
};


OpenLocksTable.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};
  

export default OpenLocksTable;
