import { useEffect, useState, useCallback, useContext, useMemo, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ErrorBoundary from "_components/ErrorBoundary";
import styles from "assets/jss/views/admin/rtmStyle.js";
import { makeStyles } from "tss-react/mui";
import LoadingPlaceHolder from "_components/Loading";
import { AppAdminDataContext } from "_contexts/AppAdminData/AppAdminData.js";
import { organizationService } from "_services/admin";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import EditIcon from "@mui/icons-material/Edit";
import { firmwareService } from "_services/admin/firmware.service";
import { rtmService } from "_services/admin/rtm.service";
import { get, map } from "lodash";
import moment from "moment";
import TextField from "@mui/material/TextField";
import RegularButton from "_components/Button/RegularButton";
import IconButton from "@mui/material/IconButton";
import { alertActions } from "_actions";
import SimpleSelect from "_components/Select/SimpleSelect";
import CustomPagination from "_components/Pagination/CustomPagination";
import { SlideMenuContext } from "_components/AnimatedMenu/SlideMenu.js";
import LiveDebugSession from "./LiveDebugSession";
import CustomModal from "_components/Modal/CustomModal";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import {
  Box,
  Tooltip
} from "@mui/material";
import {
  convertToHex,
  convertByteValueToHumanReadable
} from "_helpers";

const UsageBreakdownTypes = [
  { id: 7, name: "Daily", cycle: "day" },
  { id: 28, name: "Weekly", cycle: "week" },
  { id: 180, name: "Monthly", cycle: "month" }
];

const useStyles = makeStyles()(styles);

export default function ShowRtm(props) {
  const { rtm } = props.data;
  const { classes } = useStyles();
  const appDataContext = useContext(AppAdminDataContext);
  const { handleSubmit } = useForm();

  const ctx = useContext(SlideMenuContext);
  const [containerName, setContainerName] = useState(null);
  const [loading, setLoading]             = useState(true);
  const [submitting, setSubmitting]       = useState(false);
  const [editing, setEditing]             = useState(false);
  const [rtmData, setRtmData]             = useState(rtm);
  const [usageData, setUsageData]         = useState(null);
  const [usagePageParams, setUsagePageParams] = useState({page_index: 1, page_size: UsageBreakdownTypes[0].id});
  const [usagePageCount, setUsagePageCount] = useState(0);
  const [configs, setConfigs]             = useState(null);
  const [configsError, setConfigsError]   = useState(false);
  const [liveDebugSessionOpen, setLiveDebugSessionOpen] = useState(false);
  const [changeLockOpen, setChangeLockOpen] = useState(false);
  const [newLockIdError, setNewLockIdError] = useState(false);
  const [newLockId, setNewLockId] = useState();
  const [keepHistoryOnLockUpdate, setKeepHistoryOnLockUpdate] = useState(false);
  const [bundleOptions, setBundleOption] = useState([]);

  const dispatch = useDispatch();

  const handleNewLockIdChanges = (id) => {
    setNewLockId(id);
    if (isNaN(parseInt(id))) {
      setNewLockIdError(true);
    } else {
      // don't accept trailing garbage that parseInt ignores
      const hexString = `0x${parseInt(id).toString(16)}`.toLowerCase();
      const decimal = `${parseInt(id)}`;
      setNewLockIdError(decimal === "0" || !(id.toLowerCase() === hexString || decimal === id));
    }
  };
  
  const changeLockSelector = useMemo(() => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Typography>
            Warning!!!
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <Typography>
            Changing the lock hardware ID will terminate its active connection briefly!
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            error={newLockIdError}
            labelText={"Hardware ID (hex or decimal)"}
            formControlProps={{fullWidth: true}}
            onChange={(event) => handleNewLockIdChanges(event.target.value)}
          />
        </GridItem>
        <GridItem xs={12} className={classes.keepHistorySwitch}>
          <FormControlLabel
            label={"Keep Old History"}
            labelPlacement="start"
            control={
              <Switch
                onChange={e => setKeepHistoryOnLockUpdate(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
                defaultValue={keepHistoryOnLockUpdate}
                defaultChecked={keepHistoryOnLockUpdate}
              />}
          />
        </GridItem>
      </GridContainer>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.labelRoot, newLockIdError, keepHistoryOnLockUpdate]);

  const showLoadingSymbol = () => {
    return (<LoadingPlaceHolder title="Loading RTM Information" />);
  };

  const getTenantByIdFromCache = (id) => {
    return appDataContext.organizationList.find(o => o.id === id) || {};
  };

  const getContainerName = useCallback(async () => {
    const { lock } = rtmData;
    if (!lock) {
      return;
    }
    try {
      let result = await organizationService.fetchGlobalOrganization(lock.tenant_id, { include: ["server_alias"] });
      if (result) {
        const server_name = get(result, "server_alias.server.endpoint", "")
          .replace(/^https*:\/\//, "")
          .replace(/:\d+$/, "")
          .replace(/\.\S+/, "");
        setContainerName(server_name);
      }
    } catch (e) {
      console.warn(e);
    }
  },[rtmData]);

  const fetchRtmInfo = useCallback(async() => {
    setLoading(true);
    try {
      const result = await rtmService.fetchRtmBoardInfo(rtmData.id);
      setRtmData(result);
      setConfigs(JSON.stringify(result.configurations,null,2));
    } catch (e) {
      console.warn("Error fetching rtm info:", e);
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rtmData.id, rtmData.lock_id]);

  const getUsage = useCallback(async() => {
    try {
      const result = await rtmService.getUsage(rtmData.id, usagePageParams);
      const filterType = UsageBreakdownTypes.find(t => t.id === usagePageParams.page_size) || UsageBreakdownTypes[0];
      setUsagePageCount(result.pagination ? result.pagination.total_pages : 0);

      let rows = [];
      if (filterType.cycle !== "day") {
        // Condensed view
        const cycle = filterType.cycle;
        let index = 0;
        for(let i = 0; i < result.data.length;) {
          const reference = result.data[0].cycle;
          const cycleStart = moment(reference).startOf(cycle).subtract(index, cycle);
          const cycleEnd = moment(reference).startOf(cycle).subtract(index, cycle).add(1, cycle);
          const cycles = result.data.filter(l => moment(l.cycle) >= cycleStart && moment(l.cycle) < cycleEnd);
          if (cycles.length > 0) {
            rows.push(cycles.reduce((acc, c) => {
              acc.tx_total += (c.tx_total ? BigInt(c.tx_total) : 0n);
              acc.tx_down += (c.tx_down ? BigInt(c.tx_down) : 0n);
              acc.tx_up += (c.tx_up ? BigInt(c.tx_up) : 0n);
              return acc;
            }, {cycle: cycleStart.toString(), tx_down: 0n, tx_up: 0n, tx_total: 0n}));
          }
          index++;
          i += cycles.length;
        }
      } else {
        rows = result.data;
      }

      setUsageData(rows);

    } catch (e) {
      console.warn("Error fetching rtm usage:", e);
    }
  },[rtmData.id, usagePageParams]);

  const getBundleOptions = data => {
    return data.map(bundle => {
      return { id: bundle.id, name: bundle.id };
    });
  };

  const fetchFirmwareBundles = useCallback(async () => {
    try {
      const result = await firmwareService.fetchBundles();
      setBundleOption(getBundleOptions(result.data));
    } catch (e) {
      console.warn("Error fetching firmware bundles:", e);
    }
  }, []);

  useEffect(() => {
    getUsage();
    fetchFirmwareBundles();
  }, [getUsage, fetchFirmwareBundles]);

  const toggleEdit = () => {
    setEditing(!editing);
    setConfigs(JSON.stringify(rtmData.configurations, null, 2));
    setConfigsError(!rtmData.configurations);
  };

  const submitChanges = async () => {
    setSubmitting(true);
    setEditing(false);
    try {
      const configurations = JSON.parse(configs);
      const result = await rtmService.updateBoardConfiguration(rtmData.id, {configurations});
      setRtmData(result);
      setConfigs(JSON.stringify(result.configurations, null, 2));
      dispatch(alertActions.send("Configurations Saved.", "success"));
    } catch (e) {
      console.error(`Failed to update rtm configs ${e}`);
      const status = get(e,"response.status") || "unknown";
      dispatch(alertActions.send(`Failed to save configurations. Error: ${status}`, "error"));
    }
    setSubmitting(false);
  };

  const handleConfigChanges = (data) => {
    try {
      JSON.parse(data);
      setConfigsError(false);
    } catch (e) {
      setConfigsError(true);
    }
    setConfigs(data);
  };

  const handleChangeUsagePage = (page_index) => {
    const {page_size} = usagePageParams;
    setUsagePageParams({page_index, page_size});
  };

  const handleChangeUsagePageSize = (event) => {
    setUsagePageParams({page_index: 1, page_size: event.target.value});
  };

  const showTenantName = () => {
    const tenant = rtmData.lock ? getTenantByIdFromCache(rtmData.lock.tenant_id) : {};
    return (
      <Fragment>
        <b>Organization:</b>&nbsp;
        <Tooltip title={tenant.id} aria-label={tenant.id}><span>{ tenant ? tenant.name : null }</span></Tooltip>
      </Fragment>
    );
  };

  const showLockInfo = async () => {
    ctx.updateMenuData({ menuType: "lockEdit", data: { lock_id: rtmData.lock_id } });
  };

  const dataUsageTable = (data) => {
    const filterType = UsageBreakdownTypes.find(t => t.id === usagePageParams.page_index) || UsageBreakdownTypes[0];
    let cycleName = "Date";
    if (filterType.name === "Monthly") {
      cycleName = "Month";
    } else if (filterType.name === "Weekly") {
      cycleName = "Week";
    }
    return(
      <div>
        <table className={classes.usageTable}>
          <thead>
            <tr className={classes.collapsedRowHeader}>
              <th>{cycleName}</th>
              <th>Up</th>
              <th>Down</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              map(data, (value, key) => {
                const format = filterType.name === "Monthly" ? "MMM YYYY" : "ll";
                return (
                  <tr className={classes.collapsedRowData} key={key}>
                    <td>{moment(value.cycle).format(format)}</td>
                    <td>{convertByteValueToHumanReadable(value.tx_up)}</td>
                    <td>{convertByteValueToHumanReadable(value.tx_down)}</td>
                    <td>{convertByteValueToHumanReadable(value.tx_total)}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  const updateBundle = async (event) => {
    try {
      const result = await rtmService.updateBoardConfiguration(rtmData.id, { firmware_bundle_next_id: event.target.value });
      setRtmData(result);
      dispatch(alertActions.send("Next bundle updated with success.", "success"));
    } catch (e) {
      console.error(`Failed to update rtm next bundle ${e}`);
      const status = get(e,"response.status") || "unknown";
      dispatch(alertActions.send(`Failed to update rtm next bundle. Error: ${status}`, "error"));
    }
  };

  const renderFirmwareBundle = () => {
    return <>
      <GridItem xs={12}><b>Firmware Bundle</b></GridItem>
      <GridItem xs={6}>
        <CustomInput
          id="firmware_bundle_current_id"
          labelText="Current Bundle"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            defaultValue: rtmData.firmware_bundle_current_id || " ",
            name: "firmware_bundle_current_id",
            disabled: true,
            classes: { select: classes.select },
            autoComplete: "off"
          }}
          name="firmware_bundle_current_id"
        />
      </GridItem>
      <GridItem xs={6} className={classes.nextVersion}>
        {bundleOptions.length > 0 ? 
          <SimpleSelect
            title="Next Bundle"
            options={bundleOptions || []}
            selectedValue={rtmData.firmware_bundle_next_id || ""}
            name="firmware_bundle_next_id"
            onChange={updateBundle}
          /> : 
          <Typography variant="body1" className={classes.noUpgrade}>
            No upgrade available.
          </Typography>
        }
      </GridItem>
    </>;
  };

  const showRTMDetails = () => {
    const {lock_id, rtm_connection, lock} = rtmData;
    const isConnected = rtm_connection && rtm_connection.active;
    const content = <GridContainer  alignItems="center">
      <GridItem xs={3} align="center">
        <SettingsInputAntennaIcon className={classes.lockIcon} />
        {lock_id && <RegularButton onClick={() => showLockInfo()}>View Lock</RegularButton>}
      </GridItem>
      <GridItem className={classes.lockDetails} xs={9}>
        <Box className={classes.lockIdContainer}>
          <h3><b>Lock:</b> {lock_id ? `0x${convertToHex(lock_id)} (${lock_id})` : "N/A"}</h3>
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Change Hardware ID">
            <IconButton
              aria-label="change hardware id"
              onClick={() => setChangeLockOpen(true)}
              className={classes.editButton}
              size="large">
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </Tooltip>
        </Box>
        <h4>{ isConnected ? "CONNECTED" : "DISCONNECTED"}</h4>
        <p className={classes.lockDetails}><b>MAC: </b>{rtmData.mac_address}</p>
        <p className={classes.lockDetails}><b>Connected At: </b>{rtm_connection && rtm_connection.connected_at ? moment(rtm_connection.connected_at).format("lll") : ""}</p>
        <p className={classes.lockDetails}><b>Disconnected At: </b>{rtm_connection && rtm_connection.disconnected_at ? moment(rtm_connection.disconnected_at).format("lll") : ""}</p>
        <p className={classes.lockDetails}><b>Last Heartbeat: </b>{rtm_connection && rtm_connection.last_heartbeat ? moment(rtm_connection.last_heartbeat).format("lll") : ""}</p>
        <p className={classes.lockDetails}><b>Configuration: </b>{rtmData.version}</p>
        { showTenantName() }
        <p className={classes.lockDetails}><b>Deployment/Container: </b>{containerName || ""}</p>
      </GridItem>
      <GridItem xs={12}>
        <p><br/></p>
        <p className={classes.lockDetails}><b>Configuration Values: </b></p>
        <TextField
          variant="standard"
          multiline
          fullWidth
          disabled={!editing}
          minRows={20}
          value={configs}
          onChange={event => handleConfigChanges(event.target.value)}
          InputProps={{
            disableUnderline: true,
            className : (editing && configsError) ? classes.borderError : "",
            classes: {root: classes.configValues}
          }} />
      </GridItem>
      <GridItem xs={12}>
        {submitting ? (<LoadingPlaceHolder height={36}/>) : (
          <GridContainer direction="row" justifyContent="flex-end" className={classes.actionButtons}>
            <GridItem>
              <RegularButton
                onClick={() => toggleEdit()}
              >
                { editing ? "Cancel" : "Edit" }
              </RegularButton>
            </GridItem>
            <GridItem>
              <RegularButton
                onClick={() => submitChanges()}
                disabled={!editing || configsError}
              >
                { "Save" }
              </RegularButton>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
      { lock && <GridItem xs={12}>
        <Box display="flex" justifyContent="center">
          <RegularButton color="primary" onClick={() => setLiveDebugSessionOpen(true)}>
            Start Live Debug Session
          </RegularButton>
        </Box>
        <LiveDebugSession
          open={liveDebugSessionOpen}
          onClose={() => setLiveDebugSessionOpen(false)}
          rtm={rtmData}>
        </LiveDebugSession>
      </GridItem> }
      { renderFirmwareBundle() }
      <GridItem xs={12}>
        <p className={classes.lockDetails}><b>Data Usage To Date: </b>
          {rtm_connection && rtm_connection.tx_total ? convertByteValueToHumanReadable(rtm_connection.tx_total) : "0B"}
        </p>
      </GridItem>
      <GridItem xs={12}>
        <SimpleSelect
          title="Data Usage Breakdown"
          options={UsageBreakdownTypes}
          selectedValue={usagePageParams.page_size}
          onChange={handleChangeUsagePageSize}>
        </SimpleSelect>
        {usageData && dataUsageTable(usageData)}
        <CustomPagination
          shape="rounded"
          size="small"
          classes={{root: classes.root, ul: classes.ul}}
          totalPages={usagePageCount}
          currentPage={usagePageParams.page_index}
          siblingCount={1}
          setCurrentPage={handleChangeUsagePage}
        />
      </GridItem>
    </GridContainer>;
    return content;
  };
  // copy the error parsing to RTM.js as well
  const onChangeLock = async() => {
    setChangeLockOpen(false);

    try {
      const payload = {
        lock_id : parseInt(newLockId),
        keep_history : keepHistoryOnLockUpdate
      };
      const res = await rtmService.migrateToNewLock(rtmData.id, payload);
      setRtmData(res);
      dispatch(alertActions.send("Successfully Updated Hardware ID.", "success"));
    } catch (e) {
      console.error(`Failed to change hardware ID ${e}`);
      let status = get(e, "response.status") || "unknown";
      const reasons = e.response?.data?.error?.errors;
      if (reasons) {
        status = Object.keys(reasons)
          .map(k => `${k.replace(/_/g, " ")} ${reasons[k].replace(/_/g, " ")}`)
          .join(";");
      }
      let errorMessage = `Failed to change hardware ID. Error: ${status}`;
      dispatch(alertActions.send(errorMessage, "error"));
    }
    setKeepHistoryOnLockUpdate(false);
  };

  useEffect(() => {
    getContainerName();
  }, [getContainerName]);

  useEffect(() => {
    fetchRtmInfo();
  }, [fetchRtmInfo]);

  return (
    <div>
      <form onSubmit={handleSubmit(onChangeLock)}>
        <CustomModal
          open={changeLockOpen}
          setOpen={setChangeLockOpen}
          title="Change Hardware ID"
          type="custom"
          cancelButton
          confirmButton
          description={changeLockSelector}
          confirm="Save"
          confirmDisabled={newLockIdError}
          handleSubmit={onChangeLock}
          manualClose
          submit
          modalStyle={classes.modal}
        />
      </form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.card}>
            <CardBody>
              <ErrorBoundary>
                { loading ? showLoadingSymbol() : showRTMDetails() }
              </ErrorBoundary>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ShowRtm.propTypes = {
  data: PropTypes.shape({
    rtm: PropTypes.object.isRequired
  }).isRequired
};