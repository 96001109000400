import PropTypes from "prop-types";

import { formatDateAsMMMMDDYYYYhhmm } from "_helpers";

import { 
  IconButton,
  Grid,
  Typography
} from "@mui/material";

import {
  Close as CloseIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  GetApp as GetAppIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/privateNotificationStyle.js";

const useStyles = makeStyles()(styles);

function PrivateNotification(props) {
  const { classes } = useStyles();
  const { accessToken, createdAt, message, messageWithLink, closeNotification } = props;
  
  return <div className={classes.notifContainer}>
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item>
            <IconButton size="small" href={accessToken} className={classes.notificationActionButton}>
              <GetAppIcon className={classes.notificationItemIcon}/>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={closeNotification} className={classes.notificationActionButton}>
              <CloseIcon className={classes.notificationItemIcon}/>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-around" alignItems="center" className={classes.notificationText}>
          <Grid item xs={1}>
            <DescriptionOutlinedIcon fontSize="small"/>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="caption">
              {message || messageWithLink}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Typography variant="caption" className={classes.notificationTimestamp}>
              {formatDateAsMMMMDDYYYYhhmm(createdAt)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>;
}

PrivateNotification.propTypes = {
  message: PropTypes.string,
  messageWithLink: PropTypes.array,
  createdAt: PropTypes.string,
  accessToken: PropTypes.string,
  closeNotification: PropTypes.func
};

export default PrivateNotification;