//Used material ui Enhanced table
import PropTypes from "prop-types";
import { useState } from "react";

import { makeStyles } from "tss-react/mui";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/widgets/tableWidgetStyle.js";
import { ThemeProvider, StyledEngineProvider, createTheme, useTheme } from "@mui/material/styles";


const useStyles = makeStyles()(styles);

/*
  The LsyTable component is used to make a generic table. It provides basic settings for making a table.
  The component TableWidget is a good example of how to use this table.
*/
function EnhancedTableHead(props) {
  const { t } = useTranslation("default");
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  const materialTheme = createTheme({
    palette: {
      primary: theme.lsyPalette.primary
    },
    overrides: {
      MuiTableSortLabel: {
        root: {
          "color": theme.lsyPalette.textContrast,
          "&:hover": {
            "color": theme.lsyPalette.textContrast,
            "&& $icon": {
              opacity: 1,
              color: theme.lsyPalette.textContrast
            }
          },
          "&.Mui-active": {
            "color": theme.lsyPalette.textContrast,
            "&& $icon": {
              opacity: 1,
              color: theme.lsyPalette.textContrast
            }
          }
        }
      }
    }
  });

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow className={classes.tableHeaderRow} >
        {props.headers.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.tableHeaderCell}
            >
              {headCell.sortable ? (
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={materialTheme}>
                    <Tooltip
                      title={
                        order === "desc" ? t("button.sortAsc") : t("button.sortDesc")
                      }
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        className={classes.sortLabel}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </Tooltip>
                  </ThemeProvider>
                </StyledEngineProvider>
              ) : (headCell.label)}
            </TableCell>
          );
        }
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headers: PropTypes.array
};

export default function EnhancedTable(props) {
  const { classes, cx } = useStyles();
  const [order, setOrder] = useState(props.sortDirection || "asc");
  const [orderBy, setOrderBy] = useState(props.orderBy || "noSort");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    props.sort(property, isAsc ? "desc" : "asc");
  };
  const headers = props.data ? props.data[0] : [];
  const bodyData = props.data ? props.data[1] : [];

  return (
    <div className={classes.tableBackground}>
      <Paper className={classes.tablePaper} elevation={0}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {bodyData.map(
                (row, index) => {
                  return (
                    <TableRow
                      role="dataRow"
                      tabIndex={-1}
                      key={`row-${index}`}
                      elevation={0}
                      onClick={row.rowAction ? () => { row.rowAction(); } : null}
                      className={row.rowAction ? classes.tableRowAction : classes.tableRow}
                    >
                      {Object.entries(row).map((cell, index) => {
                        if (cell[0] !== "rowAction") {
                          return (
                            <TableCell key={`cell=${index}`} className={cx(classes.tableBodyCell, { [cell[1].overflowHidden]: cell[1].overflowHidden })} elevation={0} align="left">
                              {cell[1].action ? (
                                <div
                                  id={index}
                                  onClick={cell[1].action}
                                  className={classes.actionLink}
                                >
                                  {cell[1].text}
                                </div>
                              ) : (cell[1].text)}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
EnhancedTable.propTypes = {
  data: PropTypes.array,
  sortDirection: PropTypes.string,
  orderBy: PropTypes.string,
  sort: PropTypes.func,
  liveUpdates: PropTypes.bool
};
