import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const styles = {
  topText: {
    marginBottom: "10px"
  }
};
const useStyles = makeStyles()(styles);

const MfaSetForm = ({ mfaEnabled, isMfaPending, personalAccount }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("auth");

  const getEnableMessage = () => {
    return personalAccount ? t("mfa.confirmEnable") : 
      <Fragment>
        <Typography>{t("mfa.enableNotice")}</Typography>
        <Typography>{t("mfa.setupNotice")}</Typography>
        <Typography>{t("mfa.confirmEnable")}</Typography>
      </Fragment>;
  };

  const getDisableMessage = () => {
    return isMfaPending ? t("mfa.confirmDisable") :
      <Fragment>
        { personalAccount ? null : <Typography>{t("mfa.disableMembership")}</Typography> }
        <Typography>{t("mfa.confirmDisable")}</Typography>
      </Fragment>;
  };

  return (
    <div className={classes.topText}>
      {mfaEnabled ? getDisableMessage() : getEnableMessage()}
    </div>
  );
};

MfaSetForm.propTypes = {
  mfaEnabled: PropTypes.bool,
  isMfaPending: PropTypes.bool,
  personalAccount: PropTypes.bool
};

MfaSetForm.defaultProps = {
  mfaEnabled: false,
  personalAccount: false
};

export default MfaSetForm;