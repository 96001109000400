import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { Can } from "@casl/react";

import { useTranslation } from "react-i18next";
import { lockgroupService } from "_services/lockstasy";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

import RecentlyUpdated from "_components/Helper/RecentlyUpdated";
import LsyRouter from "_components/Navigation/LsyRouter";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lockGroupsCardStyle.js";

import DeleteIcon from "@mui/icons-material/Delete";
import LockIcons from "assets/teleporte/LockIcons";

const useStyles = makeStyles()(styles);

function LockGroupsCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { data } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const deleteLockGroup = async (lockGroupId, fetchWidgetData) => {
    try {
      await lockgroupService.deleteLockgroup({ id: lockGroupId });
      dispatch(alertActions.send(t("success.deleteLockgroup")));
      setTimeout(() => { // FIXME: this is due to a delay to update data in the backend database
        fetchWidgetData();
      }, [1000]);
    } catch (e) {
      console.warn("Warning, failed to delete", e);
      dispatch(alertActions.send(t("error.deleteLockgroup"), "error"));
    }
  };

  const handleDeleteGroup = () => {
    props.createModal({
      type: "confirm",
      confirm: t("button.confirm"),
      confirmAction: () => deleteLockGroup(data.id, props.fetchWidgetData),
      title: t("confirmation.deleteTitle"),
      description: t("confirmation.deleteLockgroup"),
      submit: true
    });
  };

  return (
    <Card className={classes.card}>
      <div className={classes.divBar} />
      <RecentlyUpdated updatedAt={data.updated_at}/>
      <p className={classes.lockGroupName}>
        <LsyRouter page="lock_group" id={data.id}>
          <span className={classes.name}>{data.name}</span>
        </LsyRouter>
      </p>
      {data.description ?
        <p className={classes.description}>
          <span>
            {data.description}
          </span>
        </p> : <div className={classes.placeholderDiv} />}
      <div className={classes.iconDiv}>
        {ability.can("read", "locks") & data.number_of_locks !== 0 ?
          <Fragment>
            <LockIcons className={classes.lockIcon} type={"AP3"} />
            <span className={classes.count} data-testid="numberOfLocks">{data.number_of_locks}</span>
          </Fragment> : null}
        <div className={classes.iconDiv}>
          <Can I="delete" on="lock_groups" ability={ability}>
            <Tooltip title={t("button.deleteLockgroup")} size="small" classes={{ tooltip: classes.tooltip }} >
              <IconButton
                onClick={handleDeleteGroup}
                className={classes.deleteButton}
                aria-label="delete"
                size="large">
                <DeleteIcon data-testid="DeleteIcon" className={classes.lockGroupDelete} />
              </IconButton>
            </Tooltip>
          </Can>
        </div>
      </div>
    </Card>
  );
}

LockGroupsCard.propTypes = {
  data: PropTypes.object,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func
};

export default LockGroupsCard;