import { useReducer, useCallback } from "react";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";

import { compactObj, findNoteErrorString, formatDateToAPI } from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { lockNoteService, lockService, userService } from "_services/lockstasy";

import { Skeleton, TablePagination } from "@mui/material";
import GridItem from "components/Grid/GridItem";

import ErrorBoundary from "_components/ErrorBoundary";
import LockNoteCard from "_containers/Cards/LockNoteCard";
import Placeholder from "_components/Helper/Placeholder";
import GridContainer from "components/Grid/GridContainer";

import { List } from "@mui/icons-material";

import styles from "assets/jss/views/lockstasy/lockNotesStyle";
import { makeStyles } from "tss-react/mui";
import { NOTE_TYPE } from "_constants/resourceNote.constants";

const useStyles = makeStyles()(styles);

function LockNotesTable(props) {
  const { filterVariables, currentPage, setCurrentPage, 
    setNoteState, clickedNoteId, setLoading, locks, users,
    images, addLocks, addUsers, addImages } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");

  const [state, setState] = useReducer(genericReducer,
    {
      data: [],
      rowsPerPage: 15,
      totalSize: 0,
      currentPage: 1,
      sortDirection: "desc",
      loading: true,
      rowClicked: ""
    }
  );

  const setRowsPerPage = row => setState({ rowsPerPage: row });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleRowChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleNoteClicked = useCallback((note) => {
    if (!note) {
      return;
    }

    setNoteState({ data: note });
  }, [setNoteState]);

  const fetchLocks = useCallback(async () => {
    if (NOTE_TYPE.lock !== filterVariables.type) {
      return;
    }

    const lockIds = filterVariables.resources?.map(r => {
      if (locks.every(l => l.id !== r.id)) {
        return r.id;
      }
    });

    if (isEmpty(lockIds)) {
      return;
    }

    try {
      const result = await lockService.fetchLocks({ ids: lockIds });
      const formattedResult = result.data.map(l => l ? { id: l.id, name: l.full_identifier } : null);
      addLocks(formattedResult);
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, [addLocks, filterVariables.resources, filterVariables.type, locks]);

  const fetchAuthor = useCallback(async () => {
    if (!filterVariables.author?.id || users.some(u => u?.id === filterVariables.author.id)) {
      return;
    }

    try {
      const result = await userService.fetchUser({ id: filterVariables.author?.id});
      const formattedResult = { id: result.data.membership_id, name: `${result.data.first_name} ${result.data.last_name} (${result.data.email})` };
      addUsers([formattedResult]);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, [addUsers, filterVariables.author?.id, users]);

  const fetchNotes = useCallback(async() => {
    const resource_ids = filterVariables.resources?.map(r => r.id);
    const membership_id = filterVariables.author?.id;
    try {
      const options = {
        sort_by: "created_at,desc",
        page_size: state.rowsPerPage,
        page: currentPage,
        ... filterVariables.start_date && {start_date: formatDateToAPI(filterVariables.start_date.startOf("day"))},
        ... filterVariables.end_date && {end_date: formatDateToAPI(filterVariables.end_date.endOf("day"))},
        resource_ids: resource_ids?.toString(),
        type: filterVariables.type,
        membership_id: membership_id,
        system_generated: filterVariables.systemGenerated,
        lock_status: filterVariables.lockStatus
      };

      const result = await lockNoteService.fetchResourcesNotes(compactObj(options));

      setState({
        data: result.data,
        totalSize: result.meta.pagination.total,
        loading: false,
        rowClicked: ""
      });

      if (result.data) {
        setNoteState({ data: result.data[0] });
      }

    } catch (e) {
      const errorMsg = t(findNoteErrorString(e, {
        defaultMsg: `${t("fallbacks.noLockNotesFound")} (${t("error.couldNotFetch")})`
      }));
      setState({ loading: false, errorMsg: errorMsg });
      console.warn("Warning, failed to fetch lock notes", e);
    }
    setLoading(false);
  }, [filterVariables, currentPage, state.rowsPerPage, t, setNoteState, setLoading]);

  const fetchAllImages = useCallback(async () => {
    const results = [];

    for (const note of state.data) {
      if (!note.photos || isEmpty(note.photos)) {
        continue;
      }

      const img = images.find(img => note.photos.some(photo => photo.id === img.id));

      if (img) {
        continue;
      }
        
      try {
        const result = await lockNoteService.fetchImage({ id: note.photos[0].id });
        results.push({ id: note.photos[0].id, data: result.data });
      } catch(e) {
        console.warn("Warning, failed to fetch image ", e?.response);
      }
    }

    addImages(results);
  }, [state.data, images, addImages]);

  useCustomCompareEffect(() => {
    fetchAllImages();
  }, [state.data], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    setState({loading: true});
    setLoading(true);
    setNoteState({ data: {} });
    fetchLocks();
    fetchAuthor();
    fetchNotes();
  }, [filterVariables, currentPage, state.rowsPerPage], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderLockNotes = useCallback(() => {
    return state.data.map((note) => {
      return <LockNoteCard 
        key={note.id}
        note={note}
        images={images}
        addImages={addImages}
        onClick={() => handleNoteClicked(note)}
        isSelected={clickedNoteId === note.id}
      />;
    });
  }, [clickedNoteId, state.data, images, addImages, handleNoteClicked]);

  const renderLockNotesList = () => {
    return state.data.length !== 0 ? 
      <div data-testid="table">
        <GridContainer className={classes.gridContainer}>
          <GridItem xs={12} md={12}>
            {renderLockNotes()}
          </GridItem>
        </GridContainer>
      </div> : 
      <div className={classes.placeholder} data-testid="placeholder">
        <Placeholder 
          message={t("fallbacks.noLockNotes")} 
          classNameMessage={classes.placeholderText} 
          icon={<List/>} 
          classNameIcon={classes.placeholderIcon}
        />
      </div>;
  };

  return (
    <ErrorBoundary>
      <TablePagination
        className={classes.pagination}
        data-testid="pagination"
        component="div"
        count={state.totalSize || 0}
        rowsPerPage={state.rowsPerPage}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleRowChange}
      />
      {!state.loading ? 
        renderLockNotesList() : 
        <GridContainer className={classes.gridContainer}>
          <GridItem xs={12} md={12}>
            {[...Array(4).keys()].map((v) => {
              return (
                <Skeleton className={cx(classes.tableItem, classes.tableSkeleton)} key={v} />
              );
            })}
          </GridItem>
        </GridContainer>}
      <TablePagination
        className={classes.pagination}
        data-testid="pagination"
        component="div"
        count={state.totalSize || 0}
        rowsPerPage={state.rowsPerPage}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleRowChange}
      />
    </ErrorBoundary>
  );
}

LockNotesTable.propTypes = {
  filterVariables: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setNoteState: PropTypes.func,
  clickedNoteId: PropTypes.string,
  setLoading: PropTypes.func,
  locks: PropTypes.array,
  users: PropTypes.array,
  images: PropTypes.array,
  addLocks: PropTypes.func,
  addUsers: PropTypes.func,
  addImages: PropTypes.func
};

export default LockNotesTable;