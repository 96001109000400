const logCategories = Object.freeze([
  { label: "legend.1", value: 1 },
  { label: "legend.2", value: 2 },
  { label: "legend.3", value: 3 },
  { label: "legend.4", value: 4 },
  { label: "legend.5", value: 5 },
  { label: "legend.6", value: 6 },
  { label: "legend.7", value: 7 },
  { label: "legend.8", value: 8 },
  { label: "legend.9", value: 9 },
  { label: "legend.10", value: 10 },
  { label: "legend.11", value: 11 },
  { label: "legend.12", value: 12 },
  { label: "legend.13", value: 13 }
]);

export const systemLogsHelper = {
  logCategories
};