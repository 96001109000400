/*eslint quote-props: ["warn", "as-needed"]*/
const lockNoteWidgetStyle = theme => ({
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "500px"
  },
  topBar: {
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center"
  },
  pointer: {
    cursor: "pointer"
  },
  title: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  table: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    tableLayout: "fixed"
  },
  tableTitle: {
    color: theme.lsyPalette.primary.mainLight,
    textDecoration: "underline",
    textUnderlineOffset: "2px",
    textDecorationColor: theme.lsyPalette.primary.mainLight,
    textAlign: "center"
  },
  divider: {
    backgroundColor: theme.lsyPalette.primary.mainLight
  },
  noteModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    minWidth: "200px"
  },
  lockCard: {
    paddingTop: "5px",
    paddingBottom: "5px",
    marginTop: "1px",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    width: "100%" 
  },
  lockNoteMessage: {
    marginTop: "0px",
    marginBottom: "8px",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  lockName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  siteName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  date: {
    color: theme.lsyPalette.primary.mainLight
  },
  fallback: {
    minHeight: "280px",
    flexGrow: 1
  },
  fallbackText: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text
  },
  photoIcon: {
    color: theme.lsyPalette.primary.main,
    display: "inline",
    transform: "scale(0.8)"
  },
  lockCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableContainer: {
    minHeight: "310px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  image: {
    maxWidth: "400px",
    maxHeight: "400px"
  },
  previewWrap: {
    display: "inline-block"
  },
  smallPreview: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  extraSmallPreview: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3)
  }
});
export default lockNoteWidgetStyle;
