import { alertConstants } from "_constants";

function enqueueSnackbar(notification) {
  return {
    type: alertConstants.ENQUEUE,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
      options: {
        preventDuplicate: true,
        ...notification.options
      }
    }
  };
}

function info(notification) {
  return enqueueSnackbar({ message: notification, options: { variant: "info" } });
}

function warning(notification) {
  return enqueueSnackbar({ message: notification, options: { variant: "warning" } });
}

function success(notification) {
  return enqueueSnackbar({ message: notification, options: { variant: "success" } });
}

function error(notification) {
  return enqueueSnackbar({ message: notification, options: { variant: "error" } });
}

function send(notification, level = "success") {
  switch (level.toLowerCase()) {
    case "success": return success(notification);
    case "error": return error(notification);
    case "info": return info(notification);
    case "warn": return warning(notification);
    default: console.warn("No notification with level:", level);
  }
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function removeSnackbar(key) {
  return {
    type: alertConstants.REMOVE,
    key
  };
}

export const alertActions = {
  success,
  error,
  info,
  warning,
  clear,
  send,
  enqueueSnackbar,
  removeSnackbar
};