const workSessionsStyle = (theme) => ({
  backContainer: {
    width: "fit-content"
  },
  backIcon: {
    color: "inherit",
    fontSize: theme.lsyPalette.body1
  },
  displayGrid: {
    display: "grid"
  },
  divider: {
    justifySelf: "center"
  },
  line: {
    "width": "fit-content",
    "display": "flex",
    "alignItems": "center",
    "color": theme.lsyPalette.primary.mainLight,
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  titleDescription: {
    color: "inherit",
    fontSize: theme.lsyPalette.body1
  }
});
  
export default workSessionsStyle;