const personalInformationStyle = theme => ({
  margins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    textTransform: "none",
    marginBottom: theme.spacing(1)
  }
});

export default personalInformationStyle;