const GenericLockStyle = theme => ({
  svg: {
    enableBackground: "new 0 0 24 24"
  },
  cls2: {
    fill: "none",
    strokWidth: ".62px",
    stroke: "currentColor",
    strokeMiterlimit: "10"
  },
  cls2Open: {
    fill: "none",
    strokeWidth: ".62px",
    stroke: "currentColor",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  },
  circle: {
    fill: "currentColor",
    stroke: "currentColor",
    strokeMiterlimit: "10",
    strokWidth: ".42px"
  },
  path: {
    fill: "currentColor",
    stroke: "currentColor",
    strokeMiterlimit: "10",
    strokWidth: ".39px"
  },
  redStroke: {
    stroke: theme.lsyPalette.stdRed
  },
  redFill: {
    fill: theme.lsyPalette.stdRed
  },
  whiteStroke: {
    stroke: theme.lsyPalette.secondary.main
  },
  whiteFill: {
    fill: theme.lsyPalette.secondary.main
  },
  greyStroke: {
    stroke: theme.lsyPalette.primary.mainLight
  },
  greyFill: {
    fill: theme.lsyPalette.primary.mainLight
  },
  blackStroke: {
    stroke: "black"
  },
  blackFill: {
    fill: "black"
  }
});
export default GenericLockStyle;
    