let db;
const DB_VERSION = 2;

const openDB = () => {
  const DBOpen = window.indexedDB.open("TeleporteDB", DB_VERSION);

  DBOpen.onupgradeneeded = (event) => {
    db = event.target.result;

    if (!db.objectStoreNames.contains("favourites")) {
      db.createObjectStore("favourites", { keyPath: "membership_id" });
    }

    if (!db.objectStoreNames.contains("filters")) {
      db.createObjectStore("filters", { keyPath: "membership_id" });
    }
  };

  DBOpen.onsuccess = (event) => {
    db = event.target.result;
  };

  DBOpen.onerror = (err) => {
    console.warn("Error opening database", err);
  };
};

const getDB = () => {
  if (!db) {
    openDB();
  }

  return db;
};

export function initDB() {
  openDB();
}

export function get(table, key) {
  return new Promise((resolve, reject) => {
    const transaction = getDB().transaction(table, "readonly");
    const objectStore = transaction.objectStore(table);
    const request = objectStore.get(key);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (err) => {
      console.warn(`Error fetching ${table}`, err);
      reject(err);
    };
  });
}

export function save(table, data) {
  return new Promise((resolve, reject) => {
    const transaction = getDB().transaction(table, "readwrite");
    const objectStore = transaction.objectStore(table);
    const request = objectStore.add(data);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (err) => {
      console.warn(`Error saving ${table}`, err);
      reject(err);
    };
  });
}

export function update(table, data) {
  return new Promise((resolve, reject) => {
    const transaction = getDB().transaction(table, "readwrite");
    const objectStore = transaction.objectStore(table);
    const request = objectStore.put(data);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (err) => {
      console.warn(`Error updating ${table}`, err);
      reject(err);
    };
  });
}