import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { lockgroupService } from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";
import { isNullOrUndefined } from "_helpers";

const SelectLockGroup = (props) => {
  const { className, lock, lockGroup, setLockGroup } = props;

  const { t } = useTranslation("default");

  const isOptionDisabled = () => !hasLockLicense("lock_groups", lock);

  const fetchLockGroups = async (inputValue) => {
    try {
      const result = await lockgroupService.fetchLockgroups({
        page_size: 5,
        search: inputValue
      });
      const formattedResult = result.data.map(group => group ? {
        id: group.id,
        name: group.name
      } : null);

      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch lock groups with given input", e?.response);
    }
  };

  const handleOnChange = (selectedOption) => {
    const option = isNullOrUndefined(selectedOption) ? {} : selectedOption;
    setLockGroup(option);
  };

  return <CustomAsyncSelect
    class={className}
    autoFocus={false}
    placeholder={t("features.lockGroups")}
    value={isEmpty(lockGroup) ? null : lockGroup}
    onChange={handleOnChange}
    loadOptions={fetchLockGroups}
    isOptionDisabled={isOptionDisabled}
  />;
};

SelectLockGroup.propTypes = {
  className: PropTypes.string,
  lock: PropTypes.object.isRequired,
  lockGroup: PropTypes.object,
  setLockGroup: PropTypes.func.isRequired
};

export default SelectLockGroup;