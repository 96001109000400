const placeholderStyle = theme => ({
  placeholder: {
    paddingTop: "30px",
    paddingBottom: "30px",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    justifyContent: "center",
    alignItems: "center"
  },
  disabledColor: {
    color: theme.lsyPalette.primary.mainLight
  },
  message: {
    fontSize: theme.lsyPalette.body1
  }
});

export default placeholderStyle;