import { get } from "_helpers/axios/gw.client";
import {
  identityUrl,
  lockdepotUrl
} from "_services/helper";

/* This fetch has very specific options to manipulate the data PER table and PER query */
const fetchHistory = (options) => {
  var tableUrl = options.url === "lockDepot" ? lockdepotUrl : identityUrl;
  var table = options.table;
  var lockId = options.lockId;
  var query = options.query;
  var author = options.author;
  var tenant = options.tenant;
  var url = `${tableUrl}/audit_trails?page_size=${options.rowsSelected}&page_index=${options.currentPage}&sort_by=${options.currentSort},${options.currentOrder}`;

  const docId = ["Account", "Crypto", "Lock", "Ils"];

  if (table !== "All") {
    url += `&resource=${table}`;
  }

  if (query) {
    if (docId.includes(table) || (table === "All" && options.url === "lockDepot")) {
      url += `&document_id=${query}`;
    } else {
      url += `&document[${options.documentParam}]=${query}`;
    }
  }

  // placeholder for TWS-1801 to set the document_id = tenant_id IF it's the tenants table
  if (table === "Tenant")
    url += `&document_id=${tenant}`;

  if (lockId) {
    url += `&document[lock_id]=${lockId}`;
  }

  if (author) {
    url += `&account_id=${author}`;
  }

  return get(url);
};

export const historyService = {
  fetchHistory
};