import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import moment from "moment";
import JSONPretty from "react-json-pretty";

import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ReplayIcon from "@mui/icons-material/Replay";

import Collapsable from "_components/Collapsabale/Collapsable";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import RegularButton from "_components/Button/RegularButton";
import DataTable from "_components/Table/DataTable";

import { modalActions, alertActions } from "_actions";
import { organizationService } from "_services";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  content: {
    marginTop: 20
  },
  okIcon: {
    fill: theme.lsyPalette.okColor
  },
  errorIcon: {
    fill: theme.lsyPalette.stdRed
  },
  refreshIcon: {
    fill: theme.lsyPalette.footerText,
    cursor: "pointer",
    marginLeft: "0.5rem"
  },
  refreshIconDisabled: {
    fill: theme.lsyPalette.border.lightGrey,
    marginLeft: "0.5rem"
  }
}));

export default function WebhookInspector () {

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [retryDisabledRows, setRetryDisabledRows] = useState([]);

  const { t } = useTranslation("default");

  const fetchWebhooks = useCallback(async () => {
    setLoading(true);

    try {
      const result = await organizationService.fetchWebhookLogs({entries: rowsPerPage});

      setData(
        result.data.map((element, index) => {
          const { id, attempts, sent_at, last_status, completed_at } = element;
          const { action } = element.payload;

          const dataOnClick = () => {
            const modalProps = {
              title: t("label.webhooksData"),
              message: (
                <JSONPretty
                  id={`json-pretty-${index}`}
                  data={element.payload}
                />
              )
            };
            dispatch(
              modalActions.showModal({
                modalProps,
                modalType: "alert"
              })
            );
          };

          const data = (
            <IconButton
              title={"Show Data"}
              aria-label="show data"
              onClick={dataOnClick}
              size="large">
              <DataUsageIcon classes={{root: classes.smallIcon}} />
            </IconButton>
          );

          return {
            id,
            attempts,
            action,
            sent_at,
            last_status,
            data,
            completed_at
          };
        })
      );

    } catch (error) {
      console.warn(error);
    }

    setLoading(false);
  }, [classes.smallIcon, t, dispatch, rowsPerPage]);
  
  useEffect(() => {
    fetchWebhooks();
  }, [rowsPerPage, fetchWebhooks]);

  const handleReload = () => {
    fetchWebhooks();
    setRetryDisabledRows([]);
  };

  const onRefresh = async (id) => {
    try {
      await organizationService.reFetchWebhookLog(id);
      dispatch(alertActions.send(t("success.addQueue")));
    } catch (error) {
      console.warn(error);
      dispatch(alertActions.send(t("error.addQueue"), "error"));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      setRowsPerPage(e.currentTarget.dataset.value);
    }
  };

  const actionOptions = (row) => {
    return (
      <div style={{display: "flex", alignItems: "center"}}>
        {row.data}
        {!row.completed_at ? 
          <Tooltip title={t("label.resendWebhook")}>
            {!retryDisabledRows.includes(row.id) ? 
              <ReplayIcon 
                onClick={() => {
                  onRefresh(row.id);
                  setRetryDisabledRows([...retryDisabledRows, row.id]);
                }} 
                classes={{root: classes.refreshIcon }}
              /> :
              <ReplayIcon classes={{root: classes.refreshIconDisabled }}/>
            }
          </Tooltip> : null
        }
      </div>
    );
  };

  const getStatus = row => {
    const status = row.last_status;

    if (status) {
      let statusIcon;
      if ((status >= 200) && (status < 300)) {
        statusIcon = <CheckCircleIcon classes={{root: classes.okIcon }} />;
      } else {
        statusIcon = <ErrorIcon classes={{root: classes.errorIcon }} />;
      }
      return <span title={status}>{statusIcon}</span>;
    }
  };

  const getSentAt = row => {
    return row.sent_at ? <span title={row.sent_at}>{moment(row.sent_at).format("L")}</span> : null;
  };

  const handleChangeRowsPerPage = event => setRowsPerPage(event.target.value);
  
  return (
    <Card style={{ marginTop: "25px", height: "fit-content" }}>
      <Collapsable title={t("widget.webhookInspector")} executeOnUnfold={fetchWebhooks} collapsed={true}>
        <Grid container className={classes.content}>
          <GridItem xs={12}>
            <DataTable
              data={data}
              progressPending={loading}
              striped
              height={80}
              columns={[
                {
                  name: t("label.status"),
                  maxWidth: "25px",
                  selector: getStatus
                },
                {
                  name: t("label.id"),
                  maxWidth: "25px",
                  selector: (row) => row.id
                },
                {
                  name: t("label.attempts"),
                  maxWidth: "25px",
                  selector: (row) => row.attempts
                },
                {
                  name: t("label.action"),
                  selector: (row) => row.action
                },
                {
                  name: t("label.sentAt"),
                  selector: getSentAt
                },
                {
                  name: t("widgetField.options"),
                  selector: (row) => actionOptions(row)
                }
              ]}
            />

          </GridItem>
          <GridItem xs={12} >
            <Select
              className={classes.itemBox}
              variant="standard"
              value={rowsPerPage}
              label={"Number of Items"}
              data-testid="exportTypeId"
              onChange={handleChangeRowsPerPage}
            >
              <MenuItem value={5} onKeyDown={handleKeyDown}>5</MenuItem>
              <MenuItem value={10} onKeyDown={handleKeyDown}>10</MenuItem>
              <MenuItem value={25} onKeyDown={handleKeyDown}>25</MenuItem>
              <MenuItem value={50} onKeyDown={handleKeyDown}>50</MenuItem>
            </Select>
            <RegularButton justify={"right"} onClick={handleReload}>{t("actions.reload")}</RegularButton>
          </GridItem>
        </Grid>
      </Collapsable>

    </Card>
  );
}

WebhookInspector.propTypes = {};